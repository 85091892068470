import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import ReactCrop from "react-image-crop";

const CropModal = (props: {
    src: any;
    isOpen: boolean;
    onClose: any;
    onSuccess: any;
    modalTitle: string;
    aspectY?: number;
    aspectX?: number;
}) => {
    const [crop, setCrop] = useState({
        aspect: props.aspectY ? props.aspectY : 3 / props.aspectX ? props.aspectX : 2,
        height: 100,
    });
    const [imgElement, setImgElement] = useState({});
    return (
        <>
            <Modal isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalTitle}</ModalHeader>
                    <ModalBody>
                        <ReactCrop
                            src={props.src}
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onImageLoaded={(image) =>
                                setImgElement({
                                    tmpHeight: image.height,
                                    tmpWidth: image.width,
                                })
                            }
                            keepSelection
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" ml={1} onClick={props.onClose}>
                            انصراف
                        </Button>
                        <Button onClick={() => props.onSuccess({ ...crop, ...imgElement })}>تایید</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
export const b64toBlob = (b64Data, contentType?, sliceSize?) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
export default CropModal;
