import React from "react";
import { Box } from "@chakra-ui/react";
import { IAvatarProps } from "@util/interfaces";

export const CustomAvatar = React.forwardRef((props: IAvatarProps, ref: React.LegacyRef<HTMLImageElement>) => {
    return (
        <Box
            as="span"
            className={`${props.noBorder ? "p-0" : "p-1 border"} rounded${
                props.rounded ? `-${props.rounded}` : "-full"
            } inline-flex justify-center rounded- items-center relative  ${props.className}`}
            style={{
                top: props.shiftToTop ? "-1.5rem" : "0",
                height: props.height ? props.height : "3rem",
                width: props.width ? props.width : "3rem",
                ...props.style,
            }}
        >
            {props.showPlaceholder ? (
                props.placeholder
            ) : (
                <img
                    ref={ref}
                    id={props.id ? props.id : ""}
                    src={props.src}
                    className={`rounded${props.rounded ? `-${props.rounded}` : "-full"}`}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }}
                />
            )}
        </Box>
    );
});
