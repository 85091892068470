import React from "react";
import cssClasses from "./errorView.module.css";

export const ErrorView = (props: { url: string; statusCode?: number | string; errorMessage: string; terminalMessage?: string }) => {
    return (
        <>
            <div className={`${cssClasses.root} flex flex-col justify-center items-center h-full`}>
                <div className={`text-center ${cssClasses.header}`}>
                    <h3>Oops!</h3>
                    {props.statusCode && <h1>{props.statusCode}</h1>}
                    <h2>{props.errorMessage}</h2>
                </div>
                <div className={`${cssClasses.window}`}>
                    <div className={`${cssClasses.bar}`}>
                        <div className={`${cssClasses.btn}`}></div>
                    </div>
                    <div className={`${cssClasses.body}`}>
                        <pre>
                            <div className={`${cssClasses.comment}`}># fetching URL:</div>
                            <div className={`${cssClasses.prompt}`}>
                                $ <span className={`${cssClasses.command}`}>fetch {props.url}</span>
                            </div>
                            <div className={`${cssClasses.prompt}`}>
                                <span
                                    style={{
                                        color: "#e5383b",
                                    }}
                                >
                                    {props.statusCode} {props.errorMessage}
                                </span>
                            </div>
                            <div className={`${cssClasses.prompt}`}>
                                $ <span className={`${cssClasses.pulse}`}>_</span>
                            </div>
                        </pre>
                    </div>
                </div>
            </div>
        </>
    );
};
