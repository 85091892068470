import React from "react";
import { Text, useColorModeValue, Box, VStack } from "@chakra-ui/react";
import styles from "./CompSidebar.module.css";
import { IMenuSectionProps } from "@util/interfaces";

const MenuSection = (props: IMenuSectionProps) => {
    return (
        <Box className={`${!props.noMargin && "mt-12"} `}>
            <Box>
                <Text
                    fontWeight="600"
                    color={useColorModeValue("blackAlpha.500", "whiteAlpha.700")}
                    className={`${styles.menu_item_branch_label} `}
                >
                    {props.title}
                </Text>
            </Box>
            <Box
                className="mt-2"
                style={{
                    marginInlineStart: "-.75rem",
                    marginInlineEnd: "-.75rem",
                }}
            >
                <ul className="leading-9">
                    <VStack spacing=".25rem" alignItems="start">
                        {props.children}
                    </VStack>
                </ul>
            </Box>
        </Box>
    );
};
export default MenuSection;
