import { useTheme } from "@chakra-ui/system";
import {
    Input,
    Button as ChakraButton,
    Heading,
    IconButton,
    InputGroup,
    InputRightElement,
    Collapse,
    Alert,
    AlertIcon,
    AlertDescription,
    useMediaQuery,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { NextRouter } from "next/router";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { UserChangePassword } from "@util/api/auth";

import Style from "./reset.module.css";

export const ResetPassword = (props: { passwordChangeId: string | string[]; router: NextRouter }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const bg = useColorModeValue(theme.colors.RoboEpics.black.default, theme.colors.RoboEpics.gold["400"]);
    const btnTxt = useColorModeValue("#fff", theme.colors.RoboEpics.black.default);
    const passwordChangeId = props.passwordChangeId;

    const [values, setValues] = useState({
        password: "",
        passwordConfirm: "",
        showPassword: false,
        showPasswordConfirm: false,
    });
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleShowPasswordConfirm = () => {
        setValues({
            ...values,
            showPasswordConfirm: !values.showPasswordConfirm,
        });
    };
    const onResetPassword = async () => {
        setLoading(true);
        setDisabled(true);
        if (values.password.length * values.passwordConfirm.length === 0) {
            setAlertProps({
                open: true,
                message: "پر کردن هر دو فیلد الزامی است!",
                status: "error",
            });
            setLoading(false);
            setDisabled(false);
        } else {
            if (values.password !== values.passwordConfirm) {
                setAlertProps({
                    open: true,
                    message: "رمز عبور با تکرار آن همخوانی ندارد",
                    status: "error",
                });
                setLoading(false);
                setDisabled(false);
            } else {
                const serverResponse = await UserChangePassword(passwordChangeId, values.password);
                switch (serverResponse.status) {
                    case 200:
                        setAlertProps({
                            open: true,
                            message: "رمز عبور شما با موفقیت تغییر کرد.",
                            status: "success",
                        });
                        setLoading(false);
                        setDisabled(false);
                        props.router.push({ pathname: "/users/signin", query: { ...props.router.query } });
                        break;
                    case 404:
                        setAlertProps({
                            open: true,
                            message: "لینک بازنشانی رمز عبور شما منقضی شده است . دوباره تلاش کنید.",
                            status: "error",
                        });
                        setLoading(false);
                        setDisabled(false);
                        break;
                    default:
                        setAlertProps({
                            open: true,
                            message: "رمز عبور شما تغییر نکرد LOL XD",
                            status: "error",
                        });
                        setLoading(false);
                        setDisabled(false);
                        break;
                }
            }
        }
    };

    const [isGreaterThan330px] = useMediaQuery("(min-width: 330px)");
    return (
        <div
            className={`flex flex-col pt-10 pb-8 px-10 w-full items-center mx-auto justify-center mr-8 ${Style.reset_password_form_wrapper}`}
        >
            <div className={`w-3/5 py-12 px-8 rounded-md ${Style.card}`}>
                <div className="mb-6">
                    <Heading as="h2" size={isGreaterThan330px ? "lg" : "md"} className="auth-form-supertitle">
                        بازنشانی رمز عبور{" "}
                    </Heading>
                    <Heading as="h4" size="xs" fontWeight="300" className="auth-form-title">
                        رمزعبور جدید خود را در کادر زیر وارد کنید.
                    </Heading>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="auth-form-inputs flex flex-col mb-3">
                        <Collapse in={alertProps.open}>
                            <Alert status={alertProps.status} variant={colorMode === "dark" ? "subtle" : "solid"}>
                                <AlertIcon />
                                <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                            </Alert>
                        </Collapse>
                        <InputGroup size="md" className="my-3">
                            <Input
                                value={values.password}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        password: e.target.value,
                                    });
                                }}
                                variant="filled"
                                type={values.showPassword ? "text" : "password"}
                                placeholder="رمز عبور جدید"
                            />
                            <InputRightElement bg={"transparent"}>
                                <IconButton
                                    bg={"transparent"}
                                    aria-label="reset-show-password"
                                    size="sm"
                                    icon={values.showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    onClick={handleShowPassword}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <InputGroup size="md" className="my-3">
                            <Input
                                value={values.passwordConfirm}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        passwordConfirm: e.target.value,
                                    });
                                }}
                                variant="filled"
                                type={values.showPasswordConfirm ? "text" : "password"}
                                placeholder="تکرار رمز عبور جدید "
                            />
                            <InputRightElement bg={"transparent"}>
                                <IconButton
                                    bg={"transparent"}
                                    aria-label="reset-show-password-confirm"
                                    size="sm"
                                    icon={values.showPasswordConfirm ? <ViewIcon /> : <ViewOffIcon />}
                                    onClick={handleShowPasswordConfirm}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </div>

                    <div className="reset-password-button mb-6">
                        <ChakraButton
                            type="submit"
                            isDisabled={disabled}
                            isLoading={loading}
                            bg={bg}
                            color={btnTxt}
                            variant="solid"
                            isFullWidth
                            onClick={onResetPassword}
                            _hover={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                            _active={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                            _focus={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                        >
                            بازنشانی رمزعبور
                        </ChakraButton>
                    </div>
                </form>
            </div>
        </div>
    );
};
