import React from "react";

export const MainContainer = (props) => {
    return (
        <div className="flex flex-col justify-start align-top w-full h-full">
            <div className="mx-auto mt-0" style={{ maxWidth: "1280px", width: "calc(100% - 2rem)" }}>
                {props.children}
            </div>
        </div>
    );
};
