import React from "react";
import { useSelector } from "react-redux";
import { Link, Avatar, useColorModeValue, Text, Box, Badge, Tooltip } from "@chakra-ui/react";
import { CustomAvatar } from "@components";
import { getImageURL } from "@util/mediaLoader";

export const MiniUserProfile = (props: {
    size?: "sm" | "md";
    fullName: string;
    username?: string;
    imageFilename?: string;
    onClick?: any;
}) => {
    const username = useSelector((state: { user: any }) => state.user.data.username);

    let ldiv = document.createElement("div");
    document.body.appendChild(ldiv);
    ldiv.style.fontSize = "1rem";
    ldiv.style.position = "absolute";
    ldiv.style.left = "-1000px";
    ldiv.style.top = "-1000px";

    ldiv.textContent = props.fullName;
    const result = {
        width: ldiv.clientWidth,
        height: ldiv.clientHeight,
    };
    document.body.removeChild(ldiv);
    ldiv = null;

    return (
        <Tooltip isDisabled={result.width < 200} label={props.fullName} hasArrow arrowSize={6}>
            {props.onClick ? (
                <Box
                    w="full"
                    borderRadius="6px"
                    p={"0.25rem 0.5rem"}
                    className="flex flex-row items-center justify-start"
                    transition="all 0.5s"
                    onClick={props.onClick}
                    _hover={{
                        cursor: "pointer",
                        bgColor: useColorModeValue("#e9e9e9", "#2c2c2c"),
                    }}
                >
                    <CustomAvatar
                        height={props.size === "sm" ? "36px" : "48px"}
                        width={props.size === "sm" ? "36px" : "48px"}
                        noBorder
                        src={getImageURL(props.imageFilename)}
                        showPlaceholder={!props.imageFilename || props.imageFilename.toString().includes("no_avatar")}
                        placeholder={<Avatar size="full" />}
                    />
                    <div className="flex flex-col items-start mr-2">
                        <div className="flex">
                            <span
                                className="font-bold overflow-hidden whitespace-nowrap"
                                style={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px",
                                }}
                            >
                                {props.fullName}
                            </span>
                            {username === props.username && (
                                <Badge colorScheme="green" variant="subtle" className="mr-2">
                                    شما!
                                </Badge>
                            )}
                        </div>
                        {props.username && (
                            <Text
                                mb={0}
                                mt={-1}
                                color={useColorModeValue("gray.500", "gray.500")}
                                className="font-normal overflow-hidden whitespace-nowrap"
                                style={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "300px",
                                    direction: "ltr",
                                }}
                                fontSize="13px"
                                fontFamily="sans-serif"
                            >
                                @{props.username}
                            </Text>
                        )}
                    </div>
                </Box>
            ) : (
                <Link
                    href={props.username ? `/users/${props.username}` : "#"}
                    textDecor="none"
                    _hover={{
                        textDecoration: "none",
                    }}
                    sx={{
                        display: "block",
                        width: "min-content",
                    }}
                    isExternal={props.username ? true : false}
                >
                    <Box w="min" className="flex flex-row items-center justify-start">
                        <CustomAvatar
                            height={props.size === "sm" ? "36px" : "48px"}
                            width={props.size === "sm" ? "36px" : "48px"}
                            noBorder
                            src={getImageURL(props.imageFilename)}
                            showPlaceholder={!props.imageFilename || props.imageFilename.toString().includes("no_avatar")}
                            placeholder={<Avatar size="full" />}
                        />
                        <div className="flex flex-col items-start mr-2">
                            <div className="flex">
                                <span
                                    className="font-bold overflow-hidden whitespace-nowrap"
                                    style={{
                                        textOverflow: "ellipsis",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {props.fullName}
                                </span>
                                {username === props.username && (
                                    <Badge colorScheme="green" variant="subtle" className="mr-2">
                                        شما!
                                    </Badge>
                                )}
                            </div>
                            {props.username && (
                                <Text
                                    mb={0}
                                    mt={1}
                                    color={useColorModeValue("gray.500", "gray.500")}
                                    className="font-normal overflow-hidden whitespace-nowrap"
                                    style={{
                                        textOverflow: "ellipsis",
                                        maxWidth: "300px",
                                        direction: "ltr",
                                    }}
                                    fontSize="13px"
                                    fontFamily="sans-serif"
                                >
                                    @{props.username}
                                </Text>
                            )}
                        </div>
                    </Box>
                </Link>
            )}
        </Tooltip>
    );
};
