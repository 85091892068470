import React, { useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Collapse,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { default as NextImage } from "next/image";

import Style from "./settings.module.css";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

import { buildEvaluator } from "@util/api/problems";
import { NextRouter } from "next/router";
import { IToastOptions } from "@util/interfaces";

export const AdvancedEvaluationSettings = (props: {
    problemCodeSet: Array<{ gitlab_repo_url: string }>;
    evaluationFileUrl: string | null;
    sampleSubmissionFileUrl: string | null;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    problemPath: string;
    router: NextRouter;
    toast: IToastOptions;
}) => {
    const [evalModalOpen, setEvalModalOpen] = useState(false);

    return (
        <Box className={`flex flex-row justify-center px-8 ${Style.eval_form_container}`}>
            <VStack spacing={6} w="full">
                <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Gitlab Repo Url
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                    doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus
                                    suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <div className={`${Style.eval_gitlab_buttons_container} `}>
                        {props.problemCodeSet.map((el, index: number) => {
                            return (
                                <Button
                                    mx={2}
                                    size="sm"
                                    key={`repo_url_${index}`}
                                    onClick={() => {
                                        window.open(el.gitlab_repo_url);
                                    }}
                                    className={`${Style.gitlab_btn} mt-3`}
                                    leftIcon={<NextImage src="/icons/gitlab-icon.png" alt="Gitlab Colab Logo" width={16} height={16} />}
                                >
                                    <span className="mr-2">انتقال به Gitlab چالش</span>
                                </Button>
                            );
                        })}
                        <Button
                            mx={2}
                            size="sm"
                            key="eval-build-btn"
                            className={`${Style.gitlab_btn} mt-3`}
                            onClick={() => {
                                setEvalModalOpen(true);
                            }}
                        >
                            بیلد گرفتن از ارزیاب
                        </Button>
                    </div>
                </FormControl>

                {/* <Box className={`flex flex-row justify-end w-full`}>
                    <Button
                        isDisabled={loading || disabled}
                        ml={4}
                        colorScheme={"red"}
                        onClick={() => {
                            onReset();
                        }}
                        size="sm"
                    >
                        بازنشانی
                    </Button>
                    <Button
                        isDisabled={disabled}
                        isLoading={loading}
                        size="sm"
                        colorScheme={useColorModeValue(
                            "RoboEpics.dark",
                            "RoboEpics.gold",
                        )}
                        bg={useColorModeValue(
                            "RoboEpics.dark.500",
                            "RoboEpics.gold.500",
                        )}
                        onClick={() => {
                            // onConfirm();
                        }}
                    >
                        ذخیره و اعمال تغییرات
                    </Button>
                </Box> */}
            </VStack>
            <EvaluatorBuildModal
                isOpen={evalModalOpen}
                onClose={() => setEvalModalOpen(false)}
                problem={props.problemPath}
                router={props.router}
                toast={props.toast}
            />
        </Box>
    );
};
const EvaluatorBuildModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    problem: string;
    router: NextRouter;
    toast: IToastOptions;
}) => {
    const [hashCommit, setHashCommit] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "error" | "success" | "warning";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const onConfirm = async () => {
        setLoading(true);

        const serverResponse = (await buildEvaluator(props.problem, hashCommit, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setAlertProps({
                open: true,
                status: "success",
                message: "بیلد ارزیاب با موفقیت انجام شد.",
            });
        } else {
            setAlertProps({
                open: true,
                message:
                    "مشکلی در بیلد ارزیاب رخ داد " +
                    `${serverResponse.statusText} (${serverResponse.status}):` +
                    (await serverResponse.json()) +
                    "لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                status: "warning",
            });
        }

        setLoading(false);
    };
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setHashCommit("");
                setLoading(false);
                setDisabled(false);
                setAlertProps({
                    open: false,
                    message: "این یک آلرت است",
                    status: "info",
                });
            }}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>بیلد ارزیاب</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Collapse in={alertProps.open}>
                        <Alert status={alertProps.status} variant={"subtle"} className="mb-4">
                            <AlertIcon />
                            <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                        </Alert>
                    </Collapse>
                    <FormControl>
                        <FormLabel fontSize="14px">هش کامیت</FormLabel>
                        <Input value={hashCommit} onChange={(e) => setHashCommit(e.target.value)} id="hash-commit-input" />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        isLoading={loading}
                        isDisabled={!hashCommit || disabled}
                        onClick={() => {
                            onConfirm();
                        }}
                    >
                        آغاز بیلد
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
