import React, { useState, useEffect, useRef } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Spinner,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";

import { getAllProblems, searchProblemByTitle } from "@util/api/problems";
import { SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { X } from "react-feather";
import { createPhase } from "@util/api/competitions";
import { RoboEpicsDatePicker, convertServer2DatePicker, convertDatePicker2Json } from "@components";
import styles from "./CompSidebar.module.css";
import { IProblemBrief, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const NewPhaseModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    competiton: string;
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [values, setValues] = useState<{
        title: string;
        description?: string;
        submission_date_start?: string;
        submission_date_end?: string;
    }>({
        title: "",
        description: "",
        submission_date_start: "",
        submission_date_end: "",
    });
    const [problems, setProblems] = useState<IProblemBrief[]>([]);

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const onCreatePhase = async () => {
        setIsLoading(true);
        const data = {};
        Object.keys(values).forEach((value) => {
            if (values[value] !== "") {
                data[value] = values[value];
            }
        });
        const serverResponse = (await createPhase(props.competiton, data, { toast: props.toast })) as Response;
        if (serverResponse.ok) {
            props.setAddConfirmed(true);
            setDisabled(true);
            setValues({
                title: "",
                description: "",
                submission_date_start: "",
                submission_date_end: "",
            });
            props.toast({
                description: "فاز جدید با موفقیت به مسابقه اضافه شد.",
                duration: 3000,
                isClosable: true,
                status: "success",
                variant: "subtle",
            });
            props.onClose();
        } else {
            props.toast({
                title: "مشکلی در اضافه کردن فاز جدید رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setDisabled(false);
    }, []);
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setValues({
                    title: "",
                    description: "",
                    submission_date_start: "",
                    submission_date_end: "",
                });
                setDisabled(false);
            }}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>اضافه کردن فاز به مسابقه</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        <FormControl isRequired>
                            <FormLabel fontSize="14px">عنوان فاز</FormLabel>
                            <Input variant="filled" value={values.title} onChange={handleChange("title")} id="new-phase-title" />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel fontSize="14px">بازه زمانی ثبت پاسخ برای سوال‌های فاز</FormLabel>
                            <Box className={`flex flex-row w-full items-end ${styles.datePicker_container}`}>
                                <FormControl>
                                    <RoboEpicsDatePicker
                                        type="day"
                                        initDayValue={convertServer2DatePicker(values.submission_date_start, "fa")?.date}
                                        initTimeValue={convertServer2DatePicker(values.submission_date_start, "fa")?.time}
                                        setPropDate={(day, time, locale) => {
                                            setValues((current) => {
                                                return {
                                                    ...current,
                                                    submission_date_start: convertDatePicker2Json(day, time, locale),
                                                };
                                            });
                                        }}
                                        placeholder="آغاز مرحله"
                                        placement="bottom"
                                    />
                                </FormControl>
                                <Text className={`mx-8 my-2 ${styles.ta_text}`} textAlign="center">
                                    تا
                                </Text>
                                <FormControl>
                                    <RadioGroup
                                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                        value={values.submission_date_end ? "limited" : "unlimited"}
                                        mb={2}
                                        onChange={(val) => {
                                            if (val === "unlimited") {
                                                setValues((current) => {
                                                    return {
                                                        ...current,
                                                        submission_date_end: null,
                                                    };
                                                });
                                            } else {
                                                setValues((current) => {
                                                    return {
                                                        ...current,
                                                        submission_date_end: new Date().toISOString().slice(0, 19) + "+03:30",
                                                    };
                                                });
                                            }
                                        }}
                                    >
                                        <HStack>
                                            <Radio value="unlimited">پایان باز</Radio>
                                            <Radio value="limited"> تعیین زمان</Radio>
                                        </HStack>
                                    </RadioGroup>
                                    <RoboEpicsDatePicker
                                        type="day"
                                        initDayValue={convertServer2DatePicker(values.submission_date_end, "fa")?.date}
                                        initTimeValue={convertServer2DatePicker(values.submission_date_end, "fa")?.time}
                                        isDisabled={!values.submission_date_end}
                                        setPropDate={(day, time, locale) => {
                                            setValues((current) => {
                                                return {
                                                    ...current,
                                                    submission_date_end: convertDatePicker2Json(day, time, locale),
                                                };
                                            });
                                        }}
                                        placeholder="پایان مرحله"
                                        placement="bottom"
                                    />
                                </FormControl>
                            </Box>
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="14px">توضیحات فاز</FormLabel>
                            <Input
                                variant="filled"
                                value={values.description}
                                onChange={handleChange("description")}
                                id="new-phase-description"
                            />
                        </FormControl>
                        {problems.length > 0 && (
                            <FormControl>
                                <Accordion allowToggle>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="right">
                                                گزینه‌های پیشرفته
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <VStack spacing={2}>
                                                <FormControl>
                                                    <FormLabel fontSize="14px">اضافه کردن چالش به فاز</FormLabel>
                                                    <VStack spacing={4} pr={4}>
                                                        <ProblemSelectFormControl
                                                            setChosenProblem={setProblems}
                                                            toast={props.toast}
                                                            router={props.router}
                                                        />
                                                        <ProblemSearchFormControl
                                                            setChosenProblem={setProblems}
                                                            toast={props.toast}
                                                            router={props.router}
                                                        />
                                                    </VStack>
                                                </FormControl>
                                            </VStack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </FormControl>
                        )}
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            isDisabled={values.title.length === 0 || values.submission_date_start?.length === 0 || disabled}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            mr={1}
                            my={6}
                            px={10}
                            placeSelf={"flex-start"}
                            onClick={onCreatePhase}
                            alignSelf={"flex-end"}
                        >
                            ایجاد فاز
                        </Button>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
const ProblemSearchFormControl = (props: {
    setChosenProblem: React.Dispatch<React.SetStateAction<IProblemBrief[]>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const inputDelayTimer = useRef(null);
    const searchInputEl = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchResult, setSearchResult] = useState<{
        results: Array<IProblemBrief>;
        total: number;
    }>({ results: [], total: 0 });
    const onProblemSearchInputChange = async (input: string) => {
        setSearchInput(input);
        setIsLoading(false);
        setShowSearchResult(false);
        clearTimeout(inputDelayTimer.current);
        if (input.length) {
            inputDelayTimer.current = setTimeout(async () => {
                problemSearch(input);
            }, 500);
        } else {
            setSearchResult({ results: [], total: 0 });
        }
    };
    const problemSearch = async (input: string) => {
        setIsLoading(true);
        const serverResponse = (await searchProblemByTitle(
            { title: input },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const sr = await serverResponse.json();
            setSearchResult(sr);
            setShowSearchResult(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            <FormControl>
                <FormLabel fontSize="12px">چالش مربوط به نوت‌بوک</FormLabel>
                <InputGroup
                    sx={{
                        boxShadow: showSearchResult ? "0px -5px 10px -5px rgba(0,0,0,0.2)" : "none",
                    }}
                >
                    <Input
                        ref={searchInputEl}
                        size="sm"
                        placeholder="جستجو بر اساس عنوان چالش..."
                        onChange={(e) => onProblemSearchInputChange(e.target.value)}
                        value={searchInput}
                    />
                    <InputLeftElement
                        sx={{
                            height: "32px",
                            width: "32px",
                            paddingRight: "10px",
                        }}
                    >
                        <IconButton
                            bg={"transparent"}
                            variant="unstyled"
                            icon={<SearchIcon color="gray" />}
                            aria-label="search-teams"
                            pointerEvents="none"
                            height="32px"
                            width="32px"
                        />
                    </InputLeftElement>
                    <InputRightElement
                        sx={{
                            height: "32px",
                            width: "32px",
                            paddingLeft: "6px",
                        }}
                        children={
                            isLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                searchInput.length !== 0 && (
                                    <IconButton
                                        sx={{
                                            borderRadius: "999px",
                                            height: "20px",
                                            width: "20px",
                                            minWidth: "20px",
                                            padding: "2px",
                                        }}
                                        aria-label="Cancel search"
                                        onClick={() => {
                                            setSearchResult({
                                                results: [],
                                                total: 0,
                                            });
                                            setSearchInput("");
                                            setShowSearchResult(false);
                                            searchInputEl.current.focus();
                                        }}
                                        icon={<X size={18} />}
                                    />
                                )
                            )
                        }
                    />
                </InputGroup>

                {showSearchResult && (
                    <Box
                        className="py-2 px-2 overflow-y-auto border border-t-0 rounded-lg rounded-t-none"
                        sx={{
                            position: "absolute",
                            zIndex: "50",
                            top: "100%",
                            left: "0",
                            right: "0",
                            width: "100%",
                            maxHeight: "300px",
                            transition: "height 0.3s",
                            boxShadow: "0px 15px 20px 2px rgba(0,0,0,0.2)",
                        }}
                        bg={useColorModeValue("#F7F7F8", "#14181d")}
                    >
                        {searchResult.results.length !== 0 ? (
                            searchResult.results.map((result, index) => {
                                return (
                                    <Box
                                        key={`.${index}`}
                                        className={`flex flex-col py-2 px-2 my-1 cursor-pointer  ${
                                            index !== searchResult.results.length - 1 && "border-b"
                                        }`}
                                        _hover={{
                                            bgColor: useColorModeValue("gray.200", "RoboEpics.dark.600"),
                                        }}
                                        onClick={() => {
                                            props.setChosenProblem((current) => {
                                                return [...current, result];
                                            });
                                            setShowSearchResult(false);
                                        }}
                                    >
                                        <Text isTruncated>{result.title}</Text>
                                    </Box>
                                );
                            })
                        ) : (
                            <Text m={0} py={1} fontSize="13px" color={"gray.400"}>
                                نتیجه‌ای پیدا نشد!
                            </Text>
                        )}
                    </Box>
                )}
            </FormControl>
        </>
    );
};
const ProblemSelectFormControl = (props: {
    setChosenProblem: React.Dispatch<React.SetStateAction<IProblemBrief[]>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [problems, setProblems] = useState<{
        results: Array<IProblemBrief>;
        total: number;
    }>({ results: [], total: 0 });
    const _setProblems = async () => {
        const serverResponse = (await getAllProblems({ toast: props.toast, requesterPath: props.router.asPath })) as Response;
        if (serverResponse.ok) {
            const problems = await serverResponse.json();
            setProblems(problems);
        }
    };
    useEffect(() => {
        _setProblems();
    }, []);
    return (
        <>
            <FormControl>
                <FormLabel fontSize="12px">انتخاب از بین تمام چالش‌ها</FormLabel>
                <Menu placement="bottom-start">
                    <MenuButton as={Button} isDisabled={!problems.total} textAlign="right" rightIcon={<ChevronDownIcon />} width="55%">
                        {"انتخاب چالش"}
                    </MenuButton>
                    <MenuList
                        bg={useColorModeValue("#fff", "RoboEpics.black.900")}
                        zIndex={3}
                        w={"40%"}
                        maxH={"350px"}
                        overflowY={"scroll"}
                    >
                        {problems.results.map((problem, index) => {
                            return (
                                <>
                                    <MenuItem
                                        value={`${index}`}
                                        key={`problem-${problem.id}`}
                                        minH={8}
                                        overflowX="auto"
                                        overflowY="hidden"
                                        onClick={(e) => {
                                            props.setChosenProblem((current) => {
                                                return [...current, problems.results[e.currentTarget.value]];
                                            });
                                        }}
                                    >
                                        <Box className={`flex flex-col w-full pb-1`}>
                                            <Text isTruncated mb={1} fontWeight={"bold"} fontSize="15px">
                                                {problem.title}
                                            </Text>
                                            <Text isTruncated mb={0} fontWeight="light">
                                                {problem.short_description}
                                            </Text>
                                        </Box>
                                    </MenuItem>
                                    {index !== problems.total - 1 && <MenuDivider />}
                                </>
                            );
                        })}
                    </MenuList>
                </Menu>
            </FormControl>
        </>
    );
};
