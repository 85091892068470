import React, { useEffect, useState } from "react";
import { NextRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setColorMode } from "@redux/actions";
import {
    Box,
    useColorModeValue,
    useColorMode,
    Link as ChakraLink,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerContent,
    DrawerBody,
} from "@chakra-ui/react";
import { IconButton, Avatar, AvatarBadge, Img } from "@chakra-ui/react";
import { UserAvatar as UserAvatarMenu, Notifications } from "@components";
import { Sunny, Moon } from "react-ionicons";
import * as CookiesUtil from "@util/cookies";
import cssClasses from "./header.module.css";
import Link from "next/link";
import { Menu } from "react-feather";
import { IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";

export const Header2 = (props: { toast: IToastOptions; user: IUser; router: NextRouter }) => {
    const dispatch = useDispatch();
    const { colorMode, toggleColorMode } = useColorMode();
    const isLoggedIn = props.user.data.id && CookiesUtil.getCookie("re_auth_access_token");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        if (window) {
            window.onscroll = () => {
                const header = document.getElementById("landing_container");
                if (header) {
                    const sticky = header.offsetTop;

                    if (window.scrollY > sticky) {
                        document.getElementById("landing_container").style.paddingTop = "225px";
                        document.getElementById("myheader").classList.remove("relative");
                        document.getElementById("myheader").classList.remove("hover:opacity-100");
                        document.getElementById("myheader").classList.remove("opacity-50");
                        document.getElementById("myheader").classList.add("fixed");
                        document.getElementById("myheader").classList.add("opacity-100");
                        document.getElementById("myheader").classList.add("mt-0");
                        document.getElementById("myheader").classList.remove("mt-8");
                        document.getElementById("myheader").classList.remove("rounded-lg");
                        document.getElementById("myheader").classList.add("rounded-b-lg");
                        document.getElementById("myheader").classList.remove("transition-all");
                        document.getElementById("myheader").classList.add("transition-none");
                    } else {
                        document.getElementById("landing_container").style.paddingTop = "100px";
                        document.getElementById("myheader").classList.add("hover:opacity-100");
                        document.getElementById("myheader").classList.add("opacity-50");
                        document.getElementById("myheader").classList.remove("fixed");
                        document.getElementById("myheader").classList.add("relative");
                        document.getElementById("myheader").classList.remove("opacity-100");
                        document.getElementById("myheader").classList.add("mt-8");
                        document.getElementById("myheader").classList.remove("mt-0");
                        document.getElementById("myheader").classList.remove("rounded-b-lg");
                        document.getElementById("myheader").classList.add("rounded-lg");
                        document.getElementById("myheader").classList.remove("transition-none");
                        document.getElementById("myheader").classList.add("transition-all");
                    }
                }
            };
        }
    });

    return (
        <Box
            id={"myheader"}
            className={` ${cssClasses.header_main} relative shadow-xl mt-8 rounded-lg transition-all`}
            bg={useColorModeValue("#f7f7f8", "RoboEpics.black.900")}
            boxShadow={useColorModeValue(
                "0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
                "0 0 #ffff, 0 0 #ffff, 0 20px 25px -5px rgba(255, 255, 255, 0.1), 0 10px 10px -5px rgba(255, 255, 255, 0.04)",
            )}
        >
            <div
                className="flex h-full mx-auto flex-row justify-between items-center"
                style={{
                    maxWidth: "1120px",
                    width: "100%",
                }}
            >
                {/* Logo */}
                <div className={`${cssClasses.header_links_break}`}>
                    <IconButton
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        aria-label="open-drawer"
                        onClick={() => {
                            setIsDrawerOpen(true);
                        }}
                        icon={<Menu height="18px" width="18px" color={useColorModeValue("#000", "#fff")} />}
                    />
                </div>
                <Drawer
                    isOpen={isDrawerOpen}
                    placement="right"
                    onClose={() => {
                        setIsDrawerOpen(false);
                    }}
                >
                    <DrawerOverlay />
                    <DrawerContent className={`${cssClasses.drawer_content}`}>
                        <DrawerCloseButton />

                        <DrawerBody>
                            <div className={`flex flex-col justify-center ${cssClasses.drawer_links}`}>
                                {/* <Box
                                    mt={10}
                                    onClick={() => {
                                        router.push("/");
                                    }}
                                >
                                    <Img
                                        src="/images/logo-01.png"
                                        width={100}
                                        height={50}
                                        objectFit={"cover"}
                                        style={{
                                            filter: useColorModeValue(
                                                "invert(0)",
                                                "invert(1)",
                                            ),
                                            cursor: "pointer",
                                        }}
                                        mr={16}
                                    />
                                </Box> */}
                                <Link href="/c" passHref>
                                    <ChakraLink
                                        mr={16}
                                        mt={10}
                                        color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        مسابقه‌ها
                                    </ChakraLink>
                                </Link>
                                {/* <Link href="/notebooks" passHref>
                                    <ChakraLink
                                        mr={ 16}
                                        mt={8}
                                         color={useColorModeValue(
                                "RoboEpics.turquoise.500",
                                "RoboEpics.azure.500",
                            )}
                            _hover={{
                                color: useColorModeValue(
                                    "RoboEpics.turquoise.600",
                                    "RoboEpics.azure.600",
                                ),
                            }}
                                        fontWeight="semibold"
                                    >
                                        نوت‌بوک‌ها
                                        <sup
                                            className={`${cssClasses.sup}`}
                                            style={{
                                                color: useColorModeValue(
                                                    "white",
                                                    "black",
                                                ),
                                                backgroundColor: useColorModeValue(
                                                    "#03d0f9",
                                                    "#ffe562",
                                                ),
                                            }}
                                        >
                                            جدید!
                                        </sup>
                                    </ChakraLink>
                                </Link> */}
                                <Link href="/terms" passHref>
                                    <ChakraLink
                                        mr={16}
                                        mt={8}
                                        color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        قوانین و مقررات
                                    </ChakraLink>
                                </Link>
                                <Link href="/about" passHref>
                                    <ChakraLink
                                        mr={16}
                                        mt={8}
                                        color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        درباره ما
                                    </ChakraLink>
                                </Link>
                                <Link href="/contact" passHref>
                                    <ChakraLink
                                        mr={16}
                                        mt={8}
                                        color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        تماس با ما
                                    </ChakraLink>
                                </Link>
                            </div>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                <div className={`flex flex-row items-center ${cssClasses.header_links}`}>
                    <Box
                        margin="0"
                        onClick={() => {
                            props.router.push("/");
                        }}
                        className={cssClasses.logo_container}
                        _hover={{
                            scale: "1.1",
                        }}
                    >
                        <Img
                            src="/images/logo-01.png"
                            width={20}
                            height={50}
                            objectFit={"cover"}
                            style={{
                                filter: useColorModeValue("invert(0)", "invert(1)"),
                                cursor: "pointer",
                            }}
                            onError={() => {
                                console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                            }}
                        />
                    </Box>
                    <Link href="/c" passHref>
                        <ChakraLink
                            variant="unstyled"
                            mr={8}
                            color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                            }}
                            fontWeight="semibold"
                            bg="transparent"
                        >
                            مسابقه‌ها
                        </ChakraLink>
                    </Link>
                    {/* <Link href="/notebooks" passHref>
                        <ChakraLink
                            mr={8}
                             color={useColorModeValue(
                                "RoboEpics.turquoise.500",
                                "RoboEpics.azure.500",
                            )}
                            _hover={{
                                color: useColorModeValue(
                                    "RoboEpics.turquoise.600",
                                    "RoboEpics.azure.600",
                                ),
                            }}
                            fontWeight="semibold"
                        >
                            نوت‌بوک‌ها
                            <sup
                                className={`${cssClasses.sup}`}
                                style={{
                                    color: useColorModeValue("white", "black"),
                                    backgroundColor: useColorModeValue(
                                        "#03d0f9",
                                        "#ffe562",
                                    ),
                                }}
                            >
                                جدید!
                            </sup>
                        </ChakraLink>
                    </Link> */}
                    <Link href="/terms" passHref>
                        <ChakraLink
                            mr={8}
                            color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                            }}
                            fontWeight="semibold"
                        >
                            قوانین و مقررات
                        </ChakraLink>
                    </Link>
                    <Link href="/about" passHref>
                        <ChakraLink
                            mr={8}
                            color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                            }}
                            fontWeight="semibold"
                        >
                            درباره ما
                        </ChakraLink>
                    </Link>
                    <Link href="/contact" passHref>
                        <ChakraLink
                            mr={8}
                            color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                            }}
                            fontWeight="semibold"
                        >
                            تماس با ما
                        </ChakraLink>
                    </Link>
                </div>

                {/* Options */}
                <div className="flex justify-end items-center">
                    <IconButton
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        aria-label="گزینه ها"
                        onClick={() => {
                            dispatch(setColorMode(colorMode == "light" ? "dark" : "light"));
                            toggleColorMode();
                        }}
                        icon={useColorModeValue(<Moon height="18px" width="18px" />, <Sunny color="#fff" height="18px" width="18px" />)}
                    />

                    {isLoggedIn ? (
                        <>
                            <Notifications toast={props.toast} router={props.router} />
                            <UserAvatarMenu
                                user={props.user}
                                Button={
                                    <Avatar size="xs" src={props.user.data.imageUrl} className="mr-0">
                                        <AvatarBadge
                                            boxSize="1.25em"
                                            bg="green.500"
                                            style={{
                                                right: 0,
                                            }}
                                        />
                                    </Avatar>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Link href={`/users/signin?returnUrl=${props.router.asPath}`} passHref>
                                <ChakraLink
                                    mr={2}
                                    color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                    _hover={{
                                        color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                    }}
                                    fontWeight="semibold"
                                >
                                    ورود / ثبت‌نام
                                </ChakraLink>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </Box>
    );
};
