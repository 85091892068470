import React, { useEffect, useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    IconButton,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    SimpleGrid,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { ICompDataSettings, IToastOptions } from "@util/interfaces";
import { editCompetitionFormdata, editCompetitionJson } from "@util/api/competitions";
import { NextRouter } from "next/router";
import { RoboEpicsDatePicker, convertServer2DatePicker, convertDatePicker2Json, CustomAvatar } from "@components";
import CropModal, { b64toBlob } from "@util/CropModal";
import Style from "./compOwner.module.css";
import { AddOutline, Image as IonImage, ImageOutline, Trash } from "react-ionicons";
import { Edit2 } from "react-feather";
import { isValidUrl } from "@util/misc";
import { getImageURL } from "@util/mediaLoader";

export const CompSettings = (props: {
    settingsData: ICompDataSettings;
    competition: string;
    thumbnail: string | null;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    competitionStatus: 0 | 10 | 20 | 30 | 40;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [values, setValues] = useState<ICompDataSettings>(props.settingsData);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [validUrl, setValidUrl] = useState(true);

    const [thumbnailImage, setThumbnailImage] = useState(getImageURL(props.thumbnail));

    useEffect(() => {
        if (Object.keys(values).some((key) => values[key] !== props.settingsData[key])) setDisabled(false);
        if (Object.keys(values).every((key) => values[key] === props.settingsData[key])) setDisabled(true);
    }, [values]);

    const onConfirm = async () => {
        const data = {};
        Object.keys(values).forEach((element) => {
            if (values[element] !== props.settingsData[element]) {
                if (element === "title" && values[element] === "") {
                    data[element] = props.settingsData.title;
                    setValues((current) => {
                        return { ...current, title: props.settingsData.title };
                    });
                } else if (element === "subtitle" && values[element] === "") {
                    setValues((current) => {
                        data[element] = props.settingsData.subtitle;
                        return {
                            ...current,
                            subtitle: props.settingsData.subtitle,
                        };
                    });
                } else if (element === "discussion_url" && values[element] === "") {
                    setValues((current) => {
                        data[element] = props.settingsData.discussion_url;
                        return {
                            ...current,
                            discussion_url: props.settingsData.discussion_url,
                        };
                    });
                } else {
                    data[element] = values[element];
                }
            }
        });
        const serverResponse = (await editCompetitionJson(props.router.query.competition, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
            if (data["path"]) {
                props.router.push(`/c/${data["path"]}/settings`);
            }
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setValues(props.settingsData);
        }
        setLoading(false);
    };

    return (
        <Box className={`flex flex-col w-full h-full px-4 py-4 justify-start`}>
            <VStack spacing={10}>
                <FormControl w="75%" alignSelf="flex-start">
                    <FormLabel fontSize="xl">اطلاعات کلی مسابقه</FormLabel>
                    <Box className="flex flex-row justify-start self-start items-start">
                        <ThumbnailImageChange
                            competition={props.router.query.competition}
                            router={props.router}
                            setThumbnailImage={setThumbnailImage}
                            thumbnailImage={thumbnailImage}
                            toast={props.toast}
                        />
                        <FormControl mr={2}>
                            <FormLabel fontSize="sm">عنوان</FormLabel>
                            <Input
                                fontWeight="800"
                                variant="filled"
                                className="mb-0 mt-1"
                                value={values.title}
                                onChange={(e) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            title: e.target.value,
                                        };
                                    });
                                }}
                                maxLength={70}
                                placeholder="عنوان"
                            />
                            <FormHelperText textAlign="left">{`${values.title.length}/70`}</FormHelperText>
                        </FormControl>
                    </Box>
                    <FormControl mt={4} mr={2}>
                        <FormLabel fontSize="sm">زیرعنوان</FormLabel>
                        <Input
                            variant="filled"
                            className="mt-1 mb-0"
                            value={values.subtitle}
                            onChange={(e) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        subtitle: e.target.value,
                                    };
                                });
                            }}
                            maxLength={200}
                            placeholder="زیرعنوان"
                        />
                        <FormHelperText textAlign="left">{`${values.subtitle.length}/200`}</FormHelperText>
                    </FormControl>
                    <FormControl mt={4} mr={2} isDisabled={props.competitionStatus !== 0}>
                        <FormLabel fontSize="sm">جایزه</FormLabel>
                        <Input
                            variant="filled"
                            className="mt-1 mb-0"
                            value={values.prize ? values.prize : ""}
                            onChange={(e) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        prize: e.target.value,
                                    };
                                });
                            }}
                            maxLength={50}
                            placeholder="جایزه"
                        />
                        <FormHelperText textAlign="left">{`${values.prize ? values.prize.length : 0}/50`}</FormHelperText>
                    </FormControl>
                    <FormControl mt={4} mr={2} isDisabled={props.competitionStatus !== 0}>
                        <FormLabel fontSize="sm">مسیر اختصاصی</FormLabel>
                        <Input
                            variant="filled"
                            className="mt-1 mb-0"
                            value={values.path ? values.path : ""}
                            onChange={(e) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        path: e.target.value,
                                    };
                                });
                            }}
                            maxLength={50}
                            placeholder="مسیر اختصاصی"
                        />
                        <FormHelperText textAlign="left">{`${values.path ? values.path.length : 0}/255`}</FormHelperText>
                    </FormControl>
                    <FormControl mt={4} mr={2} isInvalid={!validUrl}>
                        <FormLabel fontSize="sm">لینک گفت‌وگو</FormLabel>
                        <Input
                            variant="filled"
                            className="mt-1 mb-0"
                            dir="ltr"
                            value={values.discussion_url ? values.discussion_url : "https://"}
                            isInvalid={!validUrl}
                            onChange={(e) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        discussion_url: e.target.value,
                                    };
                                });
                                if (values.discussion_url) {
                                    setValidUrl(isValidUrl(e.target.value));
                                }
                            }}
                            placeholder="example.com"
                        />
                        <FormErrorMessage>لینک وارد شده نامعتبر است.</FormErrorMessage>
                    </FormControl>
                </FormControl>
                <FormControl isDisabled={props.competitionStatus !== 0}>
                    <FormLabel fontSize="xl">نوع مسابقه</FormLabel>
                    <RadioGroup
                        value={values.access_level}
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        onChange={(value) => {
                            switch (value) {
                                case "10":
                                    setValues((current) => {
                                        return { ...current, access_level: 10 };
                                    });
                                    break;
                                case "20":
                                    setValues((current) => {
                                        return { ...current, access_level: 20 };
                                    });
                                    break;
                                case "40":
                                    setValues((current) => {
                                        return { ...current, access_level: 40 };
                                    });
                                    break;
                                case "50":
                                    setValues((current) => {
                                        return { ...current, access_level: 50 };
                                    });
                                    break;
                                default:
                                    setValues((current) => {
                                        return current;
                                    });
                                    break;
                            }
                        }}
                    >
                        <SimpleGrid minChildWidth={"190px"} spacingX={8} spacingY={6}>
                            <Radio value={10}>عمومی</Radio>
                            <Radio value={20}>خصوصی</Radio>
                        </SimpleGrid>
                    </RadioGroup>
                </FormControl>
                <SimpleGrid spacingY={6} spacingX={8} minChildWidth={"300px"} w="full">
                    <FormControl isDisabled={props.competitionStatus !== 0}>
                        <FormLabel fontSize="xl">نحوه شرکت</FormLabel>
                        <RadioGroup
                            value={values.participation_type}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            onChange={(value) => {
                                switch (value) {
                                    case "10":
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                participation_type: 10,
                                            };
                                        });
                                        break;
                                    case "30":
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                participation_type: 30,
                                            };
                                        });
                                        break;
                                    default:
                                        setValues((current) => {
                                            return current;
                                        });
                                        break;
                                }
                            }}
                        >
                            <SimpleGrid minChildWidth={"120px"} spacingX={8} spacingY={6}>
                                <Radio value={10}>انفرادی</Radio>
                                <Radio value={30}>تیمی و انفرادی</Radio>
                            </SimpleGrid>
                        </RadioGroup>
                    </FormControl>
                    <FormControl isDisabled={values.participation_type === 10 || props.competitionStatus !== 0}>
                        <FormLabel fontSize="xl">حداکثر تعداد اعضای تیم شرکت‌کننده</FormLabel>
                        <RadioGroup
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            value={values.team_member_limit ? "limited" : "unlimited"}
                            mb={4}
                            onChange={(val) => {
                                if (val === "unlimited") {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            team_member_limit: null,
                                        };
                                    });
                                } else {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            team_member_limit: values.team_member_limit ? values.team_member_limit : 2,
                                        };
                                    });
                                }
                            }}
                        >
                            <HStack>
                                <Radio value="unlimited">بدون محدودیت</Radio>
                                <Radio value="limited"> با محدودیت</Radio>
                            </HStack>
                        </RadioGroup>
                        <NumberInput
                            maxW={"300px"}
                            value={values.team_member_limit ? values.team_member_limit : 2}
                            isDisabled={!values.team_member_limit || values.participation_type === 10}
                            min={2}
                            onChange={(val) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        team_member_limit: parseInt(val),
                                    };
                                });
                            }}
                            size="sm"
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </SimpleGrid>
                <SimpleGrid spacingY={6} spacingX={8} minChildWidth={"300px"} w="full">
                    <FormControl>
                        <FormLabel fontSize="xl">حداکثر تعداد شرکت‌کنندگان</FormLabel>
                        <RadioGroup
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            value={values.participant_limit ? "limited" : "unlimited"}
                            mb={4}
                            onChange={(val) => {
                                if (val === "unlimited") {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            participant_limit: null,
                                        };
                                    });
                                } else {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            participant_limit: values.participant_limit ? values.participant_limit : 1,
                                        };
                                    });
                                }
                            }}
                        >
                            <HStack>
                                <Radio value="unlimited">بدون محدودیت</Radio>
                                <Radio value="limited"> با محدودیت</Radio>
                            </HStack>
                        </RadioGroup>
                        <NumberInput
                            maxW={"300px"}
                            value={values.participant_limit ? values.participant_limit : 1}
                            isDisabled={!values.participant_limit}
                            min={1}
                            onChange={(val) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        participant_limit: parseInt(val),
                                    };
                                });
                            }}
                            size="sm"
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="xl">حداکثر تعداد ثبت پاسخ در روز</FormLabel>
                        <RadioGroup
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            value={values.daily_submission_limit ? "limited" : "unlimited"}
                            mb={4}
                            onChange={(val) => {
                                if (val === "unlimited") {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            daily_submission_limit: null,
                                        };
                                    });
                                } else {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            daily_submission_limit: values.daily_submission_limit ? values.daily_submission_limit : 1,
                                        };
                                    });
                                }
                            }}
                        >
                            <HStack>
                                <Radio value="unlimited">بدون محدودیت</Radio>
                                <Radio value="limited"> با محدودیت</Radio>
                            </HStack>
                        </RadioGroup>
                        <NumberInput
                            maxW={"300px"}
                            value={values.daily_submission_limit ? values.daily_submission_limit : 1}
                            min={1}
                            isDisabled={!values.daily_submission_limit}
                            onChange={(val) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        daily_submission_limit: parseInt(val),
                                    };
                                });
                            }}
                            size="sm"
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </SimpleGrid>
                <FormControl>
                    <FormLabel fontSize="xl">بازه زمانی ثبت‌نام</FormLabel>
                    <Box className={`flex flex-row items-end w-max ${Style.datePicker_container}`}>
                        <FormControl>
                            <RoboEpicsDatePicker
                                type="day"
                                initDayValue={convertServer2DatePicker(values.register_date_start, "fa")?.date}
                                initTimeValue={convertServer2DatePicker(values.register_date_start, "fa")?.time}
                                setPropDate={(day, time, locale) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            register_date_start: convertDatePicker2Json(day, time, locale),
                                        };
                                    });
                                }}
                                placeholder="آغاز ثبت‌نام"
                            />
                        </FormControl>
                        <Text className={`my-2 mx-6 ${Style.ta_text}`} textAlign="center">
                            تا
                        </Text>
                        <FormControl>
                            <RadioGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                value={values.register_date_end ? "limited" : "unlimited"}
                                mb={4}
                                onChange={(val) => {
                                    if (val === "unlimited") {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                register_date_end: null,
                                            };
                                        });
                                    } else {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                register_date_end: props.settingsData.register_date_end
                                                    ? props.settingsData.register_date_end
                                                    : new Date().toISOString().slice(0, 19) + "+03:30",
                                            };
                                        });
                                    }
                                }}
                            >
                                <HStack>
                                    <Radio value="unlimited">پایان باز</Radio>
                                    <Radio value="limited"> تعیین زمان</Radio>
                                </HStack>
                            </RadioGroup>
                            <RoboEpicsDatePicker
                                type="day"
                                initDayValue={convertServer2DatePicker(values.register_date_end, "fa")?.date}
                                initTimeValue={convertServer2DatePicker(values.register_date_end, "fa")?.time}
                                isDisabled={!values.register_date_end}
                                setPropDate={(day, time, locale) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            register_date_end: convertDatePicker2Json(day, time, locale),
                                        };
                                    });
                                }}
                                placeholder="پایان ثبت‌نام"
                            />
                        </FormControl>
                    </Box>
                </FormControl>
                <Box className={`w-full flex flex-row justify-end`}>
                    <Button
                        colorScheme={"red"}
                        variant="solid"
                        className="mr-2"
                        isDisabled={disabled || loading}
                        onClick={() => {
                            setValues(props.settingsData);
                        }}
                    >
                        بازنشانی
                    </Button>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        variant="solid"
                        className="mr-2"
                        isLoading={loading}
                        isDisabled={disabled}
                        onClick={() => {
                            setLoading(true);
                            onConfirm();
                        }}
                    >
                        تایید و اعمال تغییرات
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
};
const ThumbnailImageChange = (props: {
    toast: IToastOptions;
    thumbnailImage: string;
    setThumbnailImage: React.Dispatch<React.SetStateAction<string>>;
    router: NextRouter;
    competition: string | string[];
}) => {
    const inputRef = useRef(null);
    const [tmpThumbnailImage, setTmpThumbnailImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef(null);

    const checkFileAttributes = (file: File) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };

    const onChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // const file = acceptedFile?.[0];
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                isClosable: true,
                variant: "subtle",
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 9000,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpThumbnailImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };
    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpThumbnailImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        props.setThumbnailImage(canvas.toDataURL("image/jpeg"));
        setEditAllowed(true);
    };
    const compileFormData = (thumbnailImage: string) => {
        const form_data = new FormData();

        if (thumbnailImage) {
            const block = thumbnailImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("thumbnail", blob, `problem_${props.competition}_thumbnail.png`);
            }
        } else if (thumbnailImage === null) {
            form_data.append("thumbnail", "");
        }
        return form_data;
    };
    const editThumbnailImage = async (thumbnailImage: string) => {
        const req_data = compileFormData(thumbnailImage);
        const serverResponse = (await editCompetitionFormdata(props.competition, req_data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setThumbnailImage(thumbnailImage);
            setDeleteAlertVisible(false);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setTmpThumbnailImage(props.thumbnailImage);
        }

        setEditAllowed(false);
        setDeleteLoading(false);
    };
    useEffect(() => {
        if (editAllowed) editThumbnailImage(props.thumbnailImage);
    }, [props.thumbnailImage]);
    return (
        <Box className="grid">
            <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
            <CropModal
                modalTitle={"تصویر ناخنک مسابقه"}
                src={tmpThumbnailImage}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={(data) => {
                    crop(data);
                    setIsModalOpen(false);
                }}
                aspectX={1}
                aspectY={1}
            />
            <div className={`${Style.settings_thumbnailPictureDarkHover}`}>
                <IconButton
                    size="xs"
                    bg={"transparent"}
                    aria-label="new profile picture"
                    icon={props.thumbnailImage ? <Edit2 color="#fff" size="20" /> : <AddOutline color="#fff" height="21px" width="21px" />}
                    _hover={{
                        bg: "blackAlpha.800",
                    }}
                    onClick={() => {
                        inputRef.current.click();
                    }}
                />
                {props.thumbnailImage && (
                    <IconButton
                        className="mr-0.5"
                        size="xs"
                        bg={"transparent"}
                        aria-label="remove profile picture"
                        icon={<Trash color="#c53030" height="19px" width="19px" />}
                        _hover={{
                            bg: "blackAlpha.800",
                        }}
                        onClick={() => setDeleteAlertVisible(true)}
                    />
                )}
            </div>
            <CustomAvatar
                width={"5rem"}
                height={"5rem"}
                showPlaceholder={props.thumbnailImage === null}
                placeholder={useColorModeValue(<ImageOutline color={"black"} />, <IonImage color={"white"} />)}
                shiftToTop={false}
                src={props.thumbnailImage}
            />
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                    setTmpThumbnailImage(props.thumbnailImage);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        با کلیک روی دکمه "تایید" تصویر چالش حذف خواهد شد و برای تعیین تصویر جدید باید فایل عکس را آپلود کنید.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                editThumbnailImage(null);
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
};
