import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react";
import { AddProblemToPhase, problemCreate } from "@util/api/problems";
import { NextRouter, useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import styles from "./CompSidebar.module.css";
import { IToastOptions } from "@util/interfaces";

export const NewSingleProblemModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    data: { phaseId: number; competition: string | null };
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const router = useRouter();

    const [isGreaterThan550px] = useMediaQuery("(min-width:550px");

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [values, setValues] = useState({
        title: "",
        subtitle: "",
        path: "",
        code_execution: false,
        repository_mode: 10,
        evaluation_mode: 10,
        is_output_presentable: false,
    });
    const [redirect, setRedirect] = useState(false);
    const handleChange = (prop: string) => (event) => {
        if (prop === "code_execution" || prop === "repository_mode" || prop === "evaluation_mode" || prop === "is_output_presentable") {
            switch (prop) {
                case "is_output_presentable":
                case "code_execution":
                    setValues({ ...values, [prop]: event.target.checked });
                    break;
                case "evaluation_mode":
                    switch (event.target.value) {
                        case "evaluation_mode_second":
                            setValues({ ...values, [prop]: event.target.checked ? 30 : 20 });
                            break;
                        case "evaluation_mode_first":
                            setValues({ ...values, [prop]: event.target.checked ? 20 : 10 });
                            break;
                    }
                    break;
                case "repository_mode":
                    switch (event.target.value) {
                        case "repository_mode_second":
                            setValues({ ...values, [prop]: event.target.checked ? 30 : 20 });
                            break;
                        case "repository_mode_first":
                            setValues({ ...values, [prop]: event.target.checked ? 20 : 10 });
                            break;
                    }
                    break;
            }
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const steps = [
        {
            label: "اطلاعات",
            content: (
                <VStack spacing={4} mt={4}>
                    <FormControl isRequired>
                        <FormLabel fontSize={"14px"}>عنوان چالش</FormLabel>
                        <Input variant={"filled"} value={values.title} onChange={handleChange("title")} id={"new_problem_title"} />
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize={"14px"}>توضیح مختصری از چالش</FormLabel>
                        <Input variant={"filled"} value={values.subtitle} onChange={handleChange("subtitle")} id={"new_problem_subtitle"} />
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize={"14px"}>مسیر اختصاصی چالش</FormLabel>
                        <InputGroup>
                            <Input
                                variant={"filled"}
                                value={values.path}
                                onChange={handleChange("path")}
                                id={"new_problem_path"}
                                dir="ltr"
                            />
                            {isGreaterThan550px && (
                                <InputRightAddon
                                    children={`roboepics.com/c/${props.data.competition}/p/${props.data.phaseId}/p/`}
                                    className={`${styles.input_addon}`}
                                    dir="ltr"
                                />
                            )}
                        </InputGroup>
                        {values.path && (
                            <FormHelperText className={`${styles.path_form_helper}`}>
                                <Text>
                                    مسیر اختصاصی مسابقه شما به این صورت در مرورگر نمایش داده خواهد شد:{" "}
                                    {`roboepics.com/c/${props.data.competition}/p/${props.data.phaseId}/p/${values.path}`}
                                </Text>
                            </FormHelperText>
                        )}
                    </FormControl>
                </VStack>
            ),
        },
        {
            label: "تنظیمات",
            content: (
                <VStack spacing={4} mt={4}>
                    <FormControl>
                        <FormLabel fontSize={"14px"}>Repository Mode</FormLabel>
                        <VStack spacing={2} alignItems="flex-start">
                            <Text fontWeight={200} fontSize="sm">
                                در صورت فعال کردن هر کدام از گزینه‌های زیر، حل‌کنندگان چالش باید در کنار جواب، کدی که برای به دست آوردن جواب
                                نوشته‌اند را نیز آپلود کنند.
                            </Text>
                            <Checkbox
                                isChecked={values.repository_mode !== 10}
                                isDisabled={disabled || loading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                onChange={handleChange("repository_mode")}
                                value="repository_mode_first"
                            >
                                ساختن مخزن گیت (Git repository) برای حل‌کنندگان چالش
                            </Checkbox>
                            <Checkbox
                                isChecked={values.repository_mode === 30}
                                isDisabled={values.repository_mode === 10 || disabled || loading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                onChange={handleChange("repository_mode")}
                                value="repository_mode_second"
                            >
                                فعال کردن قابلیت ساخت نوت‌بوک برای حل‌کنندگان چالش
                            </Checkbox>
                        </VStack>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize={"14px"}>Evaluation Mode</FormLabel>
                        <VStack spacing={2} alignItems="flex-start">
                            <Text fontWeight={200} fontSize="sm">
                                در صورت فعال کردن هر کدام از گزینه‌های زیر، طراح چالش باید شیوه ارزیابی را تعریف کند.
                            </Text>
                            <Checkbox
                                isChecked={values.evaluation_mode !== 10}
                                isDisabled={disabled || loading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                onChange={handleChange("evaluation_mode")}
                                value="evaluation_mode_first"
                            >
                                فعال کردن قابلیت ارزیابی پاسخ‌ها از طریق پلتفرم
                            </Checkbox>
                            <Checkbox
                                isChecked={values.evaluation_mode === 30}
                                isDisabled={values.evaluation_mode === 10 || disabled || loading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                onChange={handleChange("evaluation_mode")}
                                value="evaluation_mode_second"
                            >
                                ارزیابی پاسخ‌ها به محض دریافت پاسخ
                            </Checkbox>
                        </VStack>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize={"14px"}>Code Executuion</FormLabel>
                        <Checkbox
                            isChecked={values.code_execution}
                            isDisabled={values.repository_mode === 10 || values.evaluation_mode === 10 || disabled || loading}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            onChange={handleChange("code_execution")}
                        >
                            هنگام ارزیابی پاسخ‌ها، کد فرستاده شده توسط حل‌کننده سوال، اجرا خواهد شد.
                        </Checkbox>
                    </FormControl>
                    <Divider />
                    <FormControl>
                        <FormLabel fontSize={"14px"}>فایل خروجی</FormLabel>

                        <Checkbox
                            isChecked={values.is_output_presentable}
                            isDisabled={disabled || loading}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            onChange={handleChange("is_output_presentable")}
                        >
                            در صورت فعال کردن این گزینه، پاسخ ثبت شده توسط حل‌کننده در صفحه قابل نمایش خواهد بود
                        </Checkbox>
                    </FormControl>
                    <Divider />
                    <FormControl>
                        <Checkbox
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            isDisabled={disabled}
                            isChecked={redirect}
                            onChange={(e) => {
                                setRedirect(e.target.checked);
                            }}
                        >
                            انتقال به صفحه چالش پس از ساخته شدن چالش
                        </Checkbox>
                    </FormControl>
                </VStack>
            ),
        },
    ];
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    const errorParser = (result: { path?: Array<string>; title?: Array<string>; subtitle?: Array<string> }) => {
        const status = "error";
        const title = values.title;
        let message = "مشکلی در ساخت چالش پیش آمده. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.";
        if (result.path) {
            switch (result.path[0]) {
                case `Enter a valid "slug" consisting of letters, numbers, underscores or hyphens.`:
                    message = "مقدار وارد شده برای مسیر اختصاصی باید فقط شامل حروف انگلیسی ، اعداد، خط زیر و خط فاصله باشد.";
                    break;
                case `problem with this path already exists.`:
                    message = "مسیر اختصاصی که وارد کرده‌اید قبلاً انتخاب شده!";
                    break;
                default:
                    message = result.path[0];
                    break;
            }
        }
        if (result.subtitle) {
            switch (result.subtitle[0]) {
                default:
                    message = result.subtitle[0];
                    break;
            }
        }
        if (result.title) {
            switch (result.title[0]) {
                default:
                    message = result.title[0];
                    break;
            }
        }
        props.toast({
            variant: "subtle",
            status,
            isClosable: true,
            description: message,
            title,
        });
    };

    const onAddProblemToPhase = async (pid: number, path: string) => {
        const serverResponse = (await AddProblemToPhase(props.data.competition, props.data.phaseId, pid, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setDisabled(redirect);
            props.toast({
                status: "success",
                variant: "subtle",
                isClosable: true,
                duration: redirect ? 5000 : 3000,
                description: redirect ? "چالش جدید با موفقیت ساخته شد! در حال انتقال شما به صفحه سوال..." : "چالش جدید با موفقیت ساخته شد!",
            });
            if (redirect) {
                router.push(`/c/${props.data.competition}/p/${props.data.phaseId}/p/${path}`);
            }
            props.setAddConfirmed(true);
            props.onClose();
            setValues({
                title: "",
                subtitle: "",
                path: "",
                code_execution: false,
                repository_mode: 10,
                evaluation_mode: 10,
                is_output_presentable: false,
            });
            setRedirect(false);
            nextStep();
            setTimeout(() => {
                reset();
            }, 250);
        } else {
            errorParser(null);
        }
    };

    const onConfirm = async () => {
        setLoading(true);
        const serverResponse = (await problemCreate(
            values.title,
            values.subtitle,
            values.repository_mode,
            values.evaluation_mode,
            values.code_execution,
            values.path,
            {
                toast: props.toast,
                requesterPath: props.router.asPath,
            },
        )) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            await onAddProblemToPhase(result.id, result.path);
        } else {
            if (serverResponse.status === 400) {
                const { data } = await serverResponse.json();
                errorParser(data);
            }
        }
        setLoading(false);
    };
    useEffect(() => {
        setDisabled(false);
        setRedirect(false);
    }, []);

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setValues({
                    title: "",
                    subtitle: "",
                    path: "",
                    code_execution: false,
                    repository_mode: 10,
                    evaluation_mode: 10,
                    is_output_presentable: false,
                });
                reset();
                setDisabled(false);
                setRedirect(false);
            }}
            size={"xl"}
        >
            <ModalOverlay />
            <ModalContent className={`${styles.new_problem_modal_content}`}>
                <ModalHeader>ساخت چالش جدید</ModalHeader>
                <ModalCloseButton />

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <ModalBody>
                        <Steps
                            activeStep={activeStep}
                            state={loading ? "loading" : undefined}
                            size="sm"
                            orientation="horizontal"
                            labelOrientation="vertical"
                            responsive={false}
                        >
                            {steps.map(({ label, content }) => (
                                <Step label={label} key={label}>
                                    {content}
                                </Step>
                            ))}
                        </Steps>
                    </ModalBody>
                    <ModalFooter>
                        {activeStep === 1 ? (
                            <>
                                <Button
                                    className={"mx-2"}
                                    isDisabled={loading || disabled}
                                    size="sm"
                                    placeSelf={"flex-end"}
                                    onClick={() => {
                                        prevStep();
                                    }}
                                >
                                    مرحله قبل
                                </Button>
                                <Button
                                    type={"submit"}
                                    isDisabled={values.title.length === 0 || disabled}
                                    isLoading={loading}
                                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                    size="sm"
                                    placeSelf={"flex-end"}
                                    onClick={() => {
                                        onConfirm();
                                    }}
                                >
                                    اضافه کردن چالش به مسابقه
                                </Button>
                            </>
                        ) : (
                            <Button
                                type={"submit"}
                                isDisabled={values.title.length === 0 || disabled}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                size="sm"
                                onClick={() => {
                                    nextStep();
                                }}
                            >
                                مرحله بعد
                            </Button>
                        )}
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};
