import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const problemTextGet = async (id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/text/${id}`, {}, "preferred", ctx);

export const problemTextListGet = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/texts`, {}, "preferred", ctx);

export const problemTextCreate = async (
    path: string,
    content_type: number,
    title: string,
    text: string,
    order?: number,
    ctx?: IServerSideContext,
) => {
    const req_data = {
        content_type: content_type,
        title: title,
        text: text,
    };
    if (order) Object.assign({ order }, req_data);
    return await fetchWithRefresh(
        `/problem/${path}/texts`,
        { method: "POST", body: JSON.stringify(req_data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );
};

export const problemTextDelete = async (id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/text/${id}`, { method: "DELETE" }, "required", ctx);

export const problemTextEdit = async (
    id: number,
    content_type: 10 | 20 | 30 | 40 | 50,
    title: string,
    text: string,
    order?: number,
    ctx?: IServerSideContext,
) => {
    const req_data = { content_type, title, text };
    if (order) Object.assign({ order }, req_data);

    return await fetchWithRefresh(
        `/problem/text/${id}`,
        { method: "PATCH", body: JSON.stringify(req_data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );
};
