import { Box, IconButton, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Moon, Sunny, LogoDiscord } from "react-ionicons";
import styles from "./layout.module.css";

export const Layout = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <div className={`flex flex-row w-full h-screen bg-transparent ${styles.layout}`}>
            <Box
                className={`w-full h-full flex flex-col ${styles.rightPanel} text-center items-center justify-end pb-8`}
                backgroundImage={"url(/images/einestein-2.png)"}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundColor={useColorModeValue("RoboEpics.dark.500", "RoboEpics.dark.300")}
            >
                <Text fontSize={"3xl"} fontWeight={500} className={`${styles.quote}`} color="white" mb={2}>
                    Play is the highest form of research
                </Text>
                <Text fontSize="xl" fontWeight={300} className={`w-10/12 text-right ${styles.quote}`} color="white">
                    <i>Albert Einstein -‌</i>
                </Text>
            </Box>
            <div className="flex flex-row text-center  w-full  h-full">
                {props.children}
                <div className="auth-page-header flex flex-row  p-2">
                    <IconButton
                        as="a"
                        href="https://discord.gg/8DMfjmn6gc"
                        target="_blank"
                        aria-label="discord"
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        icon={<LogoDiscord width="20px" height="20px" color={useColorModeValue("#000", "#fff")} />}
                    />
                    <IconButton
                        size="sm"
                        bg={"transparent"}
                        onClick={toggleColorMode}
                        aria-label="auth-toggle-theme"
                        icon={
                            colorMode === "dark" ? <Sunny color="#fff" height="18px" width="18px" /> : <Moon height="18px" width="18px" />
                        }
                    ></IconButton>
                </div>
            </div>
        </div>
    );
};
