import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Collapse,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    SlideFade,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { StringOrNumber } from "@chakra-ui/utils";
import { problemEditJson } from "@util/api/problems";
import { IRoles, IToastOptions } from "@util/interfaces";
import _ from "lodash";
import { NextRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, Plus } from "react-feather";
import { Trash } from "react-ionicons";

import Style from "./settings.module.css";

export const GimulatorSettings = (props: {
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    directorRoles: Array<IRoles>;
    actorRoles: Array<IRoles>;
    terminateOnActorFailure: boolean;
    timeout: number;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    problem: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [actorRoles, setActorRoles] = useState<Array<IRoles>>(props.actorRoles ? props.actorRoles : []);
    const [directorRoles, setDirectorRoles] = useState<Array<IRoles>>(props.directorRoles ? props.directorRoles : []);
    const [terminateOnActorFailure, setTerminateOnActorFailure] = useState(
        props.terminateOnActorFailure ? props.terminateOnActorFailure : false,
    );
    const [timeout, setTimeout] = useState(props.timeout ? props.timeout : 300);

    const newInstanceActor = () => {
        return {
            name: "",
            id: actorRoles.length ? actorRoles[actorRoles.length - 1].id + 1 : 0,
            rules: [
                {
                    id: 0,
                    key: {
                        id: 0,
                        type: "",
                        name: "",
                        namespace: "",
                    },
                    methods: [],
                },
            ],
            resource_limit: {
                id: "0",
                cpu: "0",
                memory: "0",
                ephemeral: "0",
            },
        };
    };
    const newInstanceDirector = () => {
        return {
            name: "",
            id: directorRoles.length ? directorRoles[directorRoles.length - 1].id + 1 : 0,
            rules: [
                {
                    id: 0,
                    key: {
                        id: 0,
                        type: "",
                        name: "",
                        namespace: "",
                    },
                    methods: [],
                },
            ],
            resource_limit: {
                id: "0",
                cpu: "0",
                memory: "0",
                ephemeral: "0",
            },
        };
    };
    const newInstanceRule = (arr: IRoles) => {
        return {
            id: arr.rules.length ? arr.rules[arr.rules.length - 1].id + 1 : 0,
            key: { id: 0, type: "", name: "", namespace: "" },
            methods: [],
        };
    };
    const onReset = () => {
        setActorRoles(props.actorRoles);
        setDirectorRoles(props.directorRoles);
        setTerminateOnActorFailure(props.terminateOnActorFailure);
        setTimeout(props.timeout);
        props.setHasChanged(false);
        setDisabled(true);
        setLoading(false);
    };
    const onConfirm = async () => {
        setLoading(true);

        const req_data = {};
        if (!_.isEqual(props.actorRoles, actorRoles)) req_data["actor_roles"] = actorRoles;

        if (!_.isEqual(props.directorRoles, directorRoles)) req_data["director_roles"] = directorRoles;

        if (props.timeout !== timeout) req_data["timeout"] = timeout;

        if (props.terminateOnActorFailure !== terminateOnActorFailure) req_data["terminate_on_action_failure"] = terminateOnActorFailure;

        const serverResponse = (await problemEditJson(req_data, props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
        } else {
            props.toast({
                status: "error",
                duration: 5000,
                variant: "subtle",
                description: "مشکلی در اعمال تغییرات شما به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
            });
        }

        setLoading(false);
    };
    useEffect(() => {
        if (
            !_.isEqual(props.actorRoles, actorRoles) ||
            !_.isEqual(props.directorRoles, directorRoles) ||
            props.terminateOnActorFailure !== terminateOnActorFailure ||
            props.timeout !== timeout
        ) {
            props.setHasChanged(true);
            setDisabled(false);
        }
        if (
            _.isEqual(props.directorRoles, directorRoles) &&
            _.isEqual(props.actorRoles, actorRoles) &&
            props.terminateOnActorFailure === terminateOnActorFailure &&
            props.timeout === timeout
        ) {
            setDisabled(true);
            setLoading(false);
            props.setHasChanged(false);
        }
    }, [directorRoles, actorRoles, terminateOnActorFailure, timeout]);

    return (
        <Box className={`flex flex-row justify-start px-8 ${Style.gimu_main_container}`}>
            <VStack spacing={6} w="full">
                <FormControl>
                    <div className={`flex-row flex justify-between w-full items-center mb-4 ${Style.gimu_actor_header}`}>
                        <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                            Actor Roles
                            <Popover placement="left">
                                <PopoverTrigger>
                                    <IconButton
                                        icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                        bg="transparent"
                                        variant="ghost"
                                        aria-label="question-repo"
                                        size="xs"
                                        mr={2}
                                    />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverBody>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                        doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque
                                        doloribus suscipit hic delectus excepturi.
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </FormLabel>
                        <Button
                            size="sm"
                            mr="auto"
                            leftIcon={<Plus />}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                            bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                            onClick={() => {
                                setActorRoles((current) => {
                                    return [...current, newInstanceActor()];
                                });
                            }}
                        >
                            اضافه کردن نقش جدید
                        </Button>
                    </div>
                    {actorRoles.map((role, index) => {
                        return (
                            <ActorRoleBox
                                role={role}
                                index={index}
                                actorRoles={actorRoles}
                                setActorRoles={setActorRoles}
                                newInstanceRule={newInstanceRule}
                                key={`actor-role-${role.id}`}
                            />
                        );
                    })}
                </FormControl>
                {/* <Divider />
                <FormControl>
                    <div
                        className={`flex-row flex justify-between w-full items-center mb-4 ${Style.gimu_director_header}`}
                    >
                        <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                            Director Roles
                            <Popover placement="left">
                                <PopoverTrigger>
                                    <IconButton
                                        icon={
                                            <QuestionOutlineIcon
                                                boxSize="16px"
                                                transform={"rotateY(180deg)"}
                                            />
                                        }
                                        bg="transparent"
                                        variant="ghost"
                                        aria-label="question-repo"
                                        size="xs"
                                        mr={2}
                                    />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverBody>
                                        Lorem, ipsum dolor sit amet consectetur
                                        adipisicing elit. Modi molestias officia
                                        labore inventore doloremque
                                        exercitationem impedit at mollitia
                                        rerum! Animi placeat voluptatum fuga
                                        nulla atque doloribus suscipit hic
                                        delectus excepturi.
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </FormLabel>
                        <Button
                            size="sm"
                            mr="auto"
                            leftIcon={<Plus />}
                            colorScheme={useColorModeValue(
                                "RoboEpics.azure",
                                "RoboEpics.gold",
                            )}
                            bg={useColorModeValue(
                                "RoboEpics.azure.500",
                                "RoboEpics.gold.500",
                            )}
                            onClick={() => {
                                setDirectorRoles((current) => {
                                    return [...current, newInstanceDirector()];
                                });
                            }}
                        >
                            اضافه کردن نقش جدید
                        </Button>
                    </div>
                    {directorRoles.map((role, index) => {
                        return (
                            <DirectorRoleBox
                                role={role}
                                index={index}
                                directorRoles={directorRoles}
                                setDirectorRoles={setDirectorRoles}
                                newInstanceRule={newInstanceRule}
                                key={`director-role-${role.id}`}
                            />
                        );
                    })}
                </FormControl> */}
                <Divider />
                <FormControl>
                    <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                        Terminate on Actor Failure
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                    doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus
                                    suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <Checkbox
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        isChecked={terminateOnActorFailure}
                        onChange={(e) => setTerminateOnActorFailure(e.target.checked)}
                        mt={4}
                    >
                        در صورت Fail شدن Actor برای یک اجرا، آن را به محض Fail شدن متوقف می‌کند.
                    </Checkbox>
                </FormControl>
                <Divider />
                <FormControl>
                    <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                        Timeout
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                    doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus
                                    suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <HStack spacing={4} mt={4}>
                        <Text mb={0}>حداکثر زمان برای اعلام ورشکستگی؟؟؟ (ثانیه):</Text>
                        <NumberInput
                            w="100px"
                            size="sm"
                            value={timeout ? timeout : 300}
                            onChange={(valString, valNumber) => setTimeout(valNumber)}
                            min={0}
                            step={1}
                        >
                            <NumberInputField />
                        </NumberInput>
                    </HStack>
                </FormControl>
                <Box className={`flex flex-row justify-end w-full ${Style.basic_button_container}`}>
                    <Button
                        isDisabled={loading || disabled}
                        ml={4}
                        colorScheme={"red"}
                        onClick={() => {
                            onReset();
                        }}
                        size="sm"
                    >
                        بازنشانی
                    </Button>
                    <Button
                        isDisabled={disabled}
                        isLoading={loading}
                        size="sm"
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        bg={useColorModeValue("RoboEpics.dark.500", "RoboEpics.gold.500")}
                        onClick={() => {
                            onConfirm();
                        }}
                    >
                        ذخیره و اعمال تغییرات
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
};
const ActorRoleBox = (props: {
    role: IRoles;
    index: number;
    actorRoles: Array<IRoles>;
    setActorRoles: React.Dispatch<React.SetStateAction<IRoles[]>>;
    newInstanceRule: (arr: IRoles) => {
        id: number;
        key: {
            id: number;
            type: string;
            name: string;
            namespace: string;
        };
        methods: string[];
    };
}) => {
    const deleteRole = (index: number) => {
        const newRoles = props.actorRoles.filter((role, arrindex) => {
            return props.actorRoles[arrindex].id !== props.actorRoles[index].id;
        });
        props.setActorRoles(newRoles);
    };
    const newRoles = props.actorRoles.slice();
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <SlideFade in={true} offsetY={"-38px"}>
            <Collapse in={show} startingHeight={75} className={`border rounded my-2 ${Style.gimu_role_collapse}`}>
                <Box
                    className={`rounded pb-4 pr-4 pt-2 pl-2 flex flex-row justify-between ${Style.gimu_role_container}`}
                    overflowY="hidden"
                >
                    <VStack spacing={4} w="full">
                        <FormControl key={`actor-role-${props.role.id}-player-type`}>
                            <div className={`flex flex-row justify-start w-4/6 items-baseline pt-2 ${Style.gimu_role_player_type}`}>
                                <FormLabel fontSize="15px" fontWeight="bold" w="110px" className={`${Style.gimu_role_player_type_label}`}>
                                    نوع بازیکن:{" "}
                                </FormLabel>
                                <Input
                                    value={props.role.name}
                                    type="text"
                                    onChange={(e) => {
                                        newRoles[props.index].name = e.target.value;
                                        props.setActorRoles(newRoles);
                                    }}
                                />
                            </div>
                        </FormControl>
                        <FormControl key={`actor-role-${props.role.id}-resource-limits`}>
                            <FormLabel fontSize="15px" fontWeight="bold">
                                محدودیت منابع:{" "}
                            </FormLabel>
                            <VStack
                                spacing={3}
                                mr={24}
                                className={`${Style.gimu_role_resource_stack}`}
                                key={`.resource-${props.role.id}-${props.role.resource_limit.id}`}
                            >
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        CPU :{" "}
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.cpu ? props.role.resource_limit.cpu : 0}
                                        min={0}
                                        max={8}
                                        maxW={"fit-content"}
                                        step={1}
                                        onChange={(value) => {
                                            newRoles[props.index].resource_limit.cpu = value;
                                            props.setActorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        Ephemeral :
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.ephemeral ? props.role.resource_limit.ephemeral : 0}
                                        min={0}
                                        max={8}
                                        maxW={"fit-content"}
                                        step={1}
                                        onChange={(value) => {
                                            newRoles[props.index].resource_limit.ephemeral = value;
                                            props.setActorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        RAM :
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.memory ? props.role.resource_limit.memory : 0}
                                        min={0}
                                        max={8192}
                                        maxW={"fit-content"}
                                        step={parseInt(props.role.resource_limit.memory) ? parseInt(props.role.resource_limit.memory) : 1}
                                        onChange={(value) => {
                                            if (value === "0") {
                                                newRoles[props.index].resource_limit.memory = (
                                                    parseInt(props.role.resource_limit.memory) / 2
                                                ).toString();
                                            } else {
                                                newRoles[props.index].resource_limit.memory = value;
                                            }
                                            props.setActorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                            </VStack>
                        </FormControl>
                        <FormControl key={`actor-role-${props.role.id}-rules`}>
                            <div className={`flex-row flex justify-between w-full items-center mb-4 ${Style.gimu_rules_header}`}>
                                <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                                    قوانین:{" "}
                                </FormLabel>
                                <Button
                                    size="sm"
                                    mr="auto"
                                    leftIcon={<Plus />}
                                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                    bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                                    onClick={() => {
                                        newRoles[props.index].rules.push(props.newInstanceRule(newRoles[props.index]));
                                        props.setActorRoles(newRoles);
                                    }}
                                >
                                    اضافه کردن قانون جدید
                                </Button>
                            </div>

                            {props.role.rules.map((rule, rule_index) => {
                                return (
                                    <ActorRuleBox
                                        rule={rule}
                                        rule_index={rule_index}
                                        role={props.role}
                                        newRoles={newRoles}
                                        index={props.index}
                                        setActorRoles={props.setActorRoles}
                                        key={`actor-role-${props.role.id}-rule-${rule.id}`}
                                    />
                                );
                            })}
                        </FormControl>
                    </VStack>
                    <div className={`${Style.gimu_cntrlr_btn_container} flex flex-row `}>
                        <IconButton
                            aria-label="delete-actor-role"
                            icon={<Trash color="red" width="18px" height="18px" />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                                deleteRole(props.index);
                            }}
                        />
                        <IconButton
                            aria-label="toggle"
                            icon={show ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                            size="xs"
                            mr={2}
                            variant="ghost"
                            onClick={handleToggle}
                        />
                    </div>
                </Box>
            </Collapse>
        </SlideFade>
    );
};

const ActorRuleBox = (props: {
    rule: {
        id: number;
        key: { name: string; namespace: string; type: string };
        methods: StringOrNumber[];
    };
    rule_index: number;
    role: IRoles;
    index: number;
    newRoles: IRoles[];
    setActorRoles: React.Dispatch<React.SetStateAction<IRoles[]>>;
}) => {
    const deleteRule = (index: number) => {
        const newRules = props.role.rules.filter((rule, arrindex) => {
            return props.role.rules[arrindex].id !== props.role.rules[index].id;
        });
        props.newRoles[index].rules = newRules;
        props.setActorRoles(props.newRoles);
    };
    const newRules = props.role.rules.slice();
    const [showRule, setShowRule] = useState(false);
    const handleToggleRule = () => setShowRule(!showRule);
    return (
        <SlideFade in={true} offsetX={"50px"} offsetY={"0"}>
            <Collapse in={showRule} startingHeight={75} className={`border rounded my-2 mr-24 ${Style.gimu_rules_collapse}`}>
                <Box className={`pb-4 pr-4 pt-2 pl-2 flex flex-row justify-between ${Style.gimu_rule_container}`} minH={20}>
                    <VStack spacing={4} w="full" pl={8} className={`${Style.gimu_rules_stack}`}>
                        <div className={`flex flex-row items-center w-full pt-1 ${Style.gimu_rules_keys_container}`}>
                            <Text ml={3} mb={0} className={`${Style.gimu_rules_keys_label}`}>
                                کلید:{" "}
                            </Text>
                            <div className={`flex flex-row w-full ${Style.gimu_rules_keys_wrapper}`}>
                                <Input
                                    placeholder={"Name"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.name}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.name = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setActorRoles(props.newRoles);
                                    }}
                                />
                                <Input
                                    placeholder={"Type"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.type}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.type = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setActorRoles(props.newRoles);
                                    }}
                                />
                                <Input
                                    placeholder={"Namespace"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.namespace}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.namespace = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setActorRoles(props.newRoles);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`flex flex-row items-start w-full pt-1 ${Style.gimu_rules_methods_container}`}>
                            <Text ml={6} mb={0} className={`${Style.gimu_rules_methods_label}`}>
                                دسترسی‌ها:
                            </Text>
                            <CheckboxGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                defaultValue={props.rule.methods}
                                onChange={(value) => {
                                    newRules[props.rule_index].methods = value;
                                    props.newRoles[props.index].rules = newRules;
                                    props.setActorRoles(props.newRoles);
                                }}
                            >
                                <SimpleGrid
                                    columns={3}
                                    spacingX={10}
                                    spacingY={4}
                                    w="full"
                                    className={`${Style.gimu_rules_methods_wrapper}`}
                                >
                                    <Checkbox value="get">get</Checkbox>
                                    <Checkbox value="getAll">getAll</Checkbox>
                                    <Checkbox value="put">put</Checkbox>
                                    <Checkbox value="delete">delete</Checkbox>
                                    <Checkbox value="deleteAll">deleteAll</Checkbox>
                                    <Checkbox value="watch">watch</Checkbox>
                                    <Checkbox value="getActors">getActors</Checkbox>
                                    <Checkbox value="putResult">putResult</Checkbox>
                                    <Checkbox value="imReady">imReady</Checkbox>
                                    <Checkbox value="ping">ping</Checkbox>
                                    <Checkbox value="setUserStatus">setUserStatus</Checkbox>
                                </SimpleGrid>
                            </CheckboxGroup>
                        </div>
                    </VStack>
                    <div className={`${Style.gimu_cntrlr_btn_container} flex flex-row `}>
                        <IconButton
                            aria-label="delete-actor-rule"
                            icon={<Trash color="red" width="18px" height="18px" />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                                deleteRule(props.index);
                            }}
                        />
                        <IconButton
                            aria-label="toggle rule"
                            icon={showRule ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                            size="xs"
                            mr={2}
                            variant="ghost"
                            onClick={handleToggleRule}
                        />
                    </div>
                </Box>
            </Collapse>
        </SlideFade>
    );
};

const DirectorRoleBox = (props: {
    role: IRoles;
    index: number;
    directorRoles: Array<IRoles>;
    setDirectorRoles: React.Dispatch<React.SetStateAction<IRoles[]>>;
    newInstanceRule: (arr: IRoles) => {
        id: number;
        key: {
            id: number;
            type: string;
            name: string;
            namespace: string;
        };
        methods: string[];
    };
}) => {
    const deleteRole = (index: number) => {
        const newRoles = props.directorRoles.filter((role, arrindex) => {
            return props.directorRoles[arrindex].id !== props.directorRoles[index].id;
        });
        props.setDirectorRoles(newRoles);
    };
    const newRoles = props.directorRoles.slice();
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <SlideFade in={true} offsetY={"-38px"}>
            <Collapse in={show} startingHeight={75} className={`border rounded my-2 ${Style.gimu_role_collapse}`}>
                <Box
                    className={`rounded pb-4 pr-4 pt-2 pl-2 flex flex-row justify-between ${Style.gimu_role_container}`}
                    overflowY="hidden"
                >
                    <VStack spacing={4} w="full">
                        <FormControl key={`director-role-${props.role.id}-player-type`}>
                            <div className={`flex flex-row justify-start w-4/6 items-baseline pt-2 ${Style.gimu_role_player_type}`}>
                                <FormLabel fontSize="15px" fontWeight="bold" w="110px" className={`${Style.gimu_role_player_type_label}`}>
                                    نوع بازیکن:{" "}
                                </FormLabel>
                                <Input
                                    value={props.role.name}
                                    type="text"
                                    onChange={(e) => {
                                        newRoles[props.index].name = e.target.value;
                                        props.setDirectorRoles(newRoles);
                                    }}
                                />
                            </div>
                        </FormControl>
                        <FormControl key={`director-role-${props.role.id}-resource-limits`}>
                            <FormLabel fontSize="15px" fontWeight="bold">
                                محدودیت منابع:{" "}
                            </FormLabel>
                            <VStack
                                spacing={3}
                                mr={24}
                                className={`${Style.gimu_role_resource_stack}`}
                                key={`.resource-${props.role.id}-${props.role.resource_limit.id}`}
                            >
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        CPU :{" "}
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.cpu ? props.role.resource_limit.cpu : 0}
                                        min={0}
                                        max={8}
                                        maxW={"fit-content"}
                                        step={1}
                                        onChange={(value) => {
                                            newRoles[props.index].resource_limit.cpu = value;
                                            props.setDirectorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        Ephemeral :
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.ephemeral ? props.role.resource_limit.ephemeral : 0}
                                        min={0}
                                        max={8}
                                        maxW={"fit-content"}
                                        step={1}
                                        onChange={(value) => {
                                            newRoles[props.index].resource_limit.ephemeral = value;
                                            props.setDirectorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                                <div className={`flex flex-row items-center w-full ${Style.gimu_numberinput_container}`}>
                                    <Text ml={3} mb={0} className={`${Style.gimu_numberinput_label}`}>
                                        RAM :
                                    </Text>
                                    <NumberInput
                                        size="sm"
                                        value={props.role.resource_limit.memory ? props.role.resource_limit.memory : 0}
                                        min={0}
                                        max={8192}
                                        maxW={"fit-content"}
                                        step={parseInt(props.role.resource_limit.memory) ? parseInt(props.role.resource_limit.memory) : 1}
                                        onChange={(value) => {
                                            if (value === "0") {
                                                newRoles[props.index].resource_limit.memory = (
                                                    parseInt(props.role.resource_limit.memory) / 2
                                                ).toString();
                                            } else {
                                                newRoles[props.index].resource_limit.memory = value;
                                            }
                                            props.setDirectorRoles(newRoles);
                                        }}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </div>
                            </VStack>
                        </FormControl>
                        <FormControl key={`director-role-${props.role.id}-rules`}>
                            <div className={`flex-row flex justify-between w-full items-center mb-4 ${Style.gimu_rules_header}`}>
                                <FormLabel fontWeight="bold" alignItems="center" mb={0}>
                                    قوانین:{" "}
                                </FormLabel>
                                <Button
                                    size="sm"
                                    mr="auto"
                                    leftIcon={<Plus />}
                                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                    bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                                    onClick={() => {
                                        newRoles[props.index].rules.push(props.newInstanceRule(newRoles[props.index]));
                                        props.setDirectorRoles(newRoles);
                                    }}
                                >
                                    اضافه کردن قانون جدید
                                </Button>
                            </div>

                            {props.role.rules.map((rule, rule_index) => {
                                return (
                                    <DirectorRuleBox
                                        rule={rule}
                                        rule_index={rule_index}
                                        role={props.role}
                                        newRoles={newRoles}
                                        index={props.index}
                                        setDirectorRoles={props.setDirectorRoles}
                                        key={`director-role-${props.role.id}-rule-${rule.id}`}
                                    />
                                );
                            })}
                        </FormControl>
                    </VStack>
                    <div className={`${Style.gimu_cntrlr_btn_container} flex flex-row `}>
                        <IconButton
                            aria-label="delete-actor-role"
                            icon={<Trash color="red" width="18px" height="18px" />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                                deleteRole(props.index);
                            }}
                        />
                        <IconButton
                            aria-label="toggle"
                            icon={show ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                            size="xs"
                            mr={2}
                            variant="ghost"
                            onClick={handleToggle}
                        />
                    </div>
                </Box>
            </Collapse>
        </SlideFade>
    );
};

const DirectorRuleBox = (props: {
    rule: {
        id: number;
        key: { name: string; namespace: string; type: string };
        methods: StringOrNumber[];
    };
    rule_index: number;
    role: IRoles;
    index: number;
    newRoles: IRoles[];
    setDirectorRoles: React.Dispatch<React.SetStateAction<IRoles[]>>;
}) => {
    const deleteRule = (index: number) => {
        const newRules = props.role.rules.filter((rule, arrindex) => {
            return props.role.rules[arrindex].id !== props.role.rules[index].id;
        });
        props.newRoles[index].rules = newRules;
        props.setDirectorRoles(props.newRoles);
    };
    const newRules = props.role.rules.slice();
    const [showRule, setShowRule] = useState(false);
    const handleToggleRule = () => setShowRule(!showRule);
    return (
        <SlideFade in={true} offsetX={"50px"} offsetY={"0"}>
            <Collapse in={showRule} startingHeight={75} className={`border rounded my-2 mr-24 ${Style.gimu_rules_collapse}`}>
                <Box className={`pb-4 pr-4 pt-2 pl-2 flex flex-row justify-between ${Style.gimu_rule_container}`} minH={20}>
                    <VStack spacing={4} w="full" pl={8} className={`${Style.gimu_rules_stack}`}>
                        <div className={`flex flex-row items-center w-full pt-1 ${Style.gimu_rules_keys_container}`}>
                            <Text ml={3} mb={0} className={`${Style.gimu_rules_keys_label}`}>
                                کلید:{" "}
                            </Text>
                            <div className={`flex flex-row w-full ${Style.gimu_rules_keys_wrapper}`}>
                                <Input
                                    placeholder={"Name"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.name}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.name = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setDirectorRoles(props.newRoles);
                                    }}
                                />
                                <Input
                                    placeholder={"Type"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.type}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.type = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setDirectorRoles(props.newRoles);
                                    }}
                                />
                                <Input
                                    placeholder={"Namespace"}
                                    mx={2}
                                    size="sm"
                                    value={props.rule.key.namespace}
                                    onChange={(e) => {
                                        newRules[props.rule_index].key.namespace = e.target.value;
                                        props.newRoles[props.index].rules = newRules;
                                        props.setDirectorRoles(props.newRoles);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`flex flex-row items-start w-full pt-1 ${Style.gimu_rules_methods_container}`}>
                            <Text ml={6} mb={0} className={`${Style.gimu_rules_methods_label}`}>
                                دسترسی‌ها:
                            </Text>
                            <CheckboxGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                defaultValue={props.rule.methods}
                                onChange={(value) => {
                                    newRules[props.rule_index].methods = value;
                                    props.newRoles[props.index].rules = newRules;
                                    props.setDirectorRoles(props.newRoles);
                                }}
                            >
                                <SimpleGrid
                                    columns={3}
                                    spacingX={10}
                                    spacingY={4}
                                    w="full"
                                    className={`${Style.gimu_rules_methods_wrapper}`}
                                >
                                    <Checkbox value="get">get</Checkbox>
                                    <Checkbox value="getAll">getAll</Checkbox>
                                    <Checkbox value="put">put</Checkbox>
                                    <Checkbox value="delete">delete</Checkbox>
                                    <Checkbox value="deleteAll">deleteAll</Checkbox>
                                    <Checkbox value="watch">watch</Checkbox>
                                    <Checkbox value="getActors">getActors</Checkbox>
                                    <Checkbox value="putResult">putResult</Checkbox>
                                    <Checkbox value="imReady">imReady</Checkbox>
                                    <Checkbox value="ping">ping</Checkbox>
                                    <Checkbox value="setUserStatus">setUserStatus</Checkbox>
                                </SimpleGrid>
                            </CheckboxGroup>
                        </div>
                    </VStack>
                    <div className={`${Style.gimu_cntrlr_btn_container} flex flex-row `}>
                        <IconButton
                            aria-label="delete-actor-rule"
                            icon={<Trash color="red" width="18px" height="18px" />}
                            size="xs"
                            variant="ghost"
                            onClick={() => {
                                deleteRule(props.index);
                            }}
                        />
                        <IconButton
                            aria-label="toggle rule"
                            icon={showRule ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                            size="xs"
                            mr={2}
                            variant="ghost"
                            onClick={handleToggleRule}
                        />
                    </div>
                </Box>
            </Collapse>
        </SlideFade>
    );
};
