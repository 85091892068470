import React, { useEffect, useRef, useState } from "react";
import { default as NextImage } from "next/image";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    IconButton,
} from "@chakra-ui/react";
import { problemEditFormdata } from "@util/api/problems";
import Style from "./challenge.module.css";
import { getImageURL } from "@util/mediaLoader";
import { Edit2 } from "react-feather";
import { AddOutline, Trash } from "react-ionicons";
import CropModal, { b64toBlob } from "@util/CropModal";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const CoverImageChange = (props: {
    toast: IToastOptions;
    coverImage: string;
    setCoverImage: React.Dispatch<React.SetStateAction<string>>;
    router: NextRouter;
}) => {
    const inputRef = useRef(null);
    const imageRef = useRef(null);
    const [tmpCoverImage, setTmpCoverImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();

    const checkFileAttributes = (file) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };

    const onChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                isClosable: true,
                variant: "subtle",
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 9000,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpCoverImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };

    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpCoverImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        props.setCoverImage(canvas.toDataURL("image/jpeg"));
        setEditAllowed(true);
    };
    const compileFormData = (coverImage: string) => {
        const form_data = new FormData();

        if (coverImage) {
            const block = coverImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("cover_image", blob, `problem_${props.router.query.problem}_cover_image.png`);
            }
        } else if (coverImage === null) {
            form_data.append("cover_image", "");
        }
        return form_data;
    };

    const editCoverImage = async (coverImage: string) => {
        const req_data = compileFormData(coverImage);
        const serverResponse = (await problemEditFormdata(req_data, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setCoverImage(coverImage);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setDeleteAlertVisible(false);
        setEditAllowed(false);
        setDeleteLoading(false);
    };
    useEffect(() => {
        if (editAllowed) editCoverImage(props.coverImage);
    }, [props.coverImage]);
    return (
        <Box height="full" width="full" className={`flex flex-col items-center justify-center self-start ml-4 ${Style.thumbnail}`}>
            <Box className="grid w-full">
                <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
                <CropModal
                    modalTitle={"تصویر چالش"}
                    src={tmpCoverImage}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSuccess={(data) => {
                        crop(data);
                        setIsModalOpen(false);
                    }}
                    aspectY={4}
                    aspectX={1}
                />

                {props.coverImage === null ? (
                    <NextImage
                        src="/images/bannerPlaceholder.png"
                        className={`border object-cover ${Style.no_image}`}
                        width="1216"
                        height="350"
                        objectFit="cover"
                    />
                ) : (
                    <img
                        ref={imageRef}
                        className="border"
                        src={props.coverImage.includes("data:image/") ? props.coverImage : getImageURL(props.coverImage)}
                        style={{
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                        }}
                    />
                )}
                <div className={`${Style.coverPictureDarkHover}`}>
                    <IconButton
                        size="md"
                        variant="solid"
                        aria-label="new profile picture"
                        bg={"blackAlpha.700"}
                        icon={props.coverImage ? <Edit2 size="20" color="#fff" /> : <AddOutline color="#fff" height="21px" width="21px" />}
                        _hover={{
                            bg: "blackAlpha.900",
                        }}
                        onClick={() => {
                            inputRef.current.click();
                        }}
                    />
                    {props.coverImage && (
                        <IconButton
                            className="mr-2"
                            size="md"
                            variant="solid"
                            bg={"blackAlpha.700"}
                            aria-label="remove profile picture"
                            icon={<Trash color="#c53030" height="23px" width="23px" />}
                            _hover={{
                                bg: "blackAlpha.900",
                            }}
                            onClick={() => {
                                setDeleteAlertVisible(true);
                            }}
                        />
                    )}
                </div>
            </Box>
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                    setTmpCoverImage(props.coverImage);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        با کلیک روی دکمه "تایید" تصویر چالش حذف خواهد شد و برای تعیین تصویر جدید باید فایل عکس را آپلود کنید.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                editCoverImage(null);
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
};
