import React, { useState, useEffect } from "react";
import { UserActivityChart } from "../Activity/chart";
import { CompetitionParticipatedCardMinimal, CompetitionHostedCardMinimal } from "../Competitions/competitionCardMinimal";
import { NextRouter, useRouter } from "next/router";
import {
    useColorModeValue,
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorMode,
    Spinner,
    SlideFade,
    Heading,
    Alert,
    AlertTitle,
    AlertDescription,
    AlertIcon,
} from "@chakra-ui/react";
import { ChatbubblesOutline, PulseOutline, ExtensionPuzzleOutline, MedalOutline } from "react-ionicons";
import { Book } from "react-feather";

import { retrieveUserCompetitions, retrieveHostedCompetitions, retrieveUserActivities, getUserNotebooks } from "@util/api/profile";
import { NotebooksMinimalCard } from "../Notebooks";
import { ICompData, INotebookProfile, IToastOptions } from "@util/interfaces";
import { getImageURL } from "@util/mediaLoader";
import { getDatePhrase } from "@util/dateConverter";

const ActivityView = (props: { fusion_user_id: string; toast: IToastOptions; router: NextRouter }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<{ submission_dates: Array<string> }>(null);

    const getData = async () => {
        setIsLoading(true);
        const result = (await retrieveUserActivities(props.fusion_user_id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;

        if (result.ok) {
            const data = await result.json();
            setData(data);
        } else {
            props.toast({
                title: "Activity View",
                description: "مشکلی در دریافت داده‌ها رخ داد.",
                status: "error",
                variant: "subtle",
                duration: 3000,
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return isLoading ? (
        <LoadingView />
    ) : (
        <Box
            className="w-full my-4"
            sx={{
                ".chartjs-render-monitor": {
                    width: "inherit !important",
                    height: "300px !important",
                },
            }}
        >
            {data ? (
                <UserActivityChart activityData={data.submission_dates.reverse()} />
            ) : (
                <Heading size="md">هنوز فعالیتی ثبت نشده است. </Heading>
            )}
        </Box>
    );
};

const LoadingView = () => {
    return (
        <div className="mt-2">
            <Spinner className="mx-auto" size="sm" />
        </div>
    );
};

const CompetitionsView = (props: { isMyAccount: boolean; fusion_user_id: string; toast: IToastOptions; router: NextRouter }) => {
    const tq = useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold");

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [hostData, setHostData] = useState([]);

    const [tabIndex, setTabIndex] = useState(0);
    const getData = async () => {
        const result = (await retrieveUserCompetitions(props.fusion_user_id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (result.ok) {
            const compsData = await result.json();

            compsData.forEach((element: ICompData) => {
                element["competition_thumbnail"] = element["competition_thumbnail"] ? getImageURL(element["competition_thumbnail"]) : null;
                element["competition_date_published"] = getDatePhrase(element["competition_date_published"], "roboepics");
                switch (element["competition_status"]) {
                    case 10: {
                        const tmp = getDatePhrase(element["register_date_start"], "roboepics").toString();
                        element["competition_status"] = `${tmp.substring(0, tmp.length - 4)} تا آغاز ثبت‌نام`;
                        break;
                    }
                    case 20:
                        element["competition_status"] = "ثبت‌نام آغاز شده";
                        break;
                    case 30:
                        element["competition_status"] = "در حال برگزاری";
                        break;
                    case 40:
                        // el["competition_status"] = "خاتمه یافته";
                        element["competition_status"] = null;
                        break;
                    default:
                        element["competition_status"] = "برگزار نشده";
                }
            });
            setData(compsData);
        } else {
            props.toast({
                title: "Competitions Participated View",
                description: "مشکلی در دریافت داده‌ها رخ داد.",
                duration: 3000,
                variant: "subtle",
                status: "error",
            });
        }
    };
    const getHostData = async () => {
        const result = (await retrieveHostedCompetitions(props.fusion_user_id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (result.ok) {
            const compsData = await result.json();
            setHostData(compsData);
        } else {
            props.toast({
                title: "Competitions Hosted View",
                description: "مشکلی در دریافت داده‌ها رخ داد.",
                duration: 3000,
                variant: "subtle",
                status: "error",
            });
        }
    };
    useEffect(() => {
        setIsLoading(true);
        tabIndex === 1 ? getData() : getHostData();
        setIsLoading(false);
    }, [tabIndex]);
    return isLoading ? (
        <LoadingView />
    ) : (
        <SlideFade in={!isLoading} offsetY="20px">
            <Tabs
                index={tabIndex}
                onChange={(index) => {
                    setTabIndex(index);
                }}
                isFitted
                colorScheme={tq}
            >
                <TabList>
                    <Tab>برگزار شده</Tab>
                    <Tab>شرکت کرده</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box className="w-full">
                            {hostData.length > 0 ? (
                                hostData.map((compInfo) => {
                                    return <CompetitionHostedCardMinimal cardInfo={compInfo} />;
                                })
                            ) : (
                                <Heading size="md">
                                    {props.isMyAccount
                                        ? "هنوز هیچ مسابقه‌ای برگزار نکرده‌اید!"
                                        : "این کاربر هنوز مسابقه‌ای برگزار نکرده است."}
                                </Heading>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box className="w-full">
                            {data.length > 0 ? (
                                data.map((compInfo) => {
                                    return <CompetitionParticipatedCardMinimal cardInfo={compInfo} isMyAccount={props.isMyAccount} />;
                                })
                            ) : (
                                <Heading size="md">
                                    {props.isMyAccount
                                        ? "هنوز در هیچ مسابقه‌ای شرکت نکرده‌اید!"
                                        : "این کاربر هنوز در مسابقه‌ای شرکت نکرده است."}
                                </Heading>
                            )}
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </SlideFade>
    );
};

// const TeamsView = (props: { toast: IToastOptions }) => {
//     return <Box className="w-full" />;
// };

const AchievementsView = (props: { toast: IToastOptions }) => {
    console.log(props.toast); // used for typescript warning ignoring. TODO: remove this when needed.
    return <Box className="w-full" />;
};

const NotebooksView = (props: { fusion_user_id: string | string[]; isMyAccount: boolean; toast: IToastOptions; router: NextRouter }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<Array<INotebookProfile>>(null);
    const getData = async () => {
        const serverResponse = (await getUserNotebooks(props.fusion_user_id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            setResponse(result);
        } else {
            props.toast({
                title: "User Notebooks View",
                description: "مشکلی در دریافت داده‌ها رخ داد.",
                duration: 3000,
                variant: "subtle",
                status: "error",
            });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getData();
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <LoadingView />;
    }
    if (!response) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    مشکلی در دریافت داده از سرور رخ داد.
                </AlertTitle>
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    } else {
        if (response.length === 0) {
            return (
                <Heading size="md">
                    {props.isMyAccount ? "هنوز  هیچ نوت‌بوکی در RoboEpics قرار نداده‌اید!" : "این کاربر هنوز نوت‌بوکی ایجاد نکرده است."}
                </Heading>
            );
        } else {
            return (
                <SlideFade in={!isLoading} offsetY="20px">
                    <Box className="w-full flex flex-col justify-between">
                        {response.map((notebookInfo) => {
                            return <NotebooksMinimalCard notebookData={notebookInfo} isMyAccount={props.isMyAccount} />;
                        })}
                    </Box>
                </SlideFade>
            );
        }
    }
};

export const ProfileTabsPanel = (props: { userData: any; isMyAccount: boolean; toast: IToastOptions; router: NextRouter }) => {
    const { colorMode } = useColorMode();
    const tabs = [
        {
            key: "activity",
            label: "فعالیت",
            content: <ActivityView fusion_user_id={props.userData.fusion_user_id} toast={props.toast} router={props.router} />,
            iconElement: <PulseOutline />,
        },
        {
            key: "competitions",
            label: "مسابقه‌ها",
            content: (
                <CompetitionsView
                    isMyAccount={props.isMyAccount}
                    fusion_user_id={props.userData.fusion_user_id}
                    toast={props.toast}
                    router={props.router}
                />
            ),
            iconElement: <ExtensionPuzzleOutline />,
        },
        // {
        //     key: 'teams',
        //     label: "تیم‌ها",
        //     content: <TeamsView />,
        //     iconElement: <PeopleOutline />,
        // },
        {
            key: "achievements",
            label: "دستاوردها",
            disabled: true,
            content: <AchievementsView toast={props.toast} />,
            iconElement: <MedalOutline />,
        },
        {
            key: "discussion",
            label: "گفت‌و‌گو‌ها",
            content: <></>,
            disabled: true,
            iconElement: <ChatbubblesOutline />,
        },
        {
            key: "notebooks",
            label: "نوت‌بوک‌ها",
            disabled: true,
            content: (
                <NotebooksView
                    fusion_user_id={props.userData.fusion_user_id}
                    isMyAccount={props.isMyAccount}
                    toast={props.toast}
                    router={props.router}
                />
            ),
            iconElement: <Book className="ml-2 flex-shrink-0 stroke-1" />,
        },
    ];
    const [tabIndex, setTabIndex] = useState(0);
    const bg = "RoboEpics.dark.600";
    useEffect(() => {
        const view = props.router.query.v;
        if (!view) return;
        tabs.forEach((val, index) => {
            if (val.key === view.toString()) {
                if (val.disabled) {
                    setTabIndex(0);
                } else {
                    setTabIndex(index);
                }
                return;
            }
        });
    }, [props.router.query.v]);
    return (
        <Tabs variant="line" isLazy index={tabIndex} onChange={(index) => setTabIndex(index)} align="center" className="w-full">
            <TabList
                overflowX="auto"
                overflowY="hidden"
                className="px-6 shadow-sm"
                bg={useColorModeValue("#f4f4f5", bg)}
                borderWidth="0 0 1px 0"
            >
                {tabs.map((tab, index) => {
                    return (
                        <Tab
                            mb={"0"}
                            isDisabled={tab.disabled}
                            id={index.toString()}
                            key={index}
                            _hover={{
                                borderColor:
                                    index !== tabIndex
                                        ? colorMode === "dark"
                                            ? "RoboEpics.gold.100"
                                            : "RoboEpics.azure.300"
                                        : colorMode === "dark"
                                        ? "RoboEpics.gold.400"
                                        : "RoboEpics.turquoise.500",
                            }}
                            _selected={{
                                px: "1.2rem",
                                fontWeight: 600,
                                borderColor: useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                            }}
                            onClick={() => {
                                props.router.push(`/users/${props.userData.username}/?v=${tab.key}`, undefined, {
                                    shallow: true,
                                });
                            }}
                        >
                            <>
                                {tab.iconElement &&
                                    React.cloneElement(tab.iconElement, {
                                        cssClasses: "ml-2 flex-shrink-0",
                                        color:
                                            tabIndex == index ? useColorModeValue("#000", "#fff") : useColorModeValue("#898989", "#898989"),
                                        width: "20px",
                                        height: "20px",
                                    })}
                                <span
                                    style={{
                                        color:
                                            tabIndex == index ? useColorModeValue("#000", "#fff") : useColorModeValue("#898989", "#898989"),
                                    }}
                                >
                                    {tab.label}
                                </span>
                            </>
                        </Tab>
                    );
                })}
            </TabList>
            <TabPanels>
                {tabs.map((tab, index) => {
                    return (
                        <TabPanel
                            key={index}
                            className="mx-auto px-2"
                            style={{
                                maxWidth: "800px",
                            }}
                        >
                            {tab.content}
                        </TabPanel>
                    );
                })}
            </TabPanels>
        </Tabs>
    );
};
