import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Fade,
    HStack,
    IconButton,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { CErrorDisplayer, IdDisplayer } from "..";
import { Frown, User, Users } from "react-feather";
import { getCompParticipants } from "@util/api/competitions";
import { NextRouter } from "next/router";
import { ICompParticipants, IToastOptions } from "@util/interfaces";
import { COMPETITION_PARTICIPANT_ITEMS_PER_PAGE, LEADERBOARD_ITEMS_PER_PAGE } from "@util/constants";
import { RepeatIcon } from "@chakra-ui/icons";
import { Pagination } from "@components/pagination";

export const OwnerTeams = (props: {
    isOwner: boolean;
    isCollaborator: boolean;
    owner: {
        full_name: string;
        fusion_user_id: string;
        profile_picture: null | string;
        username: string;
    };
    toast: IToastOptions;
    router: NextRouter;
}) => {
    return (
        <Box className={`w-full h-full px-4 flex flex-col `}>
            <CompetitionParticipants toast={props.toast} router={props.router} />
        </Box>
    );
};
const CompetitionParticipants = (props: { toast: IToastOptions; router: NextRouter }) => {
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [participantData, setParticipantData] = useState<{
        results: Array<ICompParticipants>;
        total: number;
    }>({ results: [], total: 0 });

    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(props.router.query.p ? parseInt(props.router.query.p.toString()) : 1);

    const setData = async () => {
        setLoading(true);
        const startIndex = (currentPage - 1) * COMPETITION_PARTICIPANT_ITEMS_PER_PAGE;
        const endIndex = startIndex + COMPETITION_PARTICIPANT_ITEMS_PER_PAGE;
        const serverResponse = (await getCompParticipants(
            props.router.query.competition,
            {
                start: startIndex,
                end: endIndex,
            },
            {
                toast: props.toast,
                requesterPath: props.router.asPath,
            },
        )) as Response;

        if (serverResponse.ok) {
            setIsError(false);
            const result = await serverResponse.json();
            setTotalItems(result.total);
            setParticipantData(result);
        } else {
            setIsError(true);
        }

        setLoading(false);
    };

    useEffect(() => {
        setData();
    }, []);

    useEffect(() => {
        setData();
    }, [currentPage]);

    if (loading) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner mt={4} />
                {/* <Text fontSize="lg">در حال دریافت اطلاعات این چالش...</Text> */}
            </Box>
        );
    }

    if (isError) {
        return (
            <Fade in={isError}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    return (
        <Box>
            {participantData.total ? (
                <Box className={`flex flex-col w-full`}>
                    <IconButton
                        aria-label="reload"
                        icon={<RepeatIcon />}
                        size="sm"
                        w="fit-content"
                        mb={4}
                        alignSelf="flex-end"
                        onClick={() => {
                            setData();
                        }}
                    />

                    <Table>
                        <Thead>
                            <Tr>
                                <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                    کد شرکت‌کننده
                                </Th>
                                <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                    نحوه شرکت
                                </Th>
                                <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                    شرکت‌کننده
                                </Th>
                                <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                    Actions
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {participantData.results.map((element, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td
                                            textAlign="center"
                                            py="0"
                                            borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                        >
                                            {element.id}
                                        </Td>
                                        <Td
                                            textAlign="center"
                                            py="0"
                                            borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                        >
                                            {element.team.individual ? (
                                                <HStack spacing={4} justifyContent="center">
                                                    <User />
                                                    <Text>انفرادی</Text>
                                                </HStack>
                                            ) : (
                                                <HStack spacing={4} justifyContent="center">
                                                    <Users />
                                                    <Text>تیمی</Text>
                                                </HStack>
                                            )}
                                        </Td>
                                        <Td
                                            textAlign="center"
                                            py="0"
                                            borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                        >
                                            <Button
                                                variant="ghost"
                                                minW="208px"
                                                justifyContent="flex-start"
                                                padding="24px 20px"
                                                margin="12px 20px"
                                            >
                                                <IdDisplayer name={element.team.name} image={element.team.image} />
                                            </Button>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                    <Pagination
                        totalRecords={totalItems}
                        pageNeighbours={1}
                        currentPage={currentPage}
                        onPageChanged={(page) => {
                            setCurrentPage(page);
                            props.router.push(`/c/${props.router.query.competition}/team?p=${page}`, undefined, {
                                shallow: true,
                            });
                        }}
                    />
                </Box>
            ) : (
                <CErrorDisplayer
                    icon={Frown}
                    text={<Text fontSize="lg">هنوز شرکت‌کننده‌ای در مسابقه شما شرکت نکرده است.</Text>}
                    hasButton={false}
                />
            )}
        </Box>
    );
};
