import React, { useState, useRef, useCallback, useEffect } from "react";
import { Badge, Button, Icon, IconButton, Progress, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { submissionFileUploadDb, submissionFileUpload, submissionFileDelete } from "@util/api/problems";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { XCircle, Slash, Download, Upload, File } from "react-feather";
import { CheckmarkOutline, CloseOutline, Trash } from "react-ionicons";
import Style from "./settings.module.css";
import { NextRouter } from "next/router";
import { IToastOptions } from "@util/interfaces";
import { error_500_toast_message } from "@util/strings";

export const SampleSubmissionFile = (props: {
    url: string | null;
    file: File | null;
    setFile: React.Dispatch<React.SetStateAction<File>>;
    path: string;
    router: NextRouter;
    toast: IToastOptions;
}) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const cancelFileUpload = useRef(null);
    const [canceled, setCanceled] = useState(false);

    const [delLoading, setDelLoading] = useState(false);

    const cancelUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current();
        }
    };

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile?.[0];
        if (!file) {
            return;
        }

        try {
            props.setFile(file);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const { getInputProps, getRootProps } = useDropzone({
        onDrop,
    });

    const _attachToDb = async (filename: string, path: string) => {
        const serverResponse = (await submissionFileUploadDb(path, filename, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                variant: "subtle",
                isClosable: true,
                duration: 9000,
                description: "فایل نمونه پاسخ چالش شما با موفقیت آپلود و در سیستم ثبت شد! ",
            });
            setDone(true);
            setError(false);
            setDisabled(true);
        } else {
            props.toast({
                title: "مشکلی در آپلود فایل رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setError(true);
            setDisabled(false);
        }
        setProgress(0);
        setUploading(false);
        setLoading(false);
    };
    const putFileReq = async (file: File, url: string) => {
        setUploading(true);
        await axios
            .put(url, file, {
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
                cancelToken: new axios.CancelToken((cancel) => (cancelFileUpload.current = cancel)),
            })
            .then(() => {
                setUploading(true);
                _attachToDb(props.file.name, props.path);
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    setCanceled(true);
                } else {
                    props.toast({
                        description: error_500_toast_message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                        variant: "subtle",
                    });
                    console.log(thrown.response);
                    setError(true);
                }
                setDisabled(false);
                setUploading(false);
                setDone(false);
                setProgress(0);
            });
    };
    const onUploadSampleSubmissionFile = async () => {
        setLoading(true);
        setDisabled(true);
        setCanceled(false);

        const serverResponse = (await submissionFileUpload(props.path, props.file.name, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const url = await serverResponse.json();
            putFileReq(props.file, url);
        } else {
            props.toast({
                title: "مشکلی در آپلود فایل رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setLoading(false);
    };

    const onDeleteFile = async () => {
        setDisabled(true);
        setDelLoading(true);
        const serverResponse = (await submissionFileDelete(props.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "info",
                variant: "subtle",
                isClosable: true,
                duration: 3000,
                description: "فایل نمونه پاسخ با موفقیت حذف شد!",
            });
            props.setFile(null);
        } else {
            props.toast({
                description: "مشکلی در حذف فایل به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",

                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
        }
        setDisabled(false);
        setDelLoading(false);
    };

    useEffect(() => {
        if (props.file) {
            onUploadSampleSubmissionFile();
        }
    }, [props.file]);

    if (props.url) {
        return (
            <div>
                <Text>
                    شما قبلاً فایل ارزیاب چالش (evaluation file) را آپلود کرده‌اید. برای دانلود کردن این فایل می‌توانید روی دکمه "دریافت
                    فایل" کلیک کنید. شما می‌توانید فایل را حذف کنید یا اگر قصد تعویض یا به روزرسانی این فایل را دارید روی دکمه "انتخاب و
                    آپلود فایل جدید" کلیک کنید.
                </Text>

                <div className={`flex flex-row justify-end items-center w-full mb-4 ${Style.eval_actions_button_container}`}>
                    <Button
                        leftIcon={<Trash color={useColorModeValue("#c53030", "#feb2b2")} width={"18px"} height={"18px"} />}
                        colorScheme={"red"}
                        isDisabled={disabled}
                        isLoading={delLoading}
                        variant="ghost"
                        ml={"auto"}
                        size="sm"
                        onClick={onDeleteFile}
                    >
                        حذف فایل
                    </Button>
                    <Button
                        onClick={() => {
                            window.open(props.url, "_blank");
                        }}
                        size="sm"
                        leftIcon={<Download />}
                        isDisabled={disabled}
                        variant="link"
                    >
                        دریافت فایل
                    </Button>

                    <Button
                        leftIcon={<Upload />}
                        size="sm"
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                        {...getRootProps()}
                        isDisabled={disabled}
                        isLoading={loading}
                        mr={2}
                    >
                        انتخاب و آپلود فایل جدید <input {...getInputProps()} />
                    </Button>
                </div>
                {props.file && (
                    <div className={`flex flex-row w-full ${Style.eval_file_status_container}`}>
                        <div className={`flex flex-row justify-start items-center ml-4`}>
                            <Icon as={File} w={4} h={4} ml={3} />
                            <Text mb={0} maxW={"300px"} fontWeight={600} isTruncated className={`${Style.eval_file_name}`}>
                                {props.file.name}
                            </Text>
                        </div>
                        {error ? (
                            <div className="flex flex-row w-full">
                                <Badge mx={2} px={2} py={1} colorScheme="red" className={`${Style.status}`}>
                                    <div className="flex flex-row items-center">
                                        <Icon as={XCircle} w={4} h={4} ml={2} />
                                        مشکل در آپلود فایل{" "}
                                    </div>
                                </Badge>
                            </div>
                        ) : (
                            <div className={`flex flex-row items-center w-full`}>
                                {!canceled && uploading && <Spinner size="sm" mx={2} />}
                                {!done && !canceled && progress !== 100 && (
                                    <Progress
                                        className={`${Style.eval_progressbar}`}
                                        // sx={{ direction: "ltr" }}
                                        w={"120px"}
                                        value={progress}
                                        borderRadius={5}
                                        my={4}
                                        size="xs"
                                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                    />
                                )}
                                {!done ? (
                                    !canceled ? (
                                        <IconButton
                                            variant="ghost"
                                            aria-label="cancel-request"
                                            size={"xs"}
                                            colorScheme={"red"}
                                            icon={<CloseOutline color={"red"} />}
                                            mx={2}
                                            onClick={() => {
                                                cancelUpload();
                                            }}
                                        />
                                    ) : (
                                        <Badge mx={2} px={2} py={1} colorScheme="gray" className={`${Style.status}`}>
                                            <div className="flex flex-row items-center">
                                                <Icon as={Slash} w={4} h={4} ml={2} />
                                                لغو آپلود توسط کاربر{" "}
                                            </div>
                                        </Badge>
                                    )
                                ) : (
                                    <Badge mx={2} px={2} py={1} colorScheme="green" className={`${Style.status}`}>
                                        <div className="flex flex-row items-center">
                                            <CheckmarkOutline color={"#68D391"} cssClasses={`ml-1`} width={"18px"} height={"18px"} />
                                            آپلود موفقیت‌آمیز فایل{" "}
                                        </div>
                                    </Badge>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
    return (
        <div>
            <div className={`flex flex-row justify-between items-center w-full mb-4 ${Style.eval_nofile_container}`}>
                <Text mb={0}>برای آپلود کردن فایل نمونه پاسخ (sample submission file) روی دکمه "انتخاب و آپلود فایل" کلیک کنید.</Text>
                <Button
                    isDisabled={disabled}
                    isLoading={loading}
                    {...getRootProps()}
                    leftIcon={<Upload />}
                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                    bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                >
                    <input {...getInputProps()} />
                    انتخاب و آپلود فایل
                </Button>
            </div>
            {props.file && (
                <div className={`flex flex-row w-full ${Style.eval_file_status_container}`}>
                    <div className={`flex flex-row justify-start items-center ml-4`}>
                        <Icon as={File} w={4} h={4} ml={3} />
                        <Text mb={0} fontWeight={600} isTruncated className={`${Style.eval_file_name}`}>
                            {props.file.name}
                        </Text>
                    </div>
                    {error ? (
                        <div className="flex flex-row w-full">
                            <Badge mx={2} px={2} py={1} colorScheme="red" className={`${Style.status}`}>
                                <div className="flex flex-row items-center">
                                    <Icon as={XCircle} w={4} h={4} ml={2} />
                                    مشکل در آپلود فایل{" "}
                                </div>
                            </Badge>
                        </div>
                    ) : (
                        <div className={`flex flex-row items-center w-full`}>
                            {!canceled && uploading && <Spinner size="sm" ml={2} />}
                            {!done && !canceled && progress !== 100 && (
                                <Progress
                                    className={`${Style.eval_progressbar}`}
                                    // sx={{ direction: "ltr" }}
                                    w={"120px"}
                                    value={progress}
                                    borderRadius={5}
                                    my={4}
                                    size="xs"
                                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                />
                            )}
                            {!done ? (
                                !canceled ? (
                                    <IconButton
                                        variant="ghost"
                                        aria-label="cancel-request"
                                        size={"xs"}
                                        colorScheme={"red"}
                                        icon={<CloseOutline color={"red"} />}
                                        mx={2}
                                        onClick={() => {
                                            cancelUpload();
                                        }}
                                    />
                                ) : (
                                    <Badge mx={2} px={2} py={1} colorScheme="gray" className={`${Style.status}`}>
                                        <div className="flex flex-row items-center">
                                            <Icon as={Slash} w={4} h={4} ml={2} />
                                            لغو آپلود توسط کاربر{" "}
                                        </div>
                                    </Badge>
                                )
                            ) : (
                                <Badge mx={2} px={2} py={1} colorScheme="green" className={`${Style.status}`}>
                                    <div className="flex flex-row items-center">
                                        <CheckmarkOutline color={"#68D391"} cssClasses={`ml-1`} width={"18px"} height={"18px"} />
                                        آپلود موفقیت‌آمیز فایل
                                    </div>
                                </Badge>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
