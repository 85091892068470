import React, { useState, useRef, useEffect } from "react";
import {
    Alert,
    AlertTitle,
    AlertIcon,
    AlertDescription,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tab,
    useColorModeValue,
    Box,
    Spinner,
    Text,
    useMediaQuery,
    IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";
import { RoboEpicsMarkdown } from "@components";
import "katex/dist/katex.min.css";
import JsxParser from "react-jsx-parser";
import TeX from "@matejmazur/react-katex";
import Notebook from "@util/renderers/notebook";
import RoboEpicsFormRenderer from "@util/renderers/form";
import { Edit2 } from "react-feather";
import { Add, Trash } from "react-ionicons";
import { problemTextDelete, problemTextGet, problemTextListGet } from "@util/api/problem_text";
import { EditView } from "./EditView";
import { AddView } from "./AddView";
import { IProblemEnterSubmission, IProblemText, IProblemTextSet, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const Overview = (props: {
    problemtext_set: Array<IProblemTextSet>;
    fetchingSubmissions?: boolean;
    submissions?: Array<IProblemEnterSubmission>;
    participantId?: string | null;
    problemEnterId?: number;
    isOwner: boolean;
    isCollaborator: boolean;
    problemPath: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [renderData, setRenderData] = useState<Array<IProblemTextSet>>(props.problemtext_set);
    const [tabIndex, setTabIndex] = useState(0);
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [changeOrientation] = useMediaQuery("(min-width:769px)");
    const [editMode, setEditMode] = useState(false);
    const [editTitle, setEditTitle] = useState(tabIndex === renderData.length ? "" : renderData[tabIndex].title);
    const [editConfirmed, setEditConfirmed] = useState(false);
    const [addConfirmed, setAddConfirmed] = useState(false);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();

    const onDeleteProblemText = async () => {
        setDeleteLoading(true);
        const serverResponse = (await problemTextDelete(renderData[tabIndex].id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "بخش انتخاب شده با موفقیت از توضیحات چالش حذف شد!",
                title: renderData[tabIndex].title,
            });
            const newRenderData = renderData.filter((el) => el.id !== renderData[tabIndex].id);
            setRenderData(newRenderData);
            setTabIndex(0);
        } else {
            props.toast({
                title: "مشکلی در حذف بخش انتخاب شده رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setDeleteLoading(false);
        setDeleteAlertVisible(false);
    };

    const onReload = async () => {
        const result = (await problemTextListGet(props.problemPath, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (result.ok) {
            const data: IProblemTextSet[] = await result.json();
            setRenderData(data);
        } else {
            setErrorLoading({ hasError: true, code: result.status, details: await result.json(), status: result.statusText });
        }
        setEditMode(false);
        setAddConfirmed(false);
        setLoadingComponent(false);
        setEditConfirmed(false);
    };
    useEffect(() => {
        if (editConfirmed || addConfirmed) onReload();
    }, [editConfirmed, addConfirmed]);

    if (loadingComponent) {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Spinner mb={4} />
            </Box>
        );
    }

    if (errorLoading.hasError) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده‌های این بخش رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    if (!renderData && !(props.isOwner || props.isCollaborator)) {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Text>برگزارکننده هنوز توضیحاتی برای این چالش قرار نداده است.</Text>
            </Box>
        );
    }

    if (renderData || ((props.isOwner || props.isCollaborator) && !renderData)) {
        return (
            <>
                <Tabs
                    size="md"
                    isLazy
                    orientation={changeOrientation ? "vertical" : "horizontal"}
                    index={tabIndex}
                    onChange={(index) => {
                        setTabIndex(index);
                        setEditTitle(index === renderData.length ? "" : renderData[index].title);
                        setEditMode(false);
                    }}
                    colorScheme={useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold")}
                >
                    {(props.isOwner || props.isCollaborator || renderData.length > 1) && (
                        <TabList overflowY={changeOrientation ? "unset" : "hidden"} overflowX={changeOrientation ? "unset" : "auto"}>
                            {renderData.map((el, index) => {
                                return (
                                    <Tab
                                        id={el.id.toString()}
                                        key={el.order}
                                        sx={
                                            changeOrientation
                                                ? {
                                                      marginTop: "0.5rem",
                                                      marginBottom: "0.5rem",
                                                      width: "170px",
                                                      display: "flex",
                                                      justifyContent: "flex-end",
                                                      paddingRight: "0.5rem",
                                                      textAlign: "end",
                                                  }
                                                : {
                                                      marginBottom: "0",
                                                      paddingTop: "1rem",
                                                      minWidth: "fit-content",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      paddingRight: "0.5rem",
                                                      textAlign: "center",
                                                  }
                                        }
                                        _selected={
                                            changeOrientation
                                                ? {
                                                      fontWeight: 700,
                                                      borderColor: useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                                                  }
                                                : {
                                                      px: "1.2rem",
                                                      fontWeight: 600,
                                                      borderColor: useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                                                  }
                                        }
                                        _hover={{
                                            borderColor:
                                                index !== tabIndex
                                                    ? useColorModeValue("RoboEpics.azure.300", "RoboEpics.gold.100")
                                                    : useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                                        }}
                                    >
                                        <>
                                            {editMode && index === tabIndex ? editTitle : el.title}
                                            {(props.isOwner || props.isCollaborator) && (
                                                <>
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditMode(true);
                                                        }}
                                                        size="xs"
                                                        mr={2}
                                                        aria-label="edit-problem-text"
                                                        icon={<Edit2 size="13" />}
                                                    />
                                                    <IconButton
                                                        size="xs"
                                                        mr={2}
                                                        aria-label="edit-problem-text"
                                                        icon={<Trash color="#c53030" height="15px" width="15px" />}
                                                        onClick={() => {
                                                            setDeleteAlertVisible(true);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    </Tab>
                                );
                            })}
                            {(props.isOwner || props.isCollaborator) && (
                                <Tab
                                    id={"new"}
                                    key={"new"}
                                    sx={
                                        changeOrientation
                                            ? {
                                                  marginTop: "0.5rem",
                                                  marginBottom: "0.5rem",
                                                  width: "170px",
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  paddingRight: "0.5rem",
                                                  textAlign: "end",
                                              }
                                            : {
                                                  marginBottom: "0",
                                                  paddingTop: "1rem",
                                                  minWidth: "fit-content",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  paddingRight: "0.5rem",
                                                  textAlign: "center",
                                              }
                                    }
                                    _selected={
                                        changeOrientation
                                            ? {
                                                  fontWeight: 700,
                                                  borderColor: useColorModeValue("RoboEpics.turquoise.400", "RoboEpics.gold.400"),
                                              }
                                            : {
                                                  px: "1.2rem",
                                                  fontWeight: 600,
                                                  borderColor: useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                                              }
                                    }
                                    _hover={{
                                        borderColor:
                                            renderData.length !== tabIndex
                                                ? useColorModeValue("RoboEpics.azure.300", "RoboEpics.gold.100")
                                                : useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400"),
                                    }}
                                >
                                    <Box className={`flex flex-row items-center`}>
                                        <Add color={useColorModeValue("#000", "#fff")} height="20px" width="20px" cssClasses={`ml-2`} />
                                        بخش جدید
                                    </Box>
                                </Tab>
                            )}
                        </TabList>
                    )}
                    <TabPanels>
                        {renderData.map((el) => {
                            return (
                                <TabPanel key={el.id}>
                                    <Box minHeight="500px" maxWidth="1047px">
                                        <ProblemText
                                            id={tabIndex === renderData.length ? -1 : renderData[tabIndex].id}
                                            editMode={editMode}
                                            editConfirmed={editConfirmed}
                                            setEditMode={setEditMode}
                                            setEditTitle={setEditTitle}
                                            editTitle={editTitle}
                                            setEditConfirmed={setEditConfirmed}
                                            fetchingSubmissions={props.fetchingSubmissions}
                                            participantId={props.participantId}
                                            problemEnterId={props.problemEnterId}
                                            submissions={props.submissions}
                                            isOwner={props.isOwner}
                                            isCollaborator={props.isCollaborator}
                                            toast={props.toast}
                                            router={props.router}
                                        />
                                    </Box>
                                </TabPanel>
                            );
                        })}
                        {(props.isOwner || props.isCollaborator) && (
                            <TabPanel>
                                <Box
                                    sx={{
                                        minHeight: "500px",
                                        maxWidth: "1047px",
                                    }}
                                >
                                    <AddView
                                        path={props.problemPath}
                                        setAddConfirmed={setAddConfirmed}
                                        toast={props.toast}
                                        router={props.router}
                                    />
                                </Box>
                            </TabPanel>
                        )}
                    </TabPanels>
                </Tabs>
                <AlertDialog
                    motionPreset="scale"
                    isOpen={deleteAlertVisible}
                    leastDestructiveRef={cancelRef}
                    onClose={() => {
                        setDeleteAlertVisible(false);
                    }}
                    isCentered
                >
                    <AlertDialogOverlay />
                    <AlertDialogContent>
                        <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            با کلیک روی دکمه "تایید" این بخش از توضیحات حذف خواهد شد و قابل بازگشت نخواهد بود.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    setDeleteAlertVisible(false);
                                }}
                            >
                                انصراف
                            </Button>
                            <Button
                                colorScheme="red"
                                mr={3}
                                onClick={() => {
                                    onDeleteProblemText();
                                }}
                                isLoading={deleteLoading}
                            >
                                تایید
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </>
        );
    }
};

const ProblemText = (props: {
    id: number;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    editMode: boolean;
    setEditTitle: React.Dispatch<React.SetStateAction<string>>;
    editTitle: string;
    editConfirmed: boolean;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    participantId?: string;
    submissions?: Array<IProblemEnterSubmission>;
    fetchingSubmissions?: boolean;
    isOwner?: boolean;
    isCollaborator?: boolean;
    problemEnterId?: number;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [content, setContent] = useState("");
    const [contentType, setContentType] = useState<10 | 20 | 30 | 40 | 50>(10);
    const setData = async () => {
        setLoading(true);
        const result = (await problemTextGet(props.id, { toast: props.toast, requesterPath: props.router.asPath })) as Response;
        if (result.ok) {
            const data: IProblemText = await result.json();
            setContentType(data.content_type);
            setContent(data.text);
        } else {
            setErrorLoading({ hasError: true, code: result.status, details: await result.json(), status: result.statusText });
            setContent("");
            setContentType(10);
        }
        setLoading(false);
    };
    const getContentView = (type: 10 | 20 | 30 | 40 | 50, content: string) => {
        switch (type) {
            case 10:
                return <span>{content}</span>;
            case 20:
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case 40:
                return <Notebook notebook={content} />;
            case 30:
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case 50:
                return (
                    <RoboEpicsFormRenderer
                        canView={props.isOwner || props.isCollaborator || !!props.participantId}
                        shouldRender={(props.submissions && props.submissions.length === 0) || props.isOwner || props.isCollaborator}
                        isLoading={props.fetchingSubmissions}
                        form={JSON.parse(content.toString())}
                        problemEnterId={props.problemEnterId}
                        reloadComponent={props.setEditConfirmed}
                        toast={props.toast}
                        router={props.router}
                    />
                );
        }
    };

    useEffect(() => {
        if (props.editConfirmed) setData();
    }, [props.editConfirmed]);
    useEffect(() => {
        setData();
    }, []);
    if (loading) {
        return (
            <Box className="flex flex-col items-center my-8">
                <Spinner mb={4} />
                <Text fontSize="md" fontWeight={600} textAlign="center">
                    در حال دریافت اطلاعات این بخش <br /> ممکن است به دلیل حجم بالای داده‌ها این بارگیری کمی به طول بیانجامد و نشان‌دهنده
                    وجود مشکل در سیستم نیست. <br /> لطفاً منتظر بمانید
                </Text>
            </Box>
        );
    }
    if (errorLoading.hasError) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    مشکلی در دریافت داده‌های این بخش رخ داد.
                </AlertTitle>
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    return (
        <>
            {props.editMode ? (
                <EditView
                    id={props.id}
                    setEditMode={props.setEditMode}
                    setEditTitle={props.setEditTitle}
                    editTitle={props.editTitle}
                    content={content}
                    contentType={contentType}
                    setEditConfirmed={props.setEditConfirmed}
                    toast={props.toast}
                    router={props.router}
                />
            ) : (
                getContentView(contentType, content)
            )}
        </>
    );
};
