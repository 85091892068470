import React, { useState } from "react";
import { NextRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setColorMode } from "@redux/actions";
import {
    Box,
    useColorModeValue,
    useColorMode,
    useTheme,
    Link as ChakraLink,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerContent,
    DrawerBody,
} from "@chakra-ui/react";
import { Button, IconButton, Avatar, AvatarBadge, Img } from "@chakra-ui/react";
import { UserAvatar as UserAvatarMenu, Notifications } from "@components";
import { Sunny, Moon, Bug, LogoDiscord } from "react-ionicons";
import * as CookiesUtil from "@util/cookies";
import cssClasses from "./header.module.css";
import Link from "next/link";
import { Menu } from "react-feather";
import { IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";

export const Header = (props: { toast: IToastOptions; user: IUser; router: NextRouter }) => {
    const dispatch = useDispatch();
    const { colorMode, toggleColorMode } = useColorMode();
    const theme = useTheme();
    const isLoggedIn = props.user.data.id && CookiesUtil.getCookie("re_auth_access_token");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Box className={`shadow ${cssClasses.header_main}`} bg={useColorModeValue("#fff", "RoboEpics.black.900")}>
            <div
                className="flex h-full mx-auto flex-row justify-between items-center"
                style={{
                    // maxWidth: "1280px",
                    width: "calc(100% - 4rem)",
                }}
            >
                {/* Logo */}
                <div className={`${cssClasses.header_links_break}`}>
                    <IconButton
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        aria-label="open-drawer"
                        onClick={() => {
                            setIsDrawerOpen(true);
                        }}
                        icon={<Menu height="18px" width="18px" color={useColorModeValue("#000", "#fff")} />}
                    />
                </div>
                <Drawer
                    isOpen={isDrawerOpen}
                    placement="top"
                    onClose={() => {
                        setIsDrawerOpen(false);
                    }}
                >
                    <DrawerOverlay />
                    <DrawerContent className={`${cssClasses.drawer_content}`}>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            <Box
                                margin="0"
                                onClick={() => {
                                    props.router.push("/");
                                }}
                            >
                                <Img
                                    src="/images/logo-01.png"
                                    width={200}
                                    height={100}
                                    objectFit={"cover"}
                                    style={{
                                        filter: useColorModeValue("invert(0)", "invert(1)"),
                                        cursor: "pointer",
                                    }}
                                    mx="auto"
                                />
                            </Box>
                        </DrawerHeader>
                        <DrawerBody>
                            <div className={`flex flex-row justify-center ${cssClasses.drawer_links}`}>
                                <Link href="/c" passHref>
                                    <ChakraLink
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        مسابقه‌ها
                                    </ChakraLink>
                                </Link>
                                {/* <Link href="/notebooks" passHref>
                                    <ChakraLink
                                        mr={8}
                                        _hover={{
                                            color: useColorModeValue(
                                                "RoboEpics.turquoise.600",
                                                "RoboEpics.azure.200",
                                            ),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        نوت‌بوک‌ها
                                        <sup
                                            className={`${cssClasses.sup}`}
                                            style={{
                                                color: useColorModeValue(
                                                    "white",
                                                    "black",
                                                ),
                                                backgroundColor: useColorModeValue(
                                                    "#03d0f9",
                                                    "#ffe562",
                                                ),
                                            }}
                                        >
                                            جدید!
                                        </sup>
                                    </ChakraLink>
                                </Link> */}
                                <Link href="/terms" passHref>
                                    <ChakraLink
                                        mr={8}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        قوانین و مقررات{" "}
                                    </ChakraLink>
                                </Link>
                                <Link href="/about" passHref>
                                    <ChakraLink
                                        mr={8}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        درباره ما{" "}
                                    </ChakraLink>
                                </Link>
                                <Link href="/contact" passHref>
                                    <ChakraLink
                                        mr={8}
                                        _hover={{
                                            color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                                        }}
                                        fontWeight="semibold"
                                    >
                                        تماس با ما{" "}
                                    </ChakraLink>
                                </Link>
                            </div>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                <div className={`flex flex-row items-center ${cssClasses.header_links}`}>
                    <Box
                        margin="0"
                        onClick={() => {
                            props.router.push("/");
                        }}
                        className={cssClasses.logo_container}
                        _hover={{
                            scale: "1.1",
                        }}
                    >
                        <Img
                            src="/images/logo-01.png"
                            width={20}
                            height={50}
                            objectFit={"cover"}
                            style={{
                                filter: useColorModeValue("invert(0)", "invert(1)"),
                                cursor: "pointer",
                            }}
                        />
                    </Box>
                    <Link href="/c" passHref>
                        <ChakraLink
                            mr={8}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                            }}
                            fontWeight="semibold"
                        >
                            مسابقه‌ها{" "}
                        </ChakraLink>
                    </Link>
                    {/* <Link href="/notebooks" passHref>
                        <ChakraLink
                            mr={8}
                            _hover={{
                                color: useColorModeValue(
                                    "RoboEpics.turquoise.600",
                                    "RoboEpics.azure.200",
                                ),
                            }}
                            fontWeight="semibold"
                        >
                            نوت‌بوک‌ها
                            <sup
                                className={`${cssClasses.sup}`}
                                style={{
                                    color: useColorModeValue("white", "black"),
                                    backgroundColor: useColorModeValue(
                                        "#03d0f9",
                                        "#ffe562",
                                    ),
                                }}
                            >
                                جدید!
                            </sup>
                        </ChakraLink>
                    </Link> */}
                    <Link href="/terms" passHref>
                        <ChakraLink
                            mr={8}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                            }}
                            fontWeight="semibold"
                        >
                            قوانین و مقررات{" "}
                        </ChakraLink>
                    </Link>
                    <Link href="/about" passHref>
                        <ChakraLink
                            mr={8}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                            }}
                            fontWeight="semibold"
                        >
                            درباره ما{" "}
                        </ChakraLink>
                    </Link>
                    <Link href="/contact" passHref>
                        <ChakraLink
                            mr={8}
                            _hover={{
                                color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.200"),
                            }}
                            fontWeight="semibold"
                        >
                            تماس با ما{" "}
                        </ChakraLink>
                    </Link>
                </div>
                {/* <div className="" style={{ flexBasis: "25%" }}>
                    <InputGroup>
                        <InputLeftElement
                            style={{ width: "32px", height: "32px" }}
                        >
                            <IconButton
                                size="sm"
                                aria-label="جستجو"
                                icon={<SearchIcon />}
                            />
                        </InputLeftElement>
                        <Input
                            size="sm"
                            variant="filled"
                            placeholder="جستجو"
                            _focus={{
                                border: "0",
                                background: "initial",
                            }}
                        />
                    </InputGroup>
                </div> */}
                {/* Options */}
                <div className="flex justify-end items-center">
                    <IconButton
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        aria-label="گزینه ها"
                        onClick={() => {
                            dispatch(setColorMode(colorMode == "light" ? "dark" : "light"));
                            toggleColorMode();
                        }}
                        icon={
                            colorMode === "dark" ? <Sunny color="#fff" height="18px" width="18px" /> : <Moon height="18px" width="18px" />
                        }
                    />
                    <IconButton
                        as="a"
                        href="https://discord.gg/8DMfjmn6gc"
                        // isDisabled
                        target="_blank"
                        aria-label="discord"
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        icon={<LogoDiscord width="20px" height="20px" color={useColorModeValue("#000", "#fff")} />}
                    />
                    <IconButton
                        className="mr-1"
                        variant="ghost"
                        size="sm"
                        aria-label="bug-report"
                        onClick={() => {
                            props.router.push("/bug-report");
                        }}
                        icon={<Bug height="18px" width="18px" color={useColorModeValue("#000", "#fff")} />}
                    />

                    {isLoggedIn ? (
                        <>
                            <Notifications toast={props.toast} router={props.router} />
                            <UserAvatarMenu
                                user={props.user}
                                Button={
                                    <Avatar size="xs" className="mr-3" src={props.user.data.imageUrl}>
                                        <AvatarBadge
                                            boxSize="1.25em"
                                            bg="green.500"
                                            style={{
                                                right: 0,
                                            }}
                                        />
                                    </Avatar>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                className="mr-4"
                                size="sm"
                                onClick={() => {
                                    props.router.push(`/users/signin?returnUrl=${props.router.asPath}`);
                                }}
                            >
                                ورود
                            </Button>
                            <Button
                                className="mr-2"
                                size="sm"
                                color="white"
                                bg={theme.colors.RoboEpics.azure["500"]}
                                _hover={{
                                    bg: theme.colors.RoboEpics.azure["600"],
                                }}
                                onClick={() => {
                                    props.router.push(`/users/signup?returnUrl=${props.router.asPath}`);
                                }}
                            >
                                ثبت نام
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Box>
    );
};
