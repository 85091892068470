import React from "react";
import { Box, Heading, List, ListItem, ListIcon, Text } from "@chakra-ui/react";
import Style from "./terms.module.css";
import { ChevronLeft } from "react-feather";

export const WebsiteTerms = () => {
    return (
        <Box as="div" className={`w-full h-full items-center justify-center my-12`}>
            <Heading size="xl" textAlign="center" fontWeight={500}>
                به
                <b>RoboEpics </b>
                خوش آمدید.
            </Heading>
            <Box as="div" className={`w-full h-full my-16 ${Style.container}`}>
                <List>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">
                            شرایط و مقررات کلی فعالیت در بستر <b>RoboEpics </b>{" "}
                        </Heading>
                    </ListItem>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        نیک می‌دانید که برای تضمین سلامت فعالیت‌های یک جامعه و جلوگیری از تضییع حقوق همه‌ی ما، وجود قانون یک ضرورت است. در
                        پلتفرم
                        <b>RoboEpics </b>
                        نیز برای ایجاد شرایط مطلوب جهت فعالیت از جمله برگزاری مسابقاتی سالم، نیاز است تا مقرراتی را تنظیم کنیم و به آن
                        پایبند باشیم. در این راستا شرایط و مقررات کلی فعالیت در بستر
                        <b>RoboEpics </b>
                        به شرح زیر تنظیم شده است. از شما تقاضا داریم موارد زیر را مطالعه کنید. پذیرش این مقررات به منزله‌ی انعقاد یک قرارداد
                        با
                        <b>RoboEpics </b>
                        است و برای هر دو طرف لازم‌الاجرا است. شایان ذکر است شرکت در هر مسابقه ممکن است دارای قوانین و مقررات جداگانه ای باشد
                        که پیش از ثبت نام در آن مسابقه به شما نمایش داده می شود و در صورت تأیید، مفاد آن به قرارداد حاضر الحاق خواهد شد. در
                        صورت داشتن هرگونه سؤال، نگرانی یا پیشنهاد، لطفاً با ما در ارتباط باشید.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 1: تعاریف و اصطلاحات </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        1 - 1- اصطلاحات به کار رفته در این قرارداد دارای معانی زیر است:
                    </Text>
                    <Text px={16} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        1 - 1 - 1-
                        <b>RoboEpics </b>: پلتفرم برگزاری مسابقات هوش مصنوعی است که نقش واسطه‌گری میان کاربران شرکت کننده در مسابقه و
                        برگزارکننده مسابقه را دارد.
                    </Text>

                    <Text px={16} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        1 - 1 - 2- کاربر: شخص حقیقی که اقدام به ایجاد حساب کاربری در سایت می‌نماید و پس از ثبت نام در عنوان شرکت کننده
                        شناخته می شود.
                    </Text>

                    <Text px={16} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        1 - 1 - 3- برگزارکننده: شخص حقیقی یا حقوقی که اقدام به برگزاری یک مسابقه بر روی پلتفرم
                        <b>RoboEpics </b>
                        نموده است.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 2: کلیات </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        2 - 1- کاربر متعهد به رعایت تمامی قوانین و مقررات جاری کشور است.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        2 - 2- کاربر تعهد نمود که از پلتفرم <b>RoboEpics </b>
                        تنها در راستای اهداف مورد نظر سایت استفاده نماید و از هرگونه استفاده‌ی غیرقانونی یا غیر متداول خودداری نماید.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"}>
                        2 - 3- کاربر تأیید می‌کند که در زمان پذیرش این مقررات دارای حداقل سن قانونی و اهلیت لازم جهت انعقاد قرارداد است.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 3: حساب کاربری </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        3 - 1- کاربر متعهد است در زمان ثبت نام و یا هر زمان دیگری که از او درخواست شود، اطلاعات صحیح، کامل، دقیق و متعلق به
                        خود را وارد نماید و در صورت تغییر، به‌روزرسانی نماید.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        3 - 2- کاربر نمی‌تواند حساب کاربری خود را بدون اجازه‌ی
                        <b>RoboEpics </b> به دیگری منتقل نماید و یا اجازه‌ی استفاده از آن را بدهد و متعهد است تلاش معقول را در راستای تأمین
                        امنیت اطلاعات خود، از جمله رمز عبور حساب کاربری، به عمل آورد.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        3 - 3- کاربر نمی‌تواند همزمان بیش از یک حساب کاربری فعال داشته باشد.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 4: حدود مسؤولیت‌ها </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        4 - 1- <b>RoboEpics </b> یک پلتفرم برگزاری مسابقات هوش مصنوعی است که اقدام به برقراری ارتباط میان برگزارکنندگان و
                        کاربران شرکت کننده می‌نماید؛ بدین ترتیب و با توجه به اصل «شخصی بودن مسؤولیت» <b>RoboEpics </b> هیچگونه مسؤولیتی در
                        قبال اعمال غیر قانونی ارتکاب یافته توسط دیگران نظیر نخواهد داشت و چنانچه برگزارکننده و یا کاربر و یا هر شخص دیگری،
                        ادعای نقض حقوق مادی یا معنوی خود از سوی طرف دیگر را داشته باشد، می‌تواند اقدامات حقوقی لازم را به عمل آورد.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        4 - 2- در صورتیکه به دلیل اتفاقات خارج از کنترل، نظیر بلایای طبیعی و یا اقدامات دولت و یا حملات سایبری، هرگونه خللی
                        در استفاده از سایت به وجود آید و از این طریق آسیبی به شخصی وارد شود، <b>RoboEpics </b> هیچگونه مسؤولیتی در قبال این
                        آسیب‌ها نخواهد داشت.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        4 - 3- در صورتی که کاربر معتقد باشد اطلاعات حساب کاربری او توسط فرد دیگری به صورت غیرمجاز در حال بهره برداری است، می
                        تواند مراتب را به <b>RoboEpics </b> اطلاع دهد؛ در غیر این صورت فرض بر این است که کاربر مسؤول تمام فعالیت هایی است که
                        از طریق حساب کاربری او انجام می شود.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 5: حریم خصوصی، محرمانگی اطلاعات و حقوق مالکیت معنوی </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 1- <b>RoboEpics </b> متعهد است ضمن حفظ حریم خصوصی کاربر، تمام تلاش معقول جهت حفظ اطلاعات حساب کاربری و جلوگیری
                        از افشای آن را به عمل آورد مگر مواردی نظیر اعلام نام شخص برنده که اقتضای مسابقه است.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 2- <b>RoboEpics </b> ممکن است اطلاعاتی نظیر میزان بازدید کاربر و یا سایر داده‌های آماری را جهت تحلیل و بهبود
                        عملکرد خود جمع‌آوری نماید و کاربر رضایت خود را در این خصوص اعلام می‌نماید.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 2- <b>RoboEpics </b> ممکن است اطلاعاتی نظیر میزان بازدید کاربر و یا سایر داده‌های آماری را جهت تحلیل و بهبود
                        عملکرد خود جمع‌آوری نماید و کاربر رضایت خود را در این خصوص اعلام می‌نماید.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 3- مالکیت کدهای ارسالی از سوی کاربران در جریان مسابقه یا دسترسی برگزارکننده به آن یا هرگونه استفاده از کدها منوط
                        به شرایط و مقررات جداگانه مخصوص به هر مسابقه است و در غیر این صورت مطابق اصول و قواعد کلی راجع به حقوق مالکیت معنوی
                        عمل خواهد شد و در هر صورت چنانچه اختلافی میان کاربر و برگزارکننده و یا اشخاص ثالث راجع به مالکیت کدها و یا استفاده‌ی
                        تجاری یا غیرتجاری از آن پدید آید <b>RoboEpics </b> هیچگونه مسؤولیتی نخواهد داشت.{" "}
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 4- کاربر موظف است اطلاعاتی که در جریان مسابقه دریافت می‌کند، نظیر داده‌های ارائه شده برای یادگیری مدل را محرمانه
                        تلقی نماید و از افشای بدون اجازه‌ی آن خودداری نماید؛ چراکه این اطلاعات ممکن است از اسرار تجاری برگزارکننده باشد و
                        افشای آن منجر به بروز آسیب شود. در صورت تخلف کاربر، <b>RoboEpics </b> هیچگونه مسؤولیتی نخواهد داشت.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 5- کاربر مجاز نیست به هیچ طریقی اعم از مستقیم یا غیر مستقیم اقدام و یا تلاش برای نفوذ غیرقانونی و دستیابی به
                        کدهای نرم‌افزاری پلتفرم <b>RoboEpics </b> و یا سایر داده‌هایی که متعلق به او نیست بنماید.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 6- بازنشر محتواهای نوشتاری و بصری تولید شده از سوی
                        <b>RoboEpics </b> مانند مقالات و غیره، با ذکر نام و منبع مجاز است؛ لیکن کلیه‌ی حقوق مرتبط با محتوای منتشر شده در
                        سایت که به صورت بازنشر بوده و پدید آورنده‌ی آن شخص دیگری باشد، متعلق به صاحب آن بوده و هرگونه استفاده‌ی غیرقانونی از
                        آن و نقض حقوق مالکیت معنوی، ممنوع است و حق پیگیری قانونی برای صاحب آن محفوظ خواهد بود.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        5 - 7- کاربر تعهد نمود که در فعالیت‌های خود، از نقض هرگونه مالکیت معنوی و استفاده‌ی غیرمجاز از داده‌های متعلق به
                        دیگران خودداری نماید.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 6: سایر مقررات </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        6 - 1- کاربر تعهد نمود که از بارگزاری داده و یا هرگونه محتوایی که مخالف نظم و عفت عمومی جامعه باشد خودداری نموده و
                        در هر حال قوانین و مقررات کشور را در این خصوص رعایت نماید.
                    </Text>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        6-2- پذیرش مفاد این قرارداد و یا شرکت در مسابقات، به منزله‌ی ایجاد هیچگونه رابطه‌ی استخدامی یا پیمانی میان{" "}
                        <b>RoboEpics </b> و کاربر نیست.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        6 - 3- چنانچه برگزارکننده مسابقات قصد پیش‌بینی شرایط و مقرراتی فراتر از شرایط و مقررات فعلی را داشته باشد، کاربر
                        متعهد به قبول آن موارد است و در غیر این صورت امکان شرکت در آن مسابقه را نخواهد داشت.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 7: تغییر شرایط و مقررات </Heading>
                    </ListItem>

                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        در صورت تغییر شرایط و مقررات، این موضوع به کاربر اطلاع رسانی خواهد شد و سپس لازم الاجرا خواهد بود و استفاده‌ی بعدی
                        کاربر از سایت، به منزله‌ی پذیرش تغییرات است؛ به همین دلیل مقتضی است کاربر اطلاع رسانی‌های ارسال شده را به خوبی
                        مطالعه نماید.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 8: ضمانت اجرای نقض تعهدات </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        8 - 1- در صورتیکه تخلف از هر یک از موارد مندرج در این قرارداد برای <b>RoboEpics </b> محرز شود، ضمن اطلاع رسانی برای
                        کاربر و تقاضای اخذ توضیح، عنداللزوم حساب کاربری وی تا اتخاذ تصمیم نهایی در خصوص موضوع توسط <b>RoboEpics </b> مسدود
                        خواهد شد. چنانچه کاربر با تصمیم <b>RoboEpics </b> مخالف باشد، می‌تواند مراتب اعتراض و توضیحات خود را ارائه نماید تا
                        پس از بررسی، تصمیم مقتضی اتخاذ شود؛ لیکن کاربر بابت دوران منع فعالیت و یا مسدودی حساب خود، حق هیچگونه مطالبه‌ای
                        نخواهد داشت.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        8 - 2- در صورتیکه در اثر نقض تعهدات و یا هرگونه اقدام خلاف قانون، آسیبی اعم از مادی و معنوی به <b>RoboEpics </b>{" "}
                        وارد شود، حق مراجعه به مراجع قانونی و تقاضای جبران خسارت محفوظ است.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 9: حل و فصل اختلافات </Heading>
                    </ListItem>

                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        به جز مواردی که در این قرارداد تصمیم‌گیری قطعی راجع به مسائل به <b>RoboEpics </b> محول شده است، در صورت بروز هرگونه
                        اختلافی فیمابین <b>RoboEpics </b> و کاربر، اختلافات و دعاوی به مرکز داوری اتاق بازرگانی، صنایع، معادن و کشاورزی
                        ایران ارجاع می‌گردد که مطابق اساسنامه و آیین داوری آن مرکز با رأی یک نفر داور با توافق طرفین به صورت قطعی و
                        لازم‌الاجرا حل و فصل می‌گردد. داور علاوه بر مقررات حاکم، عرف تجاری ذیربط را نیز مراعات خواهد نمود. شرط داوری حاضر،
                        موافقتنامه‌ای مستقل تلقی می‌شود و در هر حال لازم‌الاجرا می‌باشد.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 10: انحلال قرارداد و آثار آن </Heading>
                    </ListItem>
                    <Text px={8} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        10 - 1- این قرارداد و تعهدات آن در موارد زیر منحل خواهد شد:
                    </Text>
                    <Text px={16} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        10 - 1 - 1- در صورتیکه به تشخیص <b>RoboEpics </b> حساب کاربر به صورت دائمی مسدود شود؛
                    </Text>

                    <Text px={16} py={2} mb={0} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        10 - 1 - 2- در صورتیکه کاربر تقاضای بستن حساب کاربری خود را نماید؛
                    </Text>

                    <Text px={16} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} mb={0} className={`${Style.text}`}>
                        10 - 1 - 3- در صورت دریافت دستور قانونی از سوی مراجع ذیصلاح کشور مبنی بر بستن حساب کاربری.
                    </Text>
                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        10 - 2- مقرراتی از این قرارداد که جنبه‌ی عمومی داشته و عرفاً محدود به مدت زمان استفاده‌ی کاربر از سایت نیست، مانند
                        مقررات موضوع مواد 4، 5، 8 و 9، پس از انحلال قرارداد نیز به قوت خود باقی و برای طرفین لازم الاجرا است.
                    </Text>
                    <ListItem className="flex">
                        <ListIcon as={ChevronLeft} className={`${Style.list_icon}`} />
                        <Heading size="md">ماده 11: ارزش اثباتی قرارداد </Heading>
                    </ListItem>

                    <Text px={8} py={2} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                        این قرارداد در 11 ماده تنظیم شد و تأیید آن از سوی کاربر بر اساس موارد6، 7و 12 قانون تجارت الکترونیک به‌منزله‌ی تأیید
                        بوده و دارای ارزش اثباتی معادل اسناد کاغذی است.
                    </Text>
                </List>
            </Box>
        </Box>
    );
};
