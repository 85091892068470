import axios from "axios";
import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const getSingleProblem = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}`, {}, "preferred", ctx);

export const getAllProblems = async (ctx?: IServerSideContext) => await fetchWithRefresh(`/problem/`, {}, "preferred", ctx);

export const searchProblemByTitle = async (parameters: Record<string, string>, ctx?: IServerSideContext) => {
    let query = "";
    for (const [key, value] of Object.entries(parameters)) {
        query += `&${key}=${value}`;
    }
    query = query.slice(1);

    return await fetchWithRefresh(`/problem/?${query}`, {}, "off", ctx);
};

export const problemCreate = async (
    title: string,
    short_description: string,
    repository_mode: number,
    evaluation_mode: number,
    code_execution: boolean,
    path?: string,
    ctx?: IServerSideContext,
) => {
    const reqData = {
        title,
        short_description,
        submission_score_calculator: 1,
        repository_mode,
        evaluation_mode,
        code_execution,
    };
    if (path) {
        Object.assign(reqData, { path });
    }

    return await fetchWithRefresh(
        `/problem/`,
        { method: "POST", body: JSON.stringify(reqData), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );
};

export const AddProblemToPhase = async (
    cid: string | string[],
    pid: string | string[] | number,
    problem_id: string | string[] | number,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/competition/${cid}/phases/${pid}/problems`,
        { method: "POST", body: JSON.stringify({ problem_id }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const problemDelete = async (problemId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${problemId}`, { method: "DELETE" }, "required", ctx);

export const problemEditFormdata = async (form: FormData, problem: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${problem}`, { method: "PATCH", body: form }, "required", ctx);

export const problemEditJson = async (
    data: Record<string, string | string[] | number | number[]>,
    problem: string | string[],
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/problem/${problem}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const submissionFileUpload = async (path: string, filename: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/sample-submission/upload`,
        { method: "POST", body: JSON.stringify({ filename }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const submissionFileUploadDb = async (path: string, filename: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/sample-submission`,
        { method: "POST", body: JSON.stringify({ filename }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const submissionFileDelete = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/sample-submission`, { method: "DELETE" }, "required", ctx);

export const evaluationFileUpload = async (path: string, filename: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/evaluation-file/upload`,
        { method: "POST", body: JSON.stringify({ filename }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const evaluationFileUploadDb = async (path: string, filename: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/evaluation-file`,
        { method: "POST", body: JSON.stringify({ filename }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const evaluationFileDelete = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/evaluation-file`, { method: "DELETE" }, "required", ctx);

export const retrieveProblemSubmissions = async (
    problemId: number | string | string[],
    range?: { start: number; end: number },
    ctx?: IServerSideContext,
) => {
    let queryParameter = "";
    if (range) {
        queryParameter += `?start=${range.start}&end=${range.end}`;
    }
    return await fetchWithRefresh(`/problem/${problemId}/submissions${queryParameter}`, {}, "preferred", ctx);
};

export const retrieveSubmissionResult = async (submission_id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/submission/${submission_id}/result`, { method: "POST" }, "required", ctx);

export const retrieveSubmissionResultContents = async (path: string) => {
    return await axios.get(path).then((res) => res.data);
};

export const buildEvaluator = async (path: string | string[], reference: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/build`,
        { method: "POST", body: JSON.stringify({ reference }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const changeSubmissionScoreManually = async (
    problem: string,
    sub_id: number,
    definition: number,
    value: string,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/problem/${problem}/submissions/${sub_id}/score`,
        {
            method: "POST",
            body: JSON.stringify({ definition: definition, value: value }),
            headers: { "content-type": "application/json" },
        },
        "required",
        ctx,
    );

export const retrieveProblemDataset = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/datasets`, {}, "preferred", ctx);

export const retrieveProblemCollaborators = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/collaborators`, {}, "preferred", ctx);

export const createProblemCollaborator = async (path: string | string[], username: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/collaborators`,
        { method: "POST", body: JSON.stringify({ user: username }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const removeProblemCollaborator = async (path: string | string[], id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/collaborators/${id}`, { method: "DELETE" }, "required", ctx);

export const getProblemHostNotebooks = async (problemId: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${problemId}/notebooks?host`, {}, "preferred", ctx);

export const getProblemNotebooks = async (problemId: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${problemId}/notebooks`, {}, "preferred", ctx);
