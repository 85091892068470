import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const retrieveProblemCodeset = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/codes`, {}, "preferred", ctx);

export const createProblemCodeset = async (problem: string, reqData: { path: string; name?: string }, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${problem}/codes`,
        { method: "POST", body: JSON.stringify(reqData), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );
