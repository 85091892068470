import React, { useState, useEffect, useRef } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Fade,
    useColorModeValue,
    Spinner,
    Text,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Table,
    Divider,
    Th,
    Thead,
    Tr,
    Tbody,
    Td,
    Badge,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";

import { ICollaboratorData, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";
import { searchUserByFullname } from "@util/api/profile";
import { retrieveProblemCollaborators, createProblemCollaborator, removeProblemCollaborator } from "@util/api/problems";
import { Activity, ArrowDownCircle, ArrowUpCircle, Trash, UserPlus, X } from "react-feather";
import { SearchIcon } from "@chakra-ui/icons";
import { MiniUserProfile } from "@components/miniUserProfile";
import { COLLABORATORS_ACCESS_LEVEL } from "@util/constants";

export const CollaboratorSettings = (props: {
    owner: {
        full_name: string;
        fusion_user_id: string;
        username: string;
        profile_picture: string;
    };
    isOwner: boolean;
    isCollaborator: boolean;
    collaborators: Array<ICollaboratorData>;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [loadingTable, setLoadingTable] = useState(false);
    const [editConfirmed, setEditConfirmed] = useState(false);
    const [collaborators, setCollaborators] = useState<Array<ICollaboratorData>>(props.collaborators ? props.collaborators : null);

    const [addAlertVisible, setAddAlertVisible] = useState(false);
    const [removeAlertVisible, setRemoveAlertVisible] = useState(false);

    const inputDelayTimer = useRef(null);
    const searchInputEl = useRef(null);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const search = async (input: string) => {
        setIsSearching(true);
        const serverResponse = (await searchUserByFullname(input, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const sr = await serverResponse.json();
            setSearchResult(sr);
            setShowSearchResult(true);
            setIsSearching(false);
        }
    };

    const [selectedUser, setSelectedUser] = useState<{
        username: string;
        fullname: string;
        fusion_user_id: string;
        profile_picture: string;
    }>(null);
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);

    const onSearchInputChange = async (input: string) => {
        setSearchInput(input);
        setIsSearching(false);
        setShowSearchResult(false);
        clearTimeout(inputDelayTimer.current);
        if (input.length) {
            inputDelayTimer.current = setTimeout(async () => {
                search(input);
            }, 500);
        } else {
            setSearchResult([]);
            setShowSearchResult(false);
        }
    };

    const setData = async () => {
        const serverResponse = (await retrieveProblemCollaborators(props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setCollaborators(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
        setLoadingComponent(false);
    };
    const onReload = async () => {
        setLoadingTable(true);
        await setData();
        setLoadingTable(false);
    };

    useEffect(() => {
        if (editConfirmed) onReload();
    }, [editConfirmed]);

    if (loadingComponent) {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Spinner mb={4} />
            </Box>
        );
    }
    if (errorLoading.hasError) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    if (!errorLoading.hasError && !loadingComponent) {
        return (
            <Box className={`flex flex-col w-full items-center`}>
                {props.isOwner && (
                    <>
                        <Box className={`flex flex-row items-center mb-4 self-end w-1/2`}>
                            <Text mb={0} textAlign="end" ml={2} flexBasis="100%">
                                <Icon as={UserPlus} mx={2} />
                                اضافه کردن یاور به تیم:
                            </Text>
                            <Box position="relative" w="full">
                                <InputGroup
                                    sx={{
                                        boxShadow: showSearchResult ? "0px -5px 10px -5px rgba(0,0,0,0.2)" : "none",
                                    }}
                                >
                                    <Input
                                        ref={searchInputEl}
                                        size="sm"
                                        placeholder="جستجو بر اساس نام کامل..."
                                        onChange={(e) => onSearchInputChange(e.target.value)}
                                        value={searchInput}
                                        isDisabled={!props.isOwner}
                                    />
                                    <InputLeftElement
                                        sx={{
                                            height: "32px",
                                            width: "32px",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <IconButton
                                            // isDisabled={!teamName}
                                            // isLoading={isLoading}
                                            bg={"transparent"}
                                            variant="unstyled"
                                            icon={<SearchIcon color="gray" />}
                                            aria-label="search-teams"
                                            pointerEvents="none"
                                            height="32px"
                                            width="32px"
                                        />
                                    </InputLeftElement>
                                    <InputRightElement
                                        sx={{
                                            height: "32px",
                                            width: "32px",
                                            paddingLeft: "6px",
                                        }}
                                        children={
                                            isSearching ? (
                                                <Spinner size="sm" />
                                            ) : (
                                                searchInput.length !== 0 && (
                                                    <IconButton
                                                        sx={{
                                                            borderRadius: "999px",
                                                            height: "20px",
                                                            width: "20px",
                                                            minWidth: "20px",
                                                            padding: "2px",
                                                        }}
                                                        aria-label="Cancel search"
                                                        onClick={() => {
                                                            setSearchResult([]);
                                                            setSearchInput("");
                                                            setShowSearchResult(false);
                                                            searchInputEl.current.focus();
                                                        }}
                                                        icon={<X size={18} />}
                                                    />
                                                )
                                            )
                                        }
                                    />
                                </InputGroup>
                                {showSearchResult && (
                                    <Box
                                        className="py-0 px-2 overflow-y-auto border border-t-0 rounded-lg rounded-t-none"
                                        sx={{
                                            position: "absolute",
                                            zIndex: "50",
                                            top: "100%",
                                            left: "0",
                                            right: "0",
                                            width: "100%",
                                            maxHeight: "300px",
                                            transition: "height 0.3s",
                                            boxShadow: "0px 15px 20px 2px rgba(0,0,0,0.2)",
                                        }}
                                        bg={useColorModeValue("#F7F7F8", "#14181d")}
                                    >
                                        {searchResult.length !== 0 ? (
                                            searchResult.map((result) => {
                                                return (
                                                    <Box className={`flex pb-1 my-1`}>
                                                        <MiniUserProfile
                                                            fullName={result.full_name}
                                                            username={result.username}
                                                            imageFilename={result.profile_picture}
                                                            onClick={() => {
                                                                setAddAlertVisible(true);
                                                                setSelectedUser(result);
                                                            }}
                                                        />
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Text m={0} py={1} fontSize="13px" color={"gray.400"}>
                                                نتیجه‌ای پیدا نشد!
                                            </Text>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <>
                    <Table size="md">
                        <Thead>
                            <Tr>
                                <Th>ردیف</Th>
                                <Th>همیار</Th>
                                <Th>سطح اختیارات همیار</Th>
                                {props.isOwner && <Th>Actions</Th>}
                            </Tr>
                        </Thead>
                        {!loadingTable && (
                            <Tbody>
                                <Tr>
                                    <Td>1</Td>
                                    <Td>
                                        <MiniUserProfile
                                            fullName={props.owner.full_name}
                                            imageFilename={props.owner.profile_picture}
                                            size="sm"
                                            username={props.owner.username}
                                        />
                                    </Td>
                                    <Td>
                                        <Badge>صاحب چالش</Badge>
                                    </Td>
                                    <Td></Td>
                                </Tr>
                                {collaborators?.map((collaborator, index) => {
                                    return (
                                        <Tr key={`problem-collaborator-${collaborator.id}`}>
                                            <Td>{index + 2}</Td>
                                            <Td>
                                                <MiniUserProfile
                                                    fullName={collaborator.user.full_name}
                                                    imageFilename={collaborator.user.profile_picture}
                                                    size="sm"
                                                    username={collaborator.user.username}
                                                />
                                            </Td>
                                            <Td>
                                                <Badge
                                                    colorScheme={COLLABORATORS_ACCESS_LEVEL[collaborator.access_level].color}
                                                    key={COLLABORATORS_ACCESS_LEVEL[collaborator.access_level].key}
                                                >
                                                    {COLLABORATORS_ACCESS_LEVEL[collaborator.access_level].label}
                                                </Badge>
                                            </Td>
                                            {props.isOwner && (
                                                <Td>
                                                    <HStack spacing={3}>
                                                        <IconButton
                                                            aria-label="upgrade-collaborator"
                                                            isDisabled={collaborator.access_level === 50}
                                                            size="sm"
                                                            icon={<ArrowUpCircle />}
                                                            colorScheme="green"
                                                        />
                                                        <IconButton
                                                            aria-label="downgrade-collaborator"
                                                            isDisabled={collaborator.access_level === 10}
                                                            size="sm"
                                                            icon={<ArrowDownCircle />}
                                                            colorScheme="orange"
                                                        />
                                                        <IconButton
                                                            aria-label="activity-collaborator"
                                                            isDisabled
                                                            size="sm"
                                                            colorScheme="blue"
                                                            icon={<Activity />}
                                                        />
                                                        <IconButton
                                                            aria-label="kick-collaborator"
                                                            size="sm"
                                                            colorScheme="red"
                                                            variant="outline"
                                                            icon={<Trash color={useColorModeValue("#c53030", "#feb2b2")} />}
                                                            onClick={() => {
                                                                setSelectedCollaborator(collaborator.id);
                                                                setRemoveAlertVisible(true);
                                                            }}
                                                        />
                                                    </HStack>
                                                </Td>
                                            )}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        )}
                    </Table>
                    {loadingTable && <Spinner size="sm" my={2} />}
                </>
                {props.isOwner && (
                    <>
                        <AddAlertDialog
                            selectedUser={selectedUser}
                            isOpen={addAlertVisible}
                            onClose={() => {
                                setAddAlertVisible(false);
                                setSelectedUser(null);
                            }}
                            setEditConfirmed={setEditConfirmed}
                            toast={props.toast}
                            router={props.router}
                        />
                        <RemoveAlertDialog
                            isOpen={removeAlertVisible}
                            onClose={() => {
                                setRemoveAlertVisible(false);
                                setSelectedCollaborator(null);
                            }}
                            selectedCollaborator={selectedCollaborator}
                            setEditConfirmed={setEditConfirmed}
                            toast={props.toast}
                            router={props.router}
                        />
                    </>
                )}
            </Box>
        );
    }
};
const AddAlertDialog = (props: {
    selectedUser: {
        username: string;
        fullname: string;
        fusion_user_id: string;
        profile_picture: string;
    };
    onClose: () => void;
    isOpen: boolean;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const cancelRef = useRef();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        setDisabled(props.selectedUser ? false : true);
    }, [props.selectedUser]);
    const onConfirm = async () => {
        setLoading(true);
        const serverResponse = (await createProblemCollaborator(props.router.query.problem, props.selectedUser.username, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
            props.onClose();
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setLoading(false);
    };
    return (
        <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose} isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        اضافه کردن به همیاران
                    </AlertDialogHeader>

                    <AlertDialogBody>آیا از تصمیم خود برای اضافه کردن این کاربر به همیاران چالش اطمینان دارید؟</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={props.onClose} isDisabled={loading || disabled}>
                            انصراف
                        </Button>
                        <Button colorScheme="RoboEpics.azure" onClick={onConfirm} mr={3} isLoading={loading} isDisabled={disabled}>
                            تأیید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
const RemoveAlertDialog = (props: {
    onClose: () => void;
    isOpen: boolean;
    selectedCollaborator: number;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const cancelRef = useRef();
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setDisabled(props.selectedCollaborator ? false : true);
    }, [props.selectedCollaborator]);
    const onConfirm = async () => {
        setLoading(true);
        const serverResponse = (await removeProblemCollaborator(props.router.query.problem, props.selectedCollaborator, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
            props.onClose();
        } else {
            props.toast({
                description: "مشکلی در اعمال تغییرات شما  به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
        }
        setLoading(false);
    };

    return (
        <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose} isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        حذف از همیاران
                    </AlertDialogHeader>

                    <AlertDialogBody>آیا از تصمیم خود برای حذف کردن این یاور از همیاران این چالش اطمینان دارید؟</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={props.onClose} isDisabled={disabled || loading}>
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={() => {
                                onConfirm();
                            }}
                            isLoading={loading}
                            isDisabled={disabled}
                            mr={3}
                        >
                            تأیید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
