import React, { useEffect, useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { problemEditFormdata } from "@util/api/problems";
import { getImageURL } from "@util/mediaLoader";
import Style from "./challenge.module.css";
import { Edit2 } from "react-feather";
import { AddOutline, BulbOutline, Trash } from "react-ionicons";
import { CustomAvatar } from "@components/avatar";
import CropModal, { b64toBlob } from "@util/CropModal";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const ThumbnailImageChange = (props: {
    toast: IToastOptions;
    thumbnailImage: string;
    setThumbnailImage: React.Dispatch<React.SetStateAction<string>>;
    router: NextRouter;
}) => {
    const inputRef = useRef(null);
    const [tmpThumbnailImage, setTmpThumbnailImage] = useState<string>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();

    const checkFileAttributes = (file: File) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };

    const onChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // const file = acceptedFile?.[0];
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                isClosable: true,
                variant: "subtle",
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 9000,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpThumbnailImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };

    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpThumbnailImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        props.setThumbnailImage(canvas.toDataURL("image/jpeg"));
        setEditAllowed(true);
    };
    const compileFormData = (thumbnailImage: string) => {
        const form_data = new FormData();

        if (thumbnailImage) {
            const block = thumbnailImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("thumbnail", blob, `problem_${props.router.query.problem}_thumbnail.png`);
            }
        } else if (thumbnailImage === null) {
            form_data.append("thumbnail", "");
        }
        return form_data;
    };
    const editThumbnailImage = async (thumbnailImage: string) => {
        const req_data = compileFormData(thumbnailImage);
        const serverResponse = (await problemEditFormdata(req_data, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setThumbnailImage(thumbnailImage);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setDeleteAlertVisible(false);
        setEditAllowed(false);
        setDeleteLoading(false);
    };
    useEffect(() => {
        if (editAllowed) editThumbnailImage(props.thumbnailImage);
    }, [props.thumbnailImage]);
    return (
        <Box className="grid">
            <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
            <CropModal
                modalTitle={"تصویر چالش"}
                src={tmpThumbnailImage}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={(data) => {
                    crop(data);
                    setIsModalOpen(false);
                }}
                aspectX={1}
                aspectY={1}
            />

            <div className={`${Style.thumbnailPictureDarkHover}`}>
                <IconButton
                    size="xs"
                    bg={"transparent"}
                    aria-label="new profile picture"
                    icon={props.thumbnailImage ? <Edit2 color="#fff" size="20" /> : <AddOutline color="#fff" height="21px" width="21px" />}
                    _hover={{
                        bg: "blackAlpha.800",
                    }}
                    onClick={() => {
                        inputRef.current.click();
                    }}
                />
                {props.thumbnailImage && (
                    <IconButton
                        className="mr-0.5"
                        size="xs"
                        bg={"transparent"}
                        aria-label="remove profile picture"
                        icon={<Trash color="#c53030" height="19px" width="19px" />}
                        _hover={{
                            bg: "blackAlpha.800",
                        }}
                        onClick={() => setDeleteAlertVisible(true)}
                    />
                )}
            </div>
            <CustomAvatar
                width={"4rem"}
                height={"4rem"}
                showPlaceholder={props.thumbnailImage === null}
                placeholder={<BulbOutline color={useColorModeValue("black", "white")} />}
                shiftToTop={false}
                src={props.thumbnailImage?.includes("data:image/") ? props.thumbnailImage : getImageURL(props.thumbnailImage)}
            />
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                    setTmpThumbnailImage(props.thumbnailImage);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        با کلیک روی دکمه "تایید" تصویر چالش حذف خواهد شد و برای تعیین تصویر جدید باید فایل عکس را آپلود کنید.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                editThumbnailImage(null);
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
};
