import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Style from "./about.module.css";

export const AboutUs = () => {
    return (
        <Box as="div" className={`w-full h-full items-center justify-center my-12 ${Style.container}`}>
            <Heading size="xl" textAlign="center" fontWeight={500}>
                درباره پلتفرم
                <b> RoboEpics </b>
            </Heading>
            <Box as="div" className={`w-full h-full my-16 ${Style.container}`}>
                <Text px={8} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                    هوشمندی همیشه ما را مجذوب خود کرده است. این همان چیزی است که به ما امکان حل مشکلات، یافتن ایده‌های خلاقانه و دستیابی به
                    موفقیتهای علمی را داده و جهانی را که امروز در آن زندگی می‌کنیم، ایجاد کرده است. اکتشافات بسیاری در انتظار ماست و هنوز
                    بسیاری از چالش‌های ما حل نشده است. درست مانند تلسکوپ هابل که به ما کمک می کند تا عمق فضا را ببینیم، هدف ما ساخت هوش
                    مصنوعی پیشرفته برای گسترش دانش و یافتن پاسخ‌های جدید است. با حل این مسأله، می‌توانیم در حل هزاران مشکل به مردم کمک کنیم.
                </Text>
                <Text px={8} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                    ما معتقدیم که هوش مصنوعی یک توانمند‌ساز پر قدرت است که به طرق مختلف بر تمام سازمان‌ها تأثیر مثبت می‌گذارد. ما معتقدیم که
                    هوش مصنوعی اگرچه در چند سال گذشته به طور قابل توجهی رشد کرده است، اما هنوز در مراحل ابتدایی خود قرار دارد.
                </Text>
                <Text px={8} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                    ما به خرد جمعی باور داریم و معتقدیم که گروه بزرگی از افراد با داشتن ابزارهای مناسب که فرایندها را بهبود بخشد و تعامل
                    جمعی را تسهیل کند، می‌توانند در مدت زمانی کوتاه، به پیشرفت‌هایی باورنکردنی دست یابند.
                </Text>
                <Text px={8} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                    ما معتقدیم که در بسیاری از شرایط، پیشرفت سریع در فضای باز، به مراتب ارزشمندتر از یک پیشرفت آهسته در پشت درهای بسته است.
                    به عنوان یک اثر جانبی، تعداد بیشتری از افراد می توانند از نتایج به اشتراک گذاشته شده بهره‌مند شوند.
                </Text>
                <Text px={8} fontSize={"1rem"} lineHeight={"1.8rem"} className={`${Style.text}`}>
                    به دلایل بالا، ما معتقدیم که توسعه‌ی راه‌حل‌های هوش مصنوعی از طریق جمع‌سپاری در یک فضای باز، برای سازمان‌ها بسیار
                    ارزشمند است؛ زیرا به آن‌ها امکان می‌دهد تا پیشرفت خود را در توسعه‌ و پیاده‌سازی هوش مصنوعی شتاب دهند و سریع‌تر به اهداف
                    خود برسند. این چشم‌انداز همچنین برای جامعه‌ای وسیع‌تر، که از اشتراک دستاوردها و آموخته‌‌های به دست آمده در طول این مسیر
                    بهره‌مند می‌شوند، ارزش بالاتری را خلق می کند.
                </Text>
            </Box>
            <Heading size="lg" textAlign="center">
                این همان ارزشی است که ما می‌خواهیم در RoboEpics ایجاد کنیم.
            </Heading>
        </Box>
    );
};
