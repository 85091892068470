import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorModeValue,
    useMediaQuery,
} from "@chakra-ui/react";

import { PROBLEM_SETTING_TABS_ADVANCED, PROBLEM_SETTING_TABS_SIMPLE } from "@util/constants";
import { BasicSettings } from "./basicSettings";
import { SimpleEvaluationSettings } from "./evaluationSettings";
import { AdvancedEvaluationSettings } from "./AdvancedEvaluationSettings";
import { getSingleProblem } from "@util/api/problems";
import { GimulatorSettings } from "./gimulatorSettings";
import { IChallenge, ICollaboratorData, IToastOptions } from "@util/interfaces";
import { RepositoriesSettings } from "./repositoriesSettings";
import { ScoresDefinitionSettings } from "./scoresDefinitionSettings";
import { CollaboratorSettings } from "./collaboratorsSettings";
import { NextRouter } from "next/router";

export const SettingsOverview = (props: {
    collaboratorsData: Array<ICollaboratorData>;
    problemData: IChallenge["data"];
    isClassroom: boolean;
    isOwner: boolean;
    isCollaborator: boolean;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [advancedTabIndex, setAdvancedTabIndex] = useState(0);
    const [simpleTabIndex, setSimpleTabIndex] = useState(0);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ hasError: false, code: 1, details: "", status: "" });

    const [changeOrientation] = useMediaQuery("(min-width:769px)");

    const [problemData, setProblemData] = useState<IChallenge["data"]>(props.problemData);
    const [settingsMode, setSettingsMode] = useState<"simple" | "advanced">("simple");
    const [editConfirmed, setEditConfirmed] = useState(false);
    // if any setting is changed set this to true and if user wanted to change tabs show an alert dialog and alert them that they have unsaved changes
    const [hasChanged, setHasChanged] = useState(false);
    const [alertdialogvisible, setAlertdialogvisible] = useState(false);

    const getViewByKey = (index: string, type: "simple" | "advanced") => {
        if (type === "advanced") {
            switch (PROBLEM_SETTING_TABS_ADVANCED[index].key) {
                case "Basic":
                    return (
                        <BasicSettings
                            setEditConfirmed={setEditConfirmed}
                            problem={props.problemData.path}
                            repositoryMode={props.problemData.repository_mode}
                            evaluationMode={props.problemData.evaluation_mode}
                            codeExecution={props.problemData.code_execution}
                            defaultResourceEphemeralLimit={parseInt(props.problemData.default_resource_ephemeral_limit.toString())}
                            defaultResourceMemoryLimit={parseInt(props.problemData.default_resource_memory_limit.toString())}
                            defaultResourceCPULimit={parseInt(props.problemData.default_resource_cpu_limit.toString())}
                            gimulatorResourceEphemeralLimit={parseInt(props.problemData.gimulator_resource_ephemeral_limit.toString())}
                            gimulatorResourceCPULimit={parseInt(props.problemData.gimulator_resource_cpu_limit.toString())}
                            gimulatorResourceMemoryLimit={parseInt(props.problemData.gimulator_resource_memory_limit.toString())}
                            computeResources={props.problemData.compute_resources}
                            isPublic={props.problemData.is_public}
                            setHasChanged={setHasChanged}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                case "Evaluation":
                    return (
                        <AdvancedEvaluationSettings
                            setEditConfirmed={setEditConfirmed}
                            problemPath={props.problemData.path}
                            setHasChanged={setHasChanged}
                            evaluationFileUrl={props.problemData.evaluation_file_url}
                            sampleSubmissionFileUrl={props.problemData.sample_submission_file_url}
                            problemCodeSet={props.problemData.problemcode_set}
                            router={props.router}
                            toast={props.toast}
                        />
                    );
                case "Gimulator":
                    return (
                        <GimulatorSettings
                            setEditConfirmed={setEditConfirmed}
                            setHasChanged={setHasChanged}
                            problem={props.problemData.path}
                            directorRoles={props.problemData.roles}
                            actorRoles={props.problemData.roles}
                            timeout={props.problemData.timeout}
                            terminateOnActorFailure={props.problemData.terminate_on_actor_failure}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                case "Repositories":
                    return (
                        <RepositoriesSettings
                            problem={props.problemData.path}
                            problemCodeSet={props.problemData.problemcode_set.slice(1)} // should be changed properly and divide to 2 sections : repos and evaluator; based on tags given;
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                case "Scores Definition":
                    return (
                        <ScoresDefinitionSettings
                            problem={props.problemData.path}
                            submissionScoreDefinitions={props.problemData.submission_score_definitions}
                            problemScoreDefinitions={props.problemData.score_definitions}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                case "Collaborators":
                    return (
                        <CollaboratorSettings
                            isOwner={props.isOwner}
                            isCollaborator={props.isCollaborator}
                            collaborators={props.collaboratorsData}
                            setEditConfirmed={setEditConfirmed}
                            owner={props.problemData.owner}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                default:
                    return (
                        <BasicSettings
                            setEditConfirmed={setEditConfirmed}
                            problem={props.problemData.path}
                            repositoryMode={props.problemData.repository_mode}
                            evaluationMode={props.problemData.evaluation_mode}
                            codeExecution={props.problemData.code_execution}
                            defaultResourceEphemeralLimit={parseInt(props.problemData.default_resource_ephemeral_limit.toString())}
                            defaultResourceMemoryLimit={parseInt(props.problemData.default_resource_memory_limit.toString())}
                            defaultResourceCPULimit={parseInt(props.problemData.default_resource_cpu_limit.toString())}
                            gimulatorResourceEphemeralLimit={parseInt(props.problemData.gimulator_resource_ephemeral_limit.toString())}
                            gimulatorResourceCPULimit={parseInt(props.problemData.gimulator_resource_cpu_limit.toString())}
                            gimulatorResourceMemoryLimit={parseInt(props.problemData.gimulator_resource_memory_limit.toString())}
                            computeResources={props.problemData.compute_resources}
                            isPublic={props.problemData.is_public}
                            setHasChanged={setHasChanged}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
            }
        } else {
            switch (PROBLEM_SETTING_TABS_SIMPLE[index]?.key) {
                case "Evaluation":
                    return (
                        <SimpleEvaluationSettings
                            setEditConfirmed={setEditConfirmed}
                            problemPath={props.problemData.path}
                            setHasChanged={setHasChanged}
                            evaluationFileUrl={props.problemData.evaluation_file_url}
                            sampleSubmissionFileUrl={props.problemData.sample_submission_file_url}
                            problemCodeSet={props.problemData.problemcode_set}
                            router={props.router}
                            toast={props.toast}
                        />
                    );
                case "Scores Definition":
                    return (
                        <ScoresDefinitionSettings
                            problem={props.problemData.path}
                            submissionScoreDefinitions={props.problemData.submission_score_definitions}
                            problemScoreDefinitions={props.problemData.score_definitions}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
                case "Collaborators":
                    return (
                        <CollaboratorSettings
                            isOwner={props.isOwner}
                            isCollaborator={props.isCollaborator}
                            collaborators={props.collaboratorsData}
                            setEditConfirmed={setEditConfirmed}
                            owner={props.problemData.owner}
                            toast={props.toast}
                            router={props.router}
                        />
                    );
            }
        }
    };

    const onReload = async () => {
        const serverResponse = (await getSingleProblem(props.problemData.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            setProblemData(result);
        } else {
            setError({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
        setLoading(false);
        setEditConfirmed(false);
        setHasChanged(false);
    };

    useEffect(() => {
        if (editConfirmed) onReload();
    }, [editConfirmed]);

    useEffect(() => {
        if (hasChanged) setAlertdialogvisible(true);
    }, [hasChanged]);
    if (loading) {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Spinner mb={4} />
            </Box>
        );
    }

    if (error.hasError) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {error.status ? `${error.code} - ${error.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {error.details && <AlertDescription fontSize="sm">{error.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    if (problemData) {
        return (
            <Tabs
                orientation="horizontal"
                variant="enclosed"
                size="lg"
                onChange={(index) => {
                    setSettingsMode(index ? "advanced" : "simple");
                }}
                isLazy
                mt={2}
            >
                <TabList>
                    <Tab>simple</Tab>
                    <Tab>advanced</Tab>
                </TabList>
                <TabPanels>
                    {/* simple settings; also  settings for classroom */}
                    <TabPanel>
                        <Tabs
                            size="md"
                            isLazy
                            orientation={changeOrientation ? "vertical" : "horizontal"}
                            index={simpleTabIndex}
                            onChange={(index) => {
                                setSimpleTabIndex(index);
                            }}
                            colorScheme={useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold")}
                        >
                            <TabList overflowY={changeOrientation ? "unset" : "hidden"} overflowX={changeOrientation ? "unset" : "auto"}>
                                {Object.keys(PROBLEM_SETTING_TABS_SIMPLE).map((index) => {
                                    return (
                                        <Tab
                                            id={index}
                                            key={`setting-tab-simple-${PROBLEM_SETTING_TABS_SIMPLE[index].key}`}
                                            sx={
                                                changeOrientation
                                                    ? {
                                                          marginTop: "0.5rem",
                                                          marginBottom: "0.5rem",
                                                          width: "170px",
                                                          display: "flex",
                                                          justifyContent: "flex-end",
                                                          paddingRight: "0.5rem",
                                                          textAlign: "end",
                                                      }
                                                    : {
                                                          marginBottom: "0",
                                                          paddingTop: "1rem",
                                                          minWidth: "fit-content",
                                                          display: "flex",
                                                          justifyContent: "center",
                                                          paddingRight: "0.5rem",
                                                          textAlign: "center",
                                                      }
                                            }
                                        >
                                            {PROBLEM_SETTING_TABS_SIMPLE[index].label}
                                        </Tab>
                                    );
                                })}
                            </TabList>
                            <TabPanels>
                                {Object.keys(PROBLEM_SETTING_TABS_SIMPLE).map((el) => {
                                    return (
                                        <TabPanel>
                                            <Box
                                                sx={{
                                                    minHeight: "500px",
                                                    maxWidth: "1047px",
                                                }}
                                            >
                                                {getViewByKey(el, settingsMode)}
                                            </Box>
                                        </TabPanel>
                                    );
                                })}
                            </TabPanels>
                        </Tabs>
                    </TabPanel>
                    {/* advanced settings; won't be shown for classroom */}
                    <TabPanel>
                        <Tabs
                            size="md"
                            isLazy
                            orientation={changeOrientation ? "vertical" : "horizontal"}
                            index={advancedTabIndex}
                            onChange={(index) => {
                                setAdvancedTabIndex(index);
                            }}
                            colorScheme={useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold")}
                        >
                            <TabList overflowY={changeOrientation ? "unset" : "hidden"} overflowX={changeOrientation ? "unset" : "auto"}>
                                {Object.keys(PROBLEM_SETTING_TABS_ADVANCED).map((index) => {
                                    return (
                                        <Tab
                                            id={index}
                                            key={`setting-tab-advanced-${PROBLEM_SETTING_TABS_ADVANCED[index].key}`}
                                            sx={
                                                changeOrientation
                                                    ? {
                                                          marginTop: "0.5rem",
                                                          marginBottom: "0.5rem",
                                                          width: "170px",
                                                          display: "flex",
                                                          justifyContent: "flex-end",
                                                          paddingRight: "0.5rem",
                                                          textAlign: "end",
                                                      }
                                                    : {
                                                          marginBottom: "0",
                                                          paddingTop: "1rem",
                                                          minWidth: "fit-content",
                                                          display: "flex",
                                                          justifyContent: "center",
                                                          paddingRight: "0.5rem",
                                                          textAlign: "center",
                                                      }
                                            }
                                        >
                                            {PROBLEM_SETTING_TABS_ADVANCED[index].label}
                                        </Tab>
                                    );
                                })}
                            </TabList>
                            <TabPanels>
                                {Object.keys(PROBLEM_SETTING_TABS_ADVANCED).map((el) => {
                                    return (
                                        <TabPanel>
                                            <Box
                                                sx={{
                                                    minHeight: "500px",
                                                    maxWidth: "1047px",
                                                }}
                                            >
                                                {getViewByKey(el, settingsMode)}
                                            </Box>
                                        </TabPanel>
                                    );
                                })}
                            </TabPanels>
                        </Tabs>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        );
    }
};
