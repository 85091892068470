import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const retrieveProblemEnterId = async (problem_enter_id: number | string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/enter/${problem_enter_id}`, {}, "required", ctx);

export const getTeamSubmissions = async (problem_enter_id: number, offset?: number, limit?: number, ctx?: IServerSideContext) => {
    let queryParameter = "";
    if (offset !== null && limit !== null) {
        queryParameter += `?offset=${offset}&limit=${limit}`;
    }
    return await fetchWithRefresh(`/problem/enter/${problem_enter_id}/submissions${queryParameter}`, {}, "off", ctx);
};

export const retrieveLogUrl = async (id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/runs/${id}/log`, { method: "POST" }, "required", ctx);

export const retrieveLogContents = async (url: string) => await fetch(url);

export const submitForm = async (problemEnterId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/enter/${problemEnterId}/submissions`, { method: "POST" }, "required", ctx);

export const uploadSubmission = async (message: string, formData: FormData, problemEnterId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/enter/${problemEnterId}/upload`,
        {
            method: "POST",
            body: JSON.stringify({ file: formData, message: message }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const enableNotebook4ProblemEnter = async (problemEnterId: string | number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/enter/${problemEnterId}/notebook`, { method: "POST" }, "required", ctx);

export const choooseProblemSubmission = async (problemEnterId: number, reference: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/enter/${problemEnterId}/submissions`,
        { method: "POST", body: JSON.stringify({ reference }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const uploadResult = async (filename: string, problemEnterId: number, ctx?: IServerSideContext) =>
    fetchWithRefresh(
        `/problem/enter/${problemEnterId}/upload-result`,
        { method: "POST", body: JSON.stringify({ filename }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const recentCommits = async (problemEnterId: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/enter/${problemEnterId}/recent-commits`, {}, "required", ctx);
