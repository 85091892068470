import React, { useState, useEffect, useImperativeHandle } from "react";
import { getIndividualInvites, createJoin } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Button,
    Box,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Badge,
} from "@chakra-ui/react";
import { CheckCircle, Slash } from "react-feather";
import { MiniUserProfile } from "@components";
import { NextRouter } from "next/router";
import { IToastOptions } from "@util/interfaces";

export const IndividualInvitesTable = React.forwardRef(
    (
        props: {
            refreshFunction: (value: boolean) => Promise<void>;
            invites?: Array<{
                team: {
                    id: number;
                    name: string;
                    image: string;
                    member_set: Array<{ user: { username: string; full_name: string; profile_picture: string } }>;
                    creator: { username: string; password: string };
                };
                status: string;
                statusColor: string;
                id: number;
            }>;
            showManageControls: boolean;
            canManage: boolean;
            compId: string;
            toast: IToastOptions;
            router: NextRouter;
        },
        ref,
    ) => {
        const [isLoading, setIsLoading] = useState(true);
        const [confirmModalVisible, setConfirmModalVisible] = useState(false);
        const [confirmDecision, setConfirmDecision] = useState<boolean>(false);
        const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
        const [invites, setInvites] = useState(props.invites);

        useImperativeHandle(ref, () => ({
            refresh() {
                getInvites();
            },
        }));

        const onJoinDecision = async (inviteId: number, decision: boolean) => {
            setConfirmLoading(true);

            const serverResponse = (await createJoin(inviteId, decision, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                if (!decision) {
                    setIsLoading(true);
                    await getInvites();
                    setConfirmModalVisible(false);
                    setConfirmLoading(false);
                }
                props.toast({
                    description: `عملیات با موفقیت انجام شد. ${decision ? "در حال انتقال ..." : ""}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                if (decision) {
                    await props.refreshFunction(true);
                }
            } else {
                props.toast({
                    title: "مشکلی در ساخت دعوت رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }

            setConfirmLoading(true);
        };
        const getInvites = async () => {
            const serverResponse = (await getIndividualInvites(props.compId, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;

            if (serverResponse.ok) {
                const data = await serverResponse.json();
                for (const r of data) {
                    switch (r.status.toString()) {
                        case "Received":
                            r.status = "در انتظار پاسخ";
                            r["statusColor"] = "blue";
                            break;
                        case "Denied":
                            r.status = "رد شده";
                            r["statusColor"] = "red";
                            break;
                        case "Accepted":
                            r.status = "پذیرفته شده";
                            r["statusColor"] = "green";
                            break;
                        case "Expired":
                            r.status = "منقضی شده";
                            r["statusColor"] = "orange";
                            break;
                        case "Closed":
                            r.status = "بسته شده";
                            r["statusColor"] = "gray";
                            break;
                        default:
                            r.status = "نامشخص";
                            r["statusColor"] = "gray";
                            break;
                    }
                }
                setInvites(data.reverse());
            } else {
                props.toast({
                    title: "مشکلی در دریافت دعوت‌ها رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
            setIsLoading(false);
        };

        useEffect(() => {
            getInvites();
        }, []);

        if (isLoading) {
            return (
                <Box mx="auto" w="min">
                    <Spinner my={4} />
                </Box>
            );
        }

        return (
            <Table mt={4} size="md" variant="simple">
                <Thead>
                    <Tr>
                        <Th pl={0} pr={1} minW="200px">
                            تیم
                        </Th>
                        <Th pl={0}>سرگروه</Th>
                        <Th pl={0}>وضعیت</Th>
                        {props.showManageControls && <Th>عملیات</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {invites.map((inviteData) => {
                        return (
                            <Tr
                                key={inviteData.team.id}
                                sx={{
                                    td: {
                                        paddingBottom: "12px",
                                        paddingTop: "12px",
                                        fontSize: "14px",
                                        fontWeight: "normal",
                                    },
                                }}
                            >
                                <Td pl={0} pr={1}>
                                    <MiniUserProfile size="sm" fullName={inviteData.team.name} imageFilename={inviteData.team.image} />
                                </Td>
                                <Td pl={0}>
                                    {inviteData.team.member_set.map((member) => {
                                        if (member.user.username === inviteData.team.creator.username)
                                            return (
                                                <MiniUserProfile
                                                    size="sm"
                                                    fullName={member.user.full_name}
                                                    username={member.user.username}
                                                    imageFilename={member.user.profile_picture}
                                                />
                                            );
                                        return <></>;
                                    })}
                                </Td>
                                <Td pl={0}>
                                    <Badge colorScheme={inviteData.statusColor} variant="subtle">
                                        {inviteData.status}
                                    </Badge>
                                </Td>
                                {props.showManageControls && (
                                    <Td pl={0}>
                                        {inviteData.status === "در انتظار پاسخ" && (
                                            <>
                                                <Button
                                                    isDisabled={!props.canManage}
                                                    onClick={() => {
                                                        setConfirmDecision(true);
                                                        setConfirmModalVisible(true);
                                                    }}
                                                    size="xs"
                                                    variant="outline"
                                                    colorScheme="green"
                                                    ml="3"
                                                    my="2"
                                                >
                                                    <CheckCircle size={14} className="ml-2" />
                                                    پذیرش
                                                </Button>
                                                <Button
                                                    isDisabled={!props.canManage}
                                                    onClick={() => {
                                                        setConfirmDecision(false);
                                                        setConfirmModalVisible(true);
                                                    }}
                                                    size="xs"
                                                    variant="outline"
                                                    colorScheme="red"
                                                >
                                                    <Slash size={14} className="ml-2" />
                                                    رد کردن
                                                </Button>
                                                <InviteDecisionConfirmAlert
                                                    isOpen={confirmModalVisible}
                                                    isLoading={confirmLoading}
                                                    onClose={() => setConfirmModalVisible(false)}
                                                    decision={confirmDecision}
                                                    teamName={inviteData.team.name}
                                                    onSuccess={() => onJoinDecision(inviteData.id, confirmDecision)}
                                                />
                                            </>
                                        )}
                                    </Td>
                                )}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        );
    },
);

const InviteDecisionConfirmAlert = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    decision: boolean;
    teamName: string;
    onSuccess: () => void;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{props.decision ? "پذیرش" : "رد کردن"} دعوت تیم</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    {props.decision
                        ? `در صورت پذیرش این دعوت، به تیم ${props.teamName} ملحق خواهید شد. آیا ادامه می‌دهید؟`
                        : `شما در حال رد کردن دعوت تیم ${props.teamName} هستید. این امر قابل بازگشت نیست. آیا ادامه می‌دهید؟`}
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
