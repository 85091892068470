import React from "react";
import { Box, Tooltip, Text, useColorModeValue } from "@chakra-ui/react";

interface MetadataProps {
    icon: React.ReactElement;
    text: string | number | null;
    tooltip?: string | null;
    size?: "sm" | "md" | "lg";
    className?: string;
    isIcon?: boolean;
    mainIcon?: React.ReactElement;
}

export const MetadataBlock = (props: MetadataProps) => {
    if (props.size === null) props.size = "sm";
    const iconSize = props.size === "sm" ? "16px" : props.size === "md" ? "20px" : "22px";
    const fontSize = props.size === "sm" ? "13px" : props.size === "md" ? "14px" : "16px";
    const spacing = props.size === "sm" ? "ml-1" : props.size === "md" ? "ml-2" : "ml-3";

    return (
        <Tooltip label={props.tooltip} aria-label={props.tooltip} closeDelay={100} placement="bottom">
            <Box className={`pr-2 flex flex-row justify-start items-center mb-0 ${props.className}`}>
                <Box>
                    {React.cloneElement(props.icon, {
                        cssClasses: spacing,
                        width: iconSize,
                        height: iconSize,
                        color: useColorModeValue("#000", "#fff"),
                    })}
                </Box>
                {props.isIcon ? (
                    props.mainIcon
                ) : (
                    <Text className="m-0" fontSize={fontSize} colorScheme="gray">
                        {props.text}
                    </Text>
                )}
            </Box>
        </Tooltip>
    );
};
