import { IServerSideContext } from "@util/interfaces";
import { fetchWithRefresh } from "./base";

export const bugReportCreate = async (email: string, subject: string, description: string, ctx?: IServerSideContext) =>
    fetchWithRefresh(
        `/misc/bug-report`,
        {
            method: "POST",
            body: JSON.stringify({ email, subject, description }),
            headers: { "content-type": "application/json" },
        },
        "required",
        ctx,
    );

export const contactUsCreate = async (email: string, name: string, subject: string, description: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/misc/contact-us`,
        {
            method: "POST",
            body: JSON.stringify({
                email,
                name,
                subject,
                description,
            }),
            headers: { "content-type": "application/json" },
        },
        "preferred",
        ctx,
    );

export const retrieveStats = async (ctx?: IServerSideContext) => await fetchWithRefresh(`/misc/stats`, {}, "off", ctx);
