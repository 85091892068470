import { IServerSideContext } from "@util/interfaces";
import { fetchWithRefresh } from "./base";

export const CompetitionLeaderboard = async (
    competition_id: string | string[] | number,
    range?: { start: number; end: number },
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/competition/${competition_id}/leaderboard${range ? `?start=${range.start}&end=${range.end}` : ""}`,
        {},
        "preferred",
        ctx,
    );

export const CompetitionLeaderboardModal = async (
    competition_id: string | string[] | number,
    participant_id: number,
    ctx?: IServerSideContext,
) => await fetchWithRefresh(`/competition/${competition_id}/leaderboard/${participant_id}`, {}, "preferred", ctx);

export const retrieveProblemLeaderboard = async (
    problem: string | string[] | number,
    id: number,
    range?: { start: number; end: number },
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/problem/${problem}/leaderboards/${id}${range ? `?start=${range.start}&end=${range.end}` : ""}`,
        {},
        "preferred",
        ctx,
    );

export const retrieveProblemLeaderboards = async (problem: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${problem}/leaderboards`, {}, "preferred", ctx);
