import React, { useState, useEffect, useImperativeHandle } from "react";
import { getTeamRequests, actForRequest } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    Button,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Box,
    Table,
    Tr,
    Th,
    Td,
    Tbody,
    Spinner,
    Badge,
    Thead,
} from "@chakra-ui/react";
import { MiniUserProfile } from "@components/miniUserProfile";
import { CheckCircle, Slash } from "react-feather";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

const RequestsView = React.forwardRef(
    (
        props: {
            refreshFunction: (val: boolean) => Promise<void>;
            participantId: string;
            showManageControls: boolean;
            canAct: boolean;
            toast: IToastOptions;
            router: NextRouter;
        },
        ref,
    ) => {
        const [requests, setRequests] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [modalIsLoading, setModalIsLoading] = useState(false);
        const [showActDialog, setConfirmModalVisible] = useState(false);
        const [selectedRequest, setSelectedRequest] = useState({
            id: null,
            fullName: null,
            decision: false,
        });

        const actForUser = async () => {
            setModalIsLoading(true);

            const serverResponse = (await actForRequest(props.participantId, selectedRequest.id, selectedRequest.decision, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                setConfirmModalVisible(false);
                props.toast({
                    description: "عملیات با موفقیت انجام شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                if (selectedRequest.decision) await props.refreshFunction(false);
                await getRequests();
            } else {
                props.toast({
                    title: "مشکلی در انجام عملیات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }

            setModalIsLoading(false);
        };

        useImperativeHandle(ref, () => ({
            refresh() {
                getRequests();
            },
        }));

        const getRequests = async () => {
            setIsLoading(true);

            const serverResponse = (await getTeamRequests(props.participantId, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const data = await serverResponse.json();
                for (const r of data) {
                    switch (r.status) {
                        case "Received":
                            r.status = "در انتظار پاسخ";
                            r["statusColor"] = "blue";
                            break;
                        case "Denied":
                            r.status = "رد شده";
                            r["statusColor"] = "red";
                            break;
                        case "Accepted":
                            r.status = "پذیرفته شده";
                            r["statusColor"] = "green";
                            break;
                        case "Expired":
                            r.status = "منقضی شده";
                            r["statusColor"] = "orange";
                            break;
                        case "Closed":
                            r.status = "بسته شده";
                            r["statusColor"] = "gray";
                            break;
                        default:
                            r.status = "نامشخص";
                            r["statusColor"] = "gray";
                            break;
                    }
                }
                setRequests(data.reverse());
            } else {
                props.toast({
                    title: "مشکلی در دریافت درخواست‌ها رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }

            setIsLoading(false);
        };

        useEffect(() => {
            getRequests();
        }, []);

        if (isLoading) {
            return (
                <Box mx="auto" w="min">
                    <Spinner my={4} />
                </Box>
            );
        }

        return (
            <Box minHeight="400px">
                <ActConfirmAlertDialog
                    isOpen={showActDialog}
                    onClose={() => setConfirmModalVisible(false)}
                    isLoading={modalIsLoading}
                    onSuccess={actForUser}
                    fullName={selectedRequest.fullName}
                    decision={selectedRequest.decision}
                />
                <Table mt={4} size="md" variant="simple">
                    <Thead
                    // color={table_header_text}
                    >
                        <Tr>
                            <Th pl={0} pr={1} minW="200px">
                                دریافت شده از
                            </Th>
                            <Th pl={0}>وضعیت</Th>
                            {props.showManageControls && <Th>عملیات</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {requests.map((requestData) => {
                            return (
                                <Tr
                                    id={requestData.user.fusion_user_id}
                                    key={requestData.user.fusion_user_id}
                                    sx={{
                                        td: {
                                            paddingBottom: "12px",
                                            paddingTop: "12px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                        },
                                    }}
                                >
                                    <Td textAlign={["center"]} pl={0} pr={1}>
                                        <MiniUserProfile
                                            fullName={requestData.user.full_name}
                                            username={requestData.user.username}
                                            imageFilename={requestData.user.profile_picture}
                                        />
                                    </Td>
                                    <Td pl={0}>
                                        <Badge colorScheme={requestData.statusColor} variant="subtle">
                                            {requestData.status}
                                        </Badge>
                                    </Td>
                                    {props.showManageControls && (
                                        <Td pl={0}>
                                            {requestData.status === "در انتظار پاسخ" && (
                                                <>
                                                    <Button
                                                        isDisabled={!props.canAct}
                                                        onClick={() => {
                                                            setSelectedRequest({
                                                                id: requestData.id,
                                                                fullName: requestData.user.full_name,
                                                                decision: true,
                                                            });
                                                            setConfirmModalVisible(true);
                                                        }}
                                                        size="xs"
                                                        variant="outline"
                                                        colorScheme="green"
                                                        ml="3"
                                                        my="2"
                                                    >
                                                        <CheckCircle size={14} className="ml-2" />
                                                        پذیرش
                                                    </Button>
                                                    <Button
                                                        isDisabled={!props.canAct}
                                                        onClick={() => {
                                                            setSelectedRequest({
                                                                id: requestData.id,
                                                                fullName: requestData.user.full_name,
                                                                decision: false,
                                                            });
                                                            setConfirmModalVisible(true);
                                                        }}
                                                        size="xs"
                                                        variant="outline"
                                                        colorScheme="red"
                                                    >
                                                        <Slash size={14} className="ml-2" />
                                                        رد کردن
                                                    </Button>
                                                </>
                                            )}
                                        </Td>
                                    )}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        );
    },
);

const ActConfirmAlertDialog = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    fullName: string;
    decision: boolean;
    onSuccess: () => Promise<void>;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{props.decision ? "پذیرش" : "رد"} درخواست</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    درخواست «{props.fullName}» برای پیوستن به تیم {props.decision ? "پذیرفته" : "رد"} خواهد شد. آیا ادامه می‌دهید؟
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default RequestsView;
