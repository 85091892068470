import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import Style from "./ProfileNotebooks.module.css";
import { getImageURL } from "@util/mediaLoader";
import { getDatePhrase } from "@util/dateConverter";
import Link from "next/link";
import { INotebookProfile } from "@util/interfaces";

export const NotebooksMinimalCard = (props: { isMyAccount: boolean; notebookData: INotebookProfile }) => {
    return (
        <div key={props.notebookData.path} className={`flex flex-row ${Style.NotebookInfoCard} py-3`}>
            <Box minW={16} className={`rounded-md`}>
                <Link href={`/notebooks/${props.notebookData.path}`} passHref>
                    <Image
                        boxSize="16"
                        borderRadius="4px"
                        objectFit="cover"
                        src={
                            props.notebookData.thumbnail
                                ? getImageURL(`notebook_thumbnail/${props.notebookData.thumbnail}`)
                                : "/images/bannerPlaceholder.png"
                        }
                        alt="notebook thumbnail"
                    />
                </Link>
            </Box>
            <Box w={"full"}>
                <div className={`flex flex-col w-full mr-6 text-right`}>
                    <div className="w-full">
                        <Link href={`/notebooks/${props.notebookData.path}`} passHref>
                            <h3 className="truncate font-semibold mb-1" style={{ fontSize: "1rem" }}>
                                {props.notebookData.title}
                            </h3>
                        </Link>
                    </div>
                    <div className={`w-full`}>
                        <p style={{ fontSize: "13px" }} className="truncate font-semibold">
                            {props.notebookData.subtitle}
                        </p>
                    </div>
                </div>
                <div className={`${Style.nb_misc_buttons} w-full flex-row flex items-center justify-end mt-1`}>
                    <Text mb={0}>{getDatePhrase(props.notebookData.date_created, "roboepics")}</Text>
                    <span className={"rounded-full bg-gray-300 w-1 h-1 mx-2"} />
                    <div className={`flex flex-row items-center `}>
                        <span className={`${Style["like-icon"]} like-icon`} />
                        <span>{props.notebookData.upvote_count}</span>
                    </div>
                </div>
            </Box>
        </div>
    );
};
