import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCompetitionId, setCompetitionPath, setParticipantId } from "@redux/actions";
import { Box, Text, useColorModeValue, Button, Alert, Spinner, Collapse } from "@chakra-ui/react";
import RegisterModal from "./RegisterModal";
import {
    CloudUploadOutline,
    CalendarOutline,
    FlagOutline,
    PersonOutline,
    TrophyOutline,
    BulbOutline,
    Image,
    ImageOutline,
    LockClosed,
    LogoDiscord,
} from "react-ionicons";
import MenuBranch from "./MenuBranch";
import MenuLink from "./MenuLink";
import MenuSection from "./MenuSection";
import MetadataBlock from "./MetadataBlock";
import { AlertCircle, ArrowDown, ArrowUp, Check, Clock } from "react-feather";
import styles from "./CompSidebar.module.css";
import { CustomAvatar } from "@components/avatar";
import { COMPETITION_TABS } from "@util/constants";
import { getDateDifferenceMs } from "@util/dateConverter";
import { getParticipantSummaryCompetition, registerInCompetition } from "@util/api/competitions";
import { createJoin } from "@util/api/participants";
import { ICompSidebarProps } from "@util/interfaces";
import { ICompetition } from "@redux/slices/competition";
import { getCookie } from "@util/cookies";
import { getImageURL } from "@util/mediaLoader";

export const ClientCompSidebar = (props: ICompSidebarProps) => {
    const init_phase_progress_restrictions = {};
    for (const phase of props.compData.phase_set) {
        init_phase_progress_restrictions[phase["id"]] = false;
    }
    // Redux
    const dispatch = useDispatch();
    const user_id = props.user.data.id;
    const competitionState = useSelector((state: { competition: ICompetition }) => state.competition);
    // Cookie
    const accessToken = getCookie("re_auth_access_token");
    // States
    const [challengeSection, setChallengeSection] = useState([<React.Fragment key="challenge-section"></React.Fragment>]);
    const [timeDiff, setTimeDiff] = useState<number>(null);
    const [countDownComponent, setCountdownComponent] = useState(null);
    const [unlockedPhases, setUnlockedPhases] = useState([]);
    const [participantSummary, setParticipantSummary] = useState<{
        participant_id: string;
        invite_id: number;
        phase_progress_restrictions: Record<string, string>;
    }>({
        participant_id: competitionState.participantId,
        invite_id: null,
        phase_progress_restrictions: init_phase_progress_restrictions,
    });
    const [loadingParticipantState, setLoadingParticipantState] = useState(true);
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [joinLoading, setJoinLoading] = useState(false);

    const [generalInfoExpanded, setGeneralInfoExpanded] = useState(
        Array.from(["overview", "team", "scoreboard"]).includes(props.currentTab.key) == false && props.currentTab.type === "competition",
    );

    // Functions
    const getParticipantData = async () => {
        setLoadingParticipantState(true);
        const serverResponse = (await getParticipantSummaryCompetition(props.compData.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;

        if (serverResponse.ok) {
            const data = await serverResponse.json();
            const _unlockedPhases = [];
            if (data.phase_progress_restrictions) {
                for (const phase of props.compData.phase_set) {
                    _unlockedPhases.push(phase["id"].toString());
                    if (!data.phase_progress_restrictions[phase["id"]]) {
                        break;
                    }
                }
            }
            setUnlockedPhases(_unlockedPhases);

            setParticipantSummary(data);
            dispatch(setParticipantId(data.participant_id));
        } else {
            props.toast({
                title: "مشکلی در دریافت اطلاعات شرکت‌کننده رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setLoadingParticipantState(false);
    };

    const onRegisterSuccess = async () => {
        setRegisterLoading(true);

        const serverResponse = (await registerInCompetition(props.compData.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;

        if (serverResponse.ok) {
            const { id } = await serverResponse.json();
            dispatch(setParticipantId(id));
            setParticipantSummary((current) => {
                return { ...current, participant_id: id };
            });
            setRegisterModalVisible(false);
            props.toast({
                description: props.compData.participation_price
                    ? "هزینه شرکت درمسابقه با موفقیت پرداخت و ثبت‌نام شما در مسابقه انجام شد."
                    : "ثبت‌نام با موفقیت انجام شد.",
                status: "success",
                duration: 7000,
                isClosable: true,
                variant: "subtle",
            });
            props.router.reload();
            // props.setNeedsReload(true); // FIXME not working
        } else {
            props.toast({
                title: "مشکلی در تکمیل ثبت‌نام شما در مسابقه رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: props.compData.participation_price
                    ? "در صورت کسر وجه از حساب بانکی شما، حتماً این موضوع را به تیم پشتیبانی اطلاع دهید. "
                    : "" + JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setRegisterLoading(false);
    };

    const onJoinSuccess = async () => {
        setJoinLoading(true);
        const serverResponse = (await createJoin(participantSummary.invite_id, true, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setLoadingParticipantState(true);
            await getParticipantData();
            setRegisterModalVisible(false);
            props.toast({
                description: props.compData.participation_price
                    ? "هزینه شرکت درمسابقه با موفقیت پرداخت و ثبت‌نام شما در مسابقه انجام شد."
                    : "ثبت‌نام با موفقیت انجام شد.",
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
            });
            props.router.push(`/c/${props.compData.path}/team`);
            // props.setNeedsReload(true); // FIXME not working
        } else {
            props.toast({
                title: "مشکلی در تکمیل ثبت‌نام شما در مسابقه رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: props.compData.participation_price
                    ? "در صورت کسر وجه از حساب بانکی شما، حتماً این موضوع را به تیم پشتیبانی اطلاع دهید. "
                    : "" + JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setJoinLoading(false);
    };

    const onRegisterClick = async () => {
        if (!props.user.data.id || !accessToken) {
            props.toast({
                status: "warning",
                variant: "subtle",
                duration: 5000,
                isClosable: true,
                description: "برای شرکت در این مسابقه باید وارد حساب کاربری خود شوید. در حال انتقال شما به صفحه ورود...",
            });
            setTimeout(() => {
                props.router.push(`/users/signin?returnUrl=${props.router.asPath}`);
            }, 3000);
        } else {
            setRegisterModalVisible(true);
        }
    };

    // UseEffects
    useEffect(() => {
        const separator = <span className="mx-1 text-gray-500">:</span>;
        const _challengeSection: JSX.Element[] = [];

        const days = timeDiff / 86400;
        const hours = (timeDiff % 86400) / 3600;
        const minutes = (timeDiff % 3600) / 60;
        const seconds = timeDiff % 60;

        const renderHours = ("0" + hours).slice(-2);
        const renderMinutes = ("0" + minutes).slice(-2);
        const renderSeconds = ("0" + seconds).slice(-2);

        if (props.compData.difficulty === "simple") {
            const problemObj = props.compData.phase_set[0]["problems"][0];
            const problemOwner = problemObj["problem"]["owner"] === user_id;
            const timeDiff = getDateDifferenceMs(props.compData.phase_set[0].submission_date_start);
            const disabled = problemOwner || props.compData.access_level !== 40 ? false : timeDiff < 0;
            _challengeSection.push(
                <>
                    <MenuLink
                        key={`${props.compData.phase_set[0]["id"]}-${problemObj["problem"]["id"]}`}
                        link={`/c/${props.compData.path}/p/${props.compData.phase_set[0]["id"]}/p/${problemObj["problem"]["path"]}`}
                        isSelected={props.currentTab.type === "problem"}
                        icon={disabled ? <LockClosed /> : <BulbOutline />}
                        text={problemObj["problem"]["title"]}
                        isDisabled={disabled}
                        isProblem={true}
                        phaseId={props.compData.phase_set[0]["id"]}
                        problemId={problemObj["problem"]["id"]}
                        problemPath={problemObj["problem"]["path"]}
                        toast={props.toast}
                        router={props.router}
                    />
                    {disabled && (
                        <div
                            key={`${props.compData.phase_set[0]["id"]}-${problemObj["problem"]["id"]}-timer`}
                            className={`${styles.menu_item_timer} flex items-center pl-4`}
                        >
                            <Text className="ml-2 font-semibold" mb={0} mt="2px" fontSize="13px">
                                {!timeDiff ? (
                                    "-"
                                ) : days === 0 ? (
                                    ""
                                ) : (
                                    <>
                                        {days}
                                        {separator}
                                    </>
                                )}
                                {renderHours}
                                {separator}
                                {renderMinutes}
                                {separator}
                                {renderSeconds}
                            </Text>
                            <Clock size={16} />
                        </div>
                    )}
                </>,
            );
        }
        setChallengeSection(_challengeSection);
    }, [timeDiff]);

    useEffect(() => {
        // Updating Redux state for competition
        if (props.compData.id !== competitionState.id) {
            dispatch(setCompetitionId(props.compData.id)); // FIXME do we still need this or path is enough?
            dispatch(setCompetitionPath(props.compData.path));
        }
        if (accessToken) {
            getParticipantData();
        } else {
            setLoadingParticipantState(false);
        }

        let timer = null;
        const _challengeSection = [];
        if (props.compData.difficulty === "simple") {
            const problemObj = props.compData.phase_set[0]["problems"][0];
            const problemOwner = problemObj["problem"]["owner"] === user_id;
            const timeDiff = getDateDifferenceMs(props.compData.phase_set[0].submission_date_start);
            const disabled = problemOwner || props.compData.access_level !== 40 ? false : timeDiff < 0;
            if (disabled) {
                setTimeDiff(-1 * Math.ceil(timeDiff / 1000));
                timer = setInterval(() => {
                    setTimeDiff((current) => current - 1);
                }, 1000);
            }
            _challengeSection.push(
                <>
                    <MenuLink
                        key={`${props.compData.phase_set[0]["id"]}-${problemObj["problem"]["id"]}`}
                        link={`/c/${props.compData.path}/p/${props.compData.phase_set[0]["id"]}/p/${problemObj["problem"]["path"]}`}
                        isSelected={props.currentTab.type === "problem"}
                        icon={disabled ? <LockClosed /> : <BulbOutline />}
                        text={problemObj["problem"]["title"]}
                        isDisabled={disabled}
                        isProblem={true}
                        phaseId={props.compData.phase_set[0]["id"]}
                        problemId={problemObj["problem"]["id"]}
                        problemPath={problemObj["problem"]["path"]}
                        toast={props.toast}
                        router={props.router}
                    />
                    {disabled && (
                        <div className={`${styles.menu_item_timer} flex items-center pl-4`}>
                            <Text className="ml-2 font-semibold" mb={0} mt="2px" fontSize="13px">
                                {countDownComponent}
                            </Text>
                            <Clock size={16} />
                        </div>
                    )}
                </>,
            );
        } else {
            // Calculating locked phase index
            props.compData.phase_set.forEach((phase, index: number) => {
                _challengeSection.push(
                    <MenuBranch
                        key={"phase-" + index.toString() + "-client"}
                        isExpanded={props.currentTab.type === "problem" && props.currentTab.key.split(".")[0] === phase["id"].toString()}
                        icon={<FlagOutline />}
                        text={phase.title}
                        phaseId={phase["id"]}
                    >
                        {phase.problems.map((problemObj) => {
                            const isProblemOwner = problemObj.problem.owner === user_id;
                            const disabled =
                                !isProblemOwner &&
                                !problemObj.problem.is_public &&
                                (!unlockedPhases.includes(phase.id.toString()) ||
                                    (phase.hide_until_start && getDateDifferenceMs(phase.submission_date_start) < 0));
                            return (
                                <MenuLink
                                    key={`${phase["id"]}-${problemObj["problem"]["id"]}`}
                                    link={`/c/${props.compData.path}/p/${phase["id"]}/p/${problemObj["problem"]["path"]}`}
                                    isSelected={
                                        props.currentTab.type === "problem" &&
                                        props.currentTab.key.split(".")[0] === phase["id"].toString() &&
                                        props.currentTab.key.split(".")[1].toLowerCase() ===
                                            problemObj["problem"]["path"].toString().toLowerCase()
                                    }
                                    icon={"dot"}
                                    text={problemObj["problem"]["title"]}
                                    isDisabled={disabled}
                                    isProblem={true}
                                    phaseId={phase["id"]}
                                    problemId={problemObj["problem"]["id"]}
                                    problemPath={problemObj["problem"]["path"]}
                                    toast={props.toast}
                                    router={props.router}
                                />
                            );
                        })}
                    </MenuBranch>,
                );
            });
        }
        setChallengeSection(_challengeSection);
        return () => {
            if (!timer) clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let timer = null;
        const _challengeSection = [];
        if (props.compData.difficulty === "simple") {
            const problemObj = props.compData.phase_set[0].problems[0];
            const problemOwner = problemObj.problem.owner === user_id;
            const timeDiff = getDateDifferenceMs(props.compData.phase_set[0].submission_date_start);
            const disabled = problemOwner || props.compData.access_level !== 30 ? false : timeDiff < 0;
            if (disabled) {
                setTimeDiff(-1 * Math.ceil(timeDiff / 1000));
                timer = setInterval(() => {
                    setTimeDiff((current) => current - 1);
                }, 1000);
            }
            _challengeSection.push(
                <>
                    <MenuLink
                        key={`${props.compData.phase_set[0].id}-${problemObj.problem.id}`}
                        link={`/c/${props.compData.path}/p/${props.compData.phase_set[0].id}/p/${problemObj.problem.path}`}
                        isSelected={props.currentTab.type === "problem"}
                        icon={disabled ? <LockClosed /> : <BulbOutline />}
                        text={problemObj.problem.title}
                        isDisabled={disabled}
                        isProblem={true}
                        phaseId={props.compData.phase_set[0].id}
                        problemId={problemObj.problem.id}
                        problemPath={problemObj.problem.path}
                        toast={props.toast}
                        router={props.router}
                    />
                    {disabled && (
                        <div className={`${styles.menu_item_timer} flex items-center pl-4`}>
                            <Text className="ml-2 font-semibold" mb={0} mt="2px" fontSize="13px">
                                {countDownComponent}
                            </Text>
                            <Clock size={16} />
                        </div>
                    )}
                </>,
            );
        } else {
            // Calculating locked phase index
            props.compData.phase_set.forEach((phase, index: number) => {
                _challengeSection.push(
                    <MenuBranch
                        key={"phase-" + index.toString() + "-client"}
                        isExpanded={props.currentTab.type === "problem" && props.currentTab.key.split(".")[0] === phase.id.toString()}
                        icon={<FlagOutline />}
                        text={phase.title}
                        phaseId={phase.id}
                    >
                        {phase.problems.map((problemObj) => {
                            const isProblemOwner = problemObj.problem.owner === user_id;
                            const disabled =
                                !isProblemOwner &&
                                !problemObj.problem.is_public &&
                                (!unlockedPhases.includes(phase.id.toString()) ||
                                    (phase.hide_until_start && getDateDifferenceMs(phase.submission_date_start) < 0));
                            return (
                                <MenuLink
                                    key={`${phase.id}-${problemObj.problem.id}`}
                                    link={`/c/${props.compData.path}/p/${phase.id}/p/${problemObj.problem.path}`}
                                    isSelected={
                                        props.currentTab.type === "problem" &&
                                        props.currentTab.key.split(".")[0] === phase.id.toString() &&
                                        props.currentTab.key.split(".")[1].toLowerCase() ===
                                            problemObj.problem.path.toString().toLowerCase()
                                    }
                                    icon={"dot"}
                                    text={problemObj.problem.title}
                                    isDisabled={disabled}
                                    isProblem={true}
                                    phaseId={phase.id}
                                    problemId={problemObj.problem.id}
                                    problemPath={problemObj.problem.path}
                                    toast={props.toast}
                                    router={props.router}
                                />
                            );
                        })}
                    </MenuBranch>,
                );
            });
        }
        setChallengeSection(_challengeSection);
        return () => {
            if (!timer) clearInterval(timer);
        };
    }, [unlockedPhases]);

    const buttonBgColor = useColorModeValue("#000", "RoboEpics.turquoise.600");
    const buttonBgColorHover = useColorModeValue("RoboEpics.dark.500", "RoboEpics.turquoise.700");
    const buttonBgColorActive = useColorModeValue("RoboEpics.dark.500", "RoboEpics.turquoise.700");

    return (
        <aside className={`${props.className}`} style={props.style}>
            <Box className={`flex flex-col items-center justify-center ${styles.sidebar_wrapper} w-full pb-8`}>
                {/* Sidebar Toggle */}
                {props.toggleView}
                {/* Header */}
                <Box
                    className="flex flex-row justify-start self-start items-center mb-6"
                    style={{
                        marginInlineEnd: "-.75rem",
                    }}
                >
                    {/* <Avatar size="md" /> */}
                    <CustomAvatar
                        src={getImageURL(props.compData.thumbnail)}
                        className="flex-shrink-0"
                        noBorder={!!props.compData.thumbnail}
                        showPlaceholder={!props.compData.thumbnail}
                        placeholder={useColorModeValue(<ImageOutline color={"black"} />, <Image color={"white"} />)}
                    />
                    <Text fontWeight="800" className="mr-2 mb-0">
                        {props.compData.title}
                    </Text>
                </Box>

                {/* Metadata */}
                <Box className="w-full">
                    <Box className="w-full flex flex-row justify-between items-center mb-3">
                        <MetadataBlock icon={<PersonOutline />} text={props.compData.total_participants} tooltip="شرکت‌کنندگان" />
                        <MetadataBlock icon={<CloudUploadOutline />} text={props.compData.total_submissions} tooltip="تعداد ارسال‌ها" />
                        <MetadataBlock icon={<CalendarOutline />} text={props.compData.date_published} tooltip="تاریخ انتشار" />
                    </Box>
                    <Box>
                        <MetadataBlock icon={<TrophyOutline />} text={props.compData.prize} tooltip="جایزه مسابقه" />
                    </Box>
                </Box>

                {/* Register / User Status */}
                <Box className="mt-4 w-full grid">
                    {loadingParticipantState ? (
                        <Spinner size="sm" className="mx-auto" />
                    ) : participantSummary.participant_id ? (
                        <Alert
                            status="success"
                            sx={{
                                padding: ".6rem .5rem",
                                fontSize: "13px",
                                marginInlineStart: "-.2rem",
                                marginInlineEnd: "-.2rem",
                            }}
                        >
                            <Check size={18} className="ml-1" />
                            <span
                                style={{
                                    fontSize: "13px",
                                }}
                            >
                                در این مسابقه شرکت کرده‌اید.
                            </span>
                        </Alert>
                    ) : (
                        <>
                            {accessToken && (
                                <RegisterModal
                                    comp={props.compData.path}
                                    compTitle={props.compData.title}
                                    isOpen={registerModalVisible}
                                    onRegister={onRegisterSuccess}
                                    onJoin={onJoinSuccess}
                                    onClose={() => setRegisterModalVisible(false)}
                                    isLoading={registerLoading}
                                    joinLoading={joinLoading}
                                    participantSummary={participantSummary}
                                    participationPrice={props.compData.participation_price}
                                    toast={props.toast}
                                    user={props.user}
                                    router={props.router}
                                />
                            )}
                            <Button
                                style={{
                                    width: "100%",
                                }}
                                color="#fff"
                                bg={buttonBgColor}
                                _hover={{ bg: buttonBgColorHover }}
                                _active={{ bg: buttonBgColorActive }}
                                onClick={onRegisterClick}
                                isDisabled={
                                    getDateDifferenceMs(props.compData.register_date_end) > 0 ||
                                    getDateDifferenceMs(props.compData.register_date_start) < 0
                                }
                            >
                                {props.compData.phase_set.length > 0 ? "شرکت در مسابقه" : "پیش ثبت نام در مسابقه"}
                            </Button>
                            {participantSummary.invite_id && (
                                <Alert
                                    status="info"
                                    sx={{
                                        padding: ".6rem .6rem",
                                        fontSize: "13px",
                                        marginTop: "10px",
                                        alignItems: "start",
                                    }}
                                >
                                    <AlertCircle size={18} className="ml-2" />
                                    <span
                                        style={{
                                            fontSize: "13px",
                                        }}
                                    >
                                        شما به این مسابقه دعوت شده‌اید!
                                    </span>
                                </Alert>
                            )}
                        </>
                    )}
                </Box>

                {/* Discord */}
                <Box className="mt-8 w-full grid">
                    <a href="https://discord.gg/8DMfjmn6gc" target="_blank">
                        <Button
                            variant="ghost"
                            px={3}
                            w="inherit"
                            className={`flex items-center rounded-md`}
                            style={{
                                width: "100%",
                            }}
                            color="#fff"
                            bg="#5865F2"
                            _hover={{ bg: "#404EED" }}
                            _active={{ bg: "#7289DA" }}
                        >
                            <LogoDiscord
                                cssClasses="ml-2 flex-shrink-0"
                                color={useColorModeValue("#fff", "#fff")}
                                width="20px"
                                height="20px"
                            />
                            <span
                                className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                                style={{ textOverflow: "ellipsis" }}
                            >
                                دیسکورد
                            </span>
                        </Button>
                    </a>
                </Box>

                {/* Menu */}
                <Box className="w-full">
                    <Collapse in={generalInfoExpanded} startingHeight={212} style={{ padding: "0 14px" }}>
                        <MenuSection key="competition-information-sidebar" noMargin={false} title="اطلاعات کلی">
                            {Object.keys(COMPETITION_TABS).map((key) => {
                                const el = COMPETITION_TABS[key];
                                const disabled =
                                    // (el["key"] === "discussion" &&
                                    //     props.compData.id !== 4) ||
                                    (el["key"] === "team" && !competitionState.participantId) ||
                                    (el["key"] === "team" && props.compData.participation_type === "انفرادی") ||
                                    (el["key"] === "discussion" && !props.compData.discussion_url);
                                return (
                                    <MenuLink
                                        isDisabled={disabled}
                                        key={el["key"]}
                                        isSelected={props.currentTab.key === el["key"]}
                                        icon={el["icon"]}
                                        text={el["label"]}
                                        link={
                                            el["key"] === "discussion"
                                                ? props.compData.discussion_url
                                                : `/c/${props.compData.path}/${el["key"]}`
                                        }
                                        isProblem={false}
                                        toast={props.toast}
                                        router={props.router}
                                    />
                                );
                            })}
                        </MenuSection>
                    </Collapse>

                    <Button
                        size="sm"
                        mt={2}
                        leftIcon={generalInfoExpanded ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
                        variant="ghost"
                        w="full"
                        alignSelf="center"
                        onClick={() => {
                            setGeneralInfoExpanded(!generalInfoExpanded);
                        }}
                    >
                        {generalInfoExpanded ? "مشاهده کمتر" : "مشاهده بیشتر"}
                    </Button>
                    <MenuSection
                        key="phases-sidebar-client"
                        title="سوال‌ها"
                        phaseId={props.compData.difficulty === "simple" ? props.compData.phase_set[0]["id"] : null}
                    >
                        {challengeSection}
                    </MenuSection>
                </Box>
            </Box>
        </aside>
    );
};
