import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const retrieveAllDatasets = async (query?: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset${query ? `?title=${query}` : ""}`, {}, "preferred", ctx);

export const searchDatasetByTitle = async (parameters: Record<string, string>, ctx?: IServerSideContext) => {
    let query = "";
    for (const [key, value] of Object.entries(parameters)) {
        query += `&${key}=${value}`;
    }
    query = query.slice(1);

    return await fetchWithRefresh(`/dataset/?${query}`, {}, "preferred", ctx);
};

export const retrieveDataset = async (dataset: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${dataset}`, {}, "preferred", ctx);

export const upvoteDataset = async (dataset: number | string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${dataset}/upvote`, { method: "PATCH" }, "required", ctx);

export const retrieveUpvoteStatus = async (dataset: string | string[] | number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${dataset}/upvote`, {}, "preferred", ctx);

export const datasetEditJson = async (
    dataset: number | string | string[],
    data: Record<string, string | number>,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/dataset/${dataset}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const datasetEditFormdata = async (form: FormData, dataset: string | string[] | number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${dataset}`, { method: "PATCH", body: form }, "required", ctx);

export const deleteDataset = async (dataset: number | string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${dataset}`, { method: "DELETE" }, "required", ctx);

export const createDataset = async (form: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/`, { method: "POST", body: form }, "required", ctx);

export const createNewVersionDataset = async (dataset: number | string, data: Record<string, string>, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/dataset/${dataset}/versions`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const uploadFileToDataset = async (dataset: number | string, data: Record<string, string | number>, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/dataset/${dataset}/upload`,
        { method: "POST", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const completeUploadingMultipartFile = async (
    path: string,
    filename: string,
    uploadId: string,
    parts: Array<{ ETag: string; PartNumber: number }>,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/dataset/${path}/upload-complete`,
        {
            method: "POST",
            body: JSON.stringify({ filename, upload_id: uploadId, parts }),
            headers: { "content-type": "application/json" },
        },
        "required",
        ctx,
    );

export const retrieveDatasetNotebooks = async (path: string, ctx?: IServerSideContext) =>
    fetchWithRefresh(`/dataset/${path}/notebooks`, {}, "preferred", ctx);

export const retrieveDatasetVersion = async (path: string, version: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${path}/versions/${version}`, {}, "preferred", ctx);

export const datasetVersionEditJson = async (
    path: string | string[],
    version: string,
    data: { description?: string; license?: string; version?: string },
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/dataset/${path}/versions/${version}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const retrieveDatasetCollaborators = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/dataset/${path}/collaborators`, {}, "preferred", ctx);

export const createDatasetCollaborator = async (dataset: string | string[], username: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/dataset/${dataset}/collaborators`,
        { method: "POST", body: JSON.stringify({ user: username }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const removeDatasetCollaborator = async (dataset: string | string[], id: number, ctx?: IServerSideContext) =>
    fetchWithRefresh(`/dataset/${dataset}/collaborators/${id}`, { method: "DELETE" }, "required", ctx);
