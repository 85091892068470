import React from "react";
import { CustomLeaderboardAvatar } from "@components/competition/leaderboardAvatar";
import { getImageURL } from "@util/mediaLoader";
import { Avatar, Tooltip } from "@chakra-ui/react";
interface AvatarProps {
    className?: string;
    name: string;
    image: string;
}

export const IdDisplayer = (props: AvatarProps): JSX.Element => {
    let ldiv = document.createElement("div");
    document.body.appendChild(ldiv);
    ldiv.style.fontSize = "1rem";
    ldiv.style.position = "absolute";
    ldiv.style.left = "-1000px";
    ldiv.style.top = "-1000px";

    ldiv.textContent = props.name;
    const result = {
        width: ldiv.clientWidth,
        height: ldiv.clientHeight,
    };
    document.body.removeChild(ldiv);
    ldiv = null;

    return (
        <Tooltip isDisabled={result.width < 125} label={props.name} hasArrow arrowSize={6}>
            <div className="flex flex-row  items-center justify-start">
                {props.image === undefined || props.image?.toString().includes("no_avatar") || !props.image ? (
                    <Avatar size="sm" ml="2" />
                ) : (
                    <CustomLeaderboardAvatar src={getImageURL(props.image)} />
                )}
                <span
                    className="font-semibold overflow-hidden whitespace-nowrap"
                    style={{
                        textOverflow: "ellipsis",
                        maxWidth: "125px",
                    }}
                >
                    {props.name}
                </span>
            </div>
        </Tooltip>
    );
};
