import {
    Alert,
    AlertIcon,
    AlertDescription,
    Box,
    Button,
    Collapse,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { reportUser } from "@util/api/profile";
import { getCookie } from "@util/cookies";
import { IToastOptions } from "@util/interfaces";
import { NextRouter, useRouter } from "next/router";
import React, { useState } from "react";
import Style from "./reportModal.module.css";
export const ReportModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    fusion_user_id: string;
    router: NextRouter;
    toast: IToastOptions;
}) => {
    const router = useRouter();

    const [values, setValues] = useState({ subject: "", description: "" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isLoggedIn = getCookie("re_auth_access_token");

    const bg = useColorModeValue("RoboEpics.black.default", "RoboEpics.gold.400");
    const btnTxt = useColorModeValue("#fff", "RoboEpics.black.default");
    const handleChange = (prop: string) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "error" | "success" | "warning";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const _reportUser = async () => {
        setIsLoading(true);
        const serverResponse = (await reportUser(props.fusion_user_id, values.subject, values.description, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setAlertProps({
                open: true,
                message: "گزارش شما با موفقیت ثبت شد و به تیم پشتیبانی انتقال داده می‌شود.",
                status: "success",
            });
            setIsLoading(false);
            setValues({ subject: "", description: "" });
        } else {
            setAlertProps({
                open: true,
                message:
                    "مشکلی در ارسال گزارش شما به وجود آمد (" +
                    `${serverResponse.status} - ${serverResponse.statusText}) ` +
                    (await serverResponse.json()) +
                    "لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                status: "error",
            });
        }
        setIsLoading(false);
    };
    return (
        <>
            <Modal isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent className={`${Style.modal_content}`} bg={useColorModeValue("#fff", "RoboEpics.black.900")}>
                    <ModalHeader>
                        <Heading size="md">گزارش تخلف کاربر</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isLoggedIn ? (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <Collapse in={alertProps.open}>
                                    <Alert status={alertProps.status} variant={"subtle"} className="mb-4">
                                        <AlertIcon />
                                        <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                                    </Alert>
                                </Collapse>
                                <VStack spacing={4} mb={2}>
                                    <FormControl>
                                        <FormLabel fontSize="14px">عنوان گزارش</FormLabel>
                                        <Input
                                            variant="filled"
                                            type="text"
                                            defaultValue={values.subject}
                                            onChange={handleChange("subject")}
                                            id="subject"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontSize="14px">توضیحات</FormLabel>
                                        <Textarea
                                            variant="filled"
                                            defaultValue={values.description}
                                            onChange={handleChange("description")}
                                            id="description"
                                            resize="none"
                                            maxLength={2000}
                                            h={[32, 64]}
                                        />
                                        <FormHelperText>
                                            <Text
                                                textAlign="end"
                                                mb={0}
                                                color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                                            >{`${values.description.length}/2000`}</Text>
                                        </FormHelperText>
                                    </FormControl>
                                    <Button
                                        isDisabled={values.description.length * values.subject.length === 0}
                                        isLoading={isLoading}
                                        onClick={_reportUser}
                                        bg={bg}
                                        color={btnTxt}
                                        _hover={{
                                            background: useColorModeValue("RoboEpics.black.900", "RoboEpics.gold.500"),
                                        }}
                                        _active={{
                                            background: useColorModeValue("RoboEpics.black.900", "RoboEpics.gold.500"),
                                        }}
                                        _focus={{
                                            background: useColorModeValue("RoboEpics.black.900", "RoboEpics.gold.500"),
                                        }}
                                        mb={4}
                                        px={10}
                                        placeSelf={"flex-end"}
                                        type="submit"
                                    >
                                        ارسال
                                    </Button>
                                </VStack>
                            </form>
                        ) : (
                            <Box w="full" justifyContent="center">
                                <Text textAlign="center">برای گزارش تخلف کاربر باید در سایت وارد حساب کاربری خود شده باشید.</Text>
                                <Button
                                    bg={bg}
                                    color={btnTxt}
                                    size="sm"
                                    float="right"
                                    mb="2"
                                    colorScheme={useColorModeValue("RobEpics.dark", "RoboEpics.gold")}
                                    onClick={() => {
                                        router.push("/users/login");
                                    }}
                                >
                                    ورود به حساب کاربری
                                </Button>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
