import React, { useState } from "react";
import { Input, Heading, IconButton } from "@chakra-ui/react";
import { problemEditJson } from "@util/api/problems";
import { Check, Edit2 } from "react-feather";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const EditTitle = (props: {
    title: string;
    toast: IToastOptions;
    router: NextRouter;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [title, setTitle] = useState(props.title);
    const [editMode, setEditMode] = useState(false);

    const editTitle = async () => {
        const reqData = { title: title };
        const serverResponse = (await problemEditJson(reqData, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setHasChanged(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setTitle(props.title);
        }
    };
    return (
        <div className={`flex flex-row items-center`}>
            {editMode ? (
                <Input
                    isTruncated
                    id={`title-input`}
                    variant={"flushed"}
                    defaultValue={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    mb={"0.5em"}
                />
            ) : (
                <Heading as="h1" fontSize="lg" fontWeight="600" id={`title-input`}>
                    {props.title}
                </Heading>
            )}
            {editMode ? (
                <IconButton
                    mb={"0.5em"}
                    mr={2}
                    aria-label="edit-title"
                    icon={<Check size="18" />}
                    onClick={() => {
                        if (title !== "") {
                            if (props.title !== title) {
                                editTitle();
                            }
                        } else {
                            setTitle(props.title);
                        }
                        setEditMode(false);
                    }}
                    size={"sm"}
                    variant="ghost"
                />
            ) : (
                <IconButton
                    mb={"0.5em"}
                    mr={2}
                    onClick={() => {
                        setEditMode(true);
                    }}
                    aria-label="edit-title"
                    icon={<Edit2 size="16" />}
                    size={"sm"}
                    variant="ghost"
                />
            )}
        </div>
    );
};
