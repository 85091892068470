import React from "react";
import { motion } from "framer-motion";
import {
    Text,
    useColorModeValue,
    Box,
    IconButton,
    HStack,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    Portal,
    VStack,
    Divider,
} from "@chakra-ui/react";
import { AddOutline, ChevronBackOutline, EllipsisHorizontal, SettingsOutline, Trash } from "react-ionicons";
import { IMenuBranch } from "@util/interfaces";
import { useRouter } from "next/router";

const MenuBranch = (props: IMenuBranch) => {
    const router = useRouter();
    const [isExpanded, setExpanded] = React.useState(props.isExpanded);
    const arrowRef = React.useRef(null);
    React.useEffect(() => {
        arrowRef.current.style.transform = props.isExpanded ? "rotate(-90deg)" : "none";
    }, []);
    const toggle = () => {
        arrowRef.current.style.transform = isExpanded ? "none" : "rotate(-90deg)";
        setExpanded((current) => !current);
    };

    return (
        <>
            <li className="w-full">
                <HStack>
                    <Text
                        onClick={toggle}
                        className="pr-3 flex items-center justify-start rounded-md cursor-pointer mb-0 w-full"
                        bg={"inherit"}
                        color={useColorModeValue("#000", "#fff")}
                    >
                        {React.cloneElement(props.icon, {
                            cssClasses: "ml-2",
                            color: useColorModeValue("#000", "#fff"),
                            width: "18px",
                            height: "18px",
                        })}
                        <span
                            className="flex-grow overflow-hidden whitespace-nowrap"
                            style={{
                                textOverflow: "ellipsis",
                            }}
                        >
                            {props.text}
                        </span>
                        <span
                            ref={arrowRef}
                            className="mx-2"
                            style={{
                                transition: "all .15s ease-out",
                                transformOrigin: "center",
                            }}
                        >
                            <ChevronBackOutline color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />
                        </span>
                    </Text>
                    {(props.isOwner || props.collaborationAccessLevel === 50) && (
                        <Popover placement="left-start">
                            <PopoverTrigger>
                                <IconButton
                                    aria-label="phase-menu-side"
                                    icon={<EllipsisHorizontal color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />}
                                    variant="ghost"
                                    size="xs"
                                />
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent zIndex={10} w="fit-content">
                                    <PopoverArrow bgColor="inherit" />
                                    <PopoverBody px={0}>
                                        <VStack spacing={2} w="fit-content">
                                            <HStack
                                                w="full"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                px={3}
                                                mx={1}
                                                py={2}
                                                _hover={{
                                                    cursor: "pointer",
                                                    background: useColorModeValue(
                                                        "var(--chakra-colors-gray-200)",
                                                        "var(--chakra-colors-whiteAlpha-200)",
                                                    ),
                                                }}
                                                onClick={() => {
                                                    props.setModalOpen(true);
                                                    props.setModalData((current) => {
                                                        return {
                                                            ...current,
                                                            phaseId: props.phaseId,
                                                        };
                                                    });
                                                }}
                                            >
                                                <AddOutline color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />
                                                <Text>ساخت چالش جدید</Text>
                                            </HStack>
                                            <HStack
                                                w="full"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                px={3}
                                                mx={1}
                                                py={2}
                                                _hover={{
                                                    cursor: "pointer",
                                                    background: useColorModeValue(
                                                        "var(--chakra-colors-gray-200)",
                                                        "var(--chakra-colors-whiteAlpha-200)",
                                                    ),
                                                }}
                                                onClick={() => router.push(`/c/${router.query.competition}/p/${props.phaseId}/settings`)}
                                            >
                                                <SettingsOutline color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />
                                                <Text>تنظیمات مرحله</Text>
                                            </HStack>
                                            <Divider />
                                            <HStack
                                                aria-disabled
                                                w="full"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                px={3}
                                                mx={1}
                                                py={2}
                                                color={"red"}
                                                _hover={{
                                                    cursor: "pointer",
                                                    background: useColorModeValue(
                                                        "var(--chakra-colors-red-100)",
                                                        "var(--chakra-colors-red-200)",
                                                    ),
                                                }}
                                            >
                                                <Trash color={"red"} width="16px" height="16px" />
                                                <Text>حذف فاز</Text>
                                            </HStack>
                                        </VStack>
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    )}
                </HStack>
            </li>
            {
                <Box
                    className={`w-full`}
                    style={{
                        overflow: "hidden",
                        height: isExpanded ? React.Children.toArray(props.children).length * 36 : "0",
                        transition: "height 0.8s cubic-bezier(.25,.61,.29,1)",
                        marginTop: "0",
                        marginBottom: ".5rem",
                    }}
                >
                    {React.Children.map(props.children, (child) => {
                        return (
                            <motion.div
                                animate={isExpanded ? "visible" : "initial"}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        x: 0,
                                    },
                                    hidden: {
                                        opacity: 0,
                                        x: 2,
                                    },
                                }}
                                transition={{
                                    delay:
                                        (isExpanded
                                            ? React.Children.toArray(props.children).indexOf(child)
                                            : React.Children.toArray(props.children).length -
                                              React.Children.toArray(props.children).indexOf(child)) *
                                            0.08 +
                                        0.1,
                                    ease: "easeOut",
                                    duration: 0.1,
                                }}
                                className="w-full"
                            >
                                {React.cloneElement(child, {
                                    style: {
                                        width: "calc(100% - 28px)",
                                        marginRight: "auto",
                                    },
                                })}
                            </motion.div>
                        );
                    })}
                </Box>
            }
        </>
    );
};

export default MenuBranch;
