import React, { useEffect, useState } from "react";
import { USER_SOCIALS } from "@util/constants";
import jsonToFormData from "json-form-data";
import { editMyProfile } from "@util/api/profile";
import {
    Button,
    Box,
    useColorModeValue,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Heading,
    Textarea,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    HStack,
    FormHelperText,
} from "@chakra-ui/react";
import { AddOutline } from "react-ionicons";
import { Check, Edit2, Trash2 } from "react-feather";
import classes from "./PersonalInfoEdit.module.css";
import "react-image-crop/dist/ReactCrop.css";

import { IProfileData } from "@util/interfaces";
import { NextRouter } from "next/router";

const SocialManager = (props: { defaultValue?: any; onChange: any }) => {
    const [data, setData] = useState<Array<{ title: string; link: string }>>(props.defaultValue ? props.defaultValue : []);
    const [editIndex, setEditIndex] = useState<number>(-1);
    const [editValue, setEditValue] = useState<string>("");
    useEffect(() => {
        if (!(editIndex + 1)) return;
        document.getElementById(`social-input-${editIndex}`).focus();
    }, [editIndex]);
    useEffect(() => {
        if (!props.onChange) return;
        props.onChange(data);
    }, [data]);
    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    leftIcon={<AddOutline color={useColorModeValue("#000000", "#FFFFFF")} />}
                    size="sm"
                    isDisabled={data.length === Object.keys(USER_SOCIALS).length || editIndex !== -1}
                >
                    لینک جدید
                </MenuButton>
                <MenuList>
                    {Object.keys(USER_SOCIALS).map((key, index) => {
                        let isDuplicate = false;
                        let color = "#000";
                        switch (key) {
                            case "twitter":
                                color = "#08a0e9";
                                break;
                            case "instagram":
                                color = "#dd2a7b";
                                break;
                            case "github":
                                color = useColorModeValue("#000", "#FFF");
                                break;
                            case "linkedin":
                                color = "#0077b5";
                                break;
                            default:
                                color = useColorModeValue("#000", "#FFF");
                                break;
                        }
                        for (const el of data) {
                            if (el.title === key) {
                                isDuplicate = true;
                                break;
                            }
                        }
                        if (isDuplicate) return;
                        return (
                            <MenuItem
                                key={key}
                                icon={React.cloneElement(USER_SOCIALS[key].iconElement, {
                                    color: color,
                                })}
                                onClick={() => {
                                    const _length = data.length;
                                    setData((current) => {
                                        return [...current, { title: key, link: "" }];
                                    });
                                    setEditIndex(_length);
                                }}
                            >
                                {USER_SOCIALS[key].label}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
            <Box
                className="mt-2"
                style={{
                    direction: "ltr",
                    fontSize: "14px",
                }}
            >
                {data.length !== 0 && (
                    <>
                        {data.map((value, index) => {
                            let recordData: { iconElement?: any } = {};
                            for (const el of Object.keys(USER_SOCIALS)) {
                                if (el === value.title) {
                                    recordData = USER_SOCIALS[el];
                                    break;
                                }
                            }
                            return (
                                <Box
                                    className="rounded-lg flex flex-row items-center justify-start py-2 px-3 mt-1"
                                    bg={useColorModeValue("gray.100", "RoboEpics.dark.600")}
                                    style={{
                                        transition: "all .1s",
                                    }}
                                >
                                    <div
                                        className="mr-2"
                                        style={{
                                            flex: "0 0 20px",
                                        }}
                                    >
                                        {React.cloneElement(recordData.iconElement, {
                                            height: "18px",
                                            width: "18px",
                                            color: "#898989",
                                        })}
                                    </div>
                                    {/* <div
                                        className="ml-1"
                                        style={{
                                            flex: "0 0 100px",
                                        }}
                                    >
                                        {recordData.label}
                                    </div> */}
                                    <div className="flex-grow">
                                        <Input
                                            isTruncated
                                            id={`social-input-${index}`}
                                            size="sm"
                                            variant={editIndex === index ? "flushed" : "unstyled"}
                                            defaultValue={value.link}
                                            isReadOnly={editIndex !== index}
                                            onChange={(e) => {
                                                const tmp = [...data];
                                                tmp.forEach((el) => {
                                                    if (el.title === value.title) el.link = e.target.value;
                                                });
                                                setData(tmp);
                                            }}
                                        />
                                    </div>
                                    <div className="ml-2 flex">
                                        {editIndex === index ? (
                                            <IconButton
                                                height="1.9rem"
                                                variant="ghost"
                                                size="sm"
                                                aria-label="confirm social link"
                                                icon={<Check size="18" />}
                                                onClick={() => {
                                                    const tmp = [...data];
                                                    tmp.forEach((el, index) => {
                                                        if (el.link.length === 0) tmp.splice(index, 1);
                                                    });
                                                    setData(tmp);
                                                    setEditIndex(-1);
                                                }}
                                                isDisabled={false}
                                            />
                                        ) : (
                                            <IconButton
                                                height="1.9rem"
                                                variant="ghost"
                                                size="sm"
                                                aria-label="edit social link"
                                                icon={<Edit2 size="16" />}
                                                onClick={() => {
                                                    setEditIndex(index);
                                                    setEditValue(value.link);
                                                    document.getElementById(`social-input-${index}`).focus();
                                                }}
                                                isDisabled={editIndex !== -1}
                                            />
                                        )}
                                        <IconButton
                                            height="1.9rem"
                                            variant="ghost"
                                            size="sm"
                                            aria-label="delete social link"
                                            icon={<Trash2 size="18" color="#c53030" />}
                                            onClick={() => {
                                                const arr = [...data];
                                                arr.splice(index, 1);
                                                setData(arr);
                                                setEditIndex(-1);
                                            }}
                                            // isDisabled={
                                            //     // editIndex !== -1 ||
                                            //     !(editIndex === index)
                                            // }
                                        />
                                    </div>
                                </Box>
                            );
                        })}
                    </>
                )}
            </Box>
        </Box>
    );
};

export const PersonalInfoEdit = (props: {
    profileData: IProfileData;
    toast;
    router: NextRouter;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState({
        city: "",
        university: "",
        website: "",
        biography: "",
        links: [],
    });
    const handleChange = (prop: string) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const compileFormData = () => {
        let form_data = new FormData();
        Object.keys(values).forEach((key) => {
            if (!Array.isArray(values[key])) form_data.append(key, values[key]);
        });

        // Deleting empty values from links
        form_data = jsonToFormData(
            {
                links: values.links.filter((value) => value["title"].length * value["link"].length),
            },
            {
                initialFormData: form_data,
            },
        );
        return form_data;
    };

    const onEditMyProfile = async () => {
        setIsLoading(true);

        const data_req = compileFormData();
        const serverResponse = (await editMyProfile(data_req, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                description: "تغییرات با موفقیت اعمال شد.",
                status: "success",
                duration: 5000,
                variant: "subtle",
            });
            props.setHasChanged(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setIsLoading(false);
    };

    return (
        <VStack as="div" className="w-full mb-16" borderWidth={1} borderRadius="lg" p={4} spacing={8}>
            <Box className={`flex flex-col w-full ${classes.other_info_wrapper}`}>
                <Box className={`flex flex-row w-full justify-between mb-4`}>
                    <Heading size="md" fontWeight={700} mb={4}>
                        اطلاعات جانبی
                    </Heading>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        isLoading={isLoading}
                        onClick={onEditMyProfile}
                        size="sm"
                    >
                        ذخیره تغییرات
                    </Button>
                </Box>
                <Box as="div" w="full" alignItems={`flex-start`} className={classes.fields}>
                    <VStack spacing={4} w="full">
                        <FormControl>
                            <FormLabel fontSize={"14px"} mb={2}>
                                شهر محل سکونت
                            </FormLabel>
                            <Input
                                variant={"filled"}
                                type="text"
                                defaultValue={values.city}
                                onChange={handleChange("city")}
                                id="edit-city-name"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize={"14px"} mb={2}>
                                صفحه شخصی
                            </FormLabel>
                            <Input
                                variant={"filled"}
                                dir="ltr"
                                type="text"
                                defaultValue={values.website}
                                onChange={handleChange("website")}
                                id="edit-website-name"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize={"14px"} mb={2}>
                                دانشگاه
                            </FormLabel>
                            <Input
                                variant={"filled"}
                                type="text"
                                defaultValue={values.university}
                                onChange={handleChange("university")}
                                id="edit-university-name"
                            />
                        </FormControl>
                    </VStack>
                    <FormControl>
                        <FormLabel fontSize={"14px"} mb={2}>
                            توضیحات
                        </FormLabel>
                        <Textarea
                            variant={"filled"}
                            defaultValue={values.biography}
                            onChange={handleChange("biography")}
                            id="edit-biography"
                            resize="none"
                            maxLength={200}
                            w="full"
                            h={200}
                        />
                        <FormHelperText textAlign={"end"}>{`${values.biography.length}/200`}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>

            <div className={`flex flex-col w-full ${classes.social_info_wrapper}`}>
                <Heading size="md" fontWeight={700} mb={8}>
                    شبکه‌های اجتماعی
                </Heading>
                <SocialManager
                    defaultValue={values.links}
                    onChange={(data) => {
                        setValues({
                            ...values,
                            links: data,
                        });
                    }}
                />
            </div>
        </VStack>
    );
};
