import React from "react";
import { PersonalInfoEdit } from "./personalInfoEdit";
import { ValidationInfo } from "./validationInfo";
import { GeneralInfoEdit } from "./generalInfoEdit";
import { Box, VStack } from "@chakra-ui/react";
import Style from "./profileEdit.module.css";
import { IProfileData, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const ProfileEdit = (props: {
    profileData: IProfileData;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    return (
        <VStack className={Style.container} w="full" mx="auto" spacing={4} p={8}>
            <Box as="div" className={`flex flex-row w-full mx-auto ${Style.container_inner}`}>
                <ValidationInfo
                    email={props.profileData.email}
                    phone_number={props.profileData.phone_number}
                    is_verified={props.profileData.is_verified}
                    toast={props.toast}
                    router={props.router}
                />
                <GeneralInfoEdit
                    toast={props.toast}
                    profileData={props.profileData}
                    setHasChanged={props.setChanged}
                    router={props.router}
                />
            </Box>
            <PersonalInfoEdit profileData={props.profileData} toast={props.toast} setHasChanged={props.setChanged} router={props.router} />
        </VStack>
    );
};
