import React, { useState, useEffect } from "react";
import { NextRouter } from "next/router";
import { Pagination, RoboEpicsMarkdown } from "@components";
import {
    Badge,
    Box,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Tooltip,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Textarea,
    RadioGroup,
    Radio,
    Divider,
    ModalFooter,
    Text,
} from "@chakra-ui/react";
import { getTeamSubmissions, retrieveLogContents, retrieveLogUrl } from "@util/api/problem_enter_id";
import { convertDate } from "@util/dateConverter";
import { LEADERBOARD_ITEMS_PER_PAGE, RUN_STATUS, SUBMISSIONS_ITEMS_PER_PAGE, SUBMISSION_STATUS } from "@util/constants";

import Style from "./userSubmissions.module.css";
import { RefreshOutline } from "react-ionicons";
import {
    changeSubmissionScoreManually,
    retrieveProblemSubmissions,
    retrieveSubmissionResult,
    retrieveSubmissionResultContents,
} from "@util/api/problems";
import {
    IParticipantsSubmissionData,
    IProblemEnterSubmission,
    IRoboFormData,
    IRunSubmissionData,
    ISubScoreDefData,
    IToastOptions,
    IUserSubmissionData,
} from "@util/interfaces";
import { IUser } from "@redux/slices/user";
import { problemTextGet, problemTextListGet } from "@util/api/problem_text";

export const UserSubmissions = (props: {
    problemEnterId: number;
    gimulatorTag: string;
    evaluationMode: 10 | 20 | 30;
    toast: IToastOptions;
    router: NextRouter;
    user: IUser;
}) => {
    const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [userSubmissionData, setUserSubmissionData] = useState<IUserSubmissionData>(null);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(props.router.query.p ? parseInt(props.router.query.p.toString()) : 1);
    const columns = [
        { key: "id", label: "شماره ارسال" },
        { key: "date_created", label: "تاریخ" },
        { key: "status", label: "وضعیت" },
        { key: "sub_report", label: "گزارش" },
        { key: "sub_details", label: "جزئیات" },
    ];
    const [dynamicCols, setDynamicCols] = useState<Array<Record<string, string>>>([]);

    const [logData, setLogData] = useState({ open: false, message: "" });
    const [submissionDetailData, setSubmissionDetailData] = useState<{
        open: boolean;
        runs: Array<IRunSubmissionData>;
    }>({
        open: false,
        runs: [],
    });

    const table_header_text = useColorModeValue("RoboEpics.black.500", "#FFF");

    const setData = async () => {
        setLoadingComponent(true);

        const offset = (currentPage - 1) * SUBMISSIONS_ITEMS_PER_PAGE;
        const serverResponse = (await getTeamSubmissions(props.problemEnterId, offset, SUBMISSIONS_ITEMS_PER_PAGE, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setTotalItems(parseInt(data.count));
            setUserSubmissionData(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }

        setLoadingComponent(false);
    };

    const getLogContent = async (url: string) => {
        const serverResponse = await retrieveLogContents(url);
        if (serverResponse.ok) {
            setLogData({ open: true, message: await serverResponse.text() });
        } else {
            props.toast({
                title: "مشکلی در دریافت گزارش ارسال رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.text()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };

    const getLogUrl = async (id: number) => {
        const serverResponse = (await retrieveLogUrl(id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { url } = await serverResponse.json();
            await getLogContent(url);
        } else {
            props.toast({
                title: "مشکلی در دریافت گزارش ارسال رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };

    useEffect(() => {
        if (props.problemEnterId) {
            setData();
        }
    }, [props.problemEnterId, props.user, currentPage]);

    useEffect(() => {
        if (userSubmissionData) {
            const cols = userSubmissionData?.results
                .find((d) => d.submission_scores.length > 0)
                ?.submission_scores.map((el) => {
                    return { key: `submission_score_${el.id}`, label: el.definition };
                });
            setDynamicCols(cols ?? []);
        }
    }, [userSubmissionData]);

    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center my-12">
                <Spinner mb={4} />
            </Box>
        );
    }
    if (errorLoading.hasError) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }

    return (
        <>
            <Box className="flex flex-row w-full justify-end">
                <IconButton
                    variant="ghost"
                    icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                    size="sm"
                    aria-label="Refresh list"
                    onClick={() => {
                        setData();
                    }}
                />
            </Box>
            <Box overflowX="auto" className="h-full w-full" as="div" pb={4}>
                <Table size="lg" variant="simple">
                    <Thead color={table_header_text}>
                        <Tr key="head">
                            {columns.slice(0, 3).map((col) => {
                                return (
                                    <Th textAlign="center" whiteSpace="nowrap" pl={0} pr={3} key={col.key}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                            {dynamicCols.map((col) => {
                                return (
                                    <Th key={col.key} textAlign="center" whiteSpace="nowrap" pl={0} pr={3}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                            {columns.slice(3).map((col) => {
                                return (
                                    <Th key={col.key} textAlign="center" whiteSpace="nowrap" pl={0} pr={3}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userSubmissionData?.results.map((element) => {
                            return (
                                <Tr key={element.id}>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        {element.id}
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        {convertDate(element.date_created, "D MMMM YYYY - HH:mm:ss")}
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="0">
                                        <Badge
                                            px={2}
                                            py={1}
                                            colorScheme={
                                                props.evaluationMode === 20
                                                    ? getSubmissionBadgeColorScheme(element, 20)
                                                    : props.evaluationMode === 10
                                                    ? getSubmissionBadgeColorScheme(element, 10)
                                                    : element.status !== 100
                                                    ? getSubmissionBadgeColorScheme(element, 30)
                                                    : "green"
                                            }
                                        >
                                            {props.evaluationMode !== 30
                                                ? getSubmissionBadgeLabel(element, props.evaluationMode)
                                                : element.status !== 100
                                                ? getSubmissionBadgeLabel(element, 30)
                                                : "ثبت موفقیت‌آمیز ارسال"}
                                            {/*  : "خطایی درون سیستم رخ داده (گام 4 از 7)"} */}
                                        </Badge>
                                    </Td>
                                    {element.submission_scores.length ? (
                                        <>
                                            {element.submission_scores.map((score, index) => {
                                                return (
                                                    <Td pl="0" pr="3" textAlign={["center"]} key={index}>
                                                        {score.value ? (
                                                            <Tooltip hasArrow label={score.definition} placement="top">
                                                                {score.value}
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip hasArrow label="No score submitted" placement="top">
                                                                {"---"}
                                                            </Tooltip>
                                                        )}
                                                    </Td>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        dynamicCols.map((col) => (
                                            <Td textAlign="center" key={col.key} whiteSpace="nowrap">
                                                <Tooltip hasArrow label="Nothing to Show" placement="top">
                                                    {"---"}
                                                </Tooltip>
                                            </Td>
                                        ))
                                    )}
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        <Button
                                            isDisabled={element.runs[0] == null || element.runs[0].status !== 90}
                                            size="xs"
                                            variant={useColorModeValue("solid", "outline")}
                                            colorScheme={"RoboEpics.turquoise"}
                                            fontWeight="300"
                                            color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                            onClick={() => {
                                                getLogUrl(element.runs[0].id);
                                            }}
                                        >
                                            مشاهده گزارش
                                        </Button>
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        <Button
                                            isDisabled={element.runs.length === 0 || props.evaluationMode === 10}
                                            size="xs"
                                            variant={useColorModeValue("solid", "outline")}
                                            colorScheme={"RoboEpics.turquoise"}
                                            fontWeight="300"
                                            color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                            onClick={() => {
                                                setSubmissionDetailData({
                                                    open: true,
                                                    runs: element.runs,
                                                });
                                            }}
                                        >
                                            مشاهده جزئیات
                                        </Button>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <Pagination
                    totalRecords={totalItems}
                    pageNeighbours={1}
                    currentPage={currentPage}
                    onPageChanged={(page) => {
                        setCurrentPage(page);
                        props.router.push(
                            `/c/${props.router.query.competition}/p/${props.router.query.phase_id}/p/${props.router.query.problem}?v=submissions&p=${page}`,
                            undefined,
                            { shallow: true },
                        );
                    }}
                />
            </Box>
            <RetrieveLogModal
                isOpen={logData.open}
                onClose={() => {
                    setLogData({ open: false, message: "" });
                }}
                message={logData.message}
            />
            <UserSubmissionDetailsModal
                isOpen={submissionDetailData.open}
                onClose={() => {
                    setSubmissionDetailData({ open: false, runs: [] });
                }}
                runs={submissionDetailData.runs}
                evaluationMode={props.evaluationMode}
                router={props.router}
            />
        </>
    );
};
const RetrieveLogModal = (props: { isOpen: boolean; onClose: () => void; message: string }) => {
    return (
        <Modal isOpen={props.isOpen} onClose={() => props.onClose()} isCentered size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>گزارش ارسال</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    maxH={400}
                    overflowY={"auto"}
                    mb={8}
                    mt={4}
                    mx={4}
                    backgroundColor={useColorModeValue("RoboEpics.black.300", "RoboEpics.dark.700")}
                >
                    <pre style={{ whiteSpace: "pre-wrap" }}>{props.message}</pre>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export const ParticipantsSubmissons = (props: {
    problem: string;
    evaluationMode: 10 | 20 | 30;
    subScoreDefs: Array<ISubScoreDefData>;
    toast: IToastOptions;
    router: NextRouter;
    user: IUser;
    isOutputPresentable: boolean;
}) => {
    const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [participantsSubmissionData, setparticipantsSubmissionData] = useState<IParticipantsSubmissionData>(null);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(props.router.query.p ? parseInt(props.router.query.p.toString()) : 1);
    const columns = [
        { key: "id", label: "شماره ارسال" },
        { key: "submitter", label: "ارسال‌کننده" },
        { key: "date_created", label: "تاریخ" },
        { key: "status", label: "وضعیت" },
        { key: "output_file", label: "دریافت فایل خروجی" },
        { key: "sub_details", label: "مشاهده جزئیات" },
        { key: "sub_score_change", label: "تعیین امتیاز" },
    ];
    const [dynamicCols, setDynamicCols] = useState<Array<Record<string, string>>>([]);

    const [manualScoreModalData, setManualScoreModalData] = useState<{
        sub_id: number;
    }>(null);
    const [manualScoreModalDataVisible, setManualScoreModalDataVisible] = useState(false);

    const [submissionDetailData, setSubmissionDetailData] = useState<{
        open: boolean;
        runs: Array<IRunSubmissionData>;
    }>({
        open: false,
        runs: [],
    });
    const [presentSubmissionModalData, setPresentSubmissionModalData] = useState<{ open: boolean; id: number }>({
        open: false,
        id: undefined,
    });
    const [form, setForm] = useState<IRoboFormData>(undefined);

    const table_header_text = useColorModeValue("RoboEpics.black.500", "#FFF");

    const setData = async () => {
        setLoadingComponent(true);

        const startIndex = (currentPage - 1) * LEADERBOARD_ITEMS_PER_PAGE;
        const endIndex = startIndex + LEADERBOARD_ITEMS_PER_PAGE;
        const serverResponse = (await retrieveProblemSubmissions(
            props.problem,
            {
                start: startIndex,
                end: endIndex,
            },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setTotalItems(parseInt(data.total));
            setparticipantsSubmissionData(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }

        setLoadingComponent(false);
    };

    const setFormData = async () => {
        const serverResponse = (await problemTextListGet(props.problem)) as Response;
        let id;
        let form;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            id = data.find((el) => el.content_type === 50).id;
            form = await problemTextGet(id).then((res: Response) => res.text);

            setForm(JSON.parse(form.toString()));
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
    };

    const getOutputFile = async (id: number) => {
        if (props.isOutputPresentable) {
            setPresentSubmissionModalData({ open: true, id: id });
        } else {
            const serverResponse = (await retrieveSubmissionResult(id, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const { urls } = await serverResponse.json();
                Object.values(urls).forEach((value: string) => {
                    window.location.href = value;
                });
            } else {
                setErrorLoading({
                    hasError: true,
                    code: serverResponse.status,
                    details: await serverResponse.json(),
                    status: serverResponse.statusText,
                });
            }
        }
    };

    useEffect(() => {
        if (props.problem) setData();
    }, [props.problem, props.user, currentPage]);

    useEffect(() => {
        if (participantsSubmissionData) {
            const cols = participantsSubmissionData?.results
                .find((d) => d.submission_scores.length > 0)
                ?.submission_scores.map((el) => {
                    return { key: `submission_score_${el.id}`, label: el.definition };
                });
            setDynamicCols(cols ?? []);
        }
    }, [participantsSubmissionData]);

    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center my-12">
                <Spinner mb={4} />
            </Box>
        );
    }

    if (errorLoading.hasError) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }

    return (
        <>
            <Box className="flex flex-row w-full justify-end">
                <IconButton
                    variant="ghost"
                    icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                    size="sm"
                    aria-label="Refresh list"
                    onClick={() => {
                        setData();
                    }}
                />
            </Box>
            <Box overflowX="auto" className="h-full w-full pb-4" as="div">
                <Table size="lg" variant="simple" ml={4}>
                    <Thead color={table_header_text}>
                        <Tr key="head">
                            {columns.slice(0, 4).map((col) => {
                                return (
                                    <Th key={col.key} textAlign="center" whiteSpace="nowrap" pl={0} pr={3}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                            {dynamicCols.map((col) => {
                                return (
                                    <Th key={col.key} textAlign="center" whiteSpace="nowrap" pl={0} pr={3}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                            {columns.slice(4).map((col) => {
                                return (
                                    <Th key={col.key} textAlign="center" whiteSpace="nowrap" pl={0} pr={3}>
                                        {col.label}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {participantsSubmissionData?.results.map((element) => {
                            return (
                                <Tr key={element.id}>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        {element.id}
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        <Button
                                            variant="ghost"
                                            minW="200px"
                                            justifyContent="flex-start"
                                            padding="18px 16px"
                                            onClick={() => {
                                                props.router.push(`/users/${element.submitter}`);
                                            }}
                                        >
                                            {element.problem_enter}
                                        </Button>
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        {convertDate(element.date_created, "D MMMM YYYY - HH:mm:ss")}
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="0">
                                        <Badge px={2} py={1} colorScheme={SUBMISSION_STATUS[element.status].colorScheme}>
                                            {SUBMISSION_STATUS[element.status].label}
                                        </Badge>
                                    </Td>
                                    {element.submission_scores.length
                                        ? dynamicCols.map((col, index) => {
                                              const data = element.submission_scores.find((score) => score.definition === col.label);
                                              return (
                                                  <Td pl="0" pr="3" textAlign={["center"]} key={index}>
                                                      {data?.value ? (
                                                          <Tooltip hasArrow label={data.definition} placement="top">
                                                              {data.value}
                                                          </Tooltip>
                                                      ) : (
                                                          <Tooltip hasArrow label="No score submitted" placement="top">
                                                              {"---"}
                                                          </Tooltip>
                                                      )}
                                                  </Td>
                                              );
                                          })
                                        : dynamicCols.map((col) => (
                                              <Td textAlign="center" key={col.key} whiteSpace="nowrap">
                                                  <Tooltip hasArrow label="Nothing to Show" placement="top">
                                                      {"---"}
                                                  </Tooltip>
                                              </Td>
                                          ))}
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        <Button
                                            size="xs"
                                            variant={useColorModeValue("solid", "outline")}
                                            colorScheme={"RoboEpics.turquoise"}
                                            fontWeight="300"
                                            color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                            onClick={() => {
                                                getOutputFile(element.id);
                                            }}
                                        >
                                            مشاهده فایل
                                        </Button>
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="3">
                                        <Button
                                            isDisabled={element.runs.length === 0}
                                            size="xs"
                                            variant={useColorModeValue("solid", "outline")}
                                            colorScheme={"RoboEpics.turquoise"}
                                            fontWeight="300"
                                            color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                            onClick={() => {
                                                setSubmissionDetailData({
                                                    open: true,
                                                    runs: element.runs,
                                                });
                                            }}
                                        >
                                            مشاهده جزئیات
                                        </Button>
                                    </Td>
                                    <Td textAlign={["center"]} pl="0" pr="0">
                                        <Button
                                            size="xs"
                                            variant={useColorModeValue("solid", "outline")}
                                            colorScheme={"RoboEpics.turquoise"}
                                            fontWeight="300"
                                            color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                            onClick={() => {
                                                setManualScoreModalData({
                                                    sub_id: element.id,
                                                });
                                                setManualScoreModalDataVisible(true);
                                            }}
                                        >
                                            تعیین امتیاز
                                        </Button>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <Pagination
                    totalRecords={totalItems}
                    pageNeighbours={1}
                    currentPage={currentPage}
                    onPageChanged={(page) => {
                        setCurrentPage(page);
                        props.router.push(
                            `/c/${props.router.query.competition}/p/${props.router.query.phase_id}/p/${props.router.query.problem}?v=submissions&p=${page}`,
                            undefined,
                            { shallow: true },
                        );
                    }}
                />
            </Box>
            <ManualScoreModal
                isOpen={manualScoreModalDataVisible}
                onClose={() => {
                    setManualScoreModalDataVisible(false);
                    setManualScoreModalData(null);
                }}
                data={manualScoreModalData}
                subScoreDefs={props.subScoreDefs}
                problem_path={props.problem}
                toast={props.toast}
                router={props.router}
            />
            <ParticipantSubmissionDetailsModal
                isOpen={submissionDetailData.open}
                onClose={() => {
                    setSubmissionDetailData({ open: false, runs: [] });
                }}
                runs={submissionDetailData.runs}
                router={props.router}
            />
            <PresentSubmissionContentsModal
                isOpen={presentSubmissionModalData.open}
                onClose={() => {
                    setPresentSubmissionModalData({ open: false, id: undefined });
                }}
                subId={presentSubmissionModalData.id}
                problem={props.problem}
                form={form}
            />
        </>
    );
};
const getSubmissionBadgeColorScheme = (element: IProblemEnterSubmission, evaluationMode: 10 | 20 | 30) => {
    switch (evaluationMode) {
        case 10:
            return SUBMISSION_STATUS[element.status].colorScheme;
            break;
        case 20:
            return SUBMISSION_STATUS[element.status].colorScheme;
            break;
        case 30:
            if (element.status === 100) {
                return "orange";
            } else {
                return SUBMISSION_STATUS[element.status].colorScheme;
            }
            break;
        default:
            break;
    }
};
const getRunBadgeColorScheme = (run: {
    id: number;
    owner: string;
    gatheredsubmission_set: Array<{
        submission: number;
        score_set: Array<{ definition: string; value: number }>;
    }>;
    status: number;
    date_created: string;
}) => {
    return RUN_STATUS[run.status].colorScheme;
};
const getSubmissionBadgeLabel = (element: IProblemEnterSubmission, evaluationMode: 10 | 20 | 30) => {
    if (evaluationMode !== 30) {
        return `${SUBMISSION_STATUS[element.status].label} (گام ${SUBMISSION_STATUS[element.status].step} از 3)`;
    } else {
        return `${SUBMISSION_STATUS[element.status].label} (گام ${SUBMISSION_STATUS[element.status].step} از 7)`;
    }
};
const getRunBadgeLabel = (
    run: {
        id: number;
        owner: string;
        gatheredsubmission_set: Array<{
            submission: number;
            score_set: Array<{ definition: string; value: number }>;
        }>;
        status: number;
        date_created: string;
    },
    evaluationMode: 10 | 20 | 30,
) => {
    if (evaluationMode !== 20) {
        return `${RUN_STATUS[run.status].label} (گام ${RUN_STATUS[run.status].step} از 7)`;
    } else {
        return `${RUN_STATUS[run.status].label} (گام ${RUN_STATUS[run.status].step - 3} از 4)`;
    }
};
const ManualScoreModal = (props: {
    isOpen: boolean;
    data: { sub_id: number };
    onClose: () => void;
    subScoreDefs: Array<ISubScoreDefData>;
    problem_path: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [values, SetValues] = useState<Array<string>>([""]);
    const [definitions, setDefinitions] = useState<Array<number>>(null);
    const [loading, setLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(0);

    const onConfirm = () => {
        setLoading(true);

        definitions.forEach(async (definition, index) => {
            await onChangeSubmissionScoreManually(definition, values[index], props.data.sub_id, props.problem_path);
        });

        setLoading(false);
    };
    const onChangeSubmissionScoreManually = async (definition: number, value: string, sub_id: number, problem_path: string) => {
        const serverResponse = (await changeSubmissionScoreManually(problem_path, sub_id, definition, value, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setHasChanged((current) => current + 1);
        } else {
            if (serverResponse.status === 400) {
                const data = await serverResponse.json();
                switch (data.value[0]) {
                    case "A valid number is required.":
                        props.toast({
                            description: "مقدار امتیاز وارد شده قابل قبول نمی‌باشد!",
                            isClosable: true,
                            status: "error",
                            duration: 3000,
                            variant: "subtle",
                        });
                        break;

                    default:
                        props.toast({
                            description: JSON.stringify(data),
                            isClosable: true,
                            status: "error",
                            variant: "subtle",
                            duration: 5000,
                        });
                        break;
                }
            } else {
                props.toast({
                    description: "مشکلی در اعمال تغییرات شما به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",

                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    variant: "subtle",
                });
            }
        }
    };
    useEffect(() => {
        if (hasChanged === definitions?.length) {
            props.toast({
                description: "امتیاز این ارسال با موفقیت تعیین شد!",
                status: "success",
                duration: 5000,
            });
            setTimeout(() => {
                props.onClose();
            }, 3000);
        }
    }, [hasChanged]);
    useEffect(() => {
        let v = [];
        let d = [];
        props.subScoreDefs.forEach((value) => {
            v = [...v, ""];
            d = [...d, value.id];
        });
        SetValues(v);
        setDefinitions(d);
    }, [props.subScoreDefs]);
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>تعیین امتیاز برای ارسال</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        {props.subScoreDefs.map((definition, index) => {
                            return (
                                <ManualScoreModalFormControl definition={definition} values={values} setValues={SetValues} index={index} />
                            );
                        })}
                        <Button
                            isDisabled={values.some((value) => value.length === 0)}
                            isLoading={loading}
                            placeSelf={"flex-end"}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            mb={2}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {`تعیین امتیاز${props.subScoreDefs.length > 1 ? "ها" : ""}`}
                        </Button>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
const ManualScoreModalFormControl = (props: {
    definition: ISubScoreDefData;
    values: Array<string>;
    setValues: React.Dispatch<React.SetStateAction<string[]>>;
    index: number;
}) => {
    const [value, setValue] = useState(props.values[props.index]);
    const newValues = props.values.slice();
    return (
        <FormControl key={`${props.definition.id}-${props.definition.score_definition.id}`} isRequired>
            <FormLabel>
                <Tooltip
                    hasArrow
                    placement="left"
                    isDisabled={props.definition.score_definition.description.length === 0}
                    label={props.definition.score_definition.description}
                >
                    {props.definition.score_definition.name}
                </Tooltip>
            </FormLabel>
            <Input
                variant="filled"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    newValues[props.index] = e.target.value;
                    props.setValues(newValues);
                }}
            />
        </FormControl>
    );
};
const ParticipantSubmissionDetailsModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    runs: Array<IRunSubmissionData>;
    router: NextRouter;
}) => {
    return (
        <Modal
            isCentered
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>جزئیات ارسال</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th textAlign={["center"]} pl="0" pr="3" whiteSpace="nowrap">
                                    شماره اجرا
                                </Th>
                                <Th textAlign={"center"} whiteSpace="nowrap">
                                    اجرا کننده
                                </Th>
                                <Th textAlign={["center"]} whiteSpace="nowrap">
                                    تاریخ ثبت
                                </Th>
                                <Th textAlign={["center"]} whiteSpace="nowrap">
                                    وضعیت اجرا
                                </Th>
                                {props.runs.length &&
                                    props.runs[0].gatheredsubmission_set[0].score_set.length &&
                                    props.runs[0].gatheredsubmission_set[0].score_set.map((el, index) => {
                                        return (
                                            <Th key={index} textAlign={["center"]} whiteSpace="nowrap">
                                                {el.definition}
                                            </Th>
                                        );
                                    })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.runs.map((run) => {
                                return (
                                    <Tr key={run.id}>
                                        <Td textAlign={"center"}>{run.id}</Td>
                                        <Td>
                                            <Button
                                                variant="ghost"
                                                minW="120px"
                                                justifyContent="flex-start"
                                                padding="18px 16px"
                                                onClick={() => {
                                                    props.router.push(`/users/${run.owner}`);
                                                }}
                                            >
                                                {run.owner}
                                            </Button>
                                        </Td>
                                        <Td textAlign={"center"}>{convertDate(run.date_created, "D MMMM YYYY - HH:mm:ss")}</Td>
                                        <Td textAlign={"center"}>
                                            <Badge px={2} py={1} colorScheme={RUN_STATUS[run.status].colorScheme}>
                                                {RUN_STATUS[run.status].label}
                                            </Badge>
                                        </Td>
                                        {run.gatheredsubmission_set[0].score_set.map((score) => {
                                            return (
                                                <Td key={score.definition} textAlign={"center"}>
                                                    {score.value}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
const UserSubmissionDetailsModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    runs: Array<IRunSubmissionData>;
    evaluationMode: 10 | 20 | 30;
    router: NextRouter;
}) => {
    return (
        <Modal
            isCentered
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>جزئیات ارسال</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th textAlign={["center"]} pl="0" pr="3" whiteSpace="nowrap">
                                    شماره اجرا
                                </Th>
                                <Th textAlign={"center"} whiteSpace="nowrap">
                                    اجرا کننده
                                </Th>
                                <Th textAlign={["center"]} whiteSpace="nowrap">
                                    تاریخ ثبت
                                </Th>
                                <Th textAlign={["center"]} whiteSpace="nowrap">
                                    وضعیت اجرا
                                </Th>
                                {props.runs.length &&
                                    props.runs[0].gatheredsubmission_set[0].score_set.length &&
                                    props.runs[0].gatheredsubmission_set[0].score_set.map((el, index) => {
                                        return (
                                            <Th key={index} textAlign={["center"]} whiteSpace="nowrap">
                                                {el.definition}
                                            </Th>
                                        );
                                    })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.runs.map((run) => {
                                return (
                                    <Tr key={run.id}>
                                        <Td textAlign={"center"}>{run.id}</Td>
                                        <Td>
                                            {" "}
                                            <Button
                                                variant="ghost"
                                                minW="120px"
                                                justifyContent="flex-start"
                                                padding="18px 16px"
                                                onClick={() => {
                                                    props.router.push(`/users/${run.owner}`);
                                                }}
                                            >
                                                {run.owner}
                                            </Button>
                                        </Td>
                                        <Td textAlign={"center"}>{convertDate(run.date_created, "D MMMM YYYY - HH:mm:ss")}</Td>
                                        <Td textAlign={"center"}>
                                            <Badge px={2} py={1} colorScheme={getRunBadgeColorScheme(run)}>
                                                {getRunBadgeLabel(run, props.evaluationMode)}
                                            </Badge>
                                        </Td>
                                        {run.gatheredsubmission_set[0].score_set.map((score) => {
                                            return score.value ? (
                                                <Td key={score.definition} textAlign={"center"}>
                                                    {score.value}
                                                </Td>
                                            ) : (
                                                <Td>---</Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
const PresentSubmissionContentsModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    subId: number;
    problem: string;
    form: IRoboFormData;
}) => {
    const [resultJson, setResultJson] = useState<Record<string, string>>({});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (props.isOpen) {
            getResultJson();
        }
    }, [props.isOpen, props.subId]);
    const getResultJson = async () => {
        setIsLoading(true);
        const result = (await retrieveSubmissionResult(props.subId)) as Response;
        if (result.status) {
            setIsError(true);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const { urls }: Record<string, string> = result;
            Object.values(urls)[0]
                ? await retrieveSubmissionResultContents(Object.values(urls)[0]).then((res) => setResultJson(res))
                : setIsError(true);
        }
        setIsLoading(false);
    };
    const QuestionnaireTheme = (props: { form: IRoboFormData; answers: Record<string, string> }) => {
        return (
            <Box>
                {props.form.cells.map((el) => {
                    let coreEl = null;

                    switch (el.type) {
                        case "singleline":
                            coreEl = (
                                <FormControl>
                                    <Box display="flex" flexDirection="row">
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel fontSize="sm">{el.content}</FormLabel>
                                            )}
                                        </Box>
                                    </Box>
                                    <Input size="sm" defaultValue={props.answers[el.key]} isReadOnly />
                                </FormControl>
                            );
                            break;
                        case "multiline":
                            coreEl = (
                                <FormControl>
                                    <Box display="flex" flexDirection="row">
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel fontSize="sm">{el.content}</FormLabel>
                                            )}
                                        </Box>
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                    </Box>
                                    <Textarea resize="none" isFullWidth size="sm" isReadOnly defaultValue={props.answers[el.key]} />
                                </FormControl>
                            );
                            break;
                        case "multichoice":
                            coreEl = (
                                <FormControl>
                                    <Box display="flex" flexDirection="row">
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel as="legend" fontSize="sm">
                                                    {el.content}
                                                </FormLabel>
                                            )}
                                        </Box>
                                    </Box>
                                    <RadioGroup defaultValue={props.answers[el.key]}>
                                        <VStack spacing="10px" alignItems="start">
                                            {el.choices.map((option: { label: string; value: string }, index) => {
                                                return (
                                                    <Radio fontSize="sm" value={option.value} size="sm" key={index} isReadOnly>
                                                        {option.label}
                                                    </Radio>
                                                );
                                            })}
                                        </VStack>
                                    </RadioGroup>
                                </FormControl>
                            );
                            break;
                        case "markdown":
                            coreEl = <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>;
                            break;
                        default:
                            coreEl = <div>{el.content}</div>;
                            break;
                    }
                    return (
                        <Box key={el.key}>
                            <Box className="py-3 mb-3">{coreEl}</Box>
                            <Divider />
                        </Box>
                    );
                })}
            </Box>
        );
    };
    const ChatTheme = (props: { form: IRoboFormData; answers: Record<string, string> }) => {
        return (
            <VStack spacing={4} w="full">
                {props.form.cells.map((el) => {
                    let recieved = document.createElement("div");
                    document.body.appendChild(recieved);
                    recieved.style.fontSize = "1rem";
                    recieved.style.position = "absolute";
                    recieved.style.left = "-1000px";
                    recieved.style.top = "-1000px";

                    recieved.textContent = el.content;
                    const result_recieved = {
                        width: recieved.clientWidth,
                    };
                    document.body.removeChild(recieved);
                    recieved = null;

                    let sent = document.createElement("div");
                    document.body.appendChild(sent);
                    sent.style.fontSize = "1rem";
                    sent.style.position = "absolute";
                    sent.style.left = "-1000px";
                    sent.style.top = "-1000px";

                    sent.textContent = props.answers[el.key];
                    const result_sent = {
                        width: sent.clientWidth,
                    };
                    document.body.removeChild(sent);
                    sent = null;

                    return (
                        <VStack w="full" spacing={4} key={el.key}>
                            <Box
                                px={4}
                                py={1}
                                className={`${Style.message_recieved}`}
                                maxW={result_recieved.width > 400 ? 400 : "max-content"}
                            >
                                {el.content}
                            </Box>
                            <Box px={4} py={1} className={`${Style.message_sent}`} maxWidth={result_sent.width > 400 ? 400 : "max-content"}>
                                {props.answers[el.key]}
                            </Box>
                        </VStack>
                    );
                })}
            </VStack>
        );
    };
    const Renderer = (props: { form: IRoboFormData; answers: Record<string, string> }) => {
        if (isLoading) return <Spinner size="sm" alignSelf={"center"} />;
        if (isError)
            return (
                <Text alignSelf={"center"} mb={0}>
                    مشکلی در دریافت پاسخ ارسال به وجود آمده!
                </Text>
            );
        switch (props.form.theme) {
            case "chat":
                return <ChatTheme form={props.form} answers={props.answers} />;

            case "questionnaire":
            default:
                return <QuestionnaireTheme form={props.form} answers={props.answers} />;
        }
    };
    return (
        <Modal
            isCentered
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setIsLoading(false);
                setIsError(false);
                setResultJson({});
            }}
            size={isLoading || isError ? "lg" : "2xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Form Output Presentation</ModalHeader>
                <ModalCloseButton />
                <ModalBody maxH={500} overflowY={"auto"} overflowX={"auto"}>
                    <Box className="flex flex-row justify-center">
                        <Renderer answers={resultJson} form={props.form} />
                    </Box>
                </ModalBody>
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};
