import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const getAllNotebooks = async (query?: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${query ? "?title=" + query : ""}`, {}, "preferred", ctx);

export const createNotebook = async (form: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/`, { method: "POST", body: form }, "required", ctx);

export const createNotebookVersion = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/version`, { method: "POST" }, "required", ctx);

export const retrieveNotebookCommits = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/commits`, {}, "required", ctx);

export const getNotebook = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}`, {}, "preferred", ctx);

export const getVersionsList = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/version`, {}, "off", ctx); // TODO ask Mirlou about "off" option

export const syncNotebook = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/sync`, {}, "required", ctx);

export const getNotebookUpvote = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/upvote`, {}, "preferred", ctx);

export const upvoteNotebook = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/upvote`, { method: "PUT" }, "required", ctx);

export const getNotebookPreview = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}/preview`, {}, "preferred", ctx);

export const deleteNotebook = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}`, { method: "DELETE" }, "required", ctx);

export const editNotebookJson = async (path: string | string[], data: Record<string, string>, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/notebook/${path}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const editNotebookFormData = async (path: string | string[], data: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/notebook/${path}`, { method: "PATCH", body: data }, "required", ctx);
