import React, { useEffect, useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Box,
    Button,
    Divider,
    Heading,
    IconButton,
    Link,
    Tag,
    Text,
    Tooltip,
    useColorModeValue,
} from "@chakra-ui/react";
import Style from "./datasetView.module.css";
import { Edit2, Tag as TagIcon, Download as DownloadIcon, File as FileIcon } from "react-feather";
import { Add, Trash } from "react-ionicons";
import { problemEditJson } from "@util/api/problems";
import { useSelector } from "react-redux";
import { EditModal } from "./editModal";
import { AddModal } from "./addModal";
import { IDatasetBrief, IToastOptions } from "@util/interfaces";
import { getDatasetStatusColor, getDatasetStatusText } from "@util/misc";
import { ShowMore } from "@components";
import { NextRouter } from "next/router";
import { IUser } from "@redux/slices/user";
import axios from "axios";
import fileDownload from "js-file-download";

export const OwnerView = (props: {
    datasets: Array<IDatasetBrief>;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    getContentView: (type: 10 | 20 | 30 | 40 | string, content: string) => JSX.Element;
    toast: IToastOptions;
    router: NextRouter;
    problem: string;
}) => {
    const [chosenDataset, setChosenDataset] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [addMode, setAddMode] = useState(false);

    const [datasetIds, setDatasetIds] = useState<Array<number>>(null);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();
    const user = useSelector((state: { user: IUser }) => state.user);

    const [downloadAllLoading, setDownloadAllLoading] = useState(false);
    const [downloadAllError, setDownloadAllError] = useState(false);

    const onDeleteDataset = async () => {
        const dataset_ids: Array<number> = [];
        props.datasets.forEach((dataset: IDatasetBrief) => {
            dataset_ids.push(dataset.id);
        });
        const newDatasets = dataset_ids.filter((el) => el !== props.datasets[chosenDataset].id);
        const req_data = { datasets: newDatasets };
        const serverResponse = (await problemEditJson(req_data, props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                description: "تغییرات شما با موفقیت اعمال شد!",
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
            });
            props.setEditConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            props.setError(true);
        }
        setDeleteAlertVisible(false);
        setDeleteLoading(false);
    };

    const downloadAllFiles = () => {
        const urls = props.datasets[chosenDataset].file_set.map((file) => file.url);
        let downloadindex = 0;

        setDownloadAllLoading(true);
        urls.forEach((url) => {
            console.log("downloading: ", downloadindex);
            axios
                .get(url, { responseType: "blob" })
                .then((res) => {
                    fileDownload(res.data, props.datasets[chosenDataset].file_set[downloadindex].filename);
                    downloadindex = downloadindex + 1;

                    // console.log(res);
                })
                .catch((err) => {
                    setDownloadAllError(true);
                    console.log(err);
                });
        });

        setDownloadAllLoading(false);
    };

    useEffect(() => {
        let tmp = [];
        props.datasets.forEach((dataset) => {
            tmp = [...tmp, dataset.id];
        });
        setDatasetIds(tmp);
    }, []);

    return (
        <Box className={`flex flex-col mt-4`}>
            <Button
                size={"sm"}
                variant="solid"
                isDisabled
                colorScheme={useColorModeValue("RoboEpics.green", "RoboEpics.azure")}
                leftIcon={<DownloadIcon size={16} />}
                mr={"auto"}
                mb={4}
            >
                دریافت فایل نمونه جواب
            </Button>
            <Box
                className={`flex flex-row w-full mx-4 mb-4 justify-start items-center self-center`}
                overflowY={"hidden"}
                h={48}
                overflowX={"auto"}
            >
                {props.datasets.map((dataset: IDatasetBrief, index: number) => {
                    const isEditable = dataset.dataset_owner === user.data.username;
                    return (
                        <Box
                            key={`.dataset-card-${index}`}
                            className={`flex flex-col border rounded-lg pt-4 px-4 w-full mx-4 ${Style.dataset_card} ${
                                index === chosenDataset
                                    ? useColorModeValue(Style.chosen_dataset_card_light, Style.chosen_dataset_card_dark)
                                    : ""
                            }`}
                            maxW={"3xs"}
                            minW={"3xs"}
                            minH={"36"}
                            maxH={"36"}
                            boxShadow={`0 2px 9px -1px #64dfdf, 0 2px 4px -1px ${useColorModeValue(
                                "rgba(0, 0, 0, 0.06)",
                                "rgba(255, 255, 255, 0.06)",
                            )}`}
                            _hover={{
                                boxShadow: `0 3px 14px -1px #64dfdf,0 3px 5px -2px ${useColorModeValue(
                                    "rgba(0, 0, 0, 0.05)",
                                    "rgba(255, 255, 255, 0.05)",
                                )}`,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setChosenDataset(index);
                                setAddMode(false);
                            }}
                        >
                            <Box className="flex flex-row w-full justify-between items-start">
                                <Heading isTruncated size="sm">
                                    {dataset.title}
                                </Heading>
                                <Badge colorScheme={getDatasetStatusColor(dataset.status)} className="mr-4">
                                    {getDatasetStatusText(dataset.status)}
                                </Badge>
                            </Box>
                            <Divider mb={"0.5em"} />
                            <Text fontSize={"14px"} h="40px" noOfLines={2}>
                                {dataset.subtitle}
                            </Text>
                            <Divider mb={"0.5em"} />
                            <Box className={`flex ${isEditable ? "flex-row" : "flex-row-reverse"} justify-between w-full`}>
                                {isEditable && (
                                    <IconButton
                                        onClick={() => {
                                            setChosenDataset(index);
                                            setEditMode(true);
                                        }}
                                        size="xs"
                                        ml={2}
                                        aria-label="edit-problem-text"
                                        icon={<Edit2 size="13" />}
                                    />
                                )}
                                <IconButton
                                    size="xs"
                                    mr={2}
                                    aria-label="edit-problem-text"
                                    icon={<Trash color="#c53030" height="15px" width="15px" />}
                                    onClick={() => {
                                        setDeleteAlertVisible(true);
                                    }}
                                />
                            </Box>
                        </Box>
                    );
                })}
                <Box
                    key={`.dataset-card-new`}
                    className={`flex flex-col border-dashed border-2 rounded-lg  px-4 w-full mx-4 justify-center items-center ${
                        Style.new_dataset_card
                    } ${addMode ? useColorModeValue(Style.chosen_dataset_card_light, Style.chosen_dataset_card_dark) : ""}`}
                    maxW={"3xs"}
                    minW={"3xs"}
                    minH={"36"}
                    maxH={"36"}
                    _hover={{
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setChosenDataset(-1);
                        setAddMode(true);
                    }}
                >
                    <Add
                        color={addMode ? useColorModeValue("#0077b6", "#fff") : useColorModeValue("#E2E8F0", "rgb(255,255,255,0.16)")}
                        width={"3rem"}
                        height={"3rem"}
                    />
                    <Text color={addMode ? useColorModeValue("#0077b6", "#fff") : useColorModeValue("gray.200", "whiteAlpha.300")}>
                        اضافه کردن دیتاست جدید
                    </Text>
                </Box>
            </Box>
            {addMode ? (
                <Box />
            ) : (
                props.datasets.length !== 0 && (
                    <>
                        <ShowMore height={320} shadowColor={useColorModeValue("rgb(247,247,247)", "rgb(20,24,29)")} speed={500}>
                            <div className="flex flex-row justify-start" id={"show_more_contents"}>
                                <div className={`w-full mx-4 ${Style.container}`}>
                                    <div className="mt-4 flex flex-col w-full">
                                        <div className="flex flex-row items-center">
                                            <Heading as="h2" fontSize="2xl" fontWeight="700" display="inline" mb={0}>
                                                {props.datasets[chosenDataset].title}
                                            </Heading>
                                            <Tooltip hasArrow label="نسخه دیتاست">
                                                <Tag className="mr-4">
                                                    <TagIcon size={16} className="ml-2" color="var(--chakra-colors-green-500)" />
                                                    {props.datasets[chosenDataset].version}
                                                </Tag>
                                            </Tooltip>
                                            <Tooltip hasArrow label="وضعیت دیتاست">
                                                <Badge
                                                    colorScheme={getDatasetStatusColor(props.datasets[chosenDataset].status)}
                                                    className="mr-4"
                                                >
                                                    {getDatasetStatusText(props.datasets[chosenDataset].status)}
                                                </Badge>
                                            </Tooltip>
                                        </div>
                                        {props.datasets[chosenDataset].subtitle && (
                                            <Heading as="h3" fontSize="md" fontWeight="300" mt={3}>
                                                {props.datasets[chosenDataset].subtitle}
                                            </Heading>
                                        )}
                                    </div>
                                    <Divider my="16px" />
                                    <div>
                                        {props.datasets[chosenDataset].dataset_description &&
                                        props.datasets[chosenDataset].dataset_description.length !== 0 ? (
                                            props.getContentView(
                                                props.datasets[chosenDataset].dataset_content_type,
                                                props.datasets[chosenDataset].dataset_description,
                                            )
                                        ) : (
                                            <p>توضیحی وارد نشده است.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ShowMore>
                        <Divider my="16px" />
                        <div className="mt-4">
                            <Heading as="h3" fontSize="xl" fontWeight="700" mb={2}>
                                دانلودها
                            </Heading>
                            <div className="border mt-3 rounded flex flex-col ">
                                {props.datasets[chosenDataset].file_set && (
                                    <Button
                                        size={"sm"}
                                        variant="solid"
                                        colorScheme={useColorModeValue("RoboEpics.green", "RoboEpics.azure")}
                                        leftIcon={<DownloadIcon size={16} />}
                                        isLoading={downloadAllLoading}
                                        m={4}
                                        mr="auto"
                                        onClick={() => {
                                            downloadAllFiles();
                                        }}
                                    >
                                        دریافت همه فایل‌ها
                                    </Button>
                                )}
                                {props.datasets[chosenDataset].file_set?.map((fileEl, index) => {
                                    return (
                                        <Box
                                            className={`w-full flex flex-row items-center my-1 px-3 py-1 ${
                                                index === props.datasets[chosenDataset].file_set.length - 1 ? "" : "border-b"
                                            }`}
                                        >
                                            {/* icon */}
                                            <Box>
                                                <FileIcon size={16} className="ml-3" />
                                            </Box>
                                            {/* title */}
                                            <Box className="flex-grow">
                                                <Box>
                                                    <Link href={fileEl.url} target="_blank">
                                                        <span>{fileEl.filename}</span>
                                                    </Link>
                                                </Box>
                                            </Box>
                                            {/* buttons */}
                                            <Box>
                                                <a href={fileEl.url} target="_blank">
                                                    <IconButton
                                                        variant="ghost"
                                                        size="sm"
                                                        aria-label={`Download '${fileEl.filename}'`}
                                                        icon={<DownloadIcon size={18} />}
                                                    />
                                                </a>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )
            )}
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>با کلیک روی دکمه "تایید" این دیتاست از مجموعه دیتاست‌های این چالش حذف خواهد شد.</AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                onDeleteDataset();
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            {!addMode && chosenDataset !== -1 && (
                <EditModal
                    isOpen={editMode}
                    setEditConfirmed={props.setEditConfirmed}
                    onClose={() => {
                        props.setEditConfirmed(false);
                        setEditMode(false);
                    }}
                    dataset={props.datasets[chosenDataset]}
                    toast={props.toast}
                    router={props.router}
                />
            )}
            <AddModal
                setAddConfirmed={props.setAddConfirmed}
                isOpen={addMode}
                onClose={() => {
                    setAddMode(false);
                    setChosenDataset(0);
                }}
                toast={props.toast}
                router={props.router}
                datasetIds={datasetIds}
            />
        </Box>
    );
};
