import React, { useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    Textarea,
    useColorModeValue,
    VStack,
    FormHelperText,
} from "@chakra-ui/react";
import Style from "./contact.module.css";
import { store } from "@redux/store";
import { contactUsCreate } from "@util/api/misc";
import { LocationOutline, MapOutline, CallOutline } from "react-ionicons";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const ContactUs = (props: { toast: IToastOptions; router: NextRouter }) => {
    const [isLoading, setIsLoading] = useState(false);
    const userEmail = store.getState().user.data.email;
    const userFullName = store.getState().user.data.fullName;
    const [values, setValues] = useState({
        name: userFullName ? userFullName : "",
        email: userEmail ? userEmail : "",
        subject: "",
        description: "",
    });

    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "error" | "success" | "warning";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const onCreateContact = async () => {
        setIsLoading(true);
        const serverResponse = (await contactUsCreate(values.email, values.name, values.subject, values.description, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setAlertProps({
                open: true,
                message: "با سپاس از شما، فرم تماس با RoboEpics با موفقیت ارسال شد. در صورت نیاز با شما ارتباط برقرار خواهیم کرد.",
                status: "success",
            });
            setIsLoading(false);
            setValues({
                ...values,
                subject: "",
                description: "",
            });
        } else {
            props.toast({
                title: "مشکلی در ارسال فرم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setIsLoading(false);
    };
    return (
        <Box as="div" className={`flex flex-col w-full h-full my-12`}>
            <Heading size="xl">تماس با RoboEpics</Heading>

            <Box as="div" className={`flex flex-col w-full py-4 mx-auto`}>
                <Box as="div" className={`flex flex-col flex-wrap w-full py-4 mx-auto`}>
                    <Box as="div" className={`h-full w-full flex flex-row justify-center flex-wrap ${Style.contact_container}`}>
                        <VStack
                            spacing={4}
                            className={`border p-8 my-4 mx-2 rounded-lg items-center justify-center flex-1 ${Style.contact_item_mail}`}
                        >
                            <Heading size="lg" mb={0} isTruncated>
                                پشتیبانی
                            </Heading>
                            <Text mb={0} isTruncated>
                                <a href="mailto:support@roboepics.com">support@roboepics.com</a>
                            </Text>
                        </VStack>
                        <VStack
                            spacing={4}
                            className={`border p-8 my-4 mx-2 rounded-lg items-center justify-center flex-1 ${Style.contact_item_mail}`}
                        >
                            <Heading size="lg" mb={0} isTruncated>
                                منابع انسانی
                            </Heading>
                            <Text mb={0} isTruncated>
                                <a href="mailto:hi@roboepics.com">hi@roboepics.com</a>
                            </Text>
                        </VStack>
                        <VStack
                            spacing={4}
                            className={`border p-8 my-4 mx-2 rounded-lg items-center justify-center flex-1 ${Style.contact_item_mail}`}
                        >
                            <Heading size="lg" mb={0} isTruncated>
                                تجاری
                            </Heading>
                            <Text mb={0} isTruncated>
                                <a href="mailto:biz@roboepics.com">biz@roboepics.com</a>
                            </Text>
                        </VStack>
                    </Box>

                    <Box as="div" className={`flex flex-row w-full justify-between ${Style.infos_container}`}>
                        <Box as="div" className={`flex flex-row w-full justify-start items-center mx-2 ${Style.info_container}`}>
                            <MapOutline color={useColorModeValue("black", "white")} />
                            <Text mb={0} mr={2}>
                                تهران، میدان آزادی،‌ ابتدای بزرگراه شهید لشکری، نبش ایستگاه مترو بیمه،‌ کارخانه نوآوری آزادی، فضای کاری
                                مشترک زاویه
                            </Text>
                        </Box>
                        {/* <VStack as="div" className={`border p-8 mx-2 rounded-lg`}>
                            <LocationOutline color={useColorModeValue("black", "white")} width={"3rem"} height={"3rem"} /> */}
                        <Button
                            mx={2}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            size="md"
                            onClick={() => {
                                window.open("https://goo.gl/maps/wZY3Jg5PaQvWiKUT7", "_blank");
                            }}
                            leftIcon={<LocationOutline color={useColorModeValue("white", "black")} />}
                            w="fit-content"
                            alignSelf={"flex-end"}
                            my={4}
                        >
                            نمایش روی نقشه
                        </Button>
                        {/* </VStack> */}
                    </Box>
                    <Box as="div" className={`flex flex-row w-full justify-between ${Style.infos_container}`}>
                        <Box as="div" className={`flex flex-row w-full justify-start items-center mx-2 ${Style.info_container}`}>
                            <CallOutline color={useColorModeValue("black", "white")} />
                            <Text mb={0} mr={2}>
                                021-44652914
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Divider my={8} />
                <Box as="div" className={`w-full h-full flex flex-col flex-grow `}>
                    <Collapse in={alertProps.open}>
                        <Alert status={alertProps.status} variant={"subtle"} className="mb-4">
                            <AlertIcon />
                            <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                        </Alert>
                    </Collapse>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <VStack spacing="16px" as="div" className="w-full">
                            <Box as="div" className={`flex flex-row w-full justify-between items-center ${Style.full_name_wrapper}`}>
                                <FormControl
                                    style={{
                                        flexBasis: "48%",
                                    }}
                                    className={`${Style.name_wrapper}`}
                                >
                                    <FormLabel fontSize={"14px"}>نام کامل</FormLabel>
                                    <Input
                                        isReadOnly={userFullName !== "" && userFullName !== null}
                                        variant="filled"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange("name")}
                                        id="name"
                                    />
                                </FormControl>
                                <FormControl
                                    style={{
                                        flexBasis: "48%",
                                    }}
                                    dir="ltr"
                                    className={`${Style.email_wrapper}`}
                                >
                                    <FormLabel fontSize={"14px"} dir="rtl">
                                        پست الکترونیک
                                    </FormLabel>
                                    <Input
                                        isReadOnly={userEmail !== "" && userEmail !== null}
                                        variant="filled"
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange("email")}
                                        id="email"
                                    />
                                </FormControl>
                            </Box>
                            <FormControl>
                                <FormLabel fontSize="14px">عنوان</FormLabel>
                                <Input
                                    variant="filled"
                                    type="text"
                                    value={values.subject}
                                    onChange={handleChange("subject")}
                                    id="subject"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontSize="14px">توضیحات</FormLabel>
                                <Textarea
                                    variant="filled"
                                    value={values.description}
                                    onChange={handleChange("description")}
                                    id="description"
                                    resize="none"
                                    maxLength={2000}
                                    h={[48, 64]}
                                />
                                <FormHelperText>
                                    <Text
                                        color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                                    >{`${values.description.length}/2000`}</Text>
                                </FormHelperText>
                            </FormControl>
                            <Button
                                type="submit"
                                isDisabled={
                                    values.description.length * values.email.length * values.name.length * values.subject.length === 0
                                }
                                isLoading={isLoading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                mr={1}
                                my={2}
                                px={10}
                                placeSelf={"flex-end"}
                                onClick={onCreateContact}
                            >
                                ارسال
                            </Button>
                        </VStack>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};
