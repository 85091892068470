import React, { useState, useEffect } from "react";
import { LEADERBOARD_ITEMS_PER_PAGE } from "@util/constants";
import classes from "./Pagination.module.css";
import { Button, IconButton } from "@chakra-ui/react";
import { ChevronsLeft, ChevronsRight } from "react-feather";

export const Pagination = (props: { totalRecords: number; pageNeighbours: number; currentPage: number; onPageChanged: any }) => {
    const [currentPage, setCurrentPage] = useState(props.currentPage);
    const [pages, setPages] = useState([]);

    const totalPages = Math.ceil(props.totalRecords / LEADERBOARD_ITEMS_PER_PAGE);

    const LEFT_PAGE = "LEFT";
    const RIGHT_PAGE = "RIGHT";

    /**
     * Helper method for creating a range of numbers
     * range(1, 5) => [1, 2, 3, 4, 5]
     */
    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    };

    useEffect(() => {
        const totalNumbers = props.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - props.pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + props.pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case !hasLeftSpill && hasRightSpill: {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            setPages([1, ...pages, totalPages]);
        } else {
            setPages(range(1, totalPages));
        }
    }, [props, currentPage]);

    const gotoPage = (page) => {
        props.onPageChanged(page);
        setCurrentPage(page);
    };

    const handleClick = (page) => (evt) => {
        evt.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = (evt) => {
        evt.preventDefault();
        gotoPage(currentPage - props.pageNeighbours * 2 - 1);
    };

    const handleMoveRight = (evt) => {
        evt.preventDefault();
        gotoPage(currentPage + props.pageNeighbours * 2 + 1);
    };

    return (
        <nav aria-label="Leaderboard pagination" className="flex justify-center mt-8">
            <ul className={`${classes.pagination} flex`}>
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE)
                        return (
                            <li key={index} className={`${classes.page_item}`}>
                                <IconButton
                                    variant="ghost"
                                    className={`${classes.page_link}`}
                                    aria-label="Previous"
                                    onClick={handleMoveLeft}
                                    icon={<ChevronsRight size={18} />}
                                />
                            </li>
                        );

                    if (page === RIGHT_PAGE)
                        return (
                            <li key={index} className={`${classes.page_item}`}>
                                <IconButton
                                    variant="ghost"
                                    className={`${classes.page_link}`}
                                    aria-label="Next"
                                    onClick={handleMoveRight}
                                    icon={<ChevronsLeft size={18} />}
                                />
                            </li>
                        );

                    return (
                        <li key={index} className={`${classes.page_item}  ${currentPage === page ? classes.active : ""}`}>
                            <Button
                                variant="ghost"
                                isActive={currentPage === page}
                                className={`${classes.page_link}`}
                                onClick={handleClick(page)}
                            >
                                {page}
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};
