import { Box, Button, Icon, Stack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Clock, Icon as IconProp, LogIn, XOctagon } from "react-feather";

export const CLogin = (props: { text?: string }) => {
    const router = useRouter();
    return (
        <Box className="h-full w-1/2 flex flex-col justify-center items-center my-20 mx-auto">
            <Icon as={LogIn} w={24} h={24} my={8} />
            <Text fontSize="xl" textAlign="center">
                {props.text ? props.text : "برای دسترسی به محتویات این مسابقه باید وارد حساب کاربری خود در سامانه روبواپیکس شوید."}
            </Text>
            <Stack direction={["column", "row"]} spacing={6} justifyContent="space-between">
                <Button
                    colorScheme={"RoboEpics.turquoise"}
                    onClick={() => {
                        router.push(`/users/signin?returnUrl=${router.asPath}`);
                    }}
                >
                    ورود به حساب کاربری
                </Button>
                <Button
                    colorScheme={"RoboEpics.gold"}
                    onClick={() => {
                        router.push("/");
                    }}
                >
                    بازگشت به RoboEpics
                </Button>
            </Stack>
        </Box>
    );
};

export const CRelogin = () => {
    const router = useRouter();
    const [stay, setStay] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [timer, setTimer] = useState(9);
    useEffect(() => {
        let time = null;
        time = setInterval(() => {
            setTimer((current) => (current <= 0 ? 0 : current - 1));
        }, 1000);
        if (timer === 0 && !stay) {
            setDisabled(true);
            router.push(`/users/signin?returnUrl=${router.asPath}`);
        }
        return () => {
            if (!time) clearInterval(time);
        };
    }, []);

    return (
        <Box className="h-full w-3/4 flex flex-col justify-center items-center my-20 mx-auto">
            <Icon as={Clock} w={24} h={24} my={8} />
            <Text fontSize="xl" textAlign="center">
                زمان دسترسی شما به سامانه روبواپیکس به پایان رسیده است.
                <br />
                برای دسترسی به محتویات این مسابقه باید دوباره وارد حساب کاربری خود در سامانه روبواپیکس شوید.
                <br />
            </Text>
            {!stay && timer !== 0 && (
                <Text fontSize="xl" textAlign="center">
                    در حال انتقال دادن شما به صفحه ورود به حساب کاربری در {timer} ثانیه
                </Text>
            )}
            <Stack direction={["column", "row"]} spacing={6} justifyContent="space-between" mb={6}>
                {stay ? (
                    <Button
                        colorScheme={"RoboEpics.turquoise"}
                        onClick={() => {
                            router.push(`/users/signin?returnUrl=${router.asPath}`);
                        }}
                        isDisabled={disabled}
                    >
                        ورود به حساب کاربری
                    </Button>
                ) : (
                    <Button
                        colorScheme={"RoboEpics.turquoise"}
                        onClick={() => {
                            setStay(true);
                        }}
                        isDisabled={disabled}
                    >
                        ماندن در این صفحه
                    </Button>
                )}
                <Button
                    colorScheme={"RoboEpics.gold"}
                    onClick={() => {
                        router.push("/");
                    }}
                    isDisabled={disabled}
                >
                    بازگشت به RoboEpics
                </Button>
            </Stack>
            <Button
                colorScheme={"RoboEpics.azure"}
                variant="link"
                onClick={() => {
                    router.push("/contact");
                }}
                isDisabled={disabled}
            >
                تماس با پشتیبانی
            </Button>
        </Box>
    );
};
export const CUnauthorized = () => {
    const router = useRouter();
    return (
        <Box className="h-full w-1/2 flex flex-col justify-center items-center my-20 mx-auto">
            <Icon as={XOctagon} w={24} h={24} my={8} />
            <Text fontSize="xl" textAlign="center">
                شما اجازه دسترسی به محتویات این مسابقه را ندارید.
                <br />
                برای اینکه بتوانید از محتویات این مسابقه استفاده کنید با برگزارکننده مسابقه تماس بگیرید.
            </Text>
            <Stack direction={["column", "row"]} spacing={6} justifyContent="space-between">
                <Button
                    colorScheme={"RoboEpics.turquoise"}
                    onClick={() => {
                        router.push("/contact");
                    }}
                >
                    تماس با پشتیبانی{" "}
                </Button>
                <Button
                    colorScheme={"RoboEpics.gold"}
                    onClick={() => {
                        router.push("/");
                    }}
                >
                    بازگشت به RoboEpics
                </Button>
            </Stack>
        </Box>
    );
};
export const CErrorDisplayer = (props: { text?: JSX.Element; icon?: IconProp; hasButton: boolean }) => {
    const router = useRouter();
    return (
        <Box className="h-full w-1/2 flex flex-col justify-center items-center my-20 mx-auto">
            <Icon as={props.icon ? props.icon : XOctagon} w={24} h={24} my={8} />
            {props.text ? (
                props.text
            ) : (
                <Text fontSize="xl" textAlign="center">
                    شما اجازه دسترسی به این صفحه را ندارید.
                    <br />
                    اگر برگزارکننده این مسابقه هستید و با این پیغام مواجه شده اید با تیم پشتیبانی روبواپیکس تماس بگیرید
                </Text>
            )}
            {props.hasButton && (
                <Stack direction={["column", "row"]} spacing={6} justifyContent="space-between">
                    <Button
                        colorScheme={"RoboEpics.turquoise"}
                        onClick={() => {
                            router.push("/contact");
                        }}
                    >
                        تماس با پشتیبانی
                    </Button>
                    <Button
                        colorScheme={"RoboEpics.gold"}
                        onClick={() => {
                            router.push("/");
                        }}
                    >
                        بازگشت به RoboEpics
                    </Button>
                </Stack>
            )}
        </Box>
    );
};
export const CNotFound = () => {
    return (
        <Box className="h-full w-1/2 flex flex-col justify-center items-center my-20 mx-auto">
            <Text fontFamily={"mono"} fontSize={"2xl"} fontWeight={"bold"} dir="ltr">
                404 | Not Found.
            </Text>
            <Text fontSize={"xl"}>صفحه‌ای که درخواست کرده‌اید وجود ندارد.</Text>
        </Box>
    );
};
