import React, { useState } from "react";
import { problemEditJson } from "@util/api/problems";
import { Input, IconButton, Text, Button } from "@chakra-ui/react";
import { Check, Edit2, Plus } from "react-feather";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const EditSubtitle = (props: { subtitle: string; toast: IToastOptions; router: NextRouter }) => {
    const [subtitle, setSubtitle] = useState(props.subtitle);
    const [editMode, setEditMode] = useState(false);

    const editSubtitle = async () => {
        const reqData = { short_description: subtitle };
        const serverResponse = (await problemEditJson(reqData, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            setEditMode(false);
        } else {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: "مشکلی در اعمال تغییرات شما به وجود آمده. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید!",
            });
            setSubtitle(props.subtitle);
        }
    };
    return (
        <div className={`flex flex-row items-center`}>
            {editMode ? (
                <Input
                    isTruncated
                    id={`subtitle-input`}
                    variant={"flushed"}
                    defaultValue={subtitle}
                    onChange={(e) => {
                        setSubtitle(e.target.value);
                    }}
                    mb={"0.5em"}
                />
            ) : (
                <Text fontSize="sm">{subtitle}</Text>
            )}
            {editMode ? (
                <IconButton
                    mb={"0.5em"}
                    mr={2}
                    aria-label="edit-subtitle"
                    icon={<Check size="18" />}
                    onClick={() => {
                        if (subtitle !== "") {
                            if (props.subtitle !== subtitle) {
                                editSubtitle();
                            }
                        } else {
                            setSubtitle(props.subtitle);
                        }
                        setEditMode(false);
                    }}
                    size={"sm"}
                    variant="ghost"
                />
            ) : subtitle ? (
                <IconButton
                    mb={"1em"}
                    mr={2}
                    onClick={() => {
                        setEditMode(true);
                    }}
                    aria-label="edit-subtitle"
                    icon={<Edit2 size="16" />}
                    size={"sm"}
                    variant="ghost"
                />
            ) : (
                <Button
                    mb={"1em"}
                    onClick={() => {
                        setEditMode(true);
                    }}
                    aria-label="edit-subtitle"
                    leftIcon={<Plus size={"16"} />}
                    size={"sm"}
                    variant="ghost"
                >
                    اضافه کردن زیرعنوان
                </Button>
            )}
        </div>
    );
};
