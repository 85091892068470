import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Code,
    Collapse,
    Heading,
    Progress,
    Text,
    useColorMode,
    useColorModeValue,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react";
import { uploadResult, choooseProblemSubmission } from "@util/api/problem_enter_id";
import { IToastOptions } from "@util/interfaces";
import axios from "axios";
import { NextRouter } from "next/router";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileText, Paperclip, Upload as UploadIcon } from "react-feather";

import Style from "./resultUpload.module.css";

export const ResultUpload = (props: {
    problemEnterId: number;
    setLevel: React.Dispatch<React.SetStateAction<number>>;
    maxOutputSize: number;
    submissionFileName: string | null;
    repositoryMode: 10 | 20 | 30;
    toast: IToastOptions;
    router: NextRouter;
    resetTabIndex: () => void;
}) => {
    const [uploadFile, setUploadFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const { colorMode } = useColorMode();

    // Thank you Stackoverflow
    const humanFileSize = (bytes, si = false, dp = 1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }

        const units = si
            ? ["کیلوبایت", "مگابایت", "گیگابایت", "ترابایت", "پتابایت", "اگزابایت", "زتابایت", "یوتابایت"]
            : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + " " + units[u];
    };

    const errorParser = (data: Array<string>) => {
        let message = "مشکلی در آپلود کردن فایل شما به وجود آمد. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.";

        if (data[0].includes(`upload to it in a problem which has a repository mode of "Off"!`)) {
            message = "این چالش هیچگونه مخزنی برای آپلود ندارد و شما نمی‌توانید برای آن کدی آپلود کنید";
        } else if (data[0].includes(`to be a member of this team to upload submission`)) {
            message = "برای آپلود کردن سابمیشن خود باید عضو این تیم باشید.";
        } else if (data[0].includes(`which is being built! Please wait until it finishes`)) {
            message = "شما سابمیشنی در صف بیلد شدن دارید. برای آپلود کردن کد جدید تا اتمام سابمیشن قبلی خود باید صبر کنید";
        } else if (data[0].includes(`You cannot submit to this problem right now!`)) {
            message = "در حال حاضر نمی‌توانید برای این چالش پاسخی ثبت کنید.";
        } else if (data[0].includes(`limit of daily submissions for this competition has reached!`)) {
            message = "شما محدودیت ثبت پاسخ روزانه را پر کرده‌اید. برای آپلود کردن جواب خود فردا دوباره تلاش کنید.";
        } else if (data[0].includes(`You cannot commit the same code again!`)) {
            message = "شما نمی‌توانید کدی که قبلاً آپلود کرده‌اید را دوباره به عنوان پاسخ ثبت کنید.";
        } else if (data[0].includes(`when uploading your submission! We are informed and will fix it soon`)) {
            message = "در پروسه آپلود کد شما مشکلی پیش آمده. ما از آن مطلع و در حال رفع مشکل هستیم.";
        } else {
            message = `Unhandled exception: ${JSON.stringify(data)}`;
        }

        props.toast({
            description: message,
            isClosable: true,
            duration: 7000,
            variant: "solid",
            status: "error",
        });
    };

    const onDrop = useCallback((acceptedFile) => {
        const file: File = acceptedFile?.[0];
        if (!file) {
            return;
        }

        if (file.size > props.maxOutputSize * 1024 * 1024) {
            props.toast({
                description: `حداکثر حجم قابل قبول برای فایل، ${humanFileSize(props.maxOutputSize * 1024 * 1024)} می‌باشد.`,
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "solid",
            });
            return;
        }

        if (props.submissionFileName && file.name !== props.submissionFileName) {
            props.toast({
                description: `فایل خروجی شما باید با نام ${props.submissionFileName} بارگذاری شود.`,
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "solid",
            });
            return;
        }
        try {
            setUploadFile(file);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const { isDragActive, getInputProps, getRootProps } = useDropzone({
        onDrop,
    });
    const onUploadResult = async () => {
        setIsLoading(true);
        setIsUploading(true);

        const serverResponse = (await uploadResult(uploadFile.name, props.problemEnterId, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (!serverResponse) {
            setIsLoading(false);
        } else if (serverResponse.ok) {
            const url = (await serverResponse.json()).url;
            try {
                const response = await axios.put(url, uploadFile, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    },
                });
                if (response.status === 200) {
                    if (props.repositoryMode === 10) await onCreateSubmission();
                    else {
                        setAlertProps({
                            open: true,
                            message: "فایل خروجی شما با موفقیت آپلود شد! لطفاً کد متناظر با این فایل خروجی را انتخاب یا آپلود کنید.",
                            status: "success",
                        });
                        props.setLevel(1);
                        setIsLoading(false);
                    }
                } else {
                    setAlertProps({
                        open: true,
                        message: "مشکلی در اپلود کردن فایل خروجی شما به وجود آمده. لطفاً دوباره تلاش کنید.",
                        status: "error",
                    });
                    setIsLoading(false);
                }
            } catch (err) {
                setAlertProps({
                    open: true,
                    message: "مشکلی در اپلود کردن فایل خروجی شما به وجود آمده. لطفاً دوباره تلاش کنید.",
                    status: "error",
                });
                setIsLoading(false);
            }
        } else {
            setAlertProps({
                open: true,
                message: "مشکلی در اپلود کردن فایل خروجی شما به وجود آمده. لطفاً دوباره تلاش کنید.",
                status: "error",
            });
            setIsLoading(false);
        }

        setIsUploading(false);
        setProgress(0);
    };
    const onCreateSubmission = async () => {
        const serverResponse = (await choooseProblemSubmission(props.problemEnterId, "", {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse) {
            if (serverResponse.ok) {
                props.toast({
                    description: `فایل خروجی شما با موفقیت ارسال و ثبت شد.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    variant: "solid",
                });
                props.resetTabIndex();
            } else {
                if (serverResponse.status === 400) {
                    const data = await serverResponse.json();

                    // if (data.non_field_errors && data.non_field_errors[0].includes("make a unique set")) {
                    if (data && data.length) {
                        errorParser(data);
                    }
                }
            }
        }
        setIsLoading(false);
        setIsUploading(false);
        setProgress(0);
    };
    const [isGreaterThan769px] = useMediaQuery("(min-width: 769px)");
    return (
        <div
            className={`pt-12 mt-12 pb-20 px-10 rounded-md flex flex-col w-2/5 mx-auto place-items-center items-center ${Style.result_form_wrapper}`}
        >
            <Box alignSelf="start">
                <Box mb=".75rem">
                    <Text m="0" fontSize="18px" fontWeight="600" color={useColorModeValue("gray.700", "#59697c")} className="text-xl">
                        مرحله اول
                    </Text>
                </Box>
                <Box className="flex items-center">
                    <FileText size={28} />
                    <Heading mb="0" size="lg" mr="2">
                        ارسال فایل خروجی
                    </Heading>
                </Box>
            </Box>
            <Box my="1rem">
                <Text m="0">
                    فایل خروجی موردنظرتان را آپلود کنید. این فایل معیار ارزیابی و سنجش عملکرد شما خواهد بود.
                    <br />
                    برای اطلاع از نحوه آماده‌سازی فایل، ساختار مورد نیاز و محدودیت‌های موجود به قسمت توضیحات سوال مراجعه کنید.
                </Text>
            </Box>
            <Collapse
                in={alertProps.open}
                style={{
                    width: "100%",
                    marginBottom: "16px",
                }}
            >
                <Alert status={alertProps.status} variant={colorMode === "dark" ? "subtle" : "solid"}>
                    <AlertIcon ml={2} />
                    <AlertDescription>{alertProps.message}</AlertDescription>
                </Alert>
            </Collapse>
            <VStack
                bg={useColorModeValue("#1b1b1b20", "RoboEpics.dark.600")}
                h={"13rem"}
                px={50}
                py={5}
                mb={4}
                borderRadius={5}
                borderStyle={"dashed"}
                borderColor={useColorModeValue("RoboEpics.azure.900", "RoboEpics.gold.200")}
                borderWidth={2}
                textAlign="center"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <UploadIcon size={"2rem"} />
                {isGreaterThan769px ? (
                    isDragActive ? (
                        <Text fontSize="md">رها کنید...</Text>
                    ) : (
                        <Text fontSize="sm">‌فایل مد نظر را به این ‌جا بکشید یا مستقیماً آپلود کنید.</Text>
                    )
                ) : (
                    <Text fontSize="sm">فایل مد نظر خود را در این قسمت آپلود کنید.</Text>
                )}
                {isGreaterThan769px && (
                    <Button variant="ghost" m={2} p={2} colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}>
                        انتخاب فایل
                    </Button>
                )}
            </VStack>
            <VStack className="flex flex-col justify-between items-center w-full" spacing={4}>
                {props.maxOutputSize || props.submissionFileName ? (
                    <Alert status="info" variant={"solid"} borderRadius={"md"} flexDir="column" alignItems="flex-start">
                        <AlertTitle w="max-content" borderBottom={"1px solid"} mb={2}>
                            لازمه‌های فایل ارسالی
                        </AlertTitle>
                        <AlertDescription>
                            <VStack spacing={1} alignItems={"flex-start"}>
                                {props.maxOutputSize && (
                                    <Text mb={0}>
                                        حداکثر حجم فایل خروجی:
                                        <Code colorScheme={"RoboEpics.dark"} mx={1} variant="solid">
                                            {humanFileSize(props.maxOutputSize * 1024 * 1024)}
                                        </Code>
                                    </Text>
                                )}
                                {props.submissionFileName && (
                                    <Text mb={0}>
                                        نام فایل خروجی:
                                        <Code colorScheme={"RoboEpics.dark"} mx={1} variant="solid">
                                            {props.submissionFileName}
                                        </Code>
                                    </Text>
                                )}
                            </VStack>
                        </AlertDescription>
                    </Alert>
                ) : (
                    <></>
                )}
                {uploadFile && (
                    <span className="w-full flex flex-row items-center px-2">
                        <Paperclip size="14px" />
                        <Text fontSize={"md"} mb={0} mr={2} p={0} isTruncated>
                            {uploadFile.name}
                        </Text>
                    </span>
                )}
                {isUploading && (
                    <Progress
                        w={"100%"}
                        borderRadius={5}
                        my={2}
                        size="xs"
                        value={progress}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                    />
                )}
                <Button
                    className={`${Style.send_btn}`}
                    isDisabled={!uploadFile}
                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                    mt={3}
                    px={6}
                    isLoading={isLoading}
                    onClick={onUploadResult}
                >
                    بررسی
                </Button>
            </VStack>
        </div>
    );
};
