import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Code,
    Collapse,
    Fade,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Spinner,
    Text,
    Tooltip,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react";
import { Code as CodeIcon, Paperclip, Upload as UploadIcon } from "react-feather";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { recentCommits, choooseProblemSubmission } from "@util/api/problem_enter_id";
import { Constants } from "@util";

import axios from "axios";
import Style from "./upload.module.css";
import { NextRouter } from "next/router";
import { IPhaseData, IToastOptions } from "@util/interfaces";
import { retrieveCompetitionPhase } from "@util/api/competitions";
import { TimerOutline } from "react-ionicons";
import { OnChangeValue, Select } from "chakra-react-select";
import { ALLOWED_RUNTIMES } from "@util/constants";
import { convertDate } from "@util/dateConverter";
import { refreshAccessToken } from "@util/RoboEpicsAuthHandler";
import { session_ended_toast_message } from "@util/strings";

export const Upload = (props: {
    problemEnterId: number;
    setLevel: React.Dispatch<React.SetStateAction<number>>;
    reloadComponent: React.Dispatch<React.SetStateAction<boolean>>;
    maxOutputSize: number | null;
    toast: IToastOptions;
    router: NextRouter;
    allowedRuntimes: Array<string>;
}) => {
    const [recentUserCommits, setRecentUserCommits] = useState([]);
    const [uploadFile, setUploadFile] = useState<File>(null);
    const [chosen, setChosen] = useState(null);
    const [commitMessage, setCommitMessage] = useState("");
    const [chosenRuntime, setChosenRuntime] = useState<string>(undefined);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { colorMode } = useColorMode();

    const [isLoadingChooseSub, setIsLoadingChooseSub] = useState(false);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isLoadingUploadCode, setIsLoadingUploadCode] = useState(false);

    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const [loadingComponent, setLoadingComponent] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [phaseData, setPhaseData] = useState<IPhaseData>(null);
    const [deadlinePast, setDeadlinePast] = useState(false);
    const [showFileFormatWarning, setShowFileFormatWarning] = useState(false);

    const errorParser = (data: Array<string>) => {
        let message = "مشکلی در آپلود کردن فایل شما به وجود آمد. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.";

        if (data[0].includes(`upload to it in a problem which has a repository mode of "Off"!`)) {
            message = "این چالش هیچگونه مخزنی برای آپلود ندارد و شما نمی‌توانید برای آن کدی آپلود کنید";
        } else if (data[0].includes(`to be a member of this team to upload submission`)) {
            message = "برای آپلود کردن سابمیشن خود باید عضو این تیم باشید.";
        } else if (data[0].includes(`which is being built! Please wait until it finishes`)) {
            message = "شما سابمیشنی در صف بیلد شدن دارید. برای آپلود کردن کد جدید تا اتمام سابمیشن قبلی خود باید صبر کنید";
        } else if (data[0].includes(`You cannot submit to this problem right now!`)) {
            message = "در حال حاضر نمی‌توانید برای این چالش پاسخی ثبت کنید.";
        } else if (data[0].includes(`limit of daily submissions for this competition has reached!`)) {
            message = "شما محدودیت ثبت پاسخ روزانه را پر کرده‌اید. برای آپلود کردن جواب خود فردا دوباره تلاش کنید.";
        } else if (data[0].includes(`You cannot submit with the same commit reference you have submitted before!`)) {
            message = "شما نمی‌توانید کدی که قبلاً آپلود کرده‌اید را دوباره به عنوان پاسخ ثبت کنید.";
        } else if (data[0].includes(`when uploading your submission! We are informed and will fix it soon`)) {
            message = "در پروسه آپلود کد شما مشکلی پیش آمده. ما از آن مطلع و در حال رفع مشکل هستیم.";
        }

        props.toast({
            description: message,
            isClosable: true,
            duration: 7000,
            variant: "solid",
            status: "error",
        });
    };

    // const checkMimeType = (file) => {
    //     const types = ["application/zip", "application/x-zip", "application/octet-stream", "application/x-zip-compressed"];
    //     return types.includes(file.type);
    // };

    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile?.[0];
        if (!file) {
            setAlertProps({
                open: true,
                message: "انتخاب کردن فایل الزامی است.",
                status: "error",
            });
            setUploadFile(null);
            return;
        }
        // if (!checkMimeType(file)) {
        //     setAlertProps({
        //         open: true,
        //         message: "فرمت فایل انتخاب شده نادرست است.",
        //         status: "error",
        //     });
        //     setUploadFile(null);
        //     return;
        // }

        setIsLoadingFile(true);

        try {
            setUploadFile(file);
        } catch (e) {
            console.log(e);
        }

        setIsLoadingFile(false);
    }, []);

    const setCommits = async () => {
        const serverResponse = (await recentCommits(props.problemEnterId, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            data.forEach((commit: { created_at: string }) => {
                commit.created_at = convertDate(commit.created_at, "D MMMM YYYY HH:mm:ss");
            });
            setRecentUserCommits(data);
        } else {
            setErrorLoading(true);
        }
        setLoadingComponent(false);
    };

    const uploadCode = async () => {
        setIsLoadingUploadCode(true);
        const { accessToken } = await refreshAccessToken({ toast: props.toast });

        const formData = new FormData();
        formData.append("message", commitMessage);
        formData.append("file", uploadFile);
        // formData.append("clean_repo", "1");
        if (chosenRuntime) formData.append("runtime", chosenRuntime);
        const serverResponse = await axios
            .post(`${Constants.ROBOEPICS_API}/problem/enter/${props.problemEnterId}/upload`, formData, {
                onUploadProgress: (ProgressEvent) => {
                    setUploadProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => res)
            .catch((err) => err.response);

        switch (serverResponse.status) {
            case 201:
                setAlertProps({
                    open: true,
                    message: "کد شما با موفقیت آپلود شد!",
                    status: "success",
                });
                onClose();
                setUploadFile(null);
                setCommitMessage("");
                props.router.reload();
                break;
            case 400:
                errorParser(serverResponse.data);
                break;
            case 401:
                props.toast({
                    description: session_ended_toast_message,
                    status: "error",
                    variant: "subtle",
                    duration: 5000,
                    isClosable: true,
                });
                props.router.push(`/users/signin?returnUrl=${props.router.asPath}`);
                break;
            default:
                props.toast({
                    description: "مشکلی در آپلود کردن فایل شما به وجود آمد. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",

                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    variant: "subtle",
                });
                break;
        }
        if (serverResponse.status !== 201) {
            onClose();
            setUploadFile(null);
            setCommitMessage("");
        }
        setIsLoadingUploadCode(false);
    };
    const chooseSubmission = async () => {
        setIsLoadingChooseSub(true);
        const serverResponse = (await choooseProblemSubmission(props.problemEnterId, chosen.reference, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                description: `کد شما با موفقیت ارسال و ثبت شد.`,
                status: "success",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
            setChosen(null);
            props.reloadComponent(true);
        } else {
            if (serverResponse.status === 400) {
                const { data } = await serverResponse.json();
                if (data.non_field_errors && data.non_field_errors[0].includes("make a unique set")) {
                    props.toast({
                        description: `هر کامیت فقط یک بار قابل ثبت و ارسال است. برای ارسال جدید، کامیت جدیدی ایجاد کنید.`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        variant: "subtle",
                    });
                } else {
                    props.toast({
                        description: JSON.stringify(data),
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        variant: "subtle",
                    });
                }
            } else {
                props.toast({
                    title: "مشکلی در حین ثبت کامیت رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }
        setIsLoadingChooseSub(false);
    };

    const onSetPhaseData = async () => {
        const serverResponse = (await retrieveCompetitionPhase(props.router.query.phase_id, props.router.query.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setPhaseData(data);
        } else {
            setPhaseData(null);
            setErrorLoading(true);
        }
    };

    useEffect(() => {
        const fileSplitted = uploadFile?.name.split(".");
        const format = fileSplitted ? fileSplitted[fileSplitted.length - 1] : "";
        if (chosenRuntime && uploadFile && format !== "zip") {
            setShowFileFormatWarning(!ALLOWED_RUNTIMES[chosenRuntime].fileFormats.includes(format));
        } else {
            setShowFileFormatWarning(false);
        }
    }, [uploadFile, chosenRuntime]);

    useEffect(() => {
        if (props.problemEnterId) {
            setCommits();
        }
    }, [props.problemEnterId]);

    useEffect(() => {
        onSetPhaseData();
    }, []);

    useEffect(() => {
        if (phaseData) {
            const now = Date.parse(new Date().toUTCString());
            const deadline = Date.parse(phaseData.submission_date_end);
            setDeadlinePast(now > deadline);
        }
    }, [phaseData]);

    useEffect(() => {
        setUploadProgress(0);
    }, [uploadFile]);

    const { isDragActive, getInputProps, getRootProps } = useDropzone({
        onDrop,
    });
    const [isGreaterThan769px] = useMediaQuery("(min-width:769px)");

    if (loadingComponent) {
        return (
            <Box className="flex flex-row justify-center my-32">
                <Spinner />
            </Box>
        );
    }

    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    if (deadlinePast) {
        return (
            <Box className="h-full w-1/2 flex flex-col justify-center items-center my-20 mx-auto">
                <TimerOutline width={"4rem"} height={"4rem"} color={useColorModeValue("black", "white")} />
                <Text fontSize="xl" textAlign="center" my={8}>
                    زمان ثبت پاسخ برای این چالش به پایان رسیده است. <br />
                </Text>
            </Box>
        );
    }

    return (
        <div className={`${Style.upload_form_container} pt-10 pb-8 px-10 w-full items-center mx-auto justify-center`}>
            <div
                className={`pt-3 mt-6 pb-20 px-10 rounded-md flex flex-col w-2/5 mx-auto place-items-center items-center ${Style.upload_form_wrapper}`}
            >
                <Box alignSelf="start">
                    {props.maxOutputSize && (
                        <Box mb=".75rem">
                            <Text
                                m="0"
                                fontSize="18px"
                                fontWeight="600"
                                color={useColorModeValue("gray.700", "#59697c")}
                                className="text-xl"
                            >
                                مرحله دوم
                            </Text>
                        </Box>
                    )}
                    <Box className="flex items-center">
                        <CodeIcon size={28} />
                        <Heading mb="0" size="lg" mr="2">
                            فراخوانی کد
                        </Heading>
                    </Box>
                </Box>
                <Box my="1rem">
                    <Text m="0">
                        در این مرحله باید کد خود را فراخوانی کنید. کدها در قالب کامیت‌های گیت‌لب در سرورها پردازش می‌شوند. لذا اگر قبلاً کد
                        خود را کامیت کرده‌اید، کامیت موردنظر را انتخاب کنید و در غیر این صورت، طی ایجاد یک کامیت جدید، کد خود را آپلود کنید.
                    </Text>
                </Box>
                <Collapse in={alertProps.open}>
                    <Alert status={alertProps.status} variant={colorMode === "dark" ? "subtle" : "solid"} my={2}>
                        <AlertIcon ml={2} />
                        <AlertDescription>{alertProps.message}</AlertDescription>
                    </Alert>
                </Collapse>
                <div className={` flex flex-row  w-full justify-center items-center`}>
                    <Button
                        className={`${Style.new_commit_btn}`}
                        onClick={onOpen}
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        m={1}
                        px={6}
                    >
                        ایجاد کامیت جدید
                    </Button>
                </div>
                <h3
                    className={`w-2/3 text-center ${Style.divider}`}
                    style={{
                        borderBottom: "1px solid gray",
                        lineHeight: "0.1rem",
                        margin: "1.5rem 0 0.5rem",
                    }}
                >
                    <span
                        className="px-4"
                        style={{
                            backgroundColor: useColorModeValue("#F7f7f8", "#14181d"),
                        }}
                    >
                        یا
                    </span>
                </h3>
                <div className={`w-full flex flex-col justify-center items-center my-4`}>
                    <Menu isLazy placement="bottom">
                        <MenuButton
                            className={`${Style.recent_commit_btn}`}
                            isDisabled={recentUserCommits.length === 0}
                            as={Button}
                            rightIcon={recentUserCommits.length === 0 ? <></> : <ChevronDownIcon />}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            m={1}
                            px={6}
                        >
                            {recentUserCommits.length === 0 ? "کامیتی برای انتخاب وجود ندارد" : "انتخاب از کامیت‌های اخیر"}
                        </MenuButton>
                        <MenuList bg={useColorModeValue("#fff", "RoboEpics.black.900")} mx={"auto"} className={`${Style.menu_list}`}>
                            <MenuOptionGroup
                                type="radio"
                                title={
                                    "متن و تاریخ ایجاد کامیت"
                                    // <div className="flex flex-row justify-between w-full">
                                    //     <span className="pl-4">متن کامیت</span>
                                    //     <span className="pr-4">
                                    //         تاریخ ایجاد کامیت
                                    //     </span>
                                    // </div>
                                }
                            >
                                <MenuDivider />
                                {recentUserCommits.map((commit, index) => {
                                    return (
                                        <div key={index}>
                                            <MenuItemOption
                                                icon={null}
                                                iconSpacing={0}
                                                minH="2.5rem"
                                                value={`${index}`}
                                                textAlign={"left"}
                                                overflowX="auto"
                                                overflowY="hidden"
                                                _hover={{
                                                    backgroundColor: useColorModeValue("RoboEpics.azure.200", "RoboEpics.gold.200"),
                                                    color: "#000",
                                                }}
                                                onClick={(e) => {
                                                    setChosen(recentUserCommits[e.currentTarget.value]);
                                                }}
                                            >
                                                {/* <div className="flex flex-row justify-between w-full"> */}
                                                {/* <span className="pl-4 truncate"> */}
                                                {`${commit.message} \n (${commit.created_at})`}
                                                {/* </span> */}
                                                {/* <span className="pr-4 truncate">
                                                        {commit.created_at}
                                                    </span> */}
                                                {/* </div> */}
                                            </MenuItemOption>
                                            {index !== recentUserCommits.length - 1 && <MenuDivider />}
                                        </div>
                                    );
                                })}
                            </MenuOptionGroup>
                        </MenuList>
                    </Menu>
                    {props.allowedRuntimes.length > 0 && (
                        <FormControl isRequired={props.allowedRuntimes.length > 0} mt={4}>
                            <FormLabel fontSize="14px">زبان برنامه‌نویسی</FormLabel>
                            <Select
                                options={props.allowedRuntimes.map((runtime) => {
                                    return ALLOWED_RUNTIMES[runtime] ?? { label: runtime, value: runtime };
                                })}
                                value={{
                                    label: ALLOWED_RUNTIMES[chosenRuntime] ? ALLOWED_RUNTIMES[chosenRuntime].label : chosenRuntime,
                                    value: ALLOWED_RUNTIMES[chosenRuntime] ? ALLOWED_RUNTIMES[chosenRuntime].value : chosenRuntime,
                                }}
                                onChange={(newValue: OnChangeValue<{ value: string }, false>) => setChosenRuntime(newValue.value)}
                            />
                        </FormControl>
                    )}
                </div>
            </div>
            {chosen && (
                <div className="flex flex-col  w-full justify-center items-center my-4">
                    <Heading size="md">کامیت انتخاب شده</Heading>
                    <Box
                        // borderColor={useColorModeValue(
                        //     "RoboEpics.azure.500",
                        //     "RoboEpics.green.500",
                        // )}
                        as="div"
                        className={`flex flex-row w-8/12 justify-between items-center my-2 p-4 border-2 rounded-md border-solid ${Style.chosen_commit}`}
                    >
                        <div className="flex flex-col justify-between items-center w-full">
                            <Tooltip hasArrow aria-label="commit-message-tooltip" label="متن کامیت" placement="right">
                                <Text fontSize="sm" textAlign="center">
                                    {chosen.message}
                                </Text>
                            </Tooltip>
                            <Tooltip hasArrow aria-label="commit-created-tooltip" label="تاریخ ایجاد کامیت" placement="right">
                                <Text fontSize="sm" m={0} textAlign="center">
                                    {chosen.created_at}
                                </Text>
                            </Tooltip>
                        </div>
                        <Button
                            onClick={chooseSubmission}
                            sx={{ width: "20%" }}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            m={1}
                            px={6}
                            isLoading={isLoadingChooseSub}
                            isDisabled={props.allowedRuntimes.length > 0 && !chosenRuntime}
                            size="sm"
                        >
                            ارسال
                        </Button>
                    </Box>
                </div>
            )}
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    setAlertProps({ open: false, message: "این یک آلرت است", status: "info" });
                    setUploadFile(null);
                    setIsLoadingUploadCode(false);
                    setCommitMessage("");
                }}
            >
                <ModalOverlay />
                <ModalContent className={`${Style.modal_content}`} bg={useColorModeValue("#fff", "RoboEpics.black.900")}>
                    <ModalHeader>فراخوانی کد</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Collapse in={alertProps.open}>
                                <Alert status={alertProps.status} variant={useColorModeValue("solid", "subtle")}>
                                    <AlertIcon ml={2} />
                                    <AlertDescription>{alertProps.message}</AlertDescription>
                                </Alert>
                            </Collapse>
                            <VStack
                                bg={useColorModeValue("#1b1b1b20", "RoboEpics.black.600")}
                                h={"13rem"}
                                p={25}
                                borderRadius={5}
                                borderStyle={"dashed"}
                                borderColor={useColorModeValue("RoboEpics.azure.900", "RoboEpics.gold.200")}
                                borderWidth={2}
                                textAlign="center"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <UploadIcon size={"4rem"} />
                                {isGreaterThan769px ? (
                                    isDragActive ? (
                                        <Text fontSize="md">رها کن...</Text>
                                    ) : (
                                        <>
                                            <Text fontSize="sm">‌فایل شامل کد خود را در این ‌جا بکشید یا روی دکمه پایینی کلیک کنید.</Text>
                                            {/* <Text fontSize="xs">
                                                فایل شما باید با فرمت <Code children="zip" /> باشد
                                            </Text> */}
                                        </>
                                    )
                                ) : (
                                    <>
                                        <Text fontSize="sm">فایل خروجی خود را در این قسمت آپلود کنید.</Text>
                                        <Text fontSize="xs">
                                            فایل شما باید فرمت <Code children="zip" /> داشته باشد.
                                        </Text>
                                    </>
                                )}
                                {isGreaterThan769px && (
                                    <Button
                                        variant="ghost"
                                        m={2}
                                        mx={"auto"}
                                        p={2}
                                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                        isLoading={isLoadingFile}
                                    >
                                        انتخاب فایل
                                    </Button>
                                )}
                            </VStack>

                            <FormControl>
                                <FormLabel fontSize="14px">متن کامیت (commit message)</FormLabel>
                                <Input
                                    type="text"
                                    variant="filled"
                                    placeholder="Initial Commit"
                                    value={commitMessage}
                                    onChange={(e) => {
                                        setCommitMessage(e.target.value);
                                    }}
                                    id="commit-message-input"
                                    isTruncated
                                />
                            </FormControl>

                            {props.allowedRuntimes.length > 0 && (
                                <FormControl isRequired={props.allowedRuntimes.length > 0}>
                                    <FormLabel fontSize="14px">زبان برنامه‌نویسی</FormLabel>
                                    <Select
                                        options={props.allowedRuntimes.map((runtime) => {
                                            return ALLOWED_RUNTIMES[runtime] ?? { label: runtime, value: runtime };
                                        })}
                                        value={{
                                            label: ALLOWED_RUNTIMES[chosenRuntime] ? ALLOWED_RUNTIMES[chosenRuntime].label : chosenRuntime,
                                            value: ALLOWED_RUNTIMES[chosenRuntime] ? ALLOWED_RUNTIMES[chosenRuntime].value : chosenRuntime,
                                        }}
                                        onChange={(newValue: OnChangeValue<{ value: string }, false>) => setChosenRuntime(newValue.value)}
                                    />
                                </FormControl>
                            )}

                            <div className={`flex flex-row w-full justify-between items-center mx-auto my-2 ${Style.modal_send}`}>
                                {uploadFile && (
                                    <span className="w-full flex flex-row items-center">
                                        <Paperclip size={14} />
                                        <Text
                                            fontSize={"md"}
                                            mb={0}
                                            mr={2}
                                            p={0}
                                            isTruncated
                                            maxW={"225px"}
                                            className={`${Style.new_commit_name}`}
                                        >
                                            {uploadFile.name}
                                        </Text>
                                    </span>
                                )}
                            </div>
                            {uploadProgress !== 0 && (
                                <Progress
                                    w={"80%"}
                                    mx={"auto"}
                                    value={uploadProgress}
                                    borderRadius={5}
                                    my={4}
                                    size="xs"
                                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                />
                            )}
                            {props.allowedRuntimes.length > 0 && (
                                <Collapse in={showFileFormatWarning}>
                                    <Alert status="warning" variant={useColorModeValue("solid", "subtle")}>
                                        <AlertIcon ml={2} />
                                        <AlertDescription>
                                            فرمت فایل با زبان برنامه‌نویسی انتخاب شده همخوانی ندارد و به احتمال زیاد، ارسالتان ناموفق خواهد
                                            بود. آیا از ارسالتان اطمینان دارید؟
                                        </AlertDescription>
                                    </Alert>
                                </Collapse>
                            )}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className={`${Style.send_btn}`}
                            isDisabled={!uploadFile || (props.allowedRuntimes.length > 0 && !chosenRuntime)}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            size="sm"
                            px={6}
                            isLoading={isLoadingUploadCode}
                            onClick={uploadCode}
                        >
                            ارسال
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};
