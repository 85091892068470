import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import Style from "./settings.module.css";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { problemEditJson } from "@util/api/problems";
import { IComputeResource, IToastOptions } from "@util/interfaces";
import { MAXIMUM_CPU_CORES, MAXIMUM_EPHEMERAL_MEMORY, MAXIMUM_RAM_MEMORY } from "@util/constants";
import { NextRouter } from "next/router";

export const BasicSettings = (props: {
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    problem: string;
    codeExecution: boolean;
    repositoryMode: 10 | 20 | 30 | null;
    evaluationMode: 10 | 20 | 30 | null;
    defaultResourceEphemeralLimit: number | null;
    defaultResourceMemoryLimit: number | null;
    defaultResourceCPULimit: number | null;
    gimulatorResourceEphemeralLimit: number | null;
    gimulatorResourceMemoryLimit: number | null;
    gimulatorResourceCPULimit: number | null;
    computeResources: Array<IComputeResource> | undefined;
    isPublic: boolean;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [codeExecution, setCodeExecution] = useState(props.codeExecution);
    const [repositoryMode, setRepositoryMode] = useState(props.repositoryMode);
    const [evaluationMode, setEvaluationMode] = useState(props.evaluationMode);
    const [isPublic, setIsPublic] = useState(props.isPublic);
    const [defaultResources, setDefaultResources] = useState({
        cpu: props.defaultResourceCPULimit,
        ephemeral: props.defaultResourceEphemeralLimit,
        ram: props.defaultResourceMemoryLimit,
    });
    const [gimulatorResources, setGimulatorResources] = useState({
        cpu: props.gimulatorResourceCPULimit,
        ephemeral: props.gimulatorResourceEphemeralLimit,
        ram: props.gimulatorResourceMemoryLimit,
    });

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const maximumCpu = props.computeResources ? Math.max(...props.computeResources.map((el) => el.cpu)) : 0;
    const maximumEphemral = props.computeResources ? Math.max(...props.computeResources.map((el) => el.disk)) : 0;
    const maximumRam = props.computeResources ? Math.max(...props.computeResources.map((el) => el.memory)) : 0;

    const onReset = () => {
        setCodeExecution(props.codeExecution);
        setRepositoryMode(props.repositoryMode);
        setEvaluationMode(props.evaluationMode);
        setIsPublic(props.isPublic);
        setDefaultResources({
            cpu: props.defaultResourceCPULimit,
            ephemeral: props.defaultResourceEphemeralLimit,
            ram: props.defaultResourceMemoryLimit,
        });
        setGimulatorResources({
            cpu: props.gimulatorResourceCPULimit,
            ephemeral: props.gimulatorResourceEphemeralLimit,
            ram: props.gimulatorResourceMemoryLimit,
        });
    };
    const compileReqData = () => {
        const reqData = {};

        if (codeExecution !== props.codeExecution) reqData["code_execution"] = codeExecution;
        if (repositoryMode !== props.repositoryMode) reqData["repository_mode"] = repositoryMode;
        if (evaluationMode !== props.evaluationMode) reqData["evaluation_mode"] = evaluationMode;
        if (defaultResources.ram !== props.defaultResourceMemoryLimit) reqData["default_resource_memory_limit"] = defaultResources.ram;
        if (defaultResources.cpu !== props.defaultResourceCPULimit) reqData["default_resource_cpu_limit"] = defaultResources.cpu;
        if (defaultResources.ephemeral !== props.defaultResourceEphemeralLimit)
            reqData["default_resource_ephemeral_limit"] = defaultResources.ephemeral;
        if (gimulatorResources.ram !== props.defaultResourceMemoryLimit)
            reqData["gimulator_resource_memory_limit"] = gimulatorResources.ram;
        if (gimulatorResources.cpu !== props.gimulatorResourceCPULimit) reqData["gimulator_resource_cpu_limit"] = gimulatorResources.cpu;
        if (gimulatorResources.ephemeral !== props.gimulatorResourceEphemeralLimit)
            reqData["gimulator_resource_ephemeral_limit"] = gimulatorResources.ephemeral;
        return reqData;
    };
    const onConfirm = async () => {
        setLoading(true);

        const reqData = compileReqData();
        const serverResponse = (await problemEditJson(reqData, props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
        } else {
            props.toast({
                status: "error",
                duration: 5000,
                variant: "subtle",
                description: "مشکلی در اعمال تغییرات شما به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
            });
        }

        setLoading(false);
    };
    useEffect(() => {
        if (
            codeExecution !== props.codeExecution ||
            repositoryMode !== props.repositoryMode ||
            evaluationMode !== props.evaluationMode ||
            !_.isEqual(gimulatorResources, {
                cpu: props.gimulatorResourceCPULimit,
                ram: props.gimulatorResourceMemoryLimit,
                ephemeral: props.gimulatorResourceEphemeralLimit,
            }) ||
            !_.isEqual(defaultResources, {
                cpu: props.defaultResourceCPULimit,
                ram: props.defaultResourceMemoryLimit,
                ephemeral: props.defaultResourceEphemeralLimit,
            })
        ) {
            setDisabled(false);
            props.setHasChanged(true);
        }
        if (
            evaluationMode === props.evaluationMode &&
            repositoryMode === props.repositoryMode &&
            codeExecution === props.codeExecution &&
            _.isEqual(gimulatorResources, {
                cpu: props.gimulatorResourceCPULimit,
                ram: props.gimulatorResourceMemoryLimit,
                ephemeral: props.gimulatorResourceEphemeralLimit,
            }) &&
            _.isEqual(defaultResources, {
                cpu: props.defaultResourceCPULimit,
                ram: props.defaultResourceMemoryLimit,
                ephemeral: props.defaultResourceEphemeralLimit,
            })
        ) {
            setDisabled(true);
            props.setHasChanged(false);
        }
    }, [codeExecution, repositoryMode, evaluationMode, defaultResources, gimulatorResources]);
    return (
        <VStack spacing={6} px={8} w={"full"} className={`${Style.basic_form_container}`}>
            {/* <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Repository Mode
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore
                                    inventore doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga
                                    nulla atque doloribus suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <Checkbox
                        isChecked={repositoryMode !== 10}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        onChange={(e) => setRepositoryMode(e.target.checked ? 20 : 10)}
                        className={`${Style.basic_checkbox}`}
                    >
                        choose whether or not use the repository_mode for your problem
                    </Checkbox>
                    <Checkbox
                        isDisabled={repositoryMode === 10}
                        isChecked={repositoryMode === 30}
                        onChange={(e) => setRepositoryMode(e.target.checked ? 30 : 20)}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        className={`${Style.basic_checkbox}`}
                    >
                        choose whether or not use the repository_mode_notebook for your problem
                    </Checkbox>
                </FormControl>
                <Divider />
                <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Evaluation Mode
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-eval"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore
                                    inventore doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga
                                    nulla atque doloribus suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <Checkbox
                        isChecked={evaluationMode !== 10}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        onChange={(e) => setEvaluationMode(e.target.checked ? 20 : 10)}
                        className={`${Style.basic_checkbox}`}
                    >
                        choose whether or not use the evaluation_mode for your problem
                    </Checkbox>
                    <Checkbox
                        isDisabled={evaluationMode === 10}
                        isChecked={evaluationMode === 30}
                        onChange={(e) => setEvaluationMode(e.target.checked ? 30 : 20)}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        className={`${Style.basic_checkbox}`}
                    >
                        choose whether or not use the evaluation_mode_notebook for your problem
                    </Checkbox>
                </FormControl>
                <Divider />
                <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Automatic Evaluation{" "}
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-code-exec"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore
                                    inventore doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga
                                    nulla atque doloribus suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <Checkbox
                        isChecked={codeExecution}
                        isDisabled={repositoryMode === 10 || evaluationMode === 10}
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        onChange={(e) => setCodeExecution(e.target.checked)}
                        className={`${Style.basic_checkbox}`}
                    >
                        choose whether or not use the code execution for your problem
                    </Checkbox>
                </FormControl>
                <Divider /> */}
            <FormControl>
                <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                    Default Resources
                    <Popover placement="left">
                        <PopoverTrigger>
                            <IconButton
                                icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                bg="transparent"
                                variant="ghost"
                                aria-label="question-code-exec"
                                size="xs"
                                mr={2}
                            />
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore doloremque
                                exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus suscipit hic
                                delectus excepturi.
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </FormLabel>
                <VStack spacing={3} align={"flex-start"}>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از هسته‌های CPU (هسته):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            allowMouseWheel
                            value={defaultResources.cpu ? defaultResources.cpu : 0}
                            precision={1}
                            min={0}
                            max={props.computeResources?.length > 0 ? maximumCpu : MAXIMUM_CPU_CORES}
                            maxW={"fit-content"}
                            step={1}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return {
                                        ...current,
                                        cpu: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumCpu : MAXIMUM_CPU_CORES}
                            value={defaultResources.cpu}
                            onChangeEnd={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, cpu: value };
                                });
                            }}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, cpu: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از حافظه Ephemeral (مگابایت):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            allowMouseWheel
                            value={defaultResources.ephemeral ? defaultResources.ephemeral : 0}
                            min={0}
                            maxW={"fit-content"}
                            step={1}
                            max={props.computeResources?.length > 0 ? maximumEphemral : MAXIMUM_EPHEMERAL_MEMORY}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return {
                                        ...current,
                                        ephemeral: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumEphemral : MAXIMUM_EPHEMERAL_MEMORY}
                            value={defaultResources.ephemeral}
                            onChangeEnd={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, ephemeral: value };
                                });
                            }}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, ephemeral: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از حافظه RAM (مگابایت):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            allowMouseWheel
                            value={defaultResources.ram ? defaultResources.ram : 0}
                            min={0}
                            maxW={"fit-content"}
                            step={1}
                            max={props.computeResources?.length > 0 ? maximumRam : MAXIMUM_RAM_MEMORY}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return {
                                        ...current,
                                        ram: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumRam : MAXIMUM_RAM_MEMORY}
                            value={defaultResources.ram}
                            onChangeEnd={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, ram: value };
                                });
                            }}
                            onChange={(value) => {
                                setDefaultResources((current) => {
                                    return { ...current, ram: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                </VStack>
            </FormControl>
            <Divider />
            <FormControl>
                <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                    Gimulator Resources
                    <Popover placement="left">
                        <PopoverTrigger>
                            <IconButton
                                icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                bg="transparent"
                                variant="ghost"
                                aria-label="question-code-exec"
                                size="xs"
                                mr={2}
                            />
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore doloremque
                                exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus suscipit hic
                                delectus excepturi.
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </FormLabel>
                <VStack spacing={3} align={"flex-start"}>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از هسته‌های CPU (هسته):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            allowMouseWheel
                            value={gimulatorResources.cpu ? gimulatorResources.cpu : 0}
                            min={0}
                            precision={1}
                            maxW={"fit-content"}
                            step={1}
                            max={props.computeResources?.length > 0 ? maximumCpu : MAXIMUM_CPU_CORES}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return {
                                        ...current,
                                        cpu: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumCpu : MAXIMUM_CPU_CORES}
                            value={gimulatorResources.cpu}
                            onChangeEnd={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, cpu: value };
                                });
                            }}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, cpu: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از حافظه Ephemeral (مگابایت):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            value={gimulatorResources.ephemeral ? gimulatorResources.ephemeral : 0}
                            min={0}
                            maxW={"fit-content"}
                            step={1}
                            max={props.computeResources?.length > 0 ? maximumEphemral : MAXIMUM_EPHEMERAL_MEMORY}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return {
                                        ...current,
                                        ephemeral: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumEphemral : MAXIMUM_EPHEMERAL_MEMORY}
                            value={gimulatorResources.ephemeral}
                            onChangeEnd={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, ephemeral: value };
                                });
                            }}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, ephemeral: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                    <div className={`flex flex-row items-center w-full ${Style.basic_numberinput_container}`}>
                        <Text ml={3} mb={0} className={`${Style.basic_numberinput_label}`}>
                            محدودیت استفاده از حافظه RAM (مگابایت):
                        </Text>
                        <NumberInput
                            className={`${Style.basic_numberinput_container}`}
                            size="sm"
                            value={gimulatorResources.ram ? gimulatorResources.ram : 0}
                            min={0}
                            max={props.computeResources?.length > 0 ? maximumRam : MAXIMUM_RAM_MEMORY}
                            maxW={"fit-content"}
                            step={1}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return {
                                        ...current,
                                        ram: parseInt(value),
                                    };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper />
                        </NumberInput>
                        <Slider
                            className={`${Style.basic_slider}`}
                            aria-label="slider-default-cpu"
                            focusThumbOnChange={false}
                            mx={4}
                            flex="1"
                            max={props.computeResources?.length > 0 ? maximumRam : MAXIMUM_RAM_MEMORY}
                            value={gimulatorResources.ram}
                            onChangeEnd={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, ram: value };
                                });
                            }}
                            onChange={(value) => {
                                setGimulatorResources((current) => {
                                    return { ...current, ram: value };
                                });
                            }}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                </VStack>
            </FormControl>
            <Box className={`flex flex-row justify-end w-full ${Style.basic_button_container}`}>
                <Button
                    isDisabled={loading || disabled}
                    ml={4}
                    colorScheme={"red"}
                    onClick={() => {
                        onReset();
                    }}
                    size="sm"
                >
                    بازنشانی
                </Button>
                <Button
                    isDisabled={disabled}
                    isLoading={loading}
                    size="sm"
                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                    bg={useColorModeValue("RoboEpics.dark.500", "RoboEpics.gold.500")}
                    onClick={() => {
                        onConfirm();
                    }}
                >
                    ذخیره و اعمال تغییرات
                </Button>
            </Box>
        </VStack>
    );
};
