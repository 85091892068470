import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Fade,
    Table,
    Box,
    useColorModeValue,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    Button,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Heading,
    Avatar,
    IconButton,
    Link as ChakraLink,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
} from "@chakra-ui/react";
import { Pagination } from "@components";
import { LEADERBOARD_ITEMS_PER_PAGE } from "@util/constants";
import { convertDate, getDatePhrase } from "@util/dateConverter";
import { retrieveProblemLeaderboard, retrieveProblemLeaderboards } from "@util/api/leaderboard";
import { IdDisplayer } from "@components/competition";
import { SingleProblemIndividualChart } from "@components";
// import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { getParticipant } from "@util/api/participants";
import { NextRouter } from "next/router";
import { CustomAvatar } from "@components/avatar";
import { getImageURL } from "@util/mediaLoader";
import { MiniUserProfile } from "@components/miniUserProfile";

import Style from "./problemScoreboard.module.css";
import { RefreshOutline } from "react-ionicons";
import { IScoreboardModalProps, IToastOptions } from "@util/interfaces";
import Link from "next/link";

export const ProblemScoreboard = (props: { problem: string; toast: IToastOptions; router: NextRouter }) => {
    const bw = useColorModeValue("#000", "fff");
    const [scoreboardMetaData, setScoreboardMetaData] = useState<
        Array<{ id: number; title: string; short_description: string; private: boolean }>
    >([]);

    const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const getProblemLeaderboardsData = async () => {
        setLoadingComponent(true);
        const serverResponse = (await retrieveProblemLeaderboards(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setScoreboardMetaData(data);
        } else {
            setErrorLoading(true);
        }
        setLoadingComponent(false);
    };

    useEffect(() => {
        if (props.problem) getProblemLeaderboardsData();
    }, []);

    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }

    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    return (
        <>
            <Box className="flex flex-row w-full mt-4 items-center justify-between">
                <IconButton
                    variant="ghost"
                    icon={<RefreshOutline color={bw} height="18px" width="18px" />}
                    size="sm"
                    aria-label="Refresh list"
                    onClick={() => {
                        getProblemLeaderboardsData();
                    }}
                    isDisabled={loadingComponent}
                    mr="auto"
                />
            </Box>

            <Tabs isLazy>
                <TabList>
                    {scoreboardMetaData.map((data) => {
                        return <Tab key={data.id}>{data.title}</Tab>;
                    })}
                </TabList>
                <TabPanels>
                    {scoreboardMetaData.map((data) => {
                        return (
                            <TabPanel key={data.id}>
                                <ProblemScoreboardTabPanel
                                    id={data.id}
                                    router={props.router}
                                    shortDesc={data.short_description}
                                    problem={props.problem}
                                    toast={props.toast}
                                />
                            </TabPanel>
                        );
                    })}
                </TabPanels>
            </Tabs>
        </>
    );
};

const ProblemScoreboardTabPanel = (props: { shortDesc: string; id: number; router: NextRouter; toast: IToastOptions; problem: string }) => {
    const bw = useColorModeValue("#000", "#fff");
    const table_header_text = useColorModeValue("RoboEpics.black.500", "#fff");
    const eligibleRowStyles = {
        backgroundColor: useColorModeValue("rgba(85,166,48,0.15)", "rgba(45,406,79,0.03)"),
        fontWeight: 600,
        borderRight: "6px solid #007F5F",
    };
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(props.router.query.p ? parseInt(props.router.query.p.toString()) : 1);

    const [loadingComponent, setLoadingComponent] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [scoreboardData, setScoreboardData] = useState<{
        results: Array<{
            creator_full_name: string;
            creator_profile_picture: string;
            individual: boolean;
            last_submission: string;
            participant_id: number;
            problem_enter_id: number;
            score: number;
            submission_dates: Array<string>;
            team_image: string;
            team_name: string;
        }>;
        eligible_score: null | number;
        total: number;
        team_rank: number;
    }>({
        results: [],
        eligible_score: null,
        total: null,
        team_rank: null,
    });

    const [modalParticipantInfoData, setModalParticipantInfoData] = useState<{ isOpen: boolean; pid: number }>({
        isOpen: false,
        pid: null,
    });

    const setData = async () => {
        setLoadingComponent(true);
        const startIndex = (currentPage - 1) * LEADERBOARD_ITEMS_PER_PAGE;
        const endIndex = startIndex + LEADERBOARD_ITEMS_PER_PAGE;
        const serverResponse = (await retrieveProblemLeaderboard(
            props.problem,
            props.id,
            {
                start: startIndex,
                end: endIndex,
            },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setScoreboardData(data);
            setTotalItems(data.total);
        } else {
            setErrorLoading(true);
        }
        setLoadingComponent(false);
    };

    useEffect(() => {
        if (props.problem) {
            setData();
        }
    }, [props.problem, currentPage]);

    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }

    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    return (
        <>
            <Box className="flex flex-row w-full mt-4 items-center justify-between">
                {scoreboardData.team_rank != null && (
                    <Text mb={0} fontSize="xl" fontWeight={800}>
                        رتبه تیم شما: {scoreboardData.team_rank + 1}
                    </Text>
                )}
                <IconButton
                    variant="ghost"
                    icon={<RefreshOutline color={bw} height="18px" width="18px" />}
                    size="sm"
                    aria-label="Refresh list"
                    onClick={() => {
                        setData();
                    }}
                    isDisabled={loadingComponent}
                    mr="auto"
                />
            </Box>

            <Box className="w-full h-full relative" overflowX="auto">
                {scoreboardData.results ? (
                    <>
                        <Table size="lg" variant="simple">
                            <Thead color={table_header_text}>
                                <Tr>
                                    {/* <Th
                letterSpacing="0"
                textAlign="center"
                pl="1"
                pr="2"
                ></Th> */}
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        رتبه
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        شرکت‌کننده
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        تعداد ارسال
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        آخرین ارسال
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        روند ارسال‌ها
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        امتیاز
                                    </Th>
                                    {/* <Th
                letterSpacing="0"
                textAlign="center"
                color="RoboEpics.black.600"
            ></Th> */}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {scoreboardData.results.map((element, index: number) => {
                                    return (
                                        <Tr
                                            key={index}
                                            fontSize="14px"
                                            sx={{
                                                ...(element.score >= scoreboardData.eligible_score ? eligibleRowStyles : {}),
                                            }}
                                        >
                                            {/* <Td
                        textAlign={["center"]}
                        py="0"
                        pl="1"
                        pr="2"
                        fontSize="12px"
                        >
                        <div className=" flex flex-row justify-center items-center">
                            <Tooltip
                            hasArrow
                            label="رتبه قبلی:5"
                                placement="top"
                                color={useColorModeValue(
                                    "green.400",
                                    "green.200",
                                )}
                                aria-label="positive"
                            >
                                <TriangleUpIcon
                                    color={useColorModeValue(
                                        "green.400",
                                        "green.200",
                                    )}
                                />
                            </Tooltip>
                            <Tooltip
                            hasArrow
                            label="بدون تغییر"
                                placement="top"
                                aria-label="neutral"
                                >
                                <span className="bg-gray-400 rounded-full h-2 w-2 " />
                                </Tooltip>{" "}
                                <Tooltip
                                hasArrow
                                label="رتبه قبلی: 5"
                                placement="top"
                                color={useColorModeValue(
                                    "red.500",
                                    "red.300",
                                    )}
                                    aria-label="negative"
                                    >
                                    <TriangleDownIcon
                                    color={useColorModeValue(
                                        "red.500",
                                        "red.300",
                                        )}
                                        />
                                    </Tooltip>
                                    </div>
                                </Td> */}
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {(currentPage - 1) * LEADERBOARD_ITEMS_PER_PAGE + index + 1}
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                <Button
                                                    variant="ghost"
                                                    minW="208px"
                                                    justifyContent="flex-start"
                                                    padding="24px 20px"
                                                    onClick={() => {
                                                        setModalParticipantInfoData({
                                                            isOpen: true,
                                                            pid: element.participant_id,
                                                        });
                                                    }}
                                                >
                                                    <IdDisplayer
                                                        name={element.individual ? element.creator_full_name : element.team_name}
                                                        image={element.individual ? element.creator_profile_picture : element.team_image}
                                                    />
                                                </Button>
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {element.submission_dates.length}
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                pl="0"
                                                pr="3"
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {convertDate(element.last_submission, "D MMMM YYYY")}
                                            </Td>

                                            <Td
                                                py="0"
                                                sx={{
                                                    ".chartjs-render-monitor": {
                                                        width: "150px !important",
                                                        height: "80px !important",
                                                    },
                                                }}
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                <SingleProblemIndividualChart submissionData={element.submission_dates} />
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                fontWeight="700"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {element.score}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                        <Pagination
                            totalRecords={totalItems}
                            pageNeighbours={1}
                            currentPage={currentPage}
                            onPageChanged={(page) => {
                                setCurrentPage(page);
                                props.router.push(
                                    `/c/${props.router.query.competition}/p/${props.router.query.phase_id}/p/${props.router.query.problem}?v=scoreboard&p=${page}`,
                                    undefined,
                                    { shallow: true },
                                );
                            }}
                        />
                    </>
                ) : (
                    <div className={`flex flex-col w-full mt-8 justify-center items-center`}>
                        <Heading size="sm">درحال حاضر اطلاعاتی برای نمایش دادن در جدول امتیازات وجود ندارد</Heading>
                    </div>
                )}
            </Box>

            <ParticipantInfoModal
                isOpen={modalParticipantInfoData.isOpen}
                onClose={() => {
                    setModalParticipantInfoData({ isOpen: false, pid: null });
                }}
                pid={modalParticipantInfoData.pid}
                router={props.router}
                toast={props.toast}
            />
        </>
    );
};

const ParticipantInfoModal = (props: { isOpen: boolean; onClose: () => void; pid: number; toast: IToastOptions; router: NextRouter }) => {
    const buttonColorScheme = useColorModeValue("RoboEpics.dark", "RoboEpics.gold");
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [modalData, setModalData] = useState<IScoreboardModalProps>(null);

    async function getModalData() {
        setIsLoading(true);

        const serverResponse = (await getParticipant(props.pid.toString(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { team } = await serverResponse.json();
            setModalData(team);
        } else {
            setErrorLoading(true);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (props.pid) {
            getModalData();
        }
    }, [props.pid]);
    if (errorLoading) {
        return (
            <Modal
                onClose={() => {
                    props.onClose();
                    setIsLoading(false);
                    setModalData(null);
                    setErrorLoading(false);
                }}
                isOpen={props.isOpen}
            >
                <ModalOverlay />
                <ModalContent className={`${Style.modal_content}`}>
                    <ModalHeader>
                        <Heading size="md">اطلاعات شرکت‌کننده</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Fade in={errorLoading}>
                            <Alert status="error">
                                <AlertIcon />
                                <AlertTitle ml={2} fontWeight="600">
                                    مشکلی در دریافت داده از سرور رخ داد.
                                </AlertTitle>
                                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                            </Alert>
                        </Fade>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    return (
        <Modal
            onClose={() => {
                props.onClose();
                setIsLoading(false);
                setModalData(null);
                setErrorLoading(false);
            }}
            isOpen={props.isOpen}
        >
            <ModalOverlay />
            <ModalContent className={`${Style.modal_content}`}>
                <ModalHeader>
                    <Heading size="md">اطلاعات شرکت‌کننده</Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {isLoading ? (
                        <Box className="flex flex-col items-center">
                            <Spinner className="my-12" />
                        </Box>
                    ) : modalData?.individual ? (
                        <Box as="div" className={`flex flex-col items-center w-full`}>
                            <CustomAvatar
                                src={getImageURL(modalData?.member_set[0].user.profile_picture)}
                                showPlaceholder={!modalData?.member_set[0].user.profile_picture}
                                placeholder={<Avatar size="full" />}
                                className="mb-2 px-0 py-0 border-transparent border-0"
                                style={{ width: "100px", height: "100px" }}
                            />
                            <Text fontWeight={700} fontSize="2xl" className="mb-0">
                                {modalData?.member_set[0].user.full_name}
                            </Text>
                            <div>
                                <span
                                    style={{
                                        direction: "ltr",
                                        color: "#898989",
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    {modalData?.member_set[0].user.username + " @"}
                                </span>
                            </div>
                        </Box>
                    ) : (
                        <Box as="div" className={`flex flex-col items-center w-full`}>
                            <CustomAvatar
                                src={getImageURL(modalData?.image)}
                                showPlaceholder={!modalData?.image}
                                placeholder={<Avatar size="full" />}
                                className="mb-2 px-0 py-0 border-transparent border-0"
                                style={{ width: "100px", height: "100px" }}
                            />
                            <Text fontWeight={700} fontSize="2xl" className="mb-2">
                                {modalData?.name}
                            </Text>
                            <Box className="w-full h-full" overflowX="auto">
                                <Table mt={4} size="md" variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th pl={0} pr={1} minW="200px">
                                                عضو
                                            </Th>
                                            <Th pl={0}>نقش</Th>
                                            <Th pl={0}>هم‌تیمی از</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {modalData?.member_set.map((memberData) => {
                                            return (
                                                <Tr
                                                    id={memberData.user.fusion_user_id}
                                                    key={memberData.user.fusion_user_id}
                                                    sx={{
                                                        td: {
                                                            paddingBottom: "12px",
                                                            paddingTop: "12px",
                                                            fontSize: "14px",
                                                            fontWeight: "normal",
                                                        },
                                                    }}
                                                >
                                                    <Td textAlign={["center"]} pl={0} pr={1}>
                                                        <MiniUserProfile
                                                            fullName={memberData.user.full_name}
                                                            username={memberData.user.username}
                                                            imageFilename={memberData.user.profile_picture}
                                                        />
                                                    </Td>
                                                    <Td pl={0}>{memberData?.access_level === 30 ? "عضو عادی" : "سرگروه"}</Td>
                                                    <Td pl={0}>{getDatePhrase(memberData.data_joined, "roboepics")}</Td>
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    )}
                </ModalBody>
                {modalData?.individual && (
                    <ModalFooter>
                        <Link href={`/users/${modalData?.member_set[0].user.username}`} passHref>
                            <ChakraLink isExternal>
                                <Button size="sm" colorScheme={buttonColorScheme}>
                                    مشاهده پروفایل
                                </Button>
                            </ChakraLink>
                        </Link>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};
