import React, { useEffect, useState } from "react";
import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import { convertDate } from "@util/dateConverter";
import _ from "lodash";
interface UserActivityChartProps {
    activityData: string[];
}
export const UserActivityChart = (porps: UserActivityChartProps) => {
    const { colorMode } = useColorMode();
    const convertedDates: string[] = [];
    Array.from(porps.activityData).forEach((el, id) => {
        if (el.toString().search(/^[1-9][0-9]{3}\/[0-9]{1,2}\/[0,9]{1,2}$/g) == -1) {
            convertedDates.push(convertDate(el, "YYYY/M/D").toString());
        }
    });
    const count = _.countBy(convertedDates);
    const activityDatesChartDataX = [];
    const activityDatesChartDataY = [];
    _.forEach(count, (v, k) => {
        activityDatesChartDataX.push(k), activityDatesChartDataY.push(v);
    });
    const _borderColor = useColorModeValue(`#023e8a`, `#ffd300`);
    const _backgroundColor = useColorModeValue("rgba(0,119,182,.4)", "rgba(255,229,104,.35)");
    const chartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 255);
        gradient.addColorStop(0, _backgroundColor);
        gradient.addColorStop(1, "rgba(255,255,255,0)");
        return {
            labels: activityDatesChartDataX,
            datasets: [
                {
                    // pointRadius: !(activityDatesChartDataY.length - 1) && 2.5, // if you want to see points on chart remove this lineand uncomment next lines
                    pointBackgroundColor: colorMode === "light" ? `#023e8a` : `#ffd300`,
                    pointBorderColor: colorMode === "light" ? `#023e8a` : `#ffd300`,
                    pointBorderWidth: 1,
                    pointStyles: "cross",
                    fontFamily: "Shabnam",
                    fill: "start",
                    label: "تعداد ارسال‌ها",
                    data: activityDatesChartDataY,
                    backgroundColor: gradient,
                    borderColor: _borderColor,
                    borderWidth: 2,
                },
            ],
        };
    };
    return (
        <Line
            width={100}
            height={100}
            data={chartData}
            options={{
                layout: {
                    padding: { left: 10, right: 40, top: 5, bottom: 0 },
                },
                datasets: [
                    {
                        label: { fontFamily: "Shabnam" },
                    },
                ],
                maintainAspectRatio: false,
                legend: { display: false },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                                color: useColorModeValue("rgba(0,0,0,0.05)", "rgba(255,255,255,0.05)"),
                                zeroLineColor: useColorModeValue("rgba(0,0,0,0.5)", "rgba(255,255,255,0.5)"),
                            },
                            ticks: {
                                display: false,
                            },
                            display: false,
                        },
                    ],
                    xAxes: [
                        {
                            offset: activityDatesChartDataX.length === 1,
                            gridLines: {
                                display: true,
                                color: useColorModeValue("rgba(0,0,0,0.1)", "rgba(255,255,255,0.1)"),
                                zeroLineColor: useColorModeValue("rgba(0,0,0,0.5)", "rgba(255,255,255,0.5)"),
                            },
                            ticks: {
                                display: true,
                            },
                            display: true,
                        },
                    ],
                },
            }}
        />
    );
};
