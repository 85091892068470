import React from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Avatar, Text } from "@chakra-ui/react";
import { LogOut, User, Edit, Book } from "react-feather";
import { ExtensionPuzzleOutline, SchoolOutline } from "react-ionicons";
import { useRouter } from "next/router";
import { setUserData, setParticipantId } from "@redux/actions";
import { useDispatch } from "react-redux";
import * as CookiesUtil from "@util/cookies";
import Link from "next/link";
import { IUser } from "@redux/slices/user";

export const UserAvatar = (props: { Button: JSX.Element; user: IUser }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const bg = useColorModeValue("#fff", "RoboEpics.black.900");
    const subTextColor = useColorModeValue("RoboEpics.black.700", "RoboEpics.black.500");
    const bw = useColorModeValue("#000", "#fff");

    const menuItems = [
        {
            disabled: false,
            url: `/users/${props.user.data.username}?v=competitions`,
            icon: <ExtensionPuzzleOutline height="18px" width="18px" color={bw} />,
            title: "مسابقات من",
            onClick: () => {
                router.push(`/users/${props.user.data.username}?v=competitions`);
            },
            key: "my_competitions",
        },
        {
            disabled: true,
            url: `#`,
            icon: <SchoolOutline color={bw} />,
            title: "دوره‌های من (به زودی)",
            onClick: () => {
                console.log("###");
            },
            key: "my_courses",
        },
        {
            disabled: true,
            url: `#`,
            icon: <Book size={18} />,
            title: "نوت‌بوک‌های من (به زودی)",
            onClick: () => {
                console.log("###");
            },
            key: "my_notebooks",
        },
        {
            disabled: false,
            url: `/users/${props.user.data.username}`,
            icon: <User size={18} />,
            title: "پروفایل",
            onClick: () => {
                router.push(`/users/${props.user.data.username}`);
            },
            key: "my_profile",
        },
        {
            disabled: false,
            url: `/profile/edit`,
            icon: <Edit size={18} />,
            title: "ویرایش پروفایل",
            onClick: () => {
                router.push("/profile/edit");
            },
            key: "edit_my_profile",
        },
        {
            disabled: false,
            url: "#",
            icon: <LogOut size={18} />,
            title: "خروج",
            onClick: async () => {
                dispatch(
                    setUserData({
                        id: "",
                        fullName: "",
                        username: "",
                        imageUrl: "",
                        email: "",
                        isSuperuser: false,
                    }),
                );
                dispatch(setParticipantId(null));
                // TODO: clear cookies
                CookiesUtil.eraseCookie("re_auth_access_token_expire");
                CookiesUtil.eraseCookie("re_auth_access_token");
                CookiesUtil.eraseCookie("re_auth_refresh_token");
                router.push(`/users/signin?returnUrl=${router.asPath}`);
            },
            key: "logout",
        },
    ];
    return (
        <Menu isLazy closeOnSelect>
            <MenuButton className="mr-1">{props.Button}</MenuButton>
            <MenuList minWidth="12rem" bg={bg}>
                <div className="mb-4 mt-2 flex flex-row items-center justify-start px-4">
                    <Avatar size="md" src={props.user.data.imageUrl} />
                    <div className="flex flex-col justify-center items-center mr-3">
                        <Text mb="1" width="full">
                            {props.user.data.fullName}
                        </Text>
                        <Text m="0" fontSize="xs" color={subTextColor} width="full" fontFamily="sans-serif">
                            {props.user.data.username} @
                        </Text>
                    </div>
                </div>
                <MenuDivider />
                {menuItems.slice(0, 3).map((menuItem) => {
                    return (
                        <Link href={menuItem.url} key={menuItem.key} passHref>
                            <MenuItem
                                as={"a"}
                                textAlign={"right"}
                                icon={menuItem.icon}
                                isDisabled={menuItem.disabled}
                                onClick={menuItem.onClick}
                            >
                                {menuItem.title}
                            </MenuItem>
                        </Link>
                    );
                })}
                <MenuDivider />
                {menuItems.slice(3, 5).map((menuItem) => {
                    return (
                        <Link href={menuItem.url} key={menuItem.key} passHref>
                            <MenuItem
                                as={"a"}
                                textAlign={"right"}
                                icon={menuItem.icon}
                                isDisabled={menuItem.disabled}
                                onClick={menuItem.onClick}
                            >
                                {menuItem.title}
                            </MenuItem>
                        </Link>
                    );
                })}
                <MenuDivider />

                <Link href={menuItems[5].url} key={menuItems[5].key} passHref>
                    <MenuItem
                        as={"a"}
                        textAlign={"right"}
                        icon={menuItems[5].icon}
                        isDisabled={menuItems[5].disabled}
                        onClick={menuItems[5].onClick}
                    >
                        {menuItems[5].title}
                    </MenuItem>
                </Link>
            </MenuList>
        </Menu>
    );
};
