import { Button, Heading, Img, List, ListItem, SimpleGrid, Text, useColorModeValue, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import Image from "next/image";
import Style from "./homepage.module.css";
import { CheckmarkCircle, PodiumOutline } from "react-ionicons";
import { NextRouter, useRouter } from "next/router";
import {
    DeploymentPattern,
    ChartEvaluation,
    IbmCloudDedicatedHost,
    VirtualDesktop,
    GroupResource,
    Number_1,
    Number_2,
    Number_3,
    Number_4,
} from "@carbon/icons-react";
import { Cpu } from "react-feather";
import { getAllCompetitions } from "@util/api/competitions";
import { ICompetitionCardInfo, IToastOptions } from "@util/interfaces";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { getImageURL } from "@util/mediaLoader";
import { getDatePhrase } from "@util/dateConverter";

export const HomepageComponent = (props: {
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [loadedImages, setLoadedImages] = useState(0);
    const [compData, setCompData] = useState<{
        results: Array<ICompetitionCardInfo>;
        total: number;
    }>({ results: [], total: 0 });
    const router = useRouter();

    // const fetchCompetitionsData = async () => {
    //     const serverResponse = (await getAllCompetitions(undefined, {
    //         toast: props.toast,
    //         requesterPath: props.router.asPath,
    //     })) as Response;
    //     if (serverResponse.ok) {
    //         const data = await serverResponse.json();
    //         setCompData(data);
    //     } else {
    //         props.toast({
    //             description: "Error in fetching competitions data.",
    //             duration: 1000,
    //             status: "error",
    //             isClosable: true,
    //             variant: "subtle",
    //         });
    //     }
    // };

    // useEffect(() => {
    //     fetchCompetitionsData();
    // }, []);

    useEffect(() => {
        if (loadedImages === compData.total ? 10 : 6) {
            setTimeout(() => {
                props.setIsLoaded(true);
            }, 1000);
        }
    }, [loadedImages]);

    return (
        <div className={`${Style.landing_container}`} id="landing_container">
            <div className={`${Style.blob} ${Style.blob_one}`}></div>
            <div className={`${Style.blob} ${Style.blob_two}`}></div>
            <div className={`${Style.blob} ${Style.blob_three}`}></div>
            <div className={`${Style.hero_banner}`}>
                <div className={`w-full ${Style.slider_container}`}>
                    <ReactCompareImage
                        leftImage="/images/K1-2.png"
                        rightImageCss={{
                            maxHeight: "653px",
                            objectFit: "scale-down",
                        }}
                        leftImageCss={{
                            maxHeight: "653px",
                            objectFit: "scale-down",
                        }}
                        rightImage="/images/K2-2.png"
                        hover
                        sliderLineWidth={2}
                        handle={<React.Fragment />}
                        sliderLineColor={useColorModeValue("#000", "#fff")}
                    />
                </div>
                <div className={`w-full ${Style.hero_text_container}`}>
                    <Heading fontSize={["1.5rem", "2.5rem"]} size={"2xl"} fontWeight={"bold"} lineHeight={["38px", "66px"]} mb={6}>
                        روبواپیکس
                    </Heading>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"}>
                        ما یک پلتفرم برون‌سپاری برای چالش‌های هوش مصنوعی هستیم.
                    </Text>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"}>
                        با برگزاری مسابقات هوش‌مصنوعی، افراد متخصص و علاقه‌مند را گرد هم می‌آوریم.
                    </Text>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"} mb={0}>
                        تا آن‌ها برای چالش‌های متفاوت با هم رقابت کنند و در سریع‌ترین زمان بهترین نتایج و راهکارها را ارائه دهند.
                    </Text>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        mt={10}
                        fontSize={"1rem"}
                        lineHeight={"25px"}
                        w="141px"
                        h="41px"
                        borderRadius={8}
                        onClick={() => {
                            router.push("/users/signup");
                        }}
                    >
                        ثبت‌نام کنید
                    </Button>
                </div>
            </div>
            <div className={`${Style.trusted_us}`}>
                <Heading fontSize={"2xl"} fontWeight={"normal"}>
                    به ما اعتماد کرده‌اند
                </Heading>
                <div className={`${Style.trusted_logos}`}>
                    <Img
                        src={useColorModeValue("/images/hamava.svg", "/images/hamava-dark.svg")}
                        className={`border  ${Style.no_image} ${Style.partner_logo}`}
                        objectFit="scale-down"
                        onLoad={() => {
                            setLoadedImages((current) => current + 1);
                        }}
                        onError={() => {
                            setLoadedImages((current) => current + 1);
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                        loading="eager"
                    ></Img>
                    <Img
                        src="/images/dd.png"
                        className={`border  ${Style.no_image} ${Style.partner_logo}`}
                        objectFit="scale-down"
                        onLoad={() => {
                            setLoadedImages((current) => current + 1);
                        }}
                        onError={() => {
                            setLoadedImages((current) => current + 1);
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                        loading="eager"
                    ></Img>
                    <Img
                        src="/images/trb.png"
                        className={`border  ${Style.no_image} ${Style.partner_logo}`}
                        objectFit="scale-down"
                        onLoad={() => {
                            setLoadedImages((current) => current + 1);
                        }}
                        onError={() => {
                            setLoadedImages((current) => current + 1);
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                        loading="eager"
                    ></Img>
                    <Img
                        src={useColorModeValue("/images/dg-blue.png", "/images/dg.png")}
                        className={`border  ${Style.no_image} ${Style.partner_logo}`}
                        objectFit="scale-down"
                        onLoad={() => {
                            setLoadedImages((current) => current + 1);
                        }}
                        onError={() => {
                            setLoadedImages((current) => current + 1);
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                        loading="eager"
                    ></Img>
                    <Img
                        src="/images/fanap.png"
                        className={`border  ${Style.no_image} ${Style.partner_logo}`}
                        objectFit="scale-down"
                        onLoad={() => {
                            setLoadedImages((current) => current + 1);
                        }}
                        onError={() => {
                            setLoadedImages((current) => current + 1);
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                        loading="eager"
                    ></Img>
                </div>
            </div>
            <div className={`${Style.statistics}`}>
                <Box className={`flex flex-col items-center justify-center mx-4`}>
                    <Text
                        mb={0}
                        fontWeight={700}
                        fontSize={"3xl"}
                        textAlign={"center"}
                        borderColor={useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)")}
                    >
                        8
                    </Text>
                    <Text fontWeight={500} fontSize={"xl"} mb={0} textAlign={"center"}>
                        مسابقه‌ی برگزار شده
                    </Text>
                </Box>
                <Box className={`flex flex-col items-center justify-center mx-4`}>
                    <Text
                        mb={0}
                        fontWeight={700}
                        fontSize={"3xl"}
                        textAlign={"center"}
                        borderColor={useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)")}
                    >
                        3000+
                    </Text>
                    <Text fontWeight={600} fontSize={"xl"} mb={0} textAlign={"center"}>
                        عضو فعال
                    </Text>
                </Box>
                <Box className={`flex flex-col items-center justify-center mx-4`}>
                    <Text
                        mb={0}
                        fontWeight={700}
                        fontSize={"3xl"}
                        textAlign={"center"}
                        borderColor={useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)")}
                    >
                        6000+
                    </Text>
                    <Text fontWeight={600} fontSize={"xl"} mb={0} textAlign={"center"}>
                        کد ارسال شده
                    </Text>
                </Box>
                <Box className={`flex flex-col items-center justify-center mx-4`}>
                    <Text
                        mb={0}
                        fontWeight={700}
                        fontSize={"3xl"}
                        textAlign={"center"}
                        borderColor={useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)")}
                    >
                        150,000,000+
                    </Text>
                    <Text fontWeight={600} fontSize={"xl"} mb={0} textAlign={"center"}>
                        تومان جایزه نقدی اهدا شده
                    </Text>
                </Box>
            </div>
            <div className={`${Style.tools_container}`}>
                <div className={`${Style.tools_text_container}`}>
                    <Heading fontSize={["1.5rem", "2.5rem"]} size={"2xl"} fontWeight={"bold"} lineHeight={["38px", "66px"]} mb={6}>
                        امکانات روبواپیکس
                    </Heading>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"semibold"} maxW={"346px"}>
                        سخت‌ترین چالش‌های هوش‌مصنوعی، جان‌سخت‌ترین پهلوانان علوم داده را طلب می‌کند.
                    </Text>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"346px"} mb={6}>
                        در پلتفرم روبواپیکس شرکت‌ها و سازمان‌ها، نیازهای تجاری و استخدامی خود را در قالب طیف وسیعی از مسابقات برون‌سپاری
                        می‌کنند تا متخصصین علوم داده دانش و مهارت خود را در ترازوی داده‌های واقعی محک بزنند تا:
                    </Text>
                    <List spacing={1} fontSize={["16px", "18px"]} fontWeight={"semibold"}>
                        <ListItem>
                            <span
                                className={`w-2 h-2 inline-flex rounded-full`}
                                style={{
                                    marginInlineEnd: "0.5rem",
                                    flexShrink: 0,
                                    backgroundColor: useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)"),
                                }}
                            />
                            رقابت کنند
                        </ListItem>
                        <ListItem>
                            <span
                                className={`w-2 h-2 inline-flex rounded-full`}
                                style={{
                                    marginInlineEnd: "0.5rem",
                                    flexShrink: 0,
                                    backgroundColor: useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)"),
                                }}
                            />
                            یاد بگیرند
                        </ListItem>
                        <ListItem>
                            <span
                                className={`w-2 h-2 inline-flex rounded-full`}
                                style={{
                                    marginInlineEnd: "0.5rem",
                                    flexShrink: 0,
                                    backgroundColor: useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)"),
                                }}
                            />
                            اعتبار کسب کنند
                        </ListItem>
                        <ListItem>
                            <span
                                className={`w-2 h-2 inline-flex rounded-full`}
                                style={{
                                    marginInlineEnd: "0.5rem",
                                    flexShrink: 0,
                                    backgroundColor: useColorModeValue("#595FA1", "var(--chakra-colors-RoboEpics-gold-300)"),
                                }}
                            />
                            برنده شوند
                        </ListItem>
                    </List>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        mt={8}
                        fontSize={"1rem"}
                        lineHeight={"25px"}
                        w="213px"
                        h="41px"
                        borderRadius={8}
                        onClick={() => {
                            router.push("/users/signup");
                        }}
                    >
                        برای شروع ثبت‌نام کنید
                    </Button>
                </div>
                <Image
                    src={"/images/chogan-2.png"}
                    width={467}
                    height={554}
                    quality={100}
                    onLoad={() => {
                        setLoadedImages((current) => current + 1);
                    }}
                    loading="eager"
                    objectFit="scale-down"
                />
            </div>
            <div className={`${Style.ways_container}`}>
                <Heading fontSize={"1.5rem"} size={"2xl"} fontWeight={"semibold"} lineHeight={"39px"} mb={12}>
                    چطور روبواپیکس در مسیر هوش‌ مصنوعی به شما کمک می‌کند:
                </Heading>
                <SimpleGrid className={`${Style.ways_grid}`}>
                    <div className={`${Style.ways_way_container}`}>
                        <Number_1 className={`${Style.ways_way_icon}`} size={32} />
                        {/* <Text
                            w={8}
                            h={8}
                            ml={4}
                            flexShrink={0}
                            textAlign={"center"}
                            fontSize={18}
                            fontWeight={"bold"}
                        >
                            1
                        </Text> */}
                        <Text fontSize={["16px", "18px"]}>
                            به <b>دیتاست‌</b>‌های بومی و واقعی شرکت‌های مختلف دسترسی خواهید داشت و مهارت‌های شما در حل چالش‌های واقعی بهبود
                            می‌یابد.
                        </Text>
                    </div>
                    <div className={`${Style.ways_way_container}`}>
                        <Number_2 className={`${Style.ways_way_icon}`} size={32} />

                        <Text fontSize={["16px", "18px"]}>
                            پروژه‌های واقعی هوش‌مصنوعی پیچیده‌تر از آن هستند که به تنهایی بتوان از پس آن‌ها برآمد؛ از این رو می‌توانید به
                            شکل <b>تیمی</b> روی چالش‌های بزرگ کار کنید.
                        </Text>
                    </div>
                    <div className={`${Style.ways_way_container}`}>
                        <Number_3 className={`${Style.ways_way_icon}`} size={32} />

                        <Text fontSize={["16px", "18px"]}>
                            نوت‌بوک‌های تخصصی بنویسید و آن را با بقیه به اشتراک بگذارید. مهارت‌های خود را به واسطه انتقال دادن و
                            <b> به اشتراک گذاشتن</b> با دیگران تقویت کنید.
                        </Text>
                    </div>
                    <div className={`${Style.ways_way_container}`}>
                        <Number_4 className={`${Style.ways_way_icon}`} size={32} />

                        <Text fontSize={["16px", "18px"]}>
                            در <b>رویداد</b>های مقاله‌خوانی تخصصی شرکت کنید و این مهارت مهم را برای به‌روزنگه‌داشتن خودتان به‌دست آورید.
                        </Text>
                    </div>
                </SimpleGrid>
            </div>
            <div className={`${Style.gathering_container}`}>
                <Img
                    src={"/images/korsisher-2.png"}
                    className={`${Style.gathering_image}`}
                    onLoad={() => {
                        setLoadedImages((current) => current + 1);
                    }}
                    onError={() => {
                        setLoadedImages((current) => current + 1);
                        console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                    }}
                    objectFit="scale-down"
                    loading="eager"
                />
                <div className={`${Style.gathering_text_container}`}>
                    <Heading fontSize={["1.5rem", "2.5rem"]} size={"2xl"} fontWeight={"bold"} lineHeight={["38px", "66px"]} mb={6}>
                        جایی برای گردهم آمدن
                    </Heading>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"}>
                        روبواپیکس داستان هم‌فکری، تعامل و قدرت‌نمایی دانشمندان علوم داده است.
                    </Text>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"}>
                        ما امروز بیش از هر چیز دیگر، نیاز به جامعه‌ای پویا داریم که در یک فضای امن و پشتیبان، دانش و تجربه‌ی خود را به
                        اشتراک بگذارند، راهنمایی کنند و راهنمایی شوند.
                    </Text>
                    <Text lineHeight={"28px"} fontSize={["16px", "18px"]} fontWeight={"normal"} maxW={"384px"} mb={10}>
                        جامعه‌ی روبواپیکس بر آن است تا با متحد کردن متخصصین علوم داده و هوش مصنوعی، قدرتمندتر از گذشته به نبرد با چالش‌ها
                        برود تا همه با هم در لبه‌ی فناوری‌های آینده بایستیم.
                    </Text>
                    <SimpleGrid columns={[1, 2]} w="full" spacingX={8} spacingY={4}>
                        <div className={`${Style.gathering_grid_item}`}>
                            <CheckmarkCircle color={useColorModeValue("black", "white")} height={"24px"} width={"24px"} />
                            <Text mb={0} mr={"18px"} fontWeight={"semibold"} fontSize={["16px", "18px"]} lineHeight={"28px"}>
                                فرصت‌های درون‌شبکه‌ای
                            </Text>
                        </div>
                        <div className={`${Style.gathering_grid_item}`}>
                            <CheckmarkCircle color={useColorModeValue("black", "white")} height={"24px"} width={"24px"} />
                            <Text mb={0} mr={"18px"} fontWeight={"semibold"} fontSize={["16px", "18px"]} lineHeight={"28px"}>
                                شبکه پشتیبانی
                            </Text>
                        </div>
                        <div className={`${Style.gathering_grid_item}`}>
                            <CheckmarkCircle color={useColorModeValue("black", "white")} height={"24px"} width={"24px"} />
                            <Text mb={0} mr={"18px"} fontWeight={"semibold"} fontSize={["16px", "18px"]} lineHeight={"28px"}>
                                کسب راهنمایی‌های معتبر
                            </Text>
                        </div>
                        <div className={`${Style.gathering_grid_item}`}>
                            <CheckmarkCircle color={useColorModeValue("black", "white")} height={"24px"} width={"24px"} />
                            <Text mb={0} mr={"18px"} fontWeight={"semibold"} fontSize={["16px", "18px"]} lineHeight={"28px"}>
                                فضای اشتراک دانش و تجربه
                            </Text>
                        </div>
                        <div className={`${Style.gathering_grid_item}`}>
                            <CheckmarkCircle color={useColorModeValue("black", "white")} height={"24px"} width={"24px"} />
                            <Text mb={0} mr={"18px"} fontWeight={"semibold"} fontSize={["16px", "18px"]} lineHeight={"28px"}>
                                فرصت‌های مشاوره دادن
                            </Text>
                        </div>
                    </SimpleGrid>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        mt={8}
                        fontSize={"1rem"}
                        lineHeight={"25px"}
                        w="141px"
                        h="41px"
                        borderRadius={8}
                        onClick={() => {
                            router.push("/users/signup");
                        }}
                    >
                        ثبت‌نام کنید
                    </Button>
                </div>
            </div>
            <div className={`${Style.other_tools_container}`}>
                <Heading fontSize={["1.5rem", "2.5rem"]} size={"2xl"} fontWeight={"bold"} lineHeight={["38px", "66px"]} mb={14}>
                    دیگر امکانات فوق‌العاده روبواپیکس
                </Heading>
                <SimpleGrid columns={[1, 2]} spacingX={[16, 12, 32]} spacingY={[8, 12]}>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <DeploymentPattern className={`${Style.other_tools_tool_icon}`} size={24} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                ابزارهای کاربردی برای شرکت‌کنندگان
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                به منظور افزایش سرعت توسعه و همکاری تیمی به شرکت‌کنندگان سرویس‌های <b>Gitlab</b> و<b> Google Colab</b> ارائه
                                می‌شود.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <ChartEvaluation className={`${Style.other_tools_tool_icon}`} size={24} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                ارزیابی شخصی‌سازی شده
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                امکان بارگذاری متریک اختصاصی برگزارکننده‌ها برای سنجش کدها به صورت سفارشی.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <Cpu size={24} className={`${Style.other_tools_tool_icon}`} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                اعمال محدودیت روی منابع پردازشی
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                با اختصاص منابع مشخص به هر شخص/کد برای اجرا، می‌توان شرکت‌کنندگان را ترغیب به ارائه راهکارها بهینه‌تر کرد.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <PodiumOutline
                            width={"24px"}
                            height={"24px"}
                            color={useColorModeValue("black", "white")}
                            cssClasses={`${Style.other_tools_tool_icon}`}
                        />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                رده‌بندی بلادرنگ
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                مشخص شدن رده‌بندی به صورت آنی و Real Time در لیدربردهای مسابقه وجود دارد.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <IbmCloudDedicatedHost className={`${Style.other_tools_tool_icon}`} size={24} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                میزبانی طیف وسیعی از مسابقات
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                می‌توانید چالش‌هایی خارج از چارچوب متعارف مسابقات تعریف کنید. همچنین امکان میزبانی چالش‌های{" "}
                                <b>Environment Based</b> نیز وجود دارد.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <VirtualDesktop className={`${Style.other_tools_tool_icon}`} size={24} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                دریافت و اجرای کد در فضای ابری
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                به جای تکیه بر جواب نهایی، با اجرای کد در فضای ابری می‌توان سنجش بهتری از عملکرد هر شخص به دست آورد. همچنین
                                امکان یافتن تقلب‌های احتمالی نیز فراهم شده است.
                            </Text>
                        </div>
                    </div>
                    <div className={`${Style.other_tools_tool_container}`}>
                        <GroupResource className={`${Style.other_tools_tool_icon}`} size={24} />
                        <div className={`${Style.other_tools_tool_text_container}`}>
                            <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"10px"}>
                                اختصاص آنی منابع
                            </Text>
                            <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                                در صورت نیاز و تایید برگزار کننده، می‌توان به برخی از کدها بر حسب نیاز منابع بیشتری برای اجرا اختصاص داد.
                            </Text>
                        </div>
                    </div>
                </SimpleGrid>
            </div>
            {/* {compData && (
                <div className={`${Style.contests_container}`}>
                    <Heading
                        fontSize={["1.5rem", "2.5rem"]}
                        size={"2xl"}
                        fontWeight={"bold"}
                        lineHeight={["38px", "66px"]}
                        mb={14}
                    >
                        آخرین مسابقه‌ها
                    </Heading>
                    <SimpleGrid className={`${Style.contests}`} spacing={[8, 12]}>
                        {compData.results.slice(0, 4).map((compInfo, index) => {
                            return (
                                <CompetitionCard
                                    data={compInfo}
                                    display="flex"
                                    key={`hp-comp-${index}`}
                                    setLoadedImages={setLoadedImages}
                                    index={index}
                                    router={props.router}
                                />
                            );
                        })}
                    </SimpleGrid>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                        mt={10}
                        fontSize={"1rem"}
                        lineHeight={"25px"}
                        w="141px"
                        h="41px"
                        borderRadius={8}
                        onClick={() => {
                            router.push("/c");
                        }}
                        rightIcon={<ChevronLeftIcon boxSize={5} />}
                        placeSelf={"flex-end"}
                    >
                        مشاهده بیشتر
                    </Button>
                </div>
            )} */}
        </div>
    );
};

/* const CompetitionCard = (props: {
    data: ICompetitionCardInfo;
    display: "none" | "flex";
    setLoadedImages: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    router: NextRouter;
}) => {
    return (
        <div className={`${useColorModeValue(Style.card, Style.dark_card)}`}>
            <Img
                src={getImageURL(props.data.cover_image) ?? "/images/bannerPlaceholder.png"}
                onLoad={() => {
                    props.setLoadedImages((current) => current + 1);
                }}
                className={`${Style.contest_image}`}
                objectFit={"cover"}
                onClick={() => {
                    props.router.push(`/c/${props.data.path}`);
                }}
                onError={() => {
                    props.setLoadedImages((current) => current + 1);
                    console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                }}
                _hover={{ cursor: "pointer" }}
            />

            <div className={`${Style.contest_data}`}>
                <Text
                    fontSize={"18px"}
                    fontWeight={"semibold"}
                    lineHeight={"28px"}
                    mb={"6px"}
                    isTruncated
                    className={`${Style.cd_title_container}`}
                >
                    {props.data.title}
                </Text>
                <Text
                    className={`${Style.cd_owner}`}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    color={"#5a5a5a"}
                    lineHeight={"25px"}
                    mb={2}
                    isTruncated
                    maxW={"300px"}
                >
                    {props.data.owner.full_name}
                </Text>
                <Text
                    className={`${Style.cd_subtitle}`}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    lineHeight={"25px"}
                    mb={3}
                    h="76px"
                    maxW="346px"
                >
                    {props.data.subtitle}
                </Text>
                <div className={`${Style.cd_footer}`}>
                    <div className={`${Style.cd_footer_right}`}>
                        <Text fontSize={"16px"} fontWeight={"normal"} color={"#5a5a5a"} lineHeight={"25px"} ml={4} mb={0}>
                            {getDatePhrase(props.data.date_published, "roboepics")}
                        </Text>
                        <Text fontSize={"16px"} fontWeight={"normal"} color={"#5a5a5a"} lineHeight={"25px"} mb={0}>
                            {props.data.total_participants} شرکت‌کننده
                        </Text>
                    </div>
                    <Button
                        fontSize={"1rem"}
                        lineHeight={"25px"}
                        w="84px"
                        h="41px"
                        borderRadius={8}
                        className={`${Style.cd_footer_left}`}
                        onClick={() => {
                            props.router.push(`/c/${props.data.path}`);
                        }}
                    >
                        مشاهده
                    </Button>
                </div>
            </div>
        </div>
    );
}; */
