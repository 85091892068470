import React, { useState, useRef } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { MiniUserProfile } from "@components";
import { useSelector } from "react-redux";
import { searchTeamForRequest } from "@util/api/competitions";
import { createRequestToJoin } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    Button,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Modal,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    ModalCloseButton,
    Box,
    Heading,
    IconButton,
    ModalBody,
    FormControl,
    Input,
    Text,
    InputLeftElement,
    InputRightElement,
    InputGroup,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td,
    Spinner,
} from "@chakra-ui/react";

import { SadOutline } from "react-ionicons";
import { X } from "react-feather";
import Style from "./join.module.css";
import { IToastOptions } from "@util/interfaces";
import { ICompetition } from "@redux/slices/competition";
import { NextRouter } from "next/router";

export const JoinModal = (props: {
    toast: IToastOptions;
    router: NextRouter;
    refreshFunction: (val: boolean) => Promise<void>;
    isOpen: boolean;
    isLoading: boolean;
    onJoinRequest: () => Promise<void>;
    onClose: () => void;
}) => {
    const searchInputEl = useRef(null);
    const inputDelayTimer = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<{
        id: number;
        team: {
            creator: { full_name: string; username: string };
            name: string;
            image: null | string;
        };
    }>(null);
    const [searchResult, setSearchResult] = useState<{
        results: Array<{
            id: number;
            team: {
                creator: { full_name: string; username: string; profile_picture: string };
                name: string;
                image: null | string;
            };
        }>;
        total: number;
    }>({ results: [], total: 0 });

    const competitionState = useSelector((state: { competition: ICompetition }) => state.competition);

    const onSearchInputChange = async (input: string) => {
        setSearchQuery(input);
        setIsLoading(false);
        clearTimeout(inputDelayTimer.current);
        if (input.length) {
            inputDelayTimer.current = setTimeout(async () => {
                search(input);
            }, 500);
        } else {
            setSearchResult({ total: 0, results: [] });
        }
    };

    const search = async (input: string) => {
        setIsLoading(true);

        const serverResponse = (await searchTeamForRequest(
            competitionState.path.toString(),
            { individual: 0, team_name: input },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const sr = await serverResponse.json();
            setSearchResult(sr);
        } else {
            setSearchResult({ results: [], total: 0 });
        }

        setIsLoading(false);
    };

    const sendJoinRequest = async () => {
        setConfirmDialogLoading(true);

        const serverResponse = (await createRequestToJoin(selectedTeam.id.toString(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            if (result.status === "Received") {
                props.toast({
                    description: `عملیات با موفقیت انجام شد`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                await props.refreshFunction(false);
                setShowConfirmDialog(false);
                props.onClose();
            }
        } else {
            props.toast({
                title: "مشکلی در ایجاد درخواست رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setConfirmDialogLoading(false);
    };

    return (
        <>
            <Modal closeOnOverlayClick isOpen={props.isOpen} onClose={props.onClose} size="2xl">
                <ModalOverlay />
                <ModalContent className={`${Style.modal_content}`} bg={useColorModeValue("#fff", "RoboEpics.black.900")}>
                    <ModalHeader>
                        <Heading size="md">ورود به تیم</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <InputGroup className="my-3">
                                <Input
                                    ref={searchInputEl}
                                    type="text"
                                    variant="filled"
                                    placeholder="نام تیم موردنظر را وارد کنید ..."
                                    value={searchQuery}
                                    onChange={(e) => {
                                        onSearchInputChange(e.target.value);
                                    }}
                                    id="team-name-input"
                                />
                                <InputLeftElement>
                                    <IconButton
                                        bg={"transparent"}
                                        variant="unstyled"
                                        icon={<SearchIcon color="gray" />}
                                        aria-label="search-teams"
                                        pointerEvents="none"
                                        height="32px"
                                        width="32px"
                                    />
                                </InputLeftElement>
                                <InputRightElement
                                    // sx={{
                                    //     height: "32px",
                                    //     width: "32px",
                                    //     paddingLeft: "6px",
                                    // }}
                                    children={
                                        isLoading ? (
                                            <Spinner size="sm" />
                                        ) : (
                                            searchQuery.length !== 0 && (
                                                <IconButton
                                                    sx={{
                                                        borderRadius: "999px",
                                                        height: "20px",
                                                        width: "20px",
                                                        minWidth: "20px",
                                                        padding: "2px",
                                                    }}
                                                    aria-label="Cancel search"
                                                    onClick={() => {
                                                        setSearchResult({ total: 0, results: [] });
                                                        setSearchQuery("");
                                                        searchInputEl.current.focus();
                                                    }}
                                                    icon={<X size={18} />}
                                                />
                                            )
                                        )
                                    }
                                />
                            </InputGroup>
                        </FormControl>
                        {searchResult.total === 0 ? (
                            searchQuery.length === 0 ? (
                                <></>
                            ) : (
                                <div className="flex flex-col items-center mx-auto mt-6 text-center">
                                    <SadOutline height="3em" width="3em" color={useColorModeValue("#000", "#fff")} />
                                    <Text mt={2}>نتیجه‌ای یافت نشد</Text>
                                </div>
                            )
                        ) : (
                            <Box overflowX="auto" overflowY="auto" maxH={56}>
                                <Table size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>نام تیم</Th>
                                            <Th minWidth="105px">سرگروه</Th>
                                            <Th></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {searchResult.results.map((team) => {
                                            return (
                                                <Tr>
                                                    <Td>
                                                        <div className="flex-row flex items-center justify-start">
                                                            <MiniUserProfile
                                                                size="sm"
                                                                fullName={team.team.name}
                                                                imageFilename={team.team.image}
                                                            />
                                                        </div>
                                                    </Td>
                                                    <Td fontSize="sm">
                                                        <MiniUserProfile
                                                            size="sm"
                                                            fullName={team.team.creator.full_name}
                                                            username={team.team.creator.username}
                                                            imageFilename={team.team.creator.profile_picture}
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedTeam(team);
                                                                setShowConfirmDialog(true);
                                                            }}
                                                            size="xs"
                                                            variant={useColorModeValue("solid", "outline")}
                                                            colorScheme={"RoboEpics.turquoise"}
                                                            fontWeight="300"
                                                            color={useColorModeValue("RoboEpics.black.default", "RoboEpics.turquoise.600")}
                                                        >
                                                            ارسال درخواست
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </Box>
                        )}
                        <Alert_SendRequestConfirm
                            isLoading={confirmDialogLoading}
                            isOpen={showConfirmDialog}
                            onClose={() => setShowConfirmDialog(false)}
                            teamName={selectedTeam ? selectedTeam.team.name : ""}
                            onSuccess={() => {
                                sendJoinRequest();
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

const Alert_SendRequestConfirm = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    teamName: string;
    onSuccess: () => void;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>ارسال دعوت</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>درخواست پیوستن به تیم «{props.teamName}» ارسال خواهد شد. آیا ادامه می‌دهید؟</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید و ارسال
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
