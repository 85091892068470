import { IServerSideContext } from "@util/interfaces";
import { fetchWithRefresh } from "./base";

export const retrieveCompetitionAnnouncements = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/announcements`, {}, "required", ctx);

export const createCompetitionAnnouncement = async (path: string, title: string, text: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/competition/${path}/announcements`,
        { method: "POST", body: JSON.stringify({ title: title, text: text }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const deleteCompetitionAnnouncement = async (path: string, id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/announcements/${id}`, { method: "DELETE" }, "required", ctx);

export const editCompetitionAnnouncement = async (path: string, id: number, title?: string, text?: string, ctx?: IServerSideContext) => {
    let reqData = {};
    if (text) reqData = Object.assign({ text: text }, reqData);
    if (title) reqData = Object.assign({ title: title }, reqData);
    return await fetchWithRefresh(
        `/competition/${path}/announcements/${id}`,
        { method: "PATCH", body: JSON.stringify(reqData), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );
};
