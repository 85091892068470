import React from "react";
import ReactMarkdown from "react-markdown";
import { useColorModeValue } from "@chakra-ui/react";
import cssClasses from "./RoboEpicsMarkdown.module.css";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import RemarkMathPlugin from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

export const RoboEpicsMarkdown = (props) => {
    const isStartingWithRTL = (text: string): boolean => {
        if (text) {
            const char = text.codePointAt(0);
            return (char >= 0x600 && char <= 0x6ff) || (char >= 0xfb50 && char <= 0xfdff) || (char >= 0xfe70 && char <= 0xfeff);
        }
        return false;
    };
    const isNodeRtl = (node): boolean => {
        const tagnames = ["a", "del", "code"];
        if (node.children?.length > 0) {
            const firstChild = node.children[0];
            if (firstChild.type === "text" && firstChild.value !== "" && firstChild.value !== "\n") {
                return isStartingWithRTL(firstChild.value);
            } else if (tagnames.includes(firstChild.tagName)) {
                return isStartingWithRTL(firstChild.children[0]?.value);
            } else if (node.tagName === "li") {
                return isNodeRtl(node.children[1]);
            }
            return false;
        }
        return false;
    };
    return (
        <React.Fragment>
            <ReactMarkdown
                skipHtml={false}
                remarkPlugins={[remarkGfm, RemarkMathPlugin]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                {...props}
                className={`${cssClasses["re-markdown-root"]} ${cssClasses[`re-markdown-${useColorModeValue("light", "dark")}`]}`}
                components={{
                    p: ({ node, ...props }) => {
                        return <p dir={isNodeRtl(node) ? "rtl" : "ltr"} {...props}></p>;
                    },
                    a: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <a dir={direction} {...props}></a>;
                    },
                    strong: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <strong className="w-full" dir={direction} {...props}></strong>;
                    },
                    em: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <em className="w-full" dir={direction} {...props}></em>;
                    },
                    del: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <del className="w-full" dir={direction} {...props}></del>;
                    },
                    h1: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h1 dir={direction} {...props}></h1>;
                    },
                    h2: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h2 dir={direction} {...props}></h2>;
                    },
                    h3: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h3 dir={direction} {...props}></h3>;
                    },
                    h4: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h4 dir={direction} {...props}></h4>;
                    },
                    h5: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h5 dir={direction} {...props}></h5>;
                    },
                    h6: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <h6 dir={direction} {...props}></h6>;
                    },

                    code: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return props.inline ? <code dir={direction} {...props}></code> : <code {...props}></code>;
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ul: ({ node, ordered, ...props }) => {
                        return <ul {...props} dir={isNodeRtl(node.children[1]) ? "rtl" : "ltr"}></ul>;
                    },
                    ol: ({ node, ...props }) => {
                        return <ol {...props} dir={isNodeRtl(node.children[1]) ? "rtl" : "ltr"}></ol>;
                    },
                    table: ({ ...props }) => {
                        return <table {...props} dir={"ltr"}></table>;
                    },
                    th: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <th dir={direction} {...props}></th>;
                    },
                    td: ({ node, ...props }) => {
                        const direction = isNodeRtl(node) ? "rtl" : "ltr";
                        return <td dir={direction} {...props}></td>;
                    },
                }}
            ></ReactMarkdown>
        </React.Fragment>
    );
};
