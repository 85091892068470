import React, { useRef, useState, useEffect } from "react";
import { NextRouter, useRouter } from "next/router";
import { ClientSidebar, OwnerSidebar } from "@components/competition";
import { MainContainer } from "@components";
import { getCookie, setCookie } from "@util/cookies";

import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Divider,
    useColorModeValue,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    ModalFooter,
    ListItem,
    UnorderedList,
    Spinner,
    Heading,
    Slide,
    IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { MenuOutline } from "react-ionicons";
import { ChevronsRight, X } from "react-feather";
import styleClasses from "./CompetitionViewHandler.module.css";
import { ICompData, IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";

export const CompetitionViewHandler = (props: {
    data: ICompData;
    currentViewObj: { label: string; key: string; icon: JSX.Element };
    currentViewComponent: JSX.Element;
    isOwner: boolean;
    isCollaborator: boolean;
    collaborationAccessLevel: 50 | 40; // 50 = full access, 40 = editor access
    isChallenge?: boolean;
    toast: IToastOptions;
    router: NextRouter;
    user: IUser;
    setHasChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const router = useRouter();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [showDiscordBanner, setShowDiscordBanner] = useState(false);

    const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
    const sidebarEl = useRef(null);
    const mobileOverlayEl = useRef(null);
    useEffect(() => {
        if (mobileSidebarVisible) {
            sidebarEl.current?.classList.add(styleClasses.sidebar_show_overlay);
            mobileOverlayEl.current?.classList.add(styleClasses.mobile_overlay_show);
        } else {
            sidebarEl.current?.classList.remove(styleClasses.sidebar_show_overlay);
            mobileOverlayEl.current?.classList.remove(styleClasses.mobile_overlay_show);
        }
    }, [mobileSidebarVisible]);

    useEffect(() => {
        const c = getCookie("re_hcbd");
        setShowDiscordBanner(c === "false");
    }, []);

    if (!props.data) {
        return (
            <Box className={`flex flex-col items-center mt-8 w-full`}>
                <Spinner />
            </Box>
        );
    }
    return (
        <Box className="h-full w-full flex flex-row justify-center">
            <Box
                className={`${styleClasses.sidebar_wrapper} min-h-full shadow-md border-l`}
                bg={useColorModeValue("#f7f7f8", "#0f1316")}
                borderColor={useColorModeValue("f0f0f0", "#232a33")}
                ref={sidebarEl}
            >
                <Box
                    className="sticky pt-6 overflow-y-auto h-full overflow-x-hidden flex justify-center "
                    style={{
                        position: "-webkit-sticky",
                        minHeight: "calc(100vh - 3.5em)",
                        maxHeight: "100vh",
                        height: "25vh",
                        top: "1.75em",
                    }}
                >
                    {props.isOwner || props.isCollaborator ? (
                        <OwnerSidebar
                            className="flex flex-col"
                            compData={props.data}
                            isOwner={props.isOwner}
                            isCollaborator={props.isCollaborator}
                            collaborationAccessLevel={props.collaborationAccessLevel}
                            currentTab={
                                !props.isChallenge
                                    ? {
                                          type: "competition",
                                          key: `${props.currentViewObj.key}`,
                                      }
                                    : {
                                          type: "problem",
                                          key: `${router.query.phase_id}.${router.query.problem}`,
                                      }
                            }
                            toggleView={
                                <div className={`${styleClasses.sidebar_toggle_button}`}>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        className="w-full"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        onClick={() => {
                                            setMobileSidebarVisible(false);
                                        }}
                                    >
                                        {/* بستن نوار گوشه */}
                                        <ChevronsRight size={18} />
                                        <span className="mr-1">بستن</span>
                                    </Button>
                                </div>
                            }
                            toast={props.toast}
                            router={props.router}
                            user={props.user}
                        />
                    ) : (
                        <ClientSidebar
                            className="flex flex-col"
                            compData={props.data}
                            isOwner={props.isOwner}
                            currentTab={
                                !props.isChallenge
                                    ? {
                                          type: "competition",
                                          key: `${props.currentViewObj.key}`,
                                      }
                                    : {
                                          type: "problem",
                                          key: `${router.query.phase_id}.${router.query.problem}`,
                                      }
                            }
                            toggleView={
                                <div className={`${styleClasses.sidebar_toggle_button}`}>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        className="w-full"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        onClick={() => {
                                            setMobileSidebarVisible(false);
                                        }}
                                    >
                                        {/* بستن نوار گوشه */}
                                        <ChevronsRight size={18} />
                                        <span className="mr-1">بستن</span>
                                    </Button>
                                </div>
                            }
                            toast={props.toast}
                            router={props.router}
                            user={props.user}
                            setNeedsReload={props.setHasChanged}
                        />
                    )}
                </Box>
            </Box>
            <Box
                ref={mobileOverlayEl}
                className={`${styleClasses.mobile_overlay}`}
                onClick={() => {
                    setMobileSidebarVisible(false);
                }}
            />
            <Box className={`pt-6 pb-10 mx-4 h-full w-full flex-grow ${styleClasses.content_wrapper}`}>
                {/*Only show the banner for Torob Data Challenge*/}
                {props.data.id === 2 && (
                    <Slide
                        direction="top"
                        in={showDiscordBanner}
                        style={{ zIndex: 10, position: showDiscordBanner ? "sticky" : "fixed", marginTop: "-1rem" }}
                    >
                        <>
                            <Box
                                as="a"
                                target="_blank"
                                href="https://discord.gg/8DMfjmn6gc"
                                p={4}
                                color="white"
                                bg="#404EED"
                                rounded="md"
                                shadow="dark-lg"
                                display={"flex"}
                                flexDirection={["column", "row"]}
                                justifyContent="space-between"
                                alignItems={"center"}
                                onClick={() => {
                                    setShowDiscordBanner(false);
                                    setCookie("re_hcbd", "true", "session", "strict");
                                }}
                            >
                                <Text mb={0} mx="auto" fontSize="lg">
                                    اگر هنوز عضو سرور دیسکورد روبواپیکس نیستید با کلیک روی این بنر حتماً به ما در دیسکورد بپیوندید!{" "}
                                </Text>
                            </Box>
                            <IconButton
                                aria-label="close"
                                colorScheme={useColorModeValue("blackAlpha", "whiteAlpha")}
                                icon={<X color="white" />}
                                size="sm"
                                variant={"ghost"}
                                zIndex={20}
                                onClick={() => {
                                    setShowDiscordBanner(false);
                                    setCookie("re_hcbd", "true", "session", "strict");
                                }}
                                position="absolute"
                                top={"25%"}
                                right={4}
                            />
                        </>
                    </Slide>
                )}
                <MainContainer>
                    <Box className="flex justify-start items-center">
                        <div
                            style={{
                                height: "25px",
                            }}
                            className={`flex items-center ${styleClasses.sidebar_toggle_in_content}`}
                        >
                            <button className="p-1">
                                <MenuOutline
                                    height="20px"
                                    width="20px"
                                    color="gray.400"
                                    onClick={() => {
                                        setMobileSidebarVisible(true);
                                    }}
                                />
                            </button>
                            <Divider
                                orientation="vertical"
                                className="ml-2 mr-1 inline-block"
                                borderBottomColor={useColorModeValue("RoboEpics.black.400", "RoboEpics.black.600")}
                            />
                        </div>
                        {/* <IconButton
                            ml={2}
                            variant="ghost"
                            aria-label="back-button"
                            icon={
                                <ArrowForwardOutline
                                    color={useColorModeValue("#000", "#fff")}
                                    // height="22px"
                                    // width="22px"
                                />
                            }
                            onClick={() => {
                                router.push("/c");
                            }}
                        /> */}
                        {props.isChallenge ? (
                            <Breadcrumb
                                isTruncated
                                spacing="8px"
                                separator={<ChevronLeftIcon color="gray.500" />}
                                aria-label="breadcrumb"
                                sx={{ overflowX: "auto" }}
                            >
                                <BreadcrumbItem>
                                    <BreadcrumbLink href={`/c/${props.data.path}`}>{props.data.title}</BreadcrumbLink>
                                </BreadcrumbItem>
                                {props.isChallenge && (
                                    <BreadcrumbItem>
                                        <BreadcrumbLink className="mb-0 cursor-default" href="#">
                                            سوال‌ها
                                        </BreadcrumbLink>
                                    </BreadcrumbItem>
                                )}
                                <BreadcrumbItem>
                                    <BreadcrumbLink href="#">{props.currentViewObj.label}</BreadcrumbLink>
                                </BreadcrumbItem>
                            </Breadcrumb>
                        ) : (
                            <Heading as="h1" size="lg" mb={0} isTruncated>
                                {props.data.title}
                            </Heading>
                        )}
                        {props.currentViewObj.key === "team" && !(props.isOwner || props.isCollaborator) && (
                            <Box mr="auto">
                                <Button variant="ghost" size="sm" colorScheme="blue" onClick={onOpen}>
                                    راهنمایی درباره این صفحه
                                </Button>
                                <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size="lg">
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>درباره صفحه تیم</ModalHeader>
                                        {/* <ModalCloseButton /> */}
                                        <ModalBody>
                                            <Text>
                                                در صفحه تیم، شما نحوه شرکت خود در مسابقه را تعیین می‌کنید. همه افراد پس از ثبت‌نام در
                                                مسابقه، در حالت پیش‌فرض به صورت انفرادی در مسابقه شرکت می‌کنند و در صورتی که تمایل داشته
                                                باشید به صورت گروهی در مسابقه شرکت کنید، می‌توانید از طریق این صفحه تیم خود را بسازید و یا
                                                به تیم‌هایی که توسط سایر افراد تشکیل شده بپیوندید. همچنین افرادی که برای خود تیم تشکیل
                                                داده‌اند می‌توانند برای دیگران دعوت ارسال کنند تا آن افراد وارد تیم‌شان شوند.
                                            </Text>
                                            <Text>در ادامه ذکر چند نکته حائز اهمیت خواهد بود:</Text>
                                            <UnorderedList fontSize="14px">
                                                <ListItem>
                                                    تمامی امور مربوط به تیم‌ها شامل ساخت تیم جدید، پیوستن به یک تیم، فرستادن دعوت به افراد،
                                                    اخراج عضو از تیم، حذف تیم و ترک تیم، همگی تنها تا قبل از آغاز اولین چالش مسابقه فعال
                                                    هستند و در حین باز و فعال بودن هر یک از چالش‌های مسابقه، امکان اعمال تغییر در ساختار
                                                    تیم‌ها و یا نحوه شرکت افراد به هیچ وجه وجود ندارد.
                                                </ListItem>
                                                <ListItem>
                                                    امکان دعوت افراد و یا پذیرفتن درخواست افراد برای پیوستن به تیم تنها برای سرگروه (سازنده)
                                                    تیم وجود دارد.
                                                </ListItem>
                                                <ListItem>
                                                    امکان حذف تیم فقط زمانی فراهم می‌شود که تیم فقط شامل سرگروه باشد و عضو دیگری در آن تیم
                                                    حضور نداشته باشد.
                                                </ListItem>
                                            </UnorderedList>
                                            <br />
                                            <Text>
                                                در صورت بروز هرگونه مشکل می‌توانید از طریق این صفحه با پشتیبانی روبواپیکس در ارتباط باشید.
                                            </Text>
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                                                بستن
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </Box>
                        )}
                    </Box>

                    <Divider
                        orientation="horizontal"
                        className="my-4"
                        borderBottomColor={useColorModeValue("RoboEpics.black.400", "RoboEpics.black.600")}
                    />
                    {props.currentViewComponent}
                </MainContainer>
            </Box>
        </Box>
    );
};
