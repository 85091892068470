import React, { useCallback, useEffect, useMemo, useState } from "react";

//other imports
import { useColorModeValue } from "@chakra-ui/react";
import { SimpleMDEReactProps, SimpleMdeReact, SimpleMdeToCodemirrorEvents } from "react-simplemde-editor";
import dynamic from "next/dynamic";
import { KeyMap, Editor, Position } from "codemirror";

//CSS MODULES IMPORTS
import "prismjs/themes/prism.css";
import "easymde/dist/easymde.min.css";
import Style from "./index.module.css";

// using simpleMde
const SimpleMde = dynamic(() => import("react-simplemde-editor"), {
    ssr: false,
});
export const RoboepicsSimpleMde = (props: {
    value?: string;
    setValue: (value: string) => void;
    setContentType: (type: 10 | 20 | 30 | 40) => void;
}) => {
    // const extraKeys = useMemo<KeyMap>(() => {
    //     return {
    //         Up: function (cm) {
    //             console.log(cm.lineInfo(0), cm.getCursor("head"));
    //             cm.addLineClass(0, "wrap", "RoboepicsSimpleMde");
    //             cm.replaceSelection(" surprise. ");
    //         },
    //         Down: function (cm) {
    //             console.log(cm);
    //             cm.replaceSelection(" surprise again! ");
    //         },
    //     };
    // }, []);
    // simple mde
    // const [simpleMdeInstance, setMdeInstance] = useState<SimpleMDE | null>(null);

    // const getMdeInstanceCallback = useCallback((simpleMde: SimpleMDE) => {
    //     setMdeInstance(simpleMde);
    // }, []);

    // useEffect(() => {
    //     simpleMdeInstance && console.info("Hey I'm editor instance!", simpleMdeInstance);
    // }, [simpleMdeInstance]);

    // codemirror
    const [codemirrorInstance, setCodemirrorInstance] = useState<Editor | null>(null);
    const getCmInstanceCallback = useCallback((editor: Editor) => {
        setCodemirrorInstance(editor);
    }, []);

    // useEffect(() => {
    //     codemirrorInstance && console.info("Hey I'm codemirror instance!", codemirrorInstance);
    // }, [codemirrorInstance]);

    // line and cursor
    const [lineAndCursor, setLineAndCursor] = useState<Position | null>({ ch: 0, line: 0, sticky: null });

    const getLineAndCursorCallback = useCallback((position: Position) => {
        setLineAndCursor(position);
    }, []);

    // const events = useMemo(() => {
    //     return {
    //         keyHandled: (codemirrorInstance,"Ctrl-Shift",)
    //     } as SimpleMdeToCodemirrorEvents;
    // }, []);
    const options = useMemo(() => {
        return {
            direction: "ltr",
            spellChecker: false,
            autofocus: true,
            showIcons: ["table", "strikethrough", "horizontal-rule", "code"],
            hideIcons: ["side-by-side", "preview"],
            status: false,
            toolbar: [
                "bold",
                "italic",
                "strikethrough",
                "heading",
                "|",
                "code",
                "quote",
                "unordered-list",
                "ordered-list",
                "|",
                "link",
                "image",
                "table",
                "horizontal-rule",
                "|",
                "guide",

                // {
                //     name: "paragraph_direction",
                //     action: (editor) => {
                //         console.log(editor.codemirror.lineInfo(lineAndCursor.line));
                //         // editor.codemirror.class;
                //         document.getElementsByClassName("paragraph_direction")[0].className += " active";
                //         editor.codemirror.addLineClass(lineAndCursor.line, "wrap", "CodeMirror-rtl");
                //         editor.codemirror.focus();
                //     },
                //     className: "fa fa-paragraph",
                //     title: "Change paragraph direction",
                // },
            ],
        } as SimpleMDEReactProps["options"];
    }, []);
    return (
        <SimpleMde
            className={`w-full ${useColorModeValue(Style.Editor, Style.DarkModeEditor)} ${useColorModeValue("Editor", "DarkModeEditor")}`}
            options={options}
            value={props.value ? props.value : ""}
            onChange={(value) => {
                props.setValue(value);
                props.setContentType(20);
            }}
            // extraKeys={extraKeys}
            getLineAndCursor={getLineAndCursorCallback}
            // events={}
        />
    );
};
