import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCompetitionId, setCompetitionPath } from "@redux/actions";
import { Box, Text, useColorModeValue, Button, Collapse, Tag, Tooltip } from "@chakra-ui/react";
import {
    CloudUploadOutline,
    CalendarOutline,
    FlagOutline,
    PersonOutline,
    TrophyOutline,
    BulbOutline,
    TimeOutline,
    Image,
    ImageOutline,
    LogoDiscord,
} from "react-ionicons";
import MenuBranch from "./MenuBranch";
import MenuLink from "./MenuLink";
import MenuSection from "./MenuSection";
import MetadataBlock from "./MetadataBlock";
import { ArrowDown, ArrowUp, Plus } from "react-feather";
import styles from "./CompSidebar.module.css";
import { CustomAvatar } from "@components/avatar";
import { COMPETITION_STATUS, OWNER_COMPETITION_TABS } from "@util/constants";
import { getDateDifferenceMs } from "@util/dateConverter";
import { retrieveCompetitionPhases } from "@util/api/competitions";
import { NewProblemModal } from "./NewProblemModal";
import { ICompSidebarProps } from "@util/interfaces";
import { NewPhaseModal } from "./NewPhaseModal";
import { NewSingleProblemModal } from "./NewSingleProblemModal";
import { ICompetition } from "@redux/slices/competition";
import { getImageURL } from "@util/mediaLoader";

export const OwnerCompSidebar = (props: ICompSidebarProps) => {
    const init_phase_progress_restrictions = {};
    for (const phase of props.compData.phase_set) {
        init_phase_progress_restrictions[phase["id"]] = false;
    }
    // Redux
    const dispatch = useDispatch();
    const competitionState = useSelector((state: { competition: ICompetition }) => state.competition);

    // States
    const [newPhases, setNewPhases] = useState(props.compData.phase_set);
    const [newCompDifficulty, setNewCompDifficulty] = useState<"simple" | "advanced">(props.compData.difficulty);
    const [challengeSection, setChallengeSection] = useState<Array<JSX.Element | Element>>([<></>]);

    const [newProblemModalVisible, setNewProblemModalVisible] = useState(false);
    const [newProblemModalData, setNewProblemModalData] = useState({
        phaseId: 0,
        competition: props.compData.path,
    });
    const [newSingleProblemModalVisible, setNewSingleProblemModalVisible] = useState(false);
    const [newSingleProblemModalData, setNewSingleProblemModalData] = useState({
        phaseId: 0,
        competition: props.compData.path,
    });
    const [generalInfoExpanded, setGeneralInfoExpanded] = useState(
        Array.from(["organizers", "settings", "checklist"]).includes(props.currentTab.key) == false &&
            props.currentTab.type == "competition",
    );
    const [newPhaseModalVisible, setNewPhaseModalVisible] = useState(false);
    const [addConfirmed, setAddConfirmed] = useState(false);
    const [editConfirmed, setEditConfirmed] = useState(false);

    // Functions
    const onSetNewPhases = async () => {
        const serverResponse = (await retrieveCompetitionPhases(props.compData.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const phases = await serverResponse.json();
            setNewPhases(phases);
            if (phases.length === 1 && phases[0].problems.length === 1) {
                setNewCompDifficulty("simple");
            } else {
                setNewCompDifficulty("advanced");
            }
        } else {
            props.toast({
                title: "مشکلی در دریافت اطلاعات مراحل رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setAddConfirmed(false);
        setEditConfirmed(false);
    };

    // UseEffects

    useEffect(() => {
        // Updating Redux state for competition
        if (props.compData.id !== competitionState.id) {
            dispatch(setCompetitionId(props.compData.id)); // FIXME do we still need this or path is enough?
            dispatch(setCompetitionPath(props.compData.path));
        }

        const _challengeSection: Array<Element | JSX.Element> = [];
        if (newCompDifficulty === "simple") {
            const problemObj = newPhases[0]["problems"][0];
            // const problemOwner = problemObj["problem"]["owner"] === user_id;
            const timeDiff = getDateDifferenceMs(newPhases[0].submission_date_start);

            // const disabled =
            //     problemOwner ||
            //     props.isOwner ||
            //     props.compData.access_level !== 30
            //         ? false
            //         : timeDiff < 0;

            _challengeSection.push(
                <>
                    <MenuLink
                        key={`${newPhases[0]["id"]}-${problemObj["problem"]["id"]}`}
                        link={`/c/${props.compData.path}/p/${newPhases[0]["id"]}/p/${problemObj["problem"]["path"]}`}
                        isSelected={props.currentTab.type === "problem"}
                        icon={timeDiff < 0 ? <TimeOutline /> : <BulbOutline />}
                        text={problemObj["problem"]["title"]}
                        isDisabled={false}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        collaborationAccessLevel={props.collaborationAccessLevel}
                        isProblem={true}
                        phaseId={newPhases[0]["id"]}
                        problemId={problemObj["problem"]["id"]}
                        problemPath={problemObj["problem"]["path"]}
                        setEditConfirmed={setEditConfirmed}
                        toast={props.toast}
                        router={props.router}
                    />
                </>,
            );
        } else {
            // Calculating locked phase index
            newPhases.forEach((phase, index: number) => {
                const timeDiff = getDateDifferenceMs(phase.submission_date_start);
                _challengeSection.push(
                    <MenuBranch
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        collaborationAccessLevel={props.collaborationAccessLevel}
                        key={"phase-" + index.toString() + "-owner"}
                        isExpanded={props.currentTab.type === "problem" && props.currentTab.key.split(".")[0] === phase["id"].toString()}
                        icon={timeDiff < 0 ? <TimeOutline /> : <FlagOutline />}
                        text={phase.title}
                        phaseId={phase["id"]}
                        setModalData={setNewSingleProblemModalData}
                        setModalOpen={setNewSingleProblemModalVisible}
                    >
                        {phase.problems.map((problemObj) => {
                            // const problemOwner =
                            //     problemObj["problem"]["owner"] === user_id;
                            // const disabled =
                            //     problemOwner ||
                            //     props.isOwner ||
                            //     props.compData.access_level !== 30
                            //         ? false
                            //         : getDateDifferenceMs(
                            //               phase.submission_date_start,
                            //           ) < 0;
                            return (
                                <MenuLink
                                    key={`${phase["id"]}-${problemObj["problem"]["id"]}`}
                                    link={`/c/${props.compData.path}/p/${phase["id"]}/p/${problemObj["problem"]["path"]}`}
                                    isSelected={
                                        props.currentTab.type === "problem" &&
                                        props.currentTab.key.split(".")[0] === phase["id"].toString() &&
                                        props.currentTab.key.split(".")[1].toLowerCase() ===
                                            problemObj["problem"]["path"].toString().toLowerCase()
                                    }
                                    icon={"dot"}
                                    text={problemObj["problem"]["title"]}
                                    isDisabled={false}
                                    isOwner={props.isOwner}
                                    isCollaborator={props.isCollaborator}
                                    collaborationAccessLevel={props.collaborationAccessLevel}
                                    isProblem={true}
                                    phaseId={phase["id"]}
                                    problemId={problemObj["problem"]["id"]}
                                    problemPath={problemObj["problem"]["path"]}
                                    setEditConfirmed={setEditConfirmed}
                                    toast={props.toast}
                                    router={props.router}
                                />
                            );
                        })}
                    </MenuBranch>,
                );
            });
        }
        setChallengeSection(_challengeSection);
    }, [newPhases]);

    useEffect(() => {
        if (addConfirmed) onSetNewPhases();
    }, [addConfirmed]);

    useEffect(() => {
        if (editConfirmed) onSetNewPhases();
    }, [editConfirmed]);

    useEffect(() => {
        setNewPhases(props.compData.phase_set);
    }, [props.compData]);

    return (
        <aside className={`${props.className}`} style={props.style}>
            <Box className={`flex flex-col items-center justify-center ${styles.sidebar_wrapper} w-full pb-8`}>
                {/* Sidebar Toggle */}
                {props.toggleView}
                {/* Header */}
                <Box
                    className="flex flex-row justify-start self-start items-center mb-6"
                    style={{
                        marginInlineEnd: "-.75rem",
                    }}
                >
                    {/* <Avatar size="md" /> */}
                    <CustomAvatar
                        src={getImageURL(props.compData.thumbnail)}
                        className="flex-shrink-0"
                        noBorder={!!props.compData.thumbnail}
                        showPlaceholder={!props.compData.thumbnail}
                        placeholder={useColorModeValue(<ImageOutline color={"black"} />, <Image color={"white"} />)}
                    />
                    <Text fontWeight="800" className="mr-2 mb-0">
                        {props.compData.title}
                    </Text>
                </Box>

                {/* Metadata */}
                <Box className="w-full">
                    <Box className="w-full flex flex-row justify-between items-center mb-3">
                        <MetadataBlock icon={<PersonOutline />} text={props.compData.total_participants} tooltip="شرکت‌کنندگان" />
                        <MetadataBlock icon={<CloudUploadOutline />} text={props.compData.total_submissions} tooltip="تعداد ارسال‌ها" />
                        <MetadataBlock icon={<CalendarOutline />} text={props.compData.date_published} tooltip="تاریخ انتشار" />
                    </Box>
                    <Box>
                        <MetadataBlock icon={<TrophyOutline />} text={props.compData.prize} tooltip="جایزه مسابقه" />
                    </Box>
                </Box>

                {/* Publish Status */}
                <Box className="w-full mt-12">
                    <Tooltip label={"وضعیت مسابقه"}>
                        <Tag
                            colorScheme={COMPETITION_STATUS[props.compData.status].color}
                            key={COMPETITION_STATUS[props.compData.status].key}
                            size="md"
                            w="full"
                            justifyContent="center"
                        >
                            {COMPETITION_STATUS[props.compData.status].label}
                        </Tag>
                    </Tooltip>
                </Box>

                {/* Discord */}
                <Box className="mt-8 w-full grid">
                    <a href="https://discord.gg/8DMfjmn6gc" target="_blank">
                        <Button
                            variant="ghost"
                            px={3}
                            w="inherit"
                            className={`flex items-center rounded-md`}
                            style={{
                                width: "100%",
                            }}
                            color="#fff"
                            bg="#5865F2"
                            _hover={{ bg: "#404EED" }}
                            _active={{ bg: "#7289DA" }}
                        >
                            <LogoDiscord
                                cssClasses="ml-2 flex-shrink-0"
                                color={useColorModeValue("#fff", "#fff")}
                                width="20px"
                                height="20px"
                            />
                            <span
                                className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                                style={{ textOverflow: "ellipsis" }}
                            >
                                دیسکورد
                            </span>
                        </Button>
                    </a>
                </Box>

                {/* Menu */}
                <Box className="w-full">
                    <Collapse in={generalInfoExpanded} startingHeight={202} style={{ padding: "0 14px" }}>
                        <MenuSection key="competition-management-sidebar" noMargin={false} title="مدیریت مسابقه">
                            {Object.keys(OWNER_COMPETITION_TABS).map((key) => {
                                const el = OWNER_COMPETITION_TABS[key];
                                const disabled = false;
                                return (
                                    <MenuLink
                                        isDisabled={disabled}
                                        key={el["key"]}
                                        linkKey={el["key"]}
                                        isSelected={props.currentTab.key === el["key"]}
                                        icon={el["icon"]}
                                        text={el["label"]}
                                        link={
                                            el["key"] === "discussion"
                                                ? props.compData.discussion_url
                                                    ? props.compData.discussion_url
                                                    : `/c/${props.compData.path}/settings`
                                                : `/c/${props.compData.path}/${el["key"]}`
                                        }
                                        isOwner={props.isOwner}
                                        isCollaborator={props.isCollaborator}
                                        collaborationAccessLevel={props.collaborationAccessLevel}
                                        isProblem={false}
                                        toast={props.toast}
                                        router={props.router}
                                    />
                                );
                            })}
                        </MenuSection>
                    </Collapse>

                    <Button
                        size="sm"
                        mt={2}
                        leftIcon={generalInfoExpanded ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
                        variant="ghost"
                        w="full"
                        alignSelf="center"
                        onClick={() => {
                            setGeneralInfoExpanded(!generalInfoExpanded);
                        }}
                    >
                        {generalInfoExpanded ? "مشاهده کمتر" : "مشاهده بیشتر"}
                    </Button>

                    <MenuSection
                        key="phases-sidebar-owner"
                        title="سوال‌ها"
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        collaborationAccessLevel={props.collaborationAccessLevel}
                        compType={props.compData.difficulty}
                        editable
                        phaseId={props.compData.difficulty === "simple" ? props.compData.phase_set[0]["id"] : null}
                        setModalData={setNewSingleProblemModalData}
                        setModalOpen={setNewSingleProblemModalVisible}
                    >
                        {newPhases.length ? (
                            <React.Fragment key="challenge-section">
                                <div className="mb-6 w-full">{challengeSection}</div>
                                <Button
                                    size="sm"
                                    leftIcon={<Plus size={16} />}
                                    w="full"
                                    alignSelf="center"
                                    variant="outline"
                                    colorScheme={"RoboEpics.azure"}
                                    onClick={() => {
                                        setNewPhaseModalVisible(true);
                                    }}
                                    mb={2}
                                >
                                    اضافه کردن فاز
                                </Button>
                            </React.Fragment>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    leftIcon={<Plus size={16} />}
                                    w="full"
                                    alignSelf="center"
                                    variant="outline"
                                    colorScheme={"RoboEpics.azure"}
                                    onClick={() => {
                                        setNewPhaseModalVisible(true);
                                    }}
                                >
                                    اضافه کردن فاز
                                </Button>
                                <span className="w-full text-center">یا</span>
                                <Button
                                    size="sm"
                                    leftIcon={<Plus size={16} />}
                                    w="full"
                                    alignSelf="center"
                                    variant="outline"
                                    colorScheme={"RoboEpics.azure"}
                                    onClick={() => {
                                        setNewProblemModalVisible(true);
                                    }}
                                >
                                    اضافه کردن چالش
                                </Button>
                            </>
                        )}
                    </MenuSection>
                </Box>

                <NewProblemModal
                    isOpen={newProblemModalVisible}
                    onClose={() => {
                        setNewProblemModalData({
                            phaseId: 0,
                            competition: props.compData.path,
                        });
                        setNewProblemModalVisible(false);
                    }}
                    data={newProblemModalData}
                    setAddConfirmed={setAddConfirmed}
                    toast={props.toast}
                    router={props.router}
                />

                <NewPhaseModal
                    isOpen={newPhaseModalVisible}
                    onClose={() => {
                        setNewPhaseModalVisible(false);
                    }}
                    competiton={props.compData.path}
                    setAddConfirmed={setAddConfirmed}
                    toast={props.toast}
                    router={props.router}
                />

                <NewSingleProblemModal
                    isOpen={newSingleProblemModalVisible}
                    onClose={() => {
                        setNewSingleProblemModalVisible(false);
                        setNewSingleProblemModalData({
                            phaseId: 0,
                            competition: props.compData.path,
                        });
                    }}
                    data={newSingleProblemModalData}
                    setAddConfirmed={setAddConfirmed}
                    toast={props.toast}
                    router={props.router}
                />
            </Box>
        </aside>
    );
};
