import React, { useEffect, useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { NextRouter } from "next/router";
import { editCompetitionFormdata, editCompetitionJson } from "@util/api/competitions";
import CropModal, { b64toBlob } from "@util/CropModal";
import { Edit2 } from "react-feather";
import { AddOutline, AlertCircleOutline, Trash } from "react-ionicons";
import { useDropzone } from "react-dropzone";
import { CustomList, RoboEpicsMarkdown, RoboepicsSimpleMde } from "@components";
import JsxParser from "react-jsx-parser";
import Notebook from "@util/renderers/notebook";
import TeX from "@matejmazur/react-katex";
import RoboEpicsFormRenderer from "@util/renderers/form";
import { default as NextImage } from "next/image";

import Style from "./compOwner.module.css";
import { IToastOptions } from "@util/interfaces";
import { getImageURL } from "@util/mediaLoader";

export const OwnerOverview = (props: {
    description: string;
    coverImage: string | null;
    toast: IToastOptions;
    router: NextRouter;
    contentType: 10 | 20 | 30 | 40;
}) => {
    const [coverImage, setCoverImage] = useState(getImageURL(props.coverImage));
    const [contentRaw, setContentRaw] = useState(props.contentType === 10 ? props.description : "");
    const [contentMd, setContentMd] = useState(props.contentType === 20 ? props.description : "");
    const [contentHTML, setContentHTML] = useState(props.contentType === 30 ? props.description : "");
    const [contentNb, setContentNb] = useState(props.contentType === 40 ? props.description : "");
    const [contentType, setContentType] = useState(props.contentType);
    const [notebook, setNotebook] = useState<File>(null);
    const [editMode, setEditMode] = useState(false);
    const [editConfirmed, setEditConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const onDrop = (acceptedFile) => {
        const file = acceptedFile?.[0];
        if (!file) {
            return;
        }
        try {
            setNotebook(file);
        } catch (e) {
            console.log(e);
        }
    };

    const { getInputProps, getRootProps } = useDropzone({
        onDrop,
    });

    useEffect(() => {
        if (!editConfirmed) {
            setContentRaw(props.contentType === 10 ? props.description : "");
            setContentMd(props.contentType === 20 ? props.description : "");
            setContentHTML(props.contentType === 30 ? props.description : "");
            setContentNb(props.contentType === 40 ? props.description : "");
            setContentType(props.contentType);
            setNotebook(null);
        }
    }, [editMode]);
    useEffect(() => {
        if (notebook) getNotebookText(notebook);
    }, [notebook]);

    const getNotebookText = async (notebook: File) => {
        const text = await notebook.text();
        setContentNb(text);
    };

    const getContentChanger = (contentType: 10 | 20 | 30 | 40 | 50) => {
        switch (contentType) {
            case 10:
                return (
                    <Textarea
                        width="full"
                        value={contentRaw}
                        onChange={(e) => {
                            setContentRaw(e.target.value);
                        }}
                        h={64}
                    />
                );
            case 20:
                return <RoboepicsSimpleMde setContentType={setContentType} setValue={setContentMd} value={contentMd} />;
            case 30:
                return (
                    <Text py={8} textAlign="center" fontSize="lg">
                        Coming Soon!
                    </Text>
                );
            case 40:
                return (
                    <InputGroup>
                        <InputRightElement w={28}>
                            <Button {...getRootProps()} size="sm" h={"1.7rem"}>
                                انتخاب فایل
                                <input {...getInputProps()} />
                            </Button>
                        </InputRightElement>
                        <Input
                            isReadOnly
                            pl={28}
                            variant="filled"
                            value={notebook?.name}
                            type="text"
                            placeholder="فایل نوت‌بوک خود را انتخاب کنید"
                        />
                    </InputGroup>
                );
            default:
                return <></>;
        }
    };

    const getContentView = (type: 10 | 20 | 30 | 40 | 50 | string, content: string) => {
        switch (type) {
            case 10:
                return <span>{content}</span>;
            case 20:
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case 40:
                return content ? <Notebook notebook={content} /> : <></>;
            case 30:
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case 50:
                return <RoboEpicsFormRenderer canView shouldRender isLoading={false} form={JSON.parse(content.toString())} />;
        }
    };

    const getContent = (contentRaw: string, contentMd: string, contentHTML: string, contentNb: string) => {
        switch (contentType) {
            case 10:
                return contentRaw;
            case 20:
                return contentMd;
            case 30:
                return contentHTML;
            case 40:
                return contentNb;
            default:
                break;
        }
    };

    const onConfirm = async () => {
        setIsLoading(true);
        const content = getContent(contentRaw, contentMd, contentHTML, contentNb);
        const req_data = { content_type: contentType, description: content };
        const serverResponse = (await editCompetitionJson(props.router.query.competition, req_data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            setEditMode(false);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setEditConfirmed(serverResponse.ok);
        setContentType(contentType);
        setContentRaw(contentType === 10 ? (serverResponse.ok ? content : props.description) : "");
        setContentMd(contentType === 20 ? (serverResponse.ok ? content : props.description) : "");
        setContentHTML(contentType === 30 ? (serverResponse.ok ? content : props.description) : "");
        setContentNb(contentType === 40 ? (serverResponse.ok ? content : props.description) : "");

        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                p: { lineHeight: "24px" },
            }}
            className={`h-full`}
        >
            <CoverImageChange coverImage={coverImage} setCoverImage={setCoverImage} router={props.router} toast={props.toast} />
            <Box className={`w-full p-4 mt-4 border flex flex-col`}>
                <VStack spacing={6} w="full">
                    <Tabs variant="enclosed" w="full">
                        <TabList>
                            <Tab>نگارش</Tab>
                            <Tab>پیش‌نمایش</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <VStack spacing={4}>
                                    <RadioGroup
                                        alignSelf="flex-start"
                                        value={contentType}
                                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                        onChange={(value) => {
                                            switch (value) {
                                                case "10":
                                                    setContentType(10);
                                                    break;
                                                case "20":
                                                    setContentType(20);
                                                    break;
                                                case "30":
                                                    setContentType(30);
                                                    break;
                                                case "40":
                                                    setContentType(40);
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        <HStack spacing={4}>
                                            <Radio value={10}>متن خام</Radio>
                                            <Radio value={20}>Markdown</Radio>
                                            <Radio isDisabled value={30}>
                                                HTML
                                            </Radio>
                                            <Radio value={40}>فایل نوت‌بوک</Radio>
                                        </HStack>
                                    </RadioGroup>
                                    {getContentChanger(contentType)}
                                </VStack>
                                {contentType === 20 && (
                                    //modal and custom list information
                                    <>
                                        <Box className="mt-6 flex flex-col md:flex-row">
                                            <Box className="flex-grow">
                                                <CustomList
                                                    items={[
                                                        {
                                                            icon: (
                                                                <AlertCircleOutline
                                                                    width="24px"
                                                                    height="24px"
                                                                    color={useColorModeValue(
                                                                        "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                                        "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                                    )}
                                                                />
                                                            ),
                                                            title: (
                                                                <div>
                                                                    نگارش عبارات ریاضی با فرمت TeX فعال است.
                                                                    <Button
                                                                        variant="link"
                                                                        className="mr-1"
                                                                        fontSize="13px"
                                                                        onClick={onOpen}
                                                                    >
                                                                        راهنما
                                                                    </Button>
                                                                </div>
                                                            ),
                                                            key: "tex-support",
                                                        },
                                                        {
                                                            icon: (
                                                                <AlertCircleOutline
                                                                    width="24px"
                                                                    height="24px"
                                                                    color={useColorModeValue(
                                                                        "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                                        "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                                    )}
                                                                />
                                                            ),
                                                            title: (
                                                                <div>
                                                                    برای خروج از حالت کد، دوبار <kbd>Enter</kbd> و برای اضافه کردن خط در بند
                                                                    فعلی، از <kbd>Shift</kbd> + <kbd>Enter</kbd> استفاده کنید.
                                                                </div>
                                                            ),
                                                            key: "newlines",
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        </Box>
                                        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size="lg">
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>نمایش عبارات ریاضی</ModalHeader>
                                                {/* <ModalCloseButton /> */}
                                                <ModalBody>
                                                    <Text fontWeight="bold">ساختار کلی</Text>
                                                    <Text>شما می‌توانید عبارات ریاضی دلخواه خود را با پیروی از چارچوب TeX نمایش دهید.</Text>
                                                    <Text>
                                                        برای آشنایی بیشتر با ساختار نگارشی TeX به{" "}
                                                        <a
                                                            href="https://www.overleaf.com/learn/latex/mathematical_expressions"
                                                            target="_blank"
                                                            style={{
                                                                color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                            }}
                                                        >
                                                            این لینک
                                                        </a>{" "}
                                                        مراجعه کنید.
                                                        <br />
                                                        اگر هنوز تا حد کافی به TeX مسلط نیستید، می‌توانید با استفاده از لینک‌های زیر
                                                        عبارت‌های موردنظر خود را به صورت TeX در دریافت کنید.
                                                        <br />
                                                        <a
                                                            href="http://atomurl.net/math/"
                                                            target="_blank"
                                                            style={{
                                                                color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                            }}
                                                        >
                                                            TeX Equation Editor
                                                        </a>
                                                        <br />
                                                        <a
                                                            href="http://equationeditor.atomurl.net/"
                                                            target="_blank"
                                                            style={{
                                                                color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                            }}
                                                        >
                                                            TeX Equation Editor (WYSIWYG)
                                                        </a>
                                                    </Text>
                                                    <br />
                                                    <Text fontWeight="bold">حالت‌های نمایش</Text>
                                                    <Text>عبارت‌های ریاضی، همچون ساختار تعریف شده در TeX به دو صورت نمایان می‌شوند:</Text>
                                                    <List fontSize="14px">
                                                        <ListItem>-&nbsp;&nbsp;بلوکی (Block)</ListItem>
                                                        <ListItem>-&nbsp;&nbsp;داخل خط (Inline)</ListItem>
                                                    </List>
                                                    <Text mt={4}>
                                                        هر چند نحوه تعیین حالت نمایش عبارت در روبواپیکس، با TeX متفاوت است.
                                                        <br />
                                                        برای نمایش <b>بلوکی</b>، عبارت را به صورت
                                                        <br />
                                                        <Box className="w-full flex my-2">
                                                            <code className="mx-auto">{"$$! <expression-here> !$$"}</code>
                                                        </Box>
                                                        <br />و برای نمایش <b>داخل خط</b>، عبارت را به صورت
                                                        <Box className="w-full flex my-2">
                                                            <code className="mx-auto">{"$! <expression-here> !$"}</code>
                                                        </Box>
                                                        بنویسید.
                                                    </Text>
                                                </ModalBody>

                                                <ModalFooter>
                                                    <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                                                        بستن
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>{getContentView(contentType, getContent(contentRaw, contentMd, contentHTML, contentNb))}</TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Button
                        className="self-end"
                        colorScheme="RoboEpics.turquoise"
                        variant="solid"
                        isLoading={isLoading}
                        onClick={onConfirm}
                    >
                        تایید و اعمال تغییرات
                    </Button>
                </VStack>
            </Box>
        </Box>
    );
};
const CoverImageChange = (props: {
    toast: IToastOptions;
    coverImage: string;
    setCoverImage: React.Dispatch<React.SetStateAction<string>>;
    router: NextRouter;
}) => {
    const inputRef = useRef(null);
    const imageRef = useRef(null);
    const [tmpCoverImage, setTmpCoverImage] = useState<string>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();

    const checkFileAttributes = (file: File) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };

    const onChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // const file = acceptedFile?.[0];
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                isClosable: true,
                variant: "subtle",
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 5000,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpCoverImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };

    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpCoverImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        props.setCoverImage(canvas.toDataURL("image/jpeg"));
        setEditAllowed(true);
    };

    const compileFormData = (coverImage: string) => {
        const form_data = new FormData();

        if (coverImage) {
            const block = coverImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("cover_image", blob, `competition_${props.router.query.competition}_cover_image.png`);
            }
        } else if (coverImage === null) {
            form_data.append("cover_image", "");
        }
        return form_data;
    };

    const editCoverImage = async (coverImage: string) => {
        const req_data = compileFormData(coverImage);
        const serverResponse = (await editCompetitionFormdata(props.router.query.competition, req_data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setCoverImage(coverImage);
            setDeleteAlertVisible(false);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setTmpCoverImage(props.coverImage);
        }
        setEditAllowed(false);
        setDeleteLoading(false);
    };

    useEffect(() => {
        if (editAllowed) editCoverImage(props.coverImage);
    }, [props.coverImage]);

    return (
        <Box height="full" width="full" className={`flex flex-col items-center justify-center self-start ml-4 ${Style.overview_thumbnail}`}>
            <Box className="grid w-full">
                <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
                <CropModal
                    modalTitle={"تصویر چالش"}
                    src={tmpCoverImage}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSuccess={(data) => {
                        crop(data);
                        setIsModalOpen(false);
                    }}
                    aspectY={4}
                    aspectX={1}
                />

                {props.coverImage === null ? (
                    <NextImage
                        src="/images/bannerPlaceholder.png"
                        className={`border object-cover ${Style.overview_no_image}`}
                        width="1216"
                        height="350"
                        objectFit="cover"
                    />
                ) : (
                    <img
                        ref={imageRef}
                        className="border"
                        src={props.coverImage}
                        style={{
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                        }}
                    />
                )}
                <div className={`${Style.overview_coverPictureDarkHover}`}>
                    <IconButton
                        size="md"
                        variant="solid"
                        aria-label="new profile picture"
                        bg={"blackAlpha.700"}
                        icon={props.coverImage ? <Edit2 size="20" color="#fff" /> : <AddOutline color="#fff" height="21px" width="21px" />}
                        _hover={{
                            bg: "blackAlpha.900",
                        }}
                        onClick={() => {
                            inputRef.current.click();
                        }}
                    />
                    {props.coverImage && (
                        <IconButton
                            className="mr-2"
                            size="md"
                            variant="solid"
                            bg={"blackAlpha.700"}
                            aria-label="remove profile picture"
                            icon={<Trash color="#c53030" height="23px" width="23px" />}
                            _hover={{
                                bg: "blackAlpha.900",
                            }}
                            onClick={() => {
                                setDeleteAlertVisible(true);
                            }}
                        />
                    )}
                </div>
            </Box>
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                    setTmpCoverImage(props.coverImage);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از تصمیم خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        با کلیک روی دکمه "تایید" تصویر چالش حذف خواهد شد و برای تعیین تصویر جدید باید فایل عکس را آپلود کنید.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                editCoverImage(null);
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
};
