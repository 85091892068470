import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { problemTextEdit } from "@util/api/problem_text";
import { CustomList, RoboepicsSimpleMde } from "@components";
import { AlertCircleOutline } from "react-ionicons";
import JsxParser from "react-jsx-parser";
import TeX from "@matejmazur/react-katex";
import { RoboEpicsMarkdown } from "@components/markdown";
import { useDropzone } from "react-dropzone";
import Notebook from "@util/renderers/notebook";
import RoboEpicsFormRenderer from "@util/renderers/form";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const EditView = (props: {
    id: number;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setEditTitle: React.Dispatch<React.SetStateAction<string>>;
    editTitle: string;
    content: string;
    contentType: 10 | 20 | 30 | 40 | 50;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [contentRaw, setContentRaw] = useState(props.contentType === 10 ? props.content : "");
    const [contentMd, setContentMd] = useState(props.contentType === 20 ? props.content : "");
    const [contentHTML, setContentHTML] = useState(props.contentType === 30 ? props.content : "");
    const [contentNb, setContentNb] = useState(props.contentType === 40 ? props.content : "");
    const [contentForm, setContentForm] = useState(props.contentType === 50 ? props.content : "");
    const [notebook, setNotebook] = useState<File>(null);
    const [contentType, setContentType] = useState(props.contentType);

    const [title, setTitle] = useState(props.editTitle);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onDrop = (acceptedFile) => {
        const file = acceptedFile?.[0];
        if (!file) {
            return;
        }
        try {
            setNotebook(file);
        } catch (e) {
            console.log(e);
        }
    };
    const { getInputProps, getRootProps } = useDropzone({
        onDrop,
    });

    const getContent = (contentRaw: string, contentMd: string, contentHTML: string, contentNb: string, contentForm: string) => {
        switch (contentType) {
            case 10:
                return contentRaw;
            case 20:
                return contentMd;
            case 30:
                return contentHTML;
            case 40:
                return contentNb;
            case 50:
                return contentForm;
            default:
                break;
        }
    };
    const getContentChanger = (contentType: 10 | 20 | 30 | 40 | 50) => {
        switch (contentType) {
            case 10:
                return (
                    <Textarea
                        width="full"
                        value={contentRaw}
                        onChange={(e) => {
                            setContentRaw(e.target.value);
                        }}
                    />
                );
            case 20:
                return <RoboepicsSimpleMde setContentType={setContentType} setValue={setContentMd} value={contentMd} />;
            case 30:
                setContentHTML("<p>Comingsoon</p>");
                return (
                    <Text py={8} textAlign="center" fontSize="lg">
                        Coming Soon!
                    </Text>
                );
            case 40:
                return (
                    <InputGroup>
                        <InputRightElement w={28}>
                            <Button {...getRootProps()} size="sm" h={"1.7rem"}>
                                انتخاب فایل
                                <input {...getInputProps()} />
                            </Button>
                        </InputRightElement>
                        <Input
                            isReadOnly
                            pl={28}
                            variant="filled"
                            value={notebook?.name}
                            type="text"
                            placeholder="فایل نوت‌بوک خود را انتخاب کنید"
                        />
                    </InputGroup>
                );
            case 50:
                setContentForm("MIRLOU");
                return <></>;
            default:
                return <></>;
        }
    };
    const getContentView = (type: 10 | 20 | 30 | 40 | 50 | string, content: string) => {
        switch (type) {
            case 10:
                return <span>{content}</span>;
            case 20:
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case 40:
                return content ? <Notebook notebook={content} /> : <></>;
            case 30:
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case 50:
                return <RoboEpicsFormRenderer canView shouldRender isLoading={false} form={JSON.parse(content.toString())} />;
        }
    };

    const onConfirm = async () => {
        setIsLoading(true);
        const content = getContent(contentRaw, contentMd, contentHTML, contentNb, contentForm);
        const serverResponse = (await problemTextEdit(props.id, contentType, title, content, undefined, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
                title: title,
            });
            props.setEditMode(false);
            props.setEditConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setIsLoading(false);
    };
    const getNotebookText = async (notebook: File) => {
        const text = await notebook.text();
        setContentNb(text);
    };
    useEffect(() => {
        if (notebook) {
            getNotebookText(notebook);
        } else {
            setContentNb(props.contentType === 40 ? props.content : "");
        }
    }, [notebook]);
    return (
        <Tabs variant="enclosed">
            <TabList>
                <Tab>نگارش</Tab>
                <Tab>پیش‌نمایش</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <VStack spacing={6}>
                        <FormControl isRequired>
                            <FormLabel fontSize="13px">عنوان بخش</FormLabel>
                            <Input
                                size="md"
                                className="mb-4"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    props.setEditTitle(e.target.value);
                                }}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel fontSize="13px">محتویات بخش</FormLabel>
                            <VStack spacing={4} w="full">
                                <RadioGroup
                                    alignSelf="flex-start"
                                    value={contentType}
                                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                    onChange={(value) => {
                                        switch (value) {
                                            case "10":
                                                setContentType(10);
                                                break;
                                            case "20":
                                                setContentType(20);
                                                break;
                                            case "30":
                                                setContentType(30);
                                                break;
                                            case "40":
                                                setContentType(40);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <HStack spacing={4}>
                                        <Radio value={10}>متن خام</Radio>
                                        <Radio value={20}>Markdown</Radio>
                                        <Radio isDisabled value={30}>
                                            HTML
                                        </Radio>
                                        <Radio value={40}>فایل نوت‌بوک</Radio>
                                    </HStack>
                                </RadioGroup>
                                {getContentChanger(contentType)}
                            </VStack>
                        </FormControl>
                    </VStack>
                    {/* <CustomCK setContent={setContent} /> */}
                    <Box className="flex mt-4">
                        <Button
                            variant={"solid"}
                            size="sm"
                            className="mr-auto"
                            onClick={() => {
                                props.setEditMode(false);
                                props.setEditTitle(props.editTitle);
                                props.setEditConfirmed(false);
                            }}
                        >
                            بازگشت
                        </Button>
                        <Button
                            colorScheme="RoboEpics.turquoise"
                            size="sm"
                            variant="solid"
                            className="mr-2"
                            isLoading={isLoading}
                            onClick={() => {
                                onConfirm();
                            }}
                            isDisabled={title.length === 0 || getContent(contentRaw, contentMd, contentHTML, contentNb, contentForm) === ""}
                        >
                            تایید و اعمال تغییرات
                        </Button>
                    </Box>
                    {contentType === 20 && (
                        <>
                            {" "}
                            <Box className="mt-6 flex flex-col md:flex-row">
                                <Box className="flex-grow">
                                    <CustomList
                                        items={[
                                            {
                                                icon: (
                                                    <AlertCircleOutline
                                                        width="24px"
                                                        height="24px"
                                                        color={useColorModeValue(
                                                            "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                            "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                        )}
                                                    />
                                                ),
                                                title: (
                                                    <div>
                                                        نگارش عبارات ریاضی با فرمت TeX فعال است.
                                                        <Button variant="link" className="mr-1" fontSize="13px" onClick={onOpen}>
                                                            راهنما
                                                        </Button>
                                                    </div>
                                                ),
                                                key: "latex-guide",
                                            },
                                            {
                                                icon: (
                                                    <AlertCircleOutline
                                                        width="24px"
                                                        height="24px"
                                                        color={useColorModeValue(
                                                            "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                            "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                        )}
                                                    />
                                                ),
                                                title: (
                                                    <div>
                                                        برای خروج از حالت کد، دوبار <kbd>Enter</kbd> و برای اضافه کردن خط در بند فعلی، از{" "}
                                                        <kbd>Shift</kbd> + <kbd>Enter</kbd> استفاده کنید.
                                                    </div>
                                                ),
                                                key: "code-guide",
                                            },
                                        ]}
                                    />
                                </Box>
                            </Box>
                            <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size="lg">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>نمایش عبارات ریاضی</ModalHeader>
                                    {/* <ModalCloseButton /> */}
                                    <ModalBody>
                                        <Text fontWeight="bold">ساختار کلی</Text>
                                        <Text>شما می‌توانید عبارات ریاضی دلخواه خود را با پیروی از چارچوب TeX نمایش دهید.</Text>
                                        <Text>
                                            برای آشنایی بیشتر با ساختار نگارشی TeX به{" "}
                                            <a
                                                href="https://www.overleaf.com/learn/latex/mathematical_expressions"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                این لینک
                                            </a>{" "}
                                            مراجعه کنید.
                                            <br />
                                            اگر هنوز تا حد کافی به TeX مسلط نیستید، می‌توانید با استفاده از لینک‌های زیر عبارت‌های موردنظر
                                            خود را به صورت TeX در دریافت کنید.
                                            <br />
                                            <a
                                                href="http://atomurl.net/math/"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                TeX Equation Editor
                                            </a>
                                            <br />
                                            <a
                                                href="http://equationeditor.atomurl.net/"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                TeX Equation Editor (WYSIWYG)
                                            </a>
                                        </Text>
                                        <br />
                                        <Text fontWeight="bold">حالت‌های نمایش</Text>
                                        <Text>عبارت‌های ریاضی، همچون ساختار تعریف شده در TeX به دو صورت نمایان می‌شوند:</Text>
                                        <List fontSize="14px">
                                            <ListItem>-&nbsp;&nbsp;بلوکی (Block)</ListItem>
                                            <ListItem>-&nbsp;&nbsp;داخل خط (Inline)</ListItem>
                                        </List>
                                        <Text mt={4}>
                                            هر چند نحوه تعیین حالت نمایش عبارت در روبواپیکس، با TeX متفاوت است.
                                            <br />
                                            برای نمایش <b>بلوکی</b>، عبارت را به صورت
                                            <br />
                                            <Box className="w-full flex my-2">
                                                <code className="mx-auto">{"$$! <expression-here> !$$"}</code>
                                            </Box>
                                            <br />و برای نمایش <b>داخل خط</b>، عبارت را به صورت
                                            <Box className="w-full flex my-2">
                                                <code className="mx-auto">{"$! <expression-here> !$"}</code>
                                            </Box>
                                            بنویسید.
                                        </Text>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                                            بستن
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </>
                    )}
                </TabPanel>
                <TabPanel>{getContentView(contentType, getContent(contentRaw, contentMd, contentHTML, contentNb, contentForm))}</TabPanel>
            </TabPanels>
        </Tabs>
    );
};
