import React, { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    useColorModeValue,
    Tabs,
    TabList,
    Tab,
    Tooltip,
    TabPanels,
    TabPanel,
    Box,
    Text,
    FormHelperText,
    Alert,
    AlertDescription,
    AlertIcon,
    Collapse,
    useColorMode,
    useTheme,
    useMediaQuery,
    IconButton,
    Icon,
    Badge,
    Spinner,
    Progress,
    Menu,
    MenuButton,
    MenuDivider,
    MenuList,
    MenuOptionGroup,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    SlideFade,
    Tag,
    MenuItem,
    HStack,
} from "@chakra-ui/react";
import { AddIcon, AttachmentIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { RoboepicsSimpleMde } from "@components";
import {
    completeUploadingMultipartFile,
    createDataset,
    createNewVersionDataset,
    deleteDataset,
    retrieveAllDatasets,
    searchDatasetByTitle,
    uploadFileToDataset,
} from "@util/api/dataset";
import { problemEditJson } from "@util/api/problems";
import { useDropzone } from "react-dropzone";
import { CheckmarkOutline, CloseOutline, Trash } from "react-ionicons";
import { AlertOctagon, File, Slash, X, XCircle, Upload as UploadIcon } from "react-feather";
import axios from "axios";
import Style from "./datasetView.module.css";
import { IDataset, IToastOptions } from "@util/interfaces";
import { ARVAN_SINGLE_UPLOAD_MAX_SIZE } from "@util/constants";
import { NextRouter } from "next/router";
import { error_500_toast_message } from "@util/strings";

export const AddModal = (props: {
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    onClose: () => void;
    toast: IToastOptions;
    router: NextRouter;
    datasetIds: Array<number>;
}) => {
    const [nextLevel, setNextLevel] = useState(false);
    const [newDatasetData, setNewDatasetData] = useState<IDataset>(null);
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size={"xl"} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>اضافه کردن دیتاست جدید به چالش</ModalHeader>
                {!nextLevel && <ModalCloseButton />}
                <ModalBody>
                    <Tabs orientation="vertical">
                        <TabList>
                            <Tab isDisabled={nextLevel}>
                                <Tooltip placement="right" hasArrow label="ایجاد دیتاست جدید">
                                    <AddIcon />
                                </Tooltip>
                            </Tab>
                            <Tab isDisabled={nextLevel}>
                                <Tooltip placement="right" hasArrow label="انتخاب دیتاست از پلتفرم Roboepics">
                                    <AttachmentIcon />
                                </Tooltip>
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <CreateDatasetTabPanel
                                    onClose={props.onClose}
                                    setAddConfirmed={props.setAddConfirmed}
                                    newDatasetData={newDatasetData}
                                    setNewDatasetData={setNewDatasetData}
                                    setNextLevel={setNextLevel}
                                    nextLevel={nextLevel}
                                    toast={props.toast}
                                    router={props.router}
                                    datasetIds={props.datasetIds}
                                />
                            </TabPanel>
                            <TabPanel>
                                <ChooseDatasetTabPanel
                                    onClose={props.onClose}
                                    setAddConfirmed={props.setAddConfirmed}
                                    toast={props.toast}
                                    router={props.router}
                                    datasetIds={props.datasetIds}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

//creating dataset
const CreateDatasetTabPanel = (props: {
    onClose: () => void;
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    setNewDatasetData: React.Dispatch<React.SetStateAction<IDataset>>;
    newDatasetData: IDataset;
    setNextLevel: React.Dispatch<React.SetStateAction<boolean>>;
    nextLevel: boolean;
    router: NextRouter;
    toast: IToastOptions;
    datasetIds: Array<number>;
}) => {
    if (props.nextLevel)
        return (
            <CreateDatasetPhaseTwo
                onClose={props.onClose}
                setAddConfirmed={props.setAddConfirmed}
                setNewDatasetData={props.setNewDatasetData}
                newDatasetData={props.newDatasetData}
                toast={props.toast}
                router={props.router}
                datasetIds={props.datasetIds}
                setNextLevel={props.setNextLevel}
            />
        );
    if (!props.nextLevel)
        return (
            <CreateDatasetPhaseOne
                setNewDatasetData={props.setNewDatasetData}
                toast={props.toast}
                setNextLevel={props.setNextLevel}
                router={props.router}
            />
        );
};
const CreateDatasetPhaseOne = (props: {
    setNewDatasetData: React.Dispatch<React.SetStateAction<IDataset>>;
    setNextLevel: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [values, setValues] = useState({
        title: "",
        subtitle: "",
        path: "",
        content_type: 20,
        description: "",
    });

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const onCreateDataset = async () => {
        setLoading(true);
        const form = new FormData();
        Object.keys(values).forEach((key) => {
            if (values[key].length !== 0) form.append(key, values[key]);
        });
        form.append("access_level", "30");
        const serverResponse = (await createDataset(form, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "دیتاست شما با موفقیت ساخته شد. در حال انتقال به مرحله بعدی...",
            });
            setDisabled(true);
            props.setNewDatasetData(result);
            setTimeout(() => {
                props.setNextLevel(true);
            }, 5000);
        } else {
            const result = await serverResponse.json();
            if (serverResponse.status === 400) {
                if (result.data.title[0].includes("dataset with this title already exists.")) {
                    props.toast({
                        description: " عنوان دیتاستی که وارد کرده‌اید قبلاً انتخاب شده!",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        variant: "subtle",
                    });
                } else {
                    props.toast({
                        title: "مشکلی درایجاد دیتاست رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                        isClosable: true,
                        duration: 5000,
                        variant: "subtle",
                        status: "error",
                        description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                    });
                }
            } else {
                props.toast({
                    title: "مشکلی درایجاد دیتاست رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }
        setLoading(false);
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <VStack spacing={2}>
                <FormControl>
                    <FormLabel fontSize={"14px"}>عنوان دیتاست</FormLabel>
                    <Input
                        variant={"filled"}
                        value={values.title}
                        onChange={handleChange("title")}
                        id={"new_dataset_title"}
                        maxLength={70}
                    />
                    <FormHelperText>
                        <Text
                            textAlign="end"
                            color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                        >{`${values.title.length}/70`}</Text>
                    </FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel fontSize={"14px"}>توضیح مختصری از دیتاست</FormLabel>
                    <Input
                        variant={"filled"}
                        value={values.subtitle}
                        onChange={handleChange("subtitle")}
                        id={"new_dataset_subtitle"}
                        maxLength={100}
                    />
                    <FormHelperText>
                        <Text
                            textAlign="end"
                            color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                        >{`${values.subtitle.length}/100`}</Text>
                    </FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel fontSize={"14px"}>توضیحات کامل دیتاست ( فرمت Markdown )</FormLabel>
                    <RoboepicsSimpleMde
                        setContentType={(inp) => {
                            setValues((current) => {
                                return { ...current, content_type: inp };
                            });
                        }}
                        setValue={(inp) => {
                            setValues((current) => {
                                return { ...current, description: inp };
                            });
                        }}
                    />
                </FormControl>
                <Button
                    className={"mb-2"}
                    type={"submit"}
                    isDisabled={values.title.length * values.subtitle.length === 0 || disabled}
                    isLoading={loading}
                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                    placeSelf={"flex-end"}
                    onClick={onCreateDataset}
                >
                    مرحله بعدی ساخت دیتاست
                </Button>
            </VStack>
        </form>
    );
};

const CreateDatasetPhaseTwo = (props: {
    onClose: () => void;
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    setNewDatasetData: React.Dispatch<React.SetStateAction<IDataset>>;
    newDatasetData: IDataset;
    router: NextRouter;
    toast: IToastOptions;
    setNextLevel: React.Dispatch<React.SetStateAction<boolean>>;
    datasetIds: Array<number>;
}) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const [isGreaterThan769px] = useMediaQuery("(min-width: 769px)");

    const [uploadFile, setUploadFile] = useState<Array<File>>(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [uploadDone, setUploadDone] = useState(0);
    const [uploadingIndex, setUploadingIndex] = useState(0);
    const [uploadedIndexes, setUploadedIndexes] = useState<Array<number>>([]);
    const [hasError, setHasError] = useState(false);
    const [hasCancellation, setHasCancellation] = useState(false);
    const [fire, setFire] = useState(false);

    const onDrop = (acceptedFile) => {
        const file = acceptedFile;
        if (!file) {
            return;
        }
        try {
            if (!uploadFile) {
                setUploadFile(file);
            } else {
                setUploadFile((current) => {
                    return [...current, ...file];
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const { isDragActive, getInputProps, getRootProps } = useDropzone({
        onDrop,
    });
    const _createnewVersionDataset = async () => {
        setLoading(true);
        setDisabled(true);

        const data = { version: "v1.0", license: "unknown" };
        const serverResponse = (await createNewVersionDataset(props.newDatasetData.path, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            await onConfirm(result.id);
        } else {
            props.toast({
                description: "مشکلی در ساخت نسخه به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
        }
        setLoading(false);
        setDisabled(false);
    };
    const onConfirm = async (id: number) => {
        const ids = [...props.datasetIds, id];
        const req_data = { datasets: ids };
        const serverResponse = (await problemEditJson(req_data, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "success",
                description: "دیتاست جدید با موفقیت به چالش اضافه شد!",
            });
            setDisabled(true);
            props.setAddConfirmed(true);
            props.onClose();
            props.setNewDatasetData(null);
        } else {
            props.toast({
                title: "مشکلی دراضافه کردن دیتاست به چالش رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };
    const deleteNewDataset = async () => {
        setLoading(true);

        const serverResponse = (await deleteDataset(props.newDatasetData.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setUploadFile(null);
            setUploadDone(0);
            setUploadedIndexes([]);
            setHasError(false);
            setHasCancellation(false);
            setFire(false);
            props.setNewDatasetData(null);
            props.setNextLevel(false);
            props.toast({
                description: "دیتاست ساخته شده با موفقیت پاک شد.",
                status: "info",
                isClosable: true,
                duration: 3000,
                variant: "subtle",
            });
        } else {
            props.toast({
                title: "مشکلی درپاک کردن دیتاست ساخته شده رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };

    useEffect(() => {
        if (uploadDone === uploadFile?.length && !hasError && !hasCancellation) _createnewVersionDataset();
    }, [uploadDone, hasError, hasCancellation]);

    useEffect(() => {
        setDisabled(fire);
    }, [fire]);

    useEffect(() => {
        if (hasError || hasCancellation) setFire(false);
        if (hasError) {
            setAlertProps({
                open: true,
                message:
                    "ساخت دیتاست شما با مشکلی مواجه شده است. شما می‌توانید پروسه ساخت را از ابتدا آغاز کنید یا ساخت دیتاست را با فایل‌هایی که به درستی آپلود شده‌اند به اتمام برسانید. در صورت برقراری مشکل با پشتیبانی تماس بگیرید.",
                status: "error",
            });
        }
        if (hasCancellation) {
            setAlertProps({
                open: true,
                message:
                    "شما آپلود یک یا چند فایل انتخاب‌شده را لغو کرده‌اید. می‌توانید این فایل‌ها را دوباره آپلود کنید یا ساخت دیتاست را با فایل‌های آپلود شده به اتمام برسانید.",
                status: "warning",
            });
        }
    }, [hasError, hasCancellation]);

    return (
        <div className={`flex flex-col w-full`}>
            {!uploadFile ? (
                <VStack
                    bg={useColorModeValue("#1b1b1b20", "RoboEpics.dark.600")}
                    h={"10rem"}
                    mb={4}
                    borderRadius={4}
                    borderStyle={"dashed"}
                    borderColor={useColorModeValue("blackAlpha.500", "whiteAlpha.500")}
                    borderWidth={2}
                    textAlign="center"
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <UploadIcon size="4rem" />
                    {isGreaterThan769px ? (
                        isDragActive ? (
                            <Text fontSize="md">رها کنید...</Text>
                        ) : (
                            <Text fontSize="sm">‌فایل(های) مد نظر را به این ‌جا بکشید یا مستقیماً آپلود کنید.</Text>
                        )
                    ) : (
                        <Text fontSize="sm">فایل مد نظر خود را در این قسمت آپلود کنید.</Text>
                    )}
                    {isGreaterThan769px && (
                        <Button variant="ghost" m={2} p={2} colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}>
                            انتخاب فایل
                        </Button>
                    )}
                </VStack>
            ) : (
                <Box h="10rem">
                    <Box className={`flex flex-col w-full justify-between`} overflowY="scroll" maxH="7.5rem">
                        {uploadFile.map((file: File, index: number) => {
                            return (
                                <FileIndicator
                                    id={props.newDatasetData.id}
                                    key={`new-files-${index}`}
                                    path={props.newDatasetData.path}
                                    file={file}
                                    index={index}
                                    setDoneFunc={setUploadDone}
                                    setUploadFile={setUploadFile}
                                    uploadFile={uploadFile}
                                    uploadFired={fire}
                                    setHasError={setHasError}
                                    setHasCancellation={setHasCancellation}
                                    uploadingIndex={uploadingIndex}
                                    setUploadingIndex={setUploadingIndex}
                                    setUploadedIndex={setUploadedIndexes}
                                    uploadedIndexes={uploadedIndexes}
                                    toast={props.toast}
                                    router={props.router}
                                />
                            );
                        })}
                    </Box>
                    <div className={`flex flex-row justify-between w-full mt-2`}>
                        <Button
                            variant="ghost"
                            size="sm"
                            colorScheme={"red"}
                            onClick={() => {
                                setUploadFile(null);
                                setUploadDone(0);
                                setFire(false);
                                setHasCancellation(false);
                                setHasError(false);
                                setUploadedIndexes([]);
                            }}
                            isDisabled={disabled}
                            leftIcon={<Trash color={useColorModeValue("#c53030", "#feb2b2")} height="15px" width="15px" />}
                        >
                            حذف همه فایل‌ها
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                            {...getRootProps()}
                            leftIcon={<AddIcon />}
                            isDisabled={disabled}
                        >
                            <input {...getInputProps()} />
                            اضافه کردن فایل
                        </Button>
                    </div>
                </Box>
            )}
            {hasError || hasCancellation ? (
                <>
                    <Collapse
                        in={alertProps.open}
                        style={{
                            width: "100%",
                            marginBottom: "16px",
                        }}
                    >
                        <Alert status={alertProps.status} variant={useColorModeValue("subtle", "solid")}>
                            <AlertIcon ml={2} />
                            <AlertDescription>{alertProps.message}</AlertDescription>
                        </Alert>
                    </Collapse>
                    {hasCancellation && (
                        <Box className={`flex flex-row justify-between w-full`}>
                            <Button
                                variant="link"
                                isDisabled={disabled || !uploadFile || hasError}
                                isLoading={loading}
                                onClick={() => {
                                    _createnewVersionDataset();
                                    setHasError(false);
                                    setHasCancellation(false);
                                }}
                                colorScheme="yellow"
                            >
                                اتمام ساخت دیتاست
                            </Button>
                            <Button
                                isDisabled={disabled || !uploadFile || hasError}
                                isLoading={loading}
                                onClick={() => {
                                    setFire(true);
                                    setHasError(false);
                                    setHasCancellation(false);
                                }}
                                colorScheme="RoboEpics.green"
                            >
                                آپلود فایل‌های کنسل‌شده و اتمام ساخت
                            </Button>
                        </Box>
                    )}
                    {hasError && (
                        <Box className={`flex flex-col w-full`}>
                            <Box className={`flex flex-row justify-between w-full mb-4`}>
                                <Button
                                    variant="link"
                                    isDisabled={disabled || !uploadFile || uploadFile.length === 1}
                                    isLoading={loading}
                                    onClick={() => {
                                        _createnewVersionDataset();
                                        setHasError(false);
                                        setHasCancellation(false);
                                    }}
                                    colorScheme="yellow"
                                >
                                    اتمام ساخت دیتاست
                                </Button>
                                <Button
                                    isDisabled={disabled || !uploadFile}
                                    isLoading={loading}
                                    colorScheme="RoboEpics.green"
                                    onClick={() => {
                                        setFire(true);
                                        setHasError(false);
                                        setHasCancellation(false);
                                    }}
                                >
                                    تلاش مجدد
                                </Button>
                            </Box>
                            <Button
                                colorScheme="red"
                                leftIcon={<AlertOctagon />}
                                w="fit-content"
                                placeSelf="center"
                                onClick={() => {
                                    deleteNewDataset();
                                }}
                            >
                                ساخت دیتاست از مرحله اول
                            </Button>
                        </Box>
                    )}
                </>
            ) : (
                <Button
                    className={"mt-4"}
                    type={"submit"}
                    isDisabled={disabled || !uploadFile || hasError}
                    isLoading={loading}
                    mr={"auto"}
                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                    placeSelf={"flex-end"}
                    onClick={() => {
                        setFire(true);
                    }}
                >
                    آپلود فایل‌های دیتاست و اتمام ساخت
                </Button>
            )}
        </div>
    );
};

const FileIndicator = (props: {
    id: number;
    path: string;
    file: File;
    setDoneFunc: React.Dispatch<React.SetStateAction<number>>;
    uploadFired: boolean;
    uploadFile: Array<File>;
    setUploadFile: React.Dispatch<React.SetStateAction<File[]>>;
    index: number;
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
    setHasCancellation: React.Dispatch<React.SetStateAction<boolean>>;
    uploadingIndex: number;
    setUploadingIndex: any;
    setUploadedIndex: React.Dispatch<React.SetStateAction<number[]>>;
    uploadedIndexes: Array<number>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [uploading, setUploading] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const uploadMultiparts = props.file.size > ARVAN_SINGLE_UPLOAD_MAX_SIZE; //true if greater than 350Mb
    const numberOfParts = Math.ceil(props.file.size / ARVAN_SINGLE_UPLOAD_MAX_SIZE);
    const [numberOfUploadedParts, setNumberOfUploadedParts] = useState(0);

    const cancelFileUpload = useRef(null);
    const cancelUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current();
        }
    };
    const putFileReq = async (file: File, url: string) => {
        await axios
            .put(url, file, {
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
                cancelToken: new axios.CancelToken((cancel) => (cancelFileUpload.current = cancel)),
            })
            .then(() => {
                setProgress(100);
                setDone(true);
                props.setUploadedIndex((current) => {
                    return [...current, props.index];
                });
                props.setDoneFunc((current) => {
                    return current + 1;
                });
                props.setUploadingIndex((current) => current + 1);
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    setCanceled(true);
                    props.setHasCancellation(true);
                } else {
                    props.toast({
                        description: error_500_toast_message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        variant: "subtle",
                    });
                    console.log(thrown.response);
                    setError(true);
                    props.setHasError(true);
                }
                setDone(false);
                setProgress(0);
            });
        setUploading(false);
    };
    const putFileMultipartReq = async (file: File, urls: Array<string>, numberOfParts: number, uploadId: string) => {
        const fileSize = file.size;
        const uploadedParts = [];
        let remainingBytes = fileSize;

        for (let i = 1; i <= numberOfParts; i++) {
            let startOfPart = fileSize - remainingBytes;
            let endOfPart = Math.min(ARVAN_SINGLE_UPLOAD_MAX_SIZE, startOfPart + remainingBytes);
            let aborted = false;
            if (i > 1) {
                endOfPart = startOfPart + Math.min(ARVAN_SINGLE_UPLOAD_MAX_SIZE, remainingBytes);
                startOfPart += 1;
            }
            try {
                const uploadPartResponse = await axios
                    .put(urls[i - 1], file.slice(startOfPart, endOfPart + 1), {
                        onUploadProgress: function (progressEvent) {
                            const percentCompleted =
                                Math.round((progressEvent.loaded * 100) / (progressEvent.total * numberOfParts)) +
                                Math.round((numberOfUploadedParts * 100) / numberOfParts);
                            setProgress(percentCompleted);
                        },
                        cancelToken: new axios.CancelToken((cancel) => (cancelFileUpload.current = cancel)),
                    })
                    .then((res) => {
                        remainingBytes -= Math.min(ARVAN_SINGLE_UPLOAD_MAX_SIZE, remainingBytes);
                        setNumberOfUploadedParts((current) => current + 1);
                        return res.headers;
                    })
                    .catch((thrown) => {
                        aborted = true;
                        if (axios.isCancel(thrown)) {
                            setCanceled(true);
                            props.setHasCancellation(true);
                        } else {
                            props.toast({
                                description: error_500_toast_message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                                variant: "subtle",
                            });
                            console.log(thrown.response);
                            setError(true);
                            props.setHasError(true);
                        }
                        setDone(false);
                        setProgress(0);
                        setNumberOfUploadedParts(0);
                    });

                // For each part upload, you must record the part number and the ETag value.
                // You must include these values in the subsequent request to complete the multipart upload.
                // https://docs.aws.amazon.com/AmazonS3/latest/API/API_CompleteMultipartUpload.html
                uploadedParts.push({
                    PartNumber: i,
                    ETag: uploadPartResponse.etag,
                });
            } catch (error) {
                aborted = true;
                console.log("Error", error);
                props.setHasError(true);
                setError(true);
                setUploading(false);
            }
            if (aborted) break;
        }
        if (uploadedParts.length === numberOfParts) completeMultiPartUpload(file, uploadId, uploadedParts);
    };
    const completeMultiPartUpload = async (file: File, uploadId: string, parts: Array<{ PartNumber: number; ETag: string }>) => {
        const serverResponse = (await completeUploadingMultipartFile(props.path, file.name, uploadId, parts, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setProgress(100);
            setDone(true);
            props.setUploadedIndex((current) => {
                return [...current, props.index];
            });
            props.setDoneFunc((current) => {
                return current + 1;
            });
        } else {
            props.toast({
                title: `${serverResponse.statusText} (${serverResponse.status})`,
                description:
                    "دیتاست شما با موفقیت ساخته شده ولی در آپلود کردن فایل به مشکل خورده. شما می‌توانید با استفاده از هاب دیتاست به اضافه کردن فایل‌ها به دیتاستتان ادامه دهید یا با پشتیبانی در تماس باشید." +
                    JSON.stringify(await serverResponse.json()),
                status: "error",
                duration: 9000,
                isClosable: true,
                variant: "subtle",
            });
            setError(true);
            setProgress(0);
            setDone(false);
            props.setHasError(true);
        }
        props.setUploadingIndex((current) => current + 1);
        setUploading(false);
    };
    const onUploadFileToDataset = async (file: File) => {
        setUploading(true);
        const data = uploadMultiparts ? { filename: file.name, number_of_parts: numberOfParts } : { filename: file.name };

        const serverResponse = (await uploadFileToDataset(props.path, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;

        if (serverResponse.ok) {
            const data = await serverResponse.json();
            uploadMultiparts ? putFileMultipartReq(file, data.urls, numberOfParts, data.upload_id) : putFileReq(file, data.urls[0]);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setError(true);
            props.setHasError(true);
        }
    };

    useEffect(() => {
        if (props.uploadFired && !props.uploadedIndexes.includes(props.index) && props.index === props.uploadingIndex)
            onUploadFileToDataset(props.file);
    }, [props.uploadFired]);
    return (
        <div key={`.${props.index}`} className={`flex flex-row justify-between items-center w-full px-4 py-1`}>
            <div className={`flex flex-row justify-items-start items-center`}>
                <File size={14} className={"ml-1"} />
                <Text fontSize={"14px"} mb={0} mr={2} p={0} isTruncated maxW={"220px"}>
                    {props.file.name}
                </Text>
            </div>
            <div className={`flex flex-row justify-end w-full items-center`}>
                {error ? (
                    <Badge mr={2} px={1} py={1} colorScheme="red" className={`${Style.status}`}>
                        <div className="flex flex-row items-center">
                            <Icon as={XCircle} w={4} h={4} ml={2} />
                            مشکل در آپلود فایل
                        </div>
                    </Badge>
                ) : (
                    <>
                        {!canceled && uploading && <Spinner size="sm" mx={2} />}
                        {!done && !canceled && uploading && progress !== 100 && (
                            <VStack>
                                <Progress
                                    w={"75px"}
                                    value={progress}
                                    borderRadius={5}
                                    mt={6}
                                    size="xs"
                                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                />
                                <HStack>
                                    {uploadMultiparts && (
                                        <Text
                                            fontSize="xs"
                                            fontWeight="hairline"
                                            mb={0}
                                        >{`پارت ${numberOfUploadedParts} از ${numberOfParts}`}</Text>
                                    )}
                                    <Text fontSize="xs" fontWeight="hairline">{`%${progress}`}</Text>
                                </HStack>
                            </VStack>
                        )}
                        {!done ? (
                            !canceled ? (
                                uploading && (
                                    <IconButton
                                        variant="ghost"
                                        aria-label="cancel-request"
                                        size={"sm"}
                                        colorScheme={"red"}
                                        icon={<CloseOutline color={"red"} />}
                                        mx={2}
                                        onClick={() => {
                                            cancelUpload();
                                        }}
                                    />
                                )
                            ) : (
                                <Badge mr={2} px={1} py={1} colorScheme="gray" className={`${Style.status}`}>
                                    <div className="flex flex-row items-center">
                                        <Icon as={Slash} w={4} h={4} ml={2} />
                                        لغو آپلود توسط کاربر{" "}
                                    </div>
                                </Badge>
                            )
                        ) : (
                            <Badge mr={2} px={1} py={1} colorScheme="green" className={`${Style.status}`}>
                                <div className="flex flex-row items-center">
                                    <CheckmarkOutline color={"#68D391"} cssClasses={`ml-1`} width={"18px"} height={"18px"} />
                                    آپلود موفقیت‌آمیز فایل{" "}
                                </div>
                            </Badge>
                            // <CheckmarkOutline
                            //     color={"#68D391"}
                            //     cssClasses={`mx-2`}
                            // />
                        )}
                        {!uploading && !done && !canceled && (
                            <IconButton
                                mr={1}
                                icon={<Trash color="#c53030" height="15px" width="15px" />}
                                bg="transparent"
                                aria-label={"remove-file"}
                                size="sm"
                                onClick={() => {
                                    const newUploadFile = props.uploadFile.filter((file: File, arrindex: number) => {
                                        return props.uploadFile[arrindex] !== props.uploadFile[props.index];
                                    });
                                    const newUploadedIndexes = props.uploadedIndexes.filter((value) => {
                                        return value !== props.uploadedIndexes[props.index];
                                    });
                                    props.setUploadedIndex(newUploadedIndexes);
                                    newUploadFile.length === 0 ? props.setUploadFile(null) : props.setUploadFile(newUploadFile);
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

//choosing dataset
const ChooseDatasetTabPanel = (props: {
    onClose: () => void;
    setAddConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    router: NextRouter;
    toast: IToastOptions;
    datasetIds: Array<number>;
}) => {
    const [datasets, setDatasets] = useState<{
        results: Array<IDataset>;
        total: number;
    }>({ results: [], total: 0 });
    const [chosen, setChosen] = useState<IDataset>(null);
    const [show, setShow] = useState(false);
    const [newDatasetsId, setNewDatasetsId] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loadingComponent, setLoadingComponent] = useState(true);

    const onRetrieveAllDatasets = async () => {
        const serverResponse = (await retrieveAllDatasets("", {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const datasets = await serverResponse.json();
            setDatasets(datasets);
            setError(false);
        } else {
            setError(true);
        }

        setLoadingComponent(false);
    };

    const removeDataset = (d_id: number, v_id: number) => {
        const newArr = newDatasetsId.filter((el: { v_id: number; d_id: number }) => {
            return !(el.v_id === v_id && el.d_id === d_id);
        });
        setNewDatasetsId(newArr);
    };

    const check = (id: number) => {
        if (props.datasetIds.includes(id)) {
            props.toast({
                duration: 5000,
                isClosable: true,
                description: "این نسخه از دیتاست قبلاً به چالش شما اضافه شده است و نمی‌توانید آن را دوباره انتخاب کنید.",
                variant: "subtle",
                status: "error",
            });
            return false;
        } else {
            return true;
        }
    };

    const onConfirm = async () => {
        setLoading(true);
        let versionIds = [];
        newDatasetsId.forEach((value) => (versionIds = [...versionIds, value.v_id]));
        const ids = [...props.datasetIds, ...versionIds];
        const req_data = { datasets: ids };
        const serverResponse = (await problemEditJson(req_data, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: `دیتاست‌${newDatasetsId.length > 1 ? "های" : ""} انتخاب شده با موفقیت به چالش شما اضافه شد${
                    newDatasetsId.length > 1 ? "ند!" : "!"
                }`,
            });
            setDisabled(true);
            props.setAddConfirmed(true);
            props.onClose();
        } else {
            props.toast({
                title: "مشکلی در اضافه کردن دیتاست(ها) رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        onRetrieveAllDatasets();
    }, []);
    if (loadingComponent) {
        return (
            <Box className="flex flex-col w-full items-center my-4">
                <Spinner size="sm" />
            </Box>
        );
    }
    return (
        <VStack spacing={2}>
            <FormControl className={`w-full justify-start items-center flex-row flex`}>
                <div className={`w-full`}>
                    <FormLabel fontSize={"14px"}>انتخاب دیتاست</FormLabel>
                    <Menu isLazy placement="bottom-end" closeOnSelect>
                        <MenuButton
                            className={`${Style.datasets_commit_btn}`}
                            isDisabled={!datasets.total}
                            as={Button}
                            textAlign={"inherit"}
                            rightIcon={<ChevronDownIcon />}
                            px={3}
                            w="full"
                        >
                            {chosen ? chosen.title : "دیتاست مورد نظر خود را انتخاب کنید"}{" "}
                        </MenuButton>
                        <MenuList
                            zIndex={3}
                            bg={useColorModeValue("#fff", "RoboEpics.black.900")}
                            mx={"auto"}
                            className={`${Style.menu_list}`}
                        >
                            <MenuOptionGroup type="radio" title={"عنوان دیتاست"}>
                                <MenuDivider />
                                {datasets.results.map((dataset, index) => {
                                    return (
                                        <>
                                            <MenuItem
                                                key={`dataset-${dataset.id}`}
                                                minH="2.5rem"
                                                value={`${index}`}
                                                overflowX="auto"
                                                overflowY="hidden"
                                                onClick={(e) => {
                                                    setChosen(datasets.results[e.currentTarget.value]);
                                                }}
                                            >
                                                <Box w="full">
                                                    <Text mb={1} isTruncated>
                                                        {dataset.title}
                                                    </Text>
                                                    <Text fontSize="xs" mb={0} fontWeight="hairline" isTruncated>
                                                        {dataset.subtitle}
                                                    </Text>
                                                </Box>
                                            </MenuItem>
                                            {index !== datasets.results.length - 1 && <MenuDivider />}
                                        </>
                                    );
                                })}
                            </MenuOptionGroup>
                        </MenuList>
                    </Menu>
                </div>
                <div className={`mr-4`}>
                    <SlideFade in={chosen ? true : false} offsetX={0} offsetY={"-20px"}>
                        <FormLabel fontSize={"14px"}>انتخاب نسخه</FormLabel>
                        <Menu isLazy placement="bottom-end" closeOnSelect>
                            <MenuButton
                                className={`${Style.datasets_commit_btn}`}
                                as={Button}
                                textAlign={"inherit"}
                                rightIcon={<ChevronDownIcon />}
                                px={3}
                            >
                                نسخه دیتاست
                            </MenuButton>
                            <MenuList
                                zIndex={3}
                                bg={useColorModeValue("#fff", "RoboEpics.black.900")}
                                mx={"auto"}
                                className={`${Style.menu_list}`}
                            >
                                {chosen?.versions.map((version, index) => {
                                    return (
                                        <>
                                            <MenuItem
                                                key={`dataset-version-${version.id}`}
                                                minH="2.5rem"
                                                value={`${index}`}
                                                overflowX="auto"
                                                overflowY="hidden"
                                                onClick={(e) => {
                                                    setNewDatasetsId((current) => {
                                                        if (
                                                            check(chosen.versions[e.currentTarget.value].id) &&
                                                            current.some(
                                                                (el: { d_id: number; v_id: number }) =>
                                                                    el.v_id === chosen.versions[e.currentTarget.value].id,
                                                            )
                                                        ) {
                                                            return [...current];
                                                        } else {
                                                            return [
                                                                ...current,
                                                                {
                                                                    d_id: chosen.id,
                                                                    v_id: chosen.versions[e.currentTarget.value].id,
                                                                },
                                                            ];
                                                        }
                                                    });
                                                }}
                                            >
                                                <Box w="full">
                                                    <Text mb={1} isTruncated>
                                                        {version.version}
                                                        <CustomTag status={version.status} />
                                                    </Text>
                                                    <Text fontSize="xs" mb={0} fontWeight="hairline" isTruncated>
                                                        {version.description}
                                                    </Text>
                                                </Box>
                                            </MenuItem>
                                            {index !== chosen?.versions.length - 1 && <MenuDivider />}
                                        </>
                                    );
                                })}
                            </MenuList>
                        </Menu>
                    </SlideFade>
                </div>
            </FormControl>
            <DatasetSearchFormControl
                setNewDatasetsId={setNewDatasetsId}
                datasetIds={props.datasetIds}
                toast={props.toast}
                router={props.router}
            />
            {newDatasetsId.length && (
                <FormControl>
                    <div className={`flex flex-row justify-between w-full items-baseline `}>
                        <FormLabel fontSize={"14px"}>دیتاست‌های انتخاب‌شده</FormLabel>
                        {newDatasetsId.length > 1 && (
                            <IconButton
                                aria-label="collapse-selected-datasets"
                                icon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                onClick={() => setShow(!show)}
                                size="xs"
                                variant="ghost"
                            />
                        )}
                    </div>
                    <Collapse
                        className={`w-full flex flex-col justify-start mt-2`}
                        in={newDatasetsId.length > 1 ? show : true}
                        startingHeight={30}
                    >
                        {newDatasetsId.map((el: { d_id: number; v_id: number }, index) => {
                            const dataset = datasets.results.find((dataset) => dataset.id === el.d_id);
                            const version = dataset.versions.find((version) => version.id === el.v_id);
                            return (
                                <SlideFade key={`chosen-datasets-${index}`} in offsetX={"50px"} offsetY={"0"}>
                                    <Box className={`w-full flex flex-row border rounded-md px-2 py-1 mb-2 items-center`}>
                                        <div className={`w-full`}>
                                            <div className={`w-full flex flex-row items-baseline justify-start`}>
                                                <Text fontWeight="bold" fontSize="16px" mb={1}>
                                                    {dataset.title}
                                                </Text>
                                                <span className={`w-1 h-1 mx-2 rounded-full bg-gray-${useColorModeValue(800, 400)}`} />
                                                <Text mb={1}>
                                                    {version.version}
                                                    <CustomTag status={version.status} />
                                                </Text>
                                            </div>
                                            <Text fontSize="13px" mb={1}>
                                                {dataset.description}
                                            </Text>
                                        </div>
                                        <IconButton
                                            aria-label="remove-dataset"
                                            icon={<CloseIcon />}
                                            size="xs"
                                            variant="ghost"
                                            colorScheme="red"
                                            onClick={() => removeDataset(el.d_id, el.v_id)}
                                        />
                                    </Box>
                                </SlideFade>
                            );
                        })}
                    </Collapse>
                </FormControl>
            )}
            <Button
                className={"mb-2"}
                type={"submit"}
                isDisabled={newDatasetsId.length ? disabled : true}
                isLoading={loading}
                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                placeSelf={"flex-end"}
                onClick={() => {
                    onConfirm();
                }}
            >
                اضافه کردن دیتاست‌(ها)
            </Button>
        </VStack>
    );
};

const DatasetSearchFormControl = (props: {
    setNewDatasetsId: React.Dispatch<React.SetStateAction<{ d_id: number; v_id: number }[]>>;
    toast: IToastOptions;
    router: NextRouter;
    datasetIds: Array<number>;
}) => {
    const inputDelayTimer = useRef(null);
    const searchInputEl = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchResult, setSearchResult] = useState({ results: [], total: 0 });

    const [chosen, setchosen] = useState<IDataset>(null);

    const onDatasetSearchInputChange = async (input: string) => {
        setSearchInput(input);
        setIsLoading(true);
        setShowSearchResult(false);
        clearTimeout(inputDelayTimer.current);
        if (input.length) {
            inputDelayTimer.current = setTimeout(async () => {
                dataSearch(input);
            }, 500);
        } else {
            setSearchResult({ results: [], total: 0 });
        }
    };

    const dataSearch = async (input: string) => {
        setIsLoading(true);

        const serverResponse = (await searchDatasetByTitle(
            { title: input },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const sr = await serverResponse.json();
            setSearchResult(sr);
            setShowSearchResult(true);
        }

        setIsLoading(false);
    };

    const check = (id: number) => {
        if (props.datasetIds.includes(id)) {
            props.toast({
                duration: 5000,
                isClosable: true,
                description: "این نسخه از دیتاست قبلاً به چالش شما اضافه شده است و نمی‌توانید آن را دوباره انتخاب کنید.",
                variant: "subtle",
                status: "error",
            });
            return false;
        } else {
            return true;
        }
    };
    return (
        <FormControl className={`w-full justify-start items-center flex-row flex`}>
            <div className={`w-full`}>
                <FormLabel fontSize={"14px"}>جست‌وجوی دیتاست</FormLabel>
                <InputGroup
                    sx={{
                        boxShadow: showSearchResult ? "0px -5px 10px -5px rgba(0,0,0,0.2)" : "none",
                    }}
                >
                    <Input
                        ref={searchInputEl}
                        size="sm"
                        placeholder="جستجو بر اساس عنوان دیتاست..."
                        onChange={(e) => onDatasetSearchInputChange(e.target.value)}
                        value={chosen ? chosen.title : searchInput}
                    />
                    <InputLeftElement
                        sx={{
                            height: "32px",
                            width: "32px",
                            paddingRight: "10px",
                        }}
                    >
                        <IconButton
                            bg={"transparent"}
                            variant="unstyled"
                            icon={<SearchIcon color="gray" />}
                            aria-label="search-teams"
                            pointerEvents="none"
                            height="32px"
                            width="32px"
                        />
                    </InputLeftElement>
                    <InputRightElement
                        sx={{
                            height: "32px",
                            width: "32px",
                            paddingLeft: "6px",
                        }}
                        children={
                            isLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                searchInput.length !== 0 && (
                                    <IconButton
                                        sx={{
                                            borderRadius: "999px",
                                            height: "20px",
                                            width: "20px",
                                            minWidth: "20px",
                                            padding: "2px",
                                        }}
                                        aria-label="Cancel search"
                                        onClick={() => {
                                            setSearchResult({
                                                results: [],
                                                total: 0,
                                            });
                                            setSearchInput("");
                                            setShowSearchResult(false);
                                            searchInputEl.current.focus();
                                        }}
                                        icon={<X size={18} />}
                                    />
                                )
                            )
                        }
                    />
                </InputGroup>
                {showSearchResult && (
                    <Box
                        className="py-2 px-2 overflow-y-auto border border-t-0 rounded-lg rounded-t-none"
                        sx={{
                            position: "absolute",
                            zIndex: "50",
                            top: "100%",
                            left: "0",
                            right: "0",
                            width: "68.5%",
                            maxHeight: "300px",
                            transition: "height 0.3s",
                            boxShadow: "0px 15px 20px 2px rgba(0,0,0,0.2)",
                        }}
                        bg={useColorModeValue("#F7F7F8", "#14181d")}
                    >
                        {searchResult.results.length !== 0 ? (
                            searchResult.results.map((result, index) => {
                                return (
                                    <Box
                                        key={`search-result-${index}`}
                                        className={`flex flex-col py-2 px-2 my-1 cursor-pointer  ${
                                            index !== searchResult.results.length - 1 && "border-b"
                                        }`}
                                        _hover={{
                                            bgColor: useColorModeValue("gray.200", "RoboEpics.dark.600"),
                                        }}
                                        onClick={() => {
                                            setchosen(result);
                                            setShowSearchResult(false);
                                        }}
                                    >
                                        <Text isTruncated mb={1}>
                                            {result.title}
                                        </Text>
                                        <Text isTruncated mb={0} fontWeight={"hairline"} fontSize="xs">
                                            {result.subtitle}
                                        </Text>
                                    </Box>
                                );
                            })
                        ) : (
                            <Text m={0} py={1} fontSize="13px" color={"gray.400"}>
                                نتیجه‌ای پیدا نشد!
                            </Text>
                        )}
                    </Box>
                )}
            </div>
            <div className={`mr-4`}>
                <SlideFade in={chosen ? true : false} offsetX={0} offsetY={"-20px"}>
                    <FormLabel fontSize={"14px"}>انتخاب نسخه</FormLabel>
                    <Menu isLazy placement="bottom-end" closeOnSelect>
                        <MenuButton
                            className={`${Style.datasets_commit_btn}`}
                            as={Button}
                            textAlign={"inherit"}
                            rightIcon={<ChevronDownIcon />}
                            px={3}
                        >
                            نسخه دیتاست
                        </MenuButton>
                        <MenuList
                            zIndex={3}
                            bg={useColorModeValue("#fff", "RoboEpics.black.900")}
                            mx={"auto"}
                            className={`${Style.menu_list}`}
                        >
                            {chosen?.versions.map((version, index) => {
                                return (
                                    <>
                                        <MenuItem
                                            key={`dataset-version-${version.id}`}
                                            minH="2.5rem"
                                            value={`${index}`}
                                            overflowX="auto"
                                            overflowY="hidden"
                                            onClick={(e) => {
                                                props.setNewDatasetsId((current) => {
                                                    if (
                                                        check(chosen.versions[e.currentTarget.value].id) &&
                                                        current.some((el) => el.v_id === chosen.versions[e.currentTarget.value].id)
                                                    ) {
                                                        return [...current];
                                                    } else {
                                                        return [
                                                            ...current,
                                                            {
                                                                d_id: chosen.id,
                                                                v_id: chosen.versions[e.currentTarget.value].id,
                                                            },
                                                        ];
                                                    }
                                                });
                                            }}
                                        >
                                            <Box w="full">
                                                <Text mb={1}>
                                                    {version.version}
                                                    <CustomTag status={version.status} />
                                                </Text>
                                                <Text fontSize="xs" mb={0} fontWeight="hairline">
                                                    {version.description}
                                                </Text>
                                            </Box>
                                        </MenuItem>
                                        {index !== chosen?.versions.length - 1 && <MenuDivider />}
                                    </>
                                );
                            })}
                        </MenuList>
                    </Menu>
                </SlideFade>
            </div>
        </FormControl>
    );
};
const CustomTag = (version: { status: 20 | 40 }) => {
    let colorScheme = "gray";
    switch (version.status) {
        case 20:
            colorScheme = "green";
            break;

        default:
            colorScheme = "gray";
            break;
    }
    return (
        <Tag colorScheme={colorScheme} size="sm" mr={2}>
            {version.status}
        </Tag>
    );
};
