import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Link as ChakraLink,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import Link from "next/link";
import { NextRouter } from "next/router";
import { CustomList, RoboEpicsMarkdown } from "@components";
import { RoboepicsSimpleMde } from "@components/Editor";
import { AlertCircleOutline } from "react-ionicons";
import { editCompetitionJson } from "@util/api/competitions";
import { IToastOptions } from "@util/interfaces";

export const OwnerTerms = (props: { rules: string; toast: IToastOptions; router: NextRouter }) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [rules, setRules] = useState(props.rules ? props.rules : "");
    const [contentType, setContentType] = useState(20);

    const onConfirm = async () => {
        const data = { rules };
        const serverResponse = (await editCompetitionJson(props.router.query.competition, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setRules(props.rules);
        }
        setLoading(false);
    };

    useEffect(() => {
        setDisabled(rules === props.rules);
    }, [rules]);

    return (
        <Box as="div" className="w-full h-full">
            <Text>
                در این صفحه شما می‌توانید قوانین مسابقه خود را تعیین کنید. <br /> شرکت‌کنندگان در مسابقه شما ملزم به پذیرفتن و پیروی از
                قوانین مسابقه و{" "}
                <Link href="/terms">
                    <ChakraLink
                        mx={1}
                        colorScheme=""
                        textDecoration="underline"
                        _hover={{
                            color: useColorModeValue("RoboEpics.azure.200", "RoboEpics.turquoise.600"),
                        }}
                    >
                        {"شرایط و مقررات سامانه RoboEpics"}
                    </ChakraLink>
                </Link>
                هستند.
                <br />
            </Text>
            <Divider />
            <Box className={`w-full px-4 pt-2 pb-4 border mt-4`}>
                <Tabs variant="enclosed">
                    <TabList>
                        <Tab>نگارش</Tab>
                        <Tab>پیش‌نمایش</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <VStack spacing={4}>
                                <RoboepicsSimpleMde setContentType={setContentType} setValue={setRules} value={rules} />
                                <Box className="mt-6 flex flex-col md:flex-row w-full">
                                    <Box className="flex-grow">
                                        <CustomList
                                            items={[
                                                {
                                                    icon: (
                                                        <AlertCircleOutline
                                                            width="24px"
                                                            height="24px"
                                                            color={useColorModeValue(
                                                                "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                                "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                            )}
                                                        />
                                                    ),
                                                    title: (
                                                        <div>
                                                            نگارش عبارات ریاضی با فرمت TeX فعال است.
                                                            <Button variant="link" className="mr-1" fontSize="13px" onClick={onOpen}>
                                                                راهنما
                                                            </Button>
                                                        </div>
                                                    ),
                                                    key: "latex-guide",
                                                },
                                                {
                                                    icon: (
                                                        <AlertCircleOutline
                                                            width="24px"
                                                            height="24px"
                                                            color={useColorModeValue(
                                                                "var(--chakra-colors-RoboEpics-turquoise-500)",
                                                                "var(--chakra-colors-RoboEpics-turquoise-400)",
                                                            )}
                                                        />
                                                    ),
                                                    title: (
                                                        <div>
                                                            برای خروج از حالت کد، دوبار <kbd>Enter</kbd> و برای اضافه کردن خط در بند فعلی،
                                                            از <kbd>Shift</kbd> + <kbd>Enter</kbd> استفاده کنید.
                                                        </div>
                                                    ),
                                                    key: "code-guide",
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Box>
                            </VStack>

                            <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size="lg">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>نمایش عبارات ریاضی</ModalHeader>
                                    {/* <ModalCloseButton /> */}
                                    <ModalBody>
                                        <Text fontWeight="bold">ساختار کلی</Text>
                                        <Text>شما می‌توانید عبارات ریاضی دلخواه خود را با پیروی از چارچوب TeX نمایش دهید.</Text>
                                        <Text>
                                            برای آشنایی بیشتر با ساختار نگارشی TeX به{" "}
                                            <a
                                                href="https://www.overleaf.com/learn/latex/mathematical_expressions"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                این لینک
                                            </a>{" "}
                                            مراجعه کنید.
                                            <br />
                                            اگر هنوز تا حد کافی به TeX مسلط نیستید، می‌توانید با استفاده از لینک‌های زیر عبارت‌های موردنظر
                                            خود را به صورت TeX در دریافت کنید.
                                            <br />
                                            <a
                                                href="http://atomurl.net/math/"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                TeX Equation Editor
                                            </a>
                                            <br />
                                            <a
                                                href="http://equationeditor.atomurl.net/"
                                                target="_blank"
                                                style={{
                                                    color: "var(--chakra-colors-RoboEpics-turquoise-600)",
                                                }}
                                            >
                                                TeX Equation Editor (WYSIWYG)
                                            </a>
                                        </Text>
                                        <br />
                                        <Text fontWeight="bold">حالت‌های نمایش</Text>
                                        <Text>عبارت‌های ریاضی، همچون ساختار تعریف شده در TeX به دو صورت نمایان می‌شوند:</Text>
                                        <List fontSize="14px">
                                            <ListItem>-&nbsp;&nbsp;بلوکی (Block)</ListItem>
                                            <ListItem>-&nbsp;&nbsp;داخل خط (Inline)</ListItem>
                                        </List>
                                        <Text mt={4}>
                                            هر چند نحوه تعیین حالت نمایش عبارت در روبواپیکس، با TeX متفاوت است.
                                            <br />
                                            برای نمایش <b>بلوکی</b>، عبارت را به صورت
                                            <br />
                                            <Box className="w-full flex my-2">
                                                <code className="mx-auto">{"$$! <expression-here> !$$"}</code>
                                            </Box>
                                            <br />و برای نمایش <b>داخل خط</b>، عبارت را به صورت
                                            <Box className="w-full flex my-2">
                                                <code className="mx-auto">{"$! <expression-here> !$"}</code>
                                            </Box>
                                            بنویسید.
                                        </Text>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                                            بستن
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </TabPanel>
                        <TabPanel>
                            <RoboEpicsMarkdown>{rules.toString()}</RoboEpicsMarkdown>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box className="flex flex-col mr-auto mt-2" w="fit-content">
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        variant="solid"
                        className="mr-2"
                        isLoading={loading}
                        isDisabled={disabled}
                        onClick={() => {
                            setLoading(true);
                            onConfirm();
                        }}
                    >
                        تایید و اعمال تغییرات
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
