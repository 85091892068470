import React, { useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Text,
    Textarea,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { Bug } from "react-ionicons";
import { bugReportCreate } from "@util/api/misc";
import { store } from "@redux/store";

import Style from "./bugReport.module.css";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const BugReport = (props: { toast: IToastOptions; router: NextRouter }) => {
    const userEmail: string = store.getState().user.data.email;
    const [values, setValues] = useState({
        email: userEmail ? userEmail : "",
        subject: "",
        description: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const onCreateBugReport = async () => {
        setIsLoading(true);

        const serverResponse = (await bugReportCreate(values.email, values.subject, values.description, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setAlertProps({
                open: true,
                message: "با سپاس از همکاری شما، گزارش شما با موفقیت ثبت شد و به تیم پشتیبانی انتقال داده می‌شود.",
                status: "success",
            });
            setIsLoading(false);
            setValues({
                ...values,
                subject: "",
                description: "",
            });
        } else {
            setAlertProps({
                open: true,
                message:
                    "مشکلی در ارسال گزارش رخ داد: " +
                    ` ${serverResponse.statusText} (${serverResponse.status})؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید` +
                    JSON.stringify(await serverResponse.json()),
                status: "error",
            });
        }

        setIsLoading(false);
    };
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "error" | "success" | "warning";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    return (
        <Box as="div" className={`flex flex-col w-full h-full my-12`}>
            <Heading size="xl">گزارش باگ</Heading>
            <Box as="div" className={`flex flex-col w-10/12 py-4 mx-auto ${Style.container}`}>
                <Box as="div" className={`flex mx-auto flex-col w-9/12 items-center text-center`}>
                    <Box mb={8}>
                        <Bug width="4rem" height="4rem" color={useColorModeValue("#000", "#fff")} />
                    </Box>
                    <Text>
                        در صورت مشاهده هر گونه مشکل، خطا، عدم تطابق اطلاعات یا هر نوع باگ دیگر می‌توانید با گزارش دادن باگ ما را در ارتقا
                        دادن سطح کیفیت سایت و تجربه کاربری همراهان
                        <b> RoboEpics</b>، یاری کنید. پس از بررسی گزارش توسط تیم پشتیبانی و فنی ما، نتیجه این گزارش به شما اطلاع داده خواهد
                        شد یا در صورت لزوم با شما تماس خواهیم گرفت.
                    </Text>
                    <Text>برای گزارش باگ کافیست فرم زیر را پر کرده و برای ما ارسال کنید.</Text>
                </Box>
            </Box>
            <Divider />
            <Box as="div" className={`w-9/12 h-full flex flex-col flex-grow mx-auto py-8`}>
                <Collapse in={alertProps.open}>
                    <Alert status={alertProps.status} variant={"subtle"} className="mb-4">
                        <AlertIcon />
                        <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                    </Alert>
                </Collapse>
                <form onSubmit={(e) => e.preventDefault()}>
                    <VStack spacing="16px" as="div" className="w-full">
                        <FormControl className={`${Style.email_wrapper}`}>
                            <FormLabel fontSize={"14px"}>پست الکترونیک</FormLabel>
                            <Input
                                isReadOnly={userEmail !== "" && userEmail !== null}
                                dir="ltr"
                                variant="filled"
                                type="email"
                                value={values.email}
                                onChange={handleChange("email")}
                                id="email"
                            />
                        </FormControl>
                        {/* </Box> */}
                        <FormControl>
                            <FormLabel fontSize="14px">موضوع گزارش</FormLabel>
                            <Input
                                variant="filled"
                                type="text"
                                value={values.subject}
                                onChange={handleChange("subject")}
                                id="subject"
                                maxLength={100}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="14px">توضیحات</FormLabel>
                            <Textarea
                                variant="filled"
                                value={values.description}
                                onChange={handleChange("description")}
                                id="description"
                                resize="none"
                                maxLength={2000}
                                h={[32, 64]}
                            />
                            <FormHelperText>
                                <Text
                                    textAlign="end"
                                    color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                                >{`${values.description.length}/2000`}</Text>
                            </FormHelperText>
                        </FormControl>
                        <Button
                            type="submit"
                            isDisabled={values.description.length * values.email.length * values.subject.length === 0}
                            isLoading={isLoading}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            mr={1}
                            my={2}
                            px={10}
                            placeSelf={"flex-end"}
                            onClick={onCreateBugReport}
                        >
                            ارسال
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Box>
    );
};
