import React, { useState, useEffect } from "react";
import { NextRouter } from "next/router";
import Link from "next/link";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightAddon,
    Link as ChakraLink,
    ListItem,
    Text,
    Textarea,
    UnorderedList,
    useColorModeValue,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react";
import Style from "./createComp.module.css";
import { createCompetition } from "@util/api/competitions";
import { IToastOptions } from "@util/interfaces";

export const CreateCompetition = (props: { router: NextRouter; toast: IToastOptions }) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isGreaterThan901px, isGreaterThan601px] = useMediaQuery(["(min-width:901px)", "(min-width:601px)"]);
    const [values, setValues] = useState<{
        title: string;
        subtitle: string;
        path?: string;
    }>({ title: "", subtitle: "" });
    const [agreementChecked, setAgreementChecked] = useState<boolean>(false);
    const [isClassroom, setIsClassroom] = useState(false);
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "error" | "success" | "warning";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const errorParser = (result: { path?: Array<string>; title?: Array<string>; subtitle?: Array<string> }) => {
        const open = true;
        const status = "error";
        let message = "";
        if (result.path) {
            switch (result.path[0]) {
                case `Enter a valid "slug" consisting of letters, numbers, underscores or hyphens.`:
                    message = "مقدار وارد شده برای مسیر اختصاصی باید فقط شامل حروف انگلیسی ، اعداد، خط زیر و خط فاصله باشد.";

                    break;
                case `This field must be unique.`:
                    message = "مسیر اختصاصی که وارد کرده‌اید نباید تکراری باشد.";
                    break;
                default:
                    message = result.path[0];
                    break;
            }
        }
        if (result.subtitle) {
            switch (result.subtitle[0]) {
                default:
                    message = result.subtitle[0];
                    break;
            }
        }
        if (result.title) {
            switch (result.title[0]) {
                default:
                    message = result.title[0];
                    break;
            }
        }
        setAlertProps({
            open: open,
            message: message,
            status: status,
        });
    };

    const onCreateCompetition = async () => {
        setLoading(true);
        const reqData = { title: values.title, subtitle: values.subtitle, is_classroom: isClassroom };
        if (values.path) Object.assign(reqData, { path: values.path });
        const serverResponse = (await createCompetition(reqData, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { path } = await serverResponse.json();
            setAlertProps({
                open: true,
                message: "مسابقه شما با موفقیت ساخته شد. در حال انتقال به صفحه مسابقه...",
                status: "success",
            });
            setTimeout(() => {
                props.router.push(`/c/${path}/settings`);
            }, 1000);

            setDisabled(true);
        } else {
            if (serverResponse.status === 400) {
                const { data } = await serverResponse.json();
                errorParser(data);
            } else {
                setAlertProps({
                    open: true,
                    message: "مشکلی در ساخت مسابقه رخ داد. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                    status: "warning",
                });
            }
            setDisabled(false);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (values.title && values.subtitle && agreementChecked) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [values, agreementChecked]);

    return (
        <Box className={`${Style.main_container} flex flex-row w-full px-12 py-4 my-12 justify-between `} h={"650px"}>
            <Box className={`${Style.create_form} flex flex-col w-full items-center my-2 py-4 px-2`}>
                <Heading size="lg" alignSelf="center">
                    ایجاد مسابقه جدید
                </Heading>
                <Heading as="h4" size="sm" fontWeight="300" alignSelf="center">
                    اطلاعات مسابقه خود را وارد کنید.
                </Heading>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    className={`${Style.form} w-9/12`}
                >
                    {alertProps.open && (
                        <Alert status={alertProps.status} my={4} variant="subtle">
                            <AlertIcon />
                            <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                        </Alert>
                    )}
                    <VStack spacing={4} mt={4} w="full">
                        <FormControl>
                            <FormLabel fontSize="14px">عنوان مسابقه</FormLabel>
                            <Input
                                value={values.title}
                                variant="filled"
                                onChange={handleChange("title")}
                                type="text"
                                id="roboepics-competition-title"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="14px">زیرعنوان مسابقه</FormLabel>
                            <Textarea
                                value={values.subtitle}
                                variant="filled"
                                onChange={handleChange("subtitle")}
                                id="roboepics-competition-subtitle"
                                placeholder="توضیح مختصر و مفید از مسابقه شما"
                                maxLength={50}
                                resize="none"
                                h={20}
                            />
                            <FormHelperText>
                                <Text
                                    textAlign="end"
                                    color={useColorModeValue("rgba(0,0,0,0.54)", "rgba(255,255,255,0.54)")}
                                    mb={0}
                                >{`${values.subtitle.length}/50`}</Text>
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="14px">مسیر اختصاصی مسابقه</FormLabel>
                            <InputGroup>
                                <Input
                                    variant="filled"
                                    type="text"
                                    id="path"
                                    value={values.path}
                                    onChange={handleChange("path")}
                                    dir="ltr"
                                    placeholder="my-competition"
                                    _placeholder={{ direction: "ltr" }}
                                />
                                {isGreaterThan901px && (
                                    <InputRightAddon children={"roboepics.com/c/"} className={`${Style.input_addon}`} dir="ltr" />
                                )}
                            </InputGroup>
                            {values.path && (
                                <FormHelperText className={`${Style.path_form_helper}`}>
                                    <Text>
                                        مسیر اختصاصی مسابقه شما به این صورت در مرورگر نمایش داده خواهد شد:{" "}
                                        {`roboepics.com/c/${values.path}`}
                                    </Text>
                                </FormHelperText>
                            )}
                        </FormControl>
                        {isGreaterThan601px && (
                            <>
                                <FormControl>
                                    <Checkbox
                                        isChecked={agreementChecked}
                                        spacing={4}
                                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                        onChange={(e) => setAgreementChecked(e.target.checked ? true : false)}
                                        className={`${Style.checkbox}`}
                                    >
                                        {"تمام "}
                                        <Link href="/terms">
                                            <ChakraLink
                                                mx={1}
                                                colorScheme=""
                                                textDecoration="underline"
                                                _hover={{
                                                    color: useColorModeValue("RoboEpics.azure.200", "RoboEpics.turquoise.600"),
                                                }}
                                            >
                                                {"شرایط و مقررات سامانه RoboEpics"}
                                            </ChakraLink>
                                        </Link>
                                        {" را مطالعه کردم و موافقت خود را با آن تأیید می‌کنم."}
                                    </Checkbox>
                                </FormControl>
                                <FormControl>
                                    <Checkbox
                                        isChecked={isClassroom}
                                        spacing={4}
                                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                        onChange={(e) => {
                                            setIsClassroom(e.target.checked ? true : false);
                                        }}
                                        className={`${Style.checkbox}`}
                                    >
                                        ایجاد مسابقه به عنوان کلاس درس
                                    </Checkbox>
                                </FormControl>
                                <Button
                                    alignSelf="flex-end"
                                    isDisabled={disabled}
                                    isLoading={loading}
                                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                    bg={useColorModeValue("RoboEpics.dark.500", "RoboEpics.gold.500")}
                                    onClick={onCreateCompetition}
                                >
                                    ایجاد مسابقه
                                </Button>
                            </>
                        )}
                    </VStack>
                </form>
            </Box>
            <Divider orientation={isGreaterThan601px ? "vertical" : "horizontal"} mx={isGreaterThan601px ? 4 : 0} />
            <Box className={`flex flex-col w-full items-center my-2 py-4 px-2`}>
                <Heading size="md" textAlign="center">
                    توضیحات ساخت مسابقه در روبواپیکس
                </Heading>
                <Box
                    className={`${Style.information} flex flex-col w-4/5 my-4 text-justify`}
                    fontSize="15px"
                    overflow="auto"
                    maxH={isGreaterThan601px ? "100%" : "350px"}
                >
                    <UnorderedList spacing={4}>
                        <ListItem>
                            با استفاده از پلتفرم ساخت و برگزاری مسابقات روبواپیکس هر شخصی از دانشجو و دانش‌آموز گرفته تا مدیران شرکت‌ها و
                            کسب و کارها می‌توانند به راحتی مسابقات یادگیری ماشین و هوش مصنوعی خودشان را برگزار کنند!
                        </ListItem>
                        <ListItem>
                            شما قوانین و چالش‌های مسابقه خود را تعیین می‌کنید، شرکت‌کنندگان الگوریتم‌هایی طراحی می‌کنند و راه‌حل‌های خود را
                            ارائه می‌دهند و در نهایت سرویس ما امتیاز راه‌حل‌ها را محاسبه می‌کند و برنده مسابقه را تعیین می‌کند.
                        </ListItem>
                        <ListItem>
                            پلتفرم برگزاری مسابقات روبواپیکس طوری طراحی شده که شما به راحتی هرچه تمام‌تر مسابقه خود را ایجاد کنید و میزبانی
                            آن را به سرویس‌های ما واگذار کنید تا بتوانید تمرکز خود را روی پیدا کردن دیتاست و تعریف چالش‌های مناسب بگذارید.
                        </ListItem>
                        {/* <ListItem>
                            روبواپیکس بهترین پلتفرم برای علاقه‌مندان و متخصصان
                            در زمینه یادگیری ماشین و هوش مصنوعی، اساتید و
                            دانشجویان دانشگاه‌ها و صاحبان کسب و کار است که به
                            دنبال دستیابی به راه‌حل‌هایی برای چالش‌های واقعی خود
                            هستند.
                        </ListItem>
                        <ListItem>
                            شما می‌توانید به عنوان یک علاقه‌مند یا متخصص یادگیری
                            ماشین یا هوش مصنوعی یا داده‌ها؛ با برگزاری مسابقه و
                            به کمک دیگر متخصصان این حوزه‌ها سرسخت‌ترین چالش‌ها
                            را از پا دربیاورید
                        </ListItem>
                        <ListItem>
                            شما برای آسان‌تر برگزار کردن کلاس‌های درسی خود
                            می‌توانید با استفاده از ساخت کلاس درس به صورت مسابقه
                            و کمک گرفتن از اساتید حل تمرین، تمرین‌های قرار داده
                            شده در کلاس درسی خود را مدیریت کنید.
                        </ListItem>
                        <ListItem>
                            شما حتی می‌توانیدبرای کارمندان و متخصصان یادگیری
                            ماشین و هوش مصنوعی و کسب و کار خود یا برای حل یک
                            چالش در شرکت یا کسب و کارتان مسابقه‌های کاملاً خصوصی
                            برگزار کنید و نحوه عملکرد شرکت‌کنندگان را بسنجید.
                        </ListItem>
                        <ListItem>
                            با استفاده از پلتفرم روبواپیکس و برگزاری مسابقه‌هایی
                            که مد نظر دارید به راحتی می‌توانید چالش‌های خود را
                            برون‌سپاری کنید و بهترین نتایج و مدل‌ها را از
                            شرکت‌کنندگان دریافت کنید.
                        </ListItem> */}
                    </UnorderedList>
                </Box>
            </Box>
            {!isGreaterThan601px && (
                <VStack spacing={4} className={`flex flex-col py-4`}>
                    <FormControl>
                        <Checkbox
                            isChecked={agreementChecked}
                            spacing={4}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                            onChange={(e) => setAgreementChecked(e.target.checked ? true : false)}
                            className={`${Style.checkbox}`}
                        >
                            {"تمام "}
                            <Link href="/terms">
                                <ChakraLink
                                    mx={1}
                                    colorScheme=""
                                    textDecoration="underline"
                                    _hover={{
                                        color: useColorModeValue("RoboEpics.azure.200", "RoboEpics.turquoise.600"),
                                    }}
                                >
                                    {"شرایط و مقررات سامانه RoboEpics"}
                                </ChakraLink>
                            </Link>
                            {" را مطالعه کردم و موافقت خود را با آن تأیید می‌کنم."}
                        </Checkbox>
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            isChecked={isClassroom}
                            spacing={4}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                            onChange={(e) => {
                                setIsClassroom(e.target.checked ? true : false);
                            }}
                            className={`${Style.checkbox}`}
                        >
                            ایجاد مسابقه به عنوان کلاس درس
                        </Checkbox>
                    </FormControl>
                    <Button
                        type="submit"
                        alignSelf="flex-end"
                        isDisabled={disabled}
                        isLoading={loading}
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        bg={useColorModeValue("RoboEpics.dark.500", "RoboEpics.gold.500")}
                        onClick={onCreateCompetition}
                    >
                        ایجاد مسابقه
                    </Button>
                </VStack>
            )}
        </Box>
    );
};
