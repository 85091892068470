import React from "react";
import { Box, useColorModeValue, useTheme } from "@chakra-ui/react";
import { ILeaderboardAvatarProps } from "@util/interfaces";

export const CustomLeaderboardAvatar = (props: ILeaderboardAvatarProps) => {
    const bg = useColorModeValue("RoboEpics.black.100", "RoboEpics.black.default");
    return (
        <Box
            bg={bg}
            as="span"
            className=" ml-2 rounded-full w-10 h-10 border inline-flex justify-center items-center relative"
            style={{
                border: `2px solid ${useColorModeValue("white", "black")}`,
                // boxShadow: `0 0 0 1.5px ${useColorModeValue(
                //     theme.colors.RoboEpics.azure[500],
                //     theme.colors.RoboEpics.black[300],
                // )}`,
            }}
        >
            <img
                src={props.src}
                className="rounded-full"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                }}
            />
        </Box>
    );
};
