import React, { useEffect, useRef, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import Style from "./settings.module.css";
import { Edit2, Plus } from "react-feather";
import { Trash } from "react-ionicons";
import {
    retrieveProblemSubmissionScoreDefinitions,
    retrieveProblemScoreDefinitions,
    createProblemSubmissionScoreDefinition,
    createProblemScoreDefinition,
    assignSubmissionScoreDefinitionToProblem,
    assignScoreDefinitionToProblem,
    editProblemSubmissionScoreDefinition,
    editProblemScoreDefinition,
    removeScoreDefinitionFromProblem,
    removeSubmissionScoreDefinitionFromProblem,
} from "@util/api/problem_score_definition";

import { CreatableSelect, NoticeProps, chakraComponents, LoadingIndicatorProps, OnChangeValue } from "chakra-react-select";
import { IProblemData, IScoreDefData, ISubScoreDefData, IToastOptions } from "@util/interfaces";
import { getSingleProblem } from "@util/api/problems";
import { NextRouter } from "next/router";
interface ScoreDefinitionType {
    id: number;
    description: string;
    name: string;
    value: string;
    label: string;
}

export const ScoresDefinitionSettings = (props: {
    problem: string;
    submissionScoreDefinitions: Array<ISubScoreDefData>;
    problemScoreDefinitions: Array<IScoreDefData>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [submissionDefs, setSubmissionDefs] = useState<Array<ISubScoreDefData>>(
        props.submissionScoreDefinitions.length ? props.submissionScoreDefinitions : [],
    );
    const [chosenSubDef, setChosenSubDef] = useState({
        name: "",
        weight: "1.00",
        id: -1,
        description: "",
    });
    const [subDefModalVisible, setSubDefModalVisible] = useState(false);
    const [subChanged, setSubChanged] = useState(false);
    const [runDefs, setRunDefs] = useState<Array<IScoreDefData>>(props.problemScoreDefinitions.length ? props.problemScoreDefinitions : []);
    const [chosenRunDef, setChosenRunDef] = useState({
        name: "",
        weight: "1.00",
        id: -1,
        description: "",
    });
    const [runDefModalVisible, setRunDefModalVisible] = useState(false);
    const [deleteAlertDialogInfo, setDeleteAlertDialogInfo] = useState<{
        open: boolean;
        id: number;
        type: "sub" | "run";
    }>({ open: false, id: -1, type: null });
    const [runChanged, setRunChanged] = useState(false);
    useEffect(() => {
        if (runChanged) onReload();
        if (subChanged) onReload();
    }, [runChanged, subChanged]);
    const onReload = async () => {
        const serverResponse = (await getSingleProblem(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data: IProblemData = await serverResponse.json();
            setRunDefs(data.score_definitions);
            setSubmissionDefs(data.submission_score_definitions);
            setSubChanged(false);
            setRunChanged(false);
        }
    };
    return (
        <Box className={`flex flex-row justify-center px-8 ${Style.basic_form_container}`}>
            <VStack spacing={8} w="full">
                <FormControl>
                    <FormLabel fontWeight={"bold"} display={"flex"}>
                        تعریف امتیاز برای ارسال
                        <Button
                            size="sm"
                            mr="auto"
                            leftIcon={<Plus />}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                            bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                            onClick={() => setSubDefModalVisible(true)}
                        >
                            تعریف امتیاز جدید
                        </Button>
                    </FormLabel>
                    <Table mt={4}>
                        <Thead>
                            <Tr>
                                <Th textAlign={"center"}>عنوان</Th>
                                <Th textAlign={"center"}>وزن</Th>
                                <Th textAlign={"center"}>Actions</Th>
                            </Tr>
                        </Thead>
                        {submissionDefs.length !== 0 && (
                            <Tbody>
                                {submissionDefs.map((def, index) => {
                                    return (
                                        <Tr key={`submission-def-${index}`}>
                                            <Td textAlign={"center"}>{def.score_definition.name}</Td>
                                            <Td textAlign={"center"}>{def.weight}</Td>
                                            <Td>
                                                <HStack spacing={4} justifyContent={"center"}>
                                                    <Button
                                                        leftIcon={<Edit2 size={"14"} />}
                                                        size={"xs"}
                                                        onClick={() => {
                                                            setSubDefModalVisible(true);
                                                            setChosenSubDef({
                                                                name: def.score_definition.name,
                                                                description: def.score_definition.description,
                                                                id: def.id,
                                                                weight: def.weight,
                                                            });
                                                        }}
                                                    >
                                                        ویرایش
                                                    </Button>
                                                    <Button
                                                        colorScheme={"red"}
                                                        size={"xs"}
                                                        variant={"outline"}
                                                        leftIcon={
                                                            <Trash
                                                                color={useColorModeValue("#c53030", "#feb2b2")}
                                                                width={"14px"}
                                                                height={"14px"}
                                                            />
                                                        }
                                                        onClick={() => {
                                                            setDeleteAlertDialogInfo({
                                                                open: true,
                                                                id: def.id,
                                                                type: "sub",
                                                            });
                                                        }}
                                                    >
                                                        حذف
                                                    </Button>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        )}
                    </Table>
                    {submissionDefs.length === 0 && (
                        <Text textAlign={"center"} mt={4}>
                            هنوز تعریفی برای ارسال‌های این چالش مشخص نشده است.
                        </Text>
                    )}
                </FormControl>
                <Divider />
                <FormControl>
                    <FormLabel fontWeight={"bold"} display={"flex"}>
                        تعریف امتیاز برای اجرا
                        <Button
                            size="sm"
                            mr="auto"
                            leftIcon={<Plus />}
                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                            bg={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                            onClick={() => setRunDefModalVisible(true)}
                        >
                            تعریف امتیاز جدید
                        </Button>
                    </FormLabel>
                    <Table mt={4}>
                        <Thead>
                            <Tr>
                                <Th textAlign={"center"}>عنوان</Th>
                                <Th textAlign={"center"}>وزن</Th>
                                <Th textAlign={"center"}>Actions</Th>
                            </Tr>
                        </Thead>
                        {runDefs.length !== 0 && (
                            <Tbody>
                                {runDefs.map((def, index) => {
                                    return (
                                        <Tr key={`submission-def-${index}`}>
                                            <Td textAlign={"center"}>{def.score_definition.name}</Td>
                                            <Td textAlign={"center"}>{def.weight}</Td>
                                            <Td>
                                                <HStack spacing={4} justifyContent={"center"}>
                                                    <Button
                                                        leftIcon={<Edit2 size={"14"} />}
                                                        size={"xs"}
                                                        onClick={() => {
                                                            setRunDefModalVisible(true);
                                                            setChosenRunDef({
                                                                name: def.score_definition.name,
                                                                description: def.score_definition.description,
                                                                id: def.id,
                                                                weight: def.weight,
                                                            });
                                                        }}
                                                    >
                                                        ویرایش
                                                    </Button>
                                                    <Button
                                                        colorScheme={"red"}
                                                        size={"xs"}
                                                        variant={"outline"}
                                                        leftIcon={
                                                            <Trash
                                                                color={useColorModeValue("#c53030", "#feb2b2")}
                                                                width={"14px"}
                                                                height={"14px"}
                                                            />
                                                        }
                                                        onClick={() => {
                                                            setDeleteAlertDialogInfo({
                                                                open: true,
                                                                id: def.id,
                                                                type: "run",
                                                            });
                                                        }}
                                                    >
                                                        حذف
                                                    </Button>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        )}
                    </Table>
                    {runDefs.length === 0 && (
                        <Text textAlign={"center"} mt={4}>
                            هنوز تعریفی برای اجراهای این چالش مشخص نشده است.
                        </Text>
                    )}
                </FormControl>
            </VStack>
            <SubmissionDefModal
                isOpen={subDefModalVisible}
                onClose={() => {
                    setSubDefModalVisible(false);
                    setChosenSubDef({
                        name: "",
                        weight: "1.00",
                        id: -1,
                        description: "",
                    });
                }}
                isNew={chosenSubDef.name.length ? false : true}
                definition={chosenSubDef}
                setSubChanged={setSubChanged}
                problem={props.problem}
                toast={props.toast}
                router={props.router}
            />
            <RunDefModal
                isOpen={runDefModalVisible}
                onClose={() => {
                    setRunDefModalVisible(false);
                    setChosenRunDef({
                        name: "",
                        weight: "1.00",
                        id: -1,
                        description: "",
                    });
                }}
                isNew={chosenRunDef.name.length ? false : true}
                definition={chosenRunDef}
                setRunChanged={setRunChanged}
                problem={props.problem}
                toast={props.toast}
                router={props.router}
            />
            <RemoveAlertDialog
                isOpen={deleteAlertDialogInfo.open}
                onClose={() => {
                    setDeleteAlertDialogInfo({
                        open: false,
                        id: -1,
                        type: null,
                    });
                }}
                problem={props.problem}
                type={deleteAlertDialogInfo.type}
                id={deleteAlertDialogInfo.id}
                setRunChanged={setRunChanged}
                setSubChanged={setSubChanged}
                toast={props.toast}
                router={props.router}
            />
        </Box>
    );
};
const SubmissionDefModal = (props: {
    isNew: boolean;
    definition: {
        name: string;
        weight: string;
        description: string;
        id: number;
    };
    setSubChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    onClose: () => void;
    problem: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [componentLoading, setComponentLoading] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(!props.isOpen);
    const [definition, setDefinition] = useState({
        name: props.definition.name,
        id: props.definition.id,
        description: props.definition.description,
    });
    const [weight, setWeight] = useState(props.definition.weight);
    const [definitions, setDefinitions] = useState<{
        total: number;
        results: Array<{ id: number; name: string; description: string }>;
    }>({ total: 0, results: [] });

    useEffect(() => {
        setComponentLoading(true);
        setData();
        setComponentLoading(false);
    }, []);

    useEffect(() => {
        setDefinition({
            description: props.definition.description,
            id: props.definition.id,
            name: props.definition.name,
        });
        setWeight(props.definition.weight);
    }, [props.definition]);

    const setData = async () => {
        const serverResponse = (await retrieveProblemSubmissionScoreDefinitions({
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setDefinitions(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
    };

    const createOption = async (input: string) => {
        setLoadingSelect(true);

        const serverResponse = (await createProblemSubmissionScoreDefinition(input, input.toLowerCase(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setDefinition({
                name: data.name,
                id: data.id,
                description: data.description,
            });
            await setData();
        } else {
            props.toast({
                title: "مشکلی در ایجاد تعریف امتیاز رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setLoadingSelect(false);
        }
    };

    const onConfirm = async () => {
        setLoading(true);

        if (props.isNew) {
            const serverResponse = (await assignSubmissionScoreDefinitionToProblem(props.problem, definition.name, weight, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                props.toast({
                    description: "تعریف با موفقیت به چالش اضافه شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setDisabled(true);
                props.setSubChanged(true);
            } else {
                props.toast({
                    title: "مشکلی در اضافه کردن تعریف امتیاز به چالش رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        } else {
            const serverResponse = (await editProblemSubmissionScoreDefinition(props.problem, props.definition.id, weight, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                props.toast({
                    description: "تغییر با موفقیت اعمال شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setDisabled(true);
                props.setSubChanged(true);
            } else {
                props.toast({
                    title: "مشکلی در اعمال تغییر رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }

        props.onClose();
        setLoading(false);
    };
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setDefinition({ name: "", id: -1, description: "" });
                setWeight("1.00");
            }}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.isNew ? " تعریف امتیاز ارسال جدید" : "تغییر وزن تعریف امتیاز ارسال"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {componentLoading && !errorLoading.hasError && (
                        <Box className="flex flex-row w-full justify-center">
                            <Spinner size="md" my="8" />
                        </Box>
                    )}
                    {errorLoading.hasError && !componentLoading && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle>
                                {errorLoading.status
                                    ? `${errorLoading.code} - ${errorLoading.status}`
                                    : "مشکلی در دریافت داده از سرور رخ داد."}
                            </AlertTitle>
                            {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                            <AlertDescription fontSize="sm">لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                        </Alert>
                    )}
                    {!componentLoading && !errorLoading.hasError && (
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>نام</FormLabel>
                                {props.isNew ? (
                                    <CreatableSelect
                                        placeholder={"انتخاب کنید..."}
                                        isRtl
                                        options={definitions.results.map((value) => {
                                            return {
                                                label: value.name,
                                                value: value.name,
                                                id: value.id,
                                                description: value.description,
                                                name: value.name,
                                            };
                                        })}
                                        isClearable
                                        isDisabled={!props.isNew}
                                        isLoading={loadingSelect}
                                        onChange={(newValue: OnChangeValue<ScoreDefinitionType, false>) => {
                                            setDefinition({
                                                name: newValue ? newValue.name : "",
                                                id: newValue ? newValue.id : -1,
                                                description: newValue ? newValue.description : "",
                                            });
                                        }}
                                        onCreateOption={(inp) => {
                                            createOption(inp);
                                        }}
                                        value={{ label: definition.name, value: definition.name }}
                                        allowCreateWhileLoading={false}
                                        components={{
                                            NoOptionsMessage: (props: NoticeProps) => {
                                                return (
                                                    <chakraComponents.NoOptionsMessage {...props}>
                                                        <Text mb={0}>موردی برای نمایش نیست</Text>
                                                    </chakraComponents.NoOptionsMessage>
                                                );
                                            },
                                            Option: ({ children, ...props }) => {
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                const data: ScoreDefinitionType = props.data;
                                                return (
                                                    <chakraComponents.Option {...props}>
                                                        <Box py={1}>
                                                            <Text mb={0} fontSize="14px">
                                                                {children}
                                                            </Text>
                                                            <Text mb={0} fontSize={"12px"} color={"gray"}>
                                                                {data.description}
                                                            </Text>
                                                        </Box>
                                                    </chakraComponents.Option>
                                                );
                                            },
                                            LoadingIndicator: (props: LoadingIndicatorProps<ScoreDefinitionType>) => {
                                                return (
                                                    <chakraComponents.LoadingIndicator {...props}>
                                                        <Spinner />
                                                    </chakraComponents.LoadingIndicator>
                                                );
                                            },
                                        }}
                                    />
                                ) : (
                                    <Input isDisabled variant={"filled"} value={props.definition.name} />
                                )}
                            </FormControl>
                            <FormControl>
                                <FormLabel>وزن</FormLabel>
                                <NumberInput precision={2} step={0.1} value={weight} onChange={(e) => setWeight(e)} variant={"filled"}>
                                    <NumberInputField />
                                </NumberInput>
                            </FormControl>
                        </VStack>
                    )}
                </ModalBody>
                {!componentLoading && !errorLoading.hasError && (
                    <ModalFooter>
                        <Button
                            isLoading={loading}
                            isDisabled={props.isNew ? disabled && definition.name === "" : disabled && props.definition.weight === weight}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {props.isNew ? "اضافه کردن" : "اعمال تغییرات"}
                        </Button>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};

const RunDefModal = (props: {
    isNew: boolean;
    definition: {
        name: string;
        weight: string;
        description: string;
        id: number;
    };
    setRunChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    onClose: () => void;
    problem: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [componentLoading, setComponentLoading] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(!props.isOpen);
    const [definition, setDefinition] = useState({
        name: props.definition.name,
        id: props.definition.id,
        description: props.definition.description,
    });
    const [weight, setWeight] = useState(props.definition.weight);

    const [definitions, setDefinitions] = useState<{
        total: number;
        results: Array<{ id: number; name: string; description: string }>;
    }>({ total: 0, results: [] });

    useEffect(() => {
        setComponentLoading(true);
        setData();
        setComponentLoading(false);
    }, []);

    useEffect(() => {
        setDefinition({
            description: props.definition.description,
            id: props.definition.id,
            name: props.definition.name,
        });
        setWeight(props.definition.weight);
    }, [props.definition]);

    const setData = async () => {
        const serverResponse = (await retrieveProblemScoreDefinitions({
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setDefinitions(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
    };

    const createOption = async (input: string) => {
        setLoadingSelect(true);

        const serverResponse = (await createProblemScoreDefinition(input, input.toLowerCase(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setDefinition({
                name: data.name,
                id: data.id,
                description: data.description,
            });
            await setData();
        } else {
            props.toast({
                title: "مشکلی در ایجاد تعریف امتیاز رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setLoadingSelect(false);
    };

    const onConfirm = async () => {
        setLoading(true);

        if (props.isNew) {
            const serverResponse = (await assignScoreDefinitionToProblem(props.problem, definition.name, weight, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                props.toast({
                    description: "تعریف با موفقیت به چالش اضافه شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setDisabled(true);
                props.setRunChanged(true);
            } else {
                props.toast({
                    title: "مشکلی در اضافه کردن تعریف به چالش رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        } else {
            const serverResponse = (await editProblemScoreDefinition(props.problem, props.definition.id, weight, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                props.toast({
                    description: "تغییر با موفقیت اعمال شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setDisabled(true);
                props.setRunChanged(true);
            } else {
                props.toast({
                    title: "مشکلی در اعمال تغییر رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }

        props.onClose();
        setLoading(false);
    };
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.isNew ? " تعریف امتیاز اجرای جدید" : "تغییر وزن تعریف امتیاز اجرا"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {componentLoading && !errorLoading.hasError && (
                        <Box className="flex flex-row w-full justify-center">
                            <Spinner size="md" my="8" />
                        </Box>
                    )}
                    {errorLoading.hasError && !componentLoading && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle>
                                {errorLoading.status
                                    ? `${errorLoading.code} - ${errorLoading.status}`
                                    : "مشکلی در دریافت داده از سرور رخ داد."}
                            </AlertTitle>

                            {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                            <AlertDescription fontSize="sm">لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید</AlertDescription>
                        </Alert>
                    )}
                    {!componentLoading && !errorLoading.hasError && (
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>نام</FormLabel>
                                {props.isNew ? (
                                    <CreatableSelect
                                        placeholder={"انتخاب کنید..."}
                                        isRtl
                                        options={definitions.results.map((value) => {
                                            return {
                                                label: value.name,
                                                value: value.name,
                                                id: value.id,
                                                description: value.description,
                                                name: value.name,
                                            };
                                        })}
                                        isClearable
                                        isDisabled={!props.isNew}
                                        isLoading={loadingSelect}
                                        onChange={(newValue: OnChangeValue<ScoreDefinitionType, false>) => {
                                            setDefinition({
                                                name: newValue ? newValue.name : "",
                                                id: newValue ? newValue.id : -1,
                                                description: newValue ? newValue.description : "",
                                            });
                                        }}
                                        onCreateOption={(inp) => {
                                            createOption(inp);
                                        }}
                                        value={{ label: definition.name, value: definition.name }}
                                        allowCreateWhileLoading={false}
                                        components={{
                                            NoOptionsMessage: (props: NoticeProps) => {
                                                return (
                                                    <chakraComponents.NoOptionsMessage {...props}>
                                                        <Text mb={0}>موردی برای نمایش نیست</Text>
                                                    </chakraComponents.NoOptionsMessage>
                                                );
                                            },
                                            Option: ({ children, ...props }) => {
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                const data: ScoreDefinitionType = props.data;
                                                return (
                                                    <chakraComponents.Option {...props}>
                                                        <Box py={1}>
                                                            <Text mb={0} fontSize="14px">
                                                                {children}
                                                            </Text>
                                                            <Text mb={0} fontSize={"12px"} color={"gray"}>
                                                                {data.description}
                                                            </Text>
                                                        </Box>
                                                    </chakraComponents.Option>
                                                );
                                            },
                                            LoadingIndicator: (props: LoadingIndicatorProps<ScoreDefinitionType>) => {
                                                return (
                                                    <chakraComponents.LoadingIndicator {...props}>
                                                        <Spinner />
                                                    </chakraComponents.LoadingIndicator>
                                                );
                                            },
                                        }}
                                    />
                                ) : (
                                    <Input isDisabled variant={"filled"} value={props.definition.name} />
                                )}
                            </FormControl>
                            <FormControl>
                                <FormLabel>وزن</FormLabel>
                                <NumberInput precision={2} step={0.1} value={weight} onChange={(e) => setWeight(e)} variant={"filled"}>
                                    <NumberInputField />
                                </NumberInput>
                            </FormControl>
                        </VStack>
                    )}
                </ModalBody>
                {!componentLoading && !errorLoading.hasError && (
                    <ModalFooter>
                        <Button
                            isLoading={loading}
                            isDisabled={props.isNew ? disabled && definition.name === "" : disabled && props.definition.weight === weight}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {props.isNew ? "اضافه کردن" : "اعمال تغییرات"}
                        </Button>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};

const RemoveAlertDialog = (props: {
    isOpen: boolean;
    onClose: () => void;
    type: "sub" | "run";
    problem: string;
    id: number;
    setRunChanged: React.Dispatch<React.SetStateAction<boolean>>;
    setSubChanged: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const cancelRef = useRef();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(false);
    }, [props.isOpen]);

    const onDelete = async () => {
        setLoading(true);

        if (props.type) {
            const serverResponse =
                props.type === "run"
                    ? ((await removeScoreDefinitionFromProblem(props.problem, props.id, {
                          toast: props.toast,
                          requesterPath: props.router.asPath,
                      })) as Response)
                    : ((await removeSubmissionScoreDefinitionFromProblem(props.problem, props.id, {
                          toast: props.toast,
                          requesterPath: props.router.asPath,
                      })) as Response);
            if (serverResponse.ok) {
                props.toast({
                    description: "تعریف از سوال حذف شد.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                    variant: "subtle",
                });
                setDisabled(true);
                props.type === "run" ? props.setRunChanged(true) : props.setSubChanged(true);
                props.onClose();
            } else {
                props.toast({
                    title: "مشکلی در حذف تعریف رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }

        setLoading(false);
    };

    return (
        <AlertDialog isOpen={props.isOpen} onClose={props.onClose} leastDestructiveRef={cancelRef} isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        حذف تعریف
                    </AlertDialogHeader>

                    <AlertDialogBody>آیا از حذف کردن این تعریف اطمینان دارید؟</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={props.onClose} isDisabled={loading || disabled}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={() => {
                                onDelete();
                            }}
                            mr={3}
                            isLoading={loading}
                            isDisabled={disabled || props.id <= 0}
                        >
                            تأیید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
