import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const searchTags = async (parameters, ctx?: IServerSideContext) => {
    let query = "";
    for (const [key, value] of Object.entries(parameters)) {
        query += `&${key}=${value}`;
    }
    query = query.slice(1);
    return await fetchWithRefresh(`/misc/tags?${query}`, {}, "preferred", ctx);
};
