import React from "react";
import { RoboEpicsMarkdown } from "@components";
import { Box } from "@chakra-ui/react";
import Notebook from "@util/renderers/notebook";

import { default as NextImage } from "next/image";

import Style from "./compOverview.module.css";

import JsxParser from "react-jsx-parser";
import TeX from "@matejmazur/react-katex";
import RoboEpicsFormRenderer from "@util/renderers/form";
import { getImageURL } from "@util/mediaLoader";

export const Overview = (props: {
    description: string;
    coverImage: string | null;
    contentType: 10 | 20 | 30 | 40; //10: raw_text , 20: markdown , 30: html , 40: notebook;
}) => {
    const getContentView = (type: 10 | 20 | 30 | 40 | 50 | string, content: string) => {
        switch (type) {
            case 10:
                return <span>{content}</span>;
            case 20:
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case 40:
                return content ? <Notebook notebook={content} /> : <></>;
            case 30:
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case 50:
                return <RoboEpicsFormRenderer canView canSubmit isLoading={false} form={JSON.parse(content.toString())} />;
        }
    };

    return (
        <Box
            sx={{
                p: { lineHeight: "24px" },
            }}
            className={`h-full`}
        >
            <Box height="full" width="full" className={`${Style.no_image}`}>
                {props.coverImage ? (
                    <img
                        className="border"
                        src={getImageURL(props.coverImage)}
                        style={{
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                        }}
                    />
                ) : (
                    <NextImage
                        src="/images/bannerPlaceholder.png"
                        className={`border object-cover ${Style.no_image}`}
                        width="1216"
                        height="350"
                        objectFit="cover"
                    />
                )}
            </Box>

            <Box className={`w-full pt-8 pb-2 px-4 mt-4 border flex flex-col`}>{getContentView(props.contentType, props.description)}</Box>
        </Box>
    );
};
