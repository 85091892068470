import moment from "jalali-moment";

export const convertDate = (date: string, format: string) => {
    moment.locale("en");
    const m = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSSS");
    m.locale("fa");
    const convertedDate = m.format(format);
    return convertedDate;
};

export const getDateDifferenceMs = (date: string) => {
    const m = moment.from(date, "en", "YYYY-MM-DDTHH:mm:ss");
    const now = moment().locale("fa");
    const diff = moment.duration(now.diff(m));
    return diff.asMilliseconds();
};

export const convertDiscourseDate = (date: string, format: string) => {
    moment.locale("en");
    const m = moment(date, "YYYY-MM-DDTHH:mm:ss.SSS");
    m.locale("fa");
    const convertedDate = m.format(format);
    return convertedDate;
};

export const getDatePhrase = (date: string, source: "discourse" | "roboepics") => {
    const now = moment().locale("fa");
    const _moment = moment.from(date, "en", source === "discourse" ? "YYYY-MM-DDTHH:mm:ss.SSS" : "YYYY-MM-DDTHH:mm:ss.SSSSSS");
    const diff = moment.duration(now.diff(_moment));

    if (diff.years() > 0) return `${diff.years()} سال پیش`;
    else if (diff.months() > 0) return `${diff.months()} ماه پیش`;
    else if (diff.days() > 0) return `${diff.days()} روز پیش`;
    else if (diff.hours() > 0) return `${diff.hours()} ساعت پیش`;
    else if (diff.minutes() > 0) return `${diff.minutes()} دقیقه پیش`;
    else if (diff.seconds() > 0) return `${diff.seconds()} ثانیه پیش`;
    return "";
};

export const getDateStatus = (date: string) => {
    moment.locale("fa");
    const now = moment().locale("fa");
    const _moment = moment.from(date, "en", "YYYY-MM-DDTHH:mm:ss.SSSSSS");
    const diff = moment.duration(now.diff(_moment));

    if (diff.years() > 0) return `${diff.years()} سال پیش`;
    else if (diff.months() > 0) return `${diff.months()} ماه پیش`;
    else if (diff.days() > 0) return `${diff.days()} روز پیش`;
    else if (diff.hours() > 0) return `${diff.hours()} ساعت پیش`;
    else if (diff.minutes() > 0) return `${diff.minutes()} دقیقه پیش`;
    else if (diff.seconds() > 0) return `${diff.seconds()} ثانیه پیش`;
    return "";
};

// export const getDatePhrase = (
//     date: string,
//     source: "discourse" | "roboepics",
// ) => {
//     const Moment = require("moment-timezone");
//     const now = moment(
//         Moment().tz("Asia/Tehran").format("YYYY-MM-DDTHH:mm:ss"),
//         "YYYY-MM-DDTHH:mm:ss",
//     ).locale("fa");
//     const _moment = moment.from(
//         date,
//         "fa",
//         source === "discourse"
//             ? "YYYY-MM-DDTHH:mm:ss.SSS"
//             : "YYYY-MM-DDTHH:mm:ss.SSSSSS",
//     );
//     const diff = moment.duration(now.diff(_moment));

//     if (diff.years() > 0) return `${diff.years()} سال پیش`;
//     else if (diff.months() > 0) return `${diff.months()} ماه پیش`;
//     else if (diff.days() > 0) return `${diff.days()} روز پیش`;
//     else if (diff.hours() > 0) return `${diff.hours()} ساعت پیش`;
//     else if (diff.minutes() > 0) return `${diff.minutes()} دقیقه پیش`;
//     else if (diff.seconds() > 0) return `${diff.seconds()} ثانیه پیش`;
//     return "";
// };
