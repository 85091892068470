import React, { useState, useEffect, useRef } from "react";
import { NextRouter } from "next/router";
import { useSelector } from "react-redux";
import { getParticipantSummaryCompetition } from "@util/api/competitions";
import { useDispatch } from "react-redux";
import { setParticipantId } from "@redux/actions";
import { getParticipant, createTeam, leaveTeam, deleteTeam } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Box,
    CloseButton,
    Button,
    Heading,
    Divider,
    Spinner,
    Alert,
    Fade,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Text,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { User, Users, LogOut, Trash } from "react-feather";
import { CreateModal } from "./createModal/createModal";
import { JoinModal } from "./joinModal/joinModal";
import { MembersTable } from "./membersTable";
import { IndividualInvitesTable } from "./ind_invitesTable";
import { IndividualRequestsTable } from "./ind_requestsTable";
import InviteView from "./inviteView";
import RequestsView from "./requestsView";
import { TeamInfo } from "./teamInfo";
import { getDateDifferenceMs } from "@util/dateConverter";
import { RefreshOutline } from "react-ionicons";
import { ICompData, IParticipantRetrieveData, IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";
import { ICompetition } from "@redux/slices/competition";

export const TeamView = (props: { compData: ICompData; toast: IToastOptions; router: NextRouter; user: IUser }) => {
    const dispatch = useDispatch();
    // Redux
    const competitionState = useSelector((state: { competition: ICompetition }) => state.competition);

    // Refs
    const inviteViewRef = useRef(null);
    const requestViewRef = useRef(null);
    const MembersTableRef = useRef(null);
    const ind_requestViewRef = useRef(null);
    const ind_inviteViewRef = useRef(null);

    // States
    const [data, setData] = useState<IParticipantRetrieveData>();
    const [loadingComponent, setLoadingComponent] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [createModalProps, setCreateModalProps] = useState({
        visible: false,
        loading: false,
    });
    const [joinModalProps, setJoinModalProps] = useState({
        visible: false,
        loading: false,
    });
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);

    // Functions
    const getData = async (updateParticipantId: boolean) => {
        setLoadingComponent(true);
        let participantId = competitionState.participantId;
        if (updateParticipantId) {
            const serverResponse = (await getParticipantSummaryCompetition(props.compData.path, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const { participant_id } = await serverResponse.json();
                participantId = participant_id;
                dispatch(setParticipantId(participant_id));
            } else {
                props.toast({
                    title: "مشکلی در دریافت اطلاعات شرکت‌کننده رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }
        }
        const serverResponse = (await getParticipant(participantId, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setData(data);
        }
        setLoadingComponent(false);
    };

    const onLeaveTeam = async () => {
        setConfirmLoading(true);

        const serverResponse = (await leaveTeam(competitionState.participantId, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            setConfirmLoading(false);
            props.toast({
                description: `عملیات با موفقیت انجام شد`,
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
            });
            setConfirmModalVisible(false);
            await getData(true);
        } else {
            props.toast({
                title: "مشکلی در ترک تیم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };

    const onDeleteTeam = async () => {
        setLoadingComponent(true);

        const serverResponse = (await deleteTeam(competitionState.participantId.toString(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                description: `عملیات با موفقیت انجام شد`,
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
            });
            await getData(false);
        } else {
            props.toast({
                title: "مشکلی در حذف تیم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setLoadingComponent(false);
    };

    const onCreateTeam = async (name: string) => {
        setCreateModalProps((current) => {
            return { ...current, loading: true };
        });

        const serverResponse = (await createTeam(competitionState.participantId.toString(), name, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const team = await serverResponse.json();
            setData({ ...data, team });
            props.toast({
                description: `تیم «${name}» با موفقیت ساخته شد.`,
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
            });
        } else {
            props.toast({
                title: "مشکلی در ساخت تیم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setCreateModalProps({ visible: false, loading: false });
        }
    };
    const onJoinTeam = async () => {
        setJoinModalProps((current) => {
            return { ...current, loading: true };
        });
    };

    // useEffects
    useEffect(() => {
        if (!competitionState.participantId) {
            setErrorLoading(true);
        } else {
            try {
                getData(true);
            } catch {
                setErrorLoading(true);
            }
        }
    }, []);

    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }
    if (loadingComponent) {
        return (
            <Box mx="auto" w="min">
                <Spinner my={4} />
            </Box>
        );
    }
    if (data) {
        return (
            <Box>
                {showAlert && (
                    <Alert status="warning" mb="2">
                        <AlertIcon />
                        <Box flex="1">
                            <AlertTitle>تذکر مهم!</AlertTitle>
                            <AlertDescription display="block">
                                پس از ملحق شدن به یک تیم و یا ساخت تیم جدید، امکان ترک و یا حذف آن تیم وجود ندارد. به عبارت دیگر، امکان ساخت
                                تیم جدید و یا جذب عضو جدید توسط تیم‌ها وجود دارد ولی امکان حذف یک یا چند عضو از تیم و یا تغییر نحوه شرکت از
                                تیمی به انفرادی وجود نخواهد داشت. به همین منظور، لطفاً دقت لازم را در امور مربوط به تیم‌ها به عمل آورید.
                            </AlertDescription>
                        </Box>
                        <CloseButton position="absolute" left="8px" top="8px" onClick={() => setShowAlert(false)} />
                    </Alert>
                )}
                <Box className="flex flex-row">
                    <Box className="flex items-center flex-grow">
                        <span className="">نحوه شرکت شما:</span>
                        <div className="mr-2 flex items-center">
                            {data.team.individual ? (
                                <>
                                    <User size={16} className="" />
                                    <span className="mr-2 font-bold">انفرادی</span>
                                </>
                            ) : (
                                <>
                                    <Users size={16} />
                                    <span className="mr-2 font-bold">تیمی</span>
                                </>
                            )}
                        </div>
                    </Box>
                    <Box>
                        {data.team.individual ? (
                            <>
                                <Button
                                    size="sm"
                                    variant="solid"
                                    className="ml-2"
                                    onClick={() =>
                                        setJoinModalProps((current) => {
                                            return {
                                                ...current,
                                                visible: true,
                                            };
                                        })
                                    }
                                >
                                    پیوستن به یک تیم
                                </Button>
                                <JoinModal
                                    refreshFunction={getData}
                                    isOpen={joinModalProps.visible}
                                    isLoading={joinModalProps.loading}
                                    onJoinRequest={onJoinTeam}
                                    onClose={() =>
                                        setJoinModalProps((current) => {
                                            return {
                                                ...current,
                                                visible: false,
                                            };
                                        })
                                    }
                                    toast={props.toast}
                                    router={props.router}
                                />
                                <Button
                                    size="sm"
                                    variant="solid"
                                    bg="RoboEpics.gold.400"
                                    _hover={{ bg: "RoboEpics.gold.500" }}
                                    _active={{ bg: "RoboEpics.gold.500" }}
                                    color="RoboEpics.dark.900"
                                    onClick={() =>
                                        setCreateModalProps((current) => {
                                            return {
                                                ...current,
                                                visible: true,
                                            };
                                        })
                                    }
                                >
                                    تشکیل تیم جدید
                                </Button>

                                <CreateModal
                                    isOpen={createModalProps.visible}
                                    isLoading={createModalProps.loading}
                                    onSuccess={onCreateTeam}
                                    onClose={() =>
                                        setCreateModalProps((current) => {
                                            return {
                                                ...current,
                                                visible: false,
                                            };
                                        })
                                    }
                                />
                            </>
                        ) : (
                            <>
                                {data.team.creator.username === props.user.data.username ? (
                                    <Button
                                        size="sm"
                                        variant="solid"
                                        bg="red.500"
                                        _hover={{ bg: "red.600" }}
                                        _active={{ bg: "red.600" }}
                                        color="red.50"
                                        onClick={() => {
                                            onDeleteTeam();
                                        }}
                                        isDisabled={
                                            // getDateDifferenceMs(props.compData.phase_set[0].submission_date_start) > 0 ||
                                            data.team.member_set.length > 1
                                        }
                                    >
                                        <Trash size={18} />
                                        <span className="mr-2">حذف تیم</span>
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            size="sm"
                                            variant="solid"
                                            bg="red.500"
                                            _hover={{ bg: "red.600" }}
                                            _active={{ bg: "red.600" }}
                                            color="red.50"
                                            onClick={() => {
                                                setConfirmModalVisible(true);
                                            }}
                                            isDisabled={getDateDifferenceMs(props.compData.phase_set[0].submission_date_start) > 0}
                                        >
                                            <LogOut size={18} />
                                            <span className="mr-2">ترک تیم</span>
                                        </Button>
                                        <LeaveConfirmAlert
                                            isOpen={confirmModalVisible}
                                            onClose={() => setConfirmModalVisible(false)}
                                            isLoading={confirmLoading}
                                            onSuccess={onLeaveTeam}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
                <Divider my="10px" />
                {data.team.individual ? (
                    <>
                        <Box className="flex md:flex-row md:justify-between flex-col justify-start mt-16">
                            {/* INDIVIDUAL Invites */}
                            <Box flexBasis="45%">
                                <Box display="flex" alignItems="center">
                                    <Heading as="h3" fontSize="xl">
                                        دعوت‌های دریافت‌شده
                                    </Heading>
                                    <IconButton
                                        variant="ghost"
                                        icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                                        size="sm"
                                        mr="auto"
                                        aria-label="Refresh invites list"
                                        onClick={() => {
                                            ind_inviteViewRef.current.refresh();
                                        }}
                                    />
                                </Box>

                                <Divider my="6px" />
                                <Text fontSize="14px" mt={2}>
                                    دعوت‌های دریافت‌شده از تیم‌ها:
                                </Text>

                                <IndividualInvitesTable
                                    ref={ind_inviteViewRef}
                                    compId={props.compData.id.toString()}
                                    refreshFunction={getData}
                                    showManageControls
                                    canManage
                                    router={props.router}
                                    toast={props.toast}
                                />
                            </Box>

                            {/* INDIVIDUAL Join Requests */}
                            <Box flexBasis="45%">
                                <Box display="flex" alignItems="center">
                                    <Heading as="h3" fontSize="xl">
                                        درخواست‌های ارسال شده
                                    </Heading>
                                    <IconButton
                                        variant="ghost"
                                        icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                                        size="sm"
                                        mr="auto"
                                        aria-label="Refresh invites list"
                                        onClick={() => {
                                            ind_requestViewRef.current.refresh();
                                        }}
                                    />
                                </Box>
                                <Divider my="6px" />
                                <Text fontSize="14px" mt={2}>
                                    درخواست‌های ارسال شده به تیم‌ها:
                                </Text>
                                <IndividualRequestsTable
                                    ref={ind_requestViewRef}
                                    compId={props.compData.id.toString()}
                                    toast={props.toast}
                                    router={props.router}
                                />
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        {/* Refresh Button */}
                        <Box>
                            <IconButton
                                variant="ghost"
                                icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                                size="sm"
                                float="right"
                                aria-label="Refresh invites list"
                                onClick={() => {
                                    getData(false);
                                    MembersTableRef.current.refresh();
                                }}
                            />
                        </Box>
                        {/* Edit Team */}
                        <Box>
                            <TeamInfo
                                canEdit={data.team.creator.username === props.user.data.username}
                                name={data.team.name}
                                image={data.team.image}
                                participantId={competitionState.participantId.toString()}
                                toast={props.toast}
                                router={props.router}
                            />
                        </Box>
                        {/* Members Table */}
                        <MembersTable
                            ref={MembersTableRef}
                            participantId={competitionState.participantId}
                            member_set={data.team.member_set}
                            showManageControls={data.team.creator.username === props.user.data.username}
                            canManage={
                                data.team.creator.username === props.user.data.username &&
                                getDateDifferenceMs(props.compData.phase_set[0].submission_date_start) > 0
                            }
                            refreshFunction={getData}
                            toast={props.toast}
                            user={props.user}
                            router={props.router}
                        />
                        {data.team.creator.username === props.user.data.username && (
                            <Box className="flex md:flex-row md:justify-between flex-col justify-start mt-16">
                                {/* Invites */}
                                <Box flexBasis="45%">
                                    <Box display="flex" alignItems="center">
                                        <Heading as="h3" fontSize="xl">
                                            دعوت‌ها
                                        </Heading>
                                        <IconButton
                                            variant="ghost"
                                            icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                                            size="sm"
                                            mr="auto"
                                            aria-label="Refresh invites list"
                                            onClick={() => {
                                                inviteViewRef.current.refresh();
                                            }}
                                        />
                                    </Box>
                                    <Divider my="6px" />
                                    <Text fontSize="14px" mt={2}>
                                        دعوت‌های فرستاده شده به افراد برای پیوستن به تیم در جدول قابل مشاهده است. برای فرستادن دعوت جدید،
                                        نام فرد را جست‌و‌جو کنید.
                                    </Text>
                                    <InviteView
                                        ref={inviteViewRef}
                                        participantId={competitionState.participantId.toString()}
                                        competition={data.competition}
                                        canInvite={
                                            // getDateDifferenceMs(
                                            //     props.compData.phase_set[0]
                                            //         .submission_date_start,
                                            // ) < 0
                                            true
                                        }
                                        toast={props.toast}
                                        router={props.router}
                                    />
                                </Box>

                                {/* Requests */}
                                <Box flexBasis="45%">
                                    <Box display="flex" alignItems="center">
                                        <Heading as="h3" fontSize="xl">
                                            درخواست‌ها
                                        </Heading>
                                        <IconButton
                                            variant="ghost"
                                            icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                                            size="sm"
                                            mr="auto"
                                            aria-label="Refresh invites list"
                                            onClick={() => {
                                                requestViewRef.current.refresh();
                                            }}
                                        />
                                    </Box>
                                    <Divider my="6px" />
                                    <Text fontSize="14px" mt={2}>
                                        درخواست‌های کاربران برای پیوستن به تیم شما:
                                    </Text>
                                    <RequestsView
                                        ref={requestViewRef}
                                        refreshFunction={getData}
                                        canAct={true}
                                        showManageControls
                                        participantId={competitionState.participantId.toString()}
                                        toast={props.toast}
                                        router={props.router}
                                    />
                                </Box>

                                {/* Requests */}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        );
    }
    return <div></div>;
};

const LeaveConfirmAlert = (props: { isOpen: boolean; onClose: () => void; isLoading: boolean; onSuccess: () => void }) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>ترک تیم</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>شما در حال ترک این تیم هستید. آیا ادامه می‌دهید؟</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

const DeleteTeamConfirmAlert = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    fullName: string;
    onSuccess: () => void;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>اخراج عضو از تیم</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>شما در حال اخراج {props.fullName} از تیم‌تان هستید. آیا ادامه می‌دهید؟</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
