import React from "react";
import { Box, Button, HStack, Tag, useColorModeValue } from "@chakra-ui/react";
import { CustomAvatar } from "@components";
import { getDatePhrase } from "@util/dateConverter";
import Style from "./announcementCard.module.css";
import { MegaphoneOutline, Trash } from "react-ionicons";
import { IAnnouncementData } from "@util/interfaces";
import { Edit2 } from "react-feather";

export const AnnouncementCard = (props: {
    cardInfo: IAnnouncementData;
    onClick: any;
    openViewModal: any;
    openEditModal?: any;
    openDeleteAlert?: any;
    canEdit?: boolean;
}) => {
    return (
        <Box
            className={`px-3 pb-2 py-3 flex flex-row items-center rounded-lg shadow border-r-4  mb-4 w-full cursor-pointer ${Style.competition_card_wrapper}`}
            onClick={() => {
                props.onClick();
                props.openViewModal();
            }}
            borderColor={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400")}
            _hover={{
                bg: useColorModeValue("rgba(0,0,0,0.02)", "rgba(255,255,255,0.02)"),
            }}
        >
            <div
                style={{
                    flex: "0 0 50px",
                    textAlign: "center",
                }}
            >
                <CustomAvatar
                    height="32px"
                    width="32px"
                    style={{
                        marginBottom: "10px",
                    }}
                    noBorder
                    showPlaceholder
                    placeholder={<MegaphoneOutline color={useColorModeValue("#000", "#fff")} height="24px" width="24px" />}
                    src={null}
                />
            </div>
            <div
                className="pr-2 text-right"
                style={{
                    flex: "0 1 calc(100% - .75rem)",
                    width: "calc(100% - 0.75rem - 50px)",
                }}
            >
                <div className={`w-full flex flex-row justify-between items-center ${Style.main_status_container}`}>
                    <div className={`flex flex-col w-max overflow-x-auto ${Style.main_title_container}`}>
                        <Box>
                            <h3
                                className="truncate font-semibold w-min"
                                style={{
                                    fontSize: "15px",
                                }}
                            >
                                {props.cardInfo.title}
                            </h3>
                            {/* {props.cardInfo.tags.map((tag) => {
                                return (
                                    <Tag ml="3" size="sm">
                                        {tag}
                                    </Tag>
                                );
                            })} */}
                        </Box>
                        <div className="flex items-center">
                            <span
                                className="mt-1 truncate"
                                style={{
                                    color: "#898990",
                                    fontSize: "13px",
                                }}
                            >
                                {getDatePhrase(props.cardInfo.date_created, "roboepics")}
                            </span>
                            {/* <div className="inline-block w-1 h-1 rounded-full bg-gray-300 mx-2" />
                            <span
                                className="mt-1 truncate"
                                style={{
                                    color: "#898990",
                                    fontSize: "13px",
                                }}
                            >
                                {props.cardInfo.total_participants} شرکت کننده
                            </span> */}
                        </div>
                    </div>
                    {props.canEdit && (
                        <HStack>
                            <Button
                                size="sm"
                                variant="ghost"
                                colorScheme={"red"}
                                onClick={props.openDeleteAlert}
                                leftIcon={
                                    <Trash
                                        color={useColorModeValue("var(--chakra-colors-red-600)", "var(--chakra-colors-red-200)")}
                                        width="16px"
                                        height="16px"
                                    />
                                }
                            >
                                حذف
                            </Button>
                            <Button size="sm" onClick={props.openEditModal} leftIcon={<Edit2 size="15" />}>
                                ویرایش
                            </Button>
                        </HStack>
                    )}
                </div>
            </div>
        </Box>
    );
};
