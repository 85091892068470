import { useTheme } from "@chakra-ui/system";
import {
    Input,
    Button as ChakraButton,
    Link as ChakraLink,
    Heading,
    Collapse,
    Alert,
    AlertIcon,
    AlertDescription,
    useMediaQuery,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { NextRouter } from "next/router";
import Link from "next/link";
import { UserForgotPassword } from "@util/api/auth";

import Style from "./forgot.module.css";

export const ForgotPassword = (props: { router: NextRouter }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const bg = useColorModeValue(theme.colors.RoboEpics.black.default, theme.colors.RoboEpics.gold["400"]);
    const btnTxt = useColorModeValue("#fff", theme.colors.RoboEpics.black.default);

    const [email, setEmail] = useState("");
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const onRequestResetPassword = async () => {
        setLoading(true);
        setDisabled(true);
        if (email === "") {
            setAlertProps({
                open: true,
                message: "پست الکترونیکی خود را وارد کنید.",
                status: "error",
            });
            setLoading(false);
            setDisabled(false);
        } else {
            const serverResponse = await UserForgotPassword(email);
            if (serverResponse.status === 200) {
                setAlertProps({
                    open: true,
                    message:
                        "ایمیلی حاوی لینک بازنشانی رمز عبور، برای شما ارسال شد. اگر این ایمیل را در پوشه inbox خود دریافت نکردید، لطفاً پوشه اسپم (Spam) پست الکترونیکی خود را نیز چک کنید.",
                    status: "success",
                });
                setLoading(false);
                setDisabled(false);
            } else {
                setAlertProps({
                    open: true,
                    message: "شما در RoboEpics ثبت نام نکرده‌اید",
                    status: "error",
                });
                setLoading(false);
                setDisabled(false);
            }
        }
    };

    const [isGreaterThan332px] = useMediaQuery("(min-width: 332px)");
    return (
        <div
            className={`flex flex-col pt-10 pb-8 px-10 w-full items-center mx-auto justify-center mr-8 ${Style.forgot_password_form_wrapper}`}
        >
            <div className={`w-3/5 py-12 px-8 rounded-md ${Style.card}`}>
                <div className="mb-6">
                    <Heading as="h2" size={isGreaterThan332px ? "lg" : "md"} className="auth-form-supertitle">
                        فراموشی رمز عبور{" "}
                    </Heading>
                    <Heading as="h4" size="xs" fontWeight="300" className="auth-form-title">
                        پست الکترونیکی که با آن در <b>RoboEpics</b> ثبت نام کرده‌اید را وارد کنید.
                    </Heading>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="auth-form-inputs flex flex-col mb-3">
                        <Collapse in={alertProps.open}>
                            <Alert status={alertProps.status} variant={colorMode === "dark" ? "subtle" : "solid"}>
                                <AlertIcon />
                                <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                            </Alert>
                        </Collapse>
                        <Input
                            dir="ltr"
                            _placeholder={{ textAlign: "right" }}
                            className="my-3"
                            type="email"
                            placeholder="پست الکترونیکی"
                            variant="filled"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            id="forgot-email-input"
                        />
                    </div>

                    <div className="forgot-button mb-6">
                        <ChakraButton
                            type="submit"
                            isDisabled={disabled}
                            isLoading={loading}
                            bg={bg}
                            color={btnTxt}
                            variant="solid"
                            isFullWidth
                            onClick={onRequestResetPassword}
                            _hover={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                            _active={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                            _focus={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                        >
                            ارسال لینک
                        </ChakraButton>
                    </div>
                </form>

                <div className="forgot-link-to-signup flex flex-col items-center">
                    <Link href={{ pathname: "/users/signin", query: { ...props.router.query } }} passHref>
                        <ChakraLink
                            className="py-1"
                            _hover={{
                                color: colorMode === "dark" ? theme.colors.RoboEpics.azure["200"] : theme.colors.RoboEpics.turquoise["600"],
                            }}
                        >
                            وارد RoboEpics شوید
                        </ChakraLink>
                    </Link>

                    <h3
                        className="w-2/3 text-center"
                        style={{
                            borderBottom: "1px solid gray",
                            lineHeight: "0.1rem",
                            margin: "1.5rem 0",
                        }}
                    >
                        <span
                            className="px-4"
                            style={{
                                backgroundColor: useColorModeValue("#F7f7f8", "#14181d"),
                            }}
                        >
                            یا
                        </span>
                    </h3>
                    <Link href="/users/signup" passHref>
                        <ChakraLink
                            className="py-1"
                            _hover={{
                                color: colorMode === "dark" ? theme.colors.RoboEpics.azure["200"] : theme.colors.RoboEpics.turquoise["600"],
                            }}
                        >
                            {" "}
                            همین حالا ثبت‌نام کنید!
                        </ChakraLink>
                    </Link>
                </div>
            </div>
        </div>
    );
};
