import React, { useEffect, useState } from "react";
import Link from "next/link";
import { USER_SOCIALS } from "@util/constants";
import { NextRouter } from "next/router";
import { CustomAvatar } from "@components";
import { ProfileTabsPanel } from "./tabs/tabs";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Fade,
    IconButton,
    Link as ChakraLink,
    Spinner,
    Text,
    useColorModeValue,
    useMediaQuery,
} from "@chakra-ui/react";
import {
    CalendarOutline,
    EarthOutline,
    FlagOutline,
    LocationOutline,
    MailOutline,
    CreateOutline,
    CheckmarkCircle,
    SchoolOutline,
} from "react-ionicons";
import { getImageURL } from "@util/mediaLoader";
import { convertDate } from "@util/dateConverter";
import classes from "./ProfileView.module.css";
import { ReportModal } from "./reportModal";
import { IToastOptions, IUserData } from "@util/interfaces";
import { getCookie } from "@util/cookies";
import { refreshAccessToken } from "@util/RoboEpicsAuthHandler";

export const ProfileView = (props: { data: IUserData; toast: IToastOptions; router: NextRouter }) => {
    const accessToken = getCookie("re_auth_access_token");
    const [isMyAccount, setIsMyAccount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [componentErrorLoading, setComponentErrorLoading] = useState(false);

    const userData = props.data;

    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [messageModalVisible, setMessageModalVisible] = useState(false);

    const setData = async () => {
        if (accessToken?.length > 0) {
            const serverResponse = await refreshAccessToken({ toast: props.toast });
            if (serverResponse !== null) {
                setIsMyAccount(
                    JSON.parse(Buffer.from(serverResponse.accessToken.split(".")[1], "base64").toString("utf8")).preferred_username ===
                        props.data.username,
                );
                setComponentLoading(false);
            }
        } else setIsMyAccount(false);
    };

    useEffect(() => {
        setData();
    }, []);

    const bg = "RoboEpics.dark.600";
    const [isGreaterThan769px, isGreaterThan426px] = useMediaQuery(["(min-width: 769px)", "(min-width: 426px)"]);

    if (componentLoading) {
        return (
            <Box as="div" className="flex flex-col w-full h-full mx-auto items-center">
                <Spinner mt={4}></Spinner>
            </Box>
        );
    }

    if (componentErrorLoading) {
        return (
            <Fade in>
                <Alert status="error">
                    <AlertIcon />

                    <AlertDescription>مشکلی در دریافت اطلاعات پروفایل رخ داد.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    return (
        <Box as="div" className="flex flex-col w-full h-full mx-auto items-center">
            <Box className="w-full py-2" bg={useColorModeValue("#f4f4f5", bg)}>
                <Box
                    as="div"
                    className={`flex flex-row h-full mx-auto py-4 bg px-2 ${classes.info_container}`}
                    style={{
                        maxWidth: "750px",
                    }}
                    bg={useColorModeValue("#f4f4f5", bg)}
                >
                    <Box as="div" className={`flex flex-col items-center ml-4 ${classes.profile_picture}`}>
                        <CustomAvatar
                            src={getImageURL(userData.profile_picture)}
                            showPlaceholder={!userData.profile_picture}
                            placeholder={<Avatar size="full" />}
                            className="mb-2 px-0 py-0 border-transparent border-0"
                            style={{
                                width: isGreaterThan769px ? "80px" : "100px",
                                height: isGreaterThan769px ? "80px" : "100px",
                            }}
                        />
                    </Box>
                    <Box as="div" className="flex flex-col w-full">
                        <div className={`flex flex-row justify-between items-center mb-1  ${classes.title_wrapper}`}>
                            <div className={`flex items-center`}>
                                <Text fontWeight={700} fontSize="2xl" mb={0}>
                                    {userData.full_name}
                                </Text>
                                {props.data.is_verified && (
                                    <CheckmarkCircle
                                        color="#1a9adb"
                                        width="22px"
                                        height="22px"
                                        cssClasses={isGreaterThan426px ? "mr-3" : ""}
                                    />
                                )}
                            </div>
                            {isMyAccount ? (
                                <Button
                                    isLoading={isLoading}
                                    size={isGreaterThan769px ? "sm" : "xs"}
                                    leftIcon={isGreaterThan426px && <CreateOutline color={useColorModeValue("#000", "#fff")} />}
                                    onClick={() => {
                                        setIsLoading(true), props.router.push("/profile/edit");
                                    }}
                                >
                                    {isGreaterThan426px ? "ویرایش پروفایل" : "ویرایش"}{" "}
                                </Button>
                            ) : (
                                <div className={`flex flex-row justify-end ${classes.misc_buttons}`}>
                                    <IconButton
                                        isDisabled
                                        aria-label="send-message-btn"
                                        size={isGreaterThan769px ? "sm" : "xs"}
                                        p={isGreaterThan426px ? 1 : 0}
                                        ml={1}
                                        variant={useColorModeValue("ghost", "ghost")}
                                        colorScheme={"RoboEpics.turquoise"}
                                        onClick={() => {
                                            setMessageModalVisible(true);
                                        }}
                                        icon={
                                            <MailOutline color={useColorModeValue("RoboEpics.turqouise.500", "RoboEpics.turqouise.300")} />
                                        }
                                    />
                                    <IconButton
                                        aria-label="report-btn"
                                        size={isGreaterThan769px ? "sm" : "xs"}
                                        p={isGreaterThan426px ? 1 : 0}
                                        mr={1}
                                        variant={useColorModeValue("ghost", "ghost")}
                                        colorScheme={"red"}
                                        onClick={() => {
                                            setReportModalVisible(true);
                                        }}
                                        icon={
                                            <FlagOutline color={useColorModeValue("RoboEpics.turqouise.500", "RoboEpics.turqouise.300")} />
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className={`flex items-center mb-2 ${classes.user_social_wrapper}`}>
                            <div>
                                <span
                                    // className="font-semibold"
                                    style={{
                                        direction: "ltr",
                                        color: "#898989",
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    {userData.username + " @"}
                                </span>
                            </div>
                            {userData.links.length !== 0 && (
                                <div
                                    className={`mr-6 flex items-center ${classes.socialWrapper}`}
                                    data-color={useColorModeValue("light", "dark")}
                                >
                                    {userData.links.map((el, index) => {
                                        return (
                                            <span data-logo={el.title} className="mr-2 cursor-pointer" key={index}>
                                                <a href={el.link} target="_blank">
                                                    {React.cloneElement(USER_SOCIALS[el.title].iconElement, {
                                                        width: "18px",
                                                        height: "18px",
                                                        color: "#898989",
                                                    })}
                                                </a>
                                            </span>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        {userData.biography && (
                            <div className="mb-4">
                                <span>{userData.biography}</span>
                            </div>
                        )}

                        <div className={`flex flex-row items-center justify-start ${classes.metaWrapper}`}>
                            {userData.city && (
                                <div className="flex">
                                    <LocationOutline color="#898989" height="20px" width="20px" />
                                    <span className="mr-1">{userData.city}</span>
                                </div>
                            )}
                            {userData.university && (
                                <div className="flex">
                                    <SchoolOutline color="#898989" height="21px" width="21px" />
                                    <span className="mr-2">{userData.university}</span>
                                </div>
                            )}
                            {userData.website && (
                                <div className="flex">
                                    <EarthOutline color="#898989" height="20px" width="20px" />
                                    <Link passHref href="https://google.com">
                                        <ChakraLink>
                                            <span className="mr-2">{userData.website}</span>
                                        </ChakraLink>
                                    </Link>
                                </div>
                            )}
                            {userData.date_joined && (
                                <div className="flex">
                                    <CalendarOutline color="#898989" height="18px" width="18px" />
                                    <span className="mr-2">{convertDate(userData.date_joined, "MMMM YYYY")}</span>
                                </div>
                            )}
                        </div>
                    </Box>
                </Box>
            </Box>
            <ProfileTabsPanel userData={userData} isMyAccount={isMyAccount} toast={props.toast} router={props.router} />

            <ReportModal
                isOpen={reportModalVisible}
                onClose={() => {
                    setReportModalVisible(false);
                }}
                fusion_user_id={props.data.fusion_user_id}
                router={props.router}
                toast={props.toast}
            />
        </Box>
    );
};
