import React, { useState } from "react";
import {
    Box,
    Button,
    FormLabel,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Input,
    Select,
} from "@chakra-ui/react";
import Style from "./settings.module.css";
import { ChevronDownIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { PROBLEM_SCORING_METRICS } from "@util/constants";

export const EvaluationScoringMetrics = () => {
    const [scoringMetric, setScoringMetric] = useState(null);
    return (
        <HStack className={`w-full  ${Style.eval_scoring_metric_container}`}>
            <HStack spacing={0} ml={16}>
                <FormLabel fontSize="16px" fontWeight="bold">
                    متریک امتیازدهی
                </FormLabel>
                <Popover placement="bottom" trigger="hover">
                    <PopoverTrigger>
                        <IconButton
                            icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                            bg="transparent"
                            variant="ghost"
                            aria-label="question-repo"
                            size="xs"
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverBody>معیاری که برای محاسبه کردن امتیازهای چالش استفاده می‌شود.</PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
            {/* <Menu>
                <MenuButton
                    as={Button}
                    size="sm"
                    textAlign={"right"}
                    placeholder={"انتخاب کنید..."}
                >
                    {scoringMetric ? scoringMetric : "انتخاب کنید..."}
                    <ChevronDownIcon mr={4} />
                </MenuButton>
                <MenuList>
                    {PROBLEM_SCORING_METRICS.map((metric, index) => {
                        return (
                            <MenuItem
                                key={`scoring-metric-${index}`}
                                onClick={() => {
                                    setScoringMetric(
                                        PROBLEM_SCORING_METRICS[index].title,
                                    );
                                }}
                            >
                                {metric.title}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu> */}
            <Select placeholder={"انتخاب کنید..."} w={"50%"} className={`${Style.eval_custom_select}`}>
                {PROBLEM_SCORING_METRICS.map((metric, index) => {
                    return (
                        <option
                            key={`scoring-metric-${index}`}
                            onClick={() => {
                                setScoringMetric(PROBLEM_SCORING_METRICS[index].title);
                            }}
                        >
                            {metric.title}
                        </option>
                    );
                })}
            </Select>
        </HStack>
    );
};
