import React, { useState } from "react";
import {
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Checkbox,
    Button as ChakraButton,
    Link as ChakraLink,
    Heading,
    Collapse,
    Alert,
    AlertIcon,
    AlertDescription,
    useMediaQuery,
    useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { NextRouter } from "next/router";
import Style from "./login.module.css";
import { resendVerificationEmail, UserLogin } from "@util/api/auth";
import * as CookiesUtil from "@util/cookies";
import { updateProfileState } from "@util/api/profile";

export const Login = (props: { router: NextRouter }) => {
    const [values, setValues] = useState({
        email: "",
        password: "",
        rememberMe: false,
        showPassword: false,
    });
    const [errorType, setErrorType] = useState<string>("");
    const [resendLoading, setResendLoading] = useState<boolean>(false);
    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const onLogin = async () => {
        if (values.email.length * values.password.length === 0) {
            setAlertProps({
                open: true,
                message: "لطفا همه فیلدهای فرم ورود را پر کنید.",
                status: "error",
            });
        } else {
            setLoading(true);
            setErrorType("");

            const serverResponse = (await UserLogin(values.email, values.password)) as Response;
            const data = await serverResponse.json();

            if (serverResponse.ok) {
                setAlertProps({
                    open: true,
                    message: "با موفقیت وارد شدید!",
                    status: "success",
                });

                CookiesUtil.setCookie("re_auth_access_token", data.token, values.rememberMe ? 90 : "session", "strict");
                CookiesUtil.setCookie("re_auth_refresh_token", data.refreshToken, values.rememberMe ? 90 : "session", "strict");

                const date = new Date();
                CookiesUtil.setCookie(
                    "re_auth_access_token_expire",
                    String(date.getTime() + 45 * 60 * 1000),
                    values.rememberMe ? 90 : "session",
                    "strict",
                );
                await updateProfileState();
                setDisabled(true);
                if (props.router.query.returnUrl) {
                    props.router.push(props.router.query.returnUrl.toString());
                } else props.router.push("/");
            } else {
                switch (data[0]) {
                    case "User's email has not been verified!":
                        setErrorType("verification");
                        setAlertProps({
                            open: true,
                            message: "شما هنوز اکانت خود را فعالسازی نکرده‌اید! برای ارسال دوباره ایمیل فعالسازی روی دکمه زیر کلیک کنید.",
                            status: "error",
                        });
                        break;
                    case "User is not active!":
                        setErrorType("activation");
                        setAlertProps({
                            open: true,
                            message: "اکانت شما غیرفعال شده است. برای دریافت اطلاعات بیشتر با پشتیبانی تماس بگیرید.",
                            status: "error",
                        });
                        break;
                    default:
                        setErrorType("");
                        setAlertProps({
                            open: true,
                            message: "اطلاعات وارد شده نادرست است!",
                            status: "error",
                        });
                        break;
                }
            }
            setLoading(false);
        }
    };
    const onResendVerificationEmail = async () => {
        setResendLoading(true);
        const serverResponse = (await resendVerificationEmail(values.email)) as Response;
        switch (serverResponse.status) {
            case 201:
                setErrorType("");
                setResendLoading(false);
                setAlertProps({
                    open: true,
                    message:
                        "لینک فعالسازی برای شما با موفقیت ارسال شد! اگر این ایمیل را در پوشه inbox خود دریافت نکردید، لطفاً پوشه اسپم (Spam) پست الکترونیکی خود را نیز چک کنید.",
                    status: "info",
                });
                break;
            case 400:
                setErrorType("activation");
                setResendLoading(false);
                setAlertProps({
                    open: true,
                    message: "ایمیل شما در سامانه موجود نیست. ثبت‌نام کنید و یا با پشتیبانی تماس بگیرید.",
                    status: "error",
                });
                break;
            default:
                setResendLoading(false);
                break;
        }
    };

    const [isGreaterThan350px] = useMediaQuery("(min-width: 350px)");

    return (
        <div className={`flex flex-col pt-10 pb-8 px-10 w-full items-center mx-auto justify-center mr-8 ${Style.login_form_wrapper}`}>
            <div className={`w-3/5 py-12 px-8 rounded-md ${Style.card}`}>
                <div className="mb-6 ">
                    <Heading as="h2" size={isGreaterThan350px ? "lg" : "md"} className="auth-form-supertitle">
                        ورود به حساب کاربری
                    </Heading>
                    <Heading as="h4" size="xs" fontWeight="300" className="auth-form-title">
                        اطلاعات ورود خود را وارد کنید.
                    </Heading>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className="auth-form-inputs flex flex-col mb-3">
                        <Collapse in={alertProps.open}>
                            <Alert status={alertProps.status} variant={useColorModeValue("solid", "subtle")}>
                                <AlertIcon />
                                <AlertDescription
                                    mr={2}
                                    display="flex"
                                    flexDir="column"
                                    w="inherit"
                                    alignItems="flex-start"
                                    textAlign="right"
                                >
                                    {alertProps.message}
                                    {errorType === "verification" && (
                                        <ChakraButton
                                            placeSelf="flex-end"
                                            mt={4}
                                            size="xs"
                                            isLoading={resendLoading}
                                            variant="solid"
                                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                            onClick={onResendVerificationEmail}
                                        >
                                            ارسال دوباره لینک فعالسازی
                                        </ChakraButton>
                                    )}{" "}
                                    {errorType === "activation" && (
                                        <ChakraButton
                                            placeSelf="flex-end"
                                            mt={4}
                                            size="xs"
                                            isLoading={resendLoading}
                                            variant="solid"
                                            colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.turquoise")}
                                            onClick={() => props.router.push("/contact")}
                                        >
                                            تماس با پشتیبانی{" "}
                                        </ChakraButton>
                                    )}
                                </AlertDescription>
                            </Alert>
                        </Collapse>

                        <Input
                            dir="ltr"
                            className="my-3"
                            type="text"
                            _placeholder={{
                                textAlign: "right",
                            }}
                            placeholder="نام کاربری یا پست الکترونیکی"
                            variant="filled"
                            value={values.email}
                            onChange={handleChange("email")}
                            id="login-email-input"
                        />
                        <InputGroup size="md" className="my-3">
                            <Input
                                value={values.password}
                                onChange={handleChange("password")}
                                variant="filled"
                                type={values.showPassword ? "text" : "password"}
                                placeholder="رمز عبور"
                            />
                            <InputRightElement bg={"transparent"}>
                                <IconButton
                                    bg={"transparent"}
                                    aria-label="login-show-password"
                                    size="sm"
                                    icon={values.showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    onClick={handleShowPassword}
                                ></IconButton>
                            </InputRightElement>
                        </InputGroup>
                    </div>
                    <div className={`flex flex-row justify-between items-center w-full mb-3 ${Style.remember_me}`}>
                        <Checkbox
                            fontWeight="100"
                            size="md"
                            isDisabled={loading}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            isChecked={values.rememberMe}
                            onChange={(event) =>
                                setValues({
                                    ...values,
                                    rememberMe: event.target.checked,
                                })
                            }
                        >
                            مرا به خاطر بسپار
                        </Checkbox>
                        <Link href="/users/forgotpassword">
                            <ChakraLink
                                colorScheme=""
                                _hover={{
                                    color: useColorModeValue("RoboEpics.azure.200", "RoboEpics.turquoise.600"),
                                }}
                            >
                                فراموشی رمز عبور
                            </ChakraLink>
                        </Link>
                    </div>
                    <div className="login-button mb-6">
                        <ChakraButton
                            type="submit"
                            isDisabled={disabled}
                            isLoading={loading}
                            variant="solid"
                            isFullWidth
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            onClick={onLogin}
                        >
                            ورود
                        </ChakraButton>
                    </div>
                </form>
                {/* <Divider>یا</Divider> */}
                <div className="login-link-to-signup">
                    <Link href={{ pathname: "/users/signup", query: { ...props.router.query } }}>
                        <ChakraLink
                            colorScheme=""
                            _hover={{
                                color: useColorModeValue("RoboEpics.azure.200", "RoboEpics.turquoise.600"),
                            }}
                        >
                            همین حالا ثبت‌نام کنید!
                        </ChakraLink>
                    </Link>
                </div>
            </div>
        </div>
    );
};
