import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    IconButton,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import DatePicker, { Calendar, utils } from "./lib/index";
import {
    CalendarDigit,
    CalendarProps,
    DatePickerProps,
    Day,
    DayRange,
    DayValue,
    Locale,
    RenderInputProps,
    Utils,
} from "react-modern-calendar-datepicker";

import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Style from "./datePicker.module.css";
import moment from "jalali-moment";

export const RoboEpicsDatePicker = (props: {
    initDayValue?: Day;
    initTimeValue?: { hour: number; minute: number };
    initDayRangeValue?: DayRange;
    initTimeRangeValue?: {
        from: { hour: number; minute: number };
        to: { hour: number; minute: number };
    };
    isDisabled?: boolean;
    initDaysValue?: Day[];
    isCalendar?: boolean;
    type: "day" | "day-range" | "days";
    setPropDate: any;
    placeholder?: string;
    placement?: "top" | "bottom";
}): JSX.Element => {
    const [defaultDate, setDefaultDate] = useState(props.initDayValue);
    const [defaultTime, setDefaultTime] = useState(props.initTimeValue ? props.initTimeValue : { hour: 0, minute: 0 });
    const [selectedDay, setSelectedDay] = useState<Day>(props.type === "day" && props.initDayValue ? props.initDayValue : null);
    const [selectedTime, setSelectedTime] = useState({
        hour: props.type === "day" && props.initTimeValue?.hour >= 0 ? props.initTimeValue.hour : "0",
        minute: props.type === "day" && props.initTimeValue?.minute >= 0 ? props.initTimeValue.minute : "0",
    });
    const [selectedDayRange, setSelectedDayRange] = useState<DayRange>(
        props.type === "day-range" && props.initDayRangeValue
            ? props.initDayRangeValue
            : {
                  from: null,
                  to: null,
              },
    );
    const [selectedTimeRange, setSelectedTimeRange] = useState({
        from: { hour: "", minute: "" },
        to: { hour: "", minute: "" },
    });

    const [selectedDays, setSelectedDays] = useState<Day[]>(props.type === "days" && props.initDaysValue ? props.initDaysValue : []);

    const [locale, setLocale] = useState<"fa" | "en">("fa");

    const monthsFa = ["فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
    const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const getSingleDatePhrase = (date: Day) => {
        return `${date.day} ${locale === "fa" ? monthsFa[date.month - 1] : monthsEn[date.month - 1]} ${locale === "fa" ? "ماه" : ""} ${
            date.year
        }  ساعت ${selectedTime.minute >= 0 ? (selectedTime.minute >= 10 ? selectedTime.minute : `0${selectedTime.minute}`) : "00"} : ${
            selectedTime.hour >= 0 ? (selectedTime.hour >= 10 ? selectedTime.hour : `0${selectedTime.hour}`) : "00"
        }`;
    };
    const customDatepickerInput = ({ ref }) => {
        return (
            <InputGroup>
                <Input
                    ref={ref}
                    isReadOnly
                    value={selectedDay ? getSingleDatePhrase(selectedDay) : ``}
                    variant="filled"
                    placeholder={props.placeholder ? props.placeholder : "انخاب زمان"}
                    minW={"17rem"}
                    isDisabled={props.isDisabled}
                />
                <InputLeftElement isDisabled={props.isDisabled} pointerEvents="none" children={<CalendarIcon />} />
            </InputGroup>
        );
    };
    const DatePickerFooter = () => {
        const ref = useRef();
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "0em 2em 2em",
                }}
            >
                <FormControl>
                    <FormLabel fontSize="12px">زمان :</FormLabel>
                    <HStack spacing={4}>
                        <NumberInput
                            ref={ref}
                            min={0}
                            max={59}
                            step={1}
                            size="sm"
                            value={selectedTime.minute}
                            onChange={(val) => {
                                setSelectedTime({
                                    ...selectedTime,
                                    minute: val,
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Text fontSize="2xl"> : </Text>
                        <NumberInput
                            min={0}
                            max={23}
                            step={1}
                            size="sm"
                            value={selectedTime.hour}
                            onChange={(val) => {
                                setSelectedTime((current) => {
                                    return { ...current, hour: val };
                                });
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </HStack>
                    <FormHelperText>زمان مورد نظر خود را به فرمت 24 ساعته وارد کنید</FormHelperText>
                </FormControl>
                <Button
                    colorScheme="red"
                    size="sm"
                    mt={4}
                    isDisabled={!defaultDate}
                    alignSelf="flex-end"
                    onClick={() => {
                        setSelectedDay(defaultDate);
                        setSelectedTime(defaultTime);
                        if (defaultDate) props.setPropDate(defaultDate, defaultTime, "fa");
                    }}
                >
                    بازنشانی
                </Button>
            </div>
        );
    };

    useEffect(() => {
        if (props.type === "day") {
            props.setPropDate(selectedDay, selectedTime, locale);
        }
        if (props.type === "day-range") {
            props.setPropDate(selectedDayRange, selectedTime, locale);
        }
        if (props.type === "days") {
            props.setPropDate(selectedDays, selectedTime, locale);
        }
    }, [props.type, selectedDay, selectedDayRange, selectedDays, selectedTime]);
    return (
        <div className={useColorModeValue(Style.container, Style.darkContainer)}>
            <DatePicker
                value={selectedDay}
                onChange={setSelectedDay}
                renderInput={customDatepickerInput}
                shouldHighlightWeekends
                locale={locale}
                colorPrimary={useColorModeValue("var(--chakra-colors-RoboEpics-azure-500)", "var(--chakra-colors-RoboEpics-gold-200)")}
                colorPrimaryLight={useColorModeValue("var(--chakra-colors-RoboEpics-azure-100)", "var(--chakra-colors-RoboEpics-gold-100)")}
                renderFooter={DatePickerFooter}
                calendarClassname={Style.meme}
                calendarTodayClassName={Style.today}
                calendarSelectedDayClassName={Style.selected}
                wrapperClassName={`${props.placement === "bottom" ? `${Style.wrapperbottom}` : ""}`}
            />
        </div>
    );
};
export const convertServer2DatePicker = (date: string, locale?: "fa" | "en") => {
    if (!date) {
        return null;
    }
    let year: number;
    let month: number;
    let day: number;
    let hour: number;
    let minute: number;
    const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
    const rawDate = new Date(date);

    const extractedDate = p2e(rawDate.toLocaleDateString(locale ? locale : "fa"));
    const time = p2e(rawDate.toLocaleTimeString(locale ? locale : "fa"));
    let arr = [];
    let timeArr = [];
    switch (locale) {
        case "fa":
            arr = extractedDate.split("/");
            year = parseInt(arr[0]);
            month = parseInt(arr[1]);
            day = parseInt(arr[2]);
            timeArr = time.split(":");
            hour = parseInt(timeArr[0]);
            minute = parseInt(timeArr[1]);
            break;
        case "en":
            arr = extractedDate.split("/");
            year = parseInt(arr[2]);
            month = parseInt(arr[0]);
            day = parseInt(arr[1]);
            timeArr = time.split(":");
            hour = timeArr[2].includes("PM") ? parseInt(timeArr[0]) + 12 : parseInt(timeArr[0]);
            minute = parseInt(timeArr[1]);
            break;
        default:
            arr = extractedDate.split("/");
            year = parseInt(arr[0]);
            month = parseInt(arr[1]);
            day = parseInt(arr[2]);
            timeArr = time.split(":");
            hour = parseInt(timeArr[0]);
            minute = parseInt(timeArr[1]);
            break;
    }
    return {
        date: { year: year, month: month, day: day },
        time: { hour: hour, minute: minute },
        locale: locale ? locale : "fa",
    };
};
export const convertDatePicker2Json = (date: Day, time: { hour: number; minute: number }, locale: "fa" | "en") => {
    if (!date) return null;
    let serverDate: string;
    if (locale === "fa") {
        serverDate = moment
            .from(`${date.year}/${date.month}/${date.day} ${time.hour}:${time.minute}`, "fa", "YYYY/M/D HH:mm")
            .format(date.month > 6 ? "YYYY-MM-DDTHH:mm:ss+04:30" : "YYYY-MM-DDTHH:mm:ss+03:30");
    }
    if (locale === "en") {
        serverDate = `${date.year}-${date.month >= 10 ? date.month : `0${date.month}`}-${date.day >= 10 ? date.day : `0${date.day}`}T${
            time.hour
        }:${time.minute}:${date.month > 6 ? "00+04:30" : "00+03:30"}`;
    }
    return serverDate;
};
