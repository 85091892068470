import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    Collapse,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tag,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { X, Edit2, AlertOctagon, Check } from "react-feather";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { problemEditJson } from "@util/api/problems";
import { searchTags } from "@util/api/tags";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

const arrayEquity = (a: string[], b: string[]): boolean =>
    Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);

export const TagsModal = (props: { tags: Array<string>; toast: IToastOptions; router: NextRouter; path: string }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [tags, setTags] = useState(props.tags);
    const [tagsModalVisible, setTagsModalVisible] = useState(false);
    const [searchResult, setSearchResult] = useState<{
        results: Array<{ name: string; slug: string }>;
        total: number;
    }>({ results: [], total: 0 });
    const [showSearchResult, setShowSearchResult] = useState(false);
    const deleteTag = (deletedTag: string) => {
        const newTags = tags.filter((tag) => tag !== deletedTag);
        setTags(newTags);
    };
    const onConfirm = async () => {
        const data = { tags: JSON.stringify(tags) };
        setIsLoading(true);
        const serverResponse = (await problemEditJson(data, props.router.query.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            setTagsModalVisible(false);
        } else {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: "مشکلی در اعمال تغییرات شما به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
            });
            setTags(props.tags);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        setIsDisabled(arrayEquity(tags, props.tags));
    }, [tags]);
    return (
        <>
            {tags.length !== 0 ? (
                <Box className="flex flex-row items-start">
                    <Box maxWidth="480px" display={"flex"} flexWrap={"wrap"}>
                        {tags.length > 3 ? (
                            <>
                                {tags.slice(0, 3).map((tag, index) => {
                                    return (
                                        <Tag
                                            key={`${props.router.query.problem}-tag-${index}`}
                                            colorScheme={useColorModeValue("blue", "cyan")}
                                            className={`${index === 0 ? "ml-1" : "mx-1"}`}
                                            width="fit-content"
                                        >
                                            {tag}
                                        </Tag>
                                    );
                                })}
                                <Tag ml={2} mr={1} colorScheme={useColorModeValue("blue", "cyan")}>
                                    + {tags.length - 3} تگ بیشتر
                                </Tag>
                            </>
                        ) : (
                            tags.map((tag, index) => {
                                return (
                                    <Tag
                                        key={`${props.router.query.problem}-tag-${index}`}
                                        colorScheme={useColorModeValue("blue", "cyan")}
                                        mx={2}
                                        width="fit-content"
                                        mb={4}
                                    >
                                        {tag}
                                    </Tag>
                                );
                            })
                        )}
                    </Box>
                    <Box className="flex flex-row justify-between items-center" maxW="480px">
                        <Button
                            size="xs"
                            colorScheme={useColorModeValue("blue", "cyan")}
                            variant="outline"
                            leftIcon={<Edit2 size="14" />}
                            onClick={() => {
                                setTagsModalVisible(true);
                            }}
                        >
                            ویرایش
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box className="flex flex-row justify-between items-center" maxW="480px">
                    <Button
                        size="xs"
                        colorScheme={useColorModeValue("blue", "cyan")}
                        variant="outline"
                        leftIcon={<AddIcon />}
                        onClick={() => {
                            setTagsModalVisible(true);
                        }}
                        mr={2}
                    >
                        تگ جدید
                    </Button>
                </Box>
            )}

            <Modal
                isOpen={tagsModalVisible}
                onClose={() => {
                    setTagsModalVisible(false);
                    setTags(props.tags);
                }}
                isCentered
                size={"xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ویرایش تگ‌های چالش</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TagsSearchFormControl
                            setSearchResult={setSearchResult}
                            setShowSearchResult={setShowSearchResult}
                            toast={props.toast}
                            router={props.router}
                        />
                        <Box className={`flex flex-row w-full h-full justify-between py-4`}>
                            {showSearchResult ? (
                                <Box className={`w-1/2`} maxH={48} overflowY="auto">
                                    {searchResult.total ? (
                                        <VStack spacing={1}>
                                            {searchResult.results.map((item) => {
                                                return (
                                                    <Box
                                                        className={`flex flex-row w-full justify-between p-4`}
                                                        key={item.slug}
                                                        _hover={{
                                                            cursor: "pointer",
                                                            backgroundColor: useColorModeValue("#fbfbfb", "#20262c"),
                                                        }}
                                                        onClick={() => {
                                                            if (tags.includes(item.name)) {
                                                                deleteTag(item.name);
                                                            } else {
                                                                setTags((current) => {
                                                                    return [...current, item.name];
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Text mb={0}>{item.name}</Text>
                                                        {tags.includes(item.name) && <Check />}
                                                    </Box>
                                                );
                                            })}
                                        </VStack>
                                    ) : (
                                        <Text px={4}>نتیجه‌ای برای عبارت جستجو شده پیدا نشد!</Text>
                                    )}
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <VStack w="full" spacing={4}>
                            <Collapse in={tags.length !== 0} style={{ width: "100%" }}>
                                <FormLabel>تگ‌های چالش</FormLabel>
                                <Box mt={4} display={"flex"} flexWrap={"wrap"}>
                                    {tags.map((tag, index) => (
                                        <Tag
                                            key={`problem-tags-edit-${index}`}
                                            colorScheme={useColorModeValue("blue", "RoboEpics.azure")}
                                            mx={2}
                                            my={2}
                                            lineHeight={"2rem"}
                                        >
                                            {tag}
                                            <IconButton
                                                aria-label="delete-tag-problem-edit"
                                                variant={"ghost"}
                                                size="xs"
                                                colorScheme={"red"}
                                                icon={<X size="18" />}
                                                onClick={() => {
                                                    deleteTag(tag);
                                                }}
                                                ml={-1}
                                                mr={3}
                                            />
                                        </Tag>
                                    ))}
                                </Box>
                            </Collapse>
                            <Button
                                alignSelf={"flex-end"}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                isLoading={isLoading}
                                isDisabled={isDisabled}
                                onClick={() => {
                                    onConfirm();
                                }}
                            >
                                Apply
                            </Button>
                        </VStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
const TagsSearchFormControl = (props: {
    setShowSearchResult: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchResult: React.Dispatch<
        React.SetStateAction<{
            results: Array<{
                name: string;
                slug: string;
            }>;
            total: number;
        }>
    >;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const inputDelayTimer = useRef(null);
    const searchInputEl = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const onTagSearchInputChange = async (input: string) => {
        setSearchInput(input);
        setIsLoading(true);
        props.setShowSearchResult(false);
        clearTimeout(inputDelayTimer.current);
        if (input.length) {
            inputDelayTimer.current = setTimeout(async () => {
                await tagSearch(input);
            }, 500);
        } else {
            props.setSearchResult({ results: [], total: 0 });
        }
    };
    const tagSearch = async (input: string) => {
        setIsLoading(true);
        const serverResponse = (await searchTags({ query: input }, { toast: props.toast, requesterPath: props.router.asPath })) as Response;
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            setHasError(false);
            props.setSearchResult(result);
            props.setShowSearchResult(true);
        } else {
            setHasError(true);
        }
        setIsLoading(false);
    };
    return (
        <FormControl>
            <InputGroup>
                <Input
                    ref={searchInputEl}
                    size="sm"
                    placeholder="جستجوی تگ"
                    onChange={(e) => {
                        onTagSearchInputChange(e.target.value);
                    }}
                    value={searchInput}
                />
                <InputLeftElement boxSize={8} pr={2} children={<SearchIcon color="gray" />} />
                <InputRightElement
                    boxSize={8}
                    pl={2}
                    children={
                        isLoading ? (
                            <Spinner size="sm" />
                        ) : hasError ? (
                            <AlertOctagon color="red" size={18} />
                        ) : (
                            searchInput.length !== 0 && (
                                <IconButton
                                    sx={{
                                        borderRadius: "999px",
                                        height: "20px",
                                        width: "20px",
                                        minWidth: "20px",
                                        padding: "2px",
                                    }}
                                    aria-label="Cancel search"
                                    onClick={() => {
                                        props.setSearchResult({
                                            results: [],
                                            total: 0,
                                        });
                                        setSearchInput("");
                                        props.setShowSearchResult(false);
                                        searchInputEl.current.focus();
                                    }}
                                    icon={<X size={18} />}
                                />
                            )
                        )
                    }
                />
            </InputGroup>
        </FormControl>
    );
};
