// import { useEffect, useRef } from "react";

// export function useInterval(callback, delay) {
//     const savedCallback = useRef();
//
//     // Remember the latest callback.
//     useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);
//
//     // Set up the interval.
//     useEffect(() => {
//         function tick() {
//             if (savedCallback.current) {
//                 savedCallback.current();
//             }
//         }
//         if (delay !== null) {
//             const id = setInterval(tick, delay);
//             return () => clearInterval(id);
//         }
//     }, [delay]);
// }

export const getElementY = (query) => {
    return window.scrollY + document.querySelector(query)?.getBoundingClientRect().top;
};

export const scrollToElement = (element, duration) => {
    const startingY = window.scrollY;
    const elementY = getElementY(element);
    // If element is close to page's bottom then window will scroll only to some position above the element.
    const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
    const diff = targetY - startingY;
    // Easing function: easeInOutCubic
    // From: https://gist.github.com/gre/1650294
    const easing = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    let start;

    if (!diff) return;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed miliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        let percent = Math.min(time / duration, 1);
        // Apply the easing.
        // It can cause bad-looking slow frames in browser performance tool, so be careful.
        percent = easing(percent);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    });
};

export const getDatasetStatusText = (status: number) => {
    switch (status) {
        case 20:
            return "آماده‌سازی";
        case 40:
            return "آماده";
        default:
            return status;
    }
};

export const getDatasetStatusColor = (status: number) => {
    switch (status) {
        case 20:
            return "yellow";

        case 40:
            return "green";

        default:
            return "grey";
    }
};

export const isValidUrl = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
};
