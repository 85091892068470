import React, { useState } from "react";
import { NextRouter } from "next/router";
import axios from "axios";
import { submitForm, uploadResult } from "@util/api/problem_enter_id";
import { RoboEpicsMarkdown } from "@components/markdown";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    RadioGroup,
    VStack,
    Radio,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Textarea,
    Divider,
    Button,
    Spinner,
    SlideFade,
    IconButton,
    InputGroup,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import "katex/dist/katex.min.css";
import { IToastOptions } from "@util/interfaces";
import { IRoboFormData } from "@util/interfaces";
import styles from "./form.module.css";
import { Check, Edit2, Send, X } from "react-feather";

const RoboEpicsFormRenderer = (props: {
    form: IRoboFormData;
    shouldRender: boolean;
    canView: boolean;
    isLoading: boolean;
    isReadOnly?: boolean;
    problemEnterId?: number;
    reloadComponent?: React.Dispatch<React.SetStateAction<boolean>>;
    toast?: IToastOptions;
    router?: NextRouter;
}) => {
    const [formValues, setFormValues] = useState<Record<string, string>>({});
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    const onSubmit = async () => {
        // Checking if all required fields are filled

        if (
            !Array.from(props.form.cells)
                .filter((el) => el.required)
                .every((el) => Object.keys(formValues).includes(el.key))
        ) {
            props.toast({
                description: "لطفا تمامی فیلدهای ضروری را پر کنید.",

                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
            return;
        }

        // Composing the file
        const json = JSON.stringify(formValues);
        const blob = new Blob([json], {
            type: "application/json",
        });

        setUploadLoading(true);

        const serverResponse = (await uploadResult(
            `re_formsubmission_c_${props.router.query.competition}_peid_${props.problemEnterId}.json`,
            props.problemEnterId,
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const { url } = await serverResponse.json();
            axios
                .put(url, blob)
                .then(async () => {
                    const formSubresponse = (await submitForm(props.problemEnterId.toString(), {
                        toast: props.toast,
                        requesterPath: props.router.asPath,
                    })) as Response;
                    if (formSubresponse.ok) {
                        props.toast({
                            description: "فرم با موفقیت ارسال شد.",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                            variant: "subtle",
                        });

                        props.reloadComponent(true);
                    } else {
                        props.toast({
                            title: "مشکلی در ارسال اطلاعات فرم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                            description: JSON.stringify(serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                            variant: "subtle",
                        });
                    }
                })
                .catch(() => {
                    props.toast({
                        description: "مشکلی در ارسال اطلاعات فرم رخ داد. لطفاً دوباره تلاش کنید.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        variant: "subtle",
                    });
                });
        } else {
            props.toast({
                title: "مشکلی در ارسال اطلاعات فرم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                description: JSON.stringify(serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
        }
        setUploadLoading(false);
    };

    if (props.isLoading) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }
    if (!props.canView) {
        return (
            <Alert status="error" className="mt-2">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    شما اجازه دسترسی به این فرم را ندارید.
                </AlertTitle>
            </Alert>
        );
    }
    if (!props.shouldRender) {
        return (
            <Alert status="error" className="mt-2">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    شما قبلا این فرم را ارسال کرده‌اید. امکان ارسال مجدد وجود ندارد.
                </AlertTitle>
            </Alert>
        );
    }
    if (props.form == null || props.form.cells == null) {
        return (
            <Alert status="error" className="mt-2">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    مشکلی در ساختار فرم وجود دارد.
                </AlertTitle>
            </Alert>
        );
    }

    switch (props.form.theme) {
        case "chat":
            return (
                <ChatForm
                    shouldRender={props.shouldRender}
                    form={props.form}
                    setFormValues={setFormValues}
                    submitForm={onSubmit}
                    uploadLoading={uploadLoading}
                    formValues={formValues}
                    isReadOnly={props.isReadOnly}
                />
            );

        case "questionnaire":
        default:
            return (
                <QuestionnaireForm
                    shouldRender={props.shouldRender}
                    form={props.form}
                    setFormValues={setFormValues}
                    submitForm={onSubmit}
                    uploadLoading={uploadLoading}
                    isReadOnly={props.isReadOnly}
                />
            );
    }
};
const QuestionnaireForm = (props: {
    form: IRoboFormData;
    setFormValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    submitForm: () => Promise<void>;
    shouldRender: boolean;
    uploadLoading: boolean;
    isReadOnly?: boolean;
}) => {
    return (
        <React.Fragment>
            <Box mt={-2} mx={4}>
                {props.form.cells.map((el) => {
                    let coreEl;

                    switch (el.type) {
                        case "singleline":
                            coreEl = (
                                <FormControl isReadOnly={props.isReadOnly} isRequired={el.required}>
                                    <Box display="flex" flexDirection="row">
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel fontSize="sm">{el.content}</FormLabel>
                                            )}
                                        </Box>
                                    </Box>
                                    <Input
                                        size="sm"
                                        placeholder={el.placeholder}
                                        onChange={(e) => {
                                            props.setFormValues((current) => {
                                                return {
                                                    ...current,
                                                    [el.key]: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                    {el.hint && <FormHelperText>{el.hint}</FormHelperText>}
                                </FormControl>
                            );
                            break;
                        case "multiline":
                            coreEl = (
                                <FormControl isReadOnly={props.isReadOnly} isRequired={el.required}>
                                    <Box display="flex" flexDirection="row">
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel fontSize="sm">{el.content}</FormLabel>
                                            )}
                                        </Box>
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                    </Box>
                                    <Textarea
                                        resize="vertical"
                                        w="full"
                                        size="sm"
                                        placeholder={el.placeholder}
                                        onChange={(e) => {
                                            props.setFormValues((current) => {
                                                return {
                                                    ...current,
                                                    [el.key]: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                    {el.hint && <FormHelperText>{el.hint}</FormHelperText>}
                                </FormControl>
                            );
                            break;
                        case "multichoice":
                            coreEl = (
                                <FormControl isReadOnly={props.isReadOnly} isRequired={el.required}>
                                    <Box display="flex" flexDirection="row">
                                        <Box>
                                            {el.content_type && el.content_type === "markdown" && (
                                                <span
                                                    style={{
                                                        marginInlineStart: "var(--chakra-space-1)",
                                                        color: "var(--chakra-colors-red-300)",
                                                    }}
                                                >
                                                    *
                                                </span>
                                            )}
                                        </Box>
                                        <Box className="flex-grow mr-2">
                                            {el.content_type && el.content_type === "markdown" ? (
                                                <React.Fragment>
                                                    <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>
                                                </React.Fragment>
                                            ) : (
                                                <FormLabel as="legend" fontSize="sm">
                                                    {el.content}
                                                </FormLabel>
                                            )}
                                        </Box>
                                    </Box>
                                    <RadioGroup>
                                        <VStack spacing="10px" alignItems="start">
                                            {el.choices.map((option: { label: string; value: string }, index) => {
                                                return (
                                                    <Radio
                                                        fontSize="sm"
                                                        value={option.value}
                                                        size="sm"
                                                        onChange={(e) => {
                                                            props.setFormValues((current) => {
                                                                return {
                                                                    ...current,
                                                                    [el.key]: e.target.value,
                                                                };
                                                            });
                                                        }}
                                                        key={index}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                );
                                            })}
                                        </VStack>
                                    </RadioGroup>
                                    {el.hint && <FormHelperText>{el.hint}</FormHelperText>}
                                </FormControl>
                            );
                            break;
                        case "markdown":
                            coreEl = <RoboEpicsMarkdown>{el.content}</RoboEpicsMarkdown>;
                            break;
                        default:
                            coreEl = <div>{el.content}</div>;
                            break;
                    }
                    return (
                        <Box key={el.key}>
                            <Box className="py-3 mb-3">{coreEl}</Box>
                            <Divider />
                        </Box>
                    );
                })}
                {!props.isReadOnly && (
                    <Button
                        isDisabled={!props.shouldRender}
                        isLoading={props.uploadLoading}
                        onClick={props.submitForm}
                        colorScheme="RoboEpics.green"
                        size="sm"
                        className="mt-3"
                        float="right"
                        variant="solid"
                    >
                        ثبت و ارسال
                    </Button>
                )}
            </Box>
        </React.Fragment>
    );
};
const ChatForm = (props: {
    form: IRoboFormData;
    setFormValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    formValues: Record<string, string>;
    submitForm: () => Promise<void>;
    shouldRender: boolean;
    uploadLoading: boolean;
    isReadOnly?: boolean;
}) => {
    const [currentField, setCurrentField] = useState(Object.keys(props.formValues).length);
    const [answer, setAnswer] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isInChatEditing, setIsInChatEditing] = useState(false);
    const [inChatIndex, setInChatIndex] = useState("");
    return (
        <Box mx={4}>
            <VStack spacing={6} px={4} pt={4} pb={2} className={`w-full h-full border rounded-md`}>
                <VStack spacing={4} w="full" maxHeight={"600px"} overflowY={"auto"} overflowX="hidden">
                    {props.form.cells.map((el, index) => {
                        let received = document.createElement("div");
                        document.body.appendChild(received);
                        received.style.fontSize = "1rem";
                        received.style.position = "absolute";
                        received.style.left = "-1000px";
                        received.style.top = "-1000px";

                        received.textContent = el.content;
                        const result_received = {
                            width: received.clientWidth,
                        };
                        document.body.removeChild(received);
                        received = null;

                        let sent = document.createElement("div");
                        document.body.appendChild(sent);
                        sent.style.fontSize = "1rem";
                        sent.style.position = "absolute";
                        sent.style.left = "-1000px";
                        sent.style.top = "-1000px";

                        sent.textContent = props.formValues[el.key];
                        const result_sent = {
                            width: sent.clientWidth,
                        };
                        document.body.removeChild(sent);
                        sent = null;
                        if (props.isReadOnly) {
                            return (
                                <VStack w="full" key={el.key} spacing={4}>
                                    <Box
                                        key={`received-${el.key}`}
                                        px={4}
                                        py={1}
                                        className={`${styles.message_received}`}
                                        maxWidth={result_received.width > 400 ? 400 : "max-content"}
                                    >
                                        {el.content}
                                    </Box>
                                    <Box
                                        key={`sent-${el.key}`}
                                        px={4}
                                        py={1}
                                        className={`${styles.message_sent}`}
                                        maxWidth={result_sent.width > 400 ? 400 : "max-content"}
                                    >
                                        {`پاسخ شرکت‌کننده به پیام بالایی`}
                                    </Box>
                                </VStack>
                            );
                        }

                        if (index < currentField) {
                            return (
                                <VStack w="full" key={el.key} spacing={4}>
                                    <Box
                                        key={`received-${el.key}`}
                                        px={4}
                                        py={1}
                                        className={`${styles.message_received}`}
                                        maxWidth={result_received.width > 400 ? 400 : "max-content"}
                                    >
                                        {el.content}
                                    </Box>
                                    <Box
                                        key={`sent-${el.key}`}
                                        px={4}
                                        py={1}
                                        className={`${styles.message_sent}`}
                                        maxWidth={result_sent.width > 400 ? 400 : "max-content"}
                                    >
                                        <IconButton
                                            aria-label="edit-inchat"
                                            icon={<Edit2 size={16} />}
                                            className={styles.message_edit}
                                            variant="solid"
                                            size="xs"
                                            colorScheme={"green"}
                                            rounded={"full"}
                                            onClick={() => {
                                                setInChatIndex(el.key);
                                                setIsInChatEditing(true);
                                                setIsEditing(true);
                                                setAnswer(props.formValues[el.key]);
                                            }}
                                        />
                                        {props.formValues[el.key]}
                                    </Box>
                                </VStack>
                            );
                        } else if (index == currentField) {
                            return (
                                <SlideFade in={true} offsetX={"-100px"} style={{ width: "100%" }} key={`received-${el.key}`}>
                                    <Box
                                        px={4}
                                        py={1}
                                        className={`${styles.message_received}`}
                                        maxWidth={result_received.width > 400 ? 400 : "max-content"}
                                    >
                                        {el.content}
                                    </Box>
                                </SlideFade>
                            );
                        } else {
                            return (
                                <Box display={"none"} key={el.key}>
                                    {el.key}
                                </Box>
                            );
                        }
                    })}
                    {Object.keys(props.formValues).length === props.form.cells.length && (
                        <LastMessageSent
                            msgkey={Object.keys(props.formValues)[Object.keys(props.formValues).length - 1]}
                            value={props.formValues[Object.keys(props.formValues)[Object.keys(props.formValues).length - 1]]}
                            setInChatIndex={setInChatIndex}
                            setAnswer={setAnswer}
                            setIsEditing={setIsEditing}
                            setIsInChatEditing={setIsInChatEditing}
                        />
                    )}
                </VStack>
                {!props.isReadOnly && (
                    <Box className={`flex flex-col w-full ${isEditing ? "border" : ""} border-b-0 rounded-md`}>
                        <SlideFade in={isEditing} offsetY="20px">
                            <Box className="flex flex-row w-full justify-between items-center my-2 px-2">
                                <Edit2 size="16" />
                                <Text mb={0} mx={2}>
                                    {props.formValues[isInChatEditing ? inChatIndex : props.form.cells[currentField].key]}
                                </Text>
                                <IconButton
                                    aria-label="cancel-edit"
                                    icon={<X />}
                                    size="xs"
                                    variant="ghost"
                                    alignSelf={"flex-end"}
                                    onClick={() => {
                                        setIsEditing(false);
                                        if (isInChatEditing) {
                                            setIsInChatEditing(false);
                                            setInChatIndex("");
                                        } else {
                                            setCurrentField((current) => (current < props.form.cells.length - 1 ? current + 1 : current));
                                        }
                                    }}
                                />
                            </Box>
                        </SlideFade>
                        <Box className={`flex flex-row w-full justify-start items-center`}>
                            <InputGroup>
                                <Input
                                    onChange={(e) => {
                                        setAnswer(e.target.value);
                                    }}
                                    resize="none"
                                    placeholder="پاسخ شما"
                                    borderRadius={"4px"}
                                    isDisabled={props.form.cells.length === Object.keys(props.formValues).length}
                                    onKeyDown={(e) => {
                                        switch (e.key) {
                                            case "ArrowUp":
                                                if (!isEditing) {
                                                    if (Object.keys(props.formValues).length) {
                                                        setIsEditing(true);
                                                        setAnswer(props.formValues[props.form.cells[currentField - 1].key]);
                                                        setCurrentField((current) => current - 1);
                                                    }
                                                }
                                                break;
                                            case "Enter":
                                                if (answer.length !== 0) {
                                                    if (e.shiftKey) {
                                                        setAnswer((current) => current + "\n");
                                                    } else {
                                                        props.setFormValues((current) => {
                                                            return {
                                                                ...current,
                                                                [isInChatEditing ? inChatIndex : props.form.cells[currentField].key]:
                                                                    answer,
                                                            };
                                                        });
                                                        setAnswer("");
                                                        if (!isInChatEditing)
                                                            setCurrentField((current) =>
                                                                current < props.form.cells.length - 1 ? current + 1 : current,
                                                            );
                                                    }
                                                } else {
                                                    isEditing
                                                        ? setAnswer(
                                                              props.formValues[
                                                                  isInChatEditing ? inChatIndex : props.form.cells[currentField].key
                                                              ],
                                                          )
                                                        : setAnswer("");
                                                    setCurrentField((current) =>
                                                        current < props.form.cells.length ? current + 1 : current,
                                                    );
                                                }
                                                setIsEditing(false);
                                                setIsInChatEditing(false);
                                                setInChatIndex("");

                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    value={answer}
                                />
                                <InputRightElement>
                                    <IconButton
                                        aria-label="send-message"
                                        isDisabled={props.form.cells.length === Object.keys(props.formValues).length}
                                        icon={isEditing ? <Check size={"20"} /> : <Send size={"20"} />}
                                        size="xs"
                                        variant="ghost"
                                        _hover={{ background: "transparent" }}
                                        onClick={() => {
                                            if (isEditing) {
                                                props.setFormValues((current) => {
                                                    return {
                                                        ...current,
                                                        [isInChatEditing ? inChatIndex : props.form.cells[currentField].key]:
                                                            answer.length === 0
                                                                ? props.formValues[
                                                                      isInChatEditing ? inChatIndex : props.form.cells[currentField].key
                                                                  ]
                                                                : answer,
                                                    };
                                                });
                                                setIsEditing(false);
                                                setIsInChatEditing(false);
                                                setInChatIndex("");
                                                setAnswer("");
                                                setCurrentField((current) =>
                                                    current < props.form.cells.length - 1 ? current + 1 : current,
                                                );
                                            } else {
                                                if (answer.length !== 0) {
                                                    props.setFormValues((current) => {
                                                        return {
                                                            ...current,
                                                            [props.form.cells[currentField].key]: answer,
                                                        };
                                                    });
                                                    setAnswer("");
                                                    setCurrentField((current) =>
                                                        current < props.form.cells.length - 1 ? current + 1 : current,
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Box>
                )}
            </VStack>

            {!props.isReadOnly && (
                <Button
                    isDisabled={!props.shouldRender || !(props.form.cells.length === Object.keys(props.formValues).length)}
                    isLoading={props.uploadLoading}
                    onClick={props.submitForm}
                    colorScheme="RoboEpics.green"
                    size="sm"
                    className="mt-3"
                    float="right"
                    variant="solid"
                >
                    ثبت و ارسال
                </Button>
            )}
        </Box>
    );
};
const LastMessageSent = (props: {
    msgkey: string;
    value: string;
    setInChatIndex: React.Dispatch<React.SetStateAction<string>>;
    setIsInChatEditing: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
}) => {
    let sent = document.createElement("div");
    document.body.appendChild(sent);
    sent.style.fontSize = "1rem";
    sent.style.position = "absolute";
    sent.style.left = "-1000px";
    sent.style.top = "-1000px";

    sent.textContent = props.value;
    const result_sent = {
        width: sent.clientWidth,
    };
    document.body.removeChild(sent);
    sent = null;

    return (
        <Box
            key={`sent-${props.msgkey}`}
            px={4}
            py={1}
            className={`${styles.message_sent}`}
            maxWidth={result_sent.width > 400 ? 400 : "max-content"}
        >
            <IconButton
                aria-label="edit-inchat"
                icon={<Edit2 size={16} />}
                className={styles.message_edit}
                variant="solid"
                size="xs"
                colorScheme={"green"}
                rounded={"full"}
                onClick={() => {
                    props.setInChatIndex(props.msgkey);
                    props.setIsInChatEditing(true);
                    props.setIsEditing(true);
                    props.setAnswer(props.value);
                }}
            />
            {props.value}
        </Box>
    );
};

export default RoboEpicsFormRenderer;
