import React, { useEffect, useState } from "react";
import { default as NextImage } from "next/image";
import { getImageURL } from "@util/mediaLoader";
import { PROBLEM_TABS } from "@util/constants";
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Heading,
    Text,
    Spinner,
    Alert,
    Box,
    AlertTitle,
    AlertIcon,
    AlertDescription,
    useColorModeValue,
    Fade,
    Button,
    Badge,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    Divider,
    Grid,
    Tag,
    HStack,
} from "@chakra-ui/react";
import {
    ProblemOverview as Overview,
    DatasetOverview,
    ProblemScoreboard,
    Submit,
    ProblemNotebooks,
    SettingsOverview,
    MetadataBlock,
    CErrorDisplayer,
} from "@components/competition";
import { CustomAvatar } from "@components";
import {
    BulbOutline,
    FileTrayStackedOutline,
    HardwareChipOutline,
    InfiniteOutline,
    PaperPlaneOutline,
    ServerOutline,
    SettingsOutline,
    ShareOutline,
    Trash,
} from "react-ionicons";
import { enableNotebook4ProblemEnter, getTeamSubmissions } from "@util/api/problem_enter_id";
import { getParticipantSummaryCompetition } from "@util/api/competitions";
import { AlertTriangle, CheckCircle, Power, XCircle } from "react-feather";
import { getParticipant } from "@util/api/participants";
import Style from "./challenge.module.css";
import { useSelector } from "react-redux";

import { CoverImageChange } from "./CoverImageChange";
import { ThumbnailImageChange } from "./ThumbnailImageChange";
import { EditSubtitle } from "./EditSubtitle";
import { EditTitle } from "./EditTitle";
import { IChallenge, IParticipantCodeset, IProblemEnterSubmission, IProblemTextSet } from "@util/interfaces";
import { problemTextListGet } from "@util/api/problem_text";
import { TagsModal } from "./EditTags";
import { ICompetition } from "@redux/slices/competition";
import { getCookie } from "@util/cookies";

export const Challenge = (props: IChallenge) => {
    const tagColorScheme = useColorModeValue("blue", "RoboEpics.azure");
    const bw = useColorModeValue("black", "white");
    const popoverContentClassname = useColorModeValue("chakra-ui-light", "chakra-ui-dark");
    const popoverArrowBg = useColorModeValue("white", "RoboEpics.dark.600");
    const selectedTabBorderColor = useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400");
    const tabPanelBorderColor = useColorModeValue("blackAlpha.100", "whiteAlpha.400");

    const [tabIndex, setTabIndex] = useState(props.tabIndex ? props.tabIndex : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [colabLoading, setColabLoading] = useState(false);
    const [colabError, setColabError] = useState(false);
    const [fetchingSubmissions, setFetchingSubmissions] = useState(false);
    const [submissions, setSubmissions] = useState<Array<IProblemEnterSubmission>>(null);
    const [participantData, setParticipantData] = useState<IParticipantCodeset>(null);
    const userCompetitionData = useSelector((state: { competition: ICompetition }) => state.competition);
    const [thumbnailImage, setThumbnailImage] = useState(props.data.thumbnail);
    const [coverImage, setCoverImage] = useState(props.data.cover_image);
    const [problemTextSet, setProblemTextSet] = useState<Array<IProblemTextSet>>([]);
    const [isEligibleForSubmission, setIsEligibleForSubmission] = useState(true);

    const errorParser = (data: string) => {
        let description = "";
        switch (data) {
            case "You cannot enable the notebook right now. Google services are disabled in this RoboEpics instance!":
                description = "با عرض پوزش، فعالسازی نوت‌بوک از سمت گوگل کولب بسته شده است";
                break;

            default:
                description = data;
                break;
        }
        props.toast({
            description: description,
            duration: 9000,
            isClosable: true,
            variant: "subtle",
            status: "error",
        });
    };

    const getNotebook = async () => {
        setColabLoading(true);
        if (participantData) {
            if (participantData.notebook_file_id) {
                window.open(`https://colab.research.google.com/drive/${participantData.notebook_file_id}`);
            } else {
                const serverResponse = (await enableNotebook4ProblemEnter(participantData.id, {
                    toast: props.toast,
                    requesterPath: props.router.asPath,
                })) as Response;
                if (serverResponse.ok) {
                    const { notebook_file_id } = await serverResponse.json();
                    setParticipantData({
                        ...participantData,
                        notebook_file_id,
                    });
                } else {
                    setColabError(true);
                    if (serverResponse.status === 400) {
                        const { data } = await serverResponse.json();
                        errorParser(data[0]);
                    }
                    setColabError(true);
                }
            }
        }
        setColabLoading(false);
    };

    const _setParticipantData = async () => {
        try {
            if (props.data.repository_mode === 30) setColabLoading(true);

            const participationSummaryResponse = (await getParticipantSummaryCompetition(userCompetitionData.path, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;

            if (participationSummaryResponse.ok) {
                const data = await participationSummaryResponse.json();
                if (data.phase_progress_restrictions) {
                    const restrictions = data.phase_progress_restrictions;
                    for (const phaseID of Object.keys(data.phase_progress_restrictions)
                        .map((e) => Number.parseInt(e))
                        .sort((a, b) => a - b)) {
                        if (phaseID === props.phaseID) {
                            break;
                        }

                        if (!restrictions[phaseID]) {
                            setIsEligibleForSubmission(false);
                            break;
                        }
                    }
                }
            }

            const serverResponse = (await getParticipant(userCompetitionData.participantId.toString(), {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const { problem_enters } = await serverResponse.json();
                const [data] = problem_enters.filter((element: { problem_id: number }) => element.problem_id === props.data.id);
                setParticipantData(data);
            }

            // If a form exists in problemtext_set, submissions should be fetched
            if (Array.from(problemTextSet).filter((el) => el.content_type === 50).length !== 0) {
                setFetchingSubmissions(true);
                const serverResponse = (await getTeamSubmissions(participantData.id, 0, 3, {
                    toast: props.toast,
                    requesterPath: props.router.asPath,
                })) as Response;
                if (serverResponse.ok) {
                    const resolved = await serverResponse.json();
                    setSubmissions(resolved);
                }
                setFetchingSubmissions(false);
            }
            if (props.data.repository_mode !== 10) {
                setColabLoading(false);
                setColabError(false);
            }
        } catch (error) {
            setColabLoading(false);
            setColabError(true);
        }
    };

    const _setProblemTextSet = async () => {
        setIsLoading(true);

        const ServerResponse = (await problemTextListGet(props.data.path, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (ServerResponse.ok) {
            const resolved = await ServerResponse.json();
            setProblemTextSet(resolved);
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (userCompetitionData.participantId) _setParticipantData();
    }, [props.router.query.problem, userCompetitionData.participantId, problemTextSet]);

    useEffect(() => {
        _setProblemTextSet();
    }, []);

    const getViewByKey = (key: string) => {
        switch (key) {
            case "overview":
                return (
                    <Overview
                        fetchingSubmissions={fetchingSubmissions}
                        submissions={submissions}
                        problemtext_set={problemTextSet}
                        participantId={userCompetitionData.participantId ? userCompetitionData.participantId.toString() : null}
                        problemEnterId={participantData ? participantData.id : null}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        problemPath={props.data.path}
                        toast={props.toast}
                        router={props.router}
                    />
                );
            case "dataset":
                return (
                    <DatasetOverview
                        problem={props.data.path}
                        datasets={[]}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        toast={props.toast}
                        router={props.router}
                    />
                );
            case "submissions":
                return (
                    <Submit
                        problemId={props.data.id}
                        problemPath={props.data.path}
                        gimulatorTag={props.data.gimulator_tag}
                        user={props.user}
                        problemEnterId={participantData ? participantData.id : null}
                        maxOutputSize={props.data.output_volume_size ? parseInt(props.data.output_volume_size.toString()) : null}
                        submissionFileName={props.data.submission_file_name}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        evaluationMode={props.data.evaluation_mode}
                        submission_score_definitions={props.data.submission_score_definitions}
                        repositoryMode={props.data.repository_mode}
                        isOutputPresentable={props.data.is_output_presentable}
                        allowedRuntimes={props.data.allowed_runtimes}
                        toast={props.toast}
                        setHasChanged={props.setHasChanged}
                        isEligibleForSubmission={isEligibleForSubmission}
                        router={props.router}
                    />
                );
            case "scoreboard":
                return <ProblemScoreboard problem={props.data.path} toast={props.toast} router={props.router} />;
            case "notebooks":
                return <ProblemNotebooks problem={props.data.path} toast={props.toast} router={props.router} />;
            case "settings":
                return props.isOwner || props.isCollaborator ? (
                    <SettingsOverview
                        problemData={props.data}
                        collaboratorsData={props.collaborators}
                        isClassroom={props.isClassroom}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        toast={props.toast}
                        router={props.router}
                    />
                ) : (
                    <CErrorDisplayer hasButton />
                );
            default:
                return (
                    <Overview
                        fetchingSubmissions={fetchingSubmissions}
                        submissions={submissions}
                        problemtext_set={problemTextSet}
                        participantId={userCompetitionData.participantId ? userCompetitionData.participantId.toString() : null}
                        problemEnterId={participantData ? participantData.id : null}
                        isOwner={props.isOwner}
                        isCollaborator={props.isCollaborator}
                        problemPath={props.data.path}
                        toast={props.toast}
                        router={props.router}
                    />
                );
        }
    };

    if (isLoading) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner mt={4} />
                {/* <Text fontSize="lg">در حال دریافت اطلاعات این چالش...</Text> */}
            </Box>
        );
    }

    if (isError) {
        return (
            <Fade in={isError}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }

    return (
        <>
            {props.isOwner || props.isCollaborator ? (
                <CoverImageChange coverImage={coverImage} setCoverImage={setCoverImage} toast={props.toast} router={props.router} />
            ) : (
                <Box height="full" width="full" className={`${Style.no_image}`}>
                    {props.data.cover_image === null ? (
                        <NextImage
                            src="/images/bannerPlaceholder.png"
                            className={`border object-cover ${Style.no_image}`}
                            width="1216"
                            height="350"
                            objectFit="cover"
                        />
                    ) : (
                        <img
                            className="border"
                            src={getImageURL(props.data.cover_image)}
                            style={{
                                width: "100%",
                                height: "350px",
                                objectFit: "cover",
                            }}
                        />
                    )}
                </Box>
            )}
            {(props.isOwner || props.isCollaborator) && <PublishSection isPublished={props.data.is_published} />}
            <div id="compData" className="border border-b-0">
                <div className={`flex flex-row justify-between items-center pt-4 pb-0 px-4 ${Style.main_container}`}>
                    <div className={`${Style.main_status_wrapper}`}>
                        <div className={`flex flex-col justify-start items-center self-start ${Style.main_wrapper}`}>
                            <div className="flex flex-row justify-start items-center w-full">
                                <div id="avatar" className={`ml-4 ${Style.main_data_avatar}`}>
                                    {props.isOwner || props.isCollaborator ? (
                                        <ThumbnailImageChange
                                            thumbnailImage={thumbnailImage}
                                            setThumbnailImage={setThumbnailImage}
                                            toast={props.toast}
                                            router={props.router}
                                        />
                                    ) : (
                                        <CustomAvatar
                                            showPlaceholder={props.data.thumbnail === null}
                                            placeholder={<BulbOutline color={bw} />}
                                            shiftToTop={false}
                                            src={getImageURL(props.data.thumbnail)}
                                        />
                                    )}
                                </div>
                                <div id="mainData" className="">
                                    <div className="">
                                        {props.isOwner || props.isCollaborator ? (
                                            <EditTitle
                                                title={props.data.title}
                                                toast={props.toast}
                                                router={props.router}
                                                setHasChanged={props.setHasChanged}
                                            />
                                        ) : (
                                            <Heading as="h1" fontSize="lg" fontWeight="600">
                                                {props.data.title}
                                            </Heading>
                                        )}
                                        {props.isOwner || props.isCollaborator ? (
                                            <EditSubtitle
                                                subtitle={props.data.short_description}
                                                toast={props.toast}
                                                router={props.router}
                                            />
                                        ) : (
                                            <Text fontSize="sm">{props.data.short_description}</Text>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Box id="tags" className="w-full mb-4 mr-32">
                                {props.data.tags.length ? (
                                    <Box>
                                        {props.isOwner || props.isCollaborator ? (
                                            <TagsModal
                                                path={props.data.path}
                                                toast={props.toast}
                                                router={props.router}
                                                tags={props.data.tags}
                                            />
                                        ) : (
                                            <Box className={`flex flex-row`}>
                                                <Box maxWidth="480px" display={"flex"} flexWrap={"wrap"}>
                                                    {props.data.tags.map((tag, index) => {
                                                        return (
                                                            <Tag
                                                                key={`${props.data.path}-tag-${index}`}
                                                                colorScheme={tagColorScheme}
                                                                ml={2}
                                                                mb={4}
                                                                width={"fit-content"}
                                                                size={"sm"}
                                                            >
                                                                {tag}
                                                            </Tag>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    (props.isOwner || props.isCollaborator) && (
                                        <TagsModal
                                            path={props.data.path}
                                            toast={props.toast}
                                            router={props.router}
                                            tags={props.data.tags}
                                        />
                                    )
                                )}
                            </Box>
                        </div>
                        {userCompetitionData.participantId && props.data.repository_mode !== 10 && (
                            <div className={`flex flex-row items-center ${Style.status_wrapper} mb-4 mr-16`}>
                                <Badge px={2} py={1} colorScheme="green" className={`${Style.gitlab_status}`} textTransform={"none"}>
                                    <div className="flex flex-row items-center">
                                        <Icon as={CheckCircle} w={4} h={4} ml={2} />
                                        به Gitlab متصل شده.
                                    </div>
                                </Badge>
                                {props.data.repository_mode === 30 && (
                                    <Badge
                                        className={`mr-4 ${Style.colab_status}`}
                                        px={2}
                                        py={1}
                                        colorScheme={
                                            colabLoading
                                                ? "blue"
                                                : colabError
                                                ? "red"
                                                : participantData?.notebook_file_id
                                                ? "green"
                                                : "yellow"
                                        }
                                    >
                                        {colabLoading ? (
                                            <div className="flex flex-row items-center ">
                                                <Spinner h={4} w={4} mx={6} />
                                            </div>
                                        ) : colabError ? (
                                            <div className="flex flex-row items-center">
                                                <Icon as={XCircle} w={4} h={4} ml={2} />
                                                خطایی در فعالسازی نوت‌بوک پیش آمده.
                                            </div>
                                        ) : participantData?.notebook_file_id ? (
                                            <div className="flex flex-row items-center">
                                                <Icon as={CheckCircle} w={4} h={4} ml={2} />
                                                نوت‌بوک فعال شده.
                                            </div>
                                        ) : (
                                            <div className="flex flex-row items-center">
                                                <Icon as={AlertTriangle} w={4} h={4} ml={2} />
                                                نوت‌بوک فعال نشده!
                                            </div>
                                        )}
                                    </Badge>
                                )}
                                <Popover trigger="hover">
                                    <PopoverTrigger>
                                        <Button size="xs" mr={4}>
                                            متادیتا
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className={popoverContentClassname}>
                                        <PopoverArrow bgColor={popoverArrowBg} />
                                        <PopoverHeader>متادیتای چالش</PopoverHeader>
                                        <PopoverBody>
                                            <div className={`flex flex-row justify-between items-center self-start `}>
                                                <Text>کد چالش: {props.data.id}</Text>
                                                {participantData && <Text>کد شرکت‌کننده: {participantData.id}</Text>}
                                            </div>
                                            <Divider mb={"0.5em"} />
                                            <Text>محدودیت‌های چالش:</Text>
                                            <Grid templateColumns="repeat(2,1fr)" gap={4}>
                                                <MetadataBlock
                                                    className="mb-2"
                                                    icon={<HardwareChipOutline />}
                                                    tooltip={"محدودیت هسته پردازشگر"}
                                                    text={`${props.data.default_resource_cpu_limit} هسته`}
                                                    size="md"
                                                />
                                                <MetadataBlock
                                                    className="mb-2"
                                                    icon={<FileTrayStackedOutline />}
                                                    tooltip={"محدودیت حافظه تصادفی (RAM)"}
                                                    text={`${props.data.default_resource_memory_limit} مگابایت`}
                                                    size="md"
                                                />
                                                <MetadataBlock
                                                    className="mb-2"
                                                    icon={<ServerOutline />}
                                                    tooltip={"محدودیت حافظه موقت (Ephemeral)"}
                                                    text={`${props.data.default_resource_ephemeral_limit.toString()} مگابایت`}
                                                    size="md"
                                                />
                                                <MetadataBlock
                                                    className="mb-2"
                                                    icon={<PaperPlaneOutline />}
                                                    tooltip={"محدودیت حجم خروجی"}
                                                    isIcon={props.data.output_volume_size === null}
                                                    mainIcon={<InfiniteOutline color={bw} />}
                                                    text={
                                                        props.data.output_volume_size
                                                            ? `${Math.round(
                                                                  parseInt(props.data.output_volume_size.toString()) / 1000000,
                                                              )} مگابایت`
                                                            : "بدون محدودیت"
                                                    }
                                                    size="md"
                                                />
                                            </Grid>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </div>
                        )}
                    </div>
                    <div
                        className={`flex flex-col items-end justify-start pl-2 self-start ${Style.button_container}`}
                        style={{ flex: "0 0 20%" }}
                    >
                        <Button
                            isLoading={colabLoading}
                            isDisabled={!userCompetitionData.participantId || props.data.repository_mode !== 30}
                            className={`${Style.google_colab_btn}`}
                            leftIcon={<NextImage src="/icons/collab-icon.png" alt="Google Colab Logo" width={25} height={25} />}
                            onClick={getNotebook}
                        >
                            <span className="mr-2">{participantData?.notebook_file_id ? "انتقال به نوت‌بوک" : "فعالسازی نوت‌بوک"}</span>
                        </Button>
                        <Button
                            isDisabled={!userCompetitionData.participantId || props.data.repository_mode === 10}
                            onClick={() => {
                                window.open(participantData?.gitlab_project_url);
                            }}
                            className={`${Style.gitlab_btn} mt-3`}
                            leftIcon={<NextImage src="/icons/gitlab-icon.png" alt="Gitlab Colab Logo" width={16} height={16} />}
                        >
                            <span className="mr-2">انتقال به Gitlab</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div id="tabs">
                <Tabs isLazy defaultIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <TabList className="px-4 border border-t-0 pt-4" overflowX="auto" overflowY="hidden" borderWidth="0 1px 1px ">
                        {Object.keys(PROBLEM_TABS).map((index) => {
                            return (
                                <Tab
                                    onClick={() => {
                                        props.router.push(
                                            `/c/${props.router.query.competition}/p/${props.router.query.phase_id}/p/${props.router.query.problem}?v=${PROBLEM_TABS[index].key}`,
                                            undefined,
                                            { shallow: true },
                                        );
                                    }}
                                    mb={0}
                                    key={index}
                                    isDisabled={
                                        ((PROBLEM_TABS[index].key === "scoreboard" && props.data.evaluation_mode === 10) ||
                                            (PROBLEM_TABS[index].key === "submissions" &&
                                                problemTextSet.some((el) => el.content_type === 50)) ||
                                            // (PROBLEM_TABS[index].key ===
                                            //     "submissions" &&
                                            //     !props.data
                                            //         .output_volume_size &&
                                            //     props.data.repository_mode ===
                                            //         10) ||
                                            (PROBLEM_TABS[index].key === "submissions" && (props.isOwner || props.isCollaborator))) &&
                                        !(props.isOwner || props.isCollaborator)
                                    }
                                    _selected={{
                                        px: "1.2rem",
                                        fontWeight: 600,
                                        borderColor: selectedTabBorderColor,
                                    }}
                                >
                                    {PROBLEM_TABS[index].icon &&
                                        React.cloneElement(PROBLEM_TABS[index].icon, {
                                            cssClasses: "ml-2 flex-shrink-0",
                                            color: tabIndex == parseInt(index) ? bw : "#898989",
                                            width: "20px",
                                            height: "20px",
                                        })}
                                    <span
                                        style={{
                                            color: tabIndex == parseInt(index) ? bw : "#898989",
                                        }}
                                    >
                                        {PROBLEM_TABS[index].key === "overview" && problemTextSet.some((el) => el.content_type === 50)
                                            ? "اطلاعات سوال" // it means there is a form in problem text set
                                            : PROBLEM_TABS[index].label}
                                    </span>
                                </Tab>
                            );
                        })}
                        {(props.isOwner || props.isCollaborator) && getCookie("re_auth_access_token") && (
                            <Tab
                                key={`.settings`}
                                onClick={() => {
                                    props.router.push(
                                        `/c/${props.router.query.competition}/p/${props.router.query.phase_id}/p/${props.router.query.problem}?v=settings`,
                                        undefined,
                                        { shallow: true },
                                    );
                                }}
                                mb={0}
                                mr={"auto"}
                                _selected={{
                                    px: "1.2rem",
                                    fontWeight: 600,
                                    borderColor: selectedTabBorderColor,
                                }}
                            >
                                <SettingsOutline
                                    cssClasses={`ml-2 flex-shrink-0`}
                                    width="20px"
                                    height="20px"
                                    color={tabIndex === Object.keys(PROBLEM_TABS).length ? bw : "#898989"}
                                />
                                <span
                                    style={{
                                        color: tabIndex === Object.keys(PROBLEM_TABS).length ? bw : "#898989",
                                    }}
                                >
                                    تنظیمات چالش
                                </span>
                            </Tab>
                        )}
                    </TabList>
                    <TabPanels>
                        {Object.keys(PROBLEM_TABS).map((index) => {
                            return (
                                <TabPanel
                                    key={index}
                                    className="mt-4"
                                    border="1px"
                                    borderColor={tabPanelBorderColor}
                                    style={{
                                        minHeight: "500px",
                                        paddingTop: "0",
                                    }}
                                >
                                    <Box className="h-full">{getViewByKey(PROBLEM_TABS[index].key)}</Box>
                                </TabPanel>
                            );
                        })}
                        <TabPanel
                            className="mt-4"
                            border="1px"
                            borderColor={tabPanelBorderColor}
                            style={{
                                minHeight: "500px",
                                paddingTop: "0",
                            }}
                        >
                            <Box className="h-full">{getViewByKey("settings")}</Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </>
    );
};

const PublishSection = (props: { isPublished: boolean }) => {
    const bw = useColorModeValue("white", "black");
    const az = useColorModeValue("RoboEpics.azure", "RoboEpics.gold");

    const [isPublished, setIsPublished] = useState(props.isPublished);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={`flex flex-row w-full justify-between items-center px-4 py-4 ${Style.publish_section}`}>
            <HStack spacing={2}>
                <Text mb={0}>وضعیت انتشار چالش:</Text>
                {/* <Text mb={0} color={props.isPublished ? "green" : "red"}>
                    {props.isPublished ? "منتشر شده" : "منتشر نشده"}
                </Text> */}
                <Badge px={2} py={1} colorScheme={isPublished ? "green" : "yellow"} className={``} textTransform={"none"}>
                    <div className="flex flex-row items-center">
                        <Icon as={isPublished ? CheckCircle : AlertTriangle} w={4} h={4} ml={2} />
                        {isPublished ? "منتشر شده" : "منتشر نشده"}
                    </div>
                </Badge>
            </HStack>
            {isPublished ? (
                <HStack spacing={2}>
                    <Button colorScheme={az} leftIcon={<Power />} size="sm">
                        غیرفعال کردن
                    </Button>
                    <Button colorScheme={"red"} leftIcon={<Trash color={bw} />} size="sm">
                        حذف چالش
                    </Button>
                </HStack>
            ) : (
                <Button colorScheme={az} isLoading={isLoading} leftIcon={<ShareOutline color={bw} />} size="sm">
                    انتشار چالش
                </Button>
            )}
        </div>
    );
};
