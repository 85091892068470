import { useTheme } from "@chakra-ui/system";
import {
    Input,
    InputGroup,
    IconButton,
    Button as ChakraButton,
    Link as ChakraLink,
    Heading,
    Collapse,
    Alert,
    AlertIcon,
    AlertDescription,
    useMediaQuery,
    InputRightElement,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import Link from "next/link";
import { NextRouter } from "next/router";

import { UserSignup } from "@util/api/auth";

import Style from "./signup.module.css";

export const Signup = (props: { router: NextRouter }) => {
    const [registerClosed, setRegisterClosed] = useState(false); // TODO read initial value from environment variable
    const { colorMode } = useColorMode();

    const theme = useTheme();
    const bg = useColorModeValue(theme.colors.RoboEpics.black.default, theme.colors.RoboEpics.gold["400"]);
    const btnTxt = useColorModeValue("#fff", theme.colors.RoboEpics.black.default);
    const [values, setValues] = useState({
        fullNameFa: "",
        fullNameEn: "",
        username: "",
        email: "",
        password: "",
        passwordConfirm: "",
        showPassword: false,
        showPasswordConfirm: false,
    });

    const [alertProps, setAlertProps] = useState<{
        open?: boolean;
        message?: string;
        status?: "info" | "warning" | "success" | "error";
    }>(registerClosed ? { // Show an alert if register is closed
        open: true,
        status: "error",
        message: "با عرض پوزش، به علت ارتقا زیرساخت، ثبت نام تا چند روز بسته است! در این حین می توانید با کلیک بر روی آیکون دیسکورد در بالای صفحه، عضو جامعه روبواپیکس شوید تا از آخرین اخبار مطلع شوید.",
    } : {
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === "email") {
            event.target.value.toLowerCase().includes("@gmail.com")
                ? setAlertProps({
                      open: false,
                      message: "این یک آلرت است",
                      status: "info",
                  })
                : setAlertProps({
                      open: true,
                      status: "warning",
                      message:
                          "برای استفاده بهتر از سرویس‌هایی که RoboEpics به شما ارائه می‌دهد، پیشنهاد می‌کنیم که از پست الکترونیکی گوگل خود (Gmail) برای ثبت‌نام در سایت استفاده کنید.",
                  });
        }
    };

    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleShowPasswordConfirm = () => {
        setValues({
            ...values,
            showPasswordConfirm: !values.showPasswordConfirm,
        });
    };

    const errorParser = async (serverResponse: Response) => {
        const data = await serverResponse.json();
        const message = [
            data.password
                ? data.password
                      .map((item) => {
                          switch (item) {
                              case "Ensure this field has at least 8 characters.":
                                  return "رمز عبور وارد شده باید متشکل از حداقل 8 کاراکتر باشد.";
                              case "must not contain commonly used combinations of words and letters":
                                  return "رمز عبور وارد شده نباید شامل ترکیبات پراستفاده از کلمات و حروف باشد.";
                          }
                      })
                      .join(" ")
                : "",
            data.email
                ? data.email
                      .map((item) => {
                          switch (item) {
                              case "Enter a valid email address.":
                                  return "فرمت پست الکترونیکی وارد شده صحیح نمی‌باشد.";
                              case "This field must be unique.":
                                  return "پست الکترونیکی تکراری‌ست.";
                              case "user with this email already exists.":
                                  return "این پست الکترونیکی از قبل در سامانه ثبت شده است.";
                          }
                      })
                      .join(" ")
                : "",
            data.username
                ? data.username
                      .map((item) => {
                          switch (item) {
                              case 'Enter a valid username. This value must be longer than 2 characters and may contain only English letters, numbers, and ./-/_ characters but cannot start with a "-" (dash) or end with a "." (dot).':
                                  return "لطفاً نام کاربری معتبری وارد کنید. نام کاربری که انتخاب می‌کنید باید حداقل متشکل از 2 کاراکتر باشد و می‌تواند تنها شامل حروف انگلیسی، اعداد و کاراکترهای . - _ باشد. همچنین نمی‌تواند با کاراکتر - (خط‌تیره) شروع شود، یا به کاراکتر . (نقطه) ختم شود.";
                              case "This field must be unique.":
                                  return "نام کاربری تکراری‌ست.";
                              case "A user with that username already exists!":
                                  return "این نام کاربری از قبل در سامانه ثبت شده است.";
                          }
                      })
                      .join(" ")
                : "",
        ]
            .join(" ")
            .trim();
        setAlertProps({
            open: true,
            message: message === "" ? "مشکلی در ثبت نام رخ داد." : message,
            status: "error",
        });
    };

    const onSignup = async () => {
        if (values.email.length * values.fullNameFa.length * values.password.length * values.passwordConfirm.length === 0) {
            setAlertProps({
                open: true,
                message: "همه فیلدهای فرم ثبت‌نام را پر کنید.",
                status: "error",
            });
        } else if (values.passwordConfirm !== values.password) {
            setAlertProps({
                open: true,
                message: "رمز عبور با تکرار آن مطابقت ندارد.",
                status: "error",
            });
        } else {
            setLoading(true);
            const serverResponse = (await UserSignup(
                values.email,
                values.password,
                values.fullNameEn,
                values.fullNameFa,
                values.username,
            )) as Response;
            if (serverResponse.status === 201) {
                setAlertProps({
                    open: true,
                    message:
                        "حساب کاربری شما با موفقیت ایجاد شد یک ایمیل حاوی لینک فعالسازی برای شما ارسال شده است! اگر این ایمیل را در inbox خود دریافت نکردید، لطفاً پوشه اسپم (Spam) پست الکترونیکی خود را نیز چک کنید.",
                    status: "success",
                });
                setDisabled(true);
                setTimeout(() => {
                    props.router.push({ pathname: "/users/signin", query: { ...props.router.query } });
                }, 5000);
            } else {
                setDisabled(false);
                await errorParser(serverResponse);
            }
        }
        setLoading(false);
    };

    const [isGreaterThan355px] = useMediaQuery("(min-width: 355px)");
    return (
        <div className={`flex flex-col pt-10 pb-8 px-10 w-full justify-center items-center mx-auto mr-8 ${Style.signup_form_wrapper}`}>
            <div className={`w-3/5 py-12 px-8 rounded-md ${Style.card}`}>
                <div className="mb-6">
                    <Heading as="h2" size={isGreaterThan355px ? "lg" : "md"} className="auth-form-supertitle">
                        ایجاد حساب کاربری
                    </Heading>
                    <Heading as="h4" size="xs" fontWeight="300" className="auth-form-title">
                        به جامعه بزرگ <b>RoboEpics</b> بپیوندید.
                    </Heading>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="auth-form-inputs flex flex-col mb-3">
                        <Collapse in={alertProps.open}>
                            <Alert status={alertProps.status} variant={colorMode === "dark" ? "subtle" : "solid"}>
                                <AlertIcon />
                                <AlertDescription mr={2}>{alertProps.message}</AlertDescription>
                            </Alert>
                        </Collapse>
                        <Input
                            className="my-3"
                            type="text"
                            placeholder="نام و نام خانوادگی"
                            variant="filled"
                            value={values.fullNameFa}
                            onChange={handleChange("fullNameFa")}
                            id="signup-full-name-fa-input"
                            isDisabled={registerClosed}
                        />
                        {/* <Input
                            dir="ltr"
                            className="my-3"
                            type="text"
                            placeholder="Full Name"
                            variant="filled"
                            value={values.fullNameEn}
                            onChange={handleChange("fullNameEn")}
                            id="signup-full-name-en-input"
                        /> */}
                        <Input
                            dir="ltr"
                            _placeholder={{ textAlign: "right" }}
                            className="my-3"
                            type="text"
                            placeholder="نام کاربری"
                            variant="filled"
                            value={values.username}
                            onChange={handleChange("username")}
                            id="signup-username-input"
                            isDisabled={registerClosed}
                        />
                        <Input
                            dir="ltr"
                            className="my-3"
                            type="email"
                            _placeholder={{ textAlign: "right" }}
                            placeholder="پست الکترونیکی"
                            variant="filled"
                            value={values.email}
                            onChange={handleChange("email")}
                            id="signup-email-input"
                            isDisabled={registerClosed}
                        />
                        <InputGroup size="md" className="my-3">
                            <InputRightElement bg={"transparent"}>
                                <IconButton
                                    bg={"transparent"}
                                    aria-label="signup-show-password"
                                    size="sm"
                                    icon={values.showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    onClick={handleShowPassword}
                                ></IconButton>
                            </InputRightElement>
                            <Input
                                value={values.password}
                                onChange={handleChange("password")}
                                variant="filled"
                                type={values.showPassword ? "text" : "password"}
                                placeholder="رمز عبور"
                                isDisabled={registerClosed}
                            />
                        </InputGroup>
                        <InputGroup size="md" className="my-3">
                            <Input
                                value={values.passwordConfirm}
                                onChange={handleChange("passwordConfirm")}
                                variant="filled"
                                type={values.showPasswordConfirm ? "text" : "password"}
                                placeholder="تکرار رمز عبور"
                                isDisabled={registerClosed}
                            />
                            <InputRightElement bg={"transparent"}>
                                <IconButton
                                    bg={"transparent"}
                                    aria-label="signup-show-password"
                                    size="sm"
                                    icon={values.showPasswordConfirm ? <ViewIcon /> : <ViewOffIcon />}
                                    onClick={handleShowPasswordConfirm}
                                ></IconButton>
                            </InputRightElement>
                        </InputGroup>
                    </div>

                    <div className="signup-button mb-6">
                        <ChakraButton
                            type="submit"
                            isDisabled={disabled || registerClosed}
                            isLoading={loading}
                            bg={bg}
                            color={btnTxt}
                            variant="solid"
                            isFullWidth
                            onClick={onSignup}
                            _hover={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                            _active={{
                                background: colorMode === "dark" ? theme.colors.RoboEpics.gold["500"] : theme.colors.RoboEpics.black["900"],
                            }}
                        >
                            ثبت‌نام
                        </ChakraButton>
                    </div>
                </form>
                <div className="signup-link-to-login">
                    <Link href={{ pathname: "/users/signin", query: { ...props.router.query } }} passHref>
                        <ChakraLink
                            _hover={{
                                color: colorMode === "dark" ? theme.colors.RoboEpics.azure["200"] : theme.colors.RoboEpics.turquoise["600"],
                            }}
                        >
                            {" "}
                            ورود به حساب کاربری
                        </ChakraLink>
                    </Link>
                </div>
            </div>
        </div>
    );
};
