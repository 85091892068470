import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Table,
    Box,
    useColorModeValue,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    Button,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Heading,
    Avatar,
    IconButton,
    Link as ChakraLink,
} from "@chakra-ui/react";
import { Pagination } from "@components";
import { LEADERBOARD_ITEMS_PER_PAGE } from "@util/constants";
import { convertDate } from "@util/dateConverter";
import { IdDisplayer } from "@components/competition";
import { SingleProblemIndividualChart } from "@components";
// import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { getParticipant } from "@util/api/participants";
import { NextRouter, useRouter } from "next/router";
import { CustomAvatar } from "@components/avatar";
import { getImageURL } from "@util/mediaLoader";
import { MiniUserProfile } from "@components/miniUserProfile";

import Style from "./competitionScoreboard.module.css";
import { RefreshOutline } from "react-ionicons";
import {
    CompetitionLeaderboard,
    // CompetitionLeaderboardModal,
} from "@util/api/leaderboard";
import { IScoreboardModalProps, IToastOptions } from "@util/interfaces";
import Link from "next/link";
import { useSelector } from "react-redux";

export const CompetitionScoreboard = (props: { competition: string | number; toast: IToastOptions; router: NextRouter }) => {
    const [scoreboardData, setScoreboardData] = useState<{
        results: Array<{
            creator_full_name: string;
            creator_profile_picture: string | null;
            individual: boolean;
            last_submission: string;
            participant_id: number;
            problem_enter_id: number;
            phase_scores: Array<{ phase_id: number; score: "-" | number }>;
            score: number;
            submission_dates: Array<string>;
            team_id: number;
            team_image: string;
            team_name: string;
        }>;
        phases: Array<{ title: string; id: number | string }>;
        team_rank: number;
        total: number | null;
    }>({
        results: [],
        phases: [],
        team_rank: null,
        total: null,
    });
    const router = useRouter();
    const user = useSelector((state: { user: any }) => state.user);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalContentType, setModalContentType] = useState<string>("");
    const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [noLeaderboardLoading, setNoLeaderboardLoading] = useState<boolean>(false);

    const [profilePreviewModal, setProfilePreviewModal] = useState({
        visible: false,
        loading: false,
    });
    const [modalParticipantId, setModalParticipantId] = useState<number>(-1);
    const [modalPhaseId, setModalPhaseId] = useState<number>(-1);
    const [modalData, setModalData] = useState<IScoreboardModalProps>(null);
    const [modalPhaseData, setModalPhaseData] = useState<any>([
        {
            problem_id: 1,
            problem_title: "سوال شماره",
            submission_dates: [
                "2021-06-25T14:41:22.677736Z",
                "2021-06-25T14:31:20.207037Z",
                "2021-06-25T14:16:37.222542Z",
                "2021-06-25T12:23:04.442850Z",
                "2021-06-25T00:27:59.497837Z",
                "2021-06-22T07:04:26.006552Z",
                "2021-06-21T19:14:57.940266Z",
                "2021-06-21T19:03:21.036019Z",
                "2021-06-21T19:01:56.109726Z",
                "2021-06-10T19:30:02.999057Z",
            ],
            score: 0.14565,
        },
        {
            problem_id: 2,
            problem_title: "سوال شماره",
            submission_dates: [
                "2021-06-25T14:41:22.677736Z",
                "2021-06-25T14:31:20.207037Z",
                "2021-06-25T14:16:37.222542Z",
                "2021-06-25T12:23:04.442850Z",
                "2021-06-25T00:27:59.497837Z",
                "2021-06-22T07:04:26.006552Z",
                "2021-06-21T19:14:57.940266Z",
                "2021-06-21T19:03:21.036019Z",
                "2021-06-21T19:01:56.109726Z",
                "2021-06-10T19:30:02.999057Z",
            ],
            score: 0.14565,
        },
        {
            problem_id: 3,
            problem_title: "سوال شماره",
            submission_dates: [
                "2021-06-25T14:41:22.677736Z",
                "2021-06-25T14:31:20.207037Z",
                "2021-06-25T14:16:37.222542Z",
                "2021-06-25T12:23:04.442850Z",
                "2021-06-25T00:27:59.497837Z",
                "2021-06-22T07:04:26.006552Z",
                "2021-06-21T19:14:57.940266Z",
                "2021-06-21T19:03:21.036019Z",
                "2021-06-21T19:01:56.109726Z",
                "2021-06-10T19:30:02.999057Z",
            ],
            score: 0.14565,
        },
    ]);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(router.query.p ? parseInt(router.query.p.toString()) : 1);

    const table_header_text = useColorModeValue("RoboEpics.black.500", "#fff");
    const finalScoresRowStyle = {
        backgroundColor: useColorModeValue("rgba(85,166,48,0.15)", "rgba(45,406,79,0.03)"),
        fontWeight: 600,
    };

    const setData = async () => {
        setLoadingComponent(true);
        const startIndex = (currentPage - 1) * LEADERBOARD_ITEMS_PER_PAGE;
        const endIndex = startIndex + LEADERBOARD_ITEMS_PER_PAGE;
        const serverResponse = (await CompetitionLeaderboard(
            props.competition,
            {
                start: startIndex,
                end: endIndex,
            },
            { toast: props.toast, requesterPath: props.router.asPath },
        )) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setTotalItems(data.total);
            setScoreboardData(data);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
        setLoadingComponent(false);
    };
    async function _setModalData() {
        setModalLoading(true);

        const serverResponse = (await getParticipant(modalParticipantId.toString(), {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { team } = await serverResponse.json();
            setModalData(team);
        } else {
            setErrorLoading({
                hasError: true,
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }

        setModalLoading(false);
    }
    useEffect(() => {
        if (props.competition) {
            setData();
        }
    }, [props.competition, currentPage]);
    useEffect(() => {
        if (modalParticipantId >= 0) {
            _setModalData();
        }
    }, [modalParticipantId]);

    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }
    if (errorLoading.hasError) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    } else if (noLeaderboardLoading) {
        return (
            <Alert status="info">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    در حال حاضر لیدربرد برای این مسابقه غیرفعال است.
                </AlertTitle>
            </Alert>
        );
    }
    return (
        <>
            <Box className="flex flex-row w-full mt-4 items-center justify-between">
                {scoreboardData.team_rank != null && (
                    <Text mb={0} fontSize="xl" fontWeight={800}>
                        رتبه تیم شما: {scoreboardData.team_rank + 1}
                    </Text>
                )}
                <IconButton
                    variant="ghost"
                    icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                    size="sm"
                    aria-label="Refresh list"
                    onClick={() => {
                        setData();
                    }}
                    mr="auto"
                />
            </Box>

            <Box as="div" className="w-full h-full" overflowX="auto">
                {loadingComponent ? (
                    <Box className="flex flex-col items-center">
                        <Spinner className="mt-8" />
                    </Box>
                ) : (
                    <>
                        <Table size="lg" variant="simple">
                            <Thead color={table_header_text}>
                                <Tr>
                                    {/* <Th
                            letterSpacing="0"
                            textAlign="center"
                            pl="1"
                            pr="2"
                        ></Th> */}
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        رتبه
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        شرکت‌کننده
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        تعداد ارسال
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        آخرین ارسال
                                    </Th>
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                        روند ارسال‌ها
                                    </Th>
                                    {scoreboardData.phases?.map((element) => {
                                        return (
                                            <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600">
                                                {element.title}
                                            </Th>
                                        );
                                    })}
                                    <Th letterSpacing="0" textAlign="center" color="RoboEpics.black.600" sx={finalScoresRowStyle}>
                                        امتیاز نهایی
                                    </Th>
                                    {/* <Th
                            letterSpacing="0"
                            textAlign="center"
                            color="RoboEpics.black.600"
                        ></Th> */}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {scoreboardData.results.map((element, index: number) => {
                                    return (
                                        <Tr key={index} fontSize="14px">
                                            {/* <Td
                                    textAlign={["center"]}
                                    py="0"
                                    pl="1"
                                    pr="2"
                                    fontSize="12px"
                                >
                                    <div className=" flex flex-row justify-center items-center">
                                        <Tooltip
                                            hasArrow
                                            label="رتبه قبلی:5"
                                            placement="top"
                                            color={useColorModeValue(
                                                "green.400",
                                                "green.200",
                                            )}
                                            aria-label="positive"
                                        >
                                            <TriangleUpIcon
                                                color={useColorModeValue(
                                                    "green.400",
                                                    "green.200",
                                                )}
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            hasArrow
                                            label="بدون تغییر"
                                            placement="top"
                                            aria-label="neutral"
                                        >
                                            <span className="bg-gray-400 rounded-full h-2 w-2 " />
                                        </Tooltip>{" "}
                                        <Tooltip
                                            hasArrow
                                            label="رتبه قبلی: 5"
                                            placement="top"
                                            color={useColorModeValue(
                                                "red.500",
                                                "red.300",
                                            )}
                                            aria-label="negative"
                                        >
                                            <TriangleDownIcon
                                                color={useColorModeValue(
                                                    "red.500",
                                                    "red.300",
                                                )}
                                            />
                                        </Tooltip>
                                    </div>
                                </Td> */}
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {(currentPage - 1) * LEADERBOARD_ITEMS_PER_PAGE + index + 1}
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                <Button
                                                    variant="ghost"
                                                    minW="208px"
                                                    justifyContent="flex-start"
                                                    padding="24px 20px"
                                                    margin="12px 20px"
                                                    onClick={() => {
                                                        onOpen();
                                                        setModalParticipantId(element.participant_id);
                                                        setModalContentType("profile");
                                                    }}
                                                >
                                                    <IdDisplayer
                                                        name={element.individual ? element.creator_full_name : element.team_name}
                                                        image={element.individual ? element.creator_profile_picture : element.team_image}
                                                    />
                                                </Button>
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {element.submission_dates.length}
                                            </Td>
                                            <Td
                                                textAlign={["center"]}
                                                pl="0"
                                                pr="3"
                                                py="0"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                {convertDate(element.last_submission, "D MMMM YYYY")}
                                            </Td>
                                            <Td
                                                py="0"
                                                sx={{
                                                    ".chartjs-render-monitor": {
                                                        width: "150px !important",
                                                        height: "80px !important",
                                                    },
                                                }}
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                            >
                                                <SingleProblemIndividualChart submissionData={element.submission_dates} />
                                            </Td>
                                            {scoreboardData.phases.map((p) => {
                                                return (
                                                    <Td key={`${p.id}-${p.title}`} textAlign="center" py={0}>
                                                        {/* <Button
                                                                variant="ghost"
                                                                padding="24px 20px"
                                                                onClick={() => {
                                                                    onOpen();
                                                                    setModalParticipantId(
                                                                        element.participant_id,
                                                                    );
                                                                    setModalContentType(
                                                                        "phase",
                                                                    );
                                                                }}
                                                            > */}
                                                        {element.phase_scores.find((s) => s.phase_id === p.id)?.score ?? "---"}
                                                        {/* </Button> */}
                                                    </Td>
                                                );
                                            })}

                                            {/*<Td
                                                py="0"
                                                sx={{
                                                    ".chartjs-render-monitor": {
                                                        width:
                                                            "150px !important",
                                                        height:
                                                            "80px !important",
                                                    },
                                                }}
                                                borderBottomWidth={
                                                    index ===
                                                    LEADERBOARD_ITEMS_PER_PAGE -
                                                        1
                                                        ? "0"
                                                        : "1px"
                                                }
                                            >
                                                <SingleProblemIndividualChart
                                                    submissionData={
                                                        element.submission_dates
                                                    }
                                                />
                                            </Td> */}
                                            <Td
                                                textAlign={["center"]}
                                                py="0"
                                                fontWeight="700"
                                                borderBottomWidth={index === LEADERBOARD_ITEMS_PER_PAGE - 1 ? "0" : "1px"}
                                                sx={finalScoresRowStyle}
                                            >
                                                {element.score}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                        <Pagination
                            totalRecords={totalItems}
                            pageNeighbours={1}
                            currentPage={currentPage}
                            onPageChanged={(page) => {
                                setCurrentPage(page);
                                router.push(`/c/${router.query.competition}/scoreboard?p=${page}`, undefined, {
                                    shallow: true,
                                });
                            }}
                        />
                    </>
                )}
                <Modal onClose={onClose} isOpen={isOpen}>
                    <ModalOverlay />
                    <ModalContent className={`${Style.modal_content}`}>
                        <ModalHeader>
                            <Heading size="md">اطلاعات شرکت‌کننده</Heading>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {modalLoading ? (
                                <Box className="flex flex-col items-center">
                                    <Spinner className="my-12" />
                                </Box>
                            ) : modalData?.individual ? (
                                <Box as="div" className={`flex flex-col items-center w-full`}>
                                    <CustomAvatar
                                        src={getImageURL(modalData?.member_set[0].user.profile_picture)}
                                        showPlaceholder={!modalData?.member_set[0].user.profile_picture}
                                        placeholder={<Avatar size="full" />}
                                        className="mb-2 px-0 py-0 border-transparent border-0"
                                        style={{
                                            width: modalContentType === "profile" ? "100px" : "50px",
                                            height: modalContentType === "profile" ? "100px" : "50px",
                                        }}
                                    />
                                    <Text fontWeight={700} fontSize={modalContentType === "profile" ? "2xl" : "xl"} className="mb-0">
                                        {modalData?.member_set[0].user.full_name}
                                    </Text>
                                    <div>
                                        <span
                                            // className="font-semibold"
                                            style={{
                                                direction: "ltr",
                                                color: "#898989",
                                                fontFamily: "sans-serif",
                                            }}
                                        >
                                            {modalData?.member_set[0].user.username + " @"}
                                        </span>
                                    </div>
                                    {modalContentType === "phase" && (
                                        <Table mt={4} size="md" variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th pl={0} pr={1}>
                                                        سوال
                                                    </Th>
                                                    <Th pl={0}>تعداد ارسال‌ها</Th>
                                                    <Th pl={0}>امتیاز</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {modalPhaseData?.map((problem: any, index: number) => {
                                                    return (
                                                        <Tr
                                                            id={index.toString()}
                                                            key={index}
                                                            sx={{
                                                                td: {
                                                                    paddingBottom: "12px",
                                                                    paddingTop: "12px",
                                                                    fontSize: "14px",
                                                                    fontWeight: "normal",
                                                                },
                                                            }}
                                                        >
                                                            <Td textAlign={["center"]} pl={0} pr={1}>
                                                                <Button
                                                                    variant="ghost"
                                                                    padding="24px 20px"
                                                                    justifyContent="flex-start"
                                                                    onClick={() => {
                                                                        router.push(
                                                                            `/c/${router.query.competition}/p/${modalPhaseId}/p/${problem.problem}`,
                                                                        );
                                                                    }}
                                                                >
                                                                    {problem.problem_title + " " + problem.problem_id.toString()}
                                                                </Button>
                                                            </Td>
                                                            <Td textAlign={["center"]} pl={0} pr={1}>
                                                                {problem.submission_dates.length}
                                                            </Td>
                                                            <Td textAlign={["center"]} pl={0} pr={1}>
                                                                {problem.score}
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    )}
                                </Box>
                            ) : (
                                <Box as="div" className={`flex flex-col items-center w-full`}>
                                    <CustomAvatar
                                        src={getImageURL(modalData?.image)}
                                        showPlaceholder={!modalData?.image}
                                        placeholder={<Avatar size="full" />}
                                        className="mb-2 px-0 py-0 border-transparent border-0"
                                        style={{
                                            width: modalContentType === "profile" ? "100px" : "50px",
                                            height: modalContentType === "profile" ? "100px" : "50px",
                                        }}
                                    />
                                    <Text fontWeight={700} fontSize={modalContentType === "profile" ? "2xl" : "xl"} className="mb-2">
                                        {modalData?.name}
                                    </Text>
                                    <Box className="w-full h-full" overflowX="auto">
                                        {modalContentType === "phase" && (
                                            <Table mt={4} size="md" variant="simple">
                                                <Thead>
                                                    <Tr>
                                                        <Th pl={0} pr={1}>
                                                            سوال
                                                        </Th>
                                                        <Th pl={0}>تعداد ارسال‌ها</Th>
                                                        <Th pl={0}>امتیاز</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {modalPhaseData?.map((problem: any, index: number) => {
                                                        return (
                                                            <Tr
                                                                id={index.toString()}
                                                                key={index}
                                                                sx={{
                                                                    td: {
                                                                        paddingBottom: "12px",
                                                                        paddingTop: "12px",
                                                                        fontSize: "14px",
                                                                        fontWeight: "normal",
                                                                    },
                                                                }}
                                                            >
                                                                <Td textAlign={["center"]} pl={0} pr={1}>
                                                                    <Button
                                                                        variant="ghost"
                                                                        padding="24px 20px"
                                                                        justifyContent="flex-start"
                                                                        // onClick={() => {
                                                                        //     router.push(
                                                                        //         `/c/${router.query.competition}/p/${modalPhaseId}/p/${problem.problem_id}`,
                                                                        //     );
                                                                        // }}
                                                                    >
                                                                        {problem.problem_title + " " + problem.problem_id.toString()}
                                                                    </Button>
                                                                </Td>
                                                                <Td textAlign={["center"]} pl={0} pr={1}>
                                                                    {problem.submission_dates.length}
                                                                </Td>
                                                                <Td textAlign={["center"]} pl={0} pr={1}>
                                                                    {problem.score}
                                                                </Td>
                                                            </Tr>
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        )}
                                        {modalContentType === "profile" && (
                                            <Table mt={4} size="md" variant="simple">
                                                <Thead>
                                                    <Tr>
                                                        <Th pl={0} pr={1} minW="200px">
                                                            عضو
                                                        </Th>
                                                        <Th pl={0}>نقش</Th>
                                                        {/* <Th pl={0}>هم‌تیمی از</Th> */}
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {modalData?.member_set.map((memberData) => {
                                                        return (
                                                            <Tr
                                                                id={memberData.user.fusion_user_id}
                                                                key={memberData.user.fusion_user_id}
                                                                sx={{
                                                                    td: {
                                                                        paddingBottom: "12px",
                                                                        paddingTop: "12px",
                                                                        fontSize: "14px",
                                                                        fontWeight: "normal",
                                                                    },
                                                                }}
                                                            >
                                                                <Td textAlign={["center"]} pl={0} pr={1}>
                                                                    <MiniUserProfile
                                                                        fullName={memberData.user.full_name}
                                                                        username={memberData.user.username}
                                                                        imageFilename={memberData.user.profile_picture}
                                                                    />
                                                                </Td>
                                                                <Td pl={0}>{memberData?.access_level === 30 ? "عضو عادی" : "سرگروه"}</Td>
                                                                {/* <Td pl={0}>
                                                                {getDatePhrase(
                                                                    memberData.data_joined,
                                                                    "roboepics",
                                                                )}
                                                            </Td> */}
                                                            </Tr>
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </ModalBody>
                        {modalData?.individual && modalContentType === "profile" && (
                            <ModalFooter>
                                <Link href={`/users/${modalData?.member_set[0].user.username}`} passHref>
                                    <ChakraLink isExternal>
                                        <Button size="sm" colorScheme={useColorModeValue("RoboEpics.green", "RoboEpics.gold")}>
                                            مشاهده پروفایل
                                        </Button>
                                    </ChakraLink>
                                </Link>
                            </ModalFooter>
                        )}
                    </ModalContent>
                </Modal>
            </Box>
        </>
    );
};
