import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { datasetEditJson } from "@util/api/dataset";
import { IDatasetBrief, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const EditModal = (props: {
    isOpen: boolean;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    dataset: IDatasetBrief;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [title, setTitle] = useState(props.dataset?.title);
    const [subtitle, setSubtitle] = useState(props.dataset?.subtitle);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const onConfirm = async () => {
        const data = {};
        if (props.dataset.title !== title) data["title"] = title;

        if (props.dataset.subtitle !== subtitle) data["subtitle"] = subtitle;

        const serverResponse = (await datasetEditJson(props.dataset.dataset_path, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 3000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
            setDisabled(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setTitle(props.dataset.title);
            setSubtitle(props.dataset.subtitle);
        }
        setLoading(false);
    };
    useEffect(() => {
        setTitle(props.dataset?.title);
        setSubtitle(props.dataset?.subtitle);
    }, [props.dataset]);
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size={"lg"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>تغییر دادن اطلاعات دیتاست</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel fontSize={"14px"}>عنوان دیتاست</FormLabel>
                                <Input
                                    variant={"filled"}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                    id={"edit_dataset_title"}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontSize={"14px"}>توضیح مختصری از دیتاست</FormLabel>
                                <Input
                                    variant={"filled"}
                                    value={subtitle}
                                    onChange={(e) => {
                                        setSubtitle(e.target.value);
                                    }}
                                    id={"edit_dataset_subtitle"}
                                />
                            </FormControl>
                            <Button
                                className={"mb-2"}
                                type={"submit"}
                                isDisabled={
                                    title?.length * subtitle?.length === 0 ||
                                    (title === props.dataset?.title && subtitle === props.dataset?.subtitle) ||
                                    disabled
                                }
                                isLoading={loading}
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                placeSelf={"flex-end"}
                                onClick={() => {
                                    setLoading(true);
                                    onConfirm();
                                }}
                            >
                                Confirm
                            </Button>
                        </VStack>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
