import React, { useState } from "react";
import { NextRouter } from "next/router";

import { Box, Button, Heading, useColorModeValue, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { CustomAvatar } from "@components/avatar";
import { ArrowBackOutline, TrophyOutline, SearchOutline, ImageOutline, Image } from "react-ionicons";
import classes from "./CompetitionCards.module.css";
import { ICompetitionCardInfo, IToastOptions } from "@util/interfaces";
import Link from "next/link";
import { getDatePhrase, getDateStatus } from "@util/dateConverter";
import { getImageURL } from "@util/mediaLoader";

export const CompetitionView = (props: { cards: Array<ICompetitionCardInfo>; toast: IToastOptions; router: NextRouter }) => {
    const [searchQuery, setSearchQuery] = useState(props.router.query.s);

    return (
        <>
            <Box className={`${classes.compHeaderBack} px-12 flex items-center my-8`}>
                <div className="flex justify-between w-full">
                    <div>
                        <a href="/c">
                            <Heading size="xl">مسابقه‌ها</Heading>
                        </a>
                    </div>
                    <div className={`${classes.nb_header_buttons} flex flex-row items-center`}>
                        {/* <Button
                             w={"50%"}
                             ml={4}
                             size="sm"
                             leftIcon={<AddIcon />}
                             colorScheme={useColorModeValue(
                                 "RoboEpics.dark",
                                 "RoboEpics.gold",
                             )}
                             onClick={() => {
                                 router.push(`/c/create`);
                             }}
                         >
                             ایجاد مسابقه‌
                         </Button> */}
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchOutline height="20px" width="20px" color="gray.300" />}
                            />
                            <Input
                                placeholder="جست‌و‌جو ..."
                                defaultValue={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        e.preventDefault();
                                        if (searchQuery) props.router.push(`/c?s=${searchQuery}`, {}, { shallow: true });
                                    }
                                }}
                            />
                        </InputGroup>
                    </div>
                </div>
            </Box>
            <CompetitionCards cards={props.cards} />
        </>
    );
};

export const CompetitionCards = (props: { cards: Array<ICompetitionCardInfo> }) => {
    return (
        <div className={`${classes.compCardGrid} px-12 pb-16`} data-testid="competition-cards-container">
            {props.cards.map((compInfo) => {
                return <CompetitionCard key={compInfo.id} cardInfo={compInfo} data-testid="competition-card" />;
            })}
        </div>
    );
};

export const CompetitionCard = (props: { cardInfo: ICompetitionCardInfo }) => {
    let statusTagBackgroundColor;
    const cardData = props.cardInfo;
    const path = `/c/${cardData.path}`;

    switch (cardData.status) {
        default:
            statusTagBackgroundColor = "rgba(0,0,0,0.65)";
            break;
    }

    switch (cardData.participation_type) {
        case 10:
        default:
            cardData.participation_type = "انفرادی";
            break;
        case 30:
            cardData.participation_type = "انفرادی و تیمی";
            break;
    }

    const t = getDateStatus(cardData.register_date_start);

    let status = "برگزار نشده";

    switch (cardData.status) {
        case 10:
            status = `${t.substring(0, t.length - 4)} تا آغاز ثبت نام`;
            break;
        case 20:
            status = "ثبت نام آغاز شده";
            break;
        case 30:
            status = "در حال برگزاری";
            break;
        case 40:
            status = "خاتمه یافته";
    }

    return (
        <Box
            bg={useColorModeValue("RoboEpics.black.100", "RoboEpics.dark.600")}
            _hover={{
                bg: useColorModeValue("gray.100", "RoboEpics.dark.800"),
            }}
            className="border rounded-lg"
            h="fit-content"
            data-testid="competition-card"
        >
            <div>
                <div className={`${classes.cardCover_wrapper}`}>
                    <div
                        className={`${classes.cardCover_tag} py-1 px-2 rounded`}
                        style={{
                            backgroundColor: statusTagBackgroundColor,
                            color: "white",
                        }}
                    >
                        {status}
                    </div>

                    <a href={path} className={`${classes.cardCover_link}`}>
                        <img
                            alt="Competition Cover Image"
                            className={`rounded-t-lg ${classes.cardCover}`}
                            src={getImageURL(cardData.cover_image) ?? "/images/bannerPlaceholder.png"}
                        />
                    </a>
                </div>
                <div className="px-3 pb-2 py-3 flex flex-row">
                    <div
                        style={{
                            flex: "0 0 50px",
                        }}
                    >
                        <Link passHref href={path}>
                            <CustomAvatar
                                noBorder={!!getImageURL(cardData.thumbnail)}
                                showPlaceholder={!getImageURL(cardData.thumbnail)}
                                placeholder={useColorModeValue(<ImageOutline color="black" />, <Image color="white" />)}
                                src={getImageURL(cardData.thumbnail)}
                            />
                        </Link>
                    </div>
                    <div
                        className="pr-2"
                        style={{
                            flex: "0 1 calc(100% - .75rem)",
                            width: "calc(100% - 0.75rem - 50px)",
                        }}
                    >
                        <div className="w-full">
                            <Link passHref href={path}>
                                <h3
                                    className="truncate font-semibold"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {cardData.title}
                                </h3>
                            </Link>
                            {/* <span
                                className="mt-1 truncate block"
                                style={{
                                    color: "#898990",
                                    fontSize: "13px",
                                }}
                            >
                                {cardData.subtitle}
                            </span> */}
                            <div className="flex items-center">
                                <span
                                    className="mt-1 truncate"
                                    style={{
                                        color: "#898990",
                                        fontSize: "13px",
                                    }}
                                >
                                    {getDatePhrase(cardData.date_published, "roboepics")}
                                </span>
                                <div className="inline-block w-1 h-1 rounded-full bg-gray-300 mx-2" />
                                <span
                                    className="mt-1 truncate"
                                    style={{
                                        color: "#898990",
                                        fontSize: "13px",
                                    }}
                                >
                                    {cardData.total_participants} شرکت کننده
                                </span>
                            </div>
                        </div>

                        {/* <div className="text-center mt-2">
                            <span
                                style={{ fontWeight: "normal" }}
                                className="mr-2"
                            >
                                {cardData.prize}
                            </span>
                        </div>
                        <div className="mt-4 flex flex-row justify-around items-center">
                            <Stat
                                title="شرکت‌کنندگان"
                                value={cardData.total_participants}
                            />
                            <Stat
                                title="ارسال‌ها"
                                value={cardData.total_submissions}
                            />
                        </div>
                        <hr />
                        <div className="mt-4 flex flex-row justify-center items-center">
                            <span>{cardData.date_published}</span>
                            <span className="bg-gray-400 rounded-full h-1 w-1 mx-2" />
                            <span>{cardData.participation_type}</span>
                            <span className="bg-gray-400 rounded-full h-1 w-1 mx-2" />
                            <span>{cardData.difficulty}</span>
                        </div>
                        <div className="my-4 flex flex-row justify-center items-center">
                            {cardData.tags.map((el) => {
                                return (
                                    <Tag
                                        bg={tagBg}
                                        color={tagText}
                                        size="sm"
                                        key={el}
                                        className="mx-1"
                                    >
                                        {el}
                                    </Tag>
                                );
                            })}
                        </div> */}
                    </div>
                </div>
                <hr style={{ margin: "0" }} />
                <Box
                    bg={useColorModeValue("gray.100", "var(--chakra-colors-RoboEpics-dark-500)")}
                    _hover={{
                        bg: useColorModeValue("gray.100", "RoboEpics.dark.600"),
                    }}
                    fontSize="14px"
                    className="px-3 py-3 rounded-b-lg flex justify-between"
                >
                    <div className="flex items-center">
                        <TrophyOutline color={useColorModeValue("#898989", "#a8a8a9")} height="18px" width="18px" />
                        <span
                            style={{
                                color: useColorModeValue("#303030", "#e8e8e9"),
                            }}
                            className="mr-2"
                        >
                            {cardData.prize ?? "تعیین نشده"}
                        </span>
                    </div>
                    <div>
                        <Link href={path} passHref>
                            <Button
                                size="sm"
                                variant="link"
                                iconSpacing=".3rem"
                                rightIcon={<ArrowBackOutline height="16px" width="16px" color={useColorModeValue("#000", "#fff")} />}
                            >
                                مشاهده
                            </Button>
                        </Link>
                    </div>
                </Box>
            </div>
        </Box>
    );
};
