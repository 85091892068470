import React, { useRef, useState } from "react";
import {
    Button,
    useColorModeValue,
    Link as ChakraLink,
    IconButton,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
} from "@chakra-ui/react";
import Link from "next/link";
import styles from "./CompSidebar.module.css";
import { Trash } from "react-ionicons";
import { useRouter } from "next/router";
import { problemDelete } from "@util/api/problems";
import { IMenuLink } from "@util/interfaces";

const MenuLink = (props: IMenuLink) => {
    const router = useRouter();

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const cancelRef = useRef();

    const hasAccess = () => {
        if (props.isOwner) {
            return true;
        } else if (props.isCollaborator) {
            switch (props.linkKey) {
                case "settings":
                case "checklist":
                case "organizers":
                case "announcements":
                case "discussion":
                    return props.collaborationAccessLevel === 50;
                default:
                    return true;
            }
        } else {
            return false;
        }
    };

    const deleteProblem = async () => {
        const serverResponse = (await problemDelete(props.problemPath, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "info",
                variant: "subtle",
                duration: 5000,
                description: "چالش با موفقیت پاک شد. در حال انتقال شما به صفحه مسابقه",
                isClosable: true,
                title: props.text,
            });
            props.setEditConfirmed(true);
            router.push(`/c/${router.query.competition}`);
        } else {
            props.toast({
                status: "error",
                variant: "subtle",
                duration: 5000,
                description: "در حذف چالش مشکلی به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                isClosable: true,
                title: props.text,
            });
        }
        setDeleteAlertVisible(false);
    };

    return (
        <div
            className={`w-full  ${props.className} ${styles.menu_item_li} ${props.isProblem ? styles.problem_link : ""}`}
            style={props.style}
        >
            {props.isDisabled || !hasAccess() ? (
                <Button variant="ghost" isDisabled px={3} w="inherit" justifyContent="start" className={`flex items-center rounded-md`}>
                    {props.icon === "dot" ? (
                        <span className={`rounded-full w-1 h-1 flex-shrink-0 ${useColorModeValue("bg-black", "bg-white")} ml-2`}></span>
                    ) : (
                        React.cloneElement(props.icon, {
                            cssClasses: "ml-2 flex-shrink-0",
                            color: useColorModeValue("#000", "#fff"),
                            width: "20px",
                            height: "20px",
                        })
                    )}
                    <span
                        className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                        style={{ textOverflow: "ellipsis" }}
                    >
                        {props.text}
                    </span>
                </Button>
            ) : (
                <Link href={props.link} passHref>
                    <ChakraLink
                        w="inherit"
                        className={`px-3 flex items-center justify-start rounded-md h-full ${styles.menu_item_link} ${
                            props.isSelected && "shadow-md"
                        }`}
                        bg={props.isSelected ? useColorModeValue("RoboEpics.gold.500", "RoboEpics.gold.400") : "inherit"}
                        color={props.isSelected ? useColorModeValue("#000", "#000") : useColorModeValue("#000", "#fff")}
                        fontWeight={props.isSelected ? "600" : "500"}
                    >
                        {props.icon === "dot" ? (
                            <span
                                className={`rounded-full w-1 h-1 flex-shrink-0 ${props.isSelected ? "bg-white" : "bg-gray-400"} ml-2`}
                            ></span>
                        ) : (
                            React.cloneElement(props.icon, {
                                cssClasses: "ml-2 flex-shrink-0",
                                color: props.isSelected ? useColorModeValue("#000", "#000") : useColorModeValue("#000", "#fff"),
                                width: "20px",
                                height: "20px",
                            })
                        )}
                        <span
                            className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                            style={{ textOverflow: "ellipsis" }}
                        >
                            {props.text}
                        </span>
                    </ChakraLink>
                </Link>
            )}
            {props.isProblem && (props.isOwner || props.collaborationAccessLevel === 50) && (
                <IconButton
                    aria-label={"delete-problem"}
                    size={"xs"}
                    variant={"ghost"}
                    icon={<Trash color={"red"} width="18px" height="18px" />}
                    onClick={() => {
                        setDeleteAlertVisible(true);
                    }}
                />
            )}
            <AlertDialog
                motionPreset="scale"
                isOpen={deleteAlertVisible}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setDeleteAlertVisible(false);
                }}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>آیا از عمل خود اطمینان دارید؟</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>با حذف کردن چالش، تمام اطلاعات چالش از سیستم حذف خواهند شد و راه بازگشتی وجود ندارد.</AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                setDeleteAlertVisible(false);
                            }}
                        >
                            انصراف
                        </Button>
                        <Button
                            colorScheme="red"
                            mr={3}
                            onClick={() => {
                                setDeleteLoading(true);
                                deleteProblem();
                            }}
                            isLoading={deleteLoading}
                        >
                            تایید
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default MenuLink;
