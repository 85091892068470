import React, { useState, useEffect, useImperativeHandle } from "react";
import { getParticipantSummary, kickMember } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Box,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
} from "@chakra-ui/react";
import { MiniUserProfile } from "@components";
import { getDatePhrase } from "@util/dateConverter";
import { IParticipantRetrieveData, IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";
import { NextRouter } from "next/router";

export const MembersTable = React.forwardRef(
    (
        props: {
            toast: IToastOptions;
            router: NextRouter;
            user: IUser;
            member_set: IParticipantRetrieveData["team"]["member_set"];
            participantId: string | number;
            showManageControls: boolean;
            canManage: boolean;
            refreshFunction: (value?: boolean) => Promise<void>;
            columns?: Array<string>;
        },
        ref,
    ) => {
        const [isLoading, setIsLoading] = useState(true);
        const [member_set, setMemberSet] = useState(props.member_set);
        const columns = props.columns ?? ["member", "role", "submissions", "date_joined"];

        const [confirmModalVisible, setConfirmModalVisible] = useState(false);
        const [kickedMember, setKickedMember] = useState<{
            id: string;
            fullName: string;
        }>({ id: null, fullName: null });
        const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

        useImperativeHandle(ref, () => ({
            refresh() {
                getSummaries();
            },
        }));
        const kick = async () => {
            setConfirmLoading(true);

            const serverResponse = (await kickMember(props.participantId.toString(), kickedMember.id, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                await props.refreshFunction();
                props.toast({
                    description: "عملیات با موفقیت انجام شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
            } else {
                props.toast({
                    title: "مشکلی در حذف عضو تیم رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }

            setConfirmLoading(false);
        };

        const getSummaries = async () => {
            setIsLoading(true);
            const serverResponse = (await getParticipantSummary(props.participantId.toString(), {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const { submissions_per_member } = await serverResponse.json();
                const tmp = props.member_set;

                for (const summaryId of Object.keys(submissions_per_member)) {
                    for (const member of tmp) {
                        if (member.user.fusion_user_id === summaryId) {
                            member["submissions"] = submissions_per_member[`${summaryId}`];
                            break;
                        }
                    }
                }
                setMemberSet(tmp);
            }
            setIsLoading(false);
        };

        useEffect(() => {
            getSummaries();
        }, []);

        if (isLoading) {
            return (
                <Box mx="auto" w="min">
                    <Spinner my={4} />
                </Box>
            );
        }

        return (
            <>
                <Table mt={4} size="md" variant="simple">
                    <Thead
                    // color={table_header_text}
                    >
                        <Tr>
                            {columns.includes("member") && (
                                <Th pl={0} pr={1} minW="200px">
                                    عضو
                                </Th>
                            )}
                            {columns.includes("member") && <Th pl={0}>نقش</Th>}
                            {columns.includes("submissions") && <Th pl={0}>شمار ارسال‌ها</Th>}
                            {columns.includes("date_joined") && <Th pl={0}>زمان عضویت</Th>}
                            {props.showManageControls && <Th>عملیات</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {member_set.map((memberData) => {
                            return (
                                <Tr
                                    id={memberData.user.fusion_user_id}
                                    key={memberData.user.fusion_user_id}
                                    sx={{
                                        td: {
                                            paddingBottom: "12px",
                                            paddingTop: "12px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                        },
                                    }}
                                >
                                    {columns.includes("member") && (
                                        <Td textAlign={["center"]} pl={0} pr={1}>
                                            <MiniUserProfile
                                                fullName={memberData.user.full_name}
                                                username={memberData.user.username}
                                                imageFilename={memberData.user.profile_picture}
                                            />
                                        </Td>
                                    )}
                                    {columns.includes("role") && (
                                        <Td pl={0}>
                                            {memberData.access_level === 30 ? "عضو عادی" : "سرگروه"}
                                            {/* <Badge
                                colorScheme={
                                    memberData.member_level
                                    .colorScheme
                                }
                                >
                                {memberData.member_level.title}
                            </Badge> */}
                                        </Td>
                                    )}
                                    {columns.includes("submissions") && <Td pl={0}>{memberData.submissions}</Td>}
                                    {columns.includes("date_joined") && (
                                        <Td pl={0}>{getDatePhrase(memberData.date_joined, "roboepics")}</Td>
                                    )}
                                    {props.showManageControls && (
                                        <Td pl={0}>
                                            {memberData.user.fusion_user_id !== props.user.data.id && (
                                                <Button
                                                    isDisabled={props.canManage}
                                                    onClick={() => {
                                                        setKickedMember({
                                                            id: memberData.user.fusion_user_id,
                                                            fullName: memberData.user.full_name,
                                                        });
                                                        setConfirmModalVisible(true);
                                                    }}
                                                    size="xs"
                                                    variant="outline"
                                                    colorScheme="red"
                                                >
                                                    اخراج
                                                </Button>
                                            )}
                                        </Td>
                                    )}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <KickConfirmAlert
                    isOpen={confirmModalVisible}
                    isLoading={confirmLoading}
                    onClose={() => setConfirmModalVisible(false)}
                    onSuccess={kick}
                    fullName={kickedMember.fullName}
                />
            </>
        );
    },
);

const KickConfirmAlert = (props: { isOpen: boolean; onClose: () => void; isLoading: boolean; fullName: string; onSuccess: () => void }) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>اخراج عضو از تیم</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>شما در حال اخراج {props.fullName} از تیم‌تان هستید. آیا ادامه می‌دهید؟</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
