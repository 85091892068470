import React, { useState, useEffect } from "react";
import { getNotificationsCount, getNotifications, retrieveUserProfileById } from "@util/api/profile";
import { getCompetition } from "@util/api/competitions";
import { getParticipant } from "@util/api/participants";
import { Box, useColorModeValue, Menu, MenuButton, MenuList, IconButton, MenuDivider, Text, Spinner } from "@chakra-ui/react";
import { BellIcon } from "@chakra-ui/icons";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const Notifications = (props: { toast: IToastOptions; router: NextRouter }) => {
    const bg = useColorModeValue("#fff", "RoboEpics.black.900");

    const [notifCount, setNotifCount] = useState<number>(0);
    const [notifications, setNotifications] = useState<Array<{ error: boolean; el: React.ReactElement }>>([]);
    const [objectsData, setObjectsData] = useState<Array<{ type: "user" | "competition" | "participant"; data: any }>>([]);
    const [loadingNotifs, setLoadingNotifs] = useState<boolean>(false);

    const updateNoficitaionsCount = async () => {
        const serverResponse = (await getNotificationsCount({
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { count } = await serverResponse.json();
            setNotifCount(count);
        }
    };

    const fetchNotifications = async () => {
        setLoadingNotifs(true);
        const limit = notifCount === 0 ? 10 : Math.ceil(notifCount / 5) * 5;
        const serverResponse = (await getNotifications(limit, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        const _notifications = [...notifications];
        const _objectsData = [...objectsData];
        if (serverResponse.ok) {
            const result = await serverResponse.json();
            for (const notif of result) {
                const notifEl: { error: boolean; el: React.ReactElement } = {
                    error: false,
                    el: <></>,
                };
                let actor = {},
                    targetObj = {},
                    actionObj = {};
                let query = null;
                switch (notif.verb) {
                    case "wants to join your team":
                        // Actor: Person who wants to join
                        // Target: Competition
                        // Action Object: Team

                        // ACTOR: JOINER
                        query = _objectsData.filter((el) => el.data.id === notif.actor_object_id);
                        if (query.length === 0) {
                            const _tmp = (await retrieveUserProfileById(notif.actor_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            const data = await _tmp.json();
                            _objectsData.push({ type: "user", data });
                            actor = data;
                        } else {
                            actor = query[0];
                        }

                        // TARGET: COMPETITION
                        query = _objectsData.filter((el) => el.data.id === notif.target_object_id);
                        if (query.length === 0) {
                            const _tmp = (await getCompetition(notif.target_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            _objectsData.push({ type: "competition", data: _tmp });
                            targetObj = _tmp;
                        } else {
                            targetObj = query[0];
                        }

                        // ACTION: TEAM
                        query = _objectsData.filter((el) => el.data.id === notif.action_object_object_id);
                        if (query.length === 0) {
                            const _tmp = (await getParticipant(notif.action_object_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            _objectsData.push({ type: "participant", data: _tmp });
                            actionObj = _tmp;
                        } else {
                            actionObj = query[0];
                        }
                        if (!notifEl.error) {
                            notifEl.el = (
                                <>
                                    <Box>
                                        <Text>
                                            {actor.full_name} درخواستی برای پیوستن به تیم شما ({actionObj.name}) ارسال کرده است.
                                        </Text>
                                        <Text>{targetObj.title}</Text>
                                    </Box>
                                </>
                            );
                        } else {
                            <Box className="py-2 px-2">
                                <Text>خطایی در دریافت داده رخ داد.</Text>
                            </Box>;
                        }
                        break;
                    case "invited you to join them in competition":
                        // Actor: Team
                        // Target: Competition
                        // Action Object: Person who wants to join

                        // ACTOR: Team
                        query = _objectsData.filter((el) => el.data.id === notif.actor_object_id);
                        if (query.length === 0) {
                            const _tmp = (await getParticipant(notif.actor_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            _objectsData.push({ type: "participant", data: _tmp });
                            actor = _tmp;
                        } else {
                            actor = query[0];
                        }

                        // TARGET: COMPETITION
                        query = _objectsData.filter((el) => el.data.id === notif.target_object_id);
                        if (query.length === 0) {
                            const _tmp = (await getCompetition(notif.target_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            _objectsData.push({ type: "competition", data: _tmp });
                            targetObj = _tmp;
                        } else {
                            targetObj = query[0];
                        }

                        // ACTION: INVITER
                        query = _objectsData.filter((el) => el.data.id === notif.action_object_object_id);
                        if (query.length === 0) {
                            const _tmp = (await retrieveUserProfileById(notif.action_object_object_id, {
                                toast: props.toast,
                                requesterPath: props.router.asPath,
                            })) as Response;
                            if (!_tmp.ok) {
                                notifEl.error = true;
                                continue;
                            }
                            const data = await _tmp.json();
                            _objectsData.push({ type: "user", data });
                            actionObj = data;
                        } else {
                            actionObj = query[0];
                        }
                        if (!notifEl.error) {
                            notifEl.el = (
                                <>
                                    <Box className="py-2 px-2">
                                        <Text>
                                            {actor.full_name} شما را به تیم {actionObj.name} دعوت کرده است.
                                        </Text>
                                        <Text>{targetObj.title}</Text>
                                    </Box>
                                </>
                            );
                        } else {
                            <Box className="py-2 px-2">
                                <Text>خطایی در دریافت داده رخ داد.</Text>
                            </Box>;
                        }
                        break;
                }
                _notifications.push(notifEl);
            }
        }

        setObjectsData(_objectsData);
        setNotifications(_notifications);
        setLoadingNotifs(false);
    };

    useEffect(() => {
        // updateNoficitaionsCount();
        // const checkNewNotifications = setInterval(() => {
        //     updateNoficitaionsCount();
        // }, 60000);
        // return () => {
        //     clearInterval(checkNewNotifications);
        // };
    }, []);
    return (
        <Menu
            isLazy
            lazyBehavior="unmount"
            //  onOpen={fetchNotifications}
        >
            <MenuButton
                as={IconButton}
                className="mx-1"
                variant="ghost"
                size="sm"
                aria-label="اعلان ها"
                position="relative"
                icon={
                    <>
                        {notifCount !== 0 && (
                            <Box
                                sx={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "999px",
                                    backgroundColor: "red",
                                    position: "absolute",
                                    left: "7px",
                                    top: "7px",
                                }}
                            />
                        )}

                        <BellIcon w={"1.5em"} h={"1.5em"} />
                    </>
                }
            />
            <MenuList minWidth="16rem" bg={bg}>
                <div className="mb-2 text-center">
                    <span>اعلان‌ها</span>
                </div>
                <MenuDivider />
                <div className="mb-2 text-center">
                    {loadingNotifs ? (
                        <Spinner />
                    ) : notifications.length === 0 ? (
                        <Text>اعلانی وجود ندارد</Text>
                    ) : (
                        notifications.map((el) => {
                            return el.el;
                        })
                    )}
                </div>
            </MenuList>
        </Menu>
    );
};
