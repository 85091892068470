import React, { useState, useEffect } from "react";
import { getIndividualRequests } from "@util/api/participants";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Button,
    Box,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Badge,
} from "@chakra-ui/react";
import { MiniUserProfile } from "@components";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const IndividualRequestsTable = (props: { router: NextRouter; compId: string; toast: IToastOptions }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [invites, setRequests] = useState([]);

    const getRequests = async () => {
        const serverResponse = (await getIndividualRequests(props.compId, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            for (const r of data) {
                switch (r.status.toString()) {
                    case "Received":
                        r.status = "در انتظار پاسخ";
                        r["statusColor"] = "blue";
                        break;
                    case "Denied":
                        r.status = "رد شده";
                        r["statusColor"] = "red";
                        break;
                    case "Accepted":
                        r.status = "پذیرفته شده";
                        r["statusColor"] = "green";
                        break;
                    case "Expired":
                        r.status = "منقضی شده";
                        r["statusColor"] = "orange";
                        break;
                    case "Closed":
                        r.status = "بسته شده";
                        r["statusColor"] = "gray";
                        break;
                    default:
                        r.status = "نامشخص";
                        r["statusColor"] = "gray";
                        break;
                }
            }
            setRequests(data);
        } else {
            props.toast({
                title: "مشکلی در دریافت درخواست‌ها رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        getRequests();
    }, []);

    if (isLoading) {
        return (
            <Box mx="auto" w="min">
                <Spinner my={4} />
            </Box>
        );
    }

    return (
        <Table mt={4} size="md" variant="simple">
            <Thead>
                <Tr>
                    <Th pl={0} pr={1} minW="200px">
                        تیم
                    </Th>
                    <Th pl={0}>سرگروه</Th>
                    <Th pl={0}>وضعیت</Th>
                </Tr>
            </Thead>
            <Tbody>
                {invites.map((requestData) => {
                    return (
                        <Tr
                            id={requestData.team.id}
                            key={requestData.team.id}
                            sx={{
                                td: {
                                    paddingBottom: "12px",
                                    paddingTop: "12px",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                },
                            }}
                        >
                            <Td pl={0} pr={1}>
                                <MiniUserProfile size="sm" fullName={requestData.team.name} imageFilename={requestData.team.image} />
                            </Td>
                            <Td pl={0}>
                                <MiniUserProfile
                                    size="sm"
                                    fullName={requestData.team.creator.full_name}
                                    username={requestData.team.creator.username}
                                    imageFilename={requestData.team.creator.profile_picture}
                                />
                            </Td>
                            <Td pl={0}>
                                <Badge colorScheme={requestData.statusColor} variant="subtle">
                                    {requestData.status}
                                </Badge>
                            </Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

const InviteDecisionConfirmAlert = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    decision: boolean;
    teamName: string;
    onSuccess: () => void;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{props.decision ? "پذیرش" : "رد کردن"} دعوت تیم</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    {props.decision
                        ? `در صورت پذیرش این دعوت، به تیم ${props.teamName} ملحق خواهید شد. آیا ادامه می‌دهید؟`
                        : `شما در حال رد کردن دعوت تیم ${props.teamName} هستید. این امر قابل بازگشت نیست. آیا ادامه می‌دهید؟`}
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
