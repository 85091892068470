import React from "react";
import { motion } from "framer-motion";
import { Text, useColorModeValue, Box } from "@chakra-ui/react";
import { ChevronBackOutline } from "react-ionicons";
import { IMenuBranch } from "@util/interfaces";

const MenuBranch = (props: IMenuBranch) => {
    const [isExpanded, setExpanded] = React.useState(props.isExpanded);
    const arrowRef = React.useRef(null);
    React.useEffect(() => {
        arrowRef.current.style.transform = props.isExpanded ? "rotate(-90deg)" : "none";
    }, []);
    const toggle = () => {
        arrowRef.current.style.transform = isExpanded ? "none" : "rotate(-90deg)";
        setExpanded((current) => !current);
    };

    return (
        <>
            <li className="w-full">
                <Text
                    onClick={toggle}
                    className="px-3 flex items-center justify-start rounded-md cursor-pointer mb-0 "
                    bg={"inherit"}
                    color={useColorModeValue("#000", "#fff")}
                >
                    {React.cloneElement(props.icon, {
                        cssClasses: "ml-2",
                        color: useColorModeValue("#000", "#fff"),
                        width: "18px",
                        height: "18px",
                    })}
                    <span
                        className="flex-grow overflow-hidden whitespace-nowrap"
                        style={{
                            textOverflow: "ellipsis",
                        }}
                    >
                        {props.text}
                    </span>
                    <span
                        ref={arrowRef}
                        className="mx-2"
                        style={{
                            transition: "all .15s ease-out",
                            transformOrigin: "center",
                        }}
                    >
                        <ChevronBackOutline color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />
                    </span>
                </Text>
            </li>
            {
                <Box
                    className={`w-full`}
                    style={{
                        overflow: "hidden",
                        height: isExpanded ? React.Children.toArray(props.children).length * 36 : "0",
                        transition: "height 0.8s cubic-bezier(.25,.61,.29,1)",
                        marginTop: "0",
                        marginBottom: ".5rem",
                    }}
                >
                    {React.Children.map(props.children, (child) => {
                        return (
                            <motion.div
                                animate={isExpanded ? "visible" : "initial"}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        x: 0,
                                    },
                                    hidden: {
                                        opacity: 0,
                                        x: 2,
                                    },
                                }}
                                transition={{
                                    delay:
                                        (isExpanded
                                            ? React.Children.toArray(props.children).indexOf(child)
                                            : React.Children.toArray(props.children).length -
                                              React.Children.toArray(props.children).indexOf(child)) *
                                            0.08 +
                                        0.1,
                                    ease: "easeOut",
                                    duration: 0.1,
                                }}
                                className="w-full"
                            >
                                {React.cloneElement(child, {
                                    style: {
                                        width: "calc(100% - 28px)",
                                        marginRight: "auto",
                                    },
                                })}
                            </motion.div>
                        );
                    })}
                </Box>
            }
        </>
    );
};

export default MenuBranch;
