import { fetchWithRefresh } from "@util/api/base";
import { IServerSideContext } from "@util/interfaces";

export const getAllCompetitions = async (query?: string | string[], ctx?: IServerSideContext) =>
    fetchWithRefresh(`/competition/${query ? "?title=" + query : ""}`, {}, "preferred", ctx);

export const getCompetition = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}`, {}, "preferred", ctx);

export const getMyCompetitions = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition?participated_by_me`, {}, "required", ctx);

export const getParticipantSummaryCompetition = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/participation-summary`, {}, "required", ctx);

export const registerInCompetition = async (path: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/participants`, { method: "POST" }, "required", ctx);

export const getTeamInviteSearchList = async (compId: string, parameters: Record<string, string>, ctx?: IServerSideContext) => {
    let query = "";
    for (const [key, value] of Object.entries(parameters)) {
        query += `&${key}=${value}`;
    }
    query = query.slice(1);
    return await fetchWithRefresh(`/competition/${compId}/invite-search?${query}`, {}, "preferred", ctx);
};

export const searchTeamForRequest = async (compId: string, parameters: Record<string, string | number>, ctx?: IServerSideContext) => {
    let query = "";
    for (const [key, value] of Object.entries(parameters)) {
        query += `&${key}=${value}`;
    }
    query = query.slice(1);
    return await fetchWithRefresh(`/competition/${compId}/participants?${query}`, {}, "preferred", ctx);
};

export const createCompetition = async (reqData: Record<string, string | number | boolean>, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/competition/`,
        { method: "POST", body: JSON.stringify(reqData), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const createPhase = async (competition: string, data: Record<string, string>, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/competition/${competition}/phases`,
        { method: "POST", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const retrieveCompetitionPhases = async (competition: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${competition}/phases`, {}, "preferred", ctx);

export const editCompetitionFormdata = async (competition: string | string[] | number, form: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${competition}`, { method: "PATCH", body: form }, "required", ctx);

export const editCompetitionJson = async (
    competition: string | string[],
    data: Record<string, string | string[] | number>,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/competition/${competition}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const retrieveCompetitionPhase = async (id: string | string[], competition: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${competition}/phases/${id}`, {}, "preferred", ctx);

export const editCompetitionPhaseJson = async (
    id: string | string[] | number,
    competition: string | string[],
    data: Record<string, string | string[]>,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/competition/${competition}/phases/${id}`,
        { method: "PATCH", body: JSON.stringify(data), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const retrieveCompetitionCollaborators = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/collaborators`, {}, "preferred", ctx);

export const addCompetitionCollaborators = async (path: string | string[], username: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/competition/${path}/collaborators`,
        { method: "POST", body: JSON.stringify({ user: username }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const removeCompetitionCollaborators = async (path: string | string[], id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/collaborators/${id}`, { method: "DELETE" }, "required", ctx);

export const publishCompetitionRequest = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/publish`, { method: "POST" }, "required", ctx);

export const retrieveCompetitionPublishStatus = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/publish`, {}, "required", ctx);

export const retrieveCompetitionPreviousPublishList = async (path: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/publish_requests`, {}, "required", ctx);

export const retrieveCompetitionPreviousPublishSingle = async (path: string | string[], id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/competition/${path}/publish_requests/${id}`, {}, "required", ctx);

export const getCompParticipants = async (
    competition: string | string[] | number,
    range?: { start: number; end: number },
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/competition/${competition}/participants${range ? `?start=${range.start}&end=${range.end}` : ""}`,
        {},
        "required",
        ctx,
    );
