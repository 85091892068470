import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const getParticipant = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}`, {}, "preferred", ctx);

export const getParticipantSummary = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/summary`, {}, "preferred", ctx);

export const getTeamInvites = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/team/invites`, {}, "preferred", ctx);

export const getInvite = async (inviteId: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/invites/${inviteId}`, {}, "preferred", ctx);

export const getIndividualInvites = async (compId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/invites?competition_id=${compId}`, {}, "preferred", ctx);

export const getIndividualRequests = async (compId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/joins?competition_id=${compId}`, {}, "preferred", ctx);

export const createJoin = async (inviteId: number, decision: boolean, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/account/invites/${inviteId}`,
        {
            method: "POST",
            body: JSON.stringify({ decision }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const createRequestToJoin = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/join`, { method: "POST" }, "required", ctx);

export const leaveTeam = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/team/leave`, { method: "POST" }, "required", ctx);

export const kickMember = async (participantId: string, userId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/participant/${participantId}/team/kick`,
        { method: "POST", body: JSON.stringify({ user_id: userId }), headers: { "content-type": "application/json" } },
        "required",
        ctx,
    );

export const deleteTeam = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/team`, { method: "DELETE" }, "required", ctx);

export const createInvite = async (participantId: string, username: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/participant/${participantId}/team/invites`,
        { method: "POST", body: JSON.stringify({ user: username }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const getTeamRequests = async (participantId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/team/joins`, {}, "preferred", ctx);

export const actForRequest = async (participantId: string, requestId: string, decision: boolean, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/participant/${participantId}/team/joins/${requestId}`,
        {
            method: "POST",
            body: JSON.stringify({ decision }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const createTeam = async (participantId: string, name: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/participant/${participantId}/team`,
        { method: "POST", body: JSON.stringify({ name }), headers: { "content-type": "application/json" } },
        "preferred",
        ctx,
    );

export const updateTeamInfo = async (participantId: string, formData: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/participant/${participantId}/team`, { method: "PATCH", body: formData }, "required", ctx);
