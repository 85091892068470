import React from "react";
import { Box, Heading, Text, useColorModeValue, Link as ChakraLink } from "@chakra-ui/react";
import { CustomAvatar } from "@components";
import Style from "./competitionCardMinimal.module.css";
import { BulbOutline } from "react-ionicons";
import Link from "next/link";
import { getImageURL } from "@util/mediaLoader";

interface CardInfo {
    competition_id: string;
    competition_title: string;
    competition_path: string;
    name: string;
    individual: boolean;
    number_of_submissions_by_user: number;
    competition_date_published: string;
    competition_thumbnail: string;
    competition_status: string;
}
interface HostedCardInfo {
    path: string;
    title: string;
    subtitle: string;
    thumbnail: string;
}
export const CompetitionParticipatedCardMinimal = (props: { cardInfo: CardInfo; isMyAccount: boolean }) => {
    return (
        <Link href={`/c/${props.cardInfo.competition_path}`} passHref>
            <ChakraLink _hover={{ textDecoration: "none" }}>
                <Box
                    className={`px-3 pb-2 py-3 flex flex-row rounded-lg border-r-4 mb-4 w-full ${Style.competition_card_wrapper}`}
                    borderColor={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400")}
                >
                    <div
                        style={{
                            flex: "0 0 50px",
                        }}
                    >
                        <CustomAvatar
                            noBorder={props.cardInfo.competition_thumbnail !== null}
                            src={`${props.cardInfo.competition_thumbnail}`}
                            placeholder={<BulbOutline color={useColorModeValue("black", "white")} />}
                            showPlaceholder={props.cardInfo.competition_thumbnail === null}
                        />
                    </div>
                    <div
                        className="pr-2 text-right"
                        style={{
                            flex: "0 1 calc(100% - .75rem)",
                            width: "calc(100% - 0.75rem - 50px)",
                        }}
                    >
                        <div className={`w-full flex flex-row ${Style.main_status_container}`}>
                            <div className={`flex flex-col w-max overflow-x-auto ${Style.main_title_container}`}>
                                <Link href={`/c/${props.cardInfo.competition_path}`}>
                                    <h3
                                        className="truncate font-semibold w-min"
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        {props.cardInfo.competition_title}
                                    </h3>
                                </Link>
                                <div className="flex items-center">
                                    <span
                                        className="mt-1 truncate"
                                        style={{
                                            color: "#898990",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {props.cardInfo.competition_date_published === ""
                                            ? "---"
                                            : props.cardInfo.competition_date_published}
                                    </span>
                                    <div className="inline-block w-1 h-1 rounded-full bg-gray-300 mx-2" />
                                    <span
                                        className="mt-1 truncate"
                                        style={{
                                            color: "#898990",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {props.cardInfo.number_of_submissions_by_user} ارسال ثبت شده توسط{" "}
                                        {props.isMyAccount ? "شما" : "این کاربر"}{" "}
                                    </span>
                                    {!props.cardInfo.individual && (
                                        <>
                                            {" "}
                                            <div className="inline-block w-1 h-1 rounded-full bg-gray-300 mx-2" />
                                            <span
                                                className="mt-1 truncate"
                                                style={{
                                                    color: "#898990",
                                                    fontSize: "13px",
                                                }}
                                            >
                                                {props.cardInfo.name}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <span
                                className={`py-1 px-2 rounded mr-auto text-white bg-gray-800 self-start text-center ${Style.status_wrapper} `}
                            >
                                {props.cardInfo.competition_status ? props.cardInfo.competition_status : "خاتمه یافته"}
                            </span>
                        </div>
                    </div>
                </Box>
            </ChakraLink>
        </Link>
    );
};
export const CompetitionHostedCardMinimal = (props: { cardInfo: HostedCardInfo }) => {
    return (
        <Link href={`/c/${props.cardInfo.path}`} passHref>
            <ChakraLink _hover={{ textDecoration: "none" }}>
                <Box
                    className={`px-3 pb-2 py-3 flex flex-row rounded-lg border-r-4 mb-4 w-full ${Style.competition_card_wrapper}`}
                    borderColor={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400")}
                >
                    <div
                        style={{
                            flex: "0 0 50px",
                        }}
                    >
                        <CustomAvatar
                            noBorder={props.cardInfo.thumbnail !== null}
                            src={getImageURL(props.cardInfo.thumbnail)}
                            placeholder={<BulbOutline color={useColorModeValue("black", "white")} />}
                            showPlaceholder={props.cardInfo.thumbnail === null}
                        />
                    </div>
                    <div
                        className="pr-2 text-right"
                        style={{
                            flex: "0 1 calc(100% - .75rem)",
                            width: "calc(100% - 0.75rem - 50px)",
                        }}
                    >
                        <div className={`flex flex-col w-max overflow-x-auto ${Style.main_title_container}`}>
                            <Heading isTruncated w="min" fontWeight={"semibold"} size="sm">
                                {props.cardInfo.title}
                            </Heading>
                            <div className="flex items-center">
                                <Text>{props.cardInfo.subtitle}</Text>
                            </div>
                        </div>
                    </div>
                </Box>
            </ChakraLink>
        </Link>
    );
};
