import React, { useEffect, useState } from "react";
import {
    Badge,
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    PinInput,
    PinInputField,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { checkVerificationCode, sendVerificationCode } from "@util/api/profile";
import { CheckCircle, XCircle } from "react-feather";
import styles from "./validationInfo.module.css";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const ValidationInfo = (props: {
    email: string;
    phone_number: { is_verified: boolean; phone_number: string };
    is_verified: boolean;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    return (
        <VStack spacing={6} alignItems="flex-start" borderWidth={1} borderRadius={"lg"} p={4} className={styles.container} w="full">
            <Heading fontWeight={700} size="md">
                احراز هویت
            </Heading>
            <FormControl>
                <FormLabel fontSize={"14px"}>پست الکترونیکی</FormLabel>
                <Input dir="ltr" value={props.email} variant="filled" type="email" id="edit-email" isDisabled fontFamily={"sans-serif"} />
            </FormControl>
            <PhoneFormControl phone_number={props.phone_number} toast={props.toast} router={props.router} />
            <FormControl>
                <div className="flex flex-row w-full justify-between mb-4">
                    <FormLabel fontSize={"14px"}>تاییدیه برگزاری مسابقه یا کلاس درس</FormLabel>
                    <Badge colorScheme={props.is_verified ? "green" : "red"} px={2} py={1} textTransform={"none"}>
                        <div className="flex flex-row items-center">
                            <Icon as={props.is_verified ? CheckCircle : XCircle} w={4} h={4} ml={2} />
                            {props.is_verified ? "تایید شده" : "تایید نشده"}
                        </div>
                    </Badge>
                </div>
                {!props.is_verified && (
                    <Text>
                        برگزاری مسابقات و کلاس‌های درس در پلتفرم روبواپیکس نیازمند تاییدیه از طرف کارشناسان روبواپیکس است. برای این کار شما
                        باید با استفاده از راه‌های ارتباطی درج شده در صفحه "تماس با ما"، درخواست خود را ثبت و تاییدیه را دریافت کنید.
                    </Text>
                )}
            </FormControl>
        </VStack>
    );
};

const PhoneFormControl = (props: {
    phone_number: { is_verified: boolean; phone_number: string } | null;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [verificationStatus, setVerificationStatus] = useState<"verified" | "not-verified" | "code-sent">(
        props.phone_number?.is_verified ? "verified" : "not-verified",
    );
    const [verificationInputVisible, setVerificationInputVisible] = useState(false);
    const [verificationLoading, setVerificationLoading] = useState(false);
    const [verificationDisabled, setVerificationDisabled] = useState(false);
    const [verificationSessionToken, setVerificationSessionToken] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(props.phone_number?.phone_number ?? "");
    const [countdown, setCountdown] = useState(180);

    useEffect(() => {
        if (verificationStatus === "code-sent") {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => {
                clearInterval(interval);
                if (countdown === 0) {
                    setCountdown(180);
                    setVerificationStatus("not-verified");
                    setVerificationInputVisible(false);
                }
            };
        }
    }, [countdown, verificationStatus]);
    const errorParser = (data: { non_field_errors?: string[] }) => {
        let msg: string = undefined;
        const status: "info" | "error" | "warning" | "success" = "error";
        switch (data.non_field_errors[0]) {
            case "Security code is not valid":
                msg = "کد امنیتی وارد شده نادرست است.";
                break;
            default:
                break;
        }
        props.toast({
            duration: 3000,
            status: status,
            description: msg ?? "مشکلی به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
            isClosable: true,
            variant: "subtle",
        });
    };
    const sendPhoneVerificationCode = async () => {
        setVerificationLoading(true);

        const response = (await sendVerificationCode(phoneNumber, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (response.ok) {
            const { session_token } = await response.json();
            setVerificationInputVisible(true);
            setVerificationStatus("code-sent");
            setVerificationSessionToken(session_token);
        } else {
            props.toast({
                description: "مشکلی  در ارسال کد فعالسازی به وجود آمده؛ لطفاً دوباره تلاش کنید.",

                status: "error",
                duration: 9000,
                isClosable: true,
                variant: "subtle",
            });
        }

        setVerificationLoading(false);
    };
    const checkPhoneNumberValidity = (number: string): boolean => {
        setPhoneNumber(number);
        if (number.match(/^(0|\+[0-9]{1,4})[0-9]{10}$/g) !== null) {
            return true;
        } else {
            props.toast({
                description: "شماره تلفن وارد شده معتبر نیست.",
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
            return false;
        }
    };
    const checkPhonePin = async (pin: string) => {
        setVerificationDisabled(true);
        setVerificationLoading(true);

        const response = (await checkVerificationCode(phoneNumber, pin, verificationSessionToken, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (response.ok) {
            setVerificationStatus("verified");
            setVerificationInputVisible(false);
        } else {
            errorParser(await response.json());
        }

        setVerificationDisabled(false);
        setVerificationLoading(false);
    };
    return (
        <FormControl>
            <FormLabel fontSize={"14px"}>تلفن همراه</FormLabel>
            <InputGroup>
                <InputLeftElement
                    w="fit-content"
                    children={
                        <Button
                            mx={1}
                            h={8}
                            size="sm"
                            onClick={() => {
                                if (checkPhoneNumberValidity(phoneNumber)) {
                                    sendPhoneVerificationCode();
                                }
                            }}
                            isDisabled={verificationStatus === "code-sent" || verificationStatus === "verified" || phoneNumber.length === 0}
                            isLoading={verificationLoading}
                            colorScheme={
                                verificationStatus === "code-sent" ? "blue" : verificationStatus === "verified" ? "RoboEpics.green" : "gray"
                            }
                        >
                            {verificationStatus === "code-sent"
                                ? `ارسال مجدد (${
                                      Math.floor(countdown / 60) < 10 ? "0" + Math.floor(countdown / 60) : Math.floor(countdown / 60)
                                  }:${countdown % 60 < 10 ? "0" + (countdown % 60) : countdown % 60})`
                                : verificationStatus === "verified"
                                ? "تایید شده"
                                : "ارسال کد تایید"}
                        </Button>
                    }
                />
                <Input
                    value={phoneNumber}
                    placeholder={"09123456789 یا +989123456789"}
                    dir="ltr"
                    pl={4}
                    type="text"
                    pr="5rem"
                    maxLength={phoneNumber.startsWith("+") ? 20 : 11}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    isDisabled={verificationStatus === "code-sent" || verificationStatus === "verified" || props.phone_number?.is_verified}
                />
            </InputGroup>

            <Modal
                isOpen={verificationInputVisible}
                isCentered
                onClose={() => {
                    setVerificationInputVisible(true);
                }}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>احراز هویت تلفن همراه</ModalHeader>
                    <ModalBody>
                        <Text>کد تایید به شماره تلفن همراهی که وارد کردید ارسال شد.</Text>
                        <HStack spacing={2} justifyContent="center" dir="ltr">
                            <PinInput
                                otp
                                onComplete={(value) => {
                                    checkPhonePin(value);
                                }}
                                focusBorderColor={useColorModeValue("RoboEpics.azure.500", "RoboEpics.gold.500")}
                                size="md"
                                placeholder="_"
                                type="alphanumeric"
                                isDisabled={verificationDisabled || verificationLoading}
                            >
                                <PinInputField></PinInputField>
                                <PinInputField></PinInputField>
                                <PinInputField></PinInputField>
                                <PinInputField></PinInputField>
                                <PinInputField></PinInputField>
                                <PinInputField></PinInputField>
                            </PinInput>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </FormControl>
    );
};
