import React, { useEffect, useRef, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Fade,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { IAnnouncementsData, IAnnouncementData, IToastOptions } from "@util/interfaces";

import { AnnouncementCard } from "../announcement/card/announcementCard";
import {
    createCompetitionAnnouncement,
    deleteCompetitionAnnouncement,
    editCompetitionAnnouncement,
    retrieveCompetitionAnnouncements,
} from "@util/api/announcements";
import { NextRouter } from "next/router";

export const OwnerAnnouncements = (props: { competition: string; toast: IToastOptions; router: NextRouter }) => {
    const [announcements, setAnnouncements] = useState<IAnnouncementsData>({
        results: [],
        total: 0,
    });
    const [changeConfirmed, setChangeConfirmed] = useState(false);
    const [isError, setIsError] = useState(false);

    const getData = async () => {
        const serverResponse = (await retrieveCompetitionAnnouncements(props.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const resolved = await serverResponse.json();
            setAnnouncements(resolved);
        } else {
            if (serverResponse.status === 400) {
                props.toast({
                    title: "مشکلی در دریافت اعلان‌ها رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            } else {
                setIsError(true);
            }
            setAnnouncements({ results: [], total: 0 });
        }
    };
    useEffect(() => {
        if (changeConfirmed) getData();
        setChangeConfirmed(false);
    }, [changeConfirmed]);
    useEffect(() => {
        getData();
    }, []);
    return (
        <Box className={`w-full h-full`}>
            <Tabs colorScheme={useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold")}>
                <TabList>
                    <Tab marginRight="1rem">اعلان جدید</Tab>
                    <Tab>اعلان‌های مسابقه</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <CreateAnnouncement
                            competition={props.competition}
                            setChangeConfirmed={setChangeConfirmed}
                            toast={props.toast}
                            router={props.router}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AllAnnouncements
                            competition={props.competition}
                            announcements={announcements}
                            setChangeConfirmed={setChangeConfirmed}
                            toast={props.toast}
                            router={props.router}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Fade in={isError}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        </Box>
    );
};
const CreateAnnouncement = (props: {
    competition: string;
    setChangeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [values, setValues] = useState({
        title: "",
        text: "",
    });

    const handleChange = (props: string) => (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setValues((current) => {
            return { ...current, [props]: event.target.value };
        });
    };
    const _createAnnouncement = async () => {
        setLoading(true);

        const serverResponse = (await createCompetitionAnnouncement(props.competition, values.title, values.text, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                title: "ساخت اعلان جدید",
                description: "اعلان جدید با موفقیت ساخته و منتشر شد!",
                status: "success",
                duration: 3000,
                variant: "subtle",
            });
            setValues({ text: "", title: "" });
            props.setChangeConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در ساخت اعلان رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setLoading(false);
    };
    useEffect(() => {
        if (values.title && values.text) setDisabled(false);
        if (!values.text || !values.title) setDisabled(true);
    }, [values]);
    return (
        <Box className={`flex flex-col w-full h-full`}>
            <VStack spacing={6}>
                <FormControl>
                    <FormLabel fontSize="13px">عنوان</FormLabel>
                    <Input value={values.title} onChange={handleChange("title")} id="new-announcement-title" variant="filled" />
                </FormControl>
                <FormControl>
                    <FormLabel fontSize="13px">محتویات</FormLabel>
                    <Textarea
                        value={values.text}
                        onChange={handleChange("text")}
                        id="new-announcement-text"
                        variant="filled"
                        maxLength={2000}
                    />
                    <FormHelperText mb={0}>{`${values.text.length}/2000`}</FormHelperText>
                </FormControl>
                {/* <HStack spacing={6} justifyContent="space-between" w="full">
                    <FormControl>
                        <FormLabel fontSize="13px">میزان اهمیت</FormLabel>
                        <Menu placement="left-start" flip={false}>
                            <MenuButton
                                as={Button}
                                textAlign="inherit"
                                rightIcon={<ChevronLeftIcon />}
                                w={48}
                            >
                                {values.importance
                                    ? values.importance
                                    : "تعیین اهمیت اعلان"}
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue(
                                    "#fff",
                                    "RoboEpics.black.900",
                                )}
                                w="full"
                            >
                                <MenuItem
                                    value="urgent"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="red"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Urgent</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="high"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="darkorange"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>High</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="medium"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="skyblue"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Medium</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="low"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="white"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Low</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value=""
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Close
                                        color="hotpink"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Clear</span>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="13px">زمان انتشار</FormLabel>
                        <RoboEpicsDatePicker
                            type="day"
                            initDayValue={
                                convertServer2DatePicker(
                                    values.releaseDate,
                                    "fa",
                                )?.date
                            }
                            initTimeValue={
                                convertServer2DatePicker(
                                    values.releaseDate,
                                    "fa",
                                )?.time
                            }
                            setPropDate={(day, time, locale) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        releaseDate: convertDatePicker2Json(
                                            day,
                                            time,
                                            locale,
                                        ),
                                    };
                                });
                            }}
                            placeholder="زمان انتشار اعلان"
                            placement="top"
                        />
                    </FormControl>
                </HStack> */}
                {/* <FormControl>
                    <FormLabel fontSize="13px">برچسب‌ها</FormLabel>
                    <Input
                        value={values.tags}
                        onChange={handleChange("tags")}
                        id="new-announcement-tags"
                        variant="filled"
                    />
                </FormControl> */}

                <Button
                    colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                    isLoading={loading}
                    isDisabled={disabled}
                    alignSelf={"flex-end"}
                    onClick={() => {
                        _createAnnouncement();
                    }}
                >
                    ساخت اعلان جدید
                </Button>
            </VStack>
        </Box>
    );
};
const AllAnnouncements = (props: {
    announcements: IAnnouncementsData;
    setChangeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    competition: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [loading, setLoading] = useState(false);

    const [chosenAnnouncement, setChosenAnnouncement] = useState<IAnnouncementData>(null);
    const [deleteAlertDialogVisible, setDeleteAlertDialogVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const cancelRef = useRef();
    useEffect(() => {
        if (editModalVisible || deleteAlertDialogVisible) {
            setViewModalVisible(false);
            setLoading(false);
        }
    }, [editModalVisible, deleteAlertDialogVisible, viewModalVisible]);
    return (
        <Box className="flex flex-row h-full w-3/4">
            {loading ? (
                <Box className="flex flex-col">
                    <Spinner className="self-end" />
                </Box>
            ) : (
                <Box className="w-full">
                    {props.announcements.total ? (
                        <Box className="flex flex-col w-full h-full">
                            {props.announcements.results.map((announcement, index) => {
                                return (
                                    <AnnouncementCard
                                        cardInfo={announcement}
                                        onClick={() => {
                                            setChosenAnnouncement(announcement);
                                        }}
                                        openViewModal={() => {
                                            setViewModalVisible(true);
                                            console.log("view");
                                        }}
                                        openDeleteAlert={() => {
                                            setDeleteAlertDialogVisible(true);
                                        }}
                                        openEditModal={() => {
                                            setEditModalVisible(true);
                                        }}
                                        key={`comp-${props.competition}-announcement-${index}`}
                                        canEdit
                                    />
                                );
                            })}
                        </Box>
                    ) : (
                        <Text>هنوز اعلانی برای مسابقه خود قرار نداده‌اید!</Text>
                    )}
                </Box>
            )}
            <DeleteAlertDialog
                cancelRef={cancelRef}
                isOpen={deleteAlertDialogVisible}
                onClose={() => {
                    setDeleteAlertDialogVisible(false);
                    setChosenAnnouncement(null);
                }}
                id={chosenAnnouncement ? chosenAnnouncement.id : null}
                competition={props.competition}
                setChangeConfirmed={props.setChangeConfirmed}
                toast={props.toast}
                router={props.router}
            />
            {chosenAnnouncement && (
                <EditModal
                    isOpen={editModalVisible}
                    onClose={() => {
                        setEditModalVisible(false);
                        setChosenAnnouncement(null);
                    }}
                    announcement={chosenAnnouncement}
                    competition={props.competition}
                    setChangeConfirmed={props.setChangeConfirmed}
                    toast={props.toast}
                    router={props.router}
                />
            )}
            <ViewModal
                isOpen={viewModalVisible}
                onClose={() => {
                    setViewModalVisible(false);
                    setChosenAnnouncement(null);
                }}
                announcement={chosenAnnouncement}
            />
        </Box>
    );
};
const DeleteAlertDialog = (props: {
    isOpen: boolean;
    onClose: () => void;
    cancelRef: React.MutableRefObject<undefined>;
    id: number;
    competition: string;
    setChangeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        const serverResponse = (await deleteCompetitionAnnouncement(props.competition, props.id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
                description: "اعلان با موفقیت حذف شد!",
                title: "حذف اعلان",
            });
            props.onClose();
            props.setChangeConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در حذف اعلان رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setIsLoading(false);
    };
    return (
        <AlertDialog
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setIsDisabled(false);
                setIsLoading(false);
            }}
            leastDestructiveRef={props.cancelRef}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>حذف اعلان</AlertDialogHeader>
                    <AlertDialogBody>
                        با تایید کردن حذف، این اعلان از صفحه اعلان‌های تمام شرکت‌کنندگان مسابقه حذف خواهد شد و برای انتشار دوباره باید یک
                        اعلان جدید بسازید.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={props.cancelRef} onClick={props.onClose} isDisabled={isLoading || isDisabled}>
                            انصراف
                        </Button>
                        <Button
                            colorScheme={"red"}
                            mr={2}
                            onClick={onConfirm}
                            isDisabled={props.id ? false : true || isDisabled}
                            isLoading={isLoading}
                        >
                            حذف اعلان
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
const EditModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    competition: string;
    setChangeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    announcement: IAnnouncementData;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [values, setValues] = useState({
        text: props.announcement.text,
        title: props.announcement.title,
    });
    useEffect(() => {
        setIsDisabled(values.text === props.announcement.text && values.title === props.announcement.title);
    }, [values]);
    const handleChange = (props: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues((current) => {
            return { ...current, [props]: event.target.value };
        });
    };
    const onConfirm = async () => {
        setIsLoading(true);
        const serverResponse = (await editCompetitionAnnouncement(props.competition, props.announcement.id, values.title, values.text, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "subtle",
                description: "تغییرات شما با موفقیت اعمال شد!",
                title: "ویرایش اعلان",
            });
            props.onClose();
            props.setChangeConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setIsLoading(false);
    };
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                setValues({
                    text: props.announcement.text,
                    title: props.announcement.title,
                });
                setIsLoading(false);
                setIsDisabled(false);
                props.onClose();
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>ویرایش اعلان</ModalHeader>
                <ModalBody>
                    <VStack spacing={6}>
                        <FormControl>
                            <FormLabel fontSize="13px">عنوان</FormLabel>
                            <Input value={values.title} onChange={handleChange("title")} id="edit-announcement-title" variant="filled" />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="13px">محتویات</FormLabel>
                            <Textarea
                                value={values.text}
                                onChange={handleChange("text")}
                                id="edit-announcement-text"
                                variant="filled"
                                maxLength={2000}
                            />
                            <FormHelperText mb={0}>{`${values.text.length}/2000`}</FormHelperText>
                        </FormControl>
                        {/* <HStack spacing={6} justifyContent="space-between" w="full">
                    <FormControl>
                        <FormLabel fontSize="13px">میزان اهمیت</FormLabel>
                        <Menu placement="left-start" flip={false}>
                            <MenuButton
                                as={Button}
                                textAlign="inherit"
                                rightIcon={<ChevronLeftIcon />}
                                w={48}
                            >
                                {values.importance
                                    ? values.importance
                                    : "تعیین اهمیت اعلان"}
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue(
                                    "#fff",
                                    "RoboEpics.black.900",
                                )}
                                w="full"
                            >
                                <MenuItem
                                    value="urgent"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="red"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Urgent</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="high"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="darkorange"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>High</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="medium"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="skyblue"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Medium</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value="low"
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Flag
                                        color="white"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Low</span>
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    value=""
                                    onClick={(e) =>
                                        setValues({
                                            ...values,
                                            importance: e.currentTarget.value,
                                        })
                                    }
                                >
                                    <Close
                                        color="hotpink"
                                        width="18px"
                                        height="18px"
                                        cssClasses="ml-4"
                                    />
                                    <span>Clear</span>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="13px">زمان انتشار</FormLabel>
                        <RoboEpicsDatePicker
                            type="day"
                            initDayValue={
                                convertServer2DatePicker(
                                    values.releaseDate,
                                    "fa",
                                )?.date
                            }
                            initTimeValue={
                                convertServer2DatePicker(
                                    values.releaseDate,
                                    "fa",
                                )?.time
                            }
                            setPropDate={(day, time, locale) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        releaseDate: convertDatePicker2Json(
                                            day,
                                            time,
                                            locale,
                                        ),
                                    };
                                });
                            }}
                            placeholder="زمان انتشار اعلان"
                            placement="top"
                        />
                    </FormControl>
                </HStack> */}
                        {/* <FormControl>
                    <FormLabel fontSize="13px">برچسب‌ها</FormLabel>
                    <Input
                        value={values.tags}
                        onChange={handleChange("tags")}
                        id="new-announcement-tags"
                        variant="filled"
                    />
                </FormControl> */}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onClose} isDisabled={isLoading || isDisabled}>
                        انصراف
                    </Button>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        mr={2}
                        onClick={onConfirm}
                        isDisabled={props.announcement ? false : true || isDisabled}
                        isLoading={isLoading}
                    >
                        اعمال تغییرات
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
const ViewModal = (props: { isOpen: boolean; onClose: () => void; announcement: IAnnouncementData }) => {
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
            size="lg"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>{props.announcement?.title}</ModalHeader>
                <ModalBody>{props.announcement?.text}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
