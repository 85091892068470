import React, { useEffect, useState } from "react";
import { RoboEpicsMarkdown } from "@components";
import {
    Button,
    Box,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalContent,
    Modal,
    Spinner,
    Alert,
    AlertTitle,
    AlertDescription,
    AlertIcon,
    Fade,
    Text,
} from "@chakra-ui/react";
import { retrieveCompetitionAnnouncements } from "@util/api/announcements";
import { AnnouncementCard } from "./card/announcementCard";
import { IAnnouncementData, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const Announcements = (props: { competition: string; toast: IToastOptions; router: NextRouter }) => {
    const [data, setData] = useState<{
        results: Array<IAnnouncementData>;
        total: number;
    }>(null);

    const [current, setCurrent] = useState<IAnnouncementData>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const getData = async () => {
        setLoadingComponent(true);
        const serverResponse = (await retrieveCompetitionAnnouncements(props.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const resolved = await serverResponse.json();
            setData(resolved);
        } else {
            if (serverResponse.status === 400) {
                props.toast({
                    title: "مشکلی در دریافت اعلان‌های مسابقه رخ داد:" + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                    duration: 3000,
                    variant: "subtle",
                });
            } else {
                setErrorLoading(true);
            }
            setData(null);
        }
        setLoadingComponent(false);
    };
    useEffect(() => {
        getData();
    }, []);
    if (loadingComponent) {
        return (
            <Box className="flex flex-row justify-center">
                <Spinner />
            </Box>
        );
    }
    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }
    if (data.results.length === 0) {
        return (
            <Box className="flex flex-row justify-center">
                <Text>اعلانی برای این مسابقه منتشر نشده است.</Text>
            </Box>
        );
    }

    return (
        <>
            <Box className="flex flex-col justify-between">
                {data?.results.map((el) => {
                    return (
                        <AnnouncementCard
                            cardInfo={el}
                            onClick={() => {
                                setCurrent(el);
                            }}
                            openViewModal={() => {
                                setModalOpen(true);
                            }}
                        />
                    );
                })}
            </Box>
            <AnnouncementModal
                title={current.title}
                text={current.text}
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setCurrent(null);
                }}
            />
        </>
    );
};

const AnnouncementModal = (props: { title: string; text: string; isOpen: boolean; onClose: () => void }) => {
    return (
        <Modal blockScrollOnMount={true} isOpen={props.isOpen} onClose={props.onClose} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title ? props.title : ""}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <RoboEpicsMarkdown>{props.text ? props.text.toString() : ""}</RoboEpicsMarkdown>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" variant="ghost" onClick={props.onClose}>
                        بستن
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
