import React from "react";
import { Button, useColorModeValue, Link } from "@chakra-ui/react";
import styles from "./CompSidebar.module.css";
import { IMenuLink } from "@util/interfaces";

const MenuLink = (props: IMenuLink) => {
    return (
        <div
            className={`w-full  ${props.className} ${styles.menu_item_li} ${props.isProblem ? styles.problem_link : ""}`}
            style={props.style}
        >
            {props.isDisabled ? (
                <Button variant="ghost" isDisabled px={3} w="inherit" justifyContent="start" className={`flex items-center rounded-md`}>
                    {props.icon === "dot" ? (
                        <span className={`rounded-full w-1 h-1 flex-shrink-0 ${useColorModeValue("bg-black", "bg-white")} ml-2`}></span>
                    ) : (
                        React.cloneElement(props.icon, {
                            cssClasses: "ml-2 flex-shrink-0",
                            color: useColorModeValue("#000", "#fff"),
                            width: "20px",
                            height: "20px",
                        })
                    )}
                    <span
                        className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                        style={{ textOverflow: "ellipsis" }}
                    >
                        {props.text}
                    </span>
                </Button>
            ) : (
                <Link
                    passhref="true"
                    w="inherit"
                    href={props.link}
                    className={`px-3 flex items-center justify-start rounded-md h-full ${styles.menu_item_link} ${
                        props.isSelected && "shadow-md"
                    }`}
                    bg={props.isSelected ? useColorModeValue("RoboEpics.gold.500", "RoboEpics.gold.400") : "inherit"}
                    color={props.isSelected ? useColorModeValue("#000", "#000") : useColorModeValue("#000", "#fff")}
                    fontWeight={props.isSelected ? "600" : "500"}
                >
                    {props.icon === "dot" ? (
                        <span className={`rounded-full w-1 h-1 flex-shrink-0 ${props.isSelected ? "bg-white" : "bg-gray-400"} ml-2`}></span>
                    ) : (
                        React.cloneElement(props.icon, {
                            cssClasses: "ml-2 flex-shrink-0",
                            color: props.isSelected ? useColorModeValue("#000", "#000") : useColorModeValue("#000", "#fff"),
                            width: "20px",
                            height: "20px",
                        })
                    )}
                    <span
                        className={`whitespace-nowrap overflow-hidden ${styles.menu_item_link_text}`}
                        style={{ textOverflow: "ellipsis" }}
                    >
                        {props.text}
                    </span>
                </Link>
            )}
        </div>
    );
};

export default MenuLink;
