import React, { useEffect, useState } from "react";
import { RoboEpicsMarkdown } from "@components";
import { Box, Alert, AlertIcon, AlertTitle, AlertDescription, Spinner } from "@chakra-ui/react";

import JsxParser from "react-jsx-parser";
import TeX from "@matejmazur/react-katex";
import Notebook from "@util/renderers/notebook";

import { OwnerView } from "./ownerView";
import { ClientView } from "./clientView";
import { retrieveProblemDataset } from "@util/api/problems";
import { IDatasetBrief, IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const DatasetOverview = (props: {
    problem: string;
    datasets: Array<IDatasetBrief>;
    isOwner: boolean;
    isCollaborator: boolean;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [renderData, setRenderData] = useState(props.datasets);
    const [componentLoading, setComponentLoading] = useState(false);
    const [errorLoading, setErrorLoding] = useState(false);
    const [errorDetails, setErrorDetails] = useState({ status: "", code: 1, details: "" });
    const [addConfirmed, setAddConfirmed] = useState(false);
    const [editConfirmed, setEditConfirmed] = useState(false);
    const getContentView = (type: 10 | 20 | 30 | 40 | string, content: string) => {
        //10: raw_text , 20: markdown , 30: html , 40: notebook
        switch (type) {
            case 10:
                return <span>{content}</span>;
            case 20:
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case 30:
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case 40:
                return <Notebook notebook={content} />;
            case "Raw Text":
                return <span>{content}</span>;
            case "Markdown":
                return <RoboEpicsMarkdown>{content.toString()}</RoboEpicsMarkdown>;
            case "HTML":
                return <JsxParser autoCloseVoidElements jsx={content} components={{ TeX }} />;
            case "Jupyter Notebook":
                return <Notebook notebook={content} />;
        }
    };
    const onReload = async () => {
        setComponentLoading(true);
        const serverResponse = (await retrieveProblemDataset(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const newRenderData = await serverResponse.json();
            setRenderData(newRenderData);
            setErrorLoding(false);
        } else {
            setErrorLoding(true);
            setErrorDetails({
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }
        setEditConfirmed(false);
        setAddConfirmed(false);
        setComponentLoading(false);
    };
    useEffect(() => {
        if (editConfirmed) {
            onReload();
        }
    }, [editConfirmed]);
    useEffect(() => {
        if (addConfirmed) {
            onReload();
        }
    }, [addConfirmed]);
    useEffect(() => {
        onReload();
    }, []);
    if (componentLoading) {
        return (
            <Box className="flex flex-row justify-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }
    if (errorLoading) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorDetails.status ? `${errorDetails.code} - ${errorDetails.status}` : "مشکلی در دریافت داده از سرور رخ داد"}
                </AlertTitle>
                {errorDetails.details && <AlertDescription fontSize="sm">{errorDetails.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    if (!props.datasets && !(props.isOwner || props.isCollaborator)) {
        return (
            <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    شما اجازه دسترسی به این دیتاست را ندارید
                </AlertTitle>
            </Alert>
        );
    }
    if (props.isOwner || props.isCollaborator) {
        return (
            <OwnerView
                datasets={renderData}
                setError={setErrorLoding}
                setAddConfirmed={setAddConfirmed}
                setEditConfirmed={setEditConfirmed}
                getContentView={getContentView}
                key={".ownerview"}
                toast={props.toast}
                router={props.router}
                problem={props.problem}
            />
        );
    }
    return <ClientView datasets={renderData} getContentView={getContentView} key={".clientview"} />;
};
