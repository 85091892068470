import React from "react";
import { Text, useColorModeValue, Box, Tooltip } from "@chakra-ui/react";
import styles from "./CompSidebar.module.css";

interface MetadataProps {
    icon: React.ReactElement;
    text: string | number | null;
    tooltip?: string | null;
}

const MetadataBlock = (props: MetadataProps) => {
    return props.text?.toString().length !== 0 ? (
        <Box className="pr-2 flex flex-row justify-start items-center mb-0">
            <Tooltip label={props.tooltip} aria-label={props.tooltip} closeDelay={100} placement="bottom">
                <Box>
                    {React.cloneElement(props.icon, {
                        cssClasses: "ml-1",
                        width: "16px",
                        height: "16px",
                        color: useColorModeValue("#000", "#fff"),
                    })}
                </Box>
            </Tooltip>
            <Text className="m-0" fontSize="13px" colorScheme="gray">
                {props.text}
            </Text>
        </Box>
    ) : (
        <></>
    );
};
export default MetadataBlock;
