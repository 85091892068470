// eslint-disable-next-line
// @ts-nocheck
import { CloseIcon } from "@chakra-ui/icons";
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorMode,
    useColorModeValue,
    ModalCloseButton,
    useTheme,
    Heading,
    IconButton,
    ModalBody,
    Collapse,
    Alert,
    AlertDescription,
    AlertIcon,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";

import { getImageURL } from "@util/mediaLoader";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Style from "./create.module.css";

export const CreateModal = (props: { isOpen; onSuccess; isLoading; onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [teamName, setTeamName] = useState("");
    const theme = useTheme();
    const { colorMode } = useColorMode();
    const bg = useColorModeValue("RoboEpics.black.default", "RoboEpics.gold.400");
    const btnTxt = useColorModeValue("#fff", "RoboEpics.black.default");
    const [alertProps, setAlertProps] = useState({
        open: false,
        message: "این یک آلرت است",
        status: "info",
    });
    const checkMimeType = (file) => {
        const types = ["image/jpeg", "image/png", "image/svg+xml", "image/tiff", "image/webp"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        return false;
    };
    const onDrop = useCallback((acceptedFile) => {
        const file = acceptedFile?.[0];
        if (!file || checkMimeType(file)) {
            setAlertProps({
                open: true,
                message: "فرمت فایل انتخاب شده نادرست است.",
                status: "error",
            });
            setUploadFile(null);
            return;
        }
        setIsLoading(true);

        try {
            setUploadFile(file);
            const reader = new FileReader();
            const el = document.getElementById("imagePreview") as HTMLImageElement;
            reader.onload = (e) => {
                el.src = e.target.result.toString();
            };
            reader.readAsDataURL(file);
        } catch (e) {
            setIsLoading(false);
        }

        setIsLoading(false);
    }, []);
    const { getInputProps, getRootProps } = useDropzone({
        onDrop,
    });
    const [isGreaterThan769px] = useMediaQuery("(min-width:769px)");

    return (
        <>
            <Modal closeOnOverlayClick isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent bg={useColorModeValue("#fff", "RoboEpics.black.900")} className={`${Style.modal_content}`}>
                    <ModalHeader>
                        <Heading size="md">تشکیل تیم</Heading>
                        {/* <div className="flex flex-row justify-between items-baseline">
                            
                        </div> */}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel mb={0} mr={1}>
                                نام تیم:
                            </FormLabel>
                            <Input
                                className="my-3"
                                type="text"
                                variant="filled"
                                // placeholder="تیمتو با چه لقبی صدا بزنیم سلطان؟"
                                value={teamName}
                                onChange={(e) => {
                                    setTeamName(e.target.value);
                                }}
                                id="team-name-input"
                                maxLength="30"
                            />
                        </FormControl>
                        <div className="flex flex-row-reverse justify-between items-center mx-auto my-2">
                            <Button
                                isDisabled={!teamName}
                                _hover={{
                                    background: isLoading
                                        ? theme.colors.RoboEpics.black["300"]
                                        : colorMode === "dark"
                                        ? theme.colors.RoboEpics.gold["500"]
                                        : theme.colors.RoboEpics.black["900"],
                                }}
                                bg={bg}
                                m={1}
                                px={6}
                                color={btnTxt}
                                isLoading={props.isLoading}
                                onClick={() => props.onSuccess(teamName)}
                            >
                                ایجاد تیم
                            </Button>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
