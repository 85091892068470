import React, { useState } from "react";
import {
    Box,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    VStack,
} from "@chakra-ui/react";

import Style from "./settings.module.css";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

import { NextRouter } from "next/router";
import { SampleSubmissionFile } from "./sampleSubmissionFile";
import { EvaluationFile } from "./evaluationFile";
import { EvaluationScoringMetrics } from "./evaluationScoringMetrics";
import { IToastOptions } from "@util/interfaces";

export const SimpleEvaluationSettings = (props: {
    problemCodeSet: Array<{ gitlab_repo_url: string }>;
    evaluationFileUrl: string | null;
    sampleSubmissionFileUrl: string | null;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    problemPath: string;
    router: NextRouter;
    toast: IToastOptions;
}) => {
    const [sampleSubmissionFile, setSampleSubmissionFile] = useState(null);
    const [evaluationFile, setEvaluationFile] = useState(null);

    // const [loading, setLoading] = useState(false);
    // const [disabled, setDisabled] = useState(true);

    // const onReset = () => {
    //     setSampleSubmissionFile(null);
    //     setEvaluationFile(null);
    // };

    return (
        <Box className={`flex flex-row justify-center px-8 ${Style.eval_form_container}`}>
            <VStack spacing={6} w="full">
                <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Sample Submission File Upload
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                    doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus
                                    suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <SampleSubmissionFile
                        url={props.sampleSubmissionFileUrl}
                        file={sampleSubmissionFile}
                        setFile={setSampleSubmissionFile}
                        path={props.problemPath}
                        router={props.router}
                        toast={props.toast}
                    />
                </FormControl>
                <Divider />
                <FormControl>
                    <FormLabel fontSize="16px" fontWeight="bold" alignItems="center">
                        Evaluation File Upload
                        <Popover placement="left">
                            <PopoverTrigger>
                                <IconButton
                                    icon={<QuestionOutlineIcon boxSize="16px" transform={"rotateY(180deg)"} />}
                                    bg="transparent"
                                    variant="ghost"
                                    aria-label="question-repo"
                                    size="xs"
                                    mr={2}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi molestias officia labore inventore
                                    doloremque exercitationem impedit at mollitia rerum! Animi placeat voluptatum fuga nulla atque doloribus
                                    suscipit hic delectus excepturi.
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </FormLabel>
                    <VStack spacing={6}>
                        <EvaluationFile
                            url={props.evaluationFileUrl}
                            setFile={setEvaluationFile}
                            file={evaluationFile}
                            path={props.problemPath}
                            router={props.router}
                            toast={props.toast}
                        />
                        <EvaluationScoringMetrics />
                    </VStack>
                </FormControl>
                {/* <Box className={`flex flex-row justify-end w-full`}>
                    <Button
                        isDisabled={loading || disabled}
                        ml={4}
                        colorScheme={"red"}
                        onClick={() => {
                            onReset();
                        }}
                        size="sm"
                    >
                        بازنشانی
                    </Button>
                    <Button
                        isDisabled={disabled}
                        isLoading={loading}
                        size="sm"
                        colorScheme={useColorModeValue(
                            "RoboEpics.dark",
                            "RoboEpics.gold",
                        )}
                        bg={useColorModeValue(
                            "RoboEpics.dark.500",
                            "RoboEpics.gold.500",
                        )}
                        onClick={() => {
                            // onConfirm();
                        }}
                    >
                        ذخیره و اعمال تغییرات
                    </Button>
                </Box> */}
            </VStack>
        </Box>
    );
};
