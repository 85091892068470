import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { getTeamInvites, createInvite } from "@util/api/participants";
import { getTeamInviteSearchList } from "@util/api/competitions";
import {
    AlertDialogOverlay,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogBody,
    Button,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialog,
    Input,
    Box,
    Table,
    Tr,
    Th,
    Td,
    Tbody,
    Text,
    InputRightElement,
    Spinner,
    InputGroup,
    IconButton,
    Badge,
    Thead,
    useColorModeValue,
    InputLeftElement,
} from "@chakra-ui/react";
import { MiniUserProfile } from "@components/miniUserProfile";
import { X } from "react-feather";
import { SearchIcon } from "@chakra-ui/icons";
import { NextRouter } from "next/router";
import { IToastOptions } from "@util/interfaces";

const InviteView = React.forwardRef(
    (
        props: {
            participantId: string;
            competition: string;
            canInvite: boolean;
            toast: IToastOptions;
            router: NextRouter;
        },
        ref,
    ) => {
        const inputDelayTimer = useRef(null);
        const searchInputEl = useRef(null);
        const [invites, setInvites] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [modalIsLoading, setModalIsLoading] = useState(false);
        const [isSearching, setIsSearching] = useState(false);
        const [searchResult, setSearchResult] = useState([]);
        const [showSearchResult, setShowSearchResult] = useState(false);
        const [searchInput, setSearchInput] = useState("");
        const [showInviteDialog, setShowInviteDialog] = useState(false);
        const [selectedUser, setSelectedUser] = useState({
            username: null,
            fullname: null,
        });
        useImperativeHandle(ref, () => ({
            refresh() {
                setSearchInput("");
                setSearchResult([]);
                getInvites();
            },
        }));

        const inviteUser = async () => {
            setModalIsLoading(true);

            const serverResponse = (await createInvite(props.participantId, selectedUser.username, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                setShowInviteDialog(false);
                props.toast({
                    description: "ارسال دعوت با موفقیت انجام شد.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setIsLoading(true);
                await getInvites();
                setIsLoading(false);
            } else {
                props.toast({
                    title: "مشکلی در ارسال دعوت رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    isClosable: true,
                    duration: 5000,
                    variant: "subtle",
                    status: "error",
                    description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
                });
            }

            setModalIsLoading(false);
        };

        const search = async (input: string) => {
            setIsSearching(true);

            const serverResponse = (await getTeamInviteSearchList(
                props.competition.toString(),
                { full_name: input },
                { toast: props.toast, requesterPath: props.router.asPath },
            )) as Response;
            if (serverResponse.ok) {
                const sr = await serverResponse.json();
                setSearchResult(sr);
                setShowSearchResult(true);
            } else {
                setSearchResult([]);
            }

            setIsSearching(false);
        };

        const onSearchInputChange = async (input: string) => {
            setSearchInput(input);
            setIsSearching(false);
            setShowSearchResult(false);
            clearTimeout(inputDelayTimer.current);
            if (input.length) {
                inputDelayTimer.current = setTimeout(async () => {
                    search(input);
                }, 500);
            } else {
                setSearchResult([]);
                setShowSearchResult(false);
            }
        };

        const getInvites = async () => {
            setIsLoading(true);

            const serverResponse = (await getTeamInvites(props.participantId, {
                toast: props.toast,
                requesterPath: props.router.asPath,
            })) as Response;
            if (serverResponse.ok) {
                const data = await serverResponse.json();
                for (const r of data) {
                    switch (r.status) {
                        case "Received":
                            r.status = "در انتظار پاسخ";
                            r["statusColor"] = "blue";
                            break;
                        case "Denied":
                            r.status = "رد شده";
                            r["statusColor"] = "red";
                            break;
                        case "Accepted":
                            r.status = "پذیرفته شده";
                            r["statusColor"] = "green";
                            break;
                        case "Expired":
                            r.status = "منقضی شده";
                            r["statusColor"] = "orange";
                            break;
                        case "Closed":
                            r.status = "بسته شده";
                            r["statusColor"] = "gray";
                            break;
                        default:
                            r.status = "نامشخص";
                            r["statusColor"] = "gray";
                            break;
                    }
                }
                setInvites(data.reverse());
            }

            setIsLoading(false);
        };

        useEffect(() => {
            getInvites();
        }, []);

        if (isLoading) {
            return (
                <Box mx="auto" w="min">
                    <Spinner my={4} />
                </Box>
            );
        }

        return (
            <Box minHeight="400px">
                <Box position="relative">
                    <InputGroup
                        sx={{
                            boxShadow: showSearchResult ? "0px -5px 10px -5px rgba(0,0,0,0.2)" : "none",
                        }}
                    >
                        <Input
                            ref={searchInputEl}
                            size="sm"
                            placeholder="جستجو بر اساس نام کامل..."
                            onChange={(e) => onSearchInputChange(e.target.value)}
                            value={searchInput}
                            isDisabled={!props.canInvite}
                            // onFocus={() =>
                            //     setShowSearchResult(searchResult.length !== 0)
                            // }
                        />
                        <InputLeftElement
                            sx={{
                                height: "32px",
                                width: "32px",
                                paddingRight: "10px",
                            }}
                        >
                            <IconButton
                                // isDisabled={!teamName}
                                // isLoading={isLoading}
                                bg={"transparent"}
                                variant="unstyled"
                                icon={<SearchIcon color="gray" />}
                                aria-label="search-teams"
                                pointerEvents="none"
                                height="32px"
                                width="32px"
                            />
                        </InputLeftElement>
                        <InputRightElement
                            sx={{
                                height: "32px",
                                width: "32px",
                                paddingLeft: "6px",
                            }}
                            children={
                                isSearching ? (
                                    <Spinner size="sm" />
                                ) : (
                                    searchInput.length !== 0 && (
                                        <IconButton
                                            sx={{
                                                borderRadius: "999px",
                                                height: "20px",
                                                width: "20px",
                                                minWidth: "20px",
                                                padding: "2px",
                                            }}
                                            aria-label="Cancel search"
                                            onClick={() => {
                                                setSearchResult([]);
                                                setSearchInput("");
                                                setShowSearchResult(false);
                                                searchInputEl.current.focus();
                                            }}
                                            icon={<X size={18} />}
                                        />
                                    )
                                )
                            }
                        />
                    </InputGroup>
                    {showSearchResult && (
                        <Box
                            className="py-2 px-2 overflow-y-auto border border-t-0 rounded-lg rounded-t-none"
                            sx={{
                                position: "absolute",
                                zIndex: "50",
                                top: "100%",
                                left: "0",
                                right: "0",
                                width: "100%",
                                maxHeight: "300px",
                                transition: "height 0.3s",
                                boxShadow: "0px 15px 20px 2px rgba(0,0,0,0.2)",
                            }}
                            bg={useColorModeValue("#F7F7F8", "#14181d")}
                        >
                            {searchResult.length !== 0 ? (
                                searchResult.map((result, index) => {
                                    return (
                                        <Box className={`flex py-2 my-1 ${index !== searchResult.length - 1 && "border-b"}`}>
                                            <MiniUserProfile
                                                fullName={result.full_name}
                                                username={result.username}
                                                imageFilename={result.profile_picture}
                                                onClick={() => {
                                                    setSelectedUser({
                                                        fullname: result.full_name,
                                                        username: result.username,
                                                    });
                                                    setShowInviteDialog(true);
                                                    // setShowSearchResult(false);
                                                }}
                                            />
                                        </Box>
                                    );
                                })
                            ) : (
                                <Text m={0} py={1} fontSize="13px" color={"gray.400"}>
                                    نتیجه‌ای پیدا نشد!
                                </Text>
                            )}
                            <SendInviteConfirmAlert
                                isOpen={showInviteDialog}
                                onClose={() => setShowInviteDialog(false)}
                                isLoading={modalIsLoading}
                                onSuccess={inviteUser}
                                fullName={selectedUser.fullname}
                            />
                        </Box>
                    )}
                </Box>
                <Table mt={4} size="md" variant="simple">
                    <Thead>
                        <Tr>
                            <Th pl={0} pr={1} minW="200px">
                                ارسال شده به
                            </Th>
                            <Th pl={0}>وضعیت</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {invites.map((inviteData) => {
                            return (
                                <Tr
                                    id={inviteData.user.fusion_user_id}
                                    key={inviteData.user.fusion_user_id}
                                    sx={{
                                        td: {
                                            paddingBottom: "12px",
                                            paddingTop: "12px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                        },
                                    }}
                                >
                                    <Td textAlign={["center"]} pl={0} pr={1}>
                                        <MiniUserProfile
                                            fullName={inviteData.user.full_name}
                                            username={inviteData.user.username}
                                            imageFilename={inviteData.user.profile_picture}
                                        />
                                    </Td>
                                    <Td pl={0}>
                                        <Badge colorScheme={inviteData.statusColor} variant="subtle">
                                            {inviteData.status}
                                        </Badge>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        );
    },
);

const SendInviteConfirmAlert = (props: {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    fullName: string;
    onSuccess: () => void;
}) => {
    const cancelRef = React.useRef();
    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>ارسال دعوت</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>آیا از ارسال دعوت به «{props.fullName}» اطمینان دارید؟</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={props.onClose}>
                        انصراف
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={props.onSuccess} isLoading={props.isLoading}>
                        تایید و ارسال
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default InviteView;
