import React, { useState, useRef } from "react";
import { updateTeamInfo } from "@util/api/participants";
import { Avatar, Box, Button, IconButton, Input } from "@chakra-ui/react";
import classes from "@components/profile/edit/PersonalInfoEdit.module.css";
import { CustomAvatar } from "@components/avatar";
import { AddOutline } from "react-ionicons";
import { Check, Edit2 } from "react-feather";
import { getImageURL } from "@util/mediaLoader";
import CropModal, { b64toBlob } from "@util/CropModal";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";

export const TeamInfo = (props: {
    name: string;
    image: string;
    canEdit: boolean;
    participantId: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const imageRef = useRef(null);
    const inputRef = useRef(null);
    const [profileImage, setProfileImage] = useState(props.image === null ? "" : getImageURL(props.image));
    const [tmpProfileImage, setTmpProfileImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        name: props.name,
        image: props.image,
    });

    const checkFileAttributes = (file: File) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };
    const onChooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        // const file = acceptedFile?.[0];
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                title: "فایل نامعتبر",
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 9000,
                isClosable: true,
                variant: "solid",
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpProfileImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };
    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpProfileImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        setProfileImage(canvas.toDataURL("image/jpeg"));
    };
    const compileFormData = () => {
        const form_data = new FormData();
        form_data.append("name", data.name);

        // Converting Profile Image to Binary
        if (profileImage) {
            const block = profileImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("image", blob, "team_avatar.png");
            }
        }
        return form_data;
    };
    const saveChanges = async () => {
        setIsLoading(true);

        const data_req = compileFormData();
        const serverResponse = (await updateTeamInfo(props.participantId, data_req, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                description: "ذخیره تغییرات با موفقیت انجام شد",
                status: "success",
                duration: 3000,
                isClosable: true,
                variant: "solid",
            });
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }

        setIsLoading(false);
    };
    return (
        <Box className="flex flex-row items-center">
            <Box className="grid flex-shrink-0 flex-grow-0">
                {/* <input {...getInputProps()} /> */}
                <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
                <CropModal
                    src={tmpProfileImage}
                    isOpen={isModalOpen}
                    modalTitle={"انتخاب عکس تیم"}
                    onClose={() => setIsModalOpen(false)}
                    onSuccess={(data) => {
                        crop(data);
                        setIsModalOpen(false);
                    }}
                    aspectX={1}
                    aspectY={1}
                />
                {props.canEdit && (
                    <div className={`${classes.profilePictureDarkHover}`}>
                        <IconButton
                            size="sm"
                            bg={"transparent"}
                            aria-label="new profile picture"
                            icon={<AddOutline color="#fff" height="21px" width="21px" />}
                            _hover={{
                                bg: "blackAlpha.800",
                            }}
                            onClick={() => {
                                inputRef.current.click();
                            }}
                        />
                    </div>
                )}
                <CustomAvatar
                    ref={imageRef}
                    id="imagePreview"
                    src={profileImage ? profileImage : ""}
                    height="7rem"
                    width="7rem"
                    noBorder
                    placeholder={<Avatar size="full" />}
                    showPlaceholder={!profileImage}
                    className={`w-28 h-28 mx-5 px-0 py-0 ${classes.profilePicture}`}
                    style={{
                        gridRow: "1",
                        gridColumn: "1",
                        cursor: props.canEdit ? "pointer" : "default",
                    }}
                />
            </Box>
            {/* Name */}
            <Box className="flex justify-start flex-grow">
                <Input
                    maxWidth="250px"
                    isTruncated
                    id={`team-name-input`}
                    size="lg"
                    variant={editMode ? "flushed" : "unstyled"}
                    defaultValue={data.name}
                    isReadOnly={!editMode}
                    onChange={(e) => {
                        setData({ ...data, name: e.target.value });
                    }}
                />

                {/* Edit Buttons */}
                {props.canEdit && (
                    <div className="ml-2 flex flex-row items-center">
                        {editMode ? (
                            <IconButton
                                height="1.9rem"
                                variant="ghost"
                                size="sm"
                                aria-label="confirm social link"
                                icon={<Check size="18" />}
                                onClick={() => {
                                    setEditMode(false);
                                }}
                                isDisabled={false}
                            />
                        ) : (
                            <IconButton
                                height="1.9rem"
                                variant="ghost"
                                size="sm"
                                aria-label="edit social link"
                                icon={<Edit2 size="16" />}
                                onClick={() => {
                                    setEditMode(true);
                                    document.getElementById(`team-name-input`).focus();
                                }}
                                // isDisabled={editIndex !== -1}
                            />
                        )}
                        <Box className="self-end mr-4">
                            <Button size="sm" onClick={saveChanges} isDisabled={editMode} isLoading={isLoading}>
                                ذخیره تغییرات
                            </Button>
                        </Box>
                    </div>
                )}
            </Box>

            {/* Save Button */}
            {/* {props.canEdit && (
                    <Box className="self-end">
                        <Button
                            size="sm"
                            onClick={saveChanges}
                            isDisabled={editMode}
                            isLoading={isLoading}
                        >
                            ذخیره تغییرات
                        </Button>
                    </Box>
                )} */}
        </Box>
    );
};
