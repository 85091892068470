import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    IconButton,
    Image,
    SimpleGrid,
    Spinner,
    Text,
    useColorModeValue,
    useMediaQuery,
} from "@chakra-ui/react";
import { getProblemNotebooks, getProblemHostNotebooks } from "@util/api/problems";
import { INotebookBriefInfo, IToastOptions } from "@util/interfaces";
import { getImageURL } from "@util/mediaLoader";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ChevronBackOutline, ChevronForwardOutline } from "react-ionicons";

import Style from "./problemNotebooks.module.css";

export const ProblemNotebooks = (props: { problem: string; toast: IToastOptions; router: NextRouter }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [errorDetail, setErrorDetail] = useState({ code: 1, status: "", details: "" });
    const [data, setData] = useState({ results: [], total: 0 });
    const [hostData, setHostData] = useState({ results: [], total: 0 });

    const getData = async () => {
        const serverResponse = (await getProblemNotebooks(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setData(data);
            setErrorLoading(false);
        } else {
            setErrorDetail({
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
            setErrorLoading(true);
        }
    };
    const getHostData = async () => {
        const serverResponse = (await getProblemHostNotebooks(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setHostData(data);
            setErrorLoading(false);
        } else {
            setErrorDetail({
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
            setErrorLoading(true);
        }
    };
    useEffect(() => {
        getData();
        getHostData();
        setIsLoading(false);
    }, []);
    if (isLoading) {
        return (
            <Box className="flex flex-row justify-center">
                <Spinner className="mt-8" />
            </Box>
        );
    }
    if (errorLoading) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorDetail.status ? `${errorDetail.code} - ${errorDetail.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorDetail.details && <AlertDescription fontSize="sm">{errorDetail.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    return (
        <Box className={`w-full pt-4`}>
            <Box className={`w-full mb-8 flex flex-col`}>
                <Text fontSize="lg">نوت‌بوک‌های برگزارکننده</Text>
                {hostData.total === 0 ? (
                    <Text className={`self-center`}>هنوز نوت‌بوکی توسط برگزارکننده قرار داده نشده است.</Text>
                ) : (
                    <HostNotebooksDisplayer notebooks={hostData.results} />
                )}
            </Box>
            <Box className={`w-full flex flex-col`}>
                <Text fontSize="lg">نوت‌بوک‌های شرکت‌کنندگان</Text>
                {data.total === 0 ? (
                    <Text className={`self-center`}>هنوز نوت‌بوکی توسط شرکت‌کنندگان قرار داده نشده است.</Text>
                ) : (
                    <OtherNotebooksDisplayer notebooks={data.results} />
                )}
            </Box>
        </Box>
    );
};
const OtherNotebooksDisplayer = (props: { notebooks: Array<INotebookBriefInfo> }) => {
    const router = useRouter();
    return (
        <div className={`flex flex-col w-8/12 justify-center ml-auto px-4 ${Style.notebooks_container}`}>
            {props.notebooks.map((notebookInfo) => {
                return (
                    <Box className={`flex flex-row py-3 ${Style.NotebookInfoCard}`}>
                        <Box minW={20} className={`rounded-md`}>
                            <Link href={`/notebooks/${notebookInfo.path}`} passHref>
                                <Image
                                    boxSize="20"
                                    borderRadius="4px"
                                    objectFit="cover"
                                    src={
                                        notebookInfo.thumbnail ? getImageURL(`${notebookInfo.thumbnail}`) : "/images/bannerPlaceholder.png"
                                    }
                                    alt="notebook thumbnail"
                                />
                            </Link>
                        </Box>
                        <Box className={`${Style.top_container} w-full flex flex-col mr-6 justify-between`}>
                            <div className="w-full">
                                <Link href={`/notebooks/${notebookInfo.path}`} passHref>
                                    <h3 className="truncate font-semibold mb-0" style={{ fontSize: "1rem" }}>
                                        {notebookInfo.title}
                                    </h3>
                                </Link>
                            </div>
                            <div className={`w-full`}>
                                <Text mb={"2rem"} fontSize="md" isTruncated>
                                    {notebookInfo.subtitle}
                                </Text>
                            </div>
                            <div className={`${Style.nb_misc_buttons} w-full flex-row flex justify-between`}>
                                <Link href={`/users/${notebookInfo.creator}`} passHref>
                                    <Button variant="link" color={useColorModeValue("#000", "#fff")} mb={0}>
                                        {notebookInfo.creator}
                                    </Button>
                                </Link>
                                <Button
                                    size="xs"
                                    variant={useColorModeValue("outline", "ghost")}
                                    colorScheme={useColorModeValue("RoboEpics.azure", "blue")}
                                    onClick={() => {
                                        router.push(`/notebooks/${notebookInfo.path}`);
                                    }}
                                >
                                    مشاهده
                                </Button>
                            </div>
                        </Box>
                    </Box>
                );
            })}
        </div>
    );
};

const HostNotebooksDisplayer = (props: { notebooks: Array<INotebookBriefInfo> }) => {
    const [isGreaterThan768px, isGreaterThan550px] = useMediaQuery(["(min-width:769px)", "(min-width:551px)"]);
    const router = useRouter();
    const [prev, setPrev] = useState(0);
    const [current, setCurrent] = useState(1);
    const [next, setNext] = useState(2);
    const length = props.notebooks.length;
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
        setNext(current === length - 1 ? 1 : current + 2 >= length ? 0 : current + 2);
        setPrev(current);
    };
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
        setNext(current);
        setPrev(current === 0 ? length - 2 : current - 2 < 0 ? length - 1 : current - 2);
    };
    return (
        <div className={`flex flex-row items-center justify-center`}>
            <IconButton
                size={"xs"}
                variant={"ghost"}
                ml={2}
                aria-label={"next-slide"}
                icon={<ChevronForwardOutline color={useColorModeValue("#000", "#fff")} />}
                onClick={nextSlide}
            />
            <SimpleGrid
                columns={isGreaterThan768px ? 3 : isGreaterThan550px ? 2 : 1}
                width={"full"}
                spacing={isGreaterThan768px ? 6 : isGreaterThan550px ? 4 : 2}
            >
                <Box
                    bg={useColorModeValue("RoboEpics.black.100", "RoboEpics.dark.600")}
                    _hover={{
                        bg: useColorModeValue("gray.100", "RoboEpics.dark.800"),
                    }}
                    className="border rounded-lg"
                >
                    <div>
                        <Link href={`/notebooks/${props.notebooks[prev].path}`} passHref>
                            <div className={`${Style.cardCover_link}`}>
                                <img
                                    className={`rounded-t-md ${Style.cardCover}`}
                                    src={
                                        props.notebooks[prev].thumbnail
                                            ? getImageURL(`${props.notebooks[prev].thumbnail}`)
                                            : "/images/bannerPlaceholder.png"
                                    }
                                />
                            </div>
                        </Link>
                    </div>
                    <div className={`flex flex-row px-3 pb-2 pt-3`}>
                        <div className="w-full">
                            <Link href={`/notebooks/${props.notebooks[prev].path}`} passHref>
                                <h3
                                    className="truncate font-semibold"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {props.notebooks[prev].title}
                                </h3>
                            </Link>
                            <p className="truncate font-medium">{props.notebooks[prev].subtitle}</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center mt-2 p-3 rounded-b-lg justify-between">
                        <Link href={`/users/${props.notebooks[prev].creator}`} passHref>
                            <Button variant="link" color={useColorModeValue("#000", "#fff")} mb={0}>
                                {props.notebooks[prev].creator}
                            </Button>
                        </Link>
                        <Button
                            size="xs"
                            variant={useColorModeValue("outline", "ghost")}
                            colorScheme={useColorModeValue("RoboEpics.azure", "blue")}
                            onClick={() => {
                                router.push(`/notebooks/${props.notebooks[prev].path}`);
                            }}
                        >
                            مشاهده
                        </Button>
                    </div>
                </Box>
                {isGreaterThan550px && (
                    <Box
                        bg={useColorModeValue("RoboEpics.black.100", "RoboEpics.dark.600")}
                        _hover={{
                            bg: useColorModeValue("gray.100", "RoboEpics.dark.800"),
                        }}
                        className="border rounded-lg"
                    >
                        <div>
                            <Link href={`/notebooks/${props.notebooks[current].path}`} passHref>
                                <div className={`${Style.cardCover_link}`}>
                                    <img
                                        className={`rounded-t-md ${Style.cardCover}`}
                                        src={
                                            props.notebooks[current].thumbnail
                                                ? getImageURL(`${props.notebooks[current].thumbnail}`)
                                                : "/images/bannerPlaceholder.png"
                                        }
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className={`flex flex-row px-3 pb-2 pt-3`}>
                            <div className="w-full">
                                <Link href={`/notebooks/${props.notebooks[current].path}`} passHref>
                                    <h3
                                        className="truncate font-semibold"
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        {props.notebooks[current].title}
                                    </h3>
                                </Link>
                                <p className="truncate font-medium">{props.notebooks[current].subtitle}</p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-2 p-3 rounded-b-lg justify-between">
                            <Link href={`/users/${props.notebooks[current].creator}`} passHref>
                                <Button variant="link" color={useColorModeValue("#000", "#fff")} mb={0}>
                                    {props.notebooks[current].creator}
                                </Button>
                            </Link>
                            <Button
                                size="xs"
                                variant={useColorModeValue("outline", "ghost")}
                                colorScheme={useColorModeValue("RoboEpics.azure", "blue")}
                                onClick={() => {
                                    router.push(`/notebooks/${props.notebooks[current].path}`);
                                }}
                            >
                                مشاهده
                            </Button>
                        </div>
                    </Box>
                )}
                {isGreaterThan768px && isGreaterThan550px && (
                    <Box
                        bg={useColorModeValue("RoboEpics.black.100", "RoboEpics.dark.600")}
                        _hover={{
                            bg: useColorModeValue("gray.100", "RoboEpics.dark.800"),
                        }}
                        className="border rounded-lg"
                    >
                        <div>
                            <Link href={`/notebooks/${props.notebooks[next].path}`}>
                                <div className={`${Style.cardCover_link}`}>
                                    <img
                                        className={`rounded-t-md ${Style.cardCover}`}
                                        src={
                                            props.notebooks[next].thumbnail
                                                ? getImageURL(`${props.notebooks[next].thumbnail}`)
                                                : "/images/bannerPlaceholder.png"
                                        }
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className={`flex flex-row px-3 pb-2 pt-3`}>
                            <div className="w-full">
                                <Link href={`/notebooks/${props.notebooks[next].path}`} passHref>
                                    <h3
                                        className="truncate font-semibold"
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        {props.notebooks[next].title}
                                    </h3>
                                </Link>
                                <p className="truncate font-medium">{props.notebooks[next].subtitle}</p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-2 p-3 rounded-b-lg justify-between">
                            <Link href={`/users/${props.notebooks[next].creator}`} passHref>
                                <Button variant="link" color={useColorModeValue("#000", "#fff")} mb={0}>
                                    {props.notebooks[next].creator}
                                </Button>
                            </Link>
                            <Button
                                size="xs"
                                variant={useColorModeValue("outline", "ghost")}
                                colorScheme={useColorModeValue("RoboEpics.azure", "blue")}
                                onClick={() => {
                                    router.push(`/notebooks/${props.notebooks[next].path}`);
                                }}
                            >
                                مشاهده
                            </Button>
                        </div>
                    </Box>
                )}
            </SimpleGrid>
            <IconButton
                size={"xs"}
                variant={"ghost"}
                mr={2}
                aria-label={"prev-slide"}
                icon={<ChevronBackOutline color={useColorModeValue("#000", "#fff")} />}
                onClick={prevSlide}
            />
        </div>
    );
};
