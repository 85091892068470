import { VStack } from "@chakra-ui/react";
import React from "react";

interface ICustomList {
    items: Array<{ icon: React.ReactElement; title: string | React.ReactElement; key: string }>;
    spacing?: string | number;
}

export const CustomList = (props: ICustomList) => {
    return (
        <ul>
            <VStack
                spacing={props.spacing ? props.spacing : 2}
                style={{
                    alignItems: "start",
                }}
            >
                {props.items.map((item: { icon: React.ReactElement; title: string | React.ReactElement; key: string }) => {
                    return (
                        <li className="flex flex-row justify-start items-center" key={item.key}>
                            <span className="ml-1">{item.icon}</span>
                            <span style={{ fontSize: "13px" }}>{item.title}</span>
                        </li>
                    );
                })}
            </VStack>
        </ul>
    );
};
