import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Checkbox,
    Text,
    Link,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";
import { getInvite } from "@util/api/participants";
import { Box, Divider } from "@chakra-ui/react";
import { MembersTable } from "../../team/membersTable";
import { IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";
import { NextRouter } from "next/router";

const RegisterModal = (props: {
    comp: string;
    compTitle: string;
    onClose: () => void;
    onRegister: () => void;
    onJoin: () => void;
    isOpen: boolean;
    isLoading?: boolean;
    joinLoading?: boolean;
    participantSummary: { invite_id: number; participant_id: string; phase_progress_restrictions: Record<string, string> };
    participationPrice: number | null;
    toast: IToastOptions;
    user: IUser;
    router: NextRouter;
}) => {
    const [agreement, setAgreement] = useState({
        re: false,
        comp: false,
    });
    const [inviteData, setInviteData] = useState(null);

    const getInviteData = async () => {
        const serverResponse = (await getInvite(props.participantSummary.invite_id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setInviteData(data);
        } else {
            props.toast({
                title: "مشکلی در دریافت دعوت رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
    };

    useEffect(() => {
        if (props.participantSummary.invite_id) {
            getInviteData();
        }
    }, []);

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ثبت نام و شرکت در مسابقه</ModalHeader>
                <ModalBody>
                    <p>
                        شرکت شما در این مسابقه منوط به پذیرش و پیروی از
                        <u style={{ marginLeft: "4px", marginRight: "4px" }}>
                            <Link href="/terms" target="_blank">
                                {"آیین‌نامه روبواپیکس "}
                            </Link>
                        </u>
                        {"و همچنین "}
                        <u style={{ marginLeft: "4px", marginRight: "4px" }}>
                            <Link href={`/c/${props.comp}/rules`} target="_blank">
                                {"آیین‌نامه اجرایی مسابقه "}
                            </Link>
                        </u>
                        که توسط برگزارکننده تدوین شده است، می‌باشد.
                    </p>
                    {props.participationPrice > 0 && (
                        <Alert status="info">
                            <AlertIcon />
                            هزینه شرکت در مسابقه:
                            <b style={{ margin: "0 0.5rem" }}>{props.participationPrice} هزار تومان</b>
                        </Alert>
                    )}
                    <Box className="mt-2">
                        <Checkbox
                            style={{
                                fontSize: "14px",
                            }}
                            isChecked={agreement.re}
                            onChange={(event) =>
                                setAgreement((current) => {
                                    return {
                                        ...current,
                                        re: event.target.checked,
                                    };
                                })
                            }
                        >
                            <Text mb="0" sx={{ fontSize: "14px" }}>
                                آیین‌نامه فعالیت در روبواپیکس را مطالعه کرده و آن را می‌پذیرم.
                            </Text>
                        </Checkbox>
                        <Checkbox
                            style={{
                                fontSize: "14px",
                            }}
                            isChecked={agreement.comp}
                            onChange={(event) =>
                                setAgreement((current) => {
                                    return {
                                        ...current,
                                        comp: event.target.checked,
                                    };
                                })
                            }
                        >
                            <Text mb="0" sx={{ fontSize: "14px" }}>
                                آیین‌نامه شرکت در مسابقه {props.compTitle} را مطالعه کرده و آن را می‌پذیرم.
                            </Text>
                        </Checkbox>
                    </Box>
                    {inviteData && (
                        <>
                            <Divider my="12px" />
                            <p>
                                شما از طرف تیم «<strong>{inviteData.team.name}</strong>» به این مسابقه دعوت شده‌اید.
                                <br />
                                <br />
                                اعضای تیم:
                                <MembersTable
                                    ref={null}
                                    refreshFunction={null}
                                    participantId={inviteData.team.id}
                                    member_set={inviteData.team.member_set}
                                    showManageControls={false}
                                    canManage={false}
                                    columns={["member", "role"]}
                                    toast={props.toast}
                                    user={props.user}
                                    router={props.router}
                                />
                                <br />
                                در صورت تمایل می‌توانید به این تیم محلق شوید و یا صرفا ثبت‌نام خود را تکمیل کرده و بعدا از طریق صفحه «تیم»
                                نسبت به نحوه شرکت خود در مسابقه اقدام کنید.
                            </p>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onClose} variant="ghost" isDisabled={props.joinLoading || props.isLoading}>
                        انصراف
                    </Button>
                    <Button
                        isDisabled={!(agreement.comp && agreement.re) || props.joinLoading}
                        onClick={props.onRegister}
                        isLoading={props.isLoading}
                        mr={1}
                        variant={props.participantSummary.invite_id ? "ghost" : "solid"}
                    >
                        {props.participationPrice > 0 ? "پرداخت هزینه" : "ثبت‌نام"}
                    </Button>
                    {props.participantSummary.invite_id && (
                        <Button
                            isDisabled={!(agreement.comp && agreement.re) || props.isLoading}
                            mr={1}
                            onClick={props.onJoin}
                            isLoading={props.joinLoading}
                            colorScheme="blue"
                        >
                            {props.participationPrice > 0 ? "پرداخت هزینه و پیوستن به تیم" : "ثبت‌نام و پیوستن به تیم"}
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default RegisterModal;
