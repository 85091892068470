import React from "react";
import { Text, useColorModeValue, Box, VStack, IconButton, Tooltip } from "@chakra-ui/react";
import styles from "./CompSidebar.module.css";
import { AddOutline } from "react-ionicons";
import { IMenuSectionProps } from "@util/interfaces";

const MenuSection = (props: IMenuSectionProps) => {
    return (
        <Box className={`${!props.noMargin && "mt-12"} `}>
            <Box className={`${props.editable && props.isOwner && props.isCollaborator && "flex flex-row justify-between"}`}>
                <Text
                    fontWeight="600"
                    color={useColorModeValue("blackAlpha.500", "whiteAlpha.700")}
                    className={`${styles.menu_item_branch_label} `}
                >
                    {props.title}
                </Text>
                {props.isOwner && props.isCollaborator && props.compType === "simple" && (
                    <Tooltip aria-label={"create-new-challenge"} label={"ساخت سوال جدید"} placement={"top"}>
                        <IconButton
                            onClick={() => {
                                props.setModalOpen(true);
                                props.setModalData((current) => {
                                    return {
                                        ...current,
                                        phaseId: props.phaseId,
                                    };
                                });
                            }}
                            size={"xs"}
                            variant={"ghost"}
                            aria-label="add-problem"
                            icon={<AddOutline color={useColorModeValue("#000", "#fff")} width="16px" height="16px" />}
                        />
                    </Tooltip>
                )}
            </Box>
            <Box
                className="mt-2"
                style={{
                    marginInlineStart: "-.75rem",
                    marginInlineEnd: "-.75rem",
                }}
            >
                <ul className="leading-9">
                    <VStack spacing=".25rem" alignItems="start">
                        {props.children}
                    </VStack>
                </ul>
            </Box>
        </Box>
    );
};
export default MenuSection;
