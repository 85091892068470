import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const retrieveProblemSubmissionScoreDefinitions = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/submission_score_definition`, {}, "off", ctx);

export const retrieveProblemScoreDefinitions = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/score_definition`, {}, "off", ctx);

export const createProblemSubmissionScoreDefinition = async (name: string, description: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/submission_score_definition`,
        { method: "POST", body: JSON.stringify({ name, description }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const createProblemScoreDefinition = async (name: string, description: string, ctx?: IServerSideContext) =>
    fetchWithRefresh(
        `/problem/score_definition`,
        { method: "POST", body: JSON.stringify({ name, description }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const assignSubmissionScoreDefinitionToProblem = async (path: string, name: string, weight: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/submission_score_definition`,
        {
            method: "POST",
            body: JSON.stringify({ score_definition: name, weight }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const assignScoreDefinitionToProblem = async (path: string, name: string, weight: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/score_definition`,
        {
            method: "POST",
            body: JSON.stringify({ score_definition: name, weight }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const editProblemSubmissionScoreDefinition = async (path: string, id: number, weight: string | number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/submission_score_definition/${id}`,
        { method: "PATCH", body: JSON.stringify({ weight }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const editProblemScoreDefinition = async (path: string, id: number, weight: string | number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/problem/${path}/score_definition/${id}`,
        { method: "PATCH", body: JSON.stringify({ weight }), headers: { "Content-Type": "application/json" } },
        "required",
        ctx,
    );

export const removeSubmissionScoreDefinitionFromProblem = async (path: string, id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/submission_score_definition/${id}`, { method: "DELETE" }, "required", ctx);

export const removeScoreDefinitionFromProblem = async (path: string, id: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/problem/${path}/score_definition/${id}`, { method: "DELETE" }, "required", ctx);
