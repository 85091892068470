/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React, { useState, useCallback, useEffect } from "react";
import { Button } from "@chakra-ui/react";

const useRect = (childrenHeight: number) => {
    const [rect, setRect] = useState<DOMRect | null>(new DOMRect(0, 0, undefined, childrenHeight));
    const ref = useCallback((node) => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect, ref];
};
export const ShowMore = (props: { height: number; shadowColor: string; speed: number; children: JSX.Element }) => {
    /*
     * We manage 3 heights:
     *  - height: desired collapsed height
     *  - contentsHeight: height of the actual content
     *  - currentHeight: the active state between those 2 ( contentsHeight | height )
     */
    useEffect(() => {
        containerRef(document.getElementById(props.children.props.id));
    }, [props.children]);
    const [rect, containerRef] = useRect(document.getElementById(props.children.props.id)?.clientHeight);

    const contentsHeight = rect ? rect.height : 0;
    const [currentHeight, setCurrentHeight] = useState(props.height);
    const [isOpen, setIsOpen] = useState(false);

    /**
     * Toggle between the maximum height (height of the content)
     */
    const handleToggle = () => {
        if (currentHeight === props.height) {
            setCurrentHeight(contentsHeight);
            setIsOpen(true);
        } else {
            setCurrentHeight(props.height);
            setIsOpen(false);
        }
    };
    const displayShadow = contentsHeight !== props.height && props.height < contentsHeight && !isOpen;
    const displayToggle = isOpen || displayShadow;

    return (
        <>
            <div style={{ position: "relative", overflow: "hidden" }}>
                {/* shadowOverlay */}
                {displayShadow && <ShadowOverlay color={props.shadowColor} />}

                {/* maincontent area */}
                <MainContent height={isOpen ? contentsHeight : props.height} speed={props.speed} ref={containerRef}>
                    {props.children}
                </MainContent>
                {/* invisible content container */}
            </div>

            {/* toggle */}
            {displayToggle && (
                <button
                    className="AnimatedShowMore__ToggleButton"
                    style={{
                        display: "block",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        padding: 0,
                        color: "inherit",
                        font: "inherit",
                        margin: "0 auto",
                    }}
                    onClick={handleToggle}
                >
                    {isOpen ? (
                        <Button variant="solid" size="sm" colorScheme="RoboEpics.turquoise">
                            مشاهده کمتر
                        </Button>
                    ) : (
                        <Button variant="solid" size="sm" colorScheme="RoboEpics.turquoise">
                            مشاهده بیشتر
                        </Button>
                    )}
                </button>
            )}
        </>
    );
};
const MainContent = (props: { height: number; speed: number; children: JSX.Element; ref: React.LegacyRef<HTMLDivElement> }) => {
    return (
        <div
            style={{
                overflow: "hidden",
                maxHeight: `${props.height}px`,
                transition: `max-height ${props.speed}ms linear`,
            }}
            ref={props.ref}
        >
            {props.children}
        </div>
    );
};
const ShadowOverlay = ({ color = "#fff" }) => {
    return (
        <div
            style={{
                backgroundImage: `linear-gradient(to bottom, transparent, ${color})`,
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: 60,
            }}
        />
    );
};
