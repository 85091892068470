import React from "react";
import { RoboEpicsMarkdown } from "@components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus, ghcolors } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Box, useColorModeValue } from "@chakra-ui/react";
import Convert from "ansi-to-html";

const renderPriority = [
    "application/vnd.jupyter.widget-view+json",
    "application/javascript",
    "text/html",
    "image/svg+xml",
    "image/png",
    "image/jpeg",
    "text/markdown",
    "text/latex",
    "text/plain",
];

const Notebook = (props: { notebook: string }) => {
    const content = render(JSON.parse(props.notebook));
    return (
        <Box className="re-nb-root" w="full">
            {content}
        </Box>
    );
};

const convert_ansi = new Convert();

const render = (raw: any) => {
    const joinText = (text) => {
        if (text.join) {
            return text.map(joinText).join("");
        } else {
            return text;
        }
    };
    const displayData = (obj: any, type: string) => {
        switch (type) {
            case "text/plain":
                return <pre className="text-output">{joinText(obj)}</pre>;
            case "text/html":
                // return (
                //     <JsxParser autoCloseVoidElements jsx={`${joinText(obj)}`} />
                // );
                return (
                    <div
                        // style={{ maxWidth: "100px" }}
                        dangerouslySetInnerHTML={{ __html: joinText(obj) }}
                    />
                );
            case "text/markdown":
                return <RoboEpicsMarkdown skipHtml={false}>{joinText(obj)}</RoboEpicsMarkdown>;
            case "text/svg+xml":
                return <div className="svg-output">{joinText(obj)}</div>;
            case "image/svg+xml":
                return <div className="svg-output">{joinText(obj)}</div>;
            case "text/latex":
                return <div className="latex-output">{joinText(obj)}</div>;
            case "application/javascript":
                return <script>{joinText(obj)}</script>;
            case "image/png":
                return <img src={`data:image/png;base64,${joinText(obj).replace(/\n/g, "")}`} />;
            case "image/jpeg":
                return <img src={`data:image/jpeg;base64,${joinText(obj).replace(/\n/g, "")}`} />;
            default:
                return <pre className="text-output">No output to show</pre>;
        }
    };
    const renderInput = (content: string, prompt_number?: number | null, lang?: string | null) => {
        if (!content.length) {
            return <div />;
        }
        return (
            <div data-cell-info="input" className="re-nb-input" data-prompt-number={prompt_number}>
                {/* <pre className={`language-${lang}`}>
                    <code className={`language-${lang}`} data-language={lang}> */}
                <SyntaxHighlighter language={lang} style={useColorModeValue(ghcolors, vscDarkPlus)}>
                    {content}
                </SyntaxHighlighter>
                {/* </code>
                </pre> */}
            </div>
        );
    };

    const renderOutputs = (outputs: Array<any>, prompt_number?: number | null, lang?: string | null) => {
        const result = [];
        for (const output of outputs) {
            const r = [];
            let format = null;
            switch (output.output_type) {
                case "display_data":
                    for (const type of renderPriority) {
                        if (output.data[type] !== undefined) {
                            format = type;
                            break;
                        }
                    }

                    r.push(displayData(output.data[format], format));

                    break;
                case "error":
                case "pyerr":
                    r.push(
                        <React.Fragment>
                            {/* <SyntaxHighlighter
                                language="shellSession"
                                className={`re-nb-error`}
                            > */}
                            <pre
                                style={{
                                    background: useColorModeValue("white", "rgb(30, 30, 30) none repeat scroll 0% 0%"),
                                    border: useColorModeValue("1px solid rgb(221, 221, 221)", "none"),
                                    margin: ".5em 0",
                                    padding: "1em",
                                    direction: "ltr",
                                    fontSize: "13px",
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: convert_ansi.toHtml(output.traceback.join("<br/>")),
                                    }}
                                />
                            </pre>
                            {/* </SyntaxHighlighter> */}
                        </React.Fragment>,
                    );
                    break;
                // case "pyout":

                //     break;
                case "execute_result":
                    // TODO: apply format priorities (just like notebook.js)
                    format = Object.keys(output.data)[0];
                    r.push(displayData(output.data[format], format));
                    break;
                case "stream":
                    r.push(<pre className={`re-nb-${output.name}`}>{joinText(output.text)}</pre>);
                    // r.push(displayData(output.data[format], format));
                    break;
            }
            result.push(r);
        }
        if (result.length !== 0)
            return (
                <div data-cell-info="output" className="re-nb-output" data-prompt-number={prompt_number}>
                    {result}
                </div>
            );
        else return <></>;
    };

    const result = [];
    const meta = raw.metadata;
    const worksheets = raw.worksheets ? raw.worksheets : [{ cells: raw.cells }];
    for (const worksheet of worksheets) {
        // LEVEL: Worksheet
        const _rendered_worksheet = [];
        const cells = worksheet.cells;
        for (const cell of cells) {
            const _rendered_cell = [];
            switch (cell.cell_type) {
                case "markdown":
                    _rendered_cell.push(
                        <div data-cell-type="markdown">
                            <RoboEpicsMarkdown>{joinText(cell.source)}</RoboEpicsMarkdown>
                        </div>,
                    );
                    break;
                // case "heading":
                //     const headingTag = `h${cell.level}`;
                //     _rendered_cell.push(<div data-cell-type="heading"></div>);
                //     break;
                case "raw":
                    _rendered_cell.push(<div data-cell-type="raw">{cell.source}</div>);
                    break;
                case "code": {
                    const cellInfo = {
                        number: cell.prompt_number > -1 ? cell.prompt_number : cell.execution_count,
                        input: cell.source,
                        outputs: cell.outputs ? cell.outputs : [],
                    };
                    const lang =
                        cell.language ||
                        meta.language ||
                        (meta.kernelspec && meta.kernelspec.language) ||
                        (meta.language_info && meta.language_info.name);

                    const _rendered_input = renderInput(joinText(cellInfo.input), cellInfo.number, lang);
                    const _rendered_output = renderOutputs(cellInfo.outputs, cellInfo.number, lang);

                    _rendered_cell.push(
                        <div data-cell-type="code">
                            {_rendered_input}
                            {_rendered_output}
                        </div>,
                    );
                    break;
                }
            }
            _rendered_worksheet.push(_rendered_cell);
        }
        result.push(_rendered_worksheet);
    }
    return result;
};
export default Notebook;
