import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Input,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    SimpleGrid,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spinner,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { IPhaseData, IToastOptions } from "@util/interfaces";
import Style from "./compOwner.module.css";
import { RoboEpicsDatePicker, convertDatePicker2Json, convertServer2DatePicker } from "@components";
import { NextRouter } from "next/router";
import { editCompetitionPhaseJson } from "@util/api/competitions";

export const PhaseSettings = (props: {
    isLoading: boolean;
    phaseData: IPhaseData;
    setEditConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    isClassroom: boolean;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [values, setValues] = useState(props.phaseData);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const onConfirm = async () => {
        const data = {};
        Object.keys(values).forEach((element) => {
            if (values[element] !== props.phaseData[element]) {
                data[element] = values[element];
            }
        });
        const serverResponse = (await editCompetitionPhaseJson(props.router.query.phase_id, props.router.query.competition, data, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "success",
                description: "تغییرات شما با موفقیت اعمال شد!",
            });
            props.setEditConfirmed(true);
        } else {
            props.toast({
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
            setValues(props.phaseData);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (values) {
            if (
                Object.keys(values).some((key) => {
                    return key !== "problems" ? values[key] !== props.phaseData[key] : false;
                })
            )
                setDisabled(false);
            if (Object.keys(values).every((key) => (key !== "problem" ? values[key] === props.phaseData[key] : true))) setDisabled(true);
        }
    }, [values]);
    //bc of date time problems
    useEffect(() => {
        setValues(props.phaseData);
    }, []);
    if (props.isLoading || !values || !props.phaseData) {
        return (
            <Box className={`flex flex-col w-full items-center`}>
                <Spinner size="sm" />
            </Box>
        );
    }
    return (
        <Box className={`border flex flex-col justify-between p-4 ${Style.main_container}`}>
            <Box className={`flex flex-row w-full justify-between`}>
                <Text fontSize="2xl" fontWeight="bold">
                    تنظیمات مرحله
                </Text>
            </Box>
            <VStack spacing={10}>
                <FormControl>
                    <FormLabel fontSize="lg">عنوان مرحله </FormLabel>
                    <Input
                        variant="filled"
                        value={values.title}
                        onChange={(e) => {
                            setValues((current) => {
                                return { ...current, title: e.target.value };
                            });
                        }}
                        maxLength={100}
                    />
                    <FormHelperText>{`${values.title.length}/100`}</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel fontSize="lg">بازه زمانی ثبت پاسخ برای این مرحله</FormLabel>
                    <Box className={`flex flex-row items-end ${Style.datePicker_container}`} pt={4}>
                        <RoboEpicsDatePicker
                            type="day"
                            initDayValue={convertServer2DatePicker(values.submission_date_start, "fa")?.date}
                            initTimeValue={convertServer2DatePicker(values.submission_date_start, "fa")?.time}
                            setPropDate={(day, time, locale) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        submission_date_start: convertDatePicker2Json(day, time, locale),
                                    };
                                });
                            }}
                            placeholder="آغاز ثبت پاسخ"
                        />
                        <Text className={`mx-6 my-2 ${Style.ta_text}`} textAlign="center">
                            تا
                        </Text>
                        <VStack spacing={2} alignItems="flex-start">
                            <RadioGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                value={values.submission_date_end ? "limited" : "unlimited"}
                                onChange={(val) => {
                                    if (val === "unlimited") {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                submission_date_end: null,
                                            };
                                        });
                                    } else {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                submission_date_end: props.phaseData.submission_date_end
                                                    ? props.phaseData.submission_date_end
                                                    : new Date().toISOString().slice(0, 19) + "+03:30",
                                            };
                                        });
                                    }
                                }}
                            >
                                <HStack>
                                    <Radio value="unlimited">پایان باز</Radio>
                                    <Radio value="limited"> تعیین زمان</Radio>
                                </HStack>
                            </RadioGroup>
                            <RoboEpicsDatePicker
                                type="day"
                                initDayValue={convertServer2DatePicker(values.submission_date_end, "fa")?.date}
                                initTimeValue={convertServer2DatePicker(values.submission_date_end, "fa")?.time}
                                isDisabled={!values.submission_date_end}
                                setPropDate={(day, time, locale) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            submission_date_end: convertDatePicker2Json(day, time, locale),
                                        };
                                    });
                                }}
                                placeholder="پایان ثبت پاسخ"
                            />
                        </VStack>
                    </Box>
                    <Divider mt={2} />
                </FormControl>

                <FormControl>
                    <FormLabel fontSize="lg">بازه زمانی تست این مرحله</FormLabel>
                    <Box className={`flex flex-row items-end ${Style.datePicker_container}`} pt={4}>
                        <RoboEpicsDatePicker
                            type="day"
                            initDayValue={convertServer2DatePicker(values.test_date_start, "fa")?.date}
                            initTimeValue={convertServer2DatePicker(values.test_date_start, "fa")?.time}
                            setPropDate={(day, time, locale) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        test_date_start: convertDatePicker2Json(day, time, locale),
                                    };
                                });
                            }}
                            placeholder="آغاز فرایند تست"
                        />
                        <Text className={`my-2 mx-6 ${Style.ta_text}`} textAlign="center">
                            تا
                        </Text>
                        <VStack spacing={2} alignItems="flex-start">
                            <RadioGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                value={values.test_date_end ? "limited" : "unlimited"}
                                onChange={(val) => {
                                    if (val === "unlimited") {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                test_date_end: null,
                                            };
                                        });
                                    } else {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                test_date_end: props.phaseData.test_date_end
                                                    ? props.phaseData.test_date_end
                                                    : new Date().toISOString().slice(0, 19) + "+03:30",
                                            };
                                        });
                                    }
                                }}
                            >
                                <HStack>
                                    <Radio value="unlimited">پایان باز</Radio>
                                    <Radio value="limited"> تعیین زمان</Radio>
                                </HStack>
                            </RadioGroup>
                            <RoboEpicsDatePicker
                                type="day"
                                initDayValue={convertServer2DatePicker(values.test_date_end, "fa")?.date}
                                initTimeValue={convertServer2DatePicker(values.test_date_end, "fa")?.time}
                                isDisabled={!values.test_date_end}
                                setPropDate={(day, time, locale) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            test_date_end: convertDatePicker2Json(day, time, locale),
                                        };
                                    });
                                }}
                                placeholder="پایان فرایند تست"
                            />
                        </VStack>
                    </Box>
                    <Divider mt={2} />
                </FormControl>
                <FormControl>
                    <FormLabel fontSize="lg">بازه زمانی محدود کردن این مرحله</FormLabel>
                    <Box className={`flex flex-row items-end ${Style.datePicker_container}`} pt={4}>
                        <RoboEpicsDatePicker
                            type="day"
                            initDayValue={convertServer2DatePicker(values.restrict_date_start, "fa")?.date}
                            initTimeValue={convertServer2DatePicker(values.restrict_date_start, "fa")?.time}
                            setPropDate={(day, time, locale) => {
                                setValues((current) => {
                                    return {
                                        ...current,
                                        restrict_date_start: convertDatePicker2Json(day, time, locale),
                                    };
                                });
                            }}
                            placeholder="آغاز محدودیت"
                        />

                        <Text className={`my-2 mx-6 ${Style.ta_text}`} textAlign="center">
                            تا
                        </Text>
                        <VStack spacing={2} alignItems="flex-start">
                            <RadioGroup
                                colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                                value={values.restrict_date_end ? "limited" : "unlimited"}
                                onChange={(val) => {
                                    if (val === "unlimited") {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                restrict_date_end: null,
                                            };
                                        });
                                    } else {
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                restrict_date_end: props.phaseData.restrict_date_end
                                                    ? props.phaseData.restrict_date_end
                                                    : new Date().toISOString().slice(0, 19) + "+03:30",
                                            };
                                        });
                                    }
                                }}
                            >
                                <HStack>
                                    <Radio value="unlimited">پایان باز</Radio>
                                    <Radio value="limited"> تعیین زمان</Radio>
                                </HStack>
                            </RadioGroup>
                            <RoboEpicsDatePicker
                                type="day"
                                initDayValue={convertServer2DatePicker(values.restrict_date_end, "fa")?.date}
                                initTimeValue={convertServer2DatePicker(values.restrict_date_end, "fa")?.time}
                                isDisabled={!values.restrict_date_end}
                                setPropDate={(day, time, locale) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            restrict_date_end: convertDatePicker2Json(day, time, locale),
                                        };
                                    });
                                }}
                                placeholder="پایان محدودیت"
                            />
                        </VStack>
                    </Box>
                    <Divider mt={2} />
                </FormControl>
                <Stack direction={["column", "row"]} spacing={12} justifyContent="space-between" w="full">
                    <FormControl>
                        <FormLabel fontSize="lg">پیش‌نیاز رفتن به مرحله بعد</FormLabel>
                        <RadioGroup
                            value={values.progress_restriction}
                            colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                            onChange={(value) => {
                                switch (value) {
                                    case "10":
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                progress_restriction: 10,
                                                eligible_score: props.phaseData["eligible_score"],
                                            };
                                        });
                                        break;
                                    case "20":
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                progress_restriction: 20,
                                                eligible_score: props.phaseData["eligible_score"],
                                            };
                                        });
                                        break;
                                    case "30":
                                        setValues((current) => {
                                            return {
                                                ...current,
                                                progress_restriction: 30,
                                            };
                                        });
                                        break;
                                    default:
                                        setValues((current) => {
                                            return current;
                                        });
                                        break;
                                }
                            }}
                        >
                            <SimpleGrid minChildWidth={"186px"} spacingX={6} spacingY={4}>
                                <Radio value={10}>بدون پیش‌نیاز</Radio>
                                <Radio value={20}>ارسال پاسخ</Radio>
                                <Radio value={30}>کسب حداقل امتیاز مورد نیاز</Radio>
                            </SimpleGrid>
                        </RadioGroup>
                    </FormControl>

                    <FormControl isDisabled={values.progress_restriction !== 30} w="95%">
                        <FormLabel fontSize="lg">حداقل امتیاز مورد نیاز</FormLabel>
                        <Flex>
                            <NumberInput
                                w="100px"
                                value={values.eligible_score ? values.eligible_score : 0}
                                onChange={(valueString, valueNumber) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            eligible_score: valueNumber,
                                        };
                                    });
                                }}
                                min={0}
                                max={100}
                                step={0.5}
                                precision={2}
                            >
                                <NumberInputField />
                            </NumberInput>
                            <Slider
                                isDisabled={values.progress_restriction !== 30}
                                mx={4}
                                flex="1"
                                focusThumbOnChange={false}
                                value={values.eligible_score ? values.eligible_score : 0}
                                step={0.5}
                                colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                                aria-label="slider-eligible"
                                onChangeEnd={(valueNumber) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            eligible_score: valueNumber,
                                        };
                                    });
                                }}
                                onChange={(valueNumber) => {
                                    setValues((current) => {
                                        return {
                                            ...current,
                                            eligible_score: valueNumber,
                                        };
                                    });
                                }}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </Flex>
                        <FormHelperText>برای انتخاب مقدار دقیق‌تر تا دو رقم اعشار، امتیاز را به صورت دستی وارد کنید.</FormHelperText>
                    </FormControl>
                </Stack>
                <Box className={`w-full flex flex-row justify-end`}>
                    <Button
                        colorScheme={"red"}
                        variant="solid"
                        className="mr-2"
                        isDisabled={disabled || loading}
                        onClick={() => {
                            setValues(props.phaseData);
                        }}
                    >
                        بازنشانی
                    </Button>
                    <Button
                        colorScheme={useColorModeValue("RoboEpics.dark", "RoboEpics.gold")}
                        variant="solid"
                        className="mr-2"
                        isLoading={loading}
                        isDisabled={disabled}
                        onClick={() => {
                            setLoading(true);
                            onConfirm();
                        }}
                    >
                        تایید و اعمال تغییرات
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
};
