import React, { useEffect, useState } from "react";
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, useColorModeValue, Heading, Button, Icon } from "@chakra-ui/react";
import { scrollToElement } from "@util/misc";
import { ParticipantsSubmissons, ResultUpload, Upload, UserSubmissions } from "@components/competition";

import Style from "./submit.module.css";
import { NextRouter } from "next/router";
import { AlertCircle, AlertTriangle } from "react-feather";
import { ISubScoreDefData, IToastOptions } from "@util/interfaces";
import { IUser } from "@redux/slices/user";

export const Submit = (props: {
    problemId: number;
    problemPath: string;
    gimulatorTag: string;
    problemEnterId: number | null;
    user: IUser;
    maxOutputSize: number | null;
    submissionFileName: string | null;
    isOwner: boolean;
    isCollaborator: boolean;
    evaluationMode: 10 | 20 | 30;
    repositoryMode: 10 | 20 | 30;
    submission_score_definitions: Array<ISubScoreDefData>;
    isOutputPresentable: boolean;
    allowedRuntimes: Array<string>;
    isEligibleForSubmission?: boolean;
    toast: IToastOptions;
    router: NextRouter;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [level, setLevel] = useState(props.maxOutputSize ? 0 : 1);
    const [tabIndex, setTabIndex] = useState(0);
    const turquoiseOrGold = useColorModeValue("RoboEpics.turquoise", "RoboEpics.gold");
    const tabColorScheme = useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.gold.400");

    useEffect(() => {
        if (props.problemEnterId && !(props.isOwner || props.isCollaborator)) {
            switch (level) {
                case 0:
                    scrollToElement("#output_section", 1000);
                    break;
                case 1:
                    scrollToElement("#commit_section", 1000);
                    break;
            }
        }
    }, [level, props.isCollaborator, props.isOwner, props.problemEnterId]);

    return (
        <Box as="div" className="flex flex-col w-full h-full">
            {!(props.isOwner || props.isCollaborator) ? (
                <Tabs marginInline="-1rem" colorScheme={turquoiseOrGold} index={tabIndex} onChange={(index) => setTabIndex(index)} isLazy>
                    <TabList>
                        <Tab
                            marginRight="1rem"
                            _selected={{
                                px: "1.2rem",
                                fontWeight: 600,
                                borderColor: tabColorScheme,
                            }}
                            isDisabled={props.problemEnterId === null || !props.isEligibleForSubmission}
                        >
                            ارسال‌های پیشین
                        </Tab>
                        <Tab
                            _selected={{
                                px: "1.2rem",
                                fontWeight: 600,
                                borderColor: tabColorScheme,
                            }}
                            isDisabled={props.problemEnterId === null || !props.isEligibleForSubmission}
                        >
                            ارسال جدید
                        </Tab>
                    </TabList>
                    {props.problemEnterId ? (
                        props.isEligibleForSubmission ? (
                            <TabPanels>
                                <TabPanel>
                                    <UserSubmissions
                                        problemEnterId={props.problemEnterId}
                                        gimulatorTag={props.gimulatorTag}
                                        evaluationMode={props.evaluationMode}
                                        toast={props.toast}
                                        router={props.router}
                                        user={props.user}
                                    />
                                </TabPanel>
                                <TabPanel padding="1rem">
                                    {props.maxOutputSize && (
                                        <Box
                                            id="output_section"
                                            sx={{
                                                pointerEvents: level !== 0 ? "none" : "auto",
                                                opacity: level !== 0 ? ".5" : "1",
                                            }}
                                        >
                                            <ResultUpload
                                                problemEnterId={props.problemEnterId}
                                                setLevel={setLevel}
                                                maxOutputSize={props.maxOutputSize}
                                                repositoryMode={props.repositoryMode}
                                                submissionFileName={props.submissionFileName}
                                                toast={props.toast}
                                                router={props.router}
                                                resetTabIndex={() => {
                                                    setTabIndex(0);
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {props.repositoryMode !== 10 && (
                                        <Box
                                            id="commit_section"
                                            sx={{
                                                pointerEvents: level !== 1 ? "none" : "auto",
                                                opacity: level !== 1 ? ".5" : "1",
                                            }}
                                        >
                                            <Upload
                                                problemEnterId={props.problemEnterId}
                                                setLevel={setLevel}
                                                maxOutputSize={props.maxOutputSize}
                                                allowedRuntimes={props.allowedRuntimes}
                                                reloadComponent={props.setHasChanged}
                                                toast={props.toast}
                                                router={props.router}
                                            />
                                        </Box>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        ) : (
                            <TabPanels>
                                <TabPanel>
                                    <Box
                                        className={`pt-12 mt-8 pb-20 px-8 rounded-md flex flex-col w-6/12 mx-auto place-items-center items-center text-center ${Style.submit_warn}`}
                                    >
                                        <Icon as={AlertTriangle} mb={4} w={8} h={8} />
                                        <Heading size="md" mb={4}>
                                            شما واجد شرایط ارسال برای این سوال نیستید.
                                        </Heading>
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        )
                    ) : (
                        <TabPanels>
                            <TabPanel>
                                <Box
                                    className={`pt-12 mt-8 pb-20 px-8 rounded-md flex flex-col w-6/12 mx-auto place-items-center items-center text-center ${Style.submit_warn}`}
                                >
                                    {props.user.data.id ? (
                                        <>
                                            <Icon as={AlertTriangle} mb={4} w={8} h={8} />
                                            <Heading size="md" mb={4}>
                                                شما هنوز در این مسابقه شرکت نکرده‌اید. <br /> برای ارسال پاسخ این سوال باید در مسابقه شرکت
                                                داشته باشید.
                                            </Heading>
                                        </>
                                    ) : (
                                        <>
                                            <Icon as={AlertCircle} mb={4} w={8} h={8} />
                                            <Heading size="md" mb={4}>
                                                برای ارسال پاسخ می‌بایست وارد حساب کاربری خود شده باشید.
                                            </Heading>
                                            <Button
                                                size="md"
                                                colorScheme={turquoiseOrGold}
                                                onClick={() => {
                                                    props.router.push(`/users/signin?returnUrl=${props.router.asPath}`);
                                                }}
                                            >
                                                ورود به حساب کاربری
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    )}
                </Tabs>
            ) : (
                <Tabs marginInline="-1rem" colorScheme={turquoiseOrGold}>
                    <TabList>
                        <Tab
                            marginRight="1rem"
                            _selected={{
                                px: "1.2rem",
                                fontWeight: 600,
                                borderColor: tabColorScheme,
                            }}
                        >
                            ارسال‌های شرکت‌کنندگان
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ParticipantsSubmissons
                                problem={props.problemPath}
                                evaluationMode={props.evaluationMode}
                                subScoreDefs={props.submission_score_definitions}
                                toast={props.toast}
                                router={props.router}
                                user={props.user}
                                isOutputPresentable={props.isOutputPresentable}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </Box>
    );
};
