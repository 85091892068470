import React, { useEffect, useState } from "react";
import {
    Badge,
    Box,
    Button,
    Icon,
    IconButton,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    Table,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import Style from "./compOwner.module.css";
import { CheckSquare, X } from "react-feather";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { NextRouter } from "next/router";
import {
    publishCompetitionRequest,
    retrieveCompetitionPreviousPublishList,
    retrieveCompetitionPreviousPublishSingle,
    retrieveCompetitionPublishStatus,
} from "@util/api/competitions";
import { RefreshOutline } from "react-ionicons";
import { convertDate } from "@util/dateConverter";
import { IToastOptions } from "@util/interfaces";

export const Checklist = (props: {
    isPublished: boolean;
    isInvalid: boolean;
    hasOverview: boolean;
    hasCoverImage: boolean;
    hasRegistrationStartDate: boolean;
    hasPhase: boolean;
    hasProblem: boolean;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    return (
        <Box className={`flex flex-col w-full h-full px-4 py-4 ${Style.checklist_container}`}>
            <Tabs isLazy colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}>
                <TabList>
                    <Tab>درخواست جدید</Tab>
                    <Tab>درخواست‌ها</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ChecklistComponent
                            isPublished={props.isPublished}
                            isInvalid={props.isInvalid}
                            items={{
                                hasOverview: props.hasOverview,
                                hasCoverImage: props.hasCoverImage,
                                hasPhase: props.hasPhase,
                                hasProblem: props.hasProblem,
                                hasRegistrationStartDate: props.hasRegistrationStartDate,
                            }}
                            toast={props.toast}
                            router={props.router}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ChecklistLogs router={props.router} toast={props.toast} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};
const ChecklistComponent = (props: {
    router: NextRouter;
    toast: IToastOptions;
    isPublished: boolean;
    isInvalid: boolean;
    items: {
        hasOverview: boolean;
        hasCoverImage: boolean;
        hasRegistrationStartDate: boolean;
        hasPhase: boolean;
        hasProblem: boolean;
    };
}) => {
    const listContentShortage = {
        hasOverview: {
            title: "قرار دادن توضیحات مسابقه",
            desc: "متن توضیحات  مسابقه خود را با استفاده از دکمه مقابل یا دکمه توضیحات در سایدبار بارگذاری کنید",
            link: "overview",
            button: "آپلود توضیحات",
        },
        hasCoverImage: {
            title: "اضافه کردن تصویر کاور مسابقه",
            desc: "تصویر کاور مسابقه را با استفاده از دکمه مقابل یا دکمه توضیحات در سایدبار بارگذاری کنید",
            link: "overview",
            button: "آپلود تصویر",
        },
        hasPhase: {
            title: "اضافه کردن فاز",
            desc: "مسابقه شما باید حداقل شامل یک فاز باشد، با استفاده از سایدبار می‌توانید به مسابقه خود یک فاز اضافه کنید.",
        },
        hasProblem: {
            title: "اضافه کردن چالش",
            desc: "مسابقه شما باید حداقل یک چالش داشته باشد، با استفاده از سایدبار مسابقه می‌توانید یک چالش به مسابقه خود اضافه کنید",
        },
        hasRegistrationStartDate: {
            title: "تعیین زمان آغاز ثبت‌نام شرکت‌کنندگان",
            desc: "یک تاریخ برای آغاز ثیت‌نام در مسابقه برای شرکت‌کنندگان باید تعیین کنید، با استفاده از دکمه روبرو یا دکمه تنظیمات در ساید بار می‌توانید این تاریخ را مشخص کنید.",
            link: "settings",
            button: "زمان آغاز ثبت‌نام",
        },
    };
    const listContentComplete = {
        hasOverview: { title: "توضیحات مسابقه اضافه شده", desc: "" },
        hasCoverImage: { title: "تصویر کاور مسابقه آپلود شده", desc: "" },
        hasPhase: { title: "داشتن حداقل یک فاز", desc: "" },
        hasProblem: { title: "داشتن حداقل یک چالش در مسابقه", desc: "" },
        hasRegistrationStartDate: {
            title: "تعیین شدن زمان آغاز ثبت نام شرکت",
            desc: "",
        },
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(props.isPublished);

    const [publishLoading, setPublishLoading] = useState(false);
    const [publishError, setPublishError] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [publishStatus, setPublishStatus] = useState(null);

    useEffect(() => {
        setIsDisabled(!Object.values(props.items).every((value) => value) || props.isPublished);
    }, [props.items]);

    useEffect(() => {
        onRetrieveCompetitionPublish();
    }, []);
    const onRetrieveCompetitionPublish = async () => {
        setPublishLoading(true);

        const serverResponse = (await retrieveCompetitionPublishStatus(props.router.query.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const { pending_publish_request } = await serverResponse.json();
            setPublishStatus(pending_publish_request);
        } else {
            setPublishError({
                hasError: true,
                code: serverResponse.status,
                status: serverResponse.statusText,
                details: await serverResponse.json(),
            });
        }

        setPublishLoading(false);
    };

    const errorParser = (data: Array<string>) => {
        let message = "";
        let status;
        switch (data[0]) {
            case "Competition is already published!":
                message = "مسابقه شما منتشر شده است!";
                status = "info";
                break;
            case "Competition needs to have specified the starting date of register!":
                message = "شما زمان اغاز ثبت‌نام برای مسابقه خود تعیین نکرده‌اید";
                status = "error";
                break;
            case "Competition needs to have description!":
                message = "بخش توضیحات مسابقه شما تکمیل نشده است!";
                status = "error";
                break;
            case "Competition needs to have set a cover image!":
                message = "برای مسابقه خود یک تصویر کاور ارائه کنید!";
                status = "error";
                break;
            case "Competition needs to have at least one phase!":
                message = "مسابقه شما باید حداقل یک فاز(مرحله) داشته باشد!";
                status = "error";
                break;
            case "Competition needs to have at least one problem attached to it!":
                message = "مسابقه شما باید حداقل یک چالش داشته باشد!";
                status = "error";
                break;
            case "Your previous publish request has not been reviewed yet!":
                message = "درخواست انتشار قبلی شما هنوز بررسی نشده است!";
                status = "info";
                break;
            default:
                message = data[0];
                status = "error";
                break;
        }
        props.toast({
            variant: "subtle",
            duration: 7000,
            isClosable: true,
            description: message,
            status: status,
        });
    };

    const onPublish = async () => {
        setIsLoading(true);

        const serverResponse = (await publishCompetitionRequest(props.router.query.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                status: "success",
                duration: 3000,
                description: "درخواست انتشار مسابقه شما با موفقیت ساخته شد!",
                isClosable: true,
                variant: "subtle",
            });
        } else {
            if (serverResponse.status === 400) {
                const data = await serverResponse.json();
                errorParser(data);
            } else {
                props.toast({
                    status: "error",
                    duration: 5000,
                    description: "مشکلی در ساخت درخواست به وجود آمده؛ لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
                    variant: "subtle",
                });
            }
        }

        setIsLoading(false);
    };
    return (
        <Box className={`flex flex-col w-full h-full`}>
            <Text>
                {`شما برای اینکه بتوانید مسابقه خود را در دسترس شرکت‌کنندگان برای
                شرکت و ثبت‌نام قرار دهید و علاوه بر اینکه تجربه مناسبی به
                شرکت‌کنندگان مسابقه شما ارائه شود باید مواردی را برای انتشار
                مسابقه خود در سامانه روبواپیکس در نظر بگیرید و این شروط را رعایت
                کنید. این موارد در لیست زیر آمده است و زمانی که تمام شرایط رعایت
                شود دکمه "انتشار مسابقه" برای شما فعال خواهد شد و پس از آن اگر
                مسابقه شما و محتویات آن مورد تأیید کارشناسان روبواپیکس قرار گرفت
                منتشر خواهد شد. در غیر اینصورت مشکلاتی که توسط کارشناسان گزارش
                شده در تب "سوابق درخواست انتشار" قابل مشاهده است و شما باید پس از
                برطرف کردن این مشکلات دوباره درخواست انتشار مسابقه خود را ارسال
                کنید.`}
            </Text>
            <VStack spacing={0} w="full" alignItems={"flex-start"}>
                {Object.keys(props.items).map((key, index) => {
                    return (
                        <Box
                            key={key}
                            className={`w-full pr-4 pl-4 py-4 flex flex-row justify-between border ${
                                index == 0 ? "rounded-t-md" : index == Object.keys(props.items).length - 1 ? "rounded-b-md" : ""
                            }`}
                            bgColor={
                                props.items[key]
                                    ? useColorModeValue("rgb(0 ,0 ,0 , 0.04)", "rgb(0 ,0, 0 , 0.2)")
                                    : useColorModeValue("rgb(255 ,213 ,218 , 0.4)", "rgb(183 ,28, 28 , 0.1)")
                            }
                        >
                            <Box className={`flex flex-row justify-start h-full w-full`}>
                                <Icon
                                    as={props.items[key] ? CheckSquare : X}
                                    color={
                                        props.items[key]
                                            ? useColorModeValue("green.600", "green.500")
                                            : useColorModeValue("red.600", "red.500")
                                    }
                                    boxSize={"1.2em"}
                                    ml={4}
                                    strokeWidth={`${props.items[key] ? "3px" : "5px"}`}
                                />
                                <VStack alignItems={"flex-start"} maxW={"80%"}>
                                    <Text mb={0}>{props.items[key] ? listContentComplete[key].title : listContentShortage[key].title}</Text>
                                    {!props.items[key] && (
                                        <Text fontSize={"12px"} fontWeight={"thin"}>
                                            {listContentShortage[key].desc}
                                        </Text>
                                    )}
                                </VStack>
                            </Box>
                            {!props.items[key] && listContentShortage[key].link && (
                                <Link href={`/c/${props.router.query.competition}/${listContentShortage[key].link}`} passHref>
                                    <ChakraLink>
                                        <Button
                                            variant={"link"}
                                            size="xs"
                                            height="min-content"
                                            colorScheme={"RoboEpics.azure"}
                                            rightIcon={<ChevronLeftIcon />}
                                        >
                                            {listContentShortage[key].button}
                                        </Button>
                                    </ChakraLink>
                                </Link>
                            )}
                        </Box>
                    );
                })}
            </VStack>
            <Box className={`flex flex-row w-full justify-between mt-8`}>
                {/* {publishStatus && (
                    <Text fontSize={"lg"}>
                        شناسه آخرین درخواست انتشار که در دست بررسی است :{" "}
                        {publishStatus}
                    </Text>
                )} */}
                <Button
                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onClick={() => {
                        onPublish();
                    }}
                    mr="auto"
                >
                    انتشار مسابقه
                </Button>
            </Box>
        </Box>
    );
};
const ChecklistLogs = (props: { router: NextRouter; toast: IToastOptions }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [HasErrorLoading, setHasErrorLoading] = useState(false);
    const [errorLoadingDetail, setErrorLoadingDetail] = useState({ status: "", code: 1, details: "" });
    const [publishData, setpublishData] = useState<Array<{ id: number; date_created: string; status: string }>>([]);

    const [chosenId, setChosenId] = useState<number>(null);
    const [detailModalVisible, setDetailModalVisible] = useState(false);

    const statuses = {
        "Queued for review": { color: "blue", status: "در صف بررسی" },
        Reviewing: { color: "yellow", status: "در دست بررسی" },
        Declined: { color: "red", status: "رد شده" },
        Accepted: { color: "green", status: "پذیرفته شده" },
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setpublishData([]);
        setIsLoading(true);

        const serverResponse = (await retrieveCompetitionPreviousPublishList(props.router.query.competition, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setpublishData(data);
        } else {
            setHasErrorLoading(true);
            setErrorLoadingDetail({
                code: serverResponse.status,
                details: await serverResponse.json(),
                status: serverResponse.statusText,
            });
        }

        setIsLoading(false);
    };

    return (
        <Box overflowX={"auto"} className={`w-full h-full flex flex-col`}>
            <IconButton
                alignSelf={"flex-end"}
                size="xs"
                aria-label="reload-table-data"
                variant="ghost"
                icon={<RefreshOutline color={useColorModeValue("#000", "fff")} height="18px" width="18px" />}
                onClick={getData}
            />
            <Table size="sm" variant="simple">
                <Thead>
                    <Tr>
                        <Th textAlign={["center"]} whiteSpace="nowrap">
                            ردیف
                        </Th>
                        <Th textAlign={["center"]} whiteSpace="nowrap">
                            شناسه
                        </Th>
                        <Th textAlign={["center"]} whiteSpace="nowrap">
                            زمان ارسال درخواست
                        </Th>
                        <Th textAlign={["center"]} whiteSpace="nowrap">
                            وضعیت درخواست
                        </Th>
                        <Th textAlign={["center"]} whiteSpace="nowrap">
                            نتیجه بررسی درخواست
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {publishData.map((data, index) => (
                        <Tr key={index}>
                            <Td textAlign={"center"}>{index + 1}</Td>
                            <Td textAlign={"center"}>{data.id}</Td>
                            <Td textAlign={"center"}>{convertDate(data.date_created, "D MMMM YYYY - HH:mm:ss")}</Td>
                            <Td textAlign={"center"}>
                                <Badge colorScheme={statuses[data.status].color} px={2} py={1}>
                                    {statuses[data.status].status}
                                </Badge>
                            </Td>
                            <Td textAlign={"center"}>
                                {data.status === "Declined" && (
                                    <Button
                                        size="xs"
                                        variant={useColorModeValue("solid", "outline")}
                                        onClick={() => {
                                            setChosenId(data.id);
                                            setDetailModalVisible(true);
                                        }}
                                        colorScheme={"RoboEpics.turquoise"}
                                        fontWeight="300"
                                        color={useColorModeValue("#fff", "RoboEpics.turquoise.600")}
                                    >
                                        جزئیات
                                    </Button>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Box className={`flex flex-col w-full h-full justify-center items-center`}>
                {isLoading && <Spinner size="md" my={4} />}
                {HasErrorLoading && (
                    <Text my={4}>خطایی در دریافت داده ها از سرور به وجود آمده، لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید</Text>
                )}
                {publishData.length === 0 && !isLoading && !HasErrorLoading && <Text my={4}>درخواستی وجود ندارد</Text>}
            </Box>
            <PublishRequestModal
                isOpen={detailModalVisible}
                onClose={() => {
                    setDetailModalVisible(false);
                    setChosenId(null);
                }}
                id={chosenId}
                router={props.router}
                toast={props.toast}
            />
        </Box>
    );
};
const PublishRequestModal = (props: { id: number; isOpen: boolean; onClose: () => void; router: NextRouter; toast: IToastOptions }) => {
    const [requestData, setRequestData] = useState<{
        id: number;
        status: "Declined";
        description: string;
        reviewer: string;
    }>({ id: 0, status: "Declined", description: "", reviewer: "" });

    useEffect(() => {
        if (props.isOpen) getData();
    }, [props.isOpen]);

    const getData = async () => {
        const serverResponse = (await retrieveCompetitionPreviousPublishSingle(props.router.query.competition, props.id, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setRequestData(data);
        }
    };
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setRequestData({
                    id: 0,
                    status: "Declined",
                    description: "",
                    reviewer: "",
                });
            }}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>جزئیات بررسی درخواست</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={2}>
                        <Box className={`flex flex-row w-full justify-between`}>
                            <Text>{`بررسی شده توسط: ${requestData.reviewer}`}</Text>
                            <Badge colorScheme={"red"} px={1} py={1}>
                                رد شده
                            </Badge>
                        </Box>
                    </VStack>
                    {requestData.description}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
