import { Avatar, Box, Button, FormControl, FormLabel, Heading, IconButton, Input, useColorModeValue, VStack } from "@chakra-ui/react";
import { CustomAvatar } from "@components";
import { editMyProfile, removeProfilePicture } from "@util/api/profile";
import CropModal, { b64toBlob } from "@util/CropModal";
import { IProfileData, IToastOptions } from "@util/interfaces";
import { getImageURL } from "@util/mediaLoader";
import { NextRouter } from "next/router";
import React, { useRef, useState } from "react";
import { AddOutline, Trash } from "react-ionicons";
import styles from "./general.module.css";

export const GeneralInfoEdit = (props: {
    profileData: IProfileData;
    toast: IToastOptions;
    router: NextRouter;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const imageRef = useRef(null);
    const inputRef = useRef(null);
    const [profileImage, setProfileImage] = useState(
        props.profileData.profile_picture ? getImageURL(props.profileData.profile_picture) : null,
    );
    const [tmpProfileImage, setTmpProfileImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState({
        full_name: props.profileData.full_name ?? "",
        full_name_english: props.profileData.full_name_english ?? "",
        username: props.profileData.username ?? "",
    });

    const handleChange = (prop: string) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const compileFormData = () => {
        const form_data = new FormData();
        Object.keys(values).forEach((key) => {
            if (!Array.isArray(values[key])) form_data.append(key, values[key]);
        });

        // Converting Profile Image to Binary
        if (profileImage) {
            const block = profileImage.split(";");
            if (block.length === 2) {
                const contentType = block[0].split(":")[1]; // In this case "image/gif"
                const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
                const blob = b64toBlob(realData, contentType);
                form_data.append("profile_picture", blob, "avatar.png");
            }
        }
        return form_data;
    };

    const checkFileAttributes = (file) => {
        const types = ["image/jpeg", "image/png"];
        if (types.every((type) => type !== file.type)) {
            return true;
        }
        if (file.size > 5000000) {
            return true;
        }
        return false;
    };

    const onChooseFile = (e) => {
        // const file = acceptedFile?.[0];
        const file = e.target.files[0];
        if (!file || checkFileAttributes(file)) {
            props.toast({
                isClosable: true,
                description: "عکس پروفایل فقط با فرمت png یا jpg و حجم حداکثر 500kb قابل قبول است.",
                status: "error",
                duration: 9000,
                variant: "subtle",
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setTmpProfileImage(e.target.result.toString());
            setIsModalOpen(true);
        };
        reader.readAsDataURL(file);
    };
    const crop = (cropData) => {
        const image = new Image();
        image.src = tmpProfileImage;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / cropData.tmpWidth;
        const scaleY = image.naturalHeight / cropData.tmpHeight;
        canvas.width = cropData.width;
        canvas.height = cropData.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            cropData.x * scaleX,
            cropData.y * scaleY,
            cropData.width * scaleX,
            cropData.height * scaleY,
            0,
            0,
            cropData.width,
            cropData.height,
        );
        setProfileImage(canvas.toDataURL("image/jpeg"));
    };

    const deleteProfilePicture = async () => {
        const serverResponse = (await removeProfilePicture({
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                isClosable: true,
                description: "تصویر پروفایل حذف شد",
                status: "info",
                duration: 3000,
                variant: "subtle",
            });
        } else {
            props.toast({
                isClosable: true,
                title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                description: JSON.stringify(await serverResponse.text()),
                status: "error",
                variant: "subtle",
                duration: 5000,
            });
        }
    };

    const onEditMyProfile = async () => {
        setIsLoading(true);
        const data_req = compileFormData();
        if (!profileImage && props.profileData.profile_picture) await deleteProfilePicture();
        const serverResponse = (await editMyProfile(data_req, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        switch (serverResponse.status) {
            case 200:
                props.toast({
                    isClosable: true,
                    description: "تغییرات با موفقیت اعمال شد.",
                    status: "success",
                    duration: 3000,
                    variant: "subtle",
                });
                props.setHasChanged(true);
                break;
            default:
                props.toast({
                    isClosable: true,
                    title: "مشکلی در اعمال تغییرات رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                    description: JSON.stringify(serverResponse.json()),
                    status: "error",
                    duration: 5000,
                    variant: "subtle",
                });
                break;
        }
        setIsLoading(false);
    };
    return (
        <VStack spacing={6} alignItems="flex-start" borderWidth={1} borderRadius="lg" p={4} className={styles.container} w="full">
            <Box className={`flex flex-row w-full justify-between`}>
                <Heading fontWeight={700} size="md">
                    اطلاعات اصلی
                </Heading>
                <Button
                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                    isLoading={isLoading}
                    onClick={onEditMyProfile}
                    size="sm"
                >
                    ذخیره تغییرات
                </Button>
            </Box>
            <Box className={`flex flex-row w-full justify-between ${styles.infos_container}`}>
                <Box
                    className="flex flex-col items-center justify-center"
                    style={{
                        flexBasis: "20%",
                    }}
                >
                    <Box className="grid">
                        <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => onChooseFile(e)} />
                        <CropModal
                            modalTitle={"تصویر پروفایل جدید"}
                            src={tmpProfileImage}
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            onSuccess={(data) => {
                                crop(data);
                                setIsModalOpen(false);
                            }}
                            aspectX={1}
                            aspectY={1}
                        />
                        <div className={`${styles.profilePictureDarkHover}`}>
                            <IconButton
                                size="sm"
                                bg={"transparent"}
                                aria-label="new profile picture"
                                icon={<AddOutline color="#fff" height="21px" width="21px" />}
                                _hover={{
                                    bg: "blackAlpha.800",
                                }}
                                onClick={() => {
                                    inputRef.current.click();
                                }}
                            />
                            {profileImage && (
                                <IconButton
                                    className="mr-2"
                                    size="sm"
                                    bg={"transparent"}
                                    aria-label="remove profile picture"
                                    icon={<Trash color="#c53030" height="19px" width="19px" />}
                                    _hover={{
                                        bg: "blackAlpha.800",
                                    }}
                                    onClick={() => setProfileImage(null)}
                                />
                            )}
                        </div>
                        <CustomAvatar
                            ref={imageRef}
                            id="imagePreview"
                            src={profileImage ? profileImage : ""}
                            height="7rem"
                            width="7rem"
                            noBorder
                            placeholder={<Avatar size="full" />}
                            showPlaceholder={!profileImage}
                            className={`w-28 h-28 mx-5 px-0 py-0 ${styles.profilePicture} cursor-pointer`}
                            style={{
                                gridRow: "1",
                                gridColumn: "1",
                            }}
                        />
                    </Box>
                </Box>
                <VStack spacing={4} w="full">
                    <FormControl>
                        <FormLabel fontSize="14px">نام کامل</FormLabel>
                        <Input
                            variant="filled"
                            type="text"
                            value={values.full_name}
                            onChange={handleChange("full_name")}
                            id="edit-full-name-fa"
                        />
                    </FormControl>
                    <FormControl dir="ltr">
                        <FormLabel fontSize="14px">Full Name</FormLabel>
                        <Input
                            variant="filled"
                            type="text"
                            value={values.full_name_english}
                            onChange={handleChange("full_name_english")}
                            id="edit-full-name-en"
                            fontFamily="sans-serif"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="14px">نام کاربری</FormLabel>
                        <Input
                            variant="filled"
                            type="text"
                            value={values.username}
                            onChange={handleChange("username")}
                            id="edit-username"
                            dir="ltr"
                            fontFamily="sans-serif"
                            isDisabled
                        />
                    </FormControl>
                </VStack>
            </Box>
        </VStack>
    );
};
