import React from "react";
import { useColorModeValue, Image, Img, Text, SimpleGrid, Link as ChakraLink, IconButton, Box, Icon } from "@chakra-ui/react";
import Link from "next/link";
import Style from "./footer.module.css";
import {
    Image as IonImage,
    ImageOutline,
    LogoDiscord,
    LogoGithub,
    LogoInstagram,
    LogoLinkedin,
    LogoYoutube,
    PaperPlane,
} from "react-ionicons";
import {
    ROBOEPICS_DISCORD_LINK,
    ROBOEPICS_GITHUB_LINK,
    ROBOEPICS_IG_LINK,
    ROBOEPICS_LINKEDIN_LINK,
    ROBOEPICS_TELEGRAM_LINK,
    ROBOEPICS_YT_LINK,
} from "@util/constants";

export const Footer2 = () => {
    return (
        <footer
            className={`${useColorModeValue(Style.footer_container_light, Style.footer_container_dark)}`}
            style={{
                backgroundColor: useColorModeValue("#F2F3F7", "#111111"),
            }}
        >
            <div className={`${Style.footer_row} ${Style.first_row}`}>
                <div className={`${Style.footer_text_container}`}>
                    <Text fontSize={["16px", "18px"]} fontWeight={"semibold"} lineHeight={["25px", "28px"]} mb={"20px"}>
                        آری به اتفاق جهان می‌توان گرفت
                    </Text>
                    <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]} mb={0}>
                        ما به خرد جمعی اعتقاد داریم و معتقدیم که گروه بزرگی از افراد با داشتن ابزارهای مناسب که فرایندها را بهبود ببخشد و
                        تعامل جمعی را تسهیل کند، می‌توانند در مدت زمانی کوتاه به پیشرفت‌هایی باورنکردنی دست یابند.
                    </Text>
                </div>
                <div className={`${Style.footer_links_container}`}>
                    <Img
                        className={`${Style.footer_logo}`}
                        src="/images/logo-05-top.png"
                        style={{
                            filter: useColorModeValue("invert(0)", "invert(1)"),
                        }}
                        objectFit={"scale-down"}
                        onError={() => {
                            console.log(`%c${String.fromCodePoint(0x0001f34c)}`, ["font-size: 64px"].join(";"));
                        }}
                    />
                    <SimpleGrid columns={2} spacingX={9} spacingY={3} h="fit-content" className={`${Style.footer_links_container}`}>
                        <Link href="/c" passHref>
                            <ChakraLink
                                h="fit-content"
                                fontSize={"16px"}
                                variant="unstyled"
                                color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                _hover={{
                                    color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                }}
                                fontWeight="semibold"
                                bg="transparent"
                            >
                                مسابقه‌ها
                            </ChakraLink>
                        </Link>
                        <Link href="/about" passHref>
                            <ChakraLink
                                h="fit-content"
                                fontSize={"16px"}
                                variant="unstyled"
                                color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                _hover={{
                                    color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                }}
                                fontWeight="semibold"
                                bg="transparent"
                            >
                                درباره ما
                            </ChakraLink>
                        </Link>
                        <Link href="/contact" passHref>
                            <ChakraLink
                                h="fit-content"
                                fontSize={"16px"}
                                variant="unstyled"
                                color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                _hover={{
                                    color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                }}
                                fontWeight="semibold"
                                bg="transparent"
                            >
                                تماس با ما
                            </ChakraLink>
                        </Link>
                        <Link href="/terms" passHref>
                            <ChakraLink
                                h="fit-content"
                                fontSize={"16px"}
                                variant="unstyled"
                                color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                _hover={{
                                    color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                }}
                                fontWeight="semibold"
                                bg="transparent"
                            >
                                آیین‌نامه
                            </ChakraLink>
                        </Link>
                        <Link href="/bug-report" passHref>
                            <ChakraLink
                                h="fit-content"
                                fontSize={"16px"}
                                variant="unstyled"
                                color={useColorModeValue("RoboEpics.turquoise.500", "RoboEpics.azure.500")}
                                _hover={{
                                    color: useColorModeValue("RoboEpics.turquoise.600", "RoboEpics.azure.600"),
                                }}
                                fontWeight="semibold"
                                bg="transparent"
                            >
                                گزارش باگ
                            </ChakraLink>
                        </Link>
                    </SimpleGrid>
                </div>
            </div>
            <div className={`${Style.footer_row} ${Style.second_row}`}>
                <div className={`${Style.footer_text_container}`}>
                    <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                        به دلیل بالا، ما معتقدیم که توسعه‌ی راه‌حل‌های هوش مصنوعی از طریق جمع‌سپاری در یک فضای باز، برای سازمان‌ها بسیار
                        ارزشمند است؛ زیرا به آن‌ها امکان می‌دهد تا پیشرفت خود را در توسعه و پیاده‌سازی هوش مصنوعی شتاب دهند و سریع‌تر به
                        اهداف خود برسند.
                    </Text>
                </div>
                <div className={`${Style.footer_text_container}`}>
                    <Text fontSize={["16px", "18px"]} lineHeight={["25px", "28px"]}>
                        این چشم‌انداز همچنین برای جامعه‌ای وسیع‌تر، که از اشتراک دستاوردها و آموخته‌‌های به دست آمده در طول این مسیر
                        بهره‌مند می‌شوند، ارزش بالاتری را خلق می‌کند.و این همان ارزشی است که ما می‌خواهیم در روبواپیکس ایجاد کنیم.
                    </Text>
                </div>
            </div>
            <div className={`${Style.footer_powered_by}`}>
                <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=274725&amp;Code=Ac6cWHH648f6rDRKYa2s">
                    <img
                        referrerPolicy="origin"
                        src="https://Trustseal.eNamad.ir/logo.aspx?id=274725&amp;Code=Ac6cWHH648f6rDRKYa2s"
                        alt=""
                        style={{ cursor: "pointer" }}
                        id="Ac6cWHH648f6rDRKYa2s"
                    />
                </a>
                {/* <ChakraLink
                    referrerPolicy="origin"
                    target="_blank"
                    href="https://trustseal.enamad.ir/?id=274725&amp;Code=Ac6cWHH648f6rDRKYa2s"
                >
                    <Image
                        referrerPolicy="origin"
                        src="https://Trustseal.eNamad.ir/logo.aspx?id=274725&amp;Code=Ac6cWHH648f6rDRKYa2s"
                        alt="roboepics-enamad"
                        style={{
                            cursor: "pointer",
                            maxWidth: "10rem",
                            maxHeight: "10rem",
                            borderRadius: "10px",
                            padding: "0.5rem",
                        }}
                        backgroundColor={"gray.400"}
                        id="Ac6cWHH648f6rDRKYa2s"
                        fallback={
                            <IconButton
                                width={"7rem"}
                                height={"7rem"}
                                variant={useColorModeValue("outline", "solid")}
                                aria-label="enamad-btn"
                                icon={useColorModeValue(
                                    <ImageOutline width={"4.5rem"} height="4.5rem" color="black" />,
                                    <IonImage width={"4.5rem"} height="4.5rem" color="white" />,
                                )}
                            />
                        }
                    />
                </ChakraLink> */}
                {/* <Text fontSize={"16px"} fontWeight={"semibold"} lineHeight={"25px"} mb={1}>
                    قدرت گرفته از ابر آروان
                </Text>
                <Img
                    src="/images/arvan_cloud.png"
                    className={`${Style.arvan_cloud}`}
                    style={{
                        filter: useColorModeValue("invert(0)", "invert(1)"),
                    }}
                /> */}
            </div>
            <div className={`${Style.footer_rights}`}>
                <Text fontSize={"16px"} fontWeight={"semibold"} lineHeight={"25px"} mb={0}>
                    تمامی حقوق برای روبواپیکس محفوظ است.
                </Text>
            </div>
            <div className={`${Style.footer_socials}`}>
                <Link href={ROBOEPICS_DISCORD_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        Discord
                        <LogoDiscord
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
                <Link href={ROBOEPICS_GITHUB_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        Github
                        <LogoGithub
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
                <Link href={ROBOEPICS_IG_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        Instagram
                        <LogoInstagram
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
                <Link href={ROBOEPICS_LINKEDIN_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        LinkedIn
                        <LogoLinkedin
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
                <Link href={ROBOEPICS_TELEGRAM_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        Telegram
                        <PaperPlane
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
                <Link href={ROBOEPICS_YT_LINK} passHref>
                    <ChakraLink
                        h="fit-content"
                        fontSize={"16px"}
                        variant="unstyled"
                        color={"#5990F4"}
                        className={`${Style.fs_container}`}
                        _hover={{
                            color: "#5990F4",
                        }}
                        fontWeight="semibold"
                        bg="transparent"
                    >
                        Youtube
                        <LogoYoutube
                            width="24px"
                            height="24px"
                            color={"#5990F4"}
                            cssClasses={`${useColorModeValue(Style.fs_icon, Style.fs_icon_dark)}`}
                        />
                    </ChakraLink>
                </Link>
            </div>
        </footer>
    );
};
