import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    Text,
    Tooltip,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import Style from "./settings.module.css";
import { ExternalLink, Plus } from "react-feather";
import { createProblemCodeset, retrieveProblemCodeset } from "@util/api/problem_code_set";
import { IToastOptions } from "@util/interfaces";
import { NextRouter } from "next/router";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const RepositoriesSettings = (props: {
    problemCodeSet: Array<{ gitlab_repo_url: string; kind: string; name: string }>;
    problem: string;
    toast: IToastOptions;
    router: NextRouter;
}) => {
    const [codeSets, setCodeSets] = useState(props.problemCodeSet);
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState({ hasError: false, status: "", details: "", code: 1 });
    const [newRepoModalVisible, setNewRepoModalVisible] = useState(false);
    const [reloadComponent, setReloadComponent] = useState(false);

    const getCodeSets = async () => {
        const serverResponse = (await retrieveProblemCodeset(props.problem, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            const data = await serverResponse.json();
            setCodeSets(data);
        } else {
            setErrorLoading({
                hasError: true,
                status: serverResponse.statusText,
                details: await serverResponse.json(),
                code: serverResponse.status,
            });
        }
    };

    useEffect(() => {
        if (reloadComponent) {
            setLoading(true);
            getCodeSets();
            setLoading(false);
            setReloadComponent(false);
        }
    }, [reloadComponent]);

    useEffect(() => {
        setLoading(true);
        getCodeSets();
        setLoading(false);
    }, []);

    if (loading) {
        return (
            <Box className={`flex flex-row justify-center mb-4 px-8 ${Style.basic_form_container}`}>
                <Spinner size={"md"} />
            </Box>
        );
    }
    if (errorLoading.hasError) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle ml={2} fontWeight="600">
                    {errorLoading.status ? `${errorLoading.code} - ${errorLoading.status}` : "مشکلی در دریافت داده از سرور رخ داد."}
                </AlertTitle>
                {errorLoading.details && <AlertDescription fontSize="sm">{errorLoading.details}</AlertDescription>}
                <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
            </Alert>
        );
    }
    return (
        <Box className={`flex flex-row justify-center px-8 ${Style.basic_form_container}`}>
            <VStack w={"full"} spacing={6}>
                <Button
                    onClick={() => {
                        setNewRepoModalVisible(true);
                    }}
                    colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.gold")}
                    leftIcon={<Plus />}
                    mr="auto"
                    size="sm"
                >
                    ساخت مخزن جدید
                </Button>

                <SimpleGrid columns={[null, 1, 2]} spacingX={6} spacingY={6} w={"full"}>
                    {codeSets.map((set, index) => {
                        return (
                            <FormControl key={`problem-code-set-${index}`} className="flex flex-col p-4 w-full rounded-md border">
                                <Text>عنوان مخزن : {set.name}</Text>
                                <Tooltip label={set.gitlab_repo_url} placement="bottom-end" fontSize={"12px"} textAlign={"left"}>
                                    <Button
                                        size={"xs"}
                                        onClick={() => {
                                            window.open(set.gitlab_repo_url);
                                        }}
                                        w="fit-content"
                                        alignSelf={"flex-end"}
                                        colorScheme={useColorModeValue("RoboEpics.azure", "RoboEpics.azure")}
                                        leftIcon={<ExternalLinkIcon />}
                                    >
                                        مشاهده
                                    </Button>
                                </Tooltip>
                            </FormControl>
                        );
                    })}
                </SimpleGrid>
            </VStack>
            <NewRepoModal
                onClose={() => {
                    setNewRepoModalVisible(false);
                }}
                isOpen={newRepoModalVisible}
                path={props.problem}
                setHasChanged={setReloadComponent}
                toast={props.toast}
                router={props.router}
            />
        </Box>
    );
};

const NewRepoModal = (props: {
    isOpen: boolean;
    onClose: () => void;
    path: string;
    toast: IToastOptions;
    setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    router: NextRouter;
}) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [name, setName] = useState("");
    const [path, setPath] = useState("");

    const onCreateProblemCodeset = async () => {
        setLoading(true);
        const reqData = name ? { path, name } : { path };

        const serverResponse = (await createProblemCodeset(props.path, reqData, {
            toast: props.toast,
            requesterPath: props.router.asPath,
        })) as Response;
        if (serverResponse.ok) {
            props.toast({
                description: "مخزن جدید با موفقیت ساخته شد!",
                duration: 3000,
                isClosable: true,
                status: "success",
            });

            setDisabled(true);
            setTimeout(() => {
                props.onClose();
                setPath("");
                setName("");
                props.setHasChanged(true);
            }, 3000);
        } else {
            props.toast({
                title: "مشکلی در ساخت مخزن رخ داد: " + ` ${serverResponse.statusText} (${serverResponse.status})`,
                isClosable: true,
                duration: 5000,
                variant: "subtle",
                status: "error",
                description: JSON.stringify(await serverResponse.json()) + " لطفاً دوباره تلاش کنید.",
            });
        }
        setDisabled(false);
        setLoading(false);
    };
    return (
        <Modal
            isCentered
            size={"lg"}
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose();
                setName("");
                setPath("");
                setDisabled(false);
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ساخت مخزن جدید</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        <FormControl isRequired>
                            <FormLabel>مسیر مخزن</FormLabel>
                            <Input
                                value={path}
                                onChange={(e) => {
                                    setPath(e.target.value);
                                }}
                                variant={"filled"}
                                type="text"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>عنوان مخزن</FormLabel>
                            <Input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                variant={"filled"}
                                type="text"
                            />
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={path === "" || disabled} isLoading={loading} onClick={onCreateProblemCodeset}>
                        ایجاد مخزن
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
