import React, { useState } from "react";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    Heading,
    Text,
    Link as ChakraLink,
    useColorModeValue,
    useMediaQuery,
    Spinner,
    Fade,
} from "@chakra-ui/react";
import { RoboEpicsMarkdown } from "@components";
import Style from "./terms.module.css";

export const Terms = (props: { rules: string }) => {
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const bg = useColorModeValue("RoboEpics.black.default", "RoboEpics.gold.400");
    const btnTxt = useColorModeValue("#fff", "RoboEpics.black.default");
    if (loadingComponent) {
        return (
            <Box className="flex flex-col items-center">
                <Spinner mb={4} />
                {/* <Text>قرار مدارایی که باهم داشتیم رو از سرور بگیریم...</Text> */}
            </Box>
        );
    }
    if (errorLoading) {
        return (
            <Fade in={errorLoading}>
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle ml={2} fontWeight="600">
                        مشکلی در دریافت داده از سرور رخ داد.
                    </AlertTitle>
                    <AlertDescription fontSize="sm">دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.</AlertDescription>
                </Alert>
            </Fade>
        );
    }
    return (
        <Box as="div" className="w-full h-full">
            <p>آیین‌نامه این مسابقه به شرح زیر است. حضور و مشارکت شما در این مسابقه ملزم به پذیرش و پیروی از این آیین‌نامه خواهد بود.</p>
            <Box
                className={`mt-4 p-3 border ${Style.terms_container}`}
                bg={useColorModeValue("RoboEpics.black.200", "RoboEpics.dark.500")}
                id="terms_container"
                sx={{
                    hr: {
                        margin: "16px 0",
                    },
                }}
            >
                <RoboEpicsMarkdown>{props.rules ? props.rules.toString() : ""}</RoboEpicsMarkdown>
            </Box>
            <hr className="my-3" />
        </Box>
    );
};
