import React from "react";
import { Line } from "react-chartjs-2";
import { useColorModeValue } from "@chakra-ui/react";
import { convertDate } from "@util/dateConverter";
import _ from "lodash";

interface SingleProblemIndividualChartProps {
    submissionData: string[];
}

export const SingleProblemIndividualChart = (props: SingleProblemIndividualChartProps): any => {
    const convertedDates = ["1500/1/1"];
    Array.from(props.submissionData).forEach((el) => {
        if (el.toString().search(/^[1-9][0-9]{3}\/[0-9]{1,2}\/[0-9]{1,2}$/g) == -1)
            convertedDates.push(convertDate(el, "YYYY/M/D").toString());
    });
    convertedDates.push("1000/1/1");
    const count = _.countBy(convertedDates.reverse());
    count["1500/1/1"] = count["1000/1/1"] = 0;
    const submission_dates_chart = [];
    const submission_dates_chart_count = [];
    _.forEach(count, (v, k) => {
        submission_dates_chart.push(k);
        submission_dates_chart_count.push(v);
    });
    const _borderColor = useColorModeValue(`#023e8a`, `#ffd300`);
    const _backgroundColor = useColorModeValue("rgba(0,119,182,.4)", "rgba(255,229,104,.35)");
    const chartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 63);
        gradient.addColorStop(0, _backgroundColor);
        gradient.addColorStop(1, "rgba(255,255,255,0)");
        return {
            labels: submission_dates_chart,
            datasets: [
                {
                    pointRadius: !(submission_dates_chart_count.length - 1) && 2.5, // if you want to see points on chart remove this line and uncomment next lines
                    // pointBackgroundColor: "#123456",
                    // pointBorderColor: "#dddddd",
                    // pointBorderWidth: 1,
                    // pointStyles: "cross",
                    fontFamily: "Shabnam",
                    fill: "start",
                    // label: "No. of submissions",
                    data: submission_dates_chart_count,
                    backgroundColor: gradient,
                    borderColor: _borderColor,
                    borderWidth: 2,
                },
            ],
        };
    };

    return (
        <Line
            width={100}
            height={100}
            data={chartData}
            options={{
                layout: {
                    padding: { left: 10, right: 40, top: 20, bottom: 20 },
                },
                datasets: [
                    {
                        label: { fontFamily: "Shabnam" },
                    },
                ],
                maintainAspectRatio: false,
                legend: { display: false },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                                color: useColorModeValue("rgba(0,0,0,0.1)", "rgba(255,255,255,0.1)"),
                                zeroLineColor: useColorModeValue("rgba(0,0,0,0.5)", "rgba(255,255,255,0.5)"),
                            },
                            ticks: {
                                display: false,
                            },
                            display: true,
                        },
                    ],
                    xAxes: [
                        {
                            offset: submission_dates_chart.length === 1,
                            ticks: {
                                display: false,
                            },
                            display: false,
                        },
                    ],
                },
            }}
        />
    );
};
